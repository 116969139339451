<template>
  <div>
    <div>
      <div
        class="menu-title row"
        @click="toggleMenu('ex')"
        title="Shipping Tallying - Import"
        v-if="finalUserPermissions.includes('ST_EXPORT') ">
        <div class="col-md-10">
          <svg-icon
            class="nc-icon"
            icon="tally_import"
            :has-fill="true"/>
          <span class="menu-title-name">TALLY - LOADING</span>
        </div>
        <div class="col-md-2">
          <svg-icon
            class="nc-icon-page"
            icon="down_arrow"
            :has-fill="true"
            v-show="openMenuExport"/>
          <svg-icon
            class="nc-icon-page"
            icon="right_arrow"
            :has-fill="true"
            v-show="!openMenuExport"/>
        </div>
      </div>
      <transition name="list">
        <div
          v-if="openMenuExport"
          class="menu-value">
          <sidebar-link :to="{ name: 'loadingNotification'}"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Notifications</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'loadingTally'}"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Tally Sheets</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'loadingTallyReports' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Reports</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'loadingTallyCharges' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Charges</p>
          </sidebar-link>
        </div>
      </transition>

      <div
        class="menu-title row"
        @click="toggleMenu('im')"
        title="Shipping Tallying - Import"
        v-if="finalUserPermissions.includes('ST_IMPORT') ">
        <div class="col-md-10">
          <svg-icon
            class="nc-icon"
            icon="tally_import"
            :has-fill="true"/>
          <span class="menu-title-name">TALLY - DISCHARGING</span>
        </div>
        <div class="col-md-2">
          <svg-icon
            class="nc-icon-page"
            icon="down_arrow"
            :has-fill="true"
            v-show="openMenuImport"/>
          <svg-icon
            class="nc-icon-page"
            icon="right_arrow"
            :has-fill="true"
            v-show="!openMenuImport"/>
        </div>
      </div>
      <transition name="list">
        <div
          v-if="openMenuImport"
          class="menu-value">
          <sidebar-link :to="{ name: 'dischargeNotification' }"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Notifications</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'dischargeTally' }"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Tally Sheets</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'dischargeTallyReports' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Reports</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'dischargeTallyCharges' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Charges</p>
          </sidebar-link>
        </div>
      </transition>

      <div
        class="menu-title row"
        @click="toggleMenu('st')"
        title="Shipping Tallying - Import"
        v-if="finalUserPermissions.includes('ST_EXPORT') ">
        <div class="col-md-10">
          <svg-icon
            class="nc-icon"
            icon="tally_import"
            :has-fill="true"/>
          <span class="menu-title-name">TALLY - STUFFING</span>
        </div>
        <div class="col-md-2">
          <svg-icon
            class="nc-icon-page"
            icon="down_arrow"
            :has-fill="true"
            v-show="openMenuStuffing"/>
          <svg-icon
            class="nc-icon-page"
            icon="right_arrow"
            :has-fill="true"
            v-show="!openMenuStuffing"/>
        </div>
      </div>
      <transition name="list">
        <div
          v-if="openMenuStuffing"
          class="menu-value">
          <sidebar-link :to="{ name: 'stuffingNotification' }"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Notifications</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'stuffingTally' }"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Tally Sheets</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'stuffingTallyReports' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Reports</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'stuffingTallyCharges' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Charges</p>
          </sidebar-link>
        </div>
      </transition>

      <div
        class="menu-title row"
        @click="toggleMenu('dest')"
        title="Shipping Tallying - Import"
        v-if="finalUserPermissions.includes('ST_EXPORT') ">
        <div class="col-md-10">
          <svg-icon
            class="nc-icon"
            icon="tally_import"
            :has-fill="true"/>
          <span class="menu-title-name">TALLY - DE-STUFFING</span>
        </div>
        <div class="col-md-2">
          <svg-icon
            class="nc-icon-page"
            icon="down_arrow"
            :has-fill="true"
            v-show="openMenuDeStuffing"/>
          <svg-icon
            class="nc-icon-page"
            icon="right_arrow"
            :has-fill="true"
            v-show="!openMenuDeStuffing"/>
        </div>
      </div>
      <transition name="list">
        <div
          v-if="openMenuDeStuffing"
          class="menu-value">
          <sidebar-link :to="{ name: 'de-stuffingNotification' }"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Notifications</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'de-stuffingTally' }"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Tally Sheets</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'de-stuffingTallyReports' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Reports</p>
          </sidebar-link>
          <sidebar-link :to="{ name: 'de-stuffingTallyCharges' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">
            <svg-icon
              class="nc-icon-inner"
              icon="circle"
              :has-fill="true"
            />
            <p>Charges</p>
          </sidebar-link>
        </div>
      </transition>


      <!--<div-->
        <!--class="menu-title row"-->
        <!--@click="toggleMenu('im')"-->
        <!--title="Shipping Tallying - Import"-->
        <!--v-if="finalUserPermissions.includes('ST_IMPORT') ">-->
        <!--<div class="col-md-10">-->
          <!--<svg-icon-->
            <!--class="nc-icon"-->
            <!--icon="tally_import"-->
            <!--:has-fill="true"/>-->
          <!--<span class="menu-title-name">TALLYING - IMPORT</span>-->
        <!--</div>-->
        <!--<div class="col-md-2">-->
          <!--<svg-icon-->
            <!--class="nc-icon-page"-->
            <!--icon="down_arrow"-->
            <!--:has-fill="true"-->
            <!--v-show="openMenuImport"/>-->
          <!--<svg-icon-->
            <!--class="nc-icon-page"-->
            <!--icon="right_arrow"-->
            <!--:has-fill="true"-->
            <!--v-show="!openMenuImport"/>-->
        <!--</div>-->
      <!--</div>-->

      <!--<transition name="list">-->
        <!--<div-->
          <!--v-if="openMenuImport"-->
          <!--class="menu-value">-->
          <!--&lt;!&ndash;<sidebar-link :to="{ name: 'importApprovedManifest' }">&ndash;&gt;-->
            <!--&lt;!&ndash;<svg-icon&ndash;&gt;-->
              <!--&lt;!&ndash;class="nc-icon-inner"&ndash;&gt;-->
              <!--&lt;!&ndash;icon="circle"&ndash;&gt;-->
              <!--&lt;!&ndash;:has-fill="true"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;<p>Approved Manifest</p>&ndash;&gt;-->
          <!--&lt;!&ndash;</sidebar-link>&ndash;&gt;-->
          <!--&lt;!&ndash;<sidebar-link :to="{ name: 'importApprovedDischargeList' }">&ndash;&gt;-->
            <!--&lt;!&ndash;<svg-icon&ndash;&gt;-->
              <!--&lt;!&ndash;class="nc-icon-inner"&ndash;&gt;-->
              <!--&lt;!&ndash;icon="circle"&ndash;&gt;-->
              <!--&lt;!&ndash;:has-fill="true"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;<p>Approved Discharge List</p>&ndash;&gt;-->
          <!--&lt;!&ndash;</sidebar-link>&ndash;&gt;-->
          <!--<sidebar-link :to="{ name: 'dischargeTally' }"  v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">-->
            <!--<svg-icon-->
              <!--class="nc-icon-inner"-->
              <!--icon="circle"-->
              <!--:has-fill="true"-->
            <!--/>-->
            <!--<p>Discharge Tally Sheet</p>-->
          <!--</sidebar-link>-->
          <!--<sidebar-link :to="{ name: 'destuffingTally' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">-->
            <!--<svg-icon-->
              <!--class="nc-icon-inner"-->
              <!--icon="circle"-->
              <!--:has-fill="true"-->
            <!--/>-->
            <!--<p>Destuffing Tally Sheet</p>-->
          <!--</sidebar-link>-->
<!--&lt;!&ndash;          <sidebar-link :to="{ name: 'destuffing' }" v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">&ndash;&gt;-->
<!--&lt;!&ndash;            <svg-icon&ndash;&gt;-->
<!--&lt;!&ndash;              class="nc-icon-inner"&ndash;&gt;-->
<!--&lt;!&ndash;              icon="circle"&ndash;&gt;-->
<!--&lt;!&ndash;              :has-fill="true"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--&lt;!&ndash;            <p>Destuffed Containers</p>&ndash;&gt;-->
<!--&lt;!&ndash;          </sidebar-link>&ndash;&gt;-->
        <!--</div>-->
      <!--</transition>-->

      <!--<div-->
        <!--class="menu-title row"-->
        <!--@click="toggleMenu('ex')"-->
        <!--title="Shipping Tallying - Export"-->
        <!--v-if="finalUserPermissions.includes('ST_EXPORT')">-->
        <!--<div class="col-md-10">-->
          <!--<svg-icon-->
            <!--class="nc-icon"-->
            <!--icon="tally_export"-->
            <!--:has-fill="true"-->
          <!--/>-->
          <!--<span class="menu-title-name">TALLYING - EXPORT</span>-->
        <!--</div>-->
        <!--<div class="col-md-2">-->
          <!--<svg-icon-->
            <!--class="nc-icon-page"-->
            <!--icon="down_arrow"-->
            <!--:has-fill="true"-->
            <!--v-show="openMenuExport"-->
          <!--/>-->
          <!--<svg-icon-->
            <!--class="nc-icon-page"-->
            <!--icon="right_arrow"-->
            <!--:has-fill="true"-->
            <!--v-show="!openMenuExport"-->
          <!--/>-->
        <!--</div>-->
      <!--</div>-->

      <!--<transition name="list">-->
        <!--<div-->
          <!--v-if="openMenuExport"-->
          <!--class="menu-value"-->
        <!--&gt;-->
          <!--&lt;!&ndash;<sidebar-link :to="{ name: 'exportApprovedLoadingList' }">&ndash;&gt;-->
            <!--&lt;!&ndash;<svg-icon&ndash;&gt;-->
              <!--&lt;!&ndash;class="nc-icon-inner"&ndash;&gt;-->
              <!--&lt;!&ndash;icon="circle"&ndash;&gt;-->
              <!--&lt;!&ndash;:has-fill="true"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;<p>Approved Loading List</p>&ndash;&gt;-->
          <!--&lt;!&ndash;</sidebar-link>&ndash;&gt;-->
          <!--<sidebar-link :to="{ name: 'loadingTally' }" v-if="finalUserPermissions.includes('API_TALLYING-DOCUMENTS_ALL_PAGEABLE_{OPERATIONTYPE}_GET')">-->
            <!--<svg-icon-->
              <!--class="nc-icon-inner"-->
              <!--icon="circle"-->
              <!--:has-fill="true"-->
            <!--/>-->
            <!--<p>Loading Tally Sheet</p>-->
          <!--</sidebar-link>-->
          <!--<sidebar-link :to="{ name: 'stuffingTally' }"  v-if="finalUserPermissions.includes('API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET')">-->
            <!--<svg-icon-->
              <!--class="nc-icon-inner"-->
              <!--icon="circle"-->
              <!--:has-fill="true"-->
            <!--/>-->
            <!--<p>Stuffing Tally Sheet</p>-->
          <!--</sidebar-link>-->
        <!--</div>-->
      <!--</transition>-->
    <!--</div>-->
    <!--<div-->
      <!--class="menu-title row"-->
      <!--v-if="finalUserPermissions.includes('ST_OPERATIONS')"-->
      <!--@click="toggleMenu('nt')"-->
    <!--&gt;-->
      <!--<div class="col-md-10">-->
        <!--<svg-icon-->
          <!--class="nc-icon"-->
          <!--icon="tally_export"-->
          <!--:has-fill="true"-->
        <!--/>-->
        <!--<span class="menu-title-name">TALLYING - NOTIFICATION</span>-->
      <!--</div>-->
      <!--<div class="col-md-2">-->
        <!--<svg-icon-->
          <!--class="nc-icon-page"-->
          <!--icon="down_arrow"-->
          <!--:has-fill="true"-->
          <!--v-show="openNotificationMenu"-->
        <!--/>-->
        <!--<svg-icon-->
          <!--class="nc-icon-page"-->
          <!--icon="right_arrow"-->
          <!--:has-fill="true"-->
          <!--v-show="!openNotificationMenu"-->
        <!--/>-->
      <!--</div>-->
    <!--</div>-->
    <!--<transition name="list">-->
      <!--<div v-if="openNotificationMenu" class="menu-value">-->
        <!--<sidebar-link :to="{ name: 'tallyingNotificationView' }" v-if="finalUserPermissions.includes('API_TALLY-NOTIFICATIONS_ALL_PAGEABLE_GET', 'API_TALLY-NOTIFICATIONS_POST')">-->
          <!--<svg-icon-->
            <!--class="nc-icon-inner"-->
            <!--icon="circle"-->
            <!--:has-fill="true"/>-->
          <!--<p>Tally Notifications</p>-->
        <!--</sidebar-link>-->
      <!--</div>-->
    <!--</transition>-->

    <div class="menu-title row" @click="toggleMenu('mr')" v-if="finalUserPermissions.includes('ST_CONFIGURATIONS')">
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="tally_export"
          :has-fill="true"/>
        <span class="menu-title-name">TALLYING - RESOURCES</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenuResources"/>
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenuResources"/>
      </div>
    </div>
    <transition name="list">
      <div v-if="openMenuResources" class="menu-value">
        <sidebar-link :to="{ name: 'resourceCreate' }" v-if="finalUserPermissions.includes('API_TALLY-RESOURCES_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"/>
          <p>Resources</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'resourceClerk' }" v-if="finalUserPermissions.includes('API_TALLY-CLERK-DEVICES_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"/>
          <p>Clerk</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'getresourceClerk' }" v-if="finalUserPermissions.includes('API_TALLY-CLERK-DEVICES_GET')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"/>
          <p>Clerk Resources</p>
        </sidebar-link>
      </div>
    </transition>

    <div class="menu-title row" @click="toggleMenu('ms')" v-if="finalUserPermissions.includes('ST_CONFIGURATIONS')">
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="tally_export"
          :has-fill="true"/>
        <span class="menu-title-name">TALLYING - SHIFTS</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenuShifts"/>
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenuShifts"/>
      </div>
    </div>
    <transition name="list">
      <div v-if="openMenuShifts" class="menu-value">
        <sidebar-link :to="{ name: 'shiftConfiguration' }" v-if="finalUserPermissions.includes('API_TALLY-OPERATION-SHIFTS_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"/>
          <p>Create Tally Shift</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'shiftClerk' }" v-if="finalUserPermissions.includes('API_TALLY-CLERK-SHIFTS_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"/>
          <p>Shifts Clerk</p>
        </sidebar-link>
<!--        <sidebar-link :to="{ name: 'tallyAssignments' }" v-if="finalUserPermissions.includes('API_TALLY-CLERK-ASSIGNMENTS_ALL_PAGEABLE_GET')">-->
<!--          <svg-icon-->
<!--            class="nc-icon-inner"-->
<!--            icon="circle"-->
<!--            :has-fill="true"/>-->
<!--          <p>Assignments</p>-->
<!--        </sidebar-link>-->
        <sidebar-link :to="{ name: 'shiftView' }" v-if="finalUserPermissions.includes('API_TALLY-CLERK-SHIFTS_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"/>
          <p>View Shifts</p>
        </sidebar-link>
      </div>
    </transition>
  </div>
  </div>
</template>

<script>

import SvgIcon from '../../components/SvgLoader/svgLoader'

export default {

  name: 'ShippingTallying',

  data () {
    return {

      finalUserPermissions: [],
      openMenuExport: false,
      openMenuImport: false,
      selectedTallyType: 'LOADING',
      openMenuResources: false,
      openMenuShifts: false,
      openAssignmentsMenu: false,
      openNotificationMenu: false,
      openMenuStuffing: false,
      openMenuDeStuffing: false
    }
  },

  components: {
    SvgIcon
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    this.openMenuImport = typeof (localStorage.getItem('st-import-side-menu')) ? JSON.parse(localStorage.getItem('st-import-side-menu')) : false;
    this.openMenuExport = typeof (localStorage.getItem('st-export-side-menu')) ? JSON.parse(localStorage.getItem('st-export-side-menu')) : false;

    this.openMenuResources = typeof (localStorage.getItem('mr-side-menu')) ? JSON.parse(localStorage.getItem('mr-side-menu')) : false;
    this.openMenuShifts = typeof (localStorage.getItem('ms-side-menu')) ? JSON.parse(localStorage.getItem('ms-side-menu')) : false;

    this.openAssignmentsMenu = typeof (localStorage.getItem('as-side-menu')) ? JSON.parse(localStorage.getItem('as-side-menu')) : false;
    this.openNotificationMenu = typeof (localStorage.getItem('nt-side-menu')) ? JSON.parse(localStorage.getItem('nt-side-menu')) : false
  },

  methods: {

    toggleMenu (type) {

      if (type === 'im') {
        this.openMenuImport = !this.openMenuImport;
        localStorage.setItem('st-import-side-menu', this.openMenuImport)
      }

      if (type === 'st') {
        this.openMenuStuffing = !this.openMenuStuffing;
        localStorage.setItem('st-import-side-menu', this.openMenuImport)
      }

      if (type === 'dest') {
        this.openMenuDeStuffing = !this.openMenuDeStuffing;
        localStorage.setItem('st-import-side-menu', this.openMenuImport)
      }

      if (type === 'ex') {
        this.openMenuExport = !this.openMenuExport;
        localStorage.setItem('st-export-side-menu', this.openMenuExport)
      }
      if (type === 'mr') {
        this.openMenuResources = !this.openMenuResources;
        localStorage.setItem('mr-side-menu', this.openMenuResources)
      }

      if (type === 'ms') {
        this.openMenuShifts = !this.openMenuShifts;
        localStorage.setItem('ms-side-menu', this.openMenuShifts)
      }

      if (type === 'as') {
        this.openAssignmentsMenu = !this.openAssignmentsMenu;
        localStorage.setItem('as-side-menu', this.openAssignmentsMenu)
      }

      if (type === 'nt') {
        this.openNotificationMenu = !this.openNotificationMenu;
        localStorage.setItem('nt-side-menu', this.openNotificationMenu)
      }
    }

  }

}
</script>

<style scoped>

    .menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 13px;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    .list-enter-active, .list-leave-active {
        transition: all .7s;
    }

    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }

    .nc-icon {
        padding-right: 8px;
        padding-top: 4px;
        font-size: 30px !important;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        padding-left: 10px;
        font-weight: normal !important;
    }

    .sidebar .nav li .nav-link:hover .nc-icon-inner, body > .navbar-collapse .nav li .nav-link:hover .nc-icon-inner {
        fill: #d59a18;
    }

    .menu-title:hover .nc-icon, .menu-title:hover .nc-icon-page {
        fill: #d59a18;
    }

    .nc-icon {
        position: absolute;
        top: -10px;
        left: 15px;
        font-size: 25px !important;
        fill: #FFFFFF;
    }

    .nc-icon-inner {
        font-size: 10px !important;
        padding-left: 30px;
        fill: #FFFFFF;
    }

    .nc-icon-page {
        font-size: 10px !important;
        fill: #FFFFFF;
    }

</style>
