<template>
    <div>
        <card>
        <data-table
            :tableHeaders="tableHeaders"
            :tableData="filteredRequests"
            :tableDataKeys="tableDataKeys"
            :pageSizeProp="pageSize"
            :pageNo="pageNo"
            :myLoader="myLoader"
            :myFirstLoader="myFirstLoader"
            :noDataFound="noDataFound"
            :stations="stations"
            :searchParameterProp="searchParameter"
            :activeTariffItems="activeTariffItems"
            :applicationCurrentStatus="'all'"
            :loadingTitle="'Please wait while system is loading your applications'"
            :hasManageButton="false"
            :coloredStatus="true"
            :totalElements="totalElements"
            :totalPages="totalPages"
            :isFirst="isFirst"
            :isLast="isLast"
            :getRegimes="regimes"
            :applicationStatusesProp="applicationStatuses"
            :pageNumber="pageNumber"
            :buttonList="[ {'code' : 'BillGenerate' , 'name' : 'More Details' , 'class' : 'btn-primary'} ]"
            :whichModuleAndType="whichModuleAndType"
            :is-hovered="isHovered"
            :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'} ]"
            @filterWithMultipleParameters="searchGeneralChargeParams"
            @resetParams="resetParams"
            @showToolTip="showToolTip"
            @hideToolTip="hideToolTip"
            @onChange="onChange"
            @goToPage="goToPage"
            @goToBillGenerate="goToApplication"
        >
        <div slot = "pagination">
            <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
            </paginate>
        </div>
        </data-table>
        </card>
        <global-modal v-if="myLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                <div class="col-md-12">
                    Loading your applications, Please wait
                </div>
                <div class="col-md-12">
                    <br>
                </div>
                <div class="col-md-12">
                    <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                    />
                </div>
                </div>
            </div>
        </global-modal>
    </div>
</template>

<script>

    import {mapGetters, mapActions} from "vuex";
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import GlobalModal from '../../../components/globalLoader'
    import DataTable from '../../../components/DataTable/dataTable'
    import {CFA_REQUEST_STATUSE, CFA_REQUEST_STATUSES} from '../../../mixins/constants'
    // import ApplicationDetailsComponent from './application.vue'

    export default {

    name: 'MyApplicationList',

    data () {

        return {

        finalUserPermissions: [],

        color: 'green',
        size: '13px',
        loading: true,

        requests: [],
        filteredRequests: [],
        activeTariffItems: [],
        stations: [],
        regimes: [],
        noDataFound: false,
        reloadAllRequest: false,
        hasSearchedVariable: false,
        searchParameter: '',
        applicationStatuses: CFA_REQUEST_STATUSES,
        pageNo: 0,
        pageSize: 15,
        myLoader: false,
        isHovered: [],
        myFirstLoader: false,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        whichModuleAndType: 'cfaCustomerApplication',
        newParams: '',
        isPage: 0,
        pageNoPart: 0,
        requestId: '',
        loadedParams: {},

        tableHeaders: ['BL/Airway Bill/RCN', 'File Number', 'Request Type', 'Customer Name', 'Status Date', 'Status'],
        tableDataKeys: ['blNumber', 'fileNo', 'requestType', 'customer', 'requestDate', 'status'],

        }

    },

    components: {

        PulseLoader,
        GlobalModal,
        DataTable

    },

    created () {

        this.loadRequest()
        this.loadAllRegimes()
        this.loadAllActiveTariffItems()
        this.loadAllStations()
        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.$store.dispatch('pagenames/currentPageName', 'My Applications')

    },

    computed: {

        ...mapGetters('clearingrequests', [

            'getRequestList',
            'getPageCount',
            'getMyFirstLoader',
            'getNoDataFound',
            'getReloadAllRequest',
            'getMyLoader',
            'getSearchParameter',
            'getHasSearchedVariable',
            'getPageNo',
            'getPageSize',
            'getTotalElement',
            'getTotalPage',
            'getPageNumber',
            'getIsFirst',
            'getIsLast',

        ]),

        ...mapGetters('global' , [

            'getAllRegimes',
            'getActiveTariffItems',
            'getAllStations'

        ]),


        getIsPage: {

        get: function () {
            return this.isPage
        },
        set: function (newValue) {
            this.isPage = newValue;
        }

        }

    },

    methods: {

        ...mapActions("global", [

            'loadRegimes',
            'loadActiveTariffItems',
            'loadStations'

        ]),

        ...mapActions('clearingrequests', [

            'loadClearingAndForwardingApplicationsOnCreated',
            'searchWithGeneralChargeParams',
            'loadClearingAndForwardingApplications'

        ]),

        loadAllActiveTariffItems() {

            this.loadActiveTariffItems({}).then(() => {

                this.activeTariffItems = this.getActiveTariffItems;

            }).catch(() => {})

        },

        loadAllStations() {

            this.loadStations({}).then(() => {

                this.stations = this.getAllStations;

            }).catch(() => {})

        },

        loadAllRegimes() {

            this.loadRegimes({}).then(() => {

                this.regimes = this.getAllRegimes;
            }).catch(() => {


            });

        },

        resetParams () {

            this.loadRequest()
            this.$store.dispatch("clearingrequests/hasSearchngParams", false)
            this.pageNoPart = 0
            this.isPage = this.pageNoPart + 1
            this.filteredRequests = []

        },

        loadRequest() {

            this.$store.dispatch('pagenames/currentPageName', 'My applications');

            if (localStorage.getItem("search-params") !== null) {

                this.loadedParams = {};
                this.loadedParams = JSON.parse(localStorage.getItem("search-params"));

                this.pageSize = this.loadedParams.pageSize
                this.pageNo = this.loadedParams.pageNo
                this.pageNoPart = this.pageNo
                this.isPage = this.pageNo + 1

                this.searchGeneralChargeParams(this.loadedParams, this.isPage)

            } else {

                // let applicationStatuses
                // let incomingStatus = this.applicationStatusAndUrlParamMapper(this.$route.params.status)

                // console.log('this.$route.params.status', this.$route.params.status)
                
                // if (this.$route.params.status === 'my') {

                //     applicationStatuses = null

                // } else {

                //     applicationStatuses = [incomingStatus]

                // }

                this.loadedParams = {

                    applicationStatuses: null,
                    assigmentUserId: null,
                    blNumber: null,
                    customerIds: null,
                    dismissCase: null,
                    endDate: null,
                    fileNo: null,
                    regimeIds: null,
                    requestType: null,
                    startDate: null,
                    stationIds: null,
                    tansadNumber: null,
                    tariffItem: null

                }

                // console.log('this.loadedParams', this.loadedParams)

                this.loadPageableRequestOnCreated(this.loadedParams , 0);
                this.isPage = this.pageNo + 1;
                this.$store.dispatch("clearingrequests/hasSearchngParams", false);
                this.pageNoPart = 0;

            }

                this.filteredRequests = []

        },

        loadCommonValues (status) {

            let applicationStatus = []

            if(this.$route.params.status === status) {

            applicationStatus = null

            } else {

                let myStatus = this.applicationStatusAndUrlParamMapper(this.$route.params.status)
                applicationStatus.push(myStatus)

            }

            return applicationStatus

        },

        loadSearchParameters(params, fromApplicationPageNo) {

            const NewParams = { ...params, pageNo: this.pageNo, pageSize: this.pageSize }
            let fromApplicationPageNoOrNot;
            typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0
            this.isPage = fromApplicationPageNo;
            this.loadedParams = {}
            this.loadedParams = NewParams

            return new Promise((resolve) => {

            this.$store.dispatch("clearingrequests/pageSizing", this.pageSize)
            this.$store.dispatch("clearingrequests/pageNumbering", this.pageNo)
            this.$store.dispatch("clearingrequests/paramsSearching", this.loadedParams);

            resolve()

            })

        },

        sortingData(data) {

            for(let x = 0 ; x < data.length ; x++) {

                // let status = ''

                // if(data[x].status.includes("Request")) {

                //     if((data[x].status.replace('Request', '')).trim().includes('is ')) {

                //         status = ((data[x].status.replace('Request', '')).trim().replace('is ', '')).trim()

                //     } else {

                //         status = (data[x].status.replace('Request', '')).trim()

                //     }

                // } else {

                    status = data[x].status

                // }

                let requestObject = {

                    ...data[x],
                    requestDate: this.dateTimeToHuman(data[x].lastModifiedDate),
                    requestType: data[x].requestType === 'IMP' ? 'IMPORT' : 'EXPORT',
                    status: status

                }

                this.filteredRequests.push(requestObject)

            }

        },

        setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;
        this.SuccessMessage = this.getSuccessMessage;
        this.ErrorMessage = this.getErrorMessage;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessAlert = this.getSuccessAlert;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

        this.sortingData(this.requests)

        },

        searchGeneralChargeParams(params, fromApplicationPageNo) {

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.searchWithGeneralChargeParams({}).then(() => {

                this.setAllGetters('onCreated')

                }).catch(() => {

                    this.myFirstLoader = false;

                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {

                this.myFirstLoader = false;

            });

        },

        loadPageableRequestOnCreated(params, fromApplicationPageNo) {

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.loadClearingAndForwardingApplicationsOnCreated({}).then(() => {

                this.setAllGetters('onCreated');

            }).catch(() => {

                this.myFirstLoader = false;

            });

            this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {

                this.myFirstLoader = false;

            });

        },

        loadPageableRequestsAfterCreated(params, fromApplicationPageNo) {

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.loadClearingAndForwardingApplications({}).then(() => {

                this.setAllGetters('afterCreated');

                }).catch(() => {

                    this.myFirstLoader = false;

                });

                this.myLoader = this.getMyLoader;

            }).catch(() => {

                this.myLoader = false;

            });

        },

        onChange (event) {

            this.$store.dispatch("clearingrequests/pageSizing", event);
            this.$store.dispatch("clearingrequests/pageNumbering", 0);
            this.isPage = 0

            this.pageSize = Number(this.getPageSize);
            this.pageNo = this.getPageNo;
            this.pageNoPart = 0
            this.loadPageableRequestOnCreated(this.loadedParams, 0);
            this.filteredRequests = []

        },

        goToPage(page) {

            if(page > 0) {page = page - 1;}
            this.isPage = page + 1

            this.pageNo = page;
            this.pageNoPart = this.isPage
            this.loadPageableRequestsAfterCreated(this.loadedParams, this.pageNoPart);
            this.filteredRequests = []

        },

        showToolTip (id) {

        this.isHovered = [id]

        },

        hideToolTip (id) {

        this.isHovered.splice(this.isHovered.indexOf(id), 1)

        },

        goToApplication (object) {

            document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
            this.$router.push({name: 'CustomerApplicationDetails', params: {requestId: object.requestId}})

            this.requestId = object.requestId

            if(this.loadedParams.regimeIds !== null) {

            localStorage.setItem("search-params-rlist", JSON.stringify(this.getFilteredRegimes));

            }
            if(this.loadedParams.stationIds !== null) {

            localStorage.setItem("search-params-slist", JSON.stringify(this.stations));

            }

            if(this.loadedParams.applicationStatuses !== null) {

            localStorage.setItem("search-params-alist", JSON.stringify(this.applicationStatuses));

            }

            if(this.loadedParams.tariffItem !== null) {

            localStorage.setItem("search-params-tlist", JSON.stringify(this.activeTariffItems));

            }

            localStorage.setItem("search-params", JSON.stringify(this.loadedParams));

        }

    },

    destroyed () {

        if( this.$route.fullPath !== '/clearing-and-forwarding/my-application-list' && this.$route.fullPath !== '/clearing-and-forwarding/application-details/' + this.requestId) {

            localStorage.removeItem("search-params")
            localStorage.removeItem('search-params-rlist')
            localStorage.removeItem('search-params-slist')
            localStorage.removeItem('search-params-alist')
            localStorage.removeItem('search-params-tlist')

        }

    }

}
</script>

<style scoped>

    .btn-space {
        margin-right: 10px !important;
    }

    .btn-success-custom {
        padding: 0 4px 0 4px;
        color: #3cb371;
    }

    .btn-success-custom:hover {
        cursor: pointer;
        color: green;
    }

    .table-striped tr {
        line-height: 20px;
    }

    .disabled-click {
        pointer-events:none;
        color:#dcdcdc;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .enabled-click {
        pointer-events:auto;
    }

    .search .form-group .input-group .input-group-addon {
        width: 40px;
        text-align: center;
        padding-top: 3.5px;
        background-color: rgba(220, 220, 220, .3);
    }

    .disable-highlight:hover span {
        cursor: pointer;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .refresh-all {
        margin-top: 6px;
        margin-right: 20px
    }

    .refresh-all:hover {
        cursor: pointer;
    }

    .search .form-group .input-group #inputGroup {
        width: 250px;
        padding-top: 3.5px;
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        background-color: #FFFFFF !important;
    }

    .table td , .table th {
        text-align: center;
    }

    .text-center {
        text-align: center !important;
    }

    .table-hover tbody tr:hover {
        background-color: rgba(153, 186, 221, .5) !important;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .more-customer-details:hover {
        cursor: pointer;
        color: #d59a18;
    }

    .close-info {
        color: blue !important;
    }

    .close-info:hover {
        cursor: pointer;
        color: green !important;
    }

</style>
