<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="text-left">
            Port List
          </div>
        </div>
        <div class="col-md-6 text-right">
          <span
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('PORT_CONFIG_POST')"
            @click.prevent="openModal(null , null , null, null ,null, actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
          <!-- <div class="fileUpload btn btn-info btn-fill">
            <i class="nc-icon nc-cloud-upload-94"></i><span> Upload Excel..</span>
            <form method="post" enctype="multipart/form-data">
              <input type="file" name = "attachment"  label="Upload File" class="upload" @change.prevent="uploadFile"/>
            </form>
          </div> -->
        </div>
      </div>
      <hr>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Port Name
            </th>
            <th scope="col">
              Port Code
            </th>
            <th scope="col">
              Port Address
            </th>
            <th scope="col">
              City
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getPort"
            :key="list.portId"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.portName }}
            </td><td>
              {{ list.portCode }}
            </td>
            <td>
              {{ list.portAddress }}
            </td>

            <td>
              {{ list.city.cityName }}
            </td>

            <td class="special-limit">
              <div
                class="all-fa-icons fa-icons-space edit-fa-icon"
                v-if="finalUserPermissions.includes('PORT_CONFIG_PUT')"
                @click.prevent="openModal(list.portId , list.portName , list.cityId ,list.portCode, list.portAddress,
                                          actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>

              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('PORT_CONFIG_{PORTID}_GET')"
                @click.prevent="openModal(list.portId , null , null , null, null, actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>

              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                v-if="finalUserPermissions.includes('PORT_CONFIG_{PORTID}_DELETE')"
                @click.prevent="deletePort(list.portId , list.portName)"
                title="delete"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                Edit Port <b>{{ portName }} ({{ portCode }})</b>
              </div>
              <div
                class="col-md-6 text-right"
                v-if="cityLoder"
              >
                <div class="row">
                  <div class="col-md-6 text-right">
                    Loading cities, please wait
                  </div>
                  <div class="col-md-6 text-right">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                Register Port
              </div>
              <div
                class="col-md-6"
                v-if="cityLoder"
              >
                <div class="row">
                  <div class="col-md-6">
                    Loading cities, please wait
                  </div>
                  <div class="col-md-6">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Port: <b>{{ data.portName }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="UploadModalDiv"
          >
            Upload Excel for Port
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editPort"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <label>Search city name</label>
                <model-list-select
                  :list="getCity"
                  v-model="cityId"
                  class="form-control"
                  option-value="cityId"
                  option-text="cityName"
                  placeholder="Search city"
                />
                <div v-if="cityIdError">
                  <div
                    class="error"
                    v-if="!$v.cityId.required"
                  >
                    Select city
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Port Name"
                  placeholder="Port Name"
                  v-model="portName"
                  value="portName"
                  class="special-bottom"
                />
                <div v-if="portNameError">
                  <div
                    class="error"
                    v-if="!$v.portName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Port Code"
                  placeholder="Port Code"
                  v-model="portCode"
                  value="portCode"
                  class="special-bottom"
                />
                <div v-if="portCodeError">
                  <div
                    class="error"
                    v-if="!$v.portCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Port Address"
                  placeholder="Port Address"
                  v-model="portAddress"
                  value="portAddress"
                  class="special-bottom"
                />
                <div v-if="portAddressError">
                  <div
                    class="error"
                    v-if="!$v.portAddress.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->

          <form
            method="POST"
            @submit.prevent="savePort"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <label>Search City name</label>
                <model-list-select
                  :list="getCity"
                  v-model="port.cityId"
                  class="form-control"
                  option-value="cityId"
                  option-text="cityName"
                  placeholder="Search City name"
                />
                <div v-if="cityIdError">
                  <div
                    class="error"
                    v-if="!$v.port.cityId.required"
                  >
                    Select city
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Port Name"
                  placeholder="Port Name"
                  v-model="port.portName"
                  class="special-bottom"
                />
                <div v-if="portNameError">
                  <div
                    class="error"
                    v-if="!$v.port.portName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Port Code"
                  placeholder="Port Code"
                  v-model="port.portCode"
                  class="special-bottom"
                />
                <div v-if="portCodeError">
                  <div
                    class="error"
                    v-if="!$v.port.portCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <textarea
                  class="special-bottom form-control col-md-12"
                  placeholder="Request Details"
                  rows="8"
                  type="textarea"
                  v-model="port.portAddress"
                />
                <div v-if="portAddressError">
                  <div
                    class="error"
                    v-if="!$v.port.portAddress.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--      SHOW FORM      -->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Port Name:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.portName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Port Code:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.portCode }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Port Address:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.portAddress }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6 text-bold">
                    Port City:
                  </div>
                  <div class="col-md-6 text-value">
                    {{ data.cityId }}
                  </div>
                </div>
              </div>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'

export default {

  name: 'PortList',

  components: {

    PulseLoader,
    Modal,
    ModelListSelect

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Ports List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPort()
  },

  computed: {

    ...mapGetters([

      'getPortNameBeforeEdit'

    ])

  },

  data () {
    return {

      array: [],
      getPort: [],
      getCity: [],
      getPortArray: [],
      finalUserPermissions: [],

      data: {},

      color: 'green',
      size: '15px',
      sizeData: '8px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',

      portName: '',
      portCode: '',
      portId: '',
      portAddress: '',
      cityId: '',

      error: '',

      fileData: '',
      file: '',

      port: {
        portName: '',
        portCode: '',
        portAddress: '',
        cityId: ''
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      UploadModalDiv: false,

      portNameError: false,
      portCodeError: false,
      portAddressError: false,
      cityIdError: false,
      cityLoder: false

    }
  },

  validations: {

    port: {
      portName: { required },
      portCode: { required },
      portAddress: { required },
      cityId: { required }
    },

    portName: { required },
    portCode: { required },
    portAddress: { required },
    cityId: { required }

  },

  methods: {

    loadPort () {
      this.myloader = true

      API.getPort().then(
        response => {
          if (response.data.status) {
            this.getPort = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadCity () { // for city select box
      this.cityLoder = true

      API.getCity().then(
        response => {
          if (response.data.status) {
            this.getCity = response.data.data
            this.cityLoder = false
          } else {
            this.cityLoder = false
          }
        }
      ).catch(e => {
        this.ErrorMessage = ', cities not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.cityLoder = false
      })
    },

    loadSinglePort (data) {
      API.getIndividualPort(data).then(
        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    savePort () {
      if (this.$v.port.cityId.$invalid || this.$v.port.portAddress.$invalid ||
          this.$v.port.portName.$invalid || this.$v.port.portCode.$invalid) {
        if (this.$v.port.city.cityId.$invalid) {
          this.cityIdError = true
        }
        if (this.$v.port.portName.$invalid) {
          this.portNameError = true
        }
        if (this.$v.port.portAddress.$invalid) {
          this.portAddressError = true
        }
        if (this.$v.port.portCode.$invalid) {
          this.portCodeError = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postPort(JSON.stringify(this.port)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = '"' + response.data.data.portName + '" port saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadPort()
              this.editMyLoader = false
              this.port.PortName = ''
              this.port.PortCode = ''
              this.port.Address = ''
              this.port.cityId = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Port not added ' + e
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.port.PortName = ''
          this.port.PortCode = ''
          this.port.PortAddress = ''
          this.port.cityId = ''
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deletePort (data, name) {
      // this.$store.dispatch("commitPortNameBeforeEdit" , name);

      this.$dialog.confirm('If you delete port [ ' + name + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API.deletePort(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ' Port[ ' + name + ' ] has been deleted'
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                window.scrollTo(10, 0)
                this.loadPort()
                this.$el.scrollTop = 0
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editPort () {
      if (this.$v.cityId.$invalid || this.$v.portAddress.$invalid ||
          this.$v.portName.$invalid || this.$v.portCode.$invalid) {
        if (this.$v.cityId.$invalid) {
          this.cityIdError = true
        }
        if (this.$v.portName.$invalid) {
          this.portNameError = true
        }
        if (this.$v.portAddress.$invalid) {
          this.portAddressError = true
        }
        if (this.$v.portCode.$invalid) {
          this.portCodeError = true
        }
      } else {
        this.editMyLoader = true

        this.array =
            {
              portName: this.portName,
              portCode: this.portCode,
              portAddress: this.portAddress,
              portId: this.portId,
              city: {
                cityId: this.cityId
              }
            }

        // console.log(JSON.stringify(this.array));

        // setTimeout(() => {

        API.updatePort(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              // this.SuccessMessage =  " " + this.countryName + " Record edited To " + response.data.data.countryName;
              this.SuccessMessage = ' Record edited'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              window.scrollTo(10, 0)
              this.closeModal()
              this.loadPort()
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (portId = null, portName = null, cityId = null, portCode = null, portAddress = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.ShowModalDiv = false
        this.UploadModalDiv = false
        this.portName = portName
        this.portCode = portCode
        this.portAddress = portAddress
        this.portId = portId
        this.cityId = cityId
        this.loadCity()
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.UploadModalDiv = false
        this.loadCity()
      } else {
        this.loadSinglePort(portId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
        this.UploadModalDiv = false
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.port.PortName = ''
      this.port.PortCode = ''
      this.port.PortAddress = ''
      this.port.cityId = ''

      this.getCity = []

      this.portNameError = false
      this.portCodeError = false
      this.portAddressError = false
      this.cityIdError = false
    },

    uploadFile (event) {
      this.fileData = event.target.files[0]
      this.file = this.fileData.name

      this.myloader = true

      const formData = new FormData()

      formData.append('file', this.fileData)

      API.uploadPortExcel(formData).then(
        response => {
          if (response.data.status) {
            response.data.data.map((value) => {
              this.getPortArray.push(value.portName)
            })

            this.SuccessAlert = true
            this.ErrorAlert = false
            this.SuccessMessage = ' Countries Added [ ' + this.getPortArray + ' ]'
            this.myloader = false
            this.loadPort()
            this.$el.scrollTop = 0
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not sent'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.50;
    text-decoration: none;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor: pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
