<template>
    <div :key="requestId">
        <!-- Reloads component from notitifcation list, do not delete -->
        {{ ketRerenderComponent }}
        <card style="font-size: 12px" v-if="!thisFirstRequestLoader && !permissionError" >
            <div class="row">
                <div class="col-md-12">
                <ul class="nav-justified">
                    <li v-if="singleRequest.status === 'Draft Request' && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT') && finalUserPermissions.includes('CFA_CUSTOMER')">
                        <button  @click.prevent="gotToAttachment" class="btn btn-purple btn-purple-fill mr-2 custom-btn-top">
                            Continue to mandatory attachment(s)
                        </button>
                    </li>
                    <li v-if="(singleRequest.status === 'Draft Request' || singleRequest.status === 'Request Returned For Major Changes'
                    || singleRequest.status === 'Request Returned For Minor Changes')  && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT') && finalUserPermissions.includes('CFA_CUSTOMER')">
                        <button @click.prevent="gotToApplication" class="btn btn-linen btn-linen-fill mr-2 custom-btn-top">
                            Edit application Details
                        </button>
                    </li>
                    <li v-if="singleRequest.status !== 'Draft Request' && singleRequest.status !== 'Request Returned For Major Changes' 
                    && singleRequest.status !== 'Request Returned For Minor Changes' && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT') && finalUserPermissions.includes('CFA_CUSTOMER')">
                        <button  @click.prevent="gotToAdditionalAttachment" class="btn btn-rosy-brown btn-rosy-brown-fill mr-2 custom-btn-top">
                            Additional attachment(s)
                        </button>
                    </li>
                    <li v-if="singleRequest.status === 'Request Returned For Major Changes' || singleRequest.status === 'Request Returned For Minor Changes'" class="float-right">
                        <button class="btn btn-primary btn-space custom-btn mr-2" @click.prevent="goToChatRoom" v-if="finalUserPermissions.includes('CFA_CUSTOMER')">
                            See Raised Issues
                        </button>
                    </li>
                </ul>
                </div>
            </div>
        </card>
        <card style="font-size: 12px">
            <div class="row" v-if="thisFirstRequestLoader">
                <div class="col-md-12 text-center loading-text-color">
                <b>Please wait while system is loading application details</b>
                </div>
                <div class="col-md-12 text-center">
                <br>
                </div>
                <div class="col-md-12 text-center">
                    <pulse-loader
                        :color="color"
                        :size="size"
                    />
                </div>
            </div>
            <div class="row" v-if="!thisFirstRequestLoader && permissionError">
                <div class="col-md-12 text-center text-danger">
                <b>{{ message }}</b>
                </div>
            </div>
            <div class="row" v-if="!thisFirstRequestLoader && !permissionError">
                <div class="col-md-12">
                    <div class="legend">
                        <h6 class="legend-title">
                        Application Details
                        </h6>

                        <div class="row div-table-row">
                            <div class="col-md-3 div-table div-table-title">
                                Current Application Stage / Status
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Submitted' "
                                style="color: #696969;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Draft Request' "
                                style="color: grey;font-weight: 900"
                            >
                                {{ applicationStatus }} &nbsp;&nbsp;&nbsp; <span class="text-danger">( This applications is not received by TASAC yet, please attach all required documents )</span>
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Accepted' "
                                style="color: #3498db;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Returned For Major Changes' "
                                style="color: #ff0000;font-weight: 900"
                            >
                                {{ applicationStatus }} &nbsp;&nbsp;&nbsp; <span class="text-danger">( This applications can not be processed by TASAC untill raised issues are solved, please attend all of them ) </span>
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Returned For Minor Changes' "
                                style="color: #ffd700;font-weight: 900"
                            >
                                {{ applicationStatus }} &nbsp;&nbsp;&nbsp; <span class="text-warning">( This applications can be still processed by TASAC untill a certain process, raised issue must be solved for its completion, please attend all of them )</span>
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Resubmitted After Changes' "
                                style="color: #2f4f4f;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Assessed' "
                                style="color: #D2691E;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Assessment Rejected' "
                                style="color: #808000;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Charged' "
                                style="color: #008080;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Billed' "
                                style="color: #8b4513;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Assessment Sent To GEPG' "
                                style="color: #800080;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Bill Paid' "
                                style="color: #008000;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Request Closed' "
                                style="color: #000000;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                            <div
                                class="col-md-9 div-table div-table-value"
                                v-if="singleRequest.status === 'Duplicate' "
                                style="color: #ff0000;font-weight: 900"
                            >
                                {{ applicationStatus }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-3 div-table div-table-title">
                                BL/Airway Bill/RCN
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ singleRequest.blNumber }}
                            </div>
                            <div class="col-md-3 div-table div-table-title">
                                Regime Type
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ typeof singleRequest.regime !== 'undefined'
                                && singleRequest.regime !== null ? singleRequest.regime.regimeName : '' }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-3 div-table div-table-title">
                                File Number
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ singleRequest.fileNo }}
                            </div>
                            <div class="col-md-3 div-table div-table-title">
                                Application Type
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ singleRequest.requestTypeName }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-3 div-table div-table-title">
                                Transportation method Name ( Code )
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ typeof singleRequest.transportMethod !== 'undefined' && singleRequest.transportMethod !== null ?
                                (singleRequest.transportMethod.transportMethodName).toUpperCase() + ' ( ' + singleRequest.transportMethod.transportMethodCode + ' )' : '' }}
                            </div>
                            <div class="col-md-3 div-table div-table-title">
                                Station Name ( Code )
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ typeof singleRequest.station !== 'undefined' && singleRequest.station !== null ?
                                (singleRequest.station.stationName).toUpperCase() + ' ( ' + singleRequest.station.stationCode + ' )' : '' }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-3 div-table div-table-title">
                                PO Number
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ singleRequest.poNumber }}
                            </div>
                            <div class="col-md-3 div-table div-table-title">
                                {{ singleRequest.requestType === 'IMP' ? 'Expected Time of Arrival (ETA)' : 'Expected Time of Departure (ETD)' }}
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ singleRequest.cargoExpectedDate !== null && singleRequest.cargoExpectedDate !== '' ? dateToHuman(singleRequest.cargoExpectedDate) : '' }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-3 div-table div-table-title">
                                Product Type
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ singleRequest.productType }}
                            </div>
                            <div class="col-md-3 div-table div-table-title">
                                Invoice Number
                            </div>
                            <div class="col-md-3 div-table div-table-value">
                                {{ singleRequest.invoiceNo }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-3 div-table div-table-title">
                                Application Description
                            </div>
                            <div class="col-md-9 div-table div-table-value">
                                {{ singleRequest.description.includes('\n') ? singleRequest.description.split('\n').join(', ') : singleRequest.description }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-12 div-table div-table-title"
                                style="border-bottom: 1px solid #a9a9a9">
                                <div class="row">
                                    <div class="text-center col-md-12 pr-5">
                                        Associated Attachment(s)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row div-table-row">
                            <div
                                class="col-md-1 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                #
                            </div>
                            <div
                                class="col-md-6 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Attachment Name
                            </div>
                            <div
                                class="col-md-1 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9"
                            >
                                View
                            </div>
                            <div
                                class="col-md-2 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Uploaded Date
                            </div>
                            <div
                                class="col-md-2 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Verification Status
                            </div>
                        </div>
                        <div class="row div-table-row" v-for="(attachment , index_attachment) in singleRequest.requestAttachDtos" :key="index_attachment">
                            <div class="col-md-1 div-table div-table-value text-center">
                                {{ index_attachment + 1 }}
                            </div>
                            <div class="col-md-6 div-table div-table-value">
                                {{ attachment.attachmentName }}
                            </div>
                            <div
                                class="col-md-1 div-table div-table-value text-center text-link"
                                @click.prevent="openPdfViewer(attachment.attachmentUrl , attachment.attachmentName, 'view')"
                            >
                                click to view
                            </div>
                            <div class="col-md-2 div-table div-table-value text-center">
                                {{ dateTimeToHuman(attachment.createdDate) }}
                            </div>
                            <div class="col-md-2 div-table div-table-value text-center text-danger" v-if="!attachment.verified">
                                Not Verified
                            </div>
                            <div class="col-md-2 div-table div-table-value text-center text-success" v-if="attachment.verified">
                                Verified
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-12 div-table div-table-title"
                                style="border-bottom: 1px solid #a9a9a9">
                                <div class="row">
                                    <div class="text-center col-md-12 pr-5">
                                        File Activites
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row div-table-row">
                            <div
                                class="col-md-1 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                #
                            </div>
                            <div
                                class="col-md-3 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Activity Name
                            </div>
                            <div
                                class="col-md-1 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Attachment
                            </div>
                            <div
                                class="col-md-2 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Status
                            </div>
                            <div
                                class="col-md-3 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                On-Hold Reason
                            </div>
                            <div
                                class="col-md-1 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Activity Date
                            </div>
                            <div
                                class="col-md-1 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Activity Updated Date
                            </div>
                        </div>
                        <div class="row div-table-row" v-for="(activity , index_activity) in singleRequest.activityLogs" :key="index_activity">
                            <div v-if="activity.status === 'On Hold' || activity.status === 'Completed'" class="col-md-12">
                                <div class="row">
                                    <div class="col-md-1 div-table div-table-value text-center">
                                        {{ index_activity + 1 }}
                                    </div>
                                    <div class="col-md-3 div-table div-table-value">
                                        {{ activity.activityName }}
                                    </div>
                                    <div v-if="activity.attachUrl !== null"
                                        class="col-md-1 div-table div-table-value text-center text-link"
                                        @click.prevent="openPdfViewer(activity.attachUrl , activity.activityName, 'view')">
                                        click to view
                                    </div>
                                    <div v-else
                                        class="col-md-1 div-table div-table-value text-center ">
                                        No attachment
                                    </div>
                                    <div
                                        class="col-md-2 div-table div-table-value text-center">
                                        {{ activity.status }}
                                    </div>
                                    <div v-if="activity.status === 'On Hold'"
                                        class="col-md-3 div-table div-table-value text-center">
                                        {{ activity.activityDescription }}
                                    </div>
                                    <div v-else
                                        class="col-md-3 div-table div-table-value text-center">
                                        
                                    </div>
                                    <div
                                        class="col-md-1 div-table div-table-value text-center">
                                        {{  dateTimeToHuman(activity.activityDate) }}
                                    </div>
                                    <div
                                        class="col-md-1 div-table div-table-value text-center">
                                        {{  dateTimeToHuman(activity.lastUpdateDate) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="row div-table-row">
                            <div class="col-md-12 div-table div-table-title"
                                style="border-bottom: 1px solid #a9a9a9">
                                <div class="row">
                                    <div class="text-center col-md-12 pr-5">
                                        Delivery Point(s)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row div-table-row">
                            <div
                                class="col-md-1 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                #
                            </div>
                            <div
                                class="col-md-5 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Transportation Method Name ( Code )
                            </div>
                            <div
                                class="col-md-6 div-table div-table-title text-center"
                                style="border-right: 1px solid #a9a9a9">
                                Station Name ( Code )
                            </div>
                        </div>
                        <div class="row div-table-row" v-for="(delivery , index_delivery) in singleRequest.requestDeliveryDtos" :key="index_delivery">
                            <div class="col-md-1 div-table div-table-value text-center">
                                {{ index_delivery + 1 }}
                            </div>
                            <div class="col-md-5 div-table div-table-value">
                                {{ delivery.transportMethodName }} ( {{ delivery.transportMethodCode }} )
                            </div>
                            <div
                                class="col-md-6 div-table div-table-value text-center">
                                {{ delivery.stationName }} ( {{ delivery.stationCode }} )
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" v-if="hasAssessment">
                    <div class="legend">
                        <h6 class="legend-title">
                        Charge and Tariff Details
                        </h6>
                        <div class="row div-table-row">
                            <div class="col-md-2 div-table div-table-title">
                                Tarrif Used
                            </div>
                            <div class="col-md-10 div-table div-table-value">
                                {{ getRequestAssNotes.tariffItemName }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-2 div-table div-table-title">
                                Custom Value (TZS)
                            </div>
                            <div class="col-md-2 div-table div-table-value">
                                {{ putComma(getRequestAssNotes.customValue) }}
                            </div>
                            <div class="col-md-2 div-table div-table-title">
                                Assessment Type
                            </div>
                            <div class="col-md-2 div-table div-table-value">
                                {{ getRequestAssNotes.bt ? 'BT Assessment' : 'Normal Assessment' }}
                            </div>
                            <div class="col-md-2 div-table div-table-title" v-if="getRequestAssNotes.cargoTonnage !== null">
                                Cargo Tonnage
                            </div>
                            <div class="col-md-2 div-table div-table-value" v-if="getRequestAssNotes.cargoTonnage !== null">
                                {{ putComma(getRequestAssNotes.cargoTonnage) }}
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-2 div-table div-table-title" v-if="getRequestAssNotes.cargoVolume !== null">
                                Cargo Volume
                            </div>
                            <div class="col-md-2 div-table div-table-value" v-if="getRequestAssNotes.cargoVolume !== null">
                                {{ putComma(getRequestAssNotes.cargoVolume) }}
                            </div>
                            <div class="col-md-2 div-table div-table-title" v-if="getRequestAssNotes.cargoCbm !== null">
                                Cargo CBM
                            </div>
                            <div class="col-md-2 div-table div-table-value" v-if="getRequestAssNotes.cargoCbm !== null">
                                {{ putComma(getRequestAssNotes.cargoCbm) }}
                            </div>
                            <div class="col-md-2 div-table div-table-title" v-if="getRequestAssNotes.quantity !== null">
                                Quantity
                            </div>
                            <div class="col-md-2 div-table div-table-value" v-if="getRequestAssNotes.quantity !== null">
                                {{ putComma(getRequestAssNotes.quantity) }}
                            </div>
                            <div class="col-md-4 div-table div-table-value" v-else>
                                
                            </div>
                        </div>
                        <br>
                        <div class="row div-table-row">
                            <div class="col-md-2 div-table div-table-title" v-if="getRequestAssNotes.bt">
                                BT Number
                            </div>
                            <div class="col-md-2 div-table div-table-value" v-if="getRequestAssNotes.bt">
                                {{ getRequestAssNotes.tansadNo }}
                            </div>
                            <div class="col-md-2 div-table div-table-title" v-if="!getRequestAssNotes.bt">
                                TANSAD Number
                            </div>
                            <div class="col-md-2 div-table div-table-value" v-if="!getRequestAssNotes.bt">
                                {{ getRequestAssNotes.tansadNo }}
                            </div>
                            <div class="col-md-2 div-table div-table-title">
                                Assessment Notice
                            </div>
                            <div class="col-md-2 div-table div-table-value text-link"
                            @click.prevent="openPdfViewer(getRequestAssNotes.assessmentNoticeDocUrl , getRequestAssNotes.tansadNo, 'view')">
                                click to view
                            </div>
                            <div class="col-md-2 div-table div-table-title">
                                Invoice Number
                            </div>
                            <div class="col-md-2 div-table div-table-value">
                                {{ getRequestAssNotes.invoiceNumber }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="!permissionError">
                <div class="col-md-12 text-right">
                    <button class="btn btn-primary btn-space custom-btn mr-2" @click.prevent="goToChatRoom" v-if="finalUserPermissions.includes('CFA_CUSTOMER') && 
                    singleRequest.status !== 'Request Returned For Major Changes' && singleRequest.status !== 'Request Returned For Minor Changes'">
                        Chat room
                    </button>
                    <button class="btn btn-primary btn-space custom-btn mr-2" @click.prevent="goToChatRoom" v-if="finalUserPermissions.includes('CFA_CUSTOMER') && 
                    (singleRequest.status === 'Request Returned For Major Changes' || singleRequest.status === 'Request Returned For Minor Changes')">
                        See Raised Issues
                    </button>
                    <button class="btn btn-default btn-fill btn-space custom-btn-all" @click.prevent="goBack">
                        Back
                    </button>
                </div>
            </div>
        </card>
        <global-modal v-if="globalLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                    <div class="col-md-12">
                        Preparing requested file, Please wait
                    </div>
                    <div class="col-md-12">
                        <br>
                    </div>
                    <div class="col-md-12">
                        <pulse-loader
                        :color="color"
                        :size="size"
                        />
                    </div>
                </div>
            </div>
        </global-modal>
        <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"
        @downloadPdf="openPdfViewer(attachmentUrlForDownload, attachmentNameForDownload + '_FOR_APPLICATION_WITH_FILE_NUMBER_' + singleRequest.fileNo)"></pdf-viewer>
    </div>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'
import PdfViewer from '../../../components/pdfViewer.vue'
import { mapGetters, mapActions } from 'vuex'
import API from '../../../api'


export default {

    name: 'CustomerApplicationDetails',

    components: {

        PulseLoader,
        GlobalModal,
        PdfViewer

    },

    created () {

        if (this.$route.params.requestId) {

            this.$store.dispatch('pagenames/currentPageName', 'Application Datails');
            this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
            this.requestId = this.$route.params.requestId
            this.loadThisRequestDetails(this.$route.params.requestId)

        }

    },

    computed: {

        ...mapGetters('global', [

            'getGlobalLoader'

        ]),

        ketRerenderComponent: function () {
            
            this.requestId = this.$route.params.requestId
            this.loadThisRequestDetails(this.requestId)

        }

    },

    data () {

        return {

            color: 'green',
            size: '12px',
            mySize: '9px',
            loading: false,
            globalLoader: false,
            finalUserPermissions:[],
            applicationStatus: '',
            singleRequest: {},
            getRequestAssNotes: {},
            thisFirstRequestLoader: false,
            message: '',
            permissionError: false,
            hasAssessment: false,
            requestId: '',
            scr: '',
            showPdfViewer: false,

            attachmentNameForDownload: '',
            attachmentUrlForDownload: '',

        }

    },

    methods: {

        ...mapActions('global', [

            'loadFileFromMinio'

        ]),

        loadParameters (fileUrl, fileName) {

            return new Promise((resolve) => {

                this.$store.dispatch('global/fileUrling', fileUrl)
                this.$store.dispatch('global/fileNaming', fileName)

                resolve()
            })

        },

        openPdfViewer (fileUrl, fileName, action) {

            this.attachmentNameForDownload = fileName
            this.attachmentUrlForDownload = fileUrl

            this.getFileFromMinio(fileUrl, fileName, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        closePdfViewer () {

            this.showPdfViewer = false
            this.attachmentNameForDownload = ''
            this.attachmentUrlForDownload = ''
            this.scr = ''

        },

        getFileFromMinio (fileUrl, fileName, action) {

            return new Promise((resolve, reject) => {

                this.loadParameters(fileUrl, fileName).then(() => {

                this.loadFileFromMinio({action}).then((res) => {

                    if(res[1].status === 204) {

                        this.loadNotification('warn', 4000, '',
                        'File not found, please contact system admin',
                        'response', 1000 , true  , true)

                    } else {

                        // this.loadNotification('success', 4000, '',
                        // 'File has been successfully loaded, please have a look at it',
                        // 'response', 1000 , true  , true)
                        this.scr = res[0]
                        resolve()


                    }

                        this.globalLoader = this.getGlobalLoader

                    }).catch((error) => {

                    // this.loadNotification('error', 4000, '',
                    //     'File not loaded, please contact system admin',
                    //     'response', 1000 , true  , true)

                    this.globalLoader = false

                    })

                    this.globalLoader = this.getGlobalLoader

                }).catch(() => {

                    this.globalLoader = false

                })

            })

        },

        loadThisRequestDetails (data) {

            this.thisFirstRequestLoader = true;

            API.getFullRequestDetails(data).then(
                response => {

                    if (response.data.status) {

                        this.singleRequest = response.data.data
                        this.applicationStatus = this.getApplicationStatus(this.singleRequest.status, 1)
                        this.getAssesmentByRequestId(this.singleRequest.requestId)
                        this.thisFirstRequestLoader = false
                        this.permissionError = false

                    } else {

                        this.thisFirstRequestLoader = false
                        this.message = response.data.message
                        this.permissionError = true

                    }

                }
            ).catch(() => {

                this.thisFirstRequestLoader = false
                this.permissionError = false

            })

        },

        getAssesmentByRequestId (requestId) {

            this.thisFirstRequestLoader = true

            API.getRequestAssessmentNotice(requestId).then((response) => {

                if(response.data.data !== null){

                this.getRequestAssNotes = response.data.data;
                this.thisFirstRequestLoader = false
                this.hasAssessment = true

                } else {

                    this.hasAssessment - false

                }

            }).catch(() => {

                this.thisFirstRequestLoader = false

            })
            
        },

        gotToAttachment () {

            let requestIdArrayProp = [{

                requestMyId: this.singleRequest.requestId,
                responseAttachment: []

            }]

            let requestBlArrayProp = [this.singleRequest.blNumber]

            let ifRequestSubmittedProp = true

            this.$router.push({ name: 'NewApplication', params: { requestIdArrayProp,  ifRequestSubmittedProp, requestBlArrayProp} });

        },

        gotToApplication () {

            document.cookie = 'browser-local-cashed-url-back-7=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
            this.$router.push({name: 'EditApplication', params: {requestId: this.singleRequest.requestId}})

        },

        goBack () {

            if (window.$cookies.isKey('browser-local-cashed-url-back')) {
                this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))

                window.$cookies.remove('browser-local-cashed-url-back')
            }

        },

        goToChatRoom () {

            document.cookie = 'browser-local-cashed-url-back-to-chat=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
            this.$router.push({ name: 'CustomerChat', params: { requestId: this.singleRequest.requestId} })

        },

        gotToAdditionalAttachment () {

            document.cookie = 'browser-local-cashed-url-back-11=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
            this.$router.push({ name: 'additionalAttachmentToApplication', params: { requestId: this.singleRequest.requestId} })

        }

    }

}
</script>

<style scoped>

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .notice-table-label {
        width: 250px;
        background-color: #99badd;
        font-weight: normal;
    }

    .custom-table, .custom-table th, .custom-table td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    .custom-table th, .custom-table td {
        padding: 8px;
        text-align: left;
    }

    .add-receiving-point , .add-delivery-or-exit-point {
        color: green;
    }

    .remove-delivery-or-exit-point {
        color: red;
        margin-top: 35px;
    }

    .remove-delivery-or-exit-point-in-table {
        color: red;
    }

    .add-receiving-point:hover, .add-delivery-or-exit-point:hover, .remove-delivery-or-exit-point:hover, .remove-delivery-or-exit-point-in-table:hover {
        color: #d59a18;
        cursor: pointer;
    }

    .row-receiving-point {
        padding: 0 30px 0 30px;
    }

    .text-view {
        color: green;
    }

    .text-view:hover {
        counter-reset: blue;
        cursor: pointer;
    }

    .current-update {
        background-color: #dcdcdc !important;
    }

    .btn-space {
        margin-right: 10px !important;
    }

    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .fileUpload {
        position: relative;
        overflow: hidden;
        margin: 0.5em 5px;
        font-size: 1em;
        font-weight: 700;
        letter-spacing: 0.02em;
        line-height: 1.26;
        text-decoration: none;
        top: 16px;
        font-weight: normal;
    }

    .fileUpload input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }

    .fileUpload span {
        cursor: pointer;
    }

    label {
        position: relative;
        top: -7px;
        right: 0;
        margin: 0;
        padding: 0;
    }

    #label-upload {
        position: relative;
        top: 16px;
    }

    .btn-disabled {
        background-color: #DCDCDC;
        cursor: not-allowed;
        color: #696969;
        padding: 10px 20px;
        border: none;
        width: 100%;
        opacity: 0.9;
        min-height: 55px;
        max-height: 55px;
    }

    .btn-disabled:hover {
        background-color: #DCDCDC;
        cursor: not-allowed;
        color: #696969;
        padding: 10px 20px;
        border: none;
        width: 100%;
        opacity: 1;
        min-height: 55px;
        max-height: 55px;
    }

    .application-status {
        /*background-color: #ededee;*/
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 12px;
    }

    .tooltiptext {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 78%;
    }

    .tooltiptext::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .tooltipe:hover .tooltiptext {
        visibility: visible;
        cursor: help;
    }

    .tooltiptext2 {
        visibility: hidden;
        width: 130px;
        background-color: black;
        color: #fff;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        bottom: 150%;
        left: 78%;
    }

    .tooltiptext2::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: black transparent transparent transparent;
    }

    .tooltipe:hover .tooltiptext2 {
        visibility: visible;
        cursor: help;
    }

    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    .insert-bl {
        margin-top: 36px;
        color: forestgreen;
        font-weight: bold;
    }

    .insert-bl .save-bl:hover {
        cursor: pointer;
    }

    .custom-btn-top {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }


    .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
        background-color: #FFFFFF !important;
    }

    .btn-blue:hover {
        border-color: #0000ff;
        color: #0000ff;
        background-color: #ffffff;
    }

    .btn-blue {
        border-color: #4169e1;
        color: #4169e1;
    }

    .btn-blue-fill {
        background-color: #4169e1;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-purple:hover {
        border-color: #800080;
        color: #800080;
        background-color: #ffffff;
    }

    .btn-purple {
        border-color: #9932cc;
        color: #9932cc;
    }

    .btn-purple-fill {
        background-color: #9932cc;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-linen:hover {
        border-color: #D2691E;
        color: #D2691E;
        background-color: #ffffff;
    }

    .btn-linen {
        border-color: rgba(210, 105, 30, .5);
        color: rgba(210, 105, 30, .5);
    }

    .btn-linen-fill {
        background-color: rgb(210, 105, 30);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-rosy-brown:hover {
        border-color: #775b5b;
        color: #775b5b;
        background-color: #ffffff;
    }

    .btn-rosy-brown {
        border-color: rgba(119,91,91, .5);
        color: rgba(119,91,91, .5);
    }

    .btn-rosy-brown-fill {
        background-color: rgb(119,91,91);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-khaki:hover {
        border-color: #989259;
        color: #989259;
        background-color: #ffffff;
    }

    .btn-khaki {
        border-color: rgba(152,146,89, .5);
        color: rgba(152,146,89, .5);
    }

    .btn-khaki-fill {
        background-color: rgb(152,146,89);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-olive:hover {
        border-color: #4B0082;
        color: #4B0082;
        background-color: #ffffff;
    }

    .btn-olive {
        border-color: #6A5ACD;
        color: #6A5ACD;
    }

    .btn-olive-fill {
        background-color: #6A5ACD;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-success-custom:hover {
        border-color: #008000;
        color: #008000;
        background-color: #ffffff;
    }

    .btn-success-custom {
        border-color: rgba(0, 128, 0, .5);
        color: rgba(0, 128, 0, .5);
    }

    .btn-success-custom-fill {
        background-color: rgba(0, 128, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .alert-success {
        padding-top:10px;
        padding-bottom: 10px;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert-danger {
        padding-top:10px;
        padding-bottom: 10px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

</style>
