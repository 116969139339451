<template>
  <div>
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
      <div class="row" v-if="!myLoader">
        <div class="col-md-12">
          <ul
            class="nav-justified-top"
            style="padding-left: 10px;padding-right: 10px"
          >
            <li
              v-if="finalUserPermissions.includes('V2_MANIFEST_MANIFESTEXCEL_{MANIFESTID}_GET')"
            >
              <button
                @click="printEDIExcel"
                class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn"
              >
                <font-awesome-icon
                  icon="file-excel"
                  class="file-excel"
                /> &nbsp; Download Excel
              </button>
            </li>

            <li
              v-if="messageType === 'COPRAR'
                && finalUserPermissions.includes('DOCUMENTS_DISCHARGELISTPDF_{DOCUMENTID}_GET')"
            >
              <button
                @click="downloadPdf(messageType , documentId)"
                class="btn btn-olive btn-olive-fill mr-2 custom-btn"
              >
                <font-awesome-icon
                  icon="file-pdf"
                  class="file-pdf"
                /> &nbsp; Download PDF
              </button>
            </li>

            <li v-if="finalUserPermissions.includes('EDIMESSAGE_CONFIG_ALL_{CONTROLREFERENCE}_GET') && !documentDetails.mailSent">
              <button
                @click="sendMail(documentId , documentDetails.senderId  , 'send')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="envelope" /> &nbsp; Send as Mail
              </button>
            </li>

            <li v-if="finalUserPermissions.includes('EDIMESSAGE_CONFIG_ALL_{CONTROLREFERENCE}_GET') && documentDetails.mailSent">
              <button
                @click="sendMail(documentId , singleDocument.carrierName , 'resend')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="envelope" /> &nbsp; Resend as Mail
              </button>
            </li>
            <li v-if="finalUserPermissions.includes('MANIFESTS_CONFIG_SINGLE_PAGEABLE_{DOCUMENTID}_GET') && !documentDetails.charged  && documentDetails.carrierId!==null">
              <button
                type="submit"
                class="btn btn-primary mr-2 custom-btn float-right"
                data-dismiss="modal"
                @click="addDocumentCharge"
                >
                Create Document Charge
              </button>
            </li>
            <li v-if="documentDetails.carrierId==null">
              <button
                type="submit"
                class="btn btn-primary mr-2 custom-btn float-right"
                data-dismiss="modal"
                disabled
              >
                Uploaded By SA
              </button>
            </li>

            <!-- <li v-if="messageType === 'CUSCAR'">
              <button
                @click="openModal(singleDocument , 'EDI_MESSAGE_HISTORY')"
                class="btn btn-purple btn-purple-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="history" /> &nbsp; EDI Message History
              </button>
            </li>

            <li
              v-if="messageType === 'CUSCAR'
                && finalUserPermissions.includes('MANIFESTS_SPECIFIED_{DOCUMENTID}_GET')"
            >
              <button
                @click="openModal(singleDocument , 'SPECIFIED_GOODS')"
                class="btn btn-linen btn-linen-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="luggage-cart" /> &nbsp; Specified Goods
              </button>
            </li> -->
          </ul>
        </div>
      </div>
    </card>
    <card style="font-size: 12px" v-if="!myLoader">
      <div class="legend">
        <h6 class="legend-title">
          EDI Document Details
        </h6>

        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">
            TASAC Ref. No.
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ documentDetails.applicationReference }}
          </div>
          <div class="col-md-3 div-table div-table-title">
            Message Name
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ messageName }}
          </div>
        </div>
        <br />
        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">
            Control Ref. No.
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ documentDetails.controlReferenceNumber }}
          </div>

          <div class="col-md-3 div-table div-table-title">
            Voyage No.
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ documentDetails.voyageNumber }}
          </div>
        </div>

        <br>

        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">
            EDI Message Type
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{documentDetails.messageTypeId}}
          </div>
          <div class="col-md-3 div-table div-table-title">
            Sender ID.
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ documentDetails.senderId }}
          </div>
        </div>

          <br>

        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">
            Vessel Name.
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ documentDetails.transportMeansName }}
          </div>
          <div class="col-md-3 div-table div-table-title">
            Created Date
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{documentDetails.createDateTime ? dateTimeToHuman(documentDetails.createDateTime) : ''}}
          </div>
        </div>

        <br>

        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">
            Carrier ID.
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ documentDetails.carrierId }}
          </div>
          <div class="col-md-3 div-table div-table-title">
            Cargo Report Date
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{documentDetails.cargoReportDate ? dateToHuman(documentDetails.cargoReportDate) : ''}}
          </div>
        </div>
      </div>

      <br />

      <div class="legend">
        <h6 class="legend-title"> EDI Message Details</h6>
        <ul class="nav-justified">
          <li>
            <a @click.prevent="setActiveOnClick('consignment')" :class="[ isActive('consignment') ? 'is-active-consignments' : '' ]" href="#consignment">Consignments</a>
          </li>
          <li>
            <a @click.prevent="setActiveOnClick('container')" :class="[ isActive('container') ? 'is-active-containers' : '' ]" href="#container">Containers</a>
          </li>
        </ul>

        <div class="tab-content py-3" id="manifestTabContent">
            <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('consignment') }" id="consignment">
                <data-table
                  :isPageable="false"
                  :tableHeaders="tableDataHeadersConsignments"
                  :tableData="filteredRequests"
                  :tableDataKeys="tableDataKeysConsignments"
                  :has-manage-button="false"
                  :has-search-form="false"
                  :loading-title="currentLoadingTitle"
                  :my-first-loader="myFirstLoader"
                  :no-data-found="noDataFound"
                  :hasViewButton="true"
                  :hasPagination="false"
                  :hasFilter="false"
                  :permissions="[ {key: 'searchPermission', value: 'V2_MANIFEST_SEARCH_POST'} ]"
                  @goToView="goToView"
                >
                </data-table>
            </div>
            <div class="tab-pane fade pt-3" :class="{ 'active show': isActive('container') }" id="container">
                <data-table
                  :isPageable="false"
                  :tableHeaders="tableDataHeadersContainers"
                  :tableData="filteredRequests"
                  :tableDataKeys="tableDataKeysContainers"
                  :has-manage-button="false"
                  :has-search-form="false"
                  :loading-title="currentLoadingTitle"
                  :my-first-loader="myFirstLoader"
                  :no-data-found="noDataFound"
                  :hasViewButton="true"
                  :hasPagination="false"
                  :hasFilter="false"
                  :permissions="[ {key: 'searchPermission', value: 'V2_MANIFEST_SEARCH_POST'} ]"
                  @goToView="goToView"
                >
                </data-table>
            </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            @click.prevent="goBack"
            class="btn btn-default btn-fill btn-space custom-btn-all float-right"
            style="float:right;"
          >Back
          </button>
        </div>
      </div>
      <br>
    </card>

    <modal-manifest v-if="showContainerDetails">
      <div class="modal-title col-md-12" slot="header">
        <div class="row">
          <div class="col-md-12">
            <b>Container Details</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <!-- <div class="card"> -->
          <div class="row">
          <div class="col-md-12">
            <div class="legend">
              <h6 class="legend-title">
                Container Details
              </h6>
              <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                  Container Number
                </div>
                <div class="col-md-2 div-table div-table-value">
                  {{containerDetails.containerNo ? containerDetails.containerNo : ''}}
                </div>

                <div class="col-md-2 div-table div-table-title">
                  Container Size.
                </div>
                <div class="col-md-2 div-table div-table-value">
                  {{containerDetails.containerSize}}
                </div>

                <div class="col-md-2 div-table div-table-title">
                  Master Bill of Lading.
                </div>
                <div class="col-md-2 div-table div-table-value">
                  {{containerDetails.masterBillOfLading}}
                </div>
              </div>
              <br />
              <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                  Container Type
                </div>
                <div class="col-md-4 div-table div-table-value">
                  {{containerDetails.typeOfContainer}}
                </div>

                <div class="col-md-2 div-table div-table-title">
                  Gross Weight
                </div>
                <div class="col-md-4 div-table div-table-value">
                  {{containerDetails.grossWeight}} {{containerDetails.grossWeightUnit}}
                </div>
              </div>
              <br />
              <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                  Number of Packages
                </div>
                <div class="col-md-4 div-table div-table-value">
                  {{containerDetails.numberOfPackage}}
                </div>
                <div class="col-md-2 div-table div-table-title">
                  Package Unit
                </div>
                <div class="col-md-4 div-table div-table-value">
                  {{containerDetails.packageUnit}}
                </div>
              </div>

              <br />

              <div class="legend mr-2 ml-2">
                <h6 class="legend-title">
                  Container Seal Numbers
                </h6>
                <div>
                  <data-table
                    :isPageable="false"
                    :tableHeaders="sealNumberHeaders"
                    :tableData="filteredContainers"
                    :tableDataKeys="sealNumberKeys"
                    :has-manage-button="false"
                    :has-search-form="false"
                    :my-first-loader="myFirstLoader"
                    :no-data-found="noDataFound"
                    :hasViewButton="false"
                    :hasPagination="false"
                    :hasFilter="false"
                    :permissions="[ {key: 'searchPermission', value: 'V2_MANIFEST_SEARCH_POST'} ]"
                  >
                  </data-table>
                </div>
              </div>


              <div class="text-center row pt-2">
                <div class="col-md-12">
                  <button @click="closeModal()" class="btn btn-fill btn-outline-info float-right mb-2 mr-2" type="button">
                    Close
                  </button>
                </div>
              </div>
        </div>
        </div>
        </div>
      <!-- </div> -->
      </div>

    </modal-manifest>

    <modal v-if="showDocumentChargeModal">
      <div class="modal-title col-md-12" slot="header">
        <div class="row">
          <div class="col-md-12">
            <b>Add Document Charge</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <form method="POST">
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="tariffItem">Tariff Item</label>
                <select
                  class="form-control"
                  id="tariffItem"
                  v-model="tariffItemId"
                >
                  <option
                    :value="item.tariffItemId" v-for="(item,index_01) in tariffItems" :key="index_01">
                    {{ item.tariffItemName }}
                  </option>
                </select>
                <div v-if="tariffItemIdError">
                  <div
                    class="error text-danger"
                    v-if="!$v.tariffItemId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="tariffFees">Tariff Fees</label>
                <select
                  class="form-control"
                  id="tariffFees"
                  v-model="tariffFeeId"
                >

                  <option
                    :value="fee.tariffFeeId" v-for="(fee,index) in tariffFees" :key="index">
                    {{ fee.tariffFeeName }}
                  </option>
                </select>
                <div v-if="tariffFeeIdError">
                  <div
                    class="error text-danger"
                    v-if="!$v.tariffFeeId.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mb-2">
                <label>Select Vessel Type</label>
                <model-list-select
                  :list="allCariers"
                  v-model="carrierName"
                  class="form-control"
                  option-value="id"
                  option-text="name"
                  placeholder="Vessel Type"
                >
                </model-list-select>
                <div v-if="carrierNameError">
                  <div
                    class="error text-danger"
                    v-if="!$v.carrierName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :color="color"
                  :loading="loading"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div class="col-md-3">
                <button
                  @click.prevent="closeModal"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  type="button"
                >
                  Close
                </button>
                <button
                  @click.prevent="submitDocumentCharge"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </div>

          </form>
        </card>
      </div>
    </modal>

    <transition
      name="addItemModal col-md-12"
      v-if="showConsignmentDetails"
    >
      <div>
        <div
          class="modal  modal-mask"
          style="display: block"
        >
          <div
            class="modal-dialog medium-modal-dialog"
            role="document"
          >
            <div class="modal-content col-md-12">
              <div class="modal-header col-md-12">
                <div class="modal-title col-md-12">
                  <div class="row">

                    <div class="col-md-12">
                      <b> EDI {{ activeItem === 'consignment' ? 'Consignment ' : 'Container ' }} Details</b>
                    </div>
                    <div class="col-md-12">
                      <hr>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="modal-body col-md-12">
                  <!--  VIEW BILL OF LADING DETAILS  -->
                  <card>
                    <div class="row div-table-row">
                      <div class="col-md-2 div-table-title">
                        Master Bill Of Lading
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{consignmentDetails.masterBillOfLading}}
                      </div>

                      <div class="col-md-2 div-table-title">
                        Port of Origin
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{consignmentDetails.portOfOrigin}}
                      </div>

                      <div class="col-md-2 div-table-title">
                        Port of Loading
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{consignmentDetails.portOfLoading}}
                      </div>
                    </div>
                    <br>
                    <div class="row div-table-row">
                      <div class="col-md-2 div-table-title">
                        Place of Delivery
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{consignmentDetails.placeOfDelivery}}
                      </div>

                      <div class="col-md-2 div-table-title">
                        Place of Destination
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{consignmentDetails.placeOfDestination}}
                      </div>
                    </div>

                    <br>
                    <div class="row div-table-row" v-if="consignmentDetails.consigneeName">
                      <div class="col-md-1 div-table-title">
                        Consignee Name
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.consigneeName}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Consignee Tel.
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{ consignmentDetails.consigneeTel}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Consignee Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consigneeLocationName}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.consigneeName">
                    <div class="row div-table-row" v-if="consignmentDetails.consigneeName">
                      <div class="col-md-2 div-table-title">
                        Consignee TIN.
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.consigneeTin}}
                      </div>
                      <div class="col-md-2 div-table-title">
                        Consignee Address
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.consigneeAddress}}
                      </div>

                    </div>
                    <br v-if="consignmentDetails.consigneeName">

                    <div class="row div-table-row" v-if="consignmentDetails.consignorName">
                      <div class="col-md-1 div-table-title">
                        Consignor Name
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.consignorName}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Consignor Tel.
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{ consignmentDetails.consignorTel}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Consignor Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.consignorLocationName}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.consignorName">
                    <div class="row div-table-row" v-if="consignmentDetails.consignorName">
                      <div class="col-md-2 div-table-title">
                        Consignor TIN.
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.consignorTin}}
                      </div>
                      <div class="col-md-2 div-table-title">
                        Consignor Address
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.consignorAddress}}
                      </div>

                    </div>
                    <br v-if="consignmentDetails.consignorName">

                    <div class="row div-table-row" v-if="consignmentDetails.notifyName">
                      <div class="col-md-1 div-table-title">
                        Notify Party Name
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.notifyName}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Notify Party Tel.
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{ consignmentDetails.notifyTel}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Notify Party Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.notifyLocationName}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.notifyName">
                    <div class="row div-table-row" v-if="consignmentDetails.notifyName">
                      <div class="col-md-2 div-table-title">
                        Notify Party TIN.
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.notifyTin}}
                      </div>
                      <div class="col-md-2 div-table-title">
                        Notify Party Address
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.notifyAddress}}
                      </div>

                    </div>
                    <br v-if="consignmentDetails.notifyName">

                    <div class="row div-table-row" v-if="consignmentDetails.exporterName">
                      <div class="col-md-1 div-table-title">
                        Exporter Name
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.exporterName}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Exporter Tel.
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{ consignmentDetails.exporterTel}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Exporter Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.exporterLocationName}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.exporterName">
                    <div class="row div-table-row" v-if="consignmentDetails.exporterName">
                      <div class="col-md-2 div-table-title">
                        Exporter TIN.
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.exporterTin}}
                      </div>
                      <div class="col-md-2 div-table-title">
                        Exporter Address
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.exporterAddress}}
                      </div>

                    </div>
                    <br v-if="consignmentDetails.exporterName">

                    <div class="row div-table-row" v-if="consignmentDetails.forwarderName">
                      <div class="col-md-1 div-table-title">
                        Forwarder Name
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.forwarderName}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Forwarder Tel.
                      </div>
                      <div class="col-md-2 div-table-value">
                        {{ consignmentDetails.forwarderTel}}
                      </div>

                      <div class="col-md-1 div-table-title">
                        Forwarder Location
                      </div>
                      <div class="col-md-3 div-table-value">
                        {{ consignmentDetails.forwarderLocationName}}
                      </div>
                    </div>
                    <br v-if="consignmentDetails.forwarderName">
                    <div class="row div-table-row" v-if="consignmentDetails.forwarderName">
                      <div class="col-md-2 div-table-title">
                        Forwarder TIN.
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.forwarderTin}}
                      </div>
                      <div class="col-md-2 div-table-title">
                        Forwarder Address
                      </div>
                      <div class="col-md-4 div-table-value">
                        {{ consignmentDetails.forwarderAddress}}
                      </div>

                    </div>

                    <div class="legend">
                      <h6 class="legend-title"> Goods' Details</h6>
                      <data-table
                        :isPageable="false"
                        :tableHeaders="goodsDetailsHeaders"
                        :tableData="filteredGoods"
                        :tableDataKeys="goodsDetailsKeys"
                        :has-manage-button="false"
                        :has-search-form="false"
                        :my-first-loader="myFirstLoader"
                        :no-data-found="noDataFound"
                        :hasViewButton="false"
                        :hasPagination="false"
                        :hasFilter="false"
                        :permissions="[ {key: 'searchPermission', value: 'V2_MANIFEST_SEARCH_POST'} ]"
                      >
                      </data-table>
                    </div>
                  </card>
                  <div class="text-center row pt-2">
                    <div class="col-md-12">
                      <button @click="closeModal()" class="btn btn-fill btn-outline-info float-right mb-2 mr-2"
                              type="button">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

<global-modal v-if="globalLoader">
  <div slot="globalLoader">
    <div class="row text-center">
      <div class="col-md-12">
        Please wait, system is processing your request
      </div>
      <div class="col-md-12">
        <br>
      </div>
      <div class="col-md-12">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>
    </div>
  </div>
</global-modal>

  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import Modal from '../../../components/Inputs/Modal';
  import ModalManifest from '../../../components/Inputs/ModalManifest';
  import {mapActions, mapGetters} from 'vuex';
  import CrudNotification from '../../../components/DataTable/crudNotification'
  import GlobalModal from "../../../components/globalLoader";
  import API from "../../../api";
  import API_REVENUE from '../../../api/rev'
  import DC_SERVER from '../../../api/dc';
  import {ModelListSelect} from "vue-search-select";
  import {required} from "vuelidate/lib/validators";


  export default {

    name: "manifestDetails",

    components: {

      PulseLoader,
      Modal,
      ModalManifest,
      ModelListSelect,
      GlobalModal,
      CrudNotification,
      DataTable: () => import('../../../components/DataTable/dataTable')

    },

    created() {

      this.documentType = this.$route.params.direction
      let formattedDocumentType = this.documentType.charAt(0).toUpperCase() + this.documentType.slice(1).toLowerCase();

      this.$store.dispatch('pagenames/currentPageName', formattedDocumentType + ' Document Details')
      this.documentId = this.$route.params.documentId;
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadEDIDocumentDetailsOnCreated();

      this.getTariffItems();
      this.getTariffFees();

    },

    watch: {

      $route(to, from) {

        if (to !== from) {

          this.documentType = this.$route.params.direction
          let formattedDocumentType = this.documentType.charAt(0).toUpperCase() + this.documentType.slice(1).toLowerCase();
          this.$store.dispatch('pagenames/currentPageName', formattedDocumentType + ' EDI Document ')
          this.loadEDIDocumentDetailsOnCreated();

        }

      }

    },

    computed: {

      ...mapGetters('v2DocumentControl', [
        'getMyFirstLoader',

        'getPageNo',
        'getPageSize',
        'getQueryParam',

        'getManifestDetails',
        'getNoDataFound',
      ])

    },

    data() {

      return {

        showDocumentChargeModal: false,
        tariffItemId: '',
        tariffFeeId: '',
        tariffFeeIdError: false,
        tariffItemIdError: false,
        carrierNameError:false,
        tariffItems: [],
        tariffFees: [],
        messageType: '',
        carrierName:'',
        allCariers:[
          {name: "Ro-Ro vessel", id: 'PCC'},
          {name: "Containerized", id: 'CONTAINERIZED'},
          {name: "Tanker vessel", id: 'LIQUID_BULK'},
          {name: "Bulk vessel", id: 'GENERAL'},
        ],

        editMyLoader: false,
        currentLoadingTitle: '',
        activeItem: 'consignment',

        consignmentData: [],
        containerData: [],

        finalUserPermissions: [],
        documentId: '',
        documentDetails: {},
        searchParameter: '',
        hasSearchedVariable: false,
        globalLoader: false,
        reloadAllRequest: false,
        noDataFound: false,
        requests: [],
        filteredRequests: [],
        filteredContainers: [],
        filteredGoods: [],
        messageName: '',
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        totalElements: '',
        totalPages: '',
        isFirst: false,
        isLast: false,
        pageNumber: '',
        myFirstLoader: false,
        isPageable: true,

        isManageable: true,
        isViewable: false,

        consignmentDetails: {},
        showConsignmentDetails: false,

        showContainerDetails: false,
        containerDetails: {},

        sealNumberKeys: ['sealNumber', 'sealNumberIssuerName', 'sealNumberIssuerType', 'sealNumberCondition'],
        sealNumberHeaders: ['Seal Number', 'seal No Issuer Name', 'seal No Issuer Type', 'seal No Condition'],

        tableDataKeysConsignments: ['masterBillOfLading', 'portOfOrigin', 'portOfLoading', 'placeOfDestination', 'placeOfDelivery',],
        tableDataHeadersConsignments: ['Master Bill of Lading', 'Port Of Origin', 'Port Of Loading', 'Destination', 'Delivery Place'],

        tableDataKeysContainers: ['masterBillOfLading', 'containerNo', 'typeOfContainer', 'packageUnit', 'grossWeight', 'numberOfPackage'],
        tableDataHeadersContainers: ['Master Bill of Lading', 'Container No', 'Type of Container', 'Package Unit', 'Gross Weight', 'Number of Packages'],

        tableDataKeys: ['applicationReference', 'senderId', 'transportMeansName', 'controlReferenceNumber', 'messageTypeEnum', 'voyageNumber'],
        tableHeaders: ['TASAC Ref No.', 'Carrier ID', 'Vessel Name', 'Control Reference No.', 'Message Type', 'Voyage Number'],

        goodsDetailsHeaders: ['Container No.', 'Package Quantity', 'package Type', 'Description', 'gross Weight', 'Marks & Numbers'],
        goodsDetailsKeys: ['containerNo', 'packageQuantity', 'packageType', 'description', 'grossWeight', 'marksNumbers'],

        popupKeyDetails: ['packageQuantity', 'packageType', 'grossWeight', 'grossWeightUnit', 'netWeight',
          'netWeightUnit', 'volume', 'volumeUnit', 'length', 'width',
          'height', 'goodsValue', 'goodsValueCurrency', 'marksNumbers', 'vehicleVIN',
          'vehicleModel', 'vehicleMake', 'vehicleOwnDrive', 'dangerousGoodsInformation'],


        color: 'green',
        size: '15px',
        loading: false,
        showModal: false,
        myLoader: false,
      }

    },

    validations:{
      tariffItemId:{required},
      tariffFeeId:{required},
      carrierName:{required}
    },

    methods: {

      ...mapActions('v2DocumentControl', [
        'getDocumentDetailsByDocumentId',
        'printEDIDocument'
      ]),

      sortingData(data , sortType) {

        for(let x = 0 ; x < data.length ; x++) {

          const requestObject = {
            ...data[x]
            };

            if(sortType === 'consignmentContainer') {

              this.filteredRequests.push(requestObject)

            } else if (sortType === 'ContainerSerialNUmber') {

              this.filteredContainers.push(requestObject)

            } else {

              this.filteredGoods.push(requestObject)

            }


        }

      },

      loadParameters() {
        return new Promise(resolve => {
          this.$store.dispatch('v2DocumentControl/manifestIdValue', this.documentId);
          resolve();
        })
      },

      loadEDIDocumentDetailsOnCreated() {

        this.loadParameters().then(() => {
          this.myLoader = true;
          this.loading=true;
          this.getDocumentDetailsByDocumentId(this.documentId).then(() => {

            this.myLoader = this.getMyFirstLoader;
            this.loading=false;

            this.documentDetails = this.getManifestDetails;

            this.sortingData(this.documentDetails.consignments , 'consignmentContainer')

            if ((this.documentDetails.messageTypeId).toUpperCase() === 'CUSCAR') {
              this.messageName = 'Manifest'
              this.messageType = this.documentDetails.messageTypeId
            } else if ((this.documentDetails.messageTypeId).toUpperCase() === 'BAPLIE') {
              this.messageName = 'Bay Plan'
              this.messageType = this.documentDetails.messageTypeId
            } else if ((this.documentDetails.messageTypeId).toUpperCase() === 'IFTDGN') {
              this.messageName = 'Dangerous Goods'
              this.messageType = this.documentDetails.messageTypeId
            } else if ((this.documentDetails.messageTypeId).toUpperCase() === 'COPRAR') {
              this.messageName = 'Discharge List'
              this.messageType = this.documentDetails.messageTypeId
            } else {

            }

            this.noDataFound = this.getNoDataFound;

          }).catch(() => {
          })
        }).catch(() => {
        })
      },


      sendMail(data, name, flag) {

        this.$dialog.confirm('You are about to ' + flag + ' MANIFEST as mail attachment to company [ ' + name + ' ], Are you sure!.', {

          loader: true
        })
          .then((dialog) => {

            this.globalLoader = true

            API.sendMailManifest(data).then(
              response => {

                if (response.data.status) {

                  this.loadNotification('success', 4000, 'Mail Message',' Mail has been successifully sent',
                  'response', 1000 , true  , true);
                  this.loadEDIDocumentDetailsOnCreated(this.$route.params.documentId, this.$route.params.documentType)

                } else {

                  this.loadNotification('error', 4000, 'Mail Message',' Mail not sent',
                  'response', 1000 , true  , true);

                }

                this.globalLoader = false

              }
            ).catch(() => {

              this.loadNotification('error', 4000, 'Mail Message',' Something went wrong, please retry',
              'response', 1000 , true  , true);

              this.globalLoader = false
            })

            dialog.close()

          }).catch(() => {
          this.globalLoader = false
          this.SuccessAlert = false
        })
      },

      submitDocumentCharge() {

        if (this.tariffFeeId === '') {
          this.tariffFeeIdError = true
        }
        if (this.tariffItemId === '') {
          this.tariffItemIdError = true
        } if (this.carrierName===''){
          this.carrierNameError=true;
        }
        else if (this.tariffFeeId !== '' && this.tariffItemId !== '') {

              this.showDocumentChargeModal = false;
              this.loading = true;

              DC_SERVER.createv2ManifestCharge(this.documentDetails.cargoManifestId, this.tariffItemId, this.tariffFeeId,this.carrierName).then((response) => {
                this.loading=false;
                if(response.data.status){
                  this.loadNotification('success', 4000, 'Charging Message',' Manifest charge has been successifully created',
                    'response', 1000 , true  , true);

                  this.loadEDIDocumentDetailsOnCreated();
                }else {
                  this.loadNotification('error', 4000, 'Charging Message',response.data.message,
                    'response', 1000 , true  , true);
                }


                //this.goBack();

            }).catch((e)=>{
                this.loading=false;

              this.loadNotification('error', 4000, 'Mail Message',' Something went wrong, please retry',
              'response', 1000 , true  , true);

          });
        }
      },

      printEDIExcel() {

          let pdfName = String('EDI_' + this.documentDetails?.applicationReference + '_' + this.documentDetails?.voyageNumber + '_' + this.documentDetails?.senderId)
          this.$store.dispatch('v2DocumentControl/manifestIdValue', this.documentId);
          this.$store.dispatch('v2DocumentControl/namingDetails', pdfName)
          this.globalLoader = true;
          this.printEDIDocument().then(() => {

            // if (response.status === '200') {

              this.loadNotification('success', 4000, 'Mail Message',' Excel for manifest has been successifully downloaded',
                  'response', 1000 , true  , true);
            // } else {

            //   this.loadNotification('error', 4000, 'Mail Message',' Something went wrong, please retry',
            //   'response', 1000 , true  , true);
            // }

            this.globalLoader = false;

          }).catch((e) => {

            this.globalLoader = false;

            if (this.getErrorAlert) {

              this.loadNotification('error', 4000, 'Mail Message',' Something went wrong, please retry',
              'response', 1000 , true  , true);

            }
          })

      },

      goBack() {

        if (window.$cookies.isKey('browser-local-cashed-url-back')) {

          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))
          // window.$cookies.remove('browser-local-cashed-url-back')

        }
      },

      isActive(menuItem) {

        return this.activeItem === menuItem;

      },

      setActiveOnClick (menuItem) {

          this.activeItem = menuItem
          this.requests = []
          this.filteredRequests = []

          if(menuItem === 'consignment') {

              this.sortingData(this.documentDetails.consignments , 'consignmentContainer')

          } else {

              this.sortingData(this.documentDetails.containers, 'consignmentContainer')

          }

      },

      getTariffItems() {
        API_REVENUE.getTariffItemsActiveByService('DC').then((response) => {
          this.tariffItems = response.data.data
        })
      },

      getTariffFees() {
        API_REVENUE.getActiveTariffFee().then((response) => {
          this.tariffFees = response.data.data
        })
      },

      goToView(data) {

        if (this.activeItem === 'consignment') {

          this.consignmentDetails = data;

          if (data.goodDetails) {

            this.sortingData(data.goodDetails , 'ConsignmentGoods')

            for (let j = 0; j < data.goodDetails.length; j++) {

              data.goodDetails[j].containerNo = data.goodDetails[j].placements[0]?.containerNo ? data.goodDetails[j].placements[0].containerNo : ''
            }
          }
          this.showConsignmentDetails = true;

        } else {

          this.containerDetails = data;
          this.sortingData(this.containerDetails.sealNumbers , 'ContainerSerialNUmber')
          this.showContainerDetails = true;

        }

      },

      addDocumentCharge() {

        this.showDocumentChargeModal = true;

      },

      closeModal() {

        this.showContainerDetails = false
        this.showConsignmentDetails = false
        this.showDocumentChargeModal = false
        this.filteredContainers = []

      }
    }

  }
</script>

<style scoped>

  .custom-btn-all {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
      width: auto;
  }

  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto;
  }

  .medium-modal-dialog {
    max-width: calc(80vw) !important;
  }

  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  thead th {
    font-weight: 600 !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  ul.nav-justified-top {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      background-color: #ededee;
      border-radius: 3px;
  }

  ul.nav-justified-top li {
      float: left;
  }

  ul.nav-justified-top li a:hover {
      color: #d59a18;
  }

  ul.nav-justified-top li a {
      display: block;
      text-align: center;
      padding: 16px 20px 10px 21px;
      text-decoration: none;
      color: blue;
      font-weight: bold;
      text-transform: uppercase;
  }

  .text-link {
      color: blue;
  }

  .text-link:hover {
      color: #d59a18;
      cursor: pointer;
  }

  .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }

  .btn-success-custom:hover {
      border-color: #008000;
      color: #008000;
      background-color: #ffffff;
  }

  .btn-success-custom:hover .file-excel {
      color: green;
  }

  .btn-success-custom {
      border-color: rgba(0, 128, 0, .5);
      color: rgba(0, 128, 0, .5);
  }

  .btn-success-custom-fill {
      background-color: rgba(0, 128, 0, .8);
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-blue:hover {
      border-color: #0000ff;
      color: #0000ff;
      background-color: #ffffff;
  }

  .btn-blue {
      border-color: #4169e1;
      color: #4169e1;
  }

  .btn-blue-fill {
      background-color: #4169e1;
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-olive:hover {
      border-color: #4B0082;
      color: #4B0082;
      background-color: #ffffff;
  }

  .btn-olive:hover .file-pdf {
      color: red;
  }

  .btn-olive {
      border-color: #6A5ACD;
      color: #6A5ACD;
  }

  .btn-olive-fill {
      background-color: #6A5ACD;
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-purple:hover {
      border-color: #800080;
      color: #800080;
      background-color: #ffffff;
  }

  .btn-purple {
      border-color: #9932cc;
      color: #9932cc;
  }

  .btn-purple-fill {
      background-color: #9932cc;
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-linen:hover {
      border-color: #D2691E;
      color: #D2691E;
      background-color: #ffffff;
  }

  .btn-linen {
      border-color: rgba(210, 105, 30, .5);
      color: rgba(210, 105, 30, .5);
  }

  .btn-linen-fill {
      background-color: rgb(210, 105, 30);
      opacity: 1;
      color: #FFFFFF;
  }

  .chargedStatus {
    color: darkgoldenrod;
  }

  .error {
    color: #dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: auto;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }


  .is-active-containers, .is-active-containers :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }


  .is-active-waiting-control-number, .is-active-waiting-control-number :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #DCDCDC !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-consignments, .is-active-consignments:hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .align-center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
  }

  .avg-width {
    width: 50vw !important;
  }
</style>
