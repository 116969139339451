<template>
  <div>
    <div
      class="menu-title row"
      v-if="finalUserPermissions.includes('SYSTEM_CONFIG')"
      @click="toggleMenu"
      title="System Configurations"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="settings"
          :has-fill="true"
        />
        <span class="menu-title-name">CONFIGURATIONS</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenu"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenu"
        />
      </div>
    </div>
    <transition name="list">
      <div
        v-if="openMenu"
        class="menu-value"
      >
        <sidebar-link
          :to="{ name: 'saAttachment' }"
          v-if="finalUserPermissions.includes('VESSEL_ATTACHMENTS_ADD_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>SA Attachment Type</p>
        </sidebar-link>
      </div>
    </transition>
    <transition name="list">
      <div
        v-if="openMenu"
        class="menu-value"
      >
        <sidebar-link
          :to="{ name: 'doAttachment' }"
          v-if="finalUserPermissions.includes('DELIVERY-ORDER-ATTACHMENT-TYPE_ADD_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>DO Attachment type</p>
        </sidebar-link>
      </div>
    </transition>

    <transition name="list">
      <div
        v-if="openMenu"
        class="menu-value"
      >
        <sidebar-link
          :to="{ name: 'ActivityList' }"
          v-if="finalUserPermissions.includes('ACTIVITY_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Activity</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'agencyCharges' }"
          v-if="finalUserPermissions.includes('SYSTEM_CONFIG') &&
          finalUserPermissions.includes('AGENCY-CHARGE_ADD_POST')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Delivery Order Charges</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'AttachmentType' }"
          v-if="finalUserPermissions.includes('ATTACHMENTS_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Attachment Type</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'RequestTypeList' }"
          v-if="finalUserPermissions.includes('REQUEST_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Application Type</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'CommonCode' }"
          v-if="finalUserPermissions.includes('COMMON-CODE_PULL_POST')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>TeWS Common Code</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'CFAgent' }"
          v-if="finalUserPermissions.includes('CF-AGENT_ALL_GET')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>CF Agents</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'CompanyList' }"
          v-if="finalUserPermissions.includes('COMPANY_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Company</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'CompanyTypeList' }"
          v-if="finalUserPermissions.includes('COMPANYTYPE_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Company Type</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'CountryList' }"
          v-if="finalUserPermissions.includes('COUNTRY_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Country</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'CityList' }"
          v-if="finalUserPermissions.includes('CITY_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>City</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'DepartmentList' }"
          v-if="finalUserPermissions.includes('DEPARTMENT_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Department</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'ShippingAgencyServiceProvider' }"
          v-if="finalUserPermissions.includes('DEPARTMENT_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>SA Service Provider</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'Disbursement' }"
        >

          <!-- v-if="finalUserPermissions.includes('SERVICE_PROVIDER_ALL_GET')
            && finalUserPermissions.includes('SYSTEM_CONFIG')" -->
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Disbursement Services</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'DesignationList' }"
          v-if="finalUserPermissions.includes('DESIGNATION_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Designation</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'DocumentTypeList' }"
          v-if="finalUserPermissions.includes('DOCTYPE_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Document Type</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'ExchangeRateList' }"
          v-if="finalUserPermissions.includes('EXCHANGE_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Exchange Rate</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'HsCodeList' }"
          v-if="finalUserPermissions.includes('HSCODE_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>HS-code</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'PackageType' }"
          v-if="finalUserPermissions.includes('PACKAGE-TYPE_ADD_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Package Type</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'PortList' }"
          v-if="finalUserPermissions.includes('PORT_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Port</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'RegimeList' }"
          v-if="finalUserPermissions.includes('REGIMES_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Regime</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'StationList' }"
          v-if="finalUserPermissions.includes('STATIONS_ADD_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Station</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'StatusList' }"
          v-if="finalUserPermissions.includes('STATUS_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Status</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'SectorList' }"
          v-if="finalUserPermissions.includes('SECTOR_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Sector</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'SpecifiedGoodList' }"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SPECIFIEDGOODS_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Specified Goods</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'Tariffs' }"
          v-if="finalUserPermissions.includes('TARIFFS_CREATE_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Tariffs</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'tariffItem' }"
          v-if="finalUserPermissions.includes('TARIFF-ITEMS_CREATE_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Tariff Items</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'TransportMethod' }"
          v-if="finalUserPermissions.includes('TRANSPORT-METHOD_ADD_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Transport Method</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'VesselSchedule' }"
          v-if="finalUserPermissions.includes('VESSELSCHEDULE_CONFIG_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Vessel Schedule</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'vehicleProperties' }"
          v-if="finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_POST')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Vehicle Properties</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'vehiclePropertiesView' }"
          v-if="finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_GET')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>View Vehicle Properties</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'TallyEvents' }"
          v-if="finalUserPermissions.includes('TALLY-EVENT-CODES_ADD_POST')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Tally Event Codes</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'tallyActivity' }"
          v-if="finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_GET')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Tally Activities</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'tallyConfTariff' }"
          v-if="finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_GET')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Tally Tariff</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'ShippingFee' }"
          v-if="finalUserPermissions.includes('SHIP-FEE_PRODUCT_LIST_GET')
            && finalUserPermissions.includes('SYSTEM_CONFIG')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Shipping Fees</p>
        </sidebar-link>

      </div>
    </transition>
  </div>
</template>
<script>

import SvgIcon from '../../components/SvgLoader/svgLoader'

export default {

  name: 'Configuration',

  data () {
    return {

      finalUserPermissions: [],
      openMenu: false

    }
  },

  components: {
    SvgIcon
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    this.openMenu = typeof (localStorage.getItem('configuration-side-menu')) ? JSON.parse(localStorage.getItem('configuration-side-menu')) : false
  },

  methods: {

    toggleMenu () {
      this.openMenu = !this.openMenu
      localStorage.setItem('configuration-side-menu', this.openMenu)
    }

  }

}
</script>

<style scoped>

    .menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 12px !important;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    .list-enter-active, .list-leave-active {
        transition: all .7s;
    }

    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }

    .nc-icon {
        padding-right: 8px;
        padding-top: 4px;
        font-size: 20px !important;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        padding-left: 10px;
        font-weight: normal !important;
    }

    .sidebar .nav li .nav-link:hover .nc-icon-inner, body > .navbar-collapse .nav li .nav-link:hover .nc-icon-inner {
        fill: #d59a18;
    }

    .menu-title:hover .nc-icon, .menu-title:hover .nc-icon-page {
        fill: #d59a18;
    }

    .nc-icon {
        position: absolute;
        top: -10px;
        left: 15px;
        font-size: 25px !important;
        fill: #FFFFFF;
    }

    .nc-icon-inner {
        font-size: 10px !important;
        padding-left: 30px;
        fill: #FFFFFF;
    }

    .nc-icon-page {
        font-size: 10px !important;
        fill: #FFFFFF;
    }

</style>
