<template>
  <div>
    <card>
      <crud-notification
        :error-message="ErrorMessage"
        :success-message="SuccessMessage"
        :error-alert="ErrorAlert"
        :success-alert="SuccessAlert"
        @fadeMe="fadeMe"
        @enter="enter"
      />
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :no-data-found="noDataFound"
        :is-first="isFirst"
        :is-last="isLast"
        :total-pages="totalPages"
        :page-number="pageNumber"
        @goToPage="goToPage"
        :placeholder="'Search by Invoice No.'"
        :date-prop="'Created At '"
        :has-manage-button="true"
        :has-view-button="true"
        :reload-all-request="reloadAllRequest"
        @searchRequest="searchInvoiceNumber"
        @reloadByIcon="reloadRequests"
        @onChange="onChange"
        @goToView="viewAccountDetails"
        @goToApplication="goToApplication"
        :loading-title="'Please wait while the system is loading Disbursement Accounts'"
      />
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div class="col-md-12">
            <b> Account Details</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <div class="row">
            <div class="col-md-4">
              <base-input
                type="text"
                label="Principals' Name"
                placeholder="Principals' Name"
                v-model="accountDetails.invoiceNo"
                :value="accountDetails.invoiceNo"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                type="text"
                label="Voyage No."
                placeholder="Voyage No."
                v-model="accountDetails.voyageNo"
                :value="accountDetails.voyageNo"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                type="text"
                label="Invoice No."
                placeholder="Invoice no."
                v-model="accountDetails.invoiceNo"
                :value="accountDetails.invoiceNo"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>
          <!--   row 2   -->
          <div class="row">
            <div
              class="col-md-4"
              v-if="accountDetails.status === 'Not Approved'"
            >
              <base-input
                type="text"
                label="Account Status"
                placeholder="Account Status"
                v-model="accountDetails.status"
                :value="accountDetails.status"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                type="text"
                label="Disbursement Type"
                placeholder="Disbursement Type"
                v-model="accountDetails.disbursementType"
                :value="accountDetails.disbursementType"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-4">
              <base-input
                type="text"
                label="Disbursement Amount"
                placeholder="Disbursement Amount"
                v-model="accountDetails.disbursementAmount"
                :value="accountDetails.disbursementAmount"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>
          <!--   row 3   -->
          <div class="row">
            <div class="col-md-3">
              <base-input
                type="text"
                label="Deposit Amount"
                placeholder="Deposit Amount"
                v-model="accountDetails.depositAmount"
                :value="accountDetails.depositAmount"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                type="text"
                label="Deposit Date"
                placeholder="Deposit Date"
                v-model="accountDetails.depositDate"
                :value="accountDetails.depositDate"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                type="text"
                label="Deposit Details"
                placeholder="Deposit Details"
                v-model="accountDetails.depositDetails"
                :value="accountDetails.depositDetails"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                type="text"
                label="Bank Reference"
                placeholder="Bank Reference"
                v-model="accountDetails.bankReference"
                :value="accountDetails.bankReference"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>
          <!--  row 4     -->
          <div class="row">
            <div class="col-md-3">
              <base-input
                type="text"
                label="Prepared By:"
                placeholder="Prepared By:"
                v-model="accountDetails.preparedBy"
                :value="accountDetails.preparedBy"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                type="text"
                label="Prepared Date"
                placeholder="Prepared Date"
                v-model="accountDetails.preparedDate"
                :value="accountDetails.preparedDate"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                type="text"
                label="Approved By:"
                placeholder="Approved By:"
                v-model="accountDetails.approvedBy"
                :value="accountDetails.approvedBy"
                class="special-bottom"
                :readonly="true"
              />
            </div>
            <div class="col-md-3">
              <base-input
                type="text"
                label="Approval Date"
                placeholder="Approval Date"
                v-model="accountDetails.approvedDate"
                :value="accountDetails.approvedDate"
                class="special-bottom"
                :readonly="true"
              />
            </div>
          </div>

          <br>
          <div class="text-center row">
            <div class="col-md-7 text-right loader-div">
              <span v-if="editMyLoader">Please wait ....</span>
            </div>
            <div class="col-md-2 left loader-div-gif">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="editMyLoader"
              />
            </div>
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-fill btn-outline-info float-right"
                style="margin-inline-start:20px"
                @click="closeModal()"
              >
                Close
              </button>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import GlobalModal from '@/components/globalLoader'
// import DataTable from "@/components/DataTable/table";
import DataTable from '../../../components/DataTable/updatedTable'

import Modal from '@/components/Inputs/Modal'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CrudNotification from '../../../components/DataTable/crudNotification'

export default {
  name: 'DisbursementAccounts',
  components: {
    PulseLoader,
    GlobalModal,
    DataTable,
    Modal,
    CrudNotification
  },
  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Disbursement Accounts')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableDisbursementAccountsOnCreated()
  },
  computed: {
    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),
    ...mapGetters('disbursement', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestId'
    ])
  },
  validations: {

    carrierCode: { required },
    address: { required },
    name: { required },

    disbursementAccountDetails: {
      carrierCode: { required },
      address: { required },
      name: { required }

    }
  },

  data () {
    return {
      disbursementTypeEnum: [
        { disbursementType: 'Proforma' },
        { disbursementType: 'Final' },
        { disbursementType: 'Other' }
      ],
      accountDetails: {
        voyageNo: 'N/A',
        accountNo: 'N/A',
        address: 'N/A',
        carrierCode: 'N/A',
        name: 'N/A',
        disbursementAccountId: 'N/A',
        vesselParticularId: 'N/A',
        invoiceNo: 'N/A',
        disbursementType: 'N/A',
        disbursementAmount: 'N/A',
        depositAmount: 'N/A',
        preparedBy: 'N/A',
        approvedBy: 'N/A',
        preparedDate: 'N/A',
        approvedDate: 'N/A',
        depositDate: 'N/A',
        depositDetails: 'N/A',
        bankReference: 'N/A',
        status: 'N/A'
      },

      AddModalDiv: false,
      EditModalDiv: false,
      SearchModalDiv: false,
      showModal: false,
      editMyLoader: false,

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'preparedDate',
      totalElements: '',
      totalPages: '',
      isFirst: true,
      isLast: false,
      pageNumber: 0,
      reloadByIcon: true,
      isPageable: true,

      color: 'green',
      size: '12px',
      loading: true,
      editable: true,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      pdfFormatError: false,
      loadAllHeaderLoader: false,
      allAttachmentVerified: false,
      myLoader: false,
      myFirstLoader: false,
      status: 'NO_ACTION_BUTTON',
      isSearchable: false,
      requests: [],

      SuccessAlert: false,
      ErrorAlert: false,

      SuccessMessage: '',
      ErrorMessage: '',

      isHovered: [],
      saveDetails: {},
      ifThereIsParam: '',
      editDetails: false,
      finalUserPermissions: [],

      tableHeaders: ['Account Status', 'Disbursement Type', 'Invoice No.'],
      tableDataKeys: ['status', 'disbursementType', 'invoiceNo']

    }
  },
  methods: {
    ...mapActions('disbursement', [
      'loadAllDisbursementAccounts',
      'getDisbursementAccountByInvoiceNo'
    ]),

    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('disbursement/pageSizing', this.pageSize)
        this.$store.dispatch('disbursement/pageNumbering', this.pageNo)

        resolve()
      })
    },

    loadPageableDisbursementAccountsOnCreated () {
      this.loadParameters().then(() => {
        this.loadAllDisbursementAccounts({}).then(() => {
          this.requests = this.getRequestList.content
          this.myFirstLoader = this.getMyFirstLoader
          this.isLast = this.getRequestList.last
          this.totalElements = this.getRequestList.totalElements
          this.totalPages = this.getRequestList.totalPages
          this.pageNumber = this.getRequestList.number
          this.isFirst = this.getRequestList.first
          this.isLast = this.getRequestList.last
          this.noDataFound = this.getNoDataFound
          this.pageNo = this.getPageNo
        }).catch(() => {})
        this.myFirstLoader = this.getMyFirstLoader
      })
    },

    openModal (modalType) {
      this.showModal = true
    },
    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.principalDetailsAddressError = false
      this.principalDetailsCarrierCodeError = false
      this.principalDetailsNameError = false
    },

    viewAccountDetails (details) {
      console.log(details)
      this.accountDetails = details
      this.accountDetails.approvedDate = this.accountDetails.approvedDate ? this.dateToHuman(this.accountDetails.approvedDate) : '' ;
      this.accountDetails.depositDate = this.accountDetails.depositDate ? this.dateToHuman(this.accountDetails.depositDate) : '' ;
      this.accountDetails.preparedDate = this.accountDetails.preparedDate ? this.dateToHuman(this.accountDetails.preparedDate) : '' ;
      this.openModal('EDIT')
    },

    goToApplication (principalId) {
      // console.log(principalId)
      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
      this.$router.push('/shipping-agency/disbursementAccounts/accountdetails/' + principalId.disbursementAccountId)
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },
    searchInvoiceNumber (searchParam) {
      this.$store.dispatch('disbursement/searchingParameter', searchParam)
      this.getDisbursementAccountByInvoiceNo({}).then(() => {
        this.requests = [this.getRequestList]
        // this.ErrorMessage = this.getErrorMessage
        // this.SuccessMessage = this.getSuccessMessage
        // this.SuccessAlert = this.getSuccessAlert
        this.ErrorAlert = this.getErrorAlert
        this.isPageable = false
        this.noDataFound = this.getNoDataFound
        console.log(this.getNoDataFound)
      }).catch(() => {
        this.ErrorAlert = this.getErrorAlert
        this.ErrorMessage = 'Sorry, something went wrong while searching for invoice no. ' + searchParam


      })
      this.reloadAllRequest = true
    },
    onChange (event) {
      this.pageSize = event
      this.pageNo = 0
      this.loadPageableDisbursementAccountsOnCreated()
    },
    goToPage (page) {
      this.pageNo = page
      this.loadPageableDisbursementAccountsOnCreated()
    },
    reloadRequests () {
      this.reloadAllRequest = false
      this.isPageable = true
      this.loadPageableDisbursementAccountsOnCreated()
    },
    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }
  }
}
</script>

<style scoped>

.btn-space {
  margin-right: 10px !important;
}
.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px;
  width: 120px;
  background-color: #FFFFFF !important;
}

.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
</style>
