<template>
  <div>
    <card v-if="firstLoader">
      <div class="row">
        <div class="col-md-12 text-center">
          <b>Please wait while system is loading bill of lading details </b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :color="color"
            :size="size">
          </pulse-loader>
        </div>
      </div>
    </card>

    <card style="font-size: 12px" v-if="!firstLoader">
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size">
        </pulse-loader>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ul class="nav">
            <li>
              <button @click.prevent="cancelCharge"
                      class="btn btn-primary mr-2 custom-btn">
                Cancel Charge
              </button>
            </li>
            <li>
              <button @click.prevent="generateBill"
                      class="btn btn-blue btn-blue-fill custom-btn">
                Generate Bill
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div class="legend">
        <h6 class="legend-title">
          Tally Notification Details
        </h6>
        <div>
          <div class="row div-table-row">
            <div class="col-md-2 div-table div-table-title mb-3">
              Vessel Name
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.vesselName}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Voyage
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.vesselName}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Operation Type
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.operationType}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Start Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.startTime}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              End Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.endTime}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Location Type
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.locationType}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Location
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.locationName}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Status
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.completed ? "COMPLETED": "NOT COMPLETED"}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Created By
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.notification.createdBy}}
            </div>
          </div>
        </div>
      </div>

      <div class="legend">
        <h6 class="legend-title">
          Tally Charge Details
        </h6>
        <div>
          <div class="row div-table-row">

            <div class="col-md-2 div-table div-table-title mb-3">
              Status
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.chargeStatus}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Empty FEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.feuemptyQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Empty TEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.teuemptyQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Full FEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.feufullQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Full TEU
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.teufullQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Vehicles
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.vehiclesQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Total Packages
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.packageQuantity}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Created By
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.createdBy}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Creation Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.createdAt}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Active
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{charge.active}}
            </div>
          </div>
        </div>
      </div>

    </card>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader';
  import GlobalModal from '../../../components/globalLoader'
  import Modal from '../../../components/Inputs/Modal';
  import TALLY_API from '../../../api/tally'

  export default {
    name: "tallyChargeDetail",

    components: {
      PulseLoader,
      GlobalModal,
      Modal
    },

    data() {
      return {
        color: 'green',
        size: '12px',
        id: '',
        charge: {},
        firstLoader: false,
        loading: false,
      }
    },

    created() {
      this.charge = this.$route.params.obj;
      console.log(this.charge);
    },

    methods: {
      getCharge() {

      },

      cancelCharge() {
        console.log("hello")
      },

      generateBill() {
        console.log("hello")
      }
    }
  }
</script>

<style scoped>
  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 200px;
    background-color: #FFFFFF !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    background-color: white;
    padding: 0.2rem 1rem;
    color: #d59a18;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
    font-weight: normal !important;
  }

  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  .table td, .table th {
    text-align: center !important;
  }

  .btn {
    font-size: 0.9rem !important;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }
</style>
