import { render, staticRenderFns } from "./clearingAndForwarding.vue?vue&type=template&id=9070a50c&scoped=true&"
import script from "./clearingAndForwarding.vue?vue&type=script&lang=js&"
export * from "./clearingAndForwarding.vue?vue&type=script&lang=js&"
import style0 from "./clearingAndForwarding.vue?vue&type=style&index=0&id=9070a50c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9070a50c",
  null
  
)

export default component.exports