c
<template>
  <div
    id="chart"
    class="col-md-12 bg-white"
  >
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>
<script>

export default {
  name: 'TotalSummaryApexLineChart',

  props: {
    displayData: {

      type: Array

    },
    title: String,
    subTitle: String,
    labelTitleOne: String,
    labelTitleTwo: String,
    footerText: String,
    isRevenue: Boolean
  },

  data () {
    return {
      labels: [],
      monthNameLabels: [],
      objects: {},

      series: [
        {
          name: 'High - 2013',
          data: [28, 29, 33, 36, 32, 32, 33]
        },
        {
          name: 'Low - 2013',
          data: [12, 11, 14, 18, 17, 13, 13]
        }
      ],
      chartOptions: {
        chart: {
          shadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: true
          }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Average High & Low Temperature',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {

          size: 6
        },
        xaxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
          title: {
            text: 'Month'
          }
        },
        yaxis: {
          title: {
            text: 'Temperature'
          },
          min: 0,
          max: 40
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5,
          itemMargin: {
            horizontal: 0,
            vertical: 20
          }
        }
      }

    }
  },
  watch: {
    displayData: function (newDisplayData, oldDisplayData) {
      this.prepareData()
    }
  },
  created () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      const monthName = new Array()
      monthName[0] = 'Jan'
      monthName[1] = 'Feb'
      monthName[2] = 'Mar'
      monthName[3] = 'Apr'
      monthName[4] = 'May'
      monthName[5] = 'Jun'
      monthName[6] = 'Jul'
      monthName[7] = 'Aug'
      monthName[8] = 'Sep'
      monthName[9] = 'Oct'
      monthName[10] = 'Nov'
      monthName[11] = 'Dec'
      this.labels = []
      this.monthNameLabels = []
      this.series = []

      let maxValue = 0
      const minValue = 0
      const newDate = new Date()
      const yr = newDate.getFullYear()
      const month = newDate.getUTCMonth() + 1

      if (month > 6) {
        for (let i = 7; i <= month; i++) {
          const proName = i < 10 ? '0' + i + '-' + yr : i + '-' + yr
          this.labels.push(proName)
          this.monthNameLabels.push(monthName[i - 1])
        }
      } else {
        for (let i = 1; i <= month; i++) {
          const proName = '0' + i + '-' + yr
          this.labels.push(proName)
          this.monthNameLabels.push(monthName[i - 1])
        }
        for (let i = 7; i <= 12; i++) {
          const proName = i < 10 ? '0' + i + '-' + yr : i + '-' + yr
          this.labels.push(proName)
          this.monthNameLabels.push(monthName[i - 1])
        }
      }

      if (this.isRevenue) {
        for (let i = 0; i < this.displayData.length; i++) {
          const newSeries1 = []
          const newArray = this.displayData[i]

          if (newArray.length > 0) {
            for (let j = 0; j < newArray.length; j++) {
              const newObj = newArray[j]

              for (let l = 0; l < this.labels.length; l++) {
                if (typeof newSeries1[l] === 'undefined') {
                  newSeries1.push(0)
                }

                if (this.labels[l] === newObj.yearMonth) {
                  newSeries1[l] = newObj.totalBillAmountTzs

                  if (maxValue < newObj.totalBillAmountTzs) {
                    maxValue = newObj.totalBillAmountTzs
                  }
                }
              }
            }
          } else {
            for (let l = 0; l < this.labels.length; l++) {
              if (typeof newSeries1[l] === 'undefined') {
                newSeries1.push(0)
              }
            }
          }

          if (i === 0) {
            this.series.push({
              name: this.labelTitleOne,
              data: newSeries1
            })
          } else {
            this.series.push({
              name: this.labelTitleTwo,
              data: newSeries1
            })
          }
        }
      } else {
        for (let i = 0; i < this.displayData.length; i++) {
          const newArray = this.displayData[i]
          const newSeries1 = []

          if (newArray.length > 0) {
            for (let j = 0; j < newArray.length; j++) {
              const newObj = newArray[j]

              for (let l = 0; l < this.labels.length; l++) {
                if (typeof newSeries1[l] === 'undefined') {
                  newSeries1.push(0)
                }
                if (this.labels[l] === newObj.title) {
                  newSeries1[l] = newObj.total

                  if (maxValue < newObj.total) {
                    maxValue = newObj.total
                  }
                }
              }
            }
          } else {
            for (let l = 0; l < this.labels.length; l++) {
              if (typeof newSeries1[l] === 'undefined') {
                newSeries1.push(0)
              }
            }
          }

          if (i === 0) {
            this.series.push({
              name: this.labelTitleOne,
              data: newSeries1
            })
          } else {
            this.series.push({
              name: this.labelTitleTwo,
              data: newSeries1
            })
          }
        }
      }
      if (maxValue < 10) {
        maxValue = 10
      } else if (maxValue < 50) {
        maxValue = 50
      } else if (maxValue < 100) {
        maxValue = 100
      } else if (maxValue < 500) {
        maxValue = 500
      } else if (maxValue < 1000) {
        maxValue = 1000
      } else {
        maxValue = Math.ceil(maxValue / 1000) * 1000
      }
      this.chartOptions.yaxis.max = maxValue
      this.chartOptions.yaxis.min = 0
      this.chartOptions.xaxis.categories = this.monthNameLabels
      this.chartOptions.yaxis.title.text = this.subTitle
      this.chartOptions.title.text = this.title
    }

  }
}
</script>
