<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
// import { INITIAL_EVENTS, createEventId } from './event-utils'
import axios from 'axios'
import jsonData from './data.json'
import TALLYAPI from "../../../api/tally";

export default {

  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  created() {
    this.getAssignmentsByActiveNotifications();
  },

  data: function () {
    const todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
    return {
      currentEventInfo: [],
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        initialView: 'timeGridWeek',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents
        /* you can update a remote database when these fire:
                    eventAdd:
                    eventChange:
                    eventRemove:
                    */
      },
      currentEvents: []
    }
  },
  computed: {
    eventsDatas () {
      if (this.currentEventInfo) {
        const newCur = []
        const tempTm = ''
        let tempNotifId = false
        for (let i = 0; i < this.currentEventInfo.length; i++) {
          const assignment = this.currentEventInfo[i]

          if (newCur.length > 0) {
            tempNotifId = false
            for (let l = 0; l < newCur.length; l++) {
              const insertedEl = newCur[l]

              if (insertedEl.notificationId === assignment.tallyDeStaffingNotification.id && assignment.startTime === insertedEl.start) {
                insertedEl.description = insertedEl.description + ', ' + assignment.clerk.firstName + ' ' + assignment.clerk.lastName
                newCur[l] = insertedEl
                tempNotifId = true
              }
            }

            if (!tempNotifId) {
              const newEvent = {
                id: '' + assignment.id,
                title: 'Voyage:' + assignment.tallyDeStaffingNotification.voyage + ' location:' + assignment.tallyDeStaffingNotification.locationName,
                description: 'Shift: ' + assignment.operationShift.name + ' =>  Clerk Names: ' + assignment.clerk.firstName + ' ' + assignment.clerk.lastName,
                notificationId: assignment.tallyDeStaffingNotification.id,
                start: assignment.startTime,
                end: assignment.endTime
              }
              newCur.push(newEvent)
            }
          } else {
            const newEvent = {
              id: '' + assignment.id,
              title: 'Voyage:' + assignment.tallyDeStaffingNotification.voyage + ' location:' + assignment.tallyDeStaffingNotification.locationName,
              description: 'Shift: ' + assignment.operationShift.name + ' => Clerk Names: ' + assignment.clerk.firstName + ' ' + assignment.clerk.lastName,
              notificationId: assignment.tallyDeStaffingNotification.id,
              start: assignment.startTime,
              end: assignment.endTime
            }
            newCur.push(newEvent)
          }
        }
        this.calendarOptions.initialEvents = newCur
      }
    }
  },
  mounted () {
     // axios
     //          .get('http://127.0.0.1:9068/api/tally-clerk-assignments/by-active-notifications')
     //          .then(response => (this.currentEventInfo = response)) */
  },
  methods: {

    getAssignmentsByActiveNotifications(){
      TALLYAPI.getAssignmentsByActiveNotifications().then((response)=>{
        this.currentEventInfo=response.data.data;

      });
    },

    handleWeekendsToggle () {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    handleDateSelect (selectInfo) {
      const title = prompt('Please enter a new title for your event')
      const calendarApi = selectInfo.view.calendar

      calendarApi.unselect(); // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: createEventId(),
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }
    },

    handleEventClick (clickInfo) {
      if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
      }
    },

    handleEvents (events) {
      this.currentEvents = events
    }
  }
}
</script>

<template>
  <card>
    <div class="demo-app">
      {{ eventsDatas }}
      <div class="demo-app-main">
        <FullCalendar
          class="demo-app-calendar"
          :options="calendarOptions"
        >
          <template v-slot:eventContent="arg">
            <section class="task task--danger">
              <b>{{ arg.timeText }}</b>
              {{ arg.event.title }}
              <section class="task task--info">
                <i>{{ arg.event.extendedProps.description }}</i>
              </section>
            </section>
            <!-- <mark>{{ arg.event.title }}</mark> -->
          </template>
        </FullCalendar>
      </div>
    </div>
  </card>
</template>

<style lang='css' scoped>

    h2 {
        margin: 0;
        font-size: 16px;
    }

    ul {
        margin: 0;
        padding: 0 0 0 1.5em;
    }

    li {
        margin: 1.5em 0;
        padding: 0;
    }

    b { /* used for event dates/times */
        margin-right: 3px;
    }

    .demo-app {
        display: flex;
        /*  min-height: 100%; */
        font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
        font-size: 14px;
    }

    .demo-app-sidebar {
        width: 300px;
        line-height: 1.5;
        background: #eaf9ff;
        border-right: 1px solid #d3e2e8;
    }

    .demo-app-sidebar-section {
        padding: 2em;
    }

    .demo-app-main {
        flex-grow: 1;
        padding: 3em;
    }

    .fc { /* the calendar root */
        max-width: 100%;
        margin: 0 auto;
    }
    html,
    body {
        width: 100%;
        height: 100%;
    }

    body {
        background: #f5f7fa;
        padding: 40px 0;
        box-sizing: border-box;
        font-family: Montserrat, "sans-serif";
        color: #51565d;
    }

    .calendar {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(7, minmax(120px, 1fr));
        grid-template-rows: 50px;
        grid-auto-rows: 120px;
        overflow: auto;
    }
    .calendar-container {
        width: 90%;
        margin: auto;
        overflow: hidden;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        background: #fff;
        max-width: 1200px;
    }
    .calendar-header {
        text-align: center;
        padding: 20px 0;
        background: -webkit-gradient(linear, left top, left bottom, from(#fafbfd), to(rgba(255, 255, 255, 0)));
        background: linear-gradient(to bottom, #fafbfd 0%, rgba(255, 255, 255, 0) 100%);
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
    }
    .calendar-header h1 {
        margin: 0;
        font-size: 18px;
    }
    .calendar-header p {
        margin: 5px 0 0 0;
        font-size: 13px;
        font-weight: 600;
        color: rgba(81, 86, 93, 0.4);
    }
    .calendar-header button {
        background: 0;
        border: 0;
        padding: 0;
        color: rgba(81, 86, 93, 0.7);
        cursor: pointer;
        outline: 0;
    }

    .day {
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        border-right: 1px solid rgba(166, 168, 179, 0.12);
        text-align: right;
        padding: 14px 20px;
        letter-spacing: 1px;
        font-size: 12px;
        box-sizing: border-box;
        color: #98a0a6;
        position: relative;
        pointer-events: none;
        z-index: 1;
    }
    .day:nth-of-type(7n + 7) {
        border-right: 0;
    }
    .day:nth-of-type(n + 1):nth-of-type(-n + 7) {
        grid-row: 2;
    }
    .day:nth-of-type(n + 8):nth-of-type(-n + 14) {
        grid-row: 3;
    }
    .day:nth-of-type(n + 15):nth-of-type(-n + 21) {
        grid-row: 4;
    }
    .day:nth-of-type(n + 22):nth-of-type(-n + 28) {
        grid-row: 5;
    }
    .day:nth-of-type(n + 29):nth-of-type(-n + 35) {
        grid-row: 6;
    }
    .day:nth-of-type(7n + 1) {
        grid-column: 1/1;
    }
    .day:nth-of-type(7n + 2) {
        grid-column: 2/2;
    }
    .day:nth-of-type(7n + 3) {
        grid-column: 3/3;
    }
    .day:nth-of-type(7n + 4) {
        grid-column: 4/4;
    }
    .day:nth-of-type(7n + 5) {
        grid-column: 5/5;
    }
    .day:nth-of-type(7n + 6) {
        grid-column: 6/6;
    }
    .day:nth-of-type(7n + 7) {
        grid-column: 7/7;
    }
    .day-name {
        font-size: 12px;
        text-transform: uppercase;
        color: #99a1a7;
        text-align: center;
        border-bottom: 1px solid rgba(166, 168, 179, 0.12);
        line-height: 50px;
        font-weight: 500;
    }
    .day--disabled {
        color: rgba(152, 160, 166, 0.6);
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23f9f9fa' fill-opacity='1' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E");
        cursor: not-allowed;
    }

    .task {
        border-left-width: 3px;
        padding: 8px 12px;
        margin: 10px;
        border-left-style: solid;
        font-size: 14px;
        position: relative;
    }
    .task--warning {
        border-left-color: #fdb44d;
        grid-column: 4 / span 3;
        grid-row: 3;
        background: #fef0db;
        align-self: center;
        color: #fc9b10;
        margin-top: -5px;
    }
    .task--danger {
        border-left-color: #fa607e;
        grid-column: 2 / span 3;
        grid-row: 3;
        margin-top: 15px;
        background: rgba(253, 197, 208, 0.7);
        align-self: end;
        color: #f8254e;
    }
    .task--info {
        border-left-color: #4786ff;
        grid-column: 6 / span 2;
        grid-row: 5;
        margin-top: 15px;
        background: rgba(218, 231, 255, 0.7);
        align-self: end;
        color: #0a5eff;
    }
    .task--primary {
        background: #4786ff;
        border: 0;
        border-radius: 4px;
        grid-column: 3 / span 3;
        grid-row: 4;
        align-self: end;
        color: #fff;
        box-shadow: 0 10px 14px rgba(71, 134, 255, 0.4);
    }
    .task__detail {
        position: absolute;
        left: 0;
        top: calc(100% + 10px);
        background: #fff;
        border: 1px solid rgba(166, 168, 179, 0.2);
        color: #000;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 10px 40px rgba(0, 0, 0, 0.08);
        z-index: 2;
    }
    .task__detail:after, .task__detail:before {
        bottom: 100%;
        left: 30%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .task__detail:before {
        border-bottom-color: rgba(166, 168, 179, 0.2);
        border-width: 8px;
        margin-left: -8px;
    }
    .task__detail:after {
        border-bottom-color: #fff;
        border-width: 6px;
        margin-left: -6px;
    }
    .task__detail h2 {
        font-size: 15px;
        margin: 0;
        color: #51565d;
    }
    .task__detail p {
        margin-top: 4px;
        font-size: 12px;
        margin-bottom: 0;
        font-weight: 500;
        color: rgba(81, 86, 93, 0.7);
    }
</style>
