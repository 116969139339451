<template>
  <div>
    <card>
      <div class="row">
        <crud-notification
          :error-message="ErrorMessage"
          :success-message="SuccessMessage"
          :error-alert="ErrorAlert"
          :success-alert="SuccessAlert"
          @fadeMe="fadeMe"
          @enter="enter"
        />

      </div>
      <div class="row">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="loading"
          />
        </div>
        <div class="col-md-12">
          <form>
            <!--<div class="row">-->
              <!--<div class="text-center sect-title col-md-12">-->
                <!--<label for="type" class="notification-title"-->
                  <!--&gt;Select Tally Type</label-->
                <!--&gt;-->
                <!--<select class="custom-select" id="type" v-model="tallyType">-->
                  <!--<option selected value="">-->
                    <!--Select Tally Type-->
                  <!--</option>-->
                  <!--<option value="DISCHARGING">-->
                    <!--DISCHARGING-->
                  <!--</option>-->
                  <!--<option value="LOADING">-->
                    <!--LOADING-->
                  <!--</option>-->
                  <!--<option value="STUFFING">-->
                    <!--STUFFING-->
                  <!--</option>-->
                  <!--<option value="DESTUFFING">-->
                    <!--DESTUFFING-->
                  <!--</option>-->
                <!--</select>-->
              <!--</div>-->
            <!--</div>-->
            <div
              class="form-group"
              v-if="
                tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''
              ">
              <label for="containerNo" class="notification-title"
                >Container Number</label>
              <input
                type="text"
                class="form-control"
                id="containerNo"
                v-model="containerNo"/>
            </div>
            <div
              class="form-group"
              v-if="
                tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''">
<!--              <label for="houseBl" class="notification-title">House BL</label>-->
<!--              <input-->
<!--                type="text"-->
<!--                class="form-control"-->
<!--                id="houseBl"-->
<!--                v-model="houseBl"-->
<!--              />-->
            </div>
            <div class="" v-if="tallyType !== ''">
              <div class="form-group">
                <label>Shipping Agent</label>
                <model-list-select :list="companies"
                                   v-model="selectedCompany"
                                   option-value="companyId"
                                   :custom-text="companyName"
                                   option-text="Shipping Agent"
                                   placeholder="select Company">
                </model-list-select>
              </div>
<!--              <div class="form-group">-->
<!--                <label for="birth" class="notification-title">Berth</label>-->
<!--                <input-->
<!--                  type="text"-->
<!--                  class="form-control"-->
<!--                  id="birth"-->
<!--                  v-model="berth"-->
<!--                />-->
<!--              </div>-->
              <div class="form-group">
                <label for="quantity" class="notification-title">No Containers</label>
                <input
                  type="text"
                  class="form-control"
                  id="quantity"
                  v-model="quantity"
                />
              </div>
              <div class="form-group"
                   v-if="
                tallyType !== 'STUFFING' &&
                  tallyType !== 'DESTUFFING' &&
                  tallyType !== ''">
                <label for="numberOfGangs" class="notification-title">Number of Gangs</label>
                <input
                  type="number"
                  class="form-control"
                  id="numberOfGangs"
                  v-model="numberOfGangs"
                />
              </div>

              <div class="form-group">
                <label for="locationName" class="notification-title">Location Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="locationName"
                  v-model="locationName"/>
                <div v-if="locationNameError" class="error">
                <span v-if="!$v.locationName.required" class="text-danger">This Field is Required</span>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="tallyType !== ''">
              <label class="notification-title">Location Type</label>
              <select class="form-control" v-model="locationType">
                <option />
                <option value="ICD">
                  ICD
                </option>
                <option value="TERMINAL">
                  TERMINAL
                </option>
                <option value="DEPOT">
                  DEPOT
                </option>
              </select>
              <div v-if="locationTypeError" class="error">
                <span v-if="!$v.locationType.required" class="text-danger">This Field is Required</span>
              </div>
            </div>
            <div class="form-group" v-if="tallyType !== ''">
              <label for="vesselType" class="notification-title">Vehicle Type</label>
              <select class="form-control" v-model="cargoType" id="vesselType">
                <option value="Container">
                  Container
                </option>
                <option value="Vehicle">
                  Vehicle
                </option>
                <option value="Dry Bulk">
                  Dry Bulk
                </option>
                <option value="Liquid Bulk">
                  Liquid Bulk
                </option>
                <option value="Break Bulk">
                  Break Bulk
                </option>
                <option value="General Cargo">
                  General Cargo
                </option>
              </select>
              <div v-if="cargoTypeError" class="error">
                <span v-if="!$v.cargoType.required" class="text-danger"
                  >This Field is Required</span>
              </div>
            </div>

            <div
              class="form-group"
              v-if="
                tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''">
              <label for="masterBl" class="notification-title">Master BL</label>
              <input
                type="text"
                class="form-control"
                id="masterBl"
                v-model="masterBl"
              />
            </div>
            <div
              class="form-group"
              v-if="
                tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''">
              <label for="shippingOrderNo" class="notification-title"
                >Shipping Order NUmber</label>
              <input
                type="text"
                class="form-control"
                id="shippingOrderNo"
                v-model="shippingOrderNo"/>
            </div>
            <div
              class="form-group"
              v-if="tallyType === 'DISCHARGING' || tallyType === 'LOADING'">
            </div>
            <div class="form-group" v-if="tallyType !== ''">
              <label for="vesselName" class="notification-title">Vessel Name</label>
              <input
                type="text"
                class="form-control"
                id="vesselName"
                v-model="vesselName"
              />
            </div>
            <div
              class="form-group"
              v-if="tallyType !== ''">
              <label for="voyage" class="notification-title">Voyage</label>
              <input
                type="text"
                class="form-control"
                id="voyage"
                v-model="voyage"
              />
            </div>
            <div class="row" v-if="tallyType !== ''">
              <div class="col-md-12 text-left">
                <b>Time range Option</b>
              </div>
              <div class="col-md-3">
                <base-input
                  type="datetime-local"
                  label="Start Date"
                  v-model="startTime"
                  @change="StartDateError = false"
                />
                <div v-if="startTimeError" class="error">
                  <span class="error text-danger" v-if="!$v.startTime.required"
                    >This field is required</span
                  >
                </div>
              </div>
              <div class="col-md-3">
                <base-input
                  type="datetime-local"
                  label="End Date"
                  v-model="endTime"
                  :min="startTime"
                  @change="EndDateError = false"
                />
<!--                <div v-if="endTimeError" class="error">-->
<!--                  <span class="error text-danger" v-if="!$v.endTime.required"-->
<!--                    >This field is required</span-->
<!--                  >-->
<!--                </div>-->
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12 text-right">
                <button class="btn btn-primary custom-btn" @click.prevent="createNotification">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import TALLYAPI from "../../../api/tally";
import API from "../../../api/index";

import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { required } from "vuelidate/lib/validators";
import { ModelListSelect } from 'vue-search-select';
import CrudNotification from '../../../components/DataTable/crudNotification';


export default {
  name: "addNotification",
  components: {
    PulseLoader,
    ModelListSelect,
    CrudNotification
  },
  validations: {
    startTime: { required },
    endTime: { required },
    locationName: { required },
    locationType: { required },
    cargoType: { required }
  },
  created() {

    // this.$store.dispatch('pagenames/currentPageName', 'Notifications')
    this.getCompanies();
  },

  data() {
    return {
      companies:[],
      quantity:null,
      numberOfGangs:null,
      selectedCompany:"",
      loading: false,
      tallyType: "",
      vesselCallId: "",
      voyage: "",
      callId: null,
      masterBl: "",
      shippingOrderNo: "",
      houseBl: "",
      containerNo: "",
      cargoType: "",
      cargoTypeError: false,
      startTime: "",
      startTimeError: false,
      endTime: "",
      endTimeError: false,
      locationType: "",
      locationTypeError: false,
      locationName: "",
      locationNameError: false,
      berth: "",
      declaredQuantity:'',
      vesselName:null,
      berthError: false,
      completed: false,
      completedError: false,

      SuccessAlert: false,
      ErrorMessage:"",
      SuccessMessage:"",
      ErrorAlert: false,
      documentId: "",
      documentIdError: false,

      color: "green",
      size: "15px"
    };
  },

  methods: {

    companyName(company) {
      return company.companyName;
    },

    getCompanies(){
      API.getCompany().then((response)=>{
        this.companies=response.data.data;
      }).catch((error)=>{

      })
    },

    createNotification() {
      if (
        this.startTime !== "" &&
        //REMOVED AS PER AGREEMENT
        // this.endTime !== "" &&
        this.locationType !== "" &&
        this.locationName !== "" &&
        this.cargoType !== ""
      ) {
        this.startTimeError = false;
        //REMOVED AS PER AGREEMENT -- REFER TO BENJAMIN
        // this.endTimeError = false;
        this.locationNameError = false;
        this.locationTypeError = false;
        this.cargoTypeError = false;
        this.loading = true;

        // let startTime=" 2020-08-07T" + this.startTime + ":00Z";
        // let endTime=" 2020-08-07T" + this.endTime + ":00Z";

        TALLYAPI.createNotification({
          berth: this.berth === "" ? null : this.berth,
          vesselName:this.vesselName,
          containerQuantity:this.quantity,
          quantity: this.declaredQuantity,
          weight: this.weight,
          numberOfGangs:this.numberOfGangs,
          callId: this.callId === "" ? null : this.callId,
          cargoType: this.cargoType === "" ? null : this.cargoType, // --> to be inserted
          completed: this.completed,
          companyId: 21,
          containerNo: this.containerNo === "" ? null : this.containerNo,
          documentId: this.documentId === "" ? null : this.documentId,
          endTime:  this.endTime ?  this.endTime + ":00.000Z" : null,
          houseBl: this.houseBl === "" ? null : this.houseBl,
          id: null,
          locationName: this.locationName === "" ? null : this.locationName,
          locationType: this.locationType === "" ? null : this.locationType,
          masterBl: this.masterBl === "" ? null : this.masterBl,
          shippingOrderNo:
            this.shippingOrderNo === "" ? null : this.shippingOrderNo,
          startTime: this.startTime ? this.startTime + ":00.000Z" : null,
          vesselCallId: this.vesselCallId === "" ? null : this.vesselCallId,
          voyage: this.voyage === "" ? null : this.voyage,
          operationType:this.tallyType
        })
          .then(() => {
            this.loading = false;
            this.vesselCallId = "";
            this.voyage = "";
            this.callId = "";
            this.locationType = "";
            this.locationName = "";
            this.masterBl = "";
            this.shippingOrderNo = "";
            this.houseBl = "";
            this.containerNo = "";
            this.startTime = "";
            this.endTime = "";
            this.berth = "";
            this.tallyType = "";

            this.SuccessMessage="Notification Created";
            this.SuccessAlert=true;
            this.ErrorMessage="";
            this.ErrorAlert=false;
          })
          .catch(() => {
            this.loading = false;
            this.SuccessMessage="Failed To Create Notification";
            this.SuccessAlert=false;
            this.ErrorMessage="Failed To Create Notification";
            this.ErrorAlert=true;
          });
      } else {
        this.startTimeError = true;
        // REMOVED AS PER AGREEMENT
        // this.endTimeError = true;
        this.locationNameError = true;
        this.locationTypeError = true;
        this.cargoTypeError = true;
      }
    },
    fadeMe: function() {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
      } else {
        this.ErrorAlert = !this.ErrorAlert;
      }
    },
    enter: function(el, done) {
      const that = this;

      setTimeout(function() {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000); // hide the message after 3 seconds
    }
  }
};
</script>

<style scoped>
.notification-title {
  font-size: 15px;
  color: black;
}
</style>
