<template>
  <div @click="clearLocalStorage">
    <div
      class="menu-title row"
      v-if="finalUserPermissions.includes('ACCOUNTANT') && finalUserPermissions.includes('ACCOUNTANT')" @click="toggleMenu"
      title="Finance and Accounting"
    >
      <div class="col-md-10">
        <svg-icon class="nc-icon" icon="revenue" :has-fill="true" />
        <span class="menu-title-name">REVENUE</span>
      </div>
      <div class="col-md-2">
        <svg-icon class="nc-icon-page" icon="down_arrow" :has-fill="true"
                  v-show="openMenu"
        />
        <svg-icon class="nc-icon-page" icon="right_arrow" :has-fill="true"
                  v-show="!openMenu"
        />
      </div>
    </div>

    <transition name="list">
      <div v-if="openMenu" class="menu-value">

        <sidebar-link :to="{ name: 'ChargedApplications' }"
                      v-if="finalUserPermissions.includes('CLEARINGFORWARDING_CHARGED-REQUESTS_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>CFA Applications Charges</p>
        </sidebar-link>

        <sidebar-link :to="{ name: 'CFABillsWaitingApproval' }"
                      v-if="finalUserPermissions.includes('CLEARINGFORWARDING_CHARGED-REQUESTS_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>CFA Applications Bills</p>
        </sidebar-link>

<!--        <hr class="custom-hr"  v-if="finalUserPermissions.includes('DOCUMENT-CHARGE_V2_GET-ALL-PAGEABLE_GET') && finalUserPermissions.includes('ACCOUNTANT')"/>-->

        <sidebar-link :to="{ name: 'ManifestDocumentBillImport' }"
                      v-if="finalUserPermissions.includes('DOCUMENT-CHARGE_V2_GET-ALL-PAGEABLE_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Manifests Charges</p>
        </sidebar-link>

        <!-- <sidebar-link :to="{ name: 'ManifestDocumentBillExport' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-PAGEABLE-ISCFA_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Export Manifests Charges</p>
        </sidebar-link> -->

        <sidebar-link :to="{ name: 'DCBillsWaitingApproval' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-DOCUMENT-CONTROL-PAGEABLE-BILLS_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Manifest Bills</p>
        </sidebar-link>

        <sidebar-link :to="{ name: 'ChargedTallySheets' }"
                      v-if="finalUserPermissions.includes('CLEARINGFORWARDING_CHARGED-REQUESTS_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Tally Charges</p>
        </sidebar-link>

        <sidebar-link :to="{ name: 'TallySheetBillsWaitingApproval' }"
                      v-if="finalUserPermissions.includes('CLEARINGFORWARDING_CHARGED-REQUESTS_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Tally Bills</p>
        </sidebar-link>

<!--        <hr class="custom-hr"  v-if="finalUserPermissions.includes('BILLS_ALL-DOCUMENT-CONTROL-PAGEABLE-BILLS_GET') && finalUserPermissions.includes('ACCOUNTANT')"/>-->
        <sidebar-link :to="{ name: 'PrincipalDetails' }" v-if="finalUserPermissions.includes('PRINCIPAL_LIST_PAGEABLE_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Principal Accounts</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'disbursementDeposit' }" v-if="finalUserPermissions.includes('DISBURSEMENT_PAYMENT_ALL_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> Disbursement Account Deposits </p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'subdisbursemenList' }" v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_LIST_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> Sub Disbursements</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'fdaList' }" v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_LIST_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>FDA</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'disbursementPaymentList' }" v-if="finalUserPermissions.includes('DISBURSEMENT_PAYMENT_LIST_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> PDA Invoice Payment Requests</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'principalRefunds' }" v-if="finalUserPermissions.includes('DISBURSEMENT_REFUND_ALL_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Principal Credit/Debit Requests</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'billOfLadingCharges' }" v-if="finalUserPermissions.includes('DISBURSEMENT_INVOICE_ADD-ALL_POST') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Bill of Lading Charges</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'billOfLadingBills' }" v-if="finalUserPermissions.includes('DISBURSEMENT_INVOICE_ADD-ALL_POST') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Bill of Lading Bills</p>
        </sidebar-link>

        <!-- <hr class="custom-hr">-->

        <sidebar-link
          :to="{ name: 'customerStatement' }"
          v-if="finalUserPermissions.includes('STATEMENT_GET_POST') && finalUserPermissions.includes('ACCOUNTANT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Customer Statement</p>
        </sidebar-link>

        <!-- <sidebar-link :to="{ name: 'AgencyCharges' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-PAGEABLE-ISCFA_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Agency Charges</p>
        </sidebar-link>

        <sidebar-link :to="{ name: 'AgencyBillsWaitingApproval' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-PAGEABLE-ISCFA_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Agency bills</p>
        </sidebar-link>

        <hr class="custom-hr"/>

        <sidebar-link :to="{ name: 'TallyCharges' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-PAGEABLE-ISCFA_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Loading & Discharge Charges</p>
        </sidebar-link>

        <sidebar-link :to="{ name: 'TallyStuffingCharges' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-PAGEABLE-ISCFA_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Stuffing Charges</p>
        </sidebar-link>

        <sidebar-link :to="{ name: 'TallyDestuffingCharges' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-PAGEABLE-ISCFA_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Destuffing Charges</p>
        </sidebar-link>

        <sidebar-link :to="{ name: 'TallyBillsWaitingApproval' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-PAGEABLE-ISCFA_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>Tallying bills</p>
        </sidebar-link>

        <hr class="custom-hr"/>

        <sidebar-link :to="{ name: 'CFACustomerBillsList' }"
                      v-if="finalUserPermissions.includes('BILLS_ALL-CFA-CUSTOMER-PAGEABLE-BILLS_GET') && finalUserPermissions.includes('ACCOUNTANT')">
          <svg-icon class="nc-icon-inner" icon="circle" :has-fill="true" /><p>My Billed Applications</p>
        </sidebar-link> -->
      </div>
    </transition>
  </div>
</template>

<script>

import SvgIcon from '../../components/SvgLoader/svgLoader';

export default {

  name: 'Revenue',

  data () {
    return {

      finalUserPermissions: [],
      openMenu: false

    };
  },

  components: {
    SvgIcon
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    this.openMenu = typeof (localStorage.getItem('revenue-side-menu')) ? JSON.parse(localStorage.getItem('revenue-side-menu')) : false;

    this.auto();
  },

  methods: {

    toggleMenu () {
      this.openMenu = !this.openMenu;
      localStorage.setItem('revenue-side-menu', this.openMenu);
    },

    auto(){

      if(this.finalUserPermissions.includes('PRINCIPAL_LIST_PAGEABLE_GET')){
        this.openMenu = true;

      }
    }

  }

};
</script>

<style scoped>

    .menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 13px;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .8s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }

    .list-enter-active, .list-leave-active {
        transition: all .7s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }

    .nc-icon {
        padding-right: 8px;
        padding-top: 4px;
        font-size: 20px !important;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        padding-left: 10px;
        font-weight: normal !important;
    }

    .sidebar .nav li .nav-link:hover .nc-icon-inner, body > .navbar-collapse .nav li .nav-link:hover .nc-icon-inner {
        fill: #d59a18;
    }

    .menu-title:hover .nc-icon , .menu-title:hover .nc-icon-page {
        fill: #d59a18;
    }

    .nc-icon {
        position: absolute;
        top: -10px;
        left: 15px;
        font-size: 25px !important;
        fill: #FFFFFF;
    }

    .nc-icon-inner {
        font-size: 10px !important;
        padding-left: 30px;
        fill: #FFFFFF;
    }

    .nc-icon-page {
        font-size: 10px !important;
        fill: #FFFFFF;
    }

  .custom-hr {
    background-color: #DCDCDC;
    width: auto;
    margin-left: 10%;
    margin-right: 10%;
  }

</style>
