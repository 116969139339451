<template>
  <div>
    <div
      class="menu-title row"
      v-if="finalUserPermissions.includes('CUSTOMERS_ALL-PAGEABLE_GET') ||
        finalUserPermissions.includes('ROLES_ALL_PAGEABLE_GET') ||
        finalUserPermissions.includes('USERS_ALLUSERS_GET')"
      @click="toggleMenu"
      title="System Users Configurations"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="user_management"
          :has-fill="true"
        />
        <span class="menu-title-name">MANAGE USER</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenu"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenu"
        />
      </div>
    </div>

    <transition name="list">
      <div
        v-if="openMenu"
        class="menu-value"
      >
        <sidebar-link
          :to="{ name: 'Customer' }"
          v-if="finalUserPermissions.includes('CUSTOMERS_ALL-PAGEABLE_GET')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Customer</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'Roles' }"
          v-if="finalUserPermissions.includes('ROLES_ALL_PAGEABLE_GET')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>Roles</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'Users' }"
          v-if="finalUserPermissions.includes('USERS_ALLUSERS_GET')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          /><p>User</p>
        </sidebar-link>
      </div>
    </transition>
  </div>
</template>
<script>

import SvgIcon from '../../components/SvgLoader/svgLoader'

export default {

  name: 'UserManagement',

  data () {
    return {

      finalUserPermissions: [],
      openMenu: false

    }
  },

  components: {
    SvgIcon
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    this.openMenu = typeof (localStorage.getItem('user-side-menu')) ? JSON.parse(localStorage.getItem('user-side-menu')) : false
  },

  methods: {

    toggleMenu () {
      this.openMenu = !this.openMenu
      localStorage.setItem('user-side-menu', this.openMenu)
    }

  }

}
</script>

<style scoped>

    .menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 13px;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .8s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }

    .list-enter-active, .list-leave-active {
        transition: all .7s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }

    .nc-icon {
        padding-right: 8px;
        padding-top: 4px;
        font-size: 20px !important;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        padding-left: 10px;
        font-weight: normal !important;
    }

    .sidebar .nav li .nav-link:hover .nc-icon-inner, body > .navbar-collapse .nav li .nav-link:hover .nc-icon-inner {
        fill: #d59a18;
    }

    .menu-title:hover .nc-icon , .menu-title:hover .nc-icon-page {
        fill: #d59a18;
    }

    .nc-icon {
        position: absolute;
        top: -10px;
        left: 15px;
        font-size: 25px !important;
        fill: #FFFFFF;
    }

    .nc-icon-inner {
        font-size: 10px !important;
        padding-left: 30px;
        fill: #FFFFFF;
    }

    .nc-icon-page {
        font-size: 10px !important;
        fill: #FFFFFF;
    }

</style>
