<template>
  <div>
    <card>
      <h2>View All resources</h2>
    </card>
    <card>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Resource Name
            </th>
            <th scope="col">
              Used In
            </th>
            <th scope="col">
              Shared By Clerks
            </th>
            <th scope="col">
              May shift Before Completion
            </th>
            <th scope="col">
              Is Active
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data,index) in tallyClerkDevices">
            <th scope="row">
              {{ index+1 }}
            </th>
            <td>{{ data.device.name }}</td>
            <td>{{ data.device.usedIn }}</td>
            <td>{{ data.device.sharedByClerks? "Shared":"Not Shared" }}</td>
            <td>{{ data.device.mayShiftShipBeforeCompletion? "May Shift":"May not Shift" }}</td>
            <td>{{ data.device.active? "Active":"Not Active" }}</td>
          </tr>
        </tbody>
      </table>
    </card>
  </div>
</template>

<script>
import TALLY_API from '../../../api/tally'

export default {
  name: 'ResourceClerkView',

  created () {
    this.$store.dispatch("pagenames/currentPageName", "Resources");

    this.getAllResourceClerks()
  },

  data () {
    return {
      tallyClerkDevices: []
    }
  },

  methods: {

    getAllResourceClerks () {
      TALLY_API.getTallyClerkResources().then(response => {
        this.tallyClerkDevices = response.data.data
      })
    }
  }
}
</script>

<style scoped>

</style>
