<template>
  <div>
    <card>
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="parseInt(pageSize)"
        :pageNo="pageNo"
        :myLoader="myLoader"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading Manifest charges'"
        :hasManageButton="false"
        :placeholder="'Search by BL/AIRWAY BILL/RCN or file number'"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :pageNumber="pageNumber"
        :buttonList="[ {'code' : 'BillGenerate' , 'name' : 'View Charge' , 'class' : 'btn-primary'} ]"
        :whichModuleAndType="whichModuleAndType"
        :is-hovered="isHovered"
        :allCompanies="allCompanies"
        :permissions="[ {key: 'searchPermission', value: 'DOCUMENT-CHARGE_V2_SEARCH_GET'} ]"
        @search="searchGeneralChargeParams"
        @resetParams="resetParams"
        @showToolTip="showToolTip"
        @hideToolTip="hideToolTip"
        @onChange="onChange"
        @goToPage="goToPage"
        @goToBillGenerate="goToApplication"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading document charges, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

import API from '../../../../api'
import {mapGetters, mapActions} from "vuex";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../../components/globalLoader'
import DataTable from '../../../../components/DataTable/dataTable'

export default {

  name: 'ChargedApplications',

  data () {

    return {

      finalUserPermissions: [],

      color: 'green',
      size: '13px',
      loading: true,

      requests: [],
      filteredRequests: [],
      noDataFound: false,
      reloadAllRequest: false,
      hasSearchedVariable: false,
      searchParameter: '',
      pageNo: 0,
      pageSize: 15,
      myLoader: false,
      isHovered: [],
      myFirstLoader: false,
      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: '',
      whichModuleAndType: 'dcCharge',
      allCompanies: [],
      newParams: '',
      isPage: 0,
      pageNoPart: 0,

      tableHeaders: ['Tasac Control Number', 'Charge Status', 'Company', 'Carrier', 'Voyage Number', 'Number Of B/L' , 'Operation Type', 'Billed'],
      tableDataKeys: ['applicationReference', 'status', 'company', 'carrier', 'voyageNumber', 'manifestCount' , 'operationType', 'billed'],

    }

  },

  components: {

    PulseLoader,
    GlobalModal,
    DataTable

  },

  created () {

    this.loadAllCompanies();
    this.loadRequest()

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    this.$store.dispatch('pagenames/currentPageName', 'Charged Manifests');
  },

  computed: {

    ...mapGetters({

      getRequestList: "dcbilling/getRequestList",
      getSuccessMessage: "dcbilling/getSuccessMessage",
      getErrorAlert: "dcbilling/getErrorAlert",
      getErrorMessage: "dcbilling/getErrorMessage",
      getSuccessAlert: "dcbilling/getSuccessAlert",
      getMyLoader: "dcbilling/getMyLoader",
      getMyFirstLoader: "dcbilling/getMyFirstLoader",
      getPageNo: "dcbilling/getPageNo",
      getPageSize: "dcbilling/getPageSize",
      getRequestId: "dcbilling/getRequestId",
      getTotalElement: "dcbilling/getTotalElement",
      getTotalPage: "dcbilling/getTotalPage",
      getPageNumber: "dcbilling/getPageNumber",
      getIsFirst: "dcbilling/getIsFirst",
      getIsLast: "dcbilling/getIsLast",
      getSearchParams: "dcbilling/getSearchParams",
      getHasSearchedVariable: "dcbilling/getHasSearchedVariable"
    }),

    getIsPage: {

      get: function () {
          return this.isPage
      },
      set: function (newValue) {
          this.isPage = newValue;
      }

    }

  },

  methods: {

    loadAllCompanies() {

      this.myloader = true;

      API.getCompany()
          .then((response) => {
          if (response.data.status) {
              this.allCompanies = response.data.data;
          } else {
          }
          })
          .catch((e) => {
          this.ErrorMessage =
              ", TASAC Customers not loaded, please contact system administrator";
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = "";
          this.SuccessAlert = false;
          this.myloader = false;
          });

    },

    resetParams () {

      this.loadPageableRequestOnCreated(this.pageNo , this.pageSize)
      this.$store.dispatch("dcbilling/hasSearchngParams", false);
      this.newParams = ""
      this.pageNoPart = 0
      this.isPage = this.pageNoPart + 1
      this.filteredRequests = []

    },

    loadRequest() {

        if (localStorage.getItem("search-params") !== null) {

          this.newParams = ""
          this.newParams = localStorage.getItem("search-params")

          let secondPart = this.newParams.split("size=")[1]
          this.pageNoPart = parseInt(secondPart.split("page=")[1])
          this.isPage = this.pageNoPart + 1

          //this.searchGeneralChargeParams(this.newParams) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page with selected page bug
          this.searchGeneralChargeParams(this.newParams, this.pageNoPart) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page without selected page bug

        } else {

            if (localStorage.getItem("page-no") !== null && localStorage.getItem("page-size") !== null) {

                this.$store.dispatch("dcbilling/pageSizing", parseInt(localStorage.getItem("page-size")));
                this.$store.dispatch("dcbilling/pageNumbering", parseInt(localStorage.getItem("page-no")));

                this.loadPageableRequestOnCreated(parseInt(localStorage.getItem("page-no")) , parseInt(localStorage.getItem("page-size")))
                this.isPage = parseInt(localStorage.getItem("page-no")) + 1

            } else {

              this.$store.dispatch("dcbilling/pageSizing", this.pageSize);
              this.$store.dispatch("dcbilling/pageNumbering", this.pageNo);

              this.loadPageableRequestOnCreated(this.pageNo , this.pageSize)
              this.isPage = this.pageNo + 1

            }

            this.$store.dispatch("dcbilling/hasSearchngParams", false);
            this.newParams = ""
            this.pageNoPart = 0

        }

        this.filteredRequests = []

      },

    sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

          let requestObject = {
            ...data[x],
            applicationReference: data[x].manifest !== null ? data[x].manifest.applicationReference : data[x].document.tasacControlNumber,
            status: data[x].billed ? 'BILLED' : 'CHARGED',
            carrier: data[x].company.companyCode,
            voyageNumber: data[x].manifest !== null ? data[x].manifest.voyageNumber : data[x].document.voyageNumber,
            billed: data[x].billed ? 'YES' : 'NO'
            }

            this.filteredRequests.push(requestObject)

        }

      },

    ...mapActions("dcbilling", [

      "getAllUnbilledDocumentControlCharges",
      "getAllUnbilledDocumentControlChargesAfterCreated",
      "searchWithGeneralChargeParams",

    ]),

    loadParameters(pageNo , pageSize) {

        return new Promise((resolve) => {

          this.$store.dispatch("dcbilling/pageSizing", pageSize);
          this.$store.dispatch("dcbilling/pageNumbering", pageNo);

          resolve();

        })

    },

    loadSearchParameters(params, fromApplicationPageNo) {

        let fromApplicationPageNoOrNot
        typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0

        let firstPart = params.split("&page=")[0]
        let secondPart = firstPart.split("size=")[0]
        this.newParams = ""

        this.isPage = fromApplicationPageNo
        this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot

        return new Promise((resolve) => {

          this.$store.dispatch("dcbilling/pageSizing", this.pageSize);
          this.$store.dispatch("dcbilling/pageNumbering", fromApplicationPageNoOrNot);
          this.$store.dispatch("dcbilling/paramsSearching", this.newParams);

          resolve();

        })

    },

    setAllGetters(flag) {

      this.requests = this.getRequestList;
      flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
      flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
      this.noDataFound = this.getNoDataFound;
      this.SuccessMessage = this.getSuccessMessage;
      this.ErrorMessage = this.getErrorMessage;
      this.ErrorAlert = this.getErrorAlert;
      this.SuccessAlert = this.getSuccessAlert;
      this.pageNo = this.getPageNo;
      this.pageSize = this.getPageSize;

      this.totalElements = this.getTotalElement;
      this.totalPages = this.getTotalPage;
      this.pageNumber = this.getPageNumber;
      this.isFirst = this.getIsFirst;
      this.isLast = this.getIsLast;

      this.sortingData(this.requests)

    },

    searchGeneralChargeParams(params, fromApplicationPageNo) {

      this.newParams = params

      this.filteredRequests = []

      this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.searchWithGeneralChargeParams({}).then(() => {

          this.setAllGetters('onCreated')

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

      }).catch(() => {


      });

    },

    loadPageableRequestOnCreated(pageNo , pageSize) {

      this.loadParameters(pageNo , pageSize).then(() => {

        this.getAllUnbilledDocumentControlCharges({}).then(() => {

          this.setAllGetters('onCreated');

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

      }).catch(() => {


      });

    },

    loadPageableRequestsAfterCreated(pageNo , pageSize) {

      this.loadParameters(pageNo , pageSize).then(() => {

        this.getAllUnbilledDocumentControlChargesAfterCreated({}).then(() => {

          this.setAllGetters('afterCreated');

        });

        this.myLoader = this.getMyLoader;

      }).catch(() => {


      });

    },

    goToPage(page) {

      if(page > 0) {page = page - 1;}
      this.isPage = page + 1

      this.pageNo = page;

      if (this.getHasSearchedVariable) {

        let firstPart = this.newParams.split("page=")[0]
        this.newParams = ""
        this.newParams = firstPart + '&page=' + this.pageNo
        this.pageNoPart = this.isPage

        this.searchGeneralChargeParams(this.newParams, this.pageNoPart);
        this.filteredRequests = []

      } else {

        this.loadPageableRequestsAfterCreated(this.pageNo , this.pageSize);
        this.filteredRequests = []
      }

    },

    onChange (event) {

      this.$store.dispatch("dcbilling/pageSizing", event);
      this.$store.dispatch("dcbilling/pageNumbering", 0);
      localStorage.removeItem("page-no")
      localStorage.removeItem("page-size")
      this.isPage = 0

      this.pageSize = this.getPageSize;
      this.pageNo = this.getPageNo;

      if (this.getHasSearchedVariable) {

        let firstPart = this.newParams.split("&page=")[0]
        let secondPart = firstPart.split("size=")[0]
        let thirdPart = firstPart.split("size=")[1]
        this.newParams = ""
        this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0
        this.pageNoPart = 0

        this.searchGeneralChargeParams(this.newParams , this.pageNoPart);
        this.filteredRequests = []

      } else {

        this.loadPageableRequestOnCreated(this.pageNo , this.pageSize);
        this.filteredRequests = []
      }

    },

    showToolTip (id) {

      this.isHovered = [id]
      
    },

    hideToolTip (id) {

      this.isHovered.splice(this.isHovered.indexOf(id), 1)

    },

    goToApplication (object) {

      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
      this.$router.push({name: 'ManifestCharge', params: {documentChargeId : object.documentChargeId}})
      if (this.getHasSearchedVariable) {

          localStorage.setItem("search-params", this.newParams);
          // localStorage.setItem("cfa-customer", JSON.stringify(this.allCompanies));
          this.newParams = ""
      } else {
          localStorage.setItem("page-no", this.getPageNo);
          localStorage.setItem("page-size", this.getPageSize);
      }

    }

  }

}
</script>

<style scoped>

    .btn-space {
        margin-right: 10px !important;
    }

    .btn-success-custom {
        padding: 0 4px 0 4px;
        color: #3cb371;
    }

    .btn-success-custom:hover {
        cursor: pointer;
        color: green;
    }

    .table-striped tr {
        line-height: 20px;
    }

    .disabled-click {
        pointer-events:none;
        color:#dcdcdc;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .enabled-click {
        pointer-events:auto;
    }

    .search .form-group .input-group .input-group-addon {
        width: 40px;
        text-align: center;
        padding-top: 3.5px;
        background-color: rgba(220, 220, 220, .3);
    }

    .disable-highlight:hover span {
        cursor: pointer;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .refresh-all {
        margin-top: 6px;
        margin-right: 20px
    }

    .refresh-all:hover {
        cursor: pointer;
    }

    .search .form-group .input-group #inputGroup {
        width: 250px;
        padding-top: 3.5px;
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        background-color: #FFFFFF !important;
    }

    .table td , .table th {
        text-align: center;
    }

    .text-center {
        text-align: center !important;
    }

    .table-hover tbody tr:hover {
        background-color: rgba(153, 186, 221, .5) !important;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .more-customer-details:hover {
        cursor: pointer;
        color: #d59a18;
    }

    .close-info {
        color: blue !important;
    }

    .close-info:hover {
        cursor: pointer;
        color: green !important;
    }

</style>
