<template>
  <div @click="removeSearchParams">
    <div
      class="menu-title row"
      v-if="finalUserPermissions.includes('CFA_IMPORT')"
      @click="toggleMenu('im')"
      title="Clearing and Forwarding - Import Applications"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="cfa_import"
          :has-fill="true"
        />
        <span class="menu-title-name">CFA - IMPORT APPLICATIONS</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenuImport"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenuImport"
        />
      </div>
    </div>
    <transition name="list">
      <div
        v-if="openMenuImport"
        class="menu-value"
      >
        <sidebar-link
          :to="{ name: 'MyAssignedActivity' }"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>My Assigned Activities</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-submitted' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>New</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-accepted' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Accepted</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-returned-for-major-changes' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Major Issue</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-returned-for-minor-changes' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Minor Issue</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-resubmitted-after-changes' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Returned</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-assessed' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Tariffed</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-assessment-rejected' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Rejected Tariff</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-charged' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Charged</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-charge-rejected' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Rejected Charges</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-billed' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Billed</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-assessment-sent-to-gepg' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Bill Approved</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-bill-paid' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Paid</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-is-dismissed' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Dismissed</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'application',params: { status: 'all' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>All</p>
        </sidebar-link>


        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-closed' ,requestType:'import'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Closed</p>
        </sidebar-link>
      </div>
    </transition>

    <div
      class="menu-title row"
      v-if="finalUserPermissions.includes('CFA_EXPORT')"
      @click="toggleMenu('ex')"
      title="Clearing and Forwarding - Export Applications"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="cfa_export"
          :has-fill="true"
        />
        <span class="menu-title-name">CFA - EXPORT APPLICATIONS</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenuExport"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenuExport"
        />
      </div>
    </div>

    <transition name="list">
      <div
        v-if="openMenuExport"
        class="menu-value"
      >
        <sidebar-link
          :to="{ name: 'MyAssignedActivity2' }"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_EXPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>My Assigned Activities</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'application', params: { status: 'request-submitted' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>New</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-accepted' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Accepted</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-returned-for-major-changes' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Major Issue</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-returned-for-minor-changes' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Minor Issue</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-resubmitted-after-changes' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Returned</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-assessed' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Tariffed</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-assessment-rejected' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Rejected Tariff</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-charged' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Charged</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-charge-rejected' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Rejected Charges</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-billed' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Billed</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-assessment-sent-to-gepg' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Bill Approved</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-bill-paid' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Paid</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-is-dismissed' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Dismissed</p>
        </sidebar-link>
        <sidebar-link
          :to="{ name: 'application',params: { status: 'all' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>All</p>
        </sidebar-link>


        <sidebar-link
          :to="{ name: 'application',params: { status: 'request-closed' ,requestType:'export'}}"
          v-if="finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
            && finalUserPermissions.includes('CFA_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Closed</p>
        </sidebar-link>
      </div>
    </transition>

    <sidebar-link :to="{ name: 'TASACNewApplication' }" v-if="finalUserPermissions.includes('CFA_APPLICATIONS')">
      <svg-icon
      class="not-inner-but-outer-icon"
      icon="opened-folder"
      :has-fill="true"
    />
    <div class="menu-title-name-solo">NEW APPLICATION</div>
    </sidebar-link>

    <sidebar-link :to="{ name: 'AllAssignedActivity' }" v-if="finalUserPermissions.includes('CFA_APPLICATIONS')">
      <svg-icon
      class="not-inner-but-outer-icon"
      icon="icon-document"
      :has-fill="true"
    />
    <div class="menu-title-name-solo">ACTIVITY ASSIGNMENTS</div>
    </sidebar-link>
  </div>
</template>

<script>

import SvgIcon from '../../components/SvgLoader/svgLoader'

export default {

  name: 'ClearingAndForwarding',

  data () {
    return {

      finalUserPermissions: [],
      openMenuExport: false,
      openMenuImport: false,
      openMenuApplications:false,

    }
  },

  components: {
    SvgIcon
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    this.openMenuImport = typeof (localStorage.getItem('cfa-import-side-menu')) ? JSON.parse(localStorage.getItem('cfa-import-side-menu')) : false
    this.openMenuExport = typeof (localStorage.getItem('cfa-export-side-menu')) ? JSON.parse(localStorage.getItem('cfa-export-side-menu')) : false
    this.openMenuApplications = typeof (localStorage.getItem('cfa-applications')) ? JSON.parse(localStorage.getItem('cfa-applications')) : false

  },

  methods: {

    toggleMenu (type) {
      if (type === 'im') {
        this.openMenuImport = !this.openMenuImport
        localStorage.setItem('cfa-import-side-menu', this.openMenuImport)
      }

      if (type === 'ex') {
        this.openMenuExport = !this.openMenuExport
        localStorage.setItem('cfa-export-side-menu', this.openMenuExport)
      }

      if (type === 'ap') {
        this.openMenuApplications = !this.openMenuApplications;
        localStorage.setItem('cfa-applications', this.openMenuApplications)
      }
    },

    removeSearchParams () {

        localStorage.removeItem('search-params')
        localStorage.removeItem("page-no")
        localStorage.removeItem("page-size")

    }

  }

}
</script>

<style scoped>

    .menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 12px !important;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-title-name-solo {
      font-weight: normal;
      margin-top: -36px;
      margin-left:32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    .list-enter-active, .list-leave-active {
        transition: all .7s;
    }

    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }

    .not-inner-but-outer-icon {
      padding-right: 8px;
        padding-top: 4px;
        font-size: 22px !important;
        fill: #FFFFFF;
    }

    .nc-icon {
        padding-right: 8px;
        padding-top: 4px;
        font-size: 20px !important;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        padding-left: 10px;
        font-weight: normal !important;
    }

    .sidebar .nav li .nav-link:hover .nc-icon-inner, .sidebar .nav li .nav-link:hover .not-inner-but-outer-icon, body > .navbar-collapse .nav li .nav-link:hover .nc-icon-inner {
        fill: #d59a18;
    }

    .menu-title:hover .nc-icon, .menu-title:hover .nc-icon-page {
        fill: #d59a18;
    }

    .nc-icon {
        position: absolute;
        top: -10px;
        left: 15px;
        font-size: 25px !important;
        fill: #FFFFFF;
    }

    .nc-icon-inner {
        font-size: 10px !important;
        padding-left: 30px;
        fill: #FFFFFF;
    }

    .nc-icon-page {
        font-size: 10px !important;
        fill: #FFFFFF;
    }

</style>
