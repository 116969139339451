<template>

  <div>
    <crud-notification
      :error-alert="ErrorAlert"
      :error-message="ErrorMessage"
      :success-alert="SuccessAlert"
      :success-message="SuccessMessage"
      @enter="enter"
      @fadeMe="fadeMe"
    />
    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
            <li v-if="finalUserPermissions.includes('API_TALLY-CLERK-ASSIGNMENTS_AUTO_ASSIGNMENTS_POST')">
              <button @click="goOperation(selectedNotificationId)" class="btn btn-rosy-brown btn-rosy-brown-fill mr-2 custom-btn">
              Operations
            </button>
            </li>
            <li v-if="!selectedNotification.assignmentApproved && finalUserPermissions.includes('API_TALLY-NOTIFICATIONS_PUT')">
              <button @click="showEditNotification" class="btn btn-linen btn-linen-fill mr-2 custom-btn">
                Edit
              </button>
            </li>
            <li v-if="!selectedNotification.assignmentApproved && finalUserPermissions.includes('API_TALLY-CLERK-ASSIGNMENTS_APPROVE-BY-NOTIFICATION_{NOTIFICATIONID}_GET')">
              <button @click="approve" class="btn btn-purple btn-purple-fill custom-btn">
                Approve
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>
    <card>
      <!-- <div class="row">
        <div class="col-md-12">
          <button @click="goOperation(selectedNotificationId)" class="btn btn-default custom-btn mr-2"
                  v-if="finalUserPermissions.includes('API_TALLY-CLERK-ASSIGNMENTS_AUTO_ASSIGNMENTS_POST')"
                  style="width: 100px">
            Operations
          </button>
          <button @click="showEditNotification" class="btn btn-primary  custom-btn mr-2"
                  style="width: 100px"
                  v-if="!selectedNotification.assignmentApproved && finalUserPermissions.includes('API_TALLY-NOTIFICATIONS_PUT')">
            Edit
          </button>
          <button @click="approve" class="btn btn-success custom-btn mr-2"
                  style="width: 100px"
                  v-if="!selectedNotification.assignmentApproved && finalUserPermissions.includes('API_TALLY-CLERK-ASSIGNMENTS_APPROVE-BY-NOTIFICATION_{NOTIFICATIONID}_GET')">
            Approve
          </button>
        </div>

      </div> -->
      <div class="legend">
        <h6 class="legend-title">
          Notification Details
        </h6>
        <div v-if="showSelectedNotification">
          <div class="row div-table-row">
            <div class="col-md-2 div-table div-table-title mb-3">
              Voyage
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.voyage}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Assignment Approved
            </div>
            <div STYLE="color: #7b1fa2" class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.assignmentApproved ? 'YES' : 'NO'}}
            </div>


            <div class="col-md-2 div-table div-table-title mb-3">
              Start Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.startTime ? dateToHuman(selectedNotification.startTime) : ''}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              End Time
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.endTime ? dateToHuman(selectedNotification.endTime) : ''}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Location Name
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.locationName}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Location Type
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.locationType}}
            </div>

            <div class="col-md-2 div-table div-table-title mb-3">
              Operation Type
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.operationType}}
            </div>
            <div class="col-md-2 div-table div-table-title mb-3">
              Cargo Type
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.cargoType}}
            </div>
            <div class="col-md-2 div-table div-table-title mb-3">
              Berth
            </div>
            <div class="col-md-2 div-table div-table-value mb-3">
              {{selectedNotification.berth}}
            </div>
            <div class="col-md-2 div-table div-table-title mb-3">
              Vessel Name
            </div>
            <div class="col-md-10 div-table div-table-value mb-3">
              {{selectedNotification.vesselName}}
            </div>

          </div>
        </div>

      </div>
      <div class="legend">
        <h6 class="legend-title"> Tally Clerk Assignments </h6>
        <div class="row">
          <div class="col-md-12">
            <button @click.prevent="showAssignmentDetails"
                    v-if="finalUserPermissions.includes('API_TALLY-CLERK-ASSIGNMENTS_POST')"
                    class="btn btn-success mr-2 custom-btn" style="float: right">
              Add Assignment
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <data-table
              :has-delete-button="finalUserPermissions.includes('API_TALLY-CLERK-ASSIGNMENTS_{TALLYCLERKASSIGNMENTID}_DELETE')"
              :is-pageable="false"
              :hasFilter="false"
              :hasPagination="false"
              :no-data-found="noAssignmentDataFound"
              :table-data="filteredRequests"
              :table-data-keys="assignmentDetailsKeys"
              :table-headers="assignmentDetailsHeaders"
              :permissions="[ {key: 'searchPermission', value: 'API_TALLY-CLERK-ASSIGNMENTS_{TALLYCLERKASSIGNMENTID}_DELETE'} ]"
              @goToDelete="deleteAssignment"
            ></data-table>
          </div>
        </div>

      </div>
      <div class="text-center row pt-2">
        <div
          class="col-md-12"
        >
          <button
            @click.prevent="goBack()"
            class="btn btn-default btn-fill btn-space custom-btn"
            style="float:right;"
          >
            <font-awesome-icon icon="undo"/>
            Back
          </button>
        </div>
      </div>

    </card>


    <modal v-if="addClerkAssignment">
      <div class="modal-title col-md-12" slot="header">
        <div class="row">
          <div class="col-md-12">
            <b> Assign Clerk To Tally Shift</b>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>
      <div slot="body">
        <div>
          <div class="col-md-12">
            <div v-if="assignmentsError">
              <span class="text-danger">Select All Fields</span>
            </div>
            <form>
              <!--              <div class="form-group">-->
              <!--                <label for="operationType2">Operation Type</label>-->
              <!--                <select class="form-control" id="operationType2" v-model="operationType">-->
              <!--                  <option value=""></option>-->
              <!--                  <option value="DISCHARGING">DISCHARGING</option>-->
              <!--                  <option value="LOADING">LOADING</option>-->
              <!--                  <option value="STUFFING">STUFFING</option>-->
              <!--                  <option value="DESTUFFING">DESTUFFING</option>-->
              <!--                </select>-->
              <!--              </div>-->

              <div class="row">
                <div class="col-md-12 form-group">
                  <label>Select Clerk Name(s)</label>
                  <multi-select
                    :options="allUsers"
                    :selected-options="items"
                    @select="onSelect"
                    id="multiSelect2"
                    placeholder="select Clerk Name(s)"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <label>Select Resource(s)</label>
                  <multi-select
                    :options="resources"
                    :selected-options="itemsResources"
                    @select="onSelectResource"
                    placeholder="select Resource(s)"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 form-group">
                  <base-input @change="StartDateError = false" label="Start Date"
                              type="date" v-model="startTime" :min="todayDate"></base-input>
                  <div v-if="StartDateError">
                    <div class="error">This field is required</div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <label>Select Shift</label>
                  <Shifts @selectedShiftEvent="onSelectedShift" ref="shifts" type="shiftClerk"></Shifts>
                </div>
              </div>

            </form>
          </div>

        </div>
        <div class="row mt-2">
          <div class="col-md-12">
            <button @click="addClerkAssignment=false" class="btn  btn-fill btn-outline-info float-right custom-btn">Close</button>
            <button @click="assignClerk" class="btn btn-success float-right mr-2 custom-btn">Assign</button>

          </div>
        </div>
      </div>
    </modal>

    <modal v-if="editNotificationDetails">
      <div class="modal-title col-md-12" slot="header">
        <div class="row">
          <div class="col-md-12">
            <b> Edit Tally Notification</b>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <div class="col-md-12">
          <form>
            <div class="row" v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <div class="col-md-6">
                <!--            STUFFING AND DISTUFFING-->
                <div
                  class="form-group"
                  v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
                  <label class="notification-title" for="containerNo">Container Number</label>
                  <input
                    class="form-control"
                    id="containerNo"
                    type="text"
                    v-model="details.containerNo"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!--            STUFFING AND DISTUFFING-->
                <div
                  class="form-group"
                  v-if="
                tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''
              "
                >
                  <label class="notification-title" for="masterBl">Master BL</label>
                  <input
                    class="form-control"
                    id="masterBl"
                    type="text"
                    v-model="details.masterBl"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="tallyType !== ''">
              <div class="col-md-6"
                   v-if="
                tallyType !== 'STUFFING' &&
                  tallyType !== 'DESTUFFING' &&
                  tallyType !== ''">
                <div class="form-group"
                     v-if="
                tallyType !== 'STUFFING' &&
                  tallyType !== 'DESTUFFING' &&
                  tallyType !== ''"
                >
                  <label class="notification-title" for="numberOfGangs">Number of Gangs</label>
                  <input
                    class="form-control"
                    id="numberOfGangs"
                    type="number"
                    v-model="details.numberOfGangs"
                  />
                </div>
              </div>
              <div class="col-md-6"
                   v-if="
                tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''
              ">
                <!--            STUFFING AND DISTUFFING-->
                <div
                  class="form-group"
                  v-if="
                tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''
              "
                >
                  <label class="notification-title" for="shippingOrderNo"
                  >Shipping Order NUmber</label
                  >
                  <input
                    class="form-control"
                    id="shippingOrderNo"
                    type="text"
                    v-model="details.shippingOrderNo"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="notification-title" for="quantity">Quantity/Tonnage/No Containers</label>
                  <input
                    class="form-control"
                    id="quantity"
                    type="text"
                    v-model="details.quantity"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="tallyType !== ''">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Company</label>
                  <model-list-select :custom-text="companyName"
                                     :list="companies"
                                     option-text="Company"
                                     option-value="companyId"
                                     placeholder="select Company"
                                     v-model="details.selectedCompany">
                  </model-list-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="notification-title" for="locationName"
                  >Location Name</label
                  >
                  <input
                    class="form-control"
                    id="locationName"
                    type="text"
                    v-model="details.locationName"
                  />
                  <div class="error" v-if="locationNameError">
                <span class="text-danger" v-if="!$v.locationName.required"
                >This Field is Required</span
                >
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" v-if="tallyType !== ''">
                  <label class="notification-title">Location Type</label>
                  <select class="form-control" v-model="details.locationType">
                    <option/>
                    <option value="ICD">
                      ICD
                    </option>
                    <option value="TERMINAL">
                      TERMINAL
                    </option>
                    <option value="DEPOT">
                      DEPOT
                    </option>
                  </select>
                  <div class="error" v-if="locationTypeError">
                <span class="text-danger" v-if="!$v.locationType.required"
                >This Field is Required</span
                >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" v-if="tallyType !== ''">
                  <label for="vesselType" class="notification-title">Cargo Type</label>
                  <select class="form-control" v-model="details.cargoType" id="vesselType">
                    <option value="Container">
                      Container
                    </option>
                    <option value="Vehicle">
                      Vehicle
                    </option>
                    <option value="Dry Bulk">
                      Dry Bulk
                    </option>
                    <option value="Liquid Bulk">
                      Liquid Bulk
                    </option>
                    <option value="Break Bulk">
                      Break Bulk
                    </option>
                    <option value="General Cargo">
                      General Cargo
                    </option>
                  </select>
                  <div class="error" v-if="cargoTypeError">
                <span class="text-danger" v-if="!$v.cargoType.required">This Field is Required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" v-if="tallyType !== ''">
                  <label class="notification-title" for="vesselName">Vessel Name</label>
                  <input
                    class="form-control"
                    id="vesselName"
                    type="text"
                    v-model="details.vesselName"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" v-if="tallyType !== ''">
                  <label class="notification-title" for="voyage">Voyage</label>
                  <input
                    class="form-control"
                    id="voyage"
                    type="text"
                    v-model="details.voyage"
                  />
                </div>
              </div>
            </div>
            <div class="row" v-if="tallyType !== ''">
              <div class="col-md-6">
                <base-input
                  @change="StartDateError = false"
                  label="Start Date"
                  type="datetime-local"
                  v-model="details.startTime"
                />
                <div class="error" v-if="startTimeError">
                  <span class="error text-danger" v-if="!$v.startTime.required"
                  >This field is required</span
                  >
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  :min="startTime"
                  @change="EndDateError = false"
                  label="End Date"
                  type="datetime-local"
                  v-model="details.endTime"
                />
              </div>
            </div>
            <hr/>
            <div class="row">
              <div class="col-md-12 text-right">
                <button @click.prevent="editNotificationData" class="btn btn-primary mr-2 custom-btn">Submit</button>
                <button @click.prevent="showEditNotification" class="btn-outline-info btn btn-fill mr-2 custom-btn"> Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import TALLYAPI from "../../../api/tally";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import Modal from "../../../components/Inputs/Modal"
  import Table from "../../../components/DataTable/table";
  import {ModelListSelect, MultiSelect} from "vue-search-select";
  import {required} from "vuelidate/lib/validators";
  import Shifts from "../subComponents/shifts";
  import DataTable from '../../../components/DataTable/dataTable'
  import CrudNotification from '../../../components/DataTable/crudNotification'
  import API from "../../../api";

  export default {
    name: "NotificationDetails",
    components: {
      Table,
      PulseLoader,
      Modal,
      ModelListSelect,
      MultiSelect,
      CrudNotification,
      DataTable,
      Shifts,

    },
    validations: {
      selectedClerk: {required},
      active: {required},
      selectedShift: {required},

      details: {
        startTime: {required},
        endTime: {required},
        locationName: {required},
        locationType: {required},
        cargoType: {required},
      }
    },
    created() {
      this.$store.dispatch('pagenames/currentPageName', 'Ship Tallying Notification Details');
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.selectedNotificationId = this.$route.params.documentId;
      this.getNotificationDetailsOnCreated();
      this.getAssignMentByNotification();
      this.getNotifications();
      this.getUsers();
      this.getAllResources();
      this.getCompanies()
    },

    data() {

      return {
        finalUserPermissions: [],
        selectedNotificationId: '',
        assignmentDetailsKeys: ["shiftName", "clerkName", "startTime", "endTime", "completed", "approved"],
        assignmentDetailsHeaders: ['SHIFT', 'CLERK', 'START TIME', 'END TIME', 'COMPLETED', 'APPROVAL'],
        assignmentDetails: [],
        filteredRequests: [],
        noAssignmentDataFound: false,
        todayDate: new Date().toJSON().slice(0, 10),

        editNotificationDetails: false,

        selectedNotification: {},
        showSelectedNotification: true,
        addClerkAssignment: false,
        notifications: [],
        items: [],
        operationType: "",
        tallyType: '',
        resources: [],
        itemsResources: [],
        lastSelectItemResource: {},

        loading: false,
        color: 'green',
        size: '15px',
        operation: {
          id: "",
          shift: {
            id: "",
            clerks: []
          }
        },
        assignments: [],
        ErrorMessage: "",
        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: "",
        assignmentsError: false,


        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        sortBy: 'createdAt',
        totalPages: 1,
        isPageable: true,
        pageNumber: 0,
        isFirst: true,
        isLast: false,
        nodataFound: false,
        myFirstLoader: false,
        totalElements: '',
        noDataFound: false,
        tableHeaders: ['Call Id', 'Voyage No.', 'Cargo Type', 'Start Time', 'End Time', 'Shipping Order No.'],
        tableDataKeys: ['callId', 'voyage', 'cargoType', 'startTime', 'endTime', 'shippingOrderNo'],


        allUsers: [],
        selectedClerk: "",
        active: true,
        activeError: false,
        completed: false,
        completedError: false,
        startTime: "",
        endTime: "",
        StartDateError: false,
        endDateError: false,
        selectedShift: "",
        operationShiftId: null,

        viewAssignment: true,
        newAssignment: false,

        details: {
          assignmentApproved: false,
          berth: null,
          callId: null,
          cargoType: null,
          completed: false,
          containerNo: null,
          documentId: null,
          endTime: null,
          houseBl: null,
          id: null,
          lastUpdatedAt: null,
          locationName: null,
          locationType: null,
          masterBl: null,
          shippingOrderNo: null,
          startTime: null,
          tallyResources: [],
          vesselCallId: null,
          voyage: null
        },

        cargoTypeError: false,
        startTimeError: false,
        endTimeError: false,
        locationTypeError: false,
        locationNameError: false,
        berthError: false,
      }
    },
    methods: {

      onSelect(items, lastSelectItem) {
        this.items = items;
        this.lastSelectItem = lastSelectItem;
      },

      getAllResources() {
        TALLYAPI.getAllResources().then((response) => {
          response.data.data.map((data) => {
            this.resources.push({value: data.id, text: data.name});
          });
        });
      },

      onSelectResource(items, lastSelectItem) {
        this.itemsResources = items;
        this.lastSelectItemResource = lastSelectItem;
      },

      approve() {
        this.$dialog.confirm('Approve assignment for <b> ['
          + this.selectedNotification.operationType +
          '] </b> Activity, that will involve vessel <b>[' +
          this.selectedNotification.vesselName +
          '] </b> <br> with voyage no: <b>[' +
          this.selectedNotification.voyage + '] </b> ', {
          loader: true,
          html: true,
          okText: 'Yes, approve',
          cancelText: 'No, close'
        }).then((dialog) => {
          this.loading = true;
          TALLYAPI.approveAssignmentsByNotification(this.selectedNotificationId).then(() => {
            this.loading = false;
            this.ErrorMessage = '';
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.SuccessMessage = 'Notification Approved';
            this.getNotifications = this.getNotifications();
          }).catch(() => {
            this.loading = false;
            this.loading = false;
            this.ErrorMessage = '';
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.SuccessMessage = 'Notification Failed to Approve';
          });
          dialog.close();
        }).catch(() => {
          this.loading = false
        })

      },

      showAssignmentDetails() {
        this.addClerkAssignment = !this.addClerkAssignment
      },

      closeModal(notification = {}) {
        this.showSelectedNotification = !this.showSelectedNotification;
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
        } else {
          this.ErrorAlert = !this.ErrorAlert;
        }
      },

      enter: function (el, done) {
        var that = this;

        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
        }, 3000); // hide the message after 3 seconds
      },

      assignClerk() {
        this.operationType = this.selectedNotification.operationType;
        if (this.operationType !== "" && this.operationShiftId && this.operationShiftId !== "" && this.startTime !== "" && this.tallyDeStaffingNotificationId !== "") {
          this.loading = true;
          this.assignmentsError = false;

          const clerkIds = [];
          const resourceIds = [];


          this.items.map((data) => {
            clerkIds.push(data.value);
          });

          this.itemsResources.map((data) => {
            resourceIds.push(data.value);
          });

          if (
            this.active !== "" &&
            this.clerkId !== "" &&
            this.endTime !== "" &&
            this.startTime !== "" &&
            this.operationShiftId !== "" &&
            this.operationType !== ""

          ) {

          } else {

          }

          TALLYAPI.assignSingleClerk({
            clerkIds: clerkIds,
            operationShiftId: this.operationShiftId,
            resourceIds: resourceIds,
            operationType: this.operationType,
            startTime: this.startTime + "T00:00:00.000Z",
            tallyDeStaffingNotificationId: parseInt(this.selectedNotificationId),

          }).then((response) => {
            this.loading = false;
            this.getAssignMentByNotification();

            this.operationShiftId = "";
            this.startTime = "";
            this.items = [];
            this.itemsResources = [];

            this.ErrorMessage = '';
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.SuccessMessage = 'Clerk Assigned';
          }).catch(() => {
            this.loading = false;
            this.ErrorMessage = 'Clerk Assignment Failed';
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = '';
          });
          this.closeNewAssignments()
        } else {

          this.assignmentsError = true;
        }

      },

      closeNewAssignments() {
        this.addClerkAssignment = false
      },

      closeViewAssignments() {
        this.viewAssignment = true;
        this.newAssignment = false;
      },

      onSelectedShift(data) {
        console.log(data);
        this.selectedShift = data;
        this.operationShiftId = data.id;
      },

      fullName(name) {
        return `${name.firstName} - ${name.lastName}`
      },

      getUsers() {
        this.loading = true;
        TALLYAPI.getShiftClerks().then((response) => {
          this.loading = false;
          response.data.data.map((data) => {
            this.allUsers.push({value: data.id, text: this.fullName(data)});
          });
        });
      },

      deleteAssignment(assignment) {

        this.$dialog.confirm('Delete shift assignment for  <b>[' + assignment.clerkName + '] </b> <br> working on the <b>[' + assignment.shiftName + '] </b> ?', {
          loader: true,
          html: true,
          okText: 'Yes, delete',
          cancelText: 'No, close'
        }).then((dialog) => {
          this.loading = true;

          TALLYAPI.deleteAssignment(assignment.id).then((response) => {
            this.loading = false;
            this.SuccessMessage = "Assignment Deleted";
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.ErrorMessage = "";
            this.getAssignMentByNotification();


          }).catch(() => {
            this.loading = false;
            this.ErrorMessage = "Failed To delete Assignment";
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = "";
          });
          dialog.close();
        }).catch(() => {

        })
      },

      sortingData(data) {

      this.filteredRequests = []

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x],
          startTime: this.dateTimeToHuman(data[x].startTime),
          endTime: this.dateTimeToHuman(data[x].endTime),
          clerkName: data[x].clerk.firstName + " " + data[x].clerk.lastName,
          shiftName: data[x].operationShift.name,
          approved: data[x].assignmentApproved ? 'YES' : 'NO',
          completed: data[x].completed ? 'YES' : 'NO'

          }

          this.filteredRequests.push(requestObject)

      }

    },

      getAssignMentByNotification() {
        this.loading = true;

        TALLYAPI.getAssignMentById(this.selectedNotificationId).then((response) => {
          this.loading = false;
          if (response.data.data.length > 0) {

            this.noAssignmentDataFound = false;
            // this.assignmentDetails = this.formatData(this.assignmentDetails);
            this.assignmentDetails = response.data.data;
            this.sortingData(this.assignmentDetails)

          } else {

            this.noAssignmentDataFound = true;

          }
        }).catch(() => {
          this.ErrorMessage = "Failed To get Assignments";
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = "";
          this.loading = false;
        })
      },

      // formatData(data) {
      //   if (data) {
      //     for (let i = 0; i < data.length; i++) {
      //       data[i].clerkName = data[i]?.clerk?.firstName + " " + data[i]?.clerk?.lastName;
      //       data[i].shiftName = data[i]?.operationShift?.name;
      //       data[i].approved = data[i]?.assignmentApproved ? 'YES' : 'NO';
      //     }
      //   }
      //   return data
      // },

      getNotificationDetailsOnCreated() {
        this.loading = true;
        TALLYAPI.getNotificationById(this.selectedNotificationId).then((response) => {
          if (response.data.status) {
            if (Object.keys(response.data.data).length > 0) {
              this.selectedNotification = response.data.data
            } else {
              this.ErrorMessage = "no data found";
              this.SuccessAlert = false;
              this.ErrorAlert = true;
              this.SuccessMessage = "";
              this.loading = false;
            }
          } else {
            this.ErrorMessage = "Failed To get Assignments";
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = "";
            this.loading = false;
          }
        }).catch(() => {
          this.ErrorMessage = "Failed To get Assignments";
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = "";
          this.loading = false;
        })
      },

      getShifts() {
        let data = [];
        this.loading = true;

        TALLYAPI.getShiftsByNotificationId(this.selectedNotificationId).then((response) => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
        })
      },

      showEditNotification() {
        this.details = JSON.parse(JSON.stringify(this.selectedNotification));
        this.tallyType = this.details.operationType;
        this.editNotificationDetails = !this.editNotificationDetails;
      },

      companyName(company) {
        return company.companyName;
      },

      getCompanies() {
        API.getCompany().then((response) => {
          this.companies = response.data.data;
        }).catch((error) => {

        })
      },

      editNotificationData() {
        if (this.details.startTime !== '' && this.details.endTime !== '' && this.details.locationType !== '' && this.details.locationName !== '') {
          this.startTimeError = false;
          this.endTimeError = false;
          this.locationNameError = false;
          this.locationTypeError = false;
          this.loading = true;

          // let startTime=" 2020-08-07T" + this.startTime + ":00Z";
          // let endTime=" 2020-08-07T" + this.endTime + ":00Z";

          TALLYAPI.editNotification(this.details).then(() => {
            this.loading = false;

            this.ErrorMessage = '';
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.SuccessMessage = 'Notification was Edited Successfully'
          }).catch(() => {
            this.loading = false;
            this.ErrorMessage = 'Notification Editing Failed';
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = ''
          });
          this.editNotificationDetails = !this.editNotificationDetails;
        } else {
          this.startTimeError = true;
          this.endTimeError = true;
          this.locationNameError = true;
          this.locationTypeError = true;
        }
      },

      goOperation(notificationId) {
        this.$router.push({name: 'clerkAssignment', params: {notificationId}})
      },


      selectNotification(notification = {}) {
        this.selectedNotification = notification;
        console.log(notification);
        this.getAssignMentByNotification();
      },

      getNotifications() {
        this.loading = true;
        TALLYAPI.getNotification(this.pageNo, this.pageSize).then((response) => {
          this.notifications = response.data.data.content;
          this.requests = response.data.data.content;
          // this.pageCount = this.getPageCount;//
          this.isLast = response.data.data.last;
          this.totalElements = response.data.data.totalElements;
          this.totalPages = response.data.data.totalPages;
          this.pageNumber = response.data.data.number;
          this.isFirst = response.data.data.first;
          this.isLast = response.data.data.last;
          this.noDataFound = this.notifications.length <= 0;
          this.loading = false;
        })
      },

      onChange(event) {
        this.pageSize = event;
        this.pageNo = 0;
        this.getNotifications()
      },

      goToPage(page) {
        this.pageNo = page;
        this.getNotifications()
      },

      getRouterName(operationType) {
        switch (operationType) {
          case 'LOADING':
            return 'loadingNotification';
          case 'DISCHARGING':
            return '/all/pageable/DISCHARGING';
          case 'STUFFING':
            return 'stuffingNotification';
          case 'DESTUFFING':
            return 'de-stuffingNotification';
        }
        return null;
      },

      goBack() {
        if (window.$cookies.isKey('operation-type-shift-clerk-assignment')) {
          const routerName = window.$cookies.get('operation-type-shift-clerk-assignment');
          console.log('routerName', routerName)
          if (routerName != null)
            this.$router.push(routerName);
            window.$cookies.remove('operation-type-shift-clerk-assignment');
        }
      },

      deleteNotification(notificationId) {
        TALLYAPI.deleteNotification(notificationId).then((response) => {
          this.getNotifications();
        })
      },
    }
    //   TODO: add this down here.. fix issues for discharge
    // /api/tally-notifications/{tallyNotificationId}
    //   getTallyDeStaffingNotification

  }
</script>

<style scoped>

  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #ededee;
    border-radius: 3px;
  }

  ul.nav-justified li {
      float: left;
  }

  ul.nav-justified li a:hover {
      color: #d59a18;
  }

  ul.nav-justified li a {
      display: block;
      text-align: center;
      padding: 16px 20px 10px 21px;
      text-decoration: none;
      color: blue;
      font-weight: bold;
      text-transform: uppercase;
  }

  .custom-btn {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
  }

  .btn-linen:hover {
    border-color: #D2691E;
    color: #D2691E;
    background-color: #ffffff;
  }

  .btn-linen {
    border-color: rgba(210, 105, 30, .5);
    color: rgba(210, 105, 30, .5);
  }

  .btn-linen-fill {
      background-color: rgb(210, 105, 30);
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-rosy-brown:hover {
      border-color: #775b5b;
      color: #775b5b;
      background-color: #ffffff;
  }

  .btn-rosy-brown {
      border-color: rgba(119,91,91, .5);
      color: rgba(119,91,91, .5);
  }

  .btn-rosy-brown-fill {
      background-color: rgb(119,91,91);
      opacity: 1;
      color: #FFFFFF;
  }

  .btn-purple:hover {
    border-color: #800080;
    color: #800080;
    background-color: #ffffff;
  }

  .btn-purple {
      border-color: #9932cc;
      color: #9932cc;
  }

  .btn-purple-fill {
      background-color: #9932cc;
      opacity: 1;
      color: #FFFFFF;
  }

  /* .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 200px;
    background-color: #FFFFFF !important;
  } */

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .table-hover tbody tr:hover {
    background-color: rgba(153, 186, 221, .5) !important;
    font-weight: normal !important;
  }

  tbody tr {
    color: #212529 !important;
    font-weight: 400 !important;
  }

  .table td, .table th {
    text-align: center !important;
  }

  .btn {
    font-size: 0.9rem !important;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }
</style>
