<template>
  <modal :maxWidth="1800">
    <div slot="body">
      <div class="row" v-if="!imoLoaded && !edit">
        <div class="col-md-12">
          <base-input type="text" label="Enter IMO to search for vessel" placeholder="Enter IMO to search for vessel" v-model="imoNumberSearched" :value="imoNumberSearched" class="special-bottom"/>
          <div v-if="imoNumberSearchedError">
              <div class="text-danger" v-if="!$v.imoNumberSearched.required">
              This field is required
              </div>
          </div>
          <div class="mt-3">
            <div class="input-group-append mb-3">
              <!-- <button class="btn btn-success btn-fill w-100" type="button" @click.prevent="getVesselV2DataByIMO">Search IMO</button> -->
            </div>
            <div class="text-center">
              <pulse-loader
                :loading="Imoloading"
                :color="color"
                :size="size"
              />
            </div>
            <div class="error text-danger text-center" v-if="searchImoError">
              <div>IMO doesnt Exist please create one</div>
            </div>
          </div>
        </div>
        <div class="col-md-12 text-right">
            <button class="btn btn-primary mr-2 custom-btn" type="button" @click.prevent="getVesselV2DataByIMO">Search</button>
            <button type="button" class="btn btn-fill btn-outline-info custom-btn-all" @click.prevent="close">Close</button>
        </div>
      </div>
      <div class="row" v-if="imoLoaded || edit">
        <div class="col-md-12" >
          <card>
            <div id="add-view">
              <ul class="nav-justified">
                <li><a @click.prevent="showStart = !showStart "  :class="[showStart ? 'is-active-main-details': '']">Vessel Details</a></li>
                <li><a @click.prevent="showStart = !showStart " :class="[!showStart? 'is-active-additional-details': '']">Additional Vessel Details</a></li>
              </ul>

              <div v-if="showStart" class="pt-4">
                <div class="row mb-3">
                  <div class="col-md-2">
                    <base-input type="text" label="IMO" placeholder="IMO" v-model="vesselDetails.imo" :value="vesselDetails.imo" class="special-bottom"/>
                    <div v-if="imoError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.imo.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Vessel Name" placeholder="Vessel Name" v-model="vesselDetails.vesselName" :value="vesselDetails.vesselName" class="special-bottom"/>
                    <div v-if="vesselNameError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.vesselName.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <base-input type="text" label="voyage No" placeholder="voyage No" v-model="vesselDetails.voyageNo" :value="vesselDetails.voyageNo" class="special-bottom"/>
                    <div v-if="voyageNoError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.voyageNo.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="CALL SIGN" placeholder="CALL SIGN" v-model="vesselDetails.callSign" :value="vesselDetails.callSign" class="special-bottom"/>
                    <div v-if="callSignError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.callSign.required">This field is required</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3">

                  <!--                  <div class="col-md-4">-->
                  <!--                    <base-input type="text" label="Vessel Name" placeholder="Vessel Name" v-model="vesselDetails.vesselName" :value="vesselDetails.vesselName" class="special-bottom"/>-->
                  <!--                    <div v-if="vesselNameError">-->
                  <!--                      <div class="error text-danger">-->
                  <!--                        <div class="error" v-if="!$v.vesselDetails.vesselName.required">This field is required</div>-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->

                  <div class="col-md-6">
                    <label>Vessel Type</label>
                    <model-list-select
                      :list="vesselTypes"
                      v-model="vesselDetails.vesselType"
                      class="form-control"
                      option-value="id"
                      option-text="name"
                      placeholder="Vessel Type"
                    >
                    </model-list-select>
                    <div v-if="vesselTypeError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.vesselType.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <base-input type="text" label="Vessel Carrier Name" placeholder="Vessel Carrier Name" v-model="vesselDetails.carrierName" :value="vesselDetails.carrierName" class="special-bottom"/>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-md-4">
                    <base-input type="text" label="Vessel Carrier Code" placeholder="Vessel Carrier Code" v-model="vesselDetails.carrierCode" :value="vesselDetails.carrierCode" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <label>Contract Type</label>
                    <model-list-select
                      :list="contractTypes"
                      v-model="vesselDetails.contractType"
                      class="form-control"
                      option-value="id"
                      option-text="name"
                      placeholder="Contract Type"
                    >
                    </model-list-select>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Vessel Master Name" placeholder="Vessel Master Name" v-model="vesselDetails.vesselMaster" :value="vesselDetails.vesselMaster" class="special-bottom"/>
                  </div>
                </div>
                <div class="row mb-3">

                  <div class="col-md-3">
                    <base-input type="text" label="Vessel Master Address" placeholder="Vessel Master Address" v-model="vesselDetails.vesselMasterAddress" :value="vesselDetails.vesselMasterAddress" class="special-bottom"/>
                  </div>

                  <div class="col-md-3">
                    <base-input type="text" label="Transport Means Name" placeholder="Transport Means Name" v-model="vesselDetails.transportMeansName" :value="vesselDetails.transportMeansName" class="special-bottom"/>
                  </div>

                  <div class="col-md-3">
                    <base-input type="text" label="Flag" placeholder="Flag" v-model="vesselDetails.flag" :value="vesselDetails.flag" class="special-bottom"/>
                  </div>
                  <div class="col-md-3">
                    <base-input type="text" label="Port of Registry" placeholder="Port of Registry" v-model="vesselDetails.portOfRegistry" :value="vesselDetails.portOfRegistry" class="special-bottom"/>
                  </div>

                </div>

                <div class="row mb-3">
                  <div class="col-md-2">
                    <base-input type="text" label="Loading Port" placeholder="Loading Port" v-model="vesselDetails.loadPort" :value="vesselDetails.loadPort" class="special-bottom"/>
                  </div>
                  <div class="col-md-2">
                    <base-input type="text" label="Last Port" placeholder="Last Port" v-model="vesselDetails.lastPort" :value="vesselDetails.lastPort" class="special-bottom"/>
                  </div>
                  <div class="col-md-2">
                    <base-input type="text" label="Discharge Port" placeholder="Discharge Port" v-model="vesselDetails.arrivalPort" :value="vesselDetails.arrivalPort" class="special-bottom"/>
                  </div>

                  <div class="col-md-2">
                    <base-input type="text" label="Next Port" placeholder="Next Port" v-model="vesselDetails.nextPort" :value="vesselDetails.nextPort" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Terminal" placeholder="Terminal" v-model="vesselDetails.terminal" :value="vesselDetails.terminal" class="special-bottom"/>
                  </div>


                </div>

                <div class="row">

                  <div class="col-md-4">
                    <base-input type="text" label="Control Reference Number" placeholder="Control Reference Number" v-model="vesselDetails.controlReferenceNumber" :value="vesselDetails.controlReferenceNumber" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Custom Office Code" placeholder="Custom Office Code" v-model="vesselDetails.customOfficeCode" :value="vesselDetails.customOfficeCode" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Beam" placeholder="Beam" v-model="vesselDetails.beam" :value="vesselDetails.beam" class="special-bottom"/>
                  </div>

                </div>

                <div class="row mb-3">
                  <div class="col-md-3">
                    <base-input type="date" label="ETA" placeholder="ETA" v-model="vesselDetails.eta" :value="vesselDetails.eta" class="special-bottom"/>
                    <!-- <label class="custom-font">ETA</label>
                    <date-picker  style="width:100% !important;" v-model="vesselDetails.eta" :value="vesselDetails.eta" @change="setFormattedETA" format="YYYY-MM-DD"></date-picker> -->
                    <div v-if="etaError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.eta.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <base-input type="date" label="ETB" placeholder="ETB" v-model="vesselDetails.etb" :value="vesselDetails.etb" class="special-bottom" :min="vesselDetails.eta" :disabled="vesselDetails.eta === ''"/>
                    <!-- <label class="custom-font">ETB</label>
                    <date-picker  style="width:100% !important;" v-model="vesselDetails.etb" :value="vesselDetails.etb" @change="setFormattedETB" format="YYYY-MM-DD" :disabled-date="validateETB"></date-picker> -->
                    <div v-if="etbError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.etb.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <base-input type="date" label="ETD" placeholder="ETD" v-model="vesselDetails.etd" :value="vesselDetails.etd" class="special-bottom" :input="calculateExpectedTimeLeft(vesselDetails.etb, vesselDetails.etd)" :min="vesselDetails.etb" :disabled="vesselDetails.etb === ''"/>
                    <!-- <label class="custom-font">ETD</label>
                    <date-picker  style="width:100% !important;" v-model="vesselDetails.etd" :value="vesselDetails.etd" @change="setFormattedETD" format="YYYY-MM-DD" :disabled-date="validateETD"></date-picker> -->
                    <div v-if="etdError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.etd.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <base-input type="text" label="StayTime(Days)" placeholder="StayTime" v-model="vesselDetails.stayTime" :value="vesselDetails.stayTime" class="special-bottom" disabled/>
                  </div>
                  <div class="col-md-1">
                    <base-input type="text" label="StayTime(HRS)" placeholder="StayTime" :value="stayTimeHrs" class="special-bottom" disabled/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <!-- <base-input type="text" label="operation" placeholder="operation" v-model="vesselDetails.operation" :value="vesselDetails.operation" class="special-bottom"/> -->
                    <label>Select Operation</label>
                    <select class="form-control" v-model="vesselDetails.operation" @change="disableQuantities">
                        <option value="" selected>Select operation</option>
                        <option v-for="(operation, index_operation) in operations" :value="operation.id" :key="index_operation">{{ operation.name }}</option>
                    </select>
                    <div v-if="operationError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.operation.required">This field is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2" style="margin-top:35px;" v-if="vesselDetails.operation === 'LOADING'">
                    <div class="custom-control custom-checkbox">
                    <base-checkbox v-model="arrivedInBalast" :id="'arrivedInBalast'" :label="'Arrived In Balast'" :inline="true" @input="disableQuantities"><label  style="margin-top:5px;"> Arrived In Balast</label></base-checkbox>
                    </div>
                  </div>
                  <div class="col-md-4" :class="[vesselDetails.operation === 'LOADING' ? 'col-md-4' : 'col-md-6']">
                    <base-input type="number"  label="Nominated cargo Quantity" placeholder="Nominated cargo Quantity" v-model="vesselDetails.nominatedCargoQuantity" :value="vesselDetails.nominatedCargoQuantity" class="special-bottom"/>
                    <div v-if="nominatedCargoQuantityError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.nominatedCargoQuantity.required">This field is required</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">

                  <div class="col-md-2">
                    <base-input type="text" label="Dead weight" placeholder="Dead weight" v-model="vesselDetails.dwt" :value="vesselDetails.dwt" class="special-bottom"/>
                  </div>

                  <div class="col-md-2">
                    <base-input type="text"  label="Gross Weight" placeholder="Gross weight" v-model="vesselDetails.grt" :value="vesselDetails.grt" class="special-bottom" :change="calculateFactor"/>
                    <div v-if="grtError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.grt.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <base-input type="text" label="Net weight" placeholder="Net weight" v-model="vesselDetails.nrt" :value="vesselDetails.nrt" class="special-bottom"/>
                    <div v-if="nrtError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.nrt.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <base-input type="text" label="Length Overall" placeholder="Length Overall" v-model="vesselDetails.loa" :value="vesselDetails.loa" class="special-bottom"/>
                    <div v-if="loaError">
                      <div class="error text-danger">
                        <div class="error" v-if="!$v.vesselDetails.loa.required">This field is required</div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Factor" placeholder="Factor" v-model="vesselDetails.factor" :value="vesselDetails.factor" class="special-bottom" disabled/>
                  </div>
                </div>

              </div>

              <div v-if="!showStart" class="pt-4">

                <div class="row">
                  <div class="col-md-4">
                    <base-input type="datetime-local" label="ATA" placeholder="ATA" v-model="vesselDetails.ata" :value="vesselDetails.ata" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="Notice of Readiness" placeholder="Notice of Readiness" v-model="vesselDetails.nort" :value="vesselDetails.nort" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="ATB" placeholder="ATB" v-model="vesselDetails.atb" :value="vesselDetails.atb" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="ATC" placeholder="ATC" v-model="vesselDetails.atc" :value="vesselDetails.atc" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="ATD" placeholder="ATD" v-model="vesselDetails.atd" :value="vesselDetails.atd" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="Time Of Anchor" placeholder="Time Of Anchor" v-model="vesselDetails.timeOfAnchor" :value="vesselDetails.timeOfAnchor" class="special-bottom"/>
                  </div>

                </div>
                <div class="row">

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="Inward Pilot On Board" placeholder="Inward Pilot On Board" v-model="vesselDetails.inwardPilotOnBoard" :value="vesselDetails.inwardPilotOnBoard" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="Outward Pilot On Board" placeholder="Outward Pilot On Board" v-model="vesselDetails.outwardPilotOnBoard" :value="vesselDetails.outwardPilotOnBoard" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Berth" placeholder="Outward Pilot On Board" v-model="vesselDetails.berth" :value="vesselDetails.berth" class="special-bottom"/>
                  </div>

                </div>
                <div class="row">

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="All Fast" placeholder="All Fast" v-model="vesselDetails.allFast" :value="vesselDetails.allFast" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="datetime-local" label="Last Line Cast Off" placeholder="Last Line Cast Off" v-model="vesselDetails.lastLineCastOff" :value="vesselDetails.lastLineCastOff" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Arrival Draft After" placeholder="Arrival Draft After" v-model="vesselDetails.arrivalDraftAfter" :value="vesselDetails.lastLineCastOff" class="special-bottom"/>
                  </div>

                </div>
                <div class="row">
                  <div class="col-md-4">
                    <base-input type="text" label="Arrival Draft Forward" placeholder="Arrival Draft Forward" v-model="vesselDetails.arrivalDraftForward" :value="vesselDetails.arrivalDraftForward" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Departure Draft After" placeholder="Departure Draft After" v-model="vesselDetails.departureDraftAfter" :value="vesselDetails.departureDraftAfter" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Departure Draft Forward" placeholder="Departure Draft Forward" v-model="vesselDetails.departureDraftForward" :value="vesselDetails.departureDraftForward" class="special-bottom"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <base-input type="text" label="Onboard Cargo Quantity" placeholder="Onboard Cargo Quantity" v-model="vesselDetails.cargoQuantity" :value="vesselDetails.cargoQuantity" class="special-bottom" :readonly="(disabled !== 1 && disabled !== 3)"/> <!-- :change="calculateQuantityRob"-->
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Cargo Weight" placeholder="Cargo Weight" v-model="vesselDetails.cargoWeight" :value="vesselDetails.cargoWeight" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Cargo Quantity Discharged" placeholder="Cargo Quantity Discharged" v-model="vesselDetails.cargoQuantityDischarged" :value="vesselDetails.cargoQuantityDischarged" class="special-bottom" :readonly="(disabled !== 1 && disabled !== 3)"/> <!-- :change="calculateQuantityRob"-->
                  </div>
            
                  <div class="col-md-4">
                    <base-input type="text" label="Cargo Quantity Loaded" placeholder="Cargo Quantity Loaded" v-model="vesselDetails.cargoQuantityLoaded" :value="vesselDetails.cargoQuantityLoaded" class="special-bottom" :readonly="(disabled !== 2 && disabled !== 3)"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Cargo Quantity ROB" placeholder="Cargo Quantity ROB" v-model="vesselDetails.cargoRobQuantity"  :value="vesselDetails.cargoRobQuantity" class="special-bottom" :readonly="(disabled !== 1 && disabled !== 3)"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Arrival Crew" placeholder="Arrival Crew" v-model="vesselDetails.crewAfter" :value="vesselDetails.crewAfter" class="special-bottom"/>
                  </div>

                  <div class="col-md-4">
                    <base-input type="text" label="Departure Crew" placeholder="Departure Crew" v-model="vesselDetails.crewForward" :value="vesselDetails.crewForward" class="special-bottom"/>
                  </div>

                  <!--                  <div class="col-md-2">-->
                  <!--                    <base-input type="datetime-local" label="Cargo Quantity ROB" placeholder="Cargo Quantity Discharged" v-model="vesselDetails.cargoRobWeight" :value="vesselDetails.cargoRobWeight" class="special-bottom"/>-->
                  <!--                  </div>-->

                </div>


                <!-- <div class="row">

                  <div class="col-md-3">
                    <base-input type="text" label="Crew After" placeholder="Crew After" v-model="vesselDetails.crewAfter" :value="vesselDetails.crewAfter" class="special-bottom"/>
                  </div>

                  <div class="col-md-3">
                    <base-input type="text" label="Crew Forward" placeholder="Crew Forward" v-model="vesselDetails.crewForward" :value="vesselDetails.crewForward" class="special-bottom"/>
                  </div>

                </div> -->

              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row" v-if="imoLoaded || edit">
        <div class="col-md-12">
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
          <button type="button" class="btn btn-fill btn-outline-info float-right custom-btn-all" @click.prevent="close">
            Close
          </button>
          <button type="submit" class="btn btn-primary btn-space float-right custom-btn" data-dismiss="modal" @click.prevent="saveVesselCall" :disabled="loading">
            {{ edit === true ? "Edit" : "Save" }}
          </button>
        </div>
      </div>

    </div>
  </modal>

</template>

<script>
  import {required} from "vuelidate/lib/validators";
  import PulseLoader from "vue-spinner/src/PulseLoader";
  import {ModelListSelect} from "vue-search-select";
  import AGENCY_API from "../../../api/agency";
  import Modal from "@/components/Inputs/Modal";
  import { VESSEL_TYPES, MARINE_VESSEL_OPERATIONS } from '../../../mixins/constants'
  // import BaseCheckbox from '../../../components/Inputs/BaseCheckbox';

  export default {
    
        name: "createVesselCall",

    components: {
      PulseLoader,
      DataTable: () => import('../../../components/DataTable/dataTable'),
      ModelListSelect,
      Modal,
      // BaseCheckbox

    },

    props:{
      closeModal:{
        type:Function
      },
      reload:{
        type:Function
      },
      edit:Boolean,
      vesselDetailsToEdit:Object,
      vesselCallId: String
    },


    validations: {

      vesselDetails: {

        imo:{required},
        vesselName: {required},
        voyageNo: {required},
        callSign: {required},
        eta: {required},
        etb: {required},
        etd: {required},
        operation: {required},
        grt: {required},
        nominatedCargoQuantity: {required},
        nrt: {required},
        loa: {required},
        vesselType:{required}

      },
      imoNumberSearched: { required }

    },

    data () {

      return {

        stayTimeCopy: '',
        factorCopy: '',
        operations: MARINE_VESSEL_OPERATIONS,
        showStart: true,
        imoNumberSearchedError: false,
        imoLoaded:false,
        color: 'green',
        size: '12px',
        stayTimeHrs:0,
        disabled: 0,
        arrivedInBalast: false,
        vesselDetails:{
          vesselParticularId: null,
          imo: "",
          vesselName: "",
          voyageNo: "",
          callSign: "",
          vesselType: null,
          carrierName: "",
          carrierCode: "",
          contractType: null,
          vesselMaster: "",
          vesselMasterAddress: "",
          transportMeansName: "",
          portOfRegistry: "",
          nominatedCargoQuantity: "",
          flag: "",
          lastPort: "",
          arrivalPort: "",
          terminal: "",
          nextPort: "",
          controlReferenceNumber: "",
          customOfficeCode: "",
          loadPort:"",
          eta: "",
          etb: "",
          etd: "",
          operation: "",
          stayTime: 0,
          dwt: 0,
          grt: "",
          nrt: "",
          loa: "",
          factor: 0,
          beam: 0,
          nort:"",
          timeOfAnchor:"",
          inwardPilotOnBoard:"",
          outwardPilotOnBoard:"",
          allFast:"",
          lastLineCastOff:"",
          arrivalDraftAfter:"",
          arrivalDraftForward:"",
          departureDraftAfter:"",
          cargoQuantity:"",
          cargoWeight:"",
          cargoQuantityDischarged:"",
          cargoQuantityLoaded:"",
          cargoRobQuantity:"",
          cargoRobWeight:"",
          crewAfter:"",
          crewForward:"",
//this  fields should be removed IN OBJECT FROM SERVER

          // arrivalDraftAfterUnit: "",
          // arrivalDraftForwardUnit: "",
          // ballast: true,
          // berthNo: "",
          // departureDraftAfterUnit: "",
          // departureDraftForwardUnit: "",
          // destinationPort: "",
          // draft: 0,
          // principalParticularId: 0,
          // status: "",
        },

        imoNumberSearched:"",

        imoError:false,
        vesselNameError:false,
        etaError:false,
        etbError:false,
        etdError:false,
        operationError:false,
        grtError:false,
        nrtError:false,
        loaError:false,
        voyageNoError:false,
        callSignError:false,
        vesselTypeError:false,
        searchImoError:false,
        nominatedCargoQuantityError: false,
        vesselTypes: VESSEL_TYPES,

        contractTypes:[
          {name: "Charter", id: "Charter"},
          {name: "Liner", id: "Liner"},
          {name: "Other", id: "Other"},
        ],

        loading:false,
        Imoloading:false,
      }
    },
    computed:{

      calculateFactor:function () {

        this.vesselDetails.factor = Math.ceil(this.vesselDetails.grt/100)
        this.factorCopy = Math.ceil(this.vesselDetails.grt/100)

      },

      calculateQuantityRob(){

        if(this.vesselDetails.cargoQuantity !== "" && this.vesselDetails.cargoQuantityDischarged !== ""){

          this.vesselDetails.cargoRobQuantity = parseInt(this.vesselDetails.cargoQuantity) - parseInt(this.vesselDetails.cargoQuantityDischarged)

        }
      },

      calculateStayTime:function () {

        if(this.vesselDetails.etd !=="" && this.vesselDetails.etb !==""){

          let etd=new Date(this.vesselDetails.etd);
          let etb=new Date(this.vesselDetails.etb);


          let milliSecondsPerHr=1000 * 60  * 60;
          let millisBetween = etd.getTime() - etb.getTime();

          let days=(etd.getDate() - etb.getDate()) + 1;

          this.stayTimeHrs = millisBetween / milliSecondsPerHr;
          this.vesselDetails.stayTime=Math.floor(days);
        }

      },


    },

    methods:{

      disableQuantities () {

        if(this.vesselDetails.operation === 'DISCHARGE') {

          this.disabled = 1
          this.arrivedInBalast = false

        }else if(this.vesselDetails.operation === 'LOADING' && this.arrivedInBalast) {

          this.disabled = 2

        }else if(this.vesselDetails.operation === 'DISCHARGE & LOADING' || (this.vesselDetails.operation === 'LOADING' && !this.arrivedInBalast)) {

          this.disabled = 3

        } else {

          this.disabled = 0

        }

      },

      calculateExpectedTimeLeft (birthDate, departureDate) {

          if(birthDate !== null && birthDate !== '' && birthDate !== 'undefined' && departureDate !== null && departureDate !== '' && departureDate !== 'undefined') {

              let birthDate1 = birthDate.split('-')
              let departureDate1 = departureDate.split('-')

              let birthDateFormatted = this.$moment(new Date(this.$moment(birthDate1[0] + '-' + birthDate1[1] + '-' + birthDate1[2]).format('YYYY, MM, DD')))
              let departureDateFormatted = this.$moment(new Date(this.$moment(departureDate1[0] + '-' + departureDate1[1] + '-' + departureDate1[2]).format('YYYY, MM, DD')))

              this.vesselDetails.stayTime = departureDateFormatted.diff(birthDateFormatted, 'days') + 1
              this.stayTimeCopy = departureDateFormatted.diff(birthDateFormatted, 'days') + 1
              this.stayTimeHrs = departureDateFormatted.diff(birthDateFormatted, 'hours') + 24

          }

      },

      close(){

        this.imoLoaded = false;
        this.closeModal();

      },

      getVesselV2DataByIMO () {

        if (this.$v.imoNumberSearched.$invalid) {

            this.imoNumberSearchedError = true

        } else {

          this.Imoloading = true;
          this.searchImoError = false;

          AGENCY_API.getVesselV2DatabYiMO(this.imoNumberSearched).then((resp)=>{
            this.Imoloading=false;
            if(resp.data.data !== null){
              this.imoLoaded=true;
              this.vesselDetails.loa=resp.data.data.lengthOverall;
              this.vesselDetails.nrt=resp.data.data.displacementSummer;
              this.vesselDetails.imo=resp.data.data.imo;
              this.vesselDetails.callSign=resp.data.data.callsign
              this.vesselDetails.grt=resp.data.data.summerDwt;
              this.vesselDetails.vesselName=resp.data.data.name;
              this.vesselDetails.vesselType=resp.data.data.vesselType;
              this.vesselDetails.flag=resp.data.data.flag;

            }else {
              this.searchImoError=true;
              this.imoLoaded=false;

            }

          }).catch((error)=>{
            this.Imoloading=false;
            this.notifyUser('error','Error','Error Failed to send Data to server');
          })
          
        }

      },

      editVessel(data){
        this.vesselDetails=data;
      },

      saveVesselCall(){
        if(
            this.vesselDetails.imo!=="" &&
            this.vesselDetails.eta!=="" &&
            this.vesselDetails.etb!=="" &&
            this.vesselDetails.etd!=="" &&
            this.vesselDetails.grt!=="" &&
            this.vesselDetails.nrt!=="" &&
            this.vesselDetails.loa!=="" &&
            this.vesselDetails.voyageNo!=="" &&
            this.vesselDetails.callSign!=="" &&
            this.vesselDetails.operation!=="" &&
            this.vesselDetails.vesselName!=="" &&
            this.vesselDetails.eta!=="" &&
            this.vesselDetails.etb!=="" &&
            this.vesselDetails.etd!=="" &&
            this.vesselDetails.nominatedCargoQuantity !== "" &&
            this.vesselDetails.vesselType!==""

        ){

          this.imoError=false;
          this.vesselNameError=false;
          this.etaError=false;
          this.etbError=false;
          this.etdError=false;
          this.operationError=false;
          this.grtError=false;
          this.nrtError=false;
          this.loaError=false;
          this.voyageNoError=false;
          this.callSignError=false;
          this.vesselTypeError=false;
          this.nominatedCargoQuantityError = false


          this.vesselDetails.contractType === "" ? this.vesselDetails.contractType = null : "";
          let vesselDetails = {}
          vesselDetails = {
            ...this.vesselDetails,
            stayTime: this.stayTimeCopy,
            factor: this.factorCopy,
            eta: this.vesselDetails.eta + 'T00:00:00',
            etb: this.vesselDetails.etb + 'T00:00:00',
            etd:this.vesselDetails.etd + 'T00:00:00'
          }

          if(this.edit){

            this.loading = true;
            AGENCY_API.editVesselParticular(vesselDetails).then(() => {

              this.loading = false;
              this.loadNotification('success', 4000, 'Bill Generation', 'Vessel Details have been successfully updated', 'response', 1000 , true  , true)
              this.imoLoaded=false;
              this.reload();

            }).catch(() => {
              
              this.loading=false;
              this.loadNotification('error', 4000, 'Bill Generation', 'Vessel Details not updated', 'response', 1000 , true  , true)

            });

            this.$emit('loadVesselNotifications');
            this.closeModal();

          } else {

            this.loading = true;
            AGENCY_API.addVesselParticular(vesselDetails).then(() => {

              this.loading = false;
              this.loadNotification('success', 4000, 'Bill Generation', 'Vessel Details have been successfully saved', 'response', 1000 , true  , true)
              this.imoLoaded=false;
              this.reload();

            }).catch(() => {

              this.loading = false;
              this.loadNotification('error', 4000, 'Bill Generation', 'Vessel Details not saved', 'response', 1000 , true  , true)

            });

            this.$emit('loadVesselNotifications');
            this.closeModal();

          }


        }else {

          this.imoError=true;
          this.vesselNameError=true;
          this.etaError=true;
          this.etbError=true;
          this.etdError=true;
          this.operationError=true;
          this.grtError=true;
          this.nrtError=true;
          this.loaError=true;
          this.voyageNoError=true;
          this.callSignError=true;
          this.vesselTypeError=true;
          this.nominatedCargoQuantityError = true
        }

      },

          getVesselCallById(){
            this.loading=true;
            AGENCY_API.getVesselParticularById(this.vesselCallId).then((resp)=>{
              this.loading=false;
              this.vesselDetails=resp.data.data;

            }).catch((error)=>{
              this.loading=false;
              this.notifyUser('error','Error','Failed to load Vessel Call');
            })
          },


    }



    }
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: auto;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-main-details, .is-active-main-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-additional-details, .is-active-additional-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto;
  }

  .medium-modal-dialog {
    /*width: calc(80vw);*/
    /*min-width: calc(60vw);*/
    max-width: calc(90vw) !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #f0bb0d;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
  }

  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

</style>
