<template>
  <div>
    <card>
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :myFirstLoader="myAssignmentLoader"
        :noDataFound="noDataFound"
        :hasFilter="false"
        :hasManageButton="false"
        :hasPagination="false"
        :isPageable="false"
        :loadingTitle="'Please wait while system is loading your activity assignments'"
        :permissions="[ {key: 'searchPermission', value: 'ASSIGNMENT_ALL-NOTIFICATIONS_GET'} ]"
        :buttonList="[ {'code' : 'Application' , 'name' : 'View Application' , 'class' : 'btn-primary'} ]"
        @goToApplication="gotoApplication">
      </data-table>
    </card>
  </div>
</template>

<script>

  import API from '../../../api/index'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  export default {

    name: 'MyAssignment',

    components: {

      PulseLoader,
      DataTable: () => import('../../../components/DataTable/dataTable')

    },

    data () {
      return {

        color: 'green',
        size: '12px',
        loading: true,

        finalUserPermissions: [],
        getAssignedActivity: [],
        assignedActivityCounter: 0,
        myAssignmentLoader: false,
        noDataFound: false,
        tableHeaders: ['File Number', 'BL Number', 'Customer', 'Activity Name', 'Activity Status', 'Assigned Date'],
        tableDataKeys: ['fileNo', 'blNumber', 'customerName', 'activityName', 'status', 'activityDate'],
        requests: [],
        filteredRequests:[],

      }
    },

    created () {

      this.loadAssignedActivity().then(() => {

        this.$store.dispatch('pagenames/currentPageName', 'My Export Assigned Activities ( ' + this.assignedActivityCounter + ' )')

      })

    },

    methods: {

      sortingData(data) {

          for(let x = 0 ; x < data.length ; x++) {

            if(data[x].request.requestTypeName === 'EXP') {

              let requestObject = {

                  ...data[x],
                  fileNo: data[x].request.fileNo,
                  blNumber: data[x].request.blNumber,
                  activityDate: data[x].activityDate !== null && data[x].activityDate !== '' ? this.dateTimeToHuman(data[x].activityDate) : '',
                  customerName: data[x].request.customerName,
                  status: data[x].status

              }

              this.filteredRequests.push(requestObject)
              this.assignedActivityCounter = this.filteredRequests.length

            }

          }

      },

      gotoApplication(data){

        let status=this.spaceToHyphenString(data.request.status);
        let requestType=data.request.requestTypeName === "IMP" ? 'import' : 'export';
        this.$router.push('/clearing-and-forwarding/' + requestType + '-' + status + '-application-view/' + data.request.requestId);

      },

      loadAssignedActivity () {

        return new Promise((resolve, reject) => {

          this.myAssignmentLoader = true;

          API.assignmentNotificationList().then(
            response => {
              if (response.data.status) {

                this.getAssignedActivity = response.data.data;
                this.sortingData(this.getAssignedActivity)
                // this.assignedActivityCounter = parseInt(response.data.message.split(', ')[1]);

                this.myAssignmentLoader = false
                resolve()

              } else {

                this.myAssignmentLoader = false
              }
            }
          ).catch(() => {

            this.myAssignmentLoader = false
            reject()

          })

        });

      }

    }
  }
</script>

<style scoped>

</style>
