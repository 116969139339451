<template>
  <card>
    <div class="row">
      <div class="col-md-12">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="loading"
          />
        </div>
        <div
          class="text-center"
          v-if="noShifts"
        >
          No Operation Shifts For This Notification
        </div>
        <div v-for="(operationShift,index) in operationShifts">
          <div class="text-right col-md-7 pr-5">
            {{ suffix(index + 1) }} Shift On <strong>{{ operationShift.startTime? dateTimeToHuman(operationShift.startTime):'' }}</strong>
          </div>
          <div class="row div-table-row">
            <div
              class="col-md-1 div-table div-table-title text-center"
              style="border-right: 1px solid #a9a9a9"
            >
              #
            </div>
            <div
              class="col-md-5 div-table div-table-title text-center"
              style="border-right: 1px solid #a9a9a9"
            >
              Shift Name
            </div>
            <div
              class="col-md-4 div-table div-table-title text-center"
              style="border-right: 1px solid #a9a9a9"
            >
              Time of Day
            </div>
            <div class="col-md-2 div-table div-table-title text-center">
              Action
            </div>
          </div>
          <div
            class="row div-table-row"
            v-for="(name,index) in operationShifts[index].name"
          >
            <div class="col-md-1 div-table div-table-value text-center" />
            <div class="col-md-5 div-table div-table-value text-center">
              {{ name }}
            </div>
            <div class="col-md-4 div-table div-table-value text-center" />
            <div class="col-md-2 div-table div-table-value text-center">
              <button
                class="btn btn-default"
                @click="getClerks(operationShift)"
              >
                View Clerks
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-12">
        <button type="button" class="btn btn-fill btn-outline-info float-right mb-2 mr-2" @click="goToBack()">
          Close
        </button>
      </div>
    </div>
  </card>
</template>

<script>
import { assignments } from './assignmnents'
import TALLYAPI from '../../../api/tally'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'AssignmentsView',

  components: {
    PulseLoader
  },

  created () {
    this.getShifts()
  },

  data () {
    return {
      operationShifts: [],
      noShifts: false,
      loading: false,
      color: 'green',
      size: '15px'
    }
  },

  methods: {
    dateToHuman (stringDate) {
      if (stringDate !== null || stringDate !== undefined) {
        const splittedDate = stringDate.split('T')
        return splittedDate[0]
      }
    },

    getClerks (data) {
      this.$router.push(
        {
          name: 'clerkAssignedDayShift',
          params: {
            shiftId: data.operationShiftId.toString(),
            day: data.startTime,
            notificationId: data.notificationId.toString()
          }
        }
      ).catch(() => {})
    },

    suffix (i) {
      const j = i % 10
      const k = i % 100
      if (j === 1 && k !== 11) {
        return i + 'st'
      }
      if (j === 2 && k !== 12) {
        return i + 'nd'
      }
      if (j === 3 && k !== 13) {
        return i + 'rd'
      }
      return i + 'th'
    },

    isDayAlreadySet (dayPassed, operationShiftsArrayName) {
      return this.operationShifts.name.map((day) => {
        return dayPassed === day
      })
    },

    isShiftInsertedInArray (data) {
      return this.operationShifts.findIndex((query) => {
        return JSON.stringify(query) === JSON.stringify(data)
      })
    },

    getShifts () {
      this.loading = true

      TALLYAPI.getShiftsByNotificationId(this.$route.params.notificationId).then((response) => {
        this.loading = false
        if (response.data.data.length === 0) {
          this.noShifts = true
        }

        response.data.data.map((data, index) => {
          const result = this.isShiftInsertedInArray({
            operationShiftId: data.operationShift.id,
            name: [data.operationShift.name],
            normalStartTime: data.operationShift.startTime,
            normalEndTime: data.operationShift.endTime,
            startTime: data.startTime,
            endTime: data.endTime,
            notificationId: data.tallyDeStaffingNotification.id
          })

          if (result === -1) {
            // let isdaySet=this.isDayAlreadySet(data.operationShift.name,this.operationShifts);

            this.operationShifts.push({
              operationShiftId: data.operationShift.id,
              name: [data.operationShift.name],
              normalStartTime: data.operationShift.startTime,
              normalEndTime: data.operationShift.endTime,
              startTime: data.startTime,
              endTime: data.endTime,
              notificationId: data.tallyDeStaffingNotification.id
            })
          } else {
            // this.operationShifts[result].name.push(data.operationShift.name)

            // let isSet= this.operationShifts[result].name.findIndex((day)=>{
            //     return data.operationShift.name===day;
            // });
            //
            // if(isSet!==-1 && this.operationShifts[isSet]!== undefined){
            //     this.operationShifts[isSet].name.push(data.operationShift.name)
            // }
          }
        })
      }).catch(() => {
        this.loading = false
      })
    },

    goToBack(){
      history.back()
    }
  }
}
</script>

<style scoped>
    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }
</style>
