<template>
  <div>
    <crud-notification
      :error-message="ErrorMessage"
      :success-message="SuccessMessage"
      :error-alert="ErrorAlert"
      :success-alert="SuccessAlert"
      @fadeMe="fadeMe"
      @enter="enter"
    />

    <card style="font-size: 12px">
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success mr-2 custom-btn"
            style="float:right;"
            @click.prevent="openModal('ADD')"
          >
            <b>Add Category</b>
          </button>
        </div>
      </div>
      <hr>
      <data-table
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :totalPages="totalPages"
        :is-pageable="isPageable"
        :total-elements="totalElements"
        :no-data-found="noDataFound"
        :is-first="isFirst"
        :is-last="isLast"
        :page-number="pageNumber"
        :has-manage-button="true"
        :has-search-form="false"
        :has-view-button="false"
        :has-edit-button="true"
        :has-filter="false"
        :loading-title="'Please wait while system is loading Disbursement services'"
        :permissions="[ {key: 'searchPermission', value: 'SERVICE_CATEGORY_LIST_GET'} ]"
        @onChange="onChange"
        @goToApplication="goToApplication"
        @goToEdit="editFormDetails"
        @goToPage="goToPage"
      >
      <div slot="pagination">
              <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
              </paginate>
            </div>
      </data-table>
    </card>

    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            <b> Edit Disbursement Category</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            <b> Register Disbursement Category</b>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveFormDetails"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Disbursement Category Name"
                  placeholder="Disbursement Category Name"
                  v-model="disbursementDetails.name"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementDetailsNameError">
                  <div
                    class="error"
                    v-if="!$v.disbursementDetails.name.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Disbursement Category Code"
                  placeholder="Disbursement Category Code"
                  v-model="disbursementDetails.code"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementDetailsCodeError">
                  <div
                    class="error"
                    v-if="!$v.disbursementDetails.code.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="textarea"
                  label="Disbursement Category Description"
                  placeholder="Disbursement Category Description"
                  v-model="disbursementDetails.description"
                  class="special-bottom"
                />
                <div v-if="disbursementDetailsDescriptionError">
                  <div
                    class="error"
                    v-if="!$v.disbursementDetails.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editSaveFormDetails"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Disbursement Category Name"
                  placeholder="Disbursement Category Name"
                  v-model="name"
                  :value="name"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementDetailsNameError">
                  <div
                    class="error"
                    v-if="!$v.name.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Disbursement Category Code"
                  placeholder="Disbursement Category Code"
                  v-model="code"
                  :value="code"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementDetailsCodeError">
                  <div
                    class="error"
                    v-if="!$v.code.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  type="textarea"
                  label="Disbursement Category Description"
                  placeholder="Disbursement Category Description"
                  v-model="description"
                  :value="description"
                  class="special-bottom"
                />
                <div v-if="disbursementDetailsDescriptionError">
                  <div
                    class="error"
                    v-if="!$v.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'
import DataTable from '../../../components/DataTable/dataTable'
import Modal from '../../../components/Inputs/Modal'
import CrudNotification from "../../../components/DataTable/crudNotification";


export default {
  name: 'Disbursement',

  components: {

    PulseLoader,
    GlobalModal,
    DataTable,
    Modal,
    CrudNotification

  },

  created () {

    this.$store.dispatch('pagenames/currentPageName', 'Disbursement Categories')
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableDisbursementCategoriesOnCreated(this.pageSize, this.pageNo)

  },

  computed: {

    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),

    ...mapGetters('disbursement', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestId',
      'getTotalPage',
      'getTotalElement',
      'getPageNumber',
      'getIsFirst',
      'getIsLast',
      'getPageSize'
    ]),

    getIsPage: {

        get: function () {
          return this.isPage
        },
        set: function (newValue) {
          this.isPage = newValue;
        }

      }

  },
  validations: {

    code: { required },
    description: { required },
    name: { required },

    disbursementDetails: {

      code: { required },
      description: { required },
      name: { required }

    }
  },

  data () {
    return {

      disbursementDetails: {
        code: '',
        description: '',
        name: ''
      },
      code: '',
      description: '',
      name: '',

      disbursementDetailsNameError: false,
      disbursementDetailsCodeError: false,
      disbursementDetailsDescriptionError: false,

      AddModalDiv: false,
      EditModalDiv: false,
      showModal: false,
      editMyLoader: false,

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'createdAt',
      isPageable: true,
      nodataFound: false,
      color: 'green',
      size: '12px',
      isPage: 0,
      pageNoPart: 0,
      loading: true,
      editable: true,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      pdfFormatError: false,
      loadAllHeaderLoader: false,
      allAttachmentVerified: false,
      myLoader: false,
      myFirstLoader: false,
      status: 'DISBURSEMENT',
      isSearchable: false,
      requests: [],
      requestFlag: 'CONFIG',

      SuccessAlert: false,
      ErrorAlert: false,

      SuccessMessage: '',
      ErrorMessage: '',

      isHovered: [],
      saveDetails: {},
      ifThereIsParam: '',
      editDetails: false,
      finalUserPermissions: [],
      filteredRequests: [],
      tableHeaders: ['Category Code', 'Name', 'Description'],
      tableDataKeys: ['code', 'name', 'description'],

      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: '',

    }
  },
  methods: {

    ...mapActions('disbursement', [

      'loadDisbursementCategoriesOnCreated',
      'loadDisbursementCategoriesAfterCreated',
      'loadDisbursementCategoriesById',
      'addDisbursementCategory',
      'updateDisbursementCategory'

    ]),

    loadParameters (pageSize, pageNo) {

      return new Promise((resolve) => {

        this.$store.dispatch('disbursement/pageSizing', pageSize)
        this.$store.dispatch('disbursement/pageNumbering', pageNo)

        resolve()

      })

    },

    loadSaveDetailsParameters () {

      return new Promise((resolve) => {

        this.$store.dispatch('disbursement/savingDetails', this.saveDetails)

        resolve()

      })

    },

    sortingData(data) {

        for (let x = 0; x < data.length; x++) {

          const requestObject = {
            ...data[x]
          }

          this.filteredRequests.push(requestObject)

        }

      },

    setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';

        this.noDataFound = this.getNoDataFound;
        this.pageNo = this.getPageNo;
        this.pageSize = this.getPageSize;

        this.totalElements = this.getTotalElement;
        this.totalPages = this.getTotalPage;
        this.pageNumber = this.getPageNumber;
        this.isFirst = this.getIsFirst;
        this.isLast = this.getIsLast;

        this.sortingData(this.requests)

      },

    loadPageableDisbursementCategoriesOnCreated(pageSize, pageNo) {

      this.loadParameters(pageSize, pageNo).then(() => {

        this.loadDisbursementCategoriesOnCreated({}).then(() => {

          this.setAllGetters('onCreated');
        });

        this.myFirstLoader = this.getMyFirstLoader;

      }).catch(() => {

      });
    },

    loadPageableDisbursementCategoriesAfterCreated(pageSize, pageNo) {

      this.loadParameters(pageSize, pageNo).then(() => {

        this.loadDisbursementCategoriesAfterCreated({}).then(() => {

          this.setAllGetters('afterCreated');
        });

        this.myLoader = this.getMyLoader;

      }).catch(() => {


      });
    },

    saveDisbursementCategories () {

      this.loadSaveDetailsParameters().then(() => {

        this.addDisbursementCategory({}).then(() => {
          
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          this.SuccessMessage = 'Disbursement Category added successfully.'
          this.ErrorMessage = 'Sorry, something went wrong...'
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessAlert
          this.loadPageableDisbursementCategoriesOnCreated()

        }).catch(() => {})

        this.myFirstLoader = this.getMyFirstLoader
      })
      this.saveDetails = {}
    },

    editDisbursementCategories () {

      this.loadSaveDetailsParameters().then(() => {

        this.updateDisbursementCategory({}).then(() => {

          this.noDataFound = this.getNoDataFound
          this.SuccessMessage = 'Disbursement Category edited successfully.'
          this.ErrorMessage = 'Sorry, something went wrong...'
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessAlert
          this.loadPageableDisbursementCategoriesOnCreated()

        }).catch(() => {})
      })
      
    },

    goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        if (this.getHasSearchedVariable) {

            let firstPart = this.newParams.split("page=")[0]
            this.newParams = ""
            this.newParams = firstPart + '&page=' + this.pageNo
            this.pageNoPart = this.isPage

            this.searchGeneralParams(this.newParams, this.pageNoPart);
            this.filteredRequests = []

        } else {
          
            this.loadPageableDisbursementCategoriesAfterCreated(this.pageSize , this.pageNo);
            this.filteredRequests = []
        }

    },

    onChange (event) {

        this.$store.dispatch("disbursement/pageSizing", event);
        this.$store.dispatch("disbursement/pageNumbering", 0);
        this.isPage = 0

        this.pageSize = Number(this.getPageSize);
        this.pageNo = this.getPageNo;

        if (this.getHasSearchedVariable) {

            let firstPart = this.newParams.split("&page=")[0]
            let secondPart = firstPart.split("size=")[0]
            let thirdPart = firstPart.split("size=")[1]
            this.newParams = ""
            this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + 0
            this.pageNoPart = 0

            this.searchGeneralParams(this.newParams , this.pageNoPart);
            this.filteredRequests = []

        } else {

            this.loadPageableDisbursementCategoriesOnCreated(this.pageSize , this.pageNo);
            this.filteredRequests = []
        }

    },

    goToApplication (data) {

      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
      this.$router.push('/configurations/disbursement-service-category-and-items/' + data.serviceCategoryId)

    },

    saveFormDetails () {

      if (this.$v.name.$invalid || this.$v.code.$invalid || this.$v.description.$invalid) {
        if (this.$v.disbursementDetails.name.$invalid) {
          this.disbursementDetailsNameError = true
        }
        if (this.$v.disbursementDetails.code.$invalid) {
          this.disbursementDetailsCodeError = true
        }
        if (this.$v.disbursementDetails.description.$invalid) {
          this.disbursementDetailsDescriptionError = true
        } else {
          this.saveDetails = this.disbursementDetails
          this.saveDetails = {
            code: this.disbursementDetails.code,
            description: this.disbursementDetails.description,
            name: this.disbursementDetails.name
          }
          
          this.closeModal()
          this.saveDisbursementCategories()
        }
      }
    },

    closeModal () {

      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.disbursementDetailsDescriptionError = false
      this.disbursementDetailsCodeError = false
      this.disbursementDetailsNameError = false

    },

    editSaveFormDetails (details) {

      if (this.$v.name.$invalid || this.$v.code.$invalid || this.$v.description.$invalid) {
        if (this.$v.disbursementDetails.name.$invalid) {
          this.disbursementDetailsNameError = true
        }
        if (this.$v.disbursementDetails.code.$invalid) {
          this.disbursementDetailsCodeError = true
        }
        if (this.$v.disbursementDetails.description.$invalid) {
          this.disbursementDetailsDescriptionError = true
        }

      } else {

        this.saveDetails = {

          active: true,
          code: this.code,
          description: this.description,
          name: this.name,
          serviceCategoryId: this.serviceCategoryId

        }

        this.editDisbursementCategories()

        this.code = ''
        this.name = ''
        this.description = ''
        this.closeModal()
      }

    },

    openModal (modalType) {

      if (modalType === 'ADD') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.showModal = true
      } else {
        this.EditModalDiv = true
        this.AddModalDiv = false
        this.showModal = true
      }

    },

    editFormDetails (details) {

      this.serviceCategoryId = details.serviceCategoryId
      this.code = details.code
      this.description = details.description
      this.name = details.name
      this.openModal('EDIT')
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }

  }

}

</script>

<style scoped>
.btn-space {
  margin-right: 10px !important;
}
.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
  width: 120px;
  background-color: #FFFFFF !important;
}

.special-limit {
  max-width: 100px !important;
  min-width: 90px !important;
}

.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  margin: 0.5em 5px;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.50;
  text-decoration: none;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.fileUpload span {
  cursor: pointer;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
</style>
