<template>
  <card>
    <div class="row">
<!--      <div class="col-md-12">-->
<!--        <crud-notification-->
<!--          :error-message="ErrorMessage"-->
<!--          :success-message="SuccessMessage"-->
<!--          :error-alert="ErrorAlert"-->
<!--          :success-alert="SuccessAlert"-->
<!--          @fadeMe="fadeMe"-->
<!--          @enter="enter"-->
<!--        />-->
<!--      </div>-->
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="row">
              <div class="form-group col-md-6">
                <label for="name">Resource Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="name">
                <div
                  class="error text-danger"
                  v-if="nameError">
                  <span v-if="!$v.name.required">Field is required</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="usedln">Select USEDLN</label>
                <select
                  class="form-control"
                  id="usedln"
                  v-model="usedIn">
                  <option/>
                  <option value="ALL">
                    ALL
                  </option>
                  <option value="AA">
                    AA
                  </option>
                  <option value="AB">
                    AB
                  </option>
                  <option value="AD">
                    AD
                  </option>
                  <option value="AE">
                    AE
                  </option>
                  <option value="BL">
                    BL
                  </option>
                  <option value="BPN">
                    BPN
                  </option>
                  <option value="BPY">
                    BPY
                  </option>
                  <option value="BR">
                    BR
                  </option>
                  <option value="BX">
                    BX
                  </option>
                  <option value="CH">
                    CH
                  </option>
                  <option value="CN">
                    CN
                  </option>
                  <option value="DPA">
                    DPA
                  </option>
                  <option value="EFP">
                    EFP
                  </option>
                  <option value="EYP">
                    EYP
                  </option>
                  <option value="FPN">
                    FPN
                  </option>
                  <option value="FPR">
                    FPR
                  </option>
                  <option value="FSU">
                    FSU
                  </option>
                  <option value="LAR">
                    LAR
                  </option>
                  <option value="LU">
                    LU
                  </option>
                  <option value="MPA">
                    MPA
                  </option>
                  <option value="PA">
                    PA
                  </option>
                  <option value="PBP">
                    PBP
                  </option>
                  <option value="PFP">
                    PFP
                  </option>
                  <option value="PL">
                    PL
                  </option>
                  <option value="PPA">
                    PPA
                  </option>
                  <option value="PST">
                    PST
                  </option>
                  <option value="RF">
                    RF
                  </option>
                  <option value="RG">
                    RG
                  </option>
                  <option value="RGF">
                    RGF
                  </option>
                  <option value="RO">
                    RO
                  </option>
                  <option value="RR">
                    RR
                  </option>
                  <option value="SCA">
                    SCA
                  </option>
                  <option value="SCB">
                    SCB
                  </option>
                  <option value="SCC">
                    SCC
                  </option>
                  <option value="SFA">
                    SFA
                  </option>
                  <option value="SPP">
                    SPP
                  </option>
                  <option value="STR">
                    STR
                  </option>
                  <option value="SW">
                    SW
                  </option>
                  <option value="TE">
                    TE
                  </option>
                  <option value="TP">
                    TP
                  </option>
                  <option value="TS">
                    TS
                  </option>
                  <option value="TSU">
                    TSU
                  </option>
                  <option value="UL">
                    UL
                  </option>
                </select>
                <div
                  class="error text-danger"
                  v-if="usedInError">
                  <span v-if="!$v.usedIn.required">Field is required</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="shared">Resource Shared</label>
                <select
                  class="form-control"
                  id="shared"
                  v-model="sharedByClerks">
                  <option/>
                  <option value="true">
                    Shared
                  </option>
                  <option value="false">
                    Not Shared
                  </option>
                </select>
                <div
                  class="error text-danger"
                  v-if="sharedByClerksError">
                  <span v-if="!$v.sharedByClerks.required">Field is required</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="may">May Shift Before Completion</label>
                <select
                  class="form-control"
                  id="may"
                  v-model="mayShiftShipBeforeCompletion">
                  <option/>
                  <option value="true">
                    May shift
                  </option>
                  <option value="false">
                    May Not Shift
                  </option>
                </select>
                <div
                  class="error text-danger"
                  v-if="mayShiftShipBeforeCompletionError">
                  <span v-if="!$v.mayShiftShipBeforeCompletion.required">Field is required</span>
                </div>
              </div>
          </div>
          <div class="form-group">
            <label for="serial">Serial Number</label>
            <input type="text" class="form-control" id="serial" v-model="serial">
          </div>
          <div class="row">
            <div class="col-md-12 text-right pr-3">
              <button
                type="submit"
                class="btn btn-primary"
                @click.prevent="createResource"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-12">
        <!-- <data-table
          :table-headers="tableHeaders"
          :table-data="requests"
          :table-data-keys="tableDataKeys"
          :my-first-loader="myFirstLoader"
          :no-data-found="noDataFound"
          :loading-title="'Please wait while system is loading resources registered'"
          :has-manage-button="false"
          :hasSearchForm="false"
          :isPageable="false"
          :hasEditButton="true"
          :hasDeleteButton="true"
          @goToDelete="deleteThisResource"
          @goToEdit="editResource"
        /> -->

        <data-table
            :table-headers="tableHeaders"
            :table-data="filteredRequests"
            :table-data-keys="tableDataKeys"
            :my-first-loader="myFirstLoader"
            :no-data-found="noDataFound"
            :has-search-form="false"
            :loading-title="'Please wait while system is loading resources registered'"
            :has-manage-button="false"
            :is-pageable="false"
            :has-pagination="false"
            :has-filter="false"
            :has-delete-button="true"
            :has-edit-button="true"
            :permissions="[ {key: 'searchPermission', value: 'API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET'} ]"
            :hasEditButton="true"
            :hasDeleteButton="true"
            @goToDelete="deleteThisResource"
            @goToEdit="editResource"
        />
      </div>
    </div>
  </card>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import resourceView from './resourceView'
  import {required} from 'vuelidate/lib/validators'
  import CrudNotification from '../../../components/DataTable/crudNotification';
  import {mapGetters, mapActions} from 'vuex'
  import DataTable from '../../../components/DataTable/dataTable'


  export default {

    name: 'Resource',

    components: {

      PulseLoader,
      resource_view: resourceView,
      CrudNotification,
      DataTable

    },

    validations: {

      usedIn: {required},
      sharedByClerks: {required},
      mayShiftShipBeforeCompletion: {required},
      active: {required},
      name: {required}

    },

    created() {

      this.$store.dispatch('pagenames/currentPageName', 'Resources');
      this.loadPageableRequestOnCreated();

    },

    data() {

      return {

        usedIn: '',
        sharedByClerks: '',
        mayShiftShipBeforeCompletion: '',
        active: true,
        name: '',
        serial: '',
        noDataFound: false,
        requests: [],
        myFirstLoader: false,

        usedInError: false,
        sharedByClerksError: false,
        mayShiftShipBeforeCompletionError: false,
        activeError: false,
        nameError: false,

        loading: false,
        color: 'green',
        size: '15px',

        ErrorMessage: '',
        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: '',

        tableHeaders: ['Resource Name', 'Serial Number', 'May Shift Ship Before Completion', 'Shared By Clerks', 'Used In', 'status'],
        tableDataKeys: ['name', 'serial', 'mayShiftShipBeforeCompletion', 'sharedByClerks', 'usedIn', 'active'],
        filteredRequests: []
      }

    },

    computed: {

      ...mapGetters('tallyingResources', [

        'getRequestList',
        'getPageCount',
        'getMyFirstLoader',
        'getNoDataFound',
        'getReloadAllRequest',
        'getMyLoader',
        'getSearchParameter',
        'getHasSearchedVariable',
        'getResourceId',
        'getSuccessAlert',
        'getErrorAlert'
      ])

    },

    methods: {

      ...mapActions('tallyingResources', [

        'loadResources',
        'deleteResource'

      ]),

      loadParameters(resourceId) {

        return new Promise((resolve) => {

          this.$store.dispatch('tallyingResources/resourceiding', resourceId);

          resolve()
        })

      },

      setAllGetters(flag) {

        this.filteredRequests = []

        this.requests = this.getRequestList;
        this.sortData(this.requests)
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound

      },

      setDeleteOrEditGetters() {

        this.myFirstLoader = this.getMyFirstLoader;

      },

      loadPageableRequestOnCreated() {

        this.loadResources({}).then(() => {

          this.setAllGetters('onCreated')

        }).catch(() => {

        });

        this.myFirstLoader = this.getMyFirstLoader

      },

      sortData (data) {

        for(let x = 0 ; x < data.length ; x++) {

          const requestObject = {

            ...data[x],
            mayShiftShipBeforeCompletion: data[x].mayShiftShipBeforeCompletion ? 'YES' : 'NO',
            sharedByClerks: data[x].sharedByClerks ? 'YES' : 'NO',
            active: data[x].active ? 'YES' : 'NO'

          };

              this.filteredRequests.push(requestObject)

        }

      },

      deleteThisResource(object) {

        this.$dialog.confirm("You are about to delete resource [ " + object.name + " ]. <br><br>" +
          " Are you sure!.", {
          loader: true,
          html: true,
          animation: 'zoom', // Available: "zoom", "bounce", "fade"
          okText: 'Yes, delete',
          cancelText: 'No, close',

        }).then((dialog) => {

        this.loadParameters(object.id).then(() => {

          this.deleteResource({}).then(() => {

            this.setDeleteOrEditGetters();

            if(this.getSuccessAlert) {

              this.loadNotification('success', 4000, 'Request Message',
                'Resource has been successifully deleted',
                'response', 1000 , true  , true);

              this.loadPageableRequestOnCreated();

            }

            if(this.getErrorAlert) {

              this.loadNotification('error', 4000, 'Request Message',
                'Resource not deleted, it is in use',
                'response', 1000 , false  , true);

            }

          }).catch(() => {

          });

          this.myFirstLoader = this.getMyFirstLoader

        }).catch(() => {

        });

        dialog.close();

      });

      },

      editResource(object) {

        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
        this.$router.push({name: 'resourceEdit', params: {"resourceId": object.id}})

      },

      createResource() {
        if (this.$v.name.required &&
          this.$v.usedIn.required &&
          this.$v.sharedByClerks &&
          this.$v.mayShiftShipBeforeCompletion &&
          this.$v.active
        ) {
          this.loading = true;

          this.$store.dispatch('resources/creeateResource', {
            active: this.active,
            mayShiftShipBeforeCompletion: this.mayShiftShipBeforeCompletion,
            name: this.name,
            sharedByClerks: this.sharedByClerks,
            usedIn: this.usedIn,
            serial: this.serial
          }).then((response) => {
            this.loading = false;
            this.ErrorMessage = '';
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.loadPageableRequestOnCreated();
            this.SuccessMessage = 'Resource Created Successfully'
          }).catch(() => {
            this.loading = false;
            this.ErrorMessage = 'Failed to create Resource Contact System Administrator';
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = ''
          })
        } else {
          this.usedInError = true;
          this.sharedByClerksError = true;
          this.mayShiftShipBeforeCompletionError = true;
          this.activeError = true;
          this.nameError = true
        }
      },
      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
        } else {
          this.ErrorAlert = !this.ErrorAlert;
        }
      },
      enter: function (el, done) {
        var that = this;

        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
        }, 3000); // hide the message after 3 seconds
      },
    },
  }
</script>

<style scoped>

</style>
