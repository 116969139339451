<template>
  <div>
    <card v-if="loadSingleRequestWithDeclaration">
      <div
        class="row"
      >
        <div class="col-md-12 text-center" style="color:#d59a18;">
          <b>Please wait while system is loading declaration details</b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
    </card>
    <card v-if="!loadSingleRequestWithDeclaration">
      <div class="row">
        <div class="col-md-12">
          <ul class="nav-justified">
              <li v-if="Object.keys(requestDeclaration).length === 0" style="margin-top:12px;margin-left:10px;color:red;">
                No Declaration found for this application
              </li>
              <li v-if="Object.keys(requestDeclaration).length !== 0">
                <button class="btn btn-blue btn-blue-fill mr-2 custom-btn-top" @click.prevent="goToNoticelist"> View Notice List</button>
              </li>
              <li v-if="Object.keys(requestDeclaration).length !== 0">
                <button class="btn btn-rosy-brown btn-rosy-brown-fill mr-2 custom-btn-top" @click.prevent="updateDeclaration"> Update Declaration</button>
              </li>
              <li class="float-right">
                <button
                  class="btn btn-default btn-fill custom-btn-all"
                  style="padding: 1px 5px 1px 5px;tab-size: 5px"
                  @click.prevent="goBack"
                >
                  Back
                </button>
              </li>
            </ul>
        </div>
      </div>
    </card>
    <card style="font-size: 13px" v-if="!loadSingleRequestWithDeclaration && Object.keys(requestDeclaration).length !== 0">

      <div class="row">
        <div class="col-md-12 text-center">
          Declaration for Application: File No. - [ <b> {{ singleRequest.fileNo }} </b> ] |
          BL/Airway Bill/RCN - [ <b> {{ singleRequest.blNumber }} </b> ]
        </div>
        <div class="col-md-12">
          <hr />
        </div>
        <div class="col-md-6 text-right">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="loadSingleRequestWithDeclaration"
          />
        </div>
      </div>

      <ul class="nav-justified col-md-12">
        <li class="col-md-3">
          <a
            @click.prevent="setActive('GeneraDetails')"
            :class="[ isActive('GeneraDetails') ? 'is-active-new' : '' ]"
            href="#GeneraDetails"
          >General Details</a>
        </li>
        <li class="col-md-3">
          <a
            @click.prevent="setActive('ItemDetails')"
            :class="[ isActive('ItemDetails') ? 'is-active-verified' : '' ]"
            href="#ItemDetails"
          >Item Details</a>
        </li>
        <li class="col-md-3">
          <a
            @click.prevent="setActive('Attachment')"
            :class="[ isActive('Attachment') ? 'is-active-major' : '' ]"
            href="#Attachment"
          >Attachment</a>
        </li>
        <li class="col-md-3">
          <a
            @click.prevent="setActive('ImportFactsDeclarationForm')"
            :class="[ isActive('ImportFactsDeclarationForm') ? 'is-active-minor' : '' ]"
            href="#ImportFactsDeclarationForm"
          >Import Facts Declaration Form</a>
        </li>
      </ul>

      <div
        class="tab-content py-3"
        id="myTabContent"
        v-if="requestDeclaration !== null"
      >
        <div
          class="tab-pane fade"
          v-if="typeof requestDeclaration.generalDetail !== 'undefined' "
          :class="{ 'active show': isActive('GeneraDetails') }"
          id="GeneraDetails"
        >
          <div class="legend">
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Reference Number
                  </th>
                  <td>
                    {{ requestDeclaration.referenceTin }}-{{ requestDeclaration.referenceYear }}-{{
                      requestDeclaration.referenceSerialNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Mode of Declaration
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.regimeCode + ' - ' +
                      requestDeclaration.generalDetail.regimeName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Processing Clearing Office
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.costumesOfficeCode + ' - ' +
                      requestDeclaration.generalDetail.costumesOfficeName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Declaration Date
                  </th>
                  <td>
                    {{ dateTimeToHuman(requestDeclaration.referenceDate) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.generalDetail !== 'undefined' "
          >
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    CL. Plan
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.clearancePlanCode + ' - ' +
                      requestDeclaration.generalDetail.clearancePlanName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Type of TANSAD Form
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.tansadFormTypeCode + ' - ' +
                      requestDeclaration.generalDetail.tansadFormTypeName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    No. of Items
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.totalItemNumber : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    No. of Packages
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.totalPackageNumber : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Type of Packages
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.packageCode + ' - ' +
                      requestDeclaration.generalDetail.packageName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Gross Weight(Kgss
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.grossWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                      ",") : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Net Weight (Kgs)s
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.netWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                    ",") : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.generalDetail !== 'undefined' "
          >
            <h6 class="legend-title">
              Country
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Consignment Country
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.consignmentCountryCode + ' - ' +
                      requestDeclaration.generalDetail.consignmentCountryName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Country of Export
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.exportCountryCode + ' - ' +
                      requestDeclaration.generalDetail.exportCountryName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Trading Country
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.tradingCountryCode + ' - ' +
                      requestDeclaration.generalDetail.tradingCountryName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Country of Destination
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.destinationCountryCode + ' - ' +
                      requestDeclaration.generalDetail.destinationCountryName : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.generalDetail !== 'undefined' "
          >
            <h6 class="legend-title">
              Exporter
            </h6><!-- MISS -->
            <table style="width:100%">
              <tbody>
                <!--                            <tr>-->
                <!--                                <th class="declaration-table-th">TIN</th>-->
                <!--                                <td>-->
                <!--                                    <i style="color: #DCDCDC;">Not Applicable</i>-->
                <!--                                </td>-->
                <!--                            </tr>-->
                <tr>
                  <th class="declaration-table-th">
                    Name
                  </th>
                  <td>
                    {{ typeof requestDeclaration.occasionalInfo !== 'undefined' ?
                      requestDeclaration.occasionalInfo.exportCompanyName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Address
                  </th>
                  <td>
                    {{ typeof requestDeclaration.occasionalInfo !== 'undefined' ?
                      requestDeclaration.occasionalInfo.exportCompanyAddress : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="requestDeclaration.generalDetail.importer !== null "
          >
            <h6 class="legend-title">
              Importer
            </h6><!-- MISS -->
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    TIN
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.importer.tin !== null ?
                      requestDeclaration.generalDetail.importer.tin.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                       "-") : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Name
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.importer.companyCode + ' - ' +
                      requestDeclaration.generalDetail.importer.companyName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Address
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.importer.address }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Phone number
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.importer.telNo }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="requestDeclaration.generalDetail.declarant !== null "
          >
            <h6 class="legend-title">
              Declarant
            </h6><!-- MISS -->
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    TIN
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.declarant.tin !== null ?
                      requestDeclaration.declarant.tin.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                          "-") : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Name
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.declarant.companyCode + ' - ' +
                      requestDeclaration.generalDetail.declarant.companyName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Address
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.declarant.address }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Phone number
                  </th>
                  <td>
                    {{ requestDeclaration.generalDetail.declarant.telNo }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.generalDetail !== 'undefined' || typeof requestDeclaration.generalValuation !== 'undefined'"
          >
            <h6 class="legend-title">
              Financial
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Delivery Term Code
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.deliveryTermCode + ' - ' +
                      requestDeclaration.generalDetail.deliveryTermName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Delivery Place
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.deliveryPlaceCode : '' }}
                    -
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.deliveryPlaceName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Invoice No.
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.invoiceNumber : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Invoice Date
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.invoiceDay : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Total Invoice Value
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalValuation !== 'undefined' ?
                      requestDeclaration.generalValuation.invoiceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                           ",") : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Payment Method
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.paymentMethodDistinctionCode : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Payment Bank
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.bankCode + ' - ' +
                      requestDeclaration.generalDetail.bankName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Payment Bank Account No.
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.paymentAccountNumber : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Security Distinction Type
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.securityDistinctionCode : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Security Account Number
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.securityAccountNumber : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Nature of Transaction 1
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.natureTrans1Code : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Nature of Transaction 2
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.natureTrans2Code : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Freight Charge
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalValuation !== 'undefined' ?
                      requestDeclaration.generalValuation.externalFreightAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                                   ",") + ' ' +
                      requestDeclaration.generalValuation.externalFreightCurrency : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Insurance
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalValuation !== 'undefined' ?
                      requestDeclaration.generalValuation.insuranceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                             ",") + ' ' +
                      requestDeclaration.generalValuation.insuranceCurrency : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Other Charges
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalValuation !== 'undefined' ?
                      requestDeclaration.generalValuation.otherCostAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                             ",") + ' ' +
                      requestDeclaration.generalValuation.otherCostCurrency : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Deductions
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalValuation !== 'undefined' ?
                      requestDeclaration.generalValuation.deductionAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                             ",") + ' ' +
                      '' +
                      requestDeclaration.generalValuation.deductionCurrency : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Total Customs Value(TZS)
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalValuation !== 'undefined' ?
                      requestDeclaration.generalValuation.totalCustomsVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                             ",") : ' ' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Self Assessment
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      (requestDeclaration.generalDetail.valueTansadYn === 'Y' ? 'YES' : 'NO') : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.generalDetail !== 'undefined' "
          >
            <h6 class="legend-title">
              Transportation
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Transport Mode
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.transportModeCode : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Identity of Transport
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.transportMeansId : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Nationality of Transport
                  </th><!-- MISS -->
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.transportNationalityCode : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Arrival Date
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.arrivalDay : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    CRN
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.crn : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    AWB/BL/RCN/Shipping Order
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.blNumber : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Vessel Name
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.vesselName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Partial of B/L
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      (requestDeclaration.generalDetail.blPartialYn === 'Y' ? 'YES' : 'NO') : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Shipment Place
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.shipmentPlaceCode + ' - ' +
                      requestDeclaration.generalDetail.shipmentPlaceName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Discharge Place
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.dischargePlaceCode + ' - ' +
                      requestDeclaration.generalDetail.dischargePlaceName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Discharge Date
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.dischargeDay : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Entry/Exit Office
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.entryOfficeCode + ' - ' +
                      requestDeclaration.generalDetail.entryOfficeName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Location of Goods
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.goodsLocationCode + ' - ' +
                      requestDeclaration.generalDetail.goodsLocationName : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    No. of Container
                  </th> <!-- MISS -->
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.totalContaineNumber : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Warehouse
                  </th> <!-- MISS -->
                  <td>
                    <span v-if="typeof requestDeclaration.generalDetail !== 'undefined'">
                      {{ requestDeclaration.generalDetail.warehouseCode }} - {{ requestDeclaration.generalDetail.warehouseName }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Previous Bonded Warehouse
                  </th>
                  <td>
                    <span v-if="typeof requestDeclaration.generalDetail !== 'undefined'" />
                    {{ requestDeclaration.generalDetail.previousWarehouseCode }} - {{
                      requestDeclaration.generalDetail.previousWarehouseName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Period(days)
                  </th>
                  <td>
                    {{ typeof requestDeclaration.generalDetail !== 'undefined' ?
                      requestDeclaration.generalDetail.warehousePeriod : '' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="tab-pane fade"
          v-if="typeof requestDeclaration.items !== 'undefined' "
          :class="{ 'active show': isActive('ItemDetails') }"
          id="ItemDetails"
        >

          <data-table
            :tableHeaders="tableHeaderItems"
            :tableData="filteredRequestItems"
            :tableDataKeys="tableDataKeyItems"
            :noDataFound="noDataFound"
            :hasFilter="false"
            :hasManageButton="false"
            :hasPagination="false"
            :isPageable="false"
            :coloredRow="coloredRow"
            :loadingTitle="'Please wait while system is loading your item'"
            :permissions="[ {key: 'searchPermission', value: 'ASSIGNMENT_ALL-NOTIFICATIONS_GET'} ]"
            :buttonList="[ {'code' : 'Application' , 'name' : 'View More' , 'class' : 'btn-primary'} ]"
            @goToApplication="viewMoreItemDetails">
          </data-table>

          <hr v-if="Object.keys(itemDetails).length">

          <div class="legend" v-if="Object.keys(itemDetails).length" id="item-display">
            <h6 class="legend-title">
              Item More Details
            </h6>
            <data-table
              :tableHeaders="tableHeaders"
              :tableData="filteredRequests"
              :tableDataKeys="tableDataKeys"
              :noDataFound="noDataFound"
              :hasFilter="false"
              :hasManageButton="false"
              :hasPagination="false"
              :isPageable="false"
              :loadingTitle="'Please wait while system is loading your item modal'"
              :permissions="[ {key: 'searchPermission', value: 'ASSIGNMENT_ALL-NOTIFICATIONS_GET'} ]">
            </data-table>
            <hr />
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Commodity Code
                  </th>
                  <td>
                    <div>{{ itemDetails.hs8Cd }} - {{ itemDetails.goods1HsDescription }}</div>
                    <hr>
                    <div>{{ itemDetails.hs4Cd }} - {{ itemDetails.goods2HsDescription }}</div>
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Mark and Numbers1
                  </th>
                  <td>
                    {{ itemDetails.packagePart1MarksNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Mark and Numbers2
                  </th>
                  <td>
                    {{ itemDetails.packagePart2MarksNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Country of Origin
                  </th>
                  <td>
                    {{ itemDetails.originCountryCd }} - {{ itemDetails.originCountryName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Customs Procedure Code
                  </th>
                  <td>
                    {{ itemDetails.epcCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Preference Code
                  </th>
                  <td>
                    {{ itemDetails.apcCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Valuation Method
                  </th>
                  <td>{{ itemDetails.valuationMethodCd }}</td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Brand Name
                  </th>
                  <td>
                    {{ itemDetails.brandName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Preceding TANSAD No.
                  </th>
                  <td>
                    {{ itemDetails.precedingCustomsOfficeCd }} - {{ itemDetails.precedingTansadYy }} -
                    {{ itemDetails.precedingTansadSerialNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Preceding TANSAD Date
                  </th>
                  <td>
                    {{ itemDetails.precedingTansadDt }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Preceding Item No.
                  </th>
                  <td>
                    {{ itemDetails.precedingItemNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Letter Ref No.
                  </th>
                  <td>
                    {{ itemDetails.letterReferenceNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Quantity
                  </th>
                  <td>
                    {{ itemDetails.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                               ",") }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Unit of Measurement
                  </th>
                  <td>
                    {{ itemDetails.quantityUnitCd }} - {{ itemDetails.quantityUnitName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Base of Duty
                  </th>
                  <td>
                    {{ itemDetails.dutyBaseVal }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Specific Code
                  </th>
                  <td>
                    {{ itemDetails.specificCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Gross Weight (Kgs)
                  </th>
                  <td>
                    {{ itemDetails.grossWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                  ",") }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Net Weight (Kgs)
                  </th>
                  <td>
                    {{ itemDetails.netWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                ",") }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Customs Value (TZS)
                  </th>
                  <td>
                    {{ itemDetails.itemCustomsVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                     ",") }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Customs Value (USD)
                  </th>
                  <td>
                    {{ itemDetails.itemStatisticalVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                         ",") }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Vehicle Y/N
                  </th>
                  <td>{{ itemDetails.vehicleYn ? 'YES' : 'NO' }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.vehicleTable !== 'undefined' "
          >
            <h6 class="legend-title">
              Vehicle
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Chassis No.
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.chasisNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Year of Make
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.makeYear }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Make/Manufacturer
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.makeCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Model Type
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.modelTypeCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Model No.
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.modelNoCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Propeller Type
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.propellerTypeCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Body Type
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.modelNoCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Transmission Type
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.transmissionTypeCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Category
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.vehicleCategoryCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Tare Weight
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.tareWeight }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Gross Weight
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.grossWeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g,
                                                                                      ",") }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Engine No.
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.engineNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Passenger Capacity
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.seatingCapacity }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Purchase Date
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.purchaseDay }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Color
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.vehicleColor }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Engine Capacity
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.engineCubicCapacity }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Engine KW
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.engineKwCapacity }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Engine Horse Power
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.engineHpCapacity }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    No. of Axle
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.axleNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Axle Distance(cm)
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.axleDistance }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Owner Category
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.ownerCategoryCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Usage
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.usageCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Fuel Type
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.fuelTypeCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Sarpco Certificate No
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.sarpcoCertificateNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Foreign Registration No.
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.foreignRegistrationNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Insurance Company Name
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.insuranceCompanyName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Insurance No.
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.insuranceNo }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Insurance Type
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.insuranceTypeCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Imported From
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.countryCd }} -
                    {{ requestDeclaration.vehicleTable.countryName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Insurance Start Date
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.insuranceStartDay }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Insurance Expiry Date
                  </th>
                  <td>
                    {{ requestDeclaration.vehicleTable.insuranceExpiryDay }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr v-if="typeof requestDeclaration.amendmentItemModalList !== 'undefined' ">

          <table
            class="table table-striped"
            v-if="typeof requestDeclaration.amendmentItemModalList !== 'undefined' "
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-center"
                >
                  #
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Item No.
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Model No.
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Model/Specification
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Component
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Unit Price
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Item Inv.Price
                </th>
              </tr>
            </thead>
            <tbody v-if="requestDeclaration.amendmentItemModalList.length > 0">
              <tr v-for="(itemAmendment , index_itemAmendment) in requestDeclaration.amendmentItemModalList">
                <th
                  scope="col"
                  class="text-center"
                >
                  {{ index_itemAmendment + 1 }}
                </th>
                <td>{{ itemAmendment.itemNo }}</td>
                <td>{{ itemAmendment.modeelNo }}</td>
                <td>{{ itemAmendment.modelSpecification }}</td>
                <td>{{ itemAmendment.component }}</td>
                <td>{{ itemAmendment.quantity }} | {{ itemAmendment.quantityUnitCd }}</td>
                <td>{{ itemAmendment.unitPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
                <td>{{ itemAmendment.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
              </tr>
            </tbody>
          </table>
          <div
            class="col-md-12 text-center bg-grey"
            v-if="typeof requestDeclaration.amendmentItemModalList !== 'undefined'
              && requestDeclaration.amendmentItemModalList.length <= 0"
          >
            No data found
          </div>

          <hr v-if="typeof requestDeclaration.amendmentItemModalListSelected !== 'undefined' ">

          <div
            class="legend"
            v-if="typeof requestDeclaration.amendmentItemModalListSelected !== 'undefined' "
            v-for="(itemAmendmentSelected , index_itemAmendmentSelected) in requestDeclaration.amendmentItemModalListSelected"
          >
            <h6 class="legend-title">
              Modal {{ index_itemAmendmentSelected + 1 }}
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Model/Specification
                  </th>
                  <td>
                    {{ itemAmendmentSelected.modelSpecification }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Component
                  </th>
                  <td>
                    {{ itemAmendmentSelected.component }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Quantity
                  </th>
                  <td>
                    {{ itemAmendmentSelected.quantity }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Unit of Measurement
                  </th>
                  <td>
                    {{ itemAmendmentSelected.quantityUnitCd }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Unit Price
                  </th>
                  <td>
                    {{ itemAmendmentSelected.unitPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Item Inv. Price
                  </th>
                  <td>
                    {{ itemAmendmentSelected.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="tab-pane fade"
          v-if="typeof requestDeclaration.attachedDocument !== 'undefined' "
          :class="{ 'active show': isActive('Attachment') }"
          id="Attachment"
        >
          <hr>

          <table class="table table-striped">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-center"
                >
                  #
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Code
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Document Name
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Description
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Issue Organization
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Item No.
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody v-if="requestDeclaration.attachedDocument.length > 0">
              <tr v-for="(itemAttachment , index_itemAttachment) in requestDeclaration.attachedDocument">
                <td>{{ index_itemAttachment + 1 }}</td>
                <td>{{ itemAttachment.documentTypeCode }} - {{ itemAttachment.documentTypeName }}</td>
                <td>{{ itemAttachment.documentName }}</td>
                <td>{{ itemAttachment.documentDescription }}</td>
                <td>
                  {{ itemAttachment.issueOrganizationCode }} - {{ itemAttachment.issueOrganizationName
                  }}
                </td>
                <td>{{ itemAttachment.itemNo }}</td>
                <td>
                  <span
                    class="download-tesws"
                    @click.prevent="getMinioFileFromTesws(itemAttachment.fileName , 'view')"
                  >view</span>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="col-md-12 text-center bg-grey"
            v-if="requestDeclaration.attachedDocument.length <= 0"
          >
            No data found
          </div>

          <hr>
        </div>

        <div
          class="tab-pane fade"
          :class="{ 'active show': isActive('ImportFactsDeclarationForm') }"
          id="ImportFactsDeclarationForm"
        >
          <div class="legend" v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' || typeof requestDeclaration.occasionalInfo !== 'undefined'">
            <h6 class="legend-title">
              1. Buyer
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    TIN
                  </th>
                  <td>
                    {{ requestDeclaration.importFactDeclaration !== null ? requestDeclaration.importFactDeclaration.buyerTin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "-") : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Name
                  </th>
                  <td>
                    {{ requestDeclaration.occasionalInfo.importCompanyName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Address
                  </th>
                  <td>
                    {{ requestDeclaration.occasionalInfo.importCompanyAddress }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' || typeof requestDeclaration.occasionalInfo !== 'undefined'">
            <h6 class="legend-title">
              2. Seller
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    TIN
                  </th>
                  <td>
                    {{ requestDeclaration.importFactDeclaration !== null && requestDeclaration.importFactDeclaration.sellerTin !== null ? requestDeclaration.importFactDeclaration.sellerTin.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "-") : '' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Name
                  </th>
                  <td>
                    {{ requestDeclaration.occasionalInfo.exportCompanyName }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Address
                  </th>
                  <td>
                    {{ requestDeclaration.occasionalInfo.exportCompanyAddress }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined'">
            <h6 class="legend-title">
              3 - 5
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    3. Invoice No.
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.invoiceNumber :''}}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    4. Contract Number
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.contractNumber:'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    5. Delivery Term
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.contractTermCode:'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Invoice Date
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.invoiceIssueDay:'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Contract Date
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.contractDay :''}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              6. Previous Tab Customs decision
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    Number of previous Tab Customs decision covering boxes
                    7 to
                    8
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.previousDecisionNumber:'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Date of previous Tab Customs decision covering boxes 7
                    to 8
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.previousDecisionDay:'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              7.
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    (a) Is the buyer and seller related (as per paragraph
                    3 & 4
                    of
                    the
                    4
                    <sup>th</sup>
                    Schedule to the Act)? if "NO" go to box 8
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.questionA === 'N' ? 'NO' : 'YES':''}}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (b) Did the relationship influence the price of the
                    imported
                    goods?
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.questionB === 'N' ? 'NO' : 'YES':'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (c) Does the transaction value of the imported goods
                    approximate
                    to the customs value? if "YES?" give details:
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.questionC === 'N' ? 'NO' : 'YES' :''}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              8.
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    a) Are there any restrictions as to the disposition or
                    use
                    of
                    the
                    goods by buyer, other than Regulations which
                    <br>- are imposed or by law or by the public authorities in the Community
                    <br>- are imposed or by law or by the public authorities in the Community
                    <br>- do not substantially affect the value of the goods?
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.questionD === 'N' ? 'NO' : 'YES':'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (b) is the sale or price of goods subject to some
                    condition
                    or
                    consideration for which a value cannot be determined with respect to the goods being
                    valued?
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.questionE === 'N' ? 'NO' : 'YES':'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              9.
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    (a) Have any ROYALITIES and LICENCE FEES on the
                    imported
                    goods
                    been paid either directly or indirectly by the buyer as a condition of sale?
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.questionF === 'N' ? 'NO' : 'YES':'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (b) Is the sale subject to an arrangement under which
                    part
                    of
                    the
                    proceeds of any subsequent RESALE DISPOSAL or USE accrues directly or indirectly to
                    the
                    seller?
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.questionG === 'N' ? 'NO' : 'YES':'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              10.
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    The undersigned, declare that all particulars given in
                    this
                    document are true and complete
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.declareName:'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Place
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.declarePlace:'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Date
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.declareDay:'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              Basis of Calculation
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    11. (a) Net prices on the INVOICES (Prices actually
                    paid or
                    payable for settlement)
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.netPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    11. (b) Indirect payments - see Box 8 (b)
                  </th>
                  <td>
                    {{(requestDeclaration.importFactDeclaration !== null
                    && requestDeclaration.importFactDeclaration.indirectPaymentAmount != null)
                    ? requestDeclaration.importFactDeclaration.indirectPaymentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    12. TOTAL A (in currency of invoice)
                  </th>
                  <td>
                    {{(requestDeclaration.importFactDeclaration !== null
                    && requestDeclaration.importFactDeclaration.totalAmount != null)
                    ? requestDeclaration.importFactDeclaration.totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              13. Adjustments of the Price paid or Payable(4<sup>th</sup> Schedule to
              the
              Act)
              Cost incurred by the buyer:
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    (a) Commissions
                  </th><!-- (Enter "✔" as applicable) -->
                  <td>
                    Selling: {{ requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.sellingCheck:'' }} - Buying: {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.buyingCheck:'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (b) Brokerage
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.brokerageAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (c) Costs for containers and packing
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.containerPackingCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              14.
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    (a) Materials, Components, Parts and similar items
                    incorporated
                    in
                    the imported goods.
                  </th>
                  <td>
                    {{
                    requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.itemIncorporated.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):''
                    }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (b) Tools, Dies, Moulds and similar items used in the
                    production
                    of the imported goods.
                  </th>
                  <td>
                    {{
                    requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.usedProduction.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (c) Materials consumed in the production of the
                    imported
                    goods.
                  </th>
                  <td>
                    {{
                    requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.consumedProduction.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (d) Engineering, development, art work, design work,
                    plans
                    and
                    sketches undertaken elsewhere other than in the country of the import and necessary
                    for the
                    production of imported goods.
                  </th>
                  <td>
                    {{ requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.elsewherePlanSkeche.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              15. & 16.
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    15. Royalties and licences fees - see Box 9(a)
                  </th>
                  <td>
                    {{ requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.royaltyLicenseFee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    16. Proceeds of any subsequent resale, disposal or use
                    accruing
                    to
                    the seller - see box 9(b)
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.resaleProceedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              17. Costs of delivery to the Community
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    (a) Transport
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.transportAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (b) Loading charges
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.loadingCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    (c) Insurance
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.insuranceAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Freight
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.freightAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    Handling charges
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.handlingCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              Post Landing Expenses (may be included in "A")
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    18. TOTAL of "B" (in currency of invoice)
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.totalBAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    19. Cost of transport after arrival in the Community
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.afterTransportCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    20. Charges of Construction, Maintenance and other
                    related
                    expenses after importation - except installation costs
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.otherCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    21. Other charges (specify)
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.otherCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    22. Customs Duty and Taxes in the Community
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.customsDutyTax.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
                <tr>
                  <th class="declaration-table-th">
                    23. TOTAL of "C" (in currency of invoice)
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.totalCAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            class="legend"
            v-if="typeof requestDeclaration.importFactDeclaration !== 'undefined' "
          >
            <h6 class="legend-title">
              CUSTOMS VALUE (A+B) or (A+B-C) If C is included in "A"
            </h6>
            <table style="width:100%">
              <tbody>
                <tr>
                  <th class="declaration-table-th">
                    24. Total Customs value (in currency of invoice)
                  </th>
                  <td>
                    {{requestDeclaration.importFactDeclaration !== null? requestDeclaration.importFactDeclaration.totalCustomsValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):'' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="loadSingleRequestWithDeclarationAfter">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            {{ message }}, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import GlobalModal from '../../../components/globalLoader'

export default {

  name: 'DeclarationView',

  data () {
    return {

      activeItem: '',
      singleRequest: {},
      requestDeclaration: {},
      loadSingleRequestWithDeclaration: false,
      loadSingleRequestWithDeclarationAfter: false,
      message: '',
      color: 'green',
      size: '12px',
      loading: true,

      noDataFound: false,
      tableHeaders: ['Model Specification', 'Quantity Unit Code', 'Model Number', 'Quantity', 'Unit Price', 'Amount', 'First Register Date', 'Last Update Date', 'Component'],
      tableDataKeys: ['modelSpecification', 'quantityUnitCd', 'modelNo', 'quantity', 'unitPrice', 'amount', 'firstRegisterDt', 'lastUpdateDt', 'component'],
      requests: [],
      filteredRequests:[],
      itemDetails: {},

      tableHeaderItems: ['Item Number', 'Commodity Code', 'Country Of Origin', 'CPC', 'Customs Value'],
      tableDataKeyItems: ['itemNo', 'CommodityCode', 'CountryOfOrigin', 'CPC', 'CustomsValue'],
      filteredRequestItems: [],

      coloredRow: []

    }
  },

  components: {

    PulseLoader,
    GlobalModal,
    DataTable: () => import('../../../components/DataTable/dataTable')

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Declaration')

    this.setActive('GeneraDetails')

    if (this.$route.params.requestId) {
      this.loadASingleRequest(this.$route.params.requestId)
    }
  },

  methods: {

    prepareItemsDetails (data) {

      return new Promise((resolve) => {

        this.itemDetails = {}
        this.filteredRequests = []
        this.coloredRow = []

        this.itemDetails = data
        this.sortingData(data.itemModel)
        this.coloredRow.push(data.itemId)

        resolve()

      })

    },

    viewMoreItemDetails (data) {

      this.prepareItemsDetails(data).then(() => {

        let element = document.getElementById("item-display");

        if(element !== null) {

          element.scrollIntoView({behavior: 'smooth'});

        }

      })


    },

    sortingData(data) {

        for(let x = 0 ; x < data.length ; x++) {

            let requestObject = {

                ...data[x],
                firstRegisterDt: data[x].firstRegisterDt !== null && data[x].firstRegisterDt !== '' ? this.dateTimeToHuman(data[x].firstRegisterDt) : '',
                lastUpdateDt: data[x].lastUpdateDt !== null && data[x].lastUpdateDt !== '' ? this.dateTimeToHuman(data[x].lastUpdateDt) : ''

            }

            this.filteredRequests.push(requestObject)

        }

    },

    sortingItemsData(data) {

        this.filteredRequestItems = []

        for(let x = 0 ; x < data.length ; x++) {

            let requestObject = {

                ...data[x],
                CommodityCode: data[x].hsCd + ' : ' + data[x].hs8Cd, 
                CountryOfOrigin: data[x].originCountryName + '( ' + data[x].originCountryCd + ')', 
                CPC: data[x].epcCd + ' : ' + data[x].apcCd, 
                CustomsValue: this.putComma(data[x].itemCustomsVal)

            }

            this.filteredRequestItems.push(requestObject)

        }

    },

    setActive (menuItem) {

      this.activeItem = menuItem
      if(menuItem === 'ItemDetails') {

        this.itemDetails = {}
        this.filteredRequests = []
        this.coloredRow = []

      }

    },

    isActive (menuItem) {
      return this.activeItem === menuItem
    },

    loadASingleRequest (data) {

      this.loadSingleRequestWithDeclaration = true

      API.getSingleRequest(data).then(
        response => {
          if (response.data.status) {
            this.singleRequest = response.data.data

            API.getDeclarationFromLocal(response.data.data.referenceSerialNo,
              response.data.data.referenceTIN, response.data.data.referenceYear).then(
              response => {
                if (response.data.status) {

                  if (response.data.data !== null) {

                    this.requestDeclaration = response.data.data
                    this.sortingItemsData(this.requestDeclaration.items)
                    this.loadSingleRequestWithDeclaration = false

                  } else {

                    this.loadSingleRequestWithDeclaration = false
                    this.requestDeclaration = {}
                    this.loadNotification('error', 4000, 'Declaration Message',
                    'No Declaration details found for this Application',
                    'response', 1000 , true  , true)

                  }
                } else {

                }
              }
            ).catch(() => {
              this.loadSingleRequestWithDeclaration = false
            })
          } else {

          }
        }
      ).catch(() => {
        this.loadSingleRequestWithDeclaration = false
      })
    },

    updateDeclaration () {

      this.message = 'Updating Declaration'
      this.loadSingleRequestWithDeclarationAfter = true

      API.pullDeclarationFromTesws(this.singleRequest.referenceSerialNo, this.singleRequest.referenceTIN, this.singleRequest.referenceYear).then(
        response => {
          if (response.data.data === 'Done') {

            this.loadSingleRequestWithDeclarationAfter = false
            this.loadASingleRequest(this.$route.params.requestId)
            this.loadNotification('success', 4000, '',
            'Declaration has been successfully updated',
            'response', 1000 , true  , true);

          } else {

            this.loadNotification('error', 4000, '',
            typeof response.data.data !== 'undefined' ? response.data.data : response.data.message,
            'response', 1000 , true  , true)

          }
        }
      ).catch(() => {
        this.loadSingleRequestWithDeclarationAfter = false
      })
    },

    goToNoticelist () {

      this.message = 'Retrieving Notice List'
      this.loadSingleRequestWithDeclarationAfter = true

      API.getNoticeListLocal(this.$route.params.requestId).then(
        response => {
          if (response.data.status && response.data.data !== []) {
            document.cookie = 'browser-local-cashed-url-back-to-notice-list=' +
                                this.$route.fullPath + ';Path = /;SameSite = Strict'

            this.$router.push('/clearing-and-forwarding/notice/list/' +
                                this.$route.params.requestId)
          } else {

            this.loadSingleRequestWithDeclarationAfter = false

            this.loadNotification('error', 4000, '',
            typeof response.data.data !== 'undefined' ? response.data.data : response.data.message,
            'response', 1000 , true  , true)
          }
        }
      ).catch(() => {

        this.loadSingleRequestWithDeclarationAfter = false
        this.loadNotification('error', 4000, '',
        'No Notice List found for this Application',
        'response', 1000 , true  , true);

      })
    },

    goBack () {

      if (window.$cookies.isKey('browser-local-cashed-url-back-to')) {
        
        this.$router.push(window.$cookies.get('browser-local-cashed-url-back-to'))
        window.$cookies.remove('browser-local-cashed-url-back-to')

      } else {

        this.$router.go(-1);

      }

    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }

  }

}
</script>

<style scoped>

    .download-link {
        color: green;
        font-weight: bold;
    }

    .download-link:hover {
        cursor: pointer;
        color: #d59a18 !important;
    }

    .custom-btn-top {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }

    .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

    .btn-blue:hover {
        border-color: #0000ff;
        color: #0000ff;
        background-color: #ffffff;
    }

    .btn-blue {
        border-color: #4169e1;
        color: #4169e1;
    }

    .btn-blue-fill {
        background-color: #4169e1;
        opacity: 1;
        color: #FFFFFF;
    }
    .btn-rosy-brown:hover {
        border-color: #775b5b;
        color: #775b5b;
        background-color: #ffffff;
    }

    .btn-rosy-brown {
        border-color: rgba(119,91,91, .5);
        color: rgba(119,91,91, .5);
    }

    .btn-rosy-brown-fill {
        background-color: rgb(119,91,91);
        opacity: 1;
        color: #FFFFFF;
    }

    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .is-active-new, .is-active-verified, .is-active-major, .is-active-minor {
        box-shadow: inset 0 -3px 0 0 #d59a18;
    }

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 2rem;
        border-radius: 3px;
        margin-top: 2rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .legend-btn {
        position: absolute;
        top: -0.9rem;
        padding: 0.2rem 1rem;
        right: 25px;
    }

    table, th, td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    th, td {
        padding: 8px;
        text-align: left;
    }

    th.declaration-table-th {
        width: 40%;
        background-color: #99badd;
    }

    .btn-space {
        margin-right: 10px !important;
    }

    .download-tesws {
        color: green;
    }

    .download-tesws:hover {
        color: blue;
        cursor: pointer;
    }

</style>
