<template>
  <div>
    <Modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      />
      <div slot="body">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="addPackageTypeLoader"
          />
        </div>
        <div class="col-12">
          <hr>
          <form>
            <div class="form-group">
              <label for="packageTypeCode">Package Type Code</label>
              <input
                type="text"
                class="form-control"
                id="packageTypeCode"
                v-model="packageData.packageTypeCode"
              >
              <div v-if="ispackageTypeCode">
                <div
                  class="error"
                  v-if="!this.$v.packageData.packageTypeCode.required"
                >
                  This field is required
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="packageTypeName">Package Type Name</label>
              <input
                type="text"
                class="form-control"
                id="packageTypeName"
                v-model="packageData.packageTypeName"
              >
              <div v-if="ispackageTypeName">
                <div
                  class="error"
                  v-if="!this.$v.packageData.packageTypeName.required"
                >
                  This field is required
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="col-12">
          <div class="float-right">
            <button
              class="btn btn-default btn-fill mr-3"
              @click="createPakageType"
              v-if="!edit"
            >
              Save
            </button>
            <button
              class="btn btn-default btn-fill mr-3"
              @click="editPakageType"
              v-if="edit"
            >
              Edit
            </button>
            <button
              class="btn btn-default btn-fill"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      <hr>
    </Modal>
    <card>
      <div class="row">
        <div class="col-md-12">
          Action color keys
        </div>
        <div class="col-md-12">
          <hr>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <span>
                <font-awesome-icon icon="plus" /><i class=" text-muted">
                  Create New Package Type</i>
              </span>
            </div>
            <div class="col-md-4">
              <span>
                <font-awesome-icon icon="edit" /><i class=" text-muted">Edit Package Type</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-12">
          <div
            class="col-md-12 alert-dismissible fade show"
            role="alert"
            :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
            style="border-radius:5px;"
            @enter="enter"
          >
            <strong v-if="SuccessAlert">Successfully!</strong> <span
              v-if="SuccessAlert"
            >{{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button
              type="button"
              class="close close-custom"
              data-dismiss="alert"
              aria-label="Close"
              v-if="SuccessAlert"
              style="line-height:2px;"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              class="close close-custom"
              data-dismiss="alert"
              aria-label="Close"
              v-if="ErrorAlert"
              style="line-height:2px;"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="col-md-12 text-right">
            <span
              class="all-fa-icons btn-space"
              title="New Package Type"
              v-if="finalUserPermissions.includes('PACKAGE-TYPE_ADD_POST')"
              @click="openModal"
            >
              <font-awesome-icon
                icon="plus"
                size="lg"
                class="pull-right"
              />
            </span>
          </div>
        </div>
        <div class="col-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  Code
                </th>
                <th scope="col">
                  Name
                </th>
                <th scope="col">
                  Edit
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(list,index) in packageTypes">
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td>{{ list.packageTypeCode }}</td>
                <td>{{ list.packageTypeName }}</td>
                <td>
                  <span
                    class="all-fa-icons btn-space"
                    title="New Package Type"
                    v-if="finalUserPermissions.includes('PACKAGE-TYPE_EDIT_PUT')"
                    @click="openModal(list)"
                  >
                    <font-awesome-icon
                      icon="edit"
                      size="lg"
                      class="pull-right"
                    />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Modal from '../../../components/Inputs/Modal'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import API from '../../../api/index'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'PackageType',
  components: {
    Modal,
    PulseLoader
  },

  validations: {
    packageData: {
      packageTypeCode: { required },
      packageTypeName: { required }
    }
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.getPackageTypes()
  },

  data () {
    return {
      showModal: false,

      color: 'green',
      size: '13px',
      loading: true,

      addPackageTypeLoader: false,
      packageData: {
        packageTypeCode: '',
        packageTypeName: ''
      },
      selectedPackageType: '',
      edit: false,

      SuccessMessage: '',
      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,

      ispackageTypeCode: false,
      ispackageTypeName: false,

      packageTypes: []
    }
  },

  methods: {

    getPackageTypes () {
      API.getPackageTypes().then((response) => {
        this.packageTypes = response.data.data
      }).catch((error) => {

      })
    },

    createPakageType () {
      if (this.$v.packageData.packageTypeCode.$invalid || this.$v.packageData.packageTypeName.$invalid) {
        this.ispackageTypeCode = true
        this.ispackageTypeName = true
      } else {
        this.addPackageTypeLoader = true
        API.createPackagetype(this.packageData).then(() => {
          this.addPackageTypeLoader = false
          this.packageData.packageTypeCode = ''
          this.packageData.packageTypeName = ''
          this.getPackageTypes()

          this.addPackageTypeLoader = false
          this.ErrorMessage = ''
          this.SuccessAlert = true
          this.ErrorAlert = false
          this.SuccessMessage = 'Package Type Created Successfully'
          this.SuccessAlert = true

          this.closeModal()
        }).catch((error) => {
          this.addPackageTypeLoader = false
          this.ErrorMessage = ' Failed To Create Package Type, please contact system administrator'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
        })
      }
    },

    editPakageType () {
      if (this.$v.packageData.packageTypeCode.$invalid || this.$v.packageData.packageTypeName.$invalid) {
        this.ispackageTypeCode = true
        this.ispackageTypeName = true
      } else {
        this.addPackageTypeLoader = true
        API.editPackagetype({
          packageTypeCode: this.packageData.packageTypeCode,
          packageTypeName: this.packageData.packageTypeName,
          packageTypeId: this.selectedPackageType
        }).then(() => {
          this.addPackageTypeLoader = false
          this.packageData.packageTypeCode = ''
          this.packageData.packageTypeName = ''
          this.getPackageTypes()
          this.closeModal()

          this.ErrorMessage = ''
          this.SuccessAlert = true
          this.ErrorAlert = false
          this.SuccessMessage = 'Package Type Edited Successfully'
          this.SuccessAlert = true
        }).catch((error) => {
          this.addPackageTypeLoader = false
          this.ErrorMessage = ' Failed To Create Package Type, please contact system administrator'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
        })
      }
    },

    openModal (list = null) {
      this.showModal = true

      if (list !== null) {
        this.edit = true
        this.packageData.packageTypeCode = list.packageTypeCode
        this.packageData.packageTypeName = list.packageTypeName
        this.selectedPackageType = list.packageTypeId
      }
    },

    closeModal () {
      this.showModal = false
      this.selectedPackageType = ''
      this.edit = false
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
        this.singlePlanAddLoader = []
      } else {
        this.ErrorAlert = !this.ErrorAlert
        this.singlePlanAddLoader = []
      }
    },
    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }
  }
}
</script>

<style scoped>

</style>
