<template>

  <data-table
    :tableHeaders="tableHeaders"
    :tableData="filteredRequests"
    :tableDataKeys="tableDataKeys"
    :pageSizeProp="pageSize"
    :pageNo="pageNo"
    :hasFilter="false"
    :myLoader="loading"
    :myFirstLoader="loading"
    :noDataFound="noDataFound"
    :searchParameterProp="searchParameter"
    :loadingTitle="'Please wait while system is loading Statement'"
    :hasManageButton="false"
    :totalElements="totalElements"
    :totalPages="totalPages"
    :isFirst="isFirst"
    :isLast="isLast"
    :isHovered="[]"
    :pageNumber="pageNumber"
    :is-hovered="false"
    :permissions="[ {key: 'transaction_by_account', value: 'PRINCIPAL_TRANSACTION_BY-ACCOUNT-NO_{ACCOUNTID}_GET'} ]"
    :isPageable="false"
    @onChange="onChange"
    @goToPage="goToPage"
    @goToBillGenerate="goToApplication"
  >
    <div slot = "pagination">
      <paginate
        v-model="getIsPage"
        :page-count=parseInt(totalPages)
        :page-range="3"
        :margin-pages="2"
        :click-handler="goToPage"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'">
      </paginate>
    </div>
  </data-table>


</template>

<script>

  import AGENCYAPI from "../../../api/agency"


    export default {
        name: "principalAccountStatement",


      components:{
        DataTable: () => import('../../../components/DataTable/dataTable'),

      },

      created() {
        this.$store.dispatch('pagenames/currentPageName', 'Principal Account')
        this.getAccountStatement();
      },

      computed:{
        getIsPage: {

          get: function () {
            return this.isPage
          },
          set: function (newValue) {
            this.isPage = newValue;
          }

        }
      },

      data(){

          return{
            tableHeaders:['Reference No','Description','Transaction Type','Amount(USD)','Balance(USD)', 'Transaction Date'],
            tableDataKeys:['referenceNo','description','transactionType','amount','balance', 'transactionDate'],
            statement:[],
            filteredRequests:[],
            pageNo: 0,
            pageSize: 2000,
            totalElements: "",
            totalPages: "",
            isFirst: false,
            isLast: false,
            pageNumber: '',
            pageCount: 1,
            noDataFound:false,
            searchParameter: '',
            isPage: 0,

            params:"",
            color: 'green',
            size: '12px',
            size1: '9px',
            loading:false,
          }
      },

      methods:{


        filterRequests(data) {
          this.filteredRequests=[];

          data.map((value,index)=>{
            this.filteredRequests.push({
              ...value,
              amount:this.putComma(value.amount,2),
              balance:this.putComma(value.balance,2)
            })
          })
        },

        getAccountStatement(){

          this.loading=true;

          AGENCYAPI.getPrincipalAccountStatement(this.$route.params.principalId).then((resp)=>{
            this.loading=false;
            this.statement=resp.data.data;
            this.filterRequests(this.statement);
          }).catch((err)=>{
            this.loading=false;
            this.loadNotification('error', 4000, 'Loading Statement',
              'Failed To Load Customer Statement',
              'response', 1000 , true  , true)
          });
        },

        onChange(event) {
          this.pageSize=event;
          this.pageNo=0;
        },

        goToPage(page) {

          if(page > 0) {page = page - 1;}
          this.isPage = page + 1;
          this.pageNo=page;

          this.getAccountStatement();

        },

        goToApplication(){

        }
      }
    }
</script>

<style scoped>

</style>
