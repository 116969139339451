<template>
  <div>
  <card>
    <data-table
      :date-prop="'Created At '"
      :has-edit-button="false"
      :has-manage-button="true"
      :has-search-form="false"
      :has-view-button="false"
      :is-first="isFirst"
      :is-last="isLast"
      :is-pageable="isPageable"
      :loading-title="'Please wait while system is loading Tally Charges'"
      :my-first-loader="loading"
      :no-data-found="noDataFound"
      :page-no="pageNo"
      :page-number="pageNumber"
      :page-size-prop="pageSize"
      :table-data="tallyCharges"
      :table-data-keys="tableDataKeys"
      :table-headers="tableHeaders"
      :total-elements="totalElements"
      :total-pages="totalPages"
      @goToApplication="goToApplication"
      @goToPage="goToPage"
      @onChange="onChange">
    </data-table>
  </card>
  </div>
</template>

<script>
  import TALLY_API from '../../../api/tally';
  import DataTable from '../../../components/DataTable/updatedTable'

    export default {
      name: "tallyCharges",

      props: ["selectedTallyType"],

      components: {
        DataTable,
      },

      created() {
          this.getTallyCharges();
          this.operationType = this.selectedTallyType;
      },

      data() {
          return {
            pageNo: 0,
            pageSize: 15,
            pageCount: 1,
            totalPages: 1,
            isPageable: true,
            operationType: '',
            pageNumber: 0,
            isFirst: true,
            isLast: false,
            noDataFound: false,
            loading: true,
            totalElements: '',
            tallyCharges: [],
            tableHeaders: ['Vessel Name', 'Voyage No.', 'Status', 'Location', 'End Time'],
            tableDataKeys: ['vesselName', 'voyage', 'chargeStatus', 'location', 'endTime'],
          }
      },

      methods: {
        getTallyCharges() {
          TALLY_API.getTallyDocumentCharges(this.selectedTallyType).then((response)=> {
            this.loading = false;
            response.data.data.content.map((data)=> {

              data = Object.assign(data, {
                vesselName: data.notification.vesselName,
                voyage: data.notification.voyage,
                location: data.notification.locationName,
                endTime: data.notification.endTime,
              });

               this.tallyCharges.push(data)
            })
          });
        },

        goToApplication(object) {
          document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
          this.$router.push({name: "tallyChargeDetail", params : {
            obj: {...object}, chargeId: object.id }});
        },

        onChange(event) {
          this.pageSize = event;
          this.pageNo = 0;
          this.getNotifications()
        },

        goToPage(page) {
          this.pageNo = page;
          this.getNotifications()
        },
      }
    }
</script>

<style scoped>

</style>
