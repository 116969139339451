


<script>
  import Notifications from 'vue-notification'

  export default {
    name: "newNotification",
    components:{
      Notifications
    },
    props: {

      SuccessMessage: '',
      ErrorMessage: '',
      SuccessAlert: {

        type: Boolean,
        default: false

      },
      ErrorAlert: {

        type: Boolean,
        default: false

      }

    },
    data() {
      return {
        id: 0,
        animation: {
          enter: {
            opacity: [1, 0],
            translateX: [0, -300],
            scale: [1, 0.2]
          },
          leave: {
            opacity: 0,
            height: 0
          }
        }
      }
    },

    methods: {
      fadeMe(){
        this.$emit('fadeMe')
      },
      enter(){
        this.$emit('enter')
      },
      show(group, type = '') {
        const text = `
        This is notification text!
        <br>
        Date: ${new Date()}
      `
        this.$notify({
          group,
          title: `Test ${type} notification #${this.id++}`,
          text,
          type,
          data: {
            randomNumber: Math.random()
          }
        })
      },
      clean(group) {
        this.$notify({group, clean: true})
      }
    }


  }
</script>

<style scoped>

</style>
