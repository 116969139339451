<template>
<div>
  <div class="row">
    <div class="col">
      <dashboard-card
        tag="totalExportApplications"
        heading="TOTAL EXPORT APPLICATIONS"
        content="192,212"
        sub-content1="QoQ Change 12%"
        sub-content2="MoM Change 22%"
        icon="caret-up"
        icon2="caret-down"
        icon-color-type="success"
        icon-color-type2="danger"
        description-title="Total Applications"
        description-content="This is the total number of applications both rejected, approved, canceled and resubmitted."
      />
    </div>
    <div class="col">
      <dashboard-card
        tag="totalImportApplications"
        heading="TOTAL IMPORT APPLICATIONS"
        content="192,212"
        sub-content1="QoQ Change 12%"
        sub-content2="MoM Change 22%"
        icon="caret-up"
        icon2="caret-down"
        icon-color-type="success"
        icon-color-type2="danger"
        description-title="Total Applications"
        description-content="This is the total number of applications both rejected, approved, canceled and resubmitted."
      />
    </div>
  </div>
  <div class="row" style="margin-top: 0!important;">
    <div class="col">
      <dashboard-card
        card-title="EXPORT BILLINGS"
        tag="TotalBilledApplications"
        heading="TOTAL PAID APPLICATIONS"
        content="192,212"
        sub-content1="QoQ Change 12%"
        sub-content2="MoM Change 22%"
        icon="receipt"
        icon2="receipt"
        icon-color-type="success"
        icon-color-type2="danger"
        footer-content="<b>Charged: </b> 212 &nbsp; <b>Billed :</b> 332 &nbsp; <b>Approved:</b>  12"
        description-title="Total Applications"
        description-content="This is the total number of applications both rejected, approved, canceled and resubmitted."
      />
    </div>
    <div class="col">
      <dashboard-card
        card-title="EXPORT APPLICATIONS"
        tag="TotalAssessedApplications"
        heading="ASSESSED APPLICATIONS"
        content="192,212"
        sub-content1="QoQ Change 12%"
        sub-content2="MoM Change 22%"
        icon="caret-up"
        icon2="caret-down"
        icon-color-type="success"
        icon-color-type2="danger"
        footer-content="<b>Received: </b> 212 &nbsp; <b>Approved:</b> 332 &nbsp;  <b>Rejected:</b>  122 &nbsp; <b> Returned</b>: 223"
        description-title="Total Applications"
        description-content="This is the total number of applications both rejected, approved, canceled and resubmitted."
      />
    </div>
    <div class="col">
      <dashboard-card
        card-title="IMPORT BILLINGS"
        tag="TotalBilledApplications"
        heading="TOTAL PAID APPLICATIONS"
        content="192,212"
        sub-content1="QoQ Change 12%"
        sub-content2="MoM Change 22%"
        icon="caret-up"
        icon2="caret-down"
        icon-color-type="success"
        icon-color-type2="danger"
        footer-content="<b>Charged: </b> 212 &nbsp; <b>Billed :</b> 332 &nbsp; <b>Approved:</b>  12"
        description-title="Total Applications"
        description-content="This is the total number of applications both rejected, approved, canceled and resubmitted."
      />
    </div>
    <div class="col">
      <dashboard-card
        card-title="IMPORT APPLICATIONS"
        tag="TotalAssessedApplications"
        heading="ASSESSED APPLICATIONS"
        content="192,212"
        sub-content1="QoQ Change 12%"
        sub-content2="MoM Change 22%"
        icon="caret-up"
        icon2="caret-down"
        icon-color-type="success"
        icon-color-type2="danger"
        footer-content="<b>Received: </b> 212 &nbsp; <b>Approved:</b> 332 &nbsp;  <b>Rejected:</b>  122 &nbsp; <b> Returned</b>: 223"
        description-title="Total Applications"
        description-content="This is the total number of applications both rejected, approved, canceled and resubmitted."
    />
    </div>
  </div>
</div>
</template>

<script>
import DashboardCard from '../dashboardChartComponents/dashboard card'
export default {
  name: "testDashboard",
  components:{
    DashboardCard
  }
}
</script>

<style scoped>

</style>
