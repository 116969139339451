<template>
  <div class="wrapper">
    <side-bar>
      <mobile-menu slot="content" />

      <dashboard-menu />

      <document-control-menu />

      <clearing-and-forwarding-menu />

      <customer-menu />

      <shipping-tallying-menu />

      <shipping-agency-menu />

      <!-- <shipping-agency-customer/> -->

      <revenue-menu />

      <reporting-menu />

      <user-management-menu />

      <admin-logs />

      <configuration-menu />
    </side-bar>

    <div class="main-panel" :class="[ closeSidebarPanel ? 'wider-main-panel' : 'normal-main-panel']" style="font-size: 12px">
      <top-navbar />
      <div class="content">
        <div
          class="container-fluid"
          style="padding-left: 0;padding-right: 0; font-size:13px;">
          <dashboard-content @click="toggleSidebar"/>
        </div>
      </div>
      <content-footer />
    </div>
  </div>
</template>
<script>
import DashboardMenu from './dashboardComponents/dashboard';
// import ShippingAgencyCustomer from './dashboardComponents/shippingAgencycustomer';
import CustomerMenu from './dashboardComponents/customer.vue';
import DocumentControlMenu from './dashboardComponents/documentControl';
import ClearingAndForwardingMenu from './dashboardComponents/clearingAndForwarding';
import RevenueMenu from './dashboardComponents/revenue';
import ReportingMenu from './dashboardComponents/reporting';
import UserManagementMenu from './dashboardComponents/userManagement';
import ConfigurationMenu from './dashboardComponents/configuration';
import ShippingTallyingMenu from './dashboardComponents/shippingTallying';
import ShippingAgencyMenu from './dashboardComponents/shippingAgency';
import TopNavbar from './TopNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import MobileMenu from './MobileMenu.vue';
import PulseLoader from 'vue-spinner/src/PulseLoader';
import { mapGetters } from 'vuex';
import AdminLogs from "./dashboardComponents/adminLogs";

export default {

  name: 'MainDashboard',

  components: {
    AdminLogs,

    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    PulseLoader,
    DashboardMenu,
    DocumentControlMenu,
    ClearingAndForwardingMenu,
    RevenueMenu,
    ReportingMenu,
    UserManagementMenu,
    ConfigurationMenu,
    ShippingTallyingMenu,
    ShippingAgencyMenu,
    // ShippingAgencyCustomer,
    CustomerMenu

  },

  computed: {

    ...mapGetters('global', [

      'getToggleSideBar'

    ]),

    closeSidebarPanel () {
      return this.getToggleSideBar;
    }

  },

  methods: {

    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};

</script>
<style>
    .sidebar-wrapper {
        font-size: 15px !important;
    }
</style>

<style lang="scss" scoped>
    .main-panel .content {
        padding: 15px 5px 0 10px !important;
    }

    .wider-main-panel {
        width: 100% !important;
    }

    .normal-main-panel {
        width: calc(100% - 290px) !important;
    }
</style>
