<template>
    <div>
        <card class="card-space">
            <card>
                <div class="row">
                    <div class="col-12">
                        <crud-notification
                            :success-message="SuccessMessage"
                            :error-message="ErrorMessage"
                            :success-alert="SuccessAlert"
                            :error-alert="ErrorAlert"
                            @fadeMe="fadeMe"
                            @enter="enter"
                        />
                    </div>
                </div>
                <form>
                    <div class="row">
                        <div class="col-md-3">
                            <!-- <base-input
                                type="time"
                                label="Shift Start Time"
                                v-model="startTime"
                                :max="todayDate"
                                @change="StartDateError = false"
                            /> -->
                            <label class="custom-font">Shift Start Time</label>
                            <date-picker  style="width:100% !important;" v-model="startTime" type="time"></date-picker>
                            <div v-if="StartDateError">
                                <div
                                    class="error text-danger"
                                    v-if="!$v.startTime.required"
                                >
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <!-- <base-input
                                type="time"
                                label="Shift End Time"
                                v-model="endTime"
                                :min="startTime"
                                :max="todayDate"
                                @change="EndDateError = false"
                            /> -->
                            <label class="custom-font">Shift End Time</label>
                            <date-picker  style="width:100% !important;" v-model="endTime" type="time"></date-picker>
                            <div v-if="EndDateError">
                                <div
                                    class="error text-danger"
                                    v-if="!$v.endTime.required"
                                >
                                    This field is required
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <base-input
                                    type="text"
                                    label="Shift Name"
                                    v-model="name"
                                />
                                <div v-if="nameError">
                                    <div
                                        class="error text-danger"
                                        v-if="!$v.name.required"
                                    >
                                        This field is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pr-3">
                            <div class="row">
                                <div class="col-md-7">
                                    <pulse-loader
                                        :loading="loading"
                                        :color="color"
                                        :size="size"
                                        v-if="!loading"
                                    />
                                </div>
                                <div class="col-md-5 text-right">
                                    <button
                                        type="submit"
                                        class="btn btn-primary custom-btn"
                                        v-if="!edit"
                                        @click.prevent="createTallyShift"
                                    >
                                        Create Shift
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-primary custom-btn mr-3"
                                        v-if="edit"
                                        @click.prevent="editTallyShift"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        type="submit"
                                        class="btn btn-danger custom-btn"
                                        v-if="edit"
                                        @click="cancelEdit"
                                    >
                                        Cancel
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </card>

            <br>

            <card>
                <data-table
                    :table-headers="tableHeaders"
                    :table-data="filteredRequests"
                    :table-data-keys="tableDataKeys"
                    :my-first-loader="myFirstLoader"
                    :no-data-found="noDataFound"
                    :has-search-form="false"
                    :loading-title="'Please wait while system is loading active tally shifts'"
                    :has-manage-button="false"
                    :is-pageable="false"
                    :has-pagination="false"
                    :has-filter="false"
                    :has-delete-button="true"
                    :has-edit-button="true"
                    :permissions="[ {key: 'searchPermission', value: 'API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET'} ]"
                    @goToEdit="selectedShift"
                    @goToDelete="deleteTallyShift"
                />
            </card>
        </card>
    </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import { required } from 'vuelidate/lib/validators';
import DataTable from '../../../components/DataTable/dataTable';
import CrudNotification from '../../../components/DataTable/crudNotification';
import { mapGetters, mapActions } from 'vuex';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {

  name: 'Shift',

  components: {

    PulseLoader,
    DataTable,
    CrudNotification,
    DatePicker

  },

  validations: {

    startTime: { required },
    endTime: { required },
    name: { required }

  },

  created () {
    this.loadTallyShifts();
    this.$store.dispatch("pagenames/currentPageName", "Shifts");

    this.$store.dispatch('tallyshift/erroralerting', this.ErrorAlert);
    this.$store.dispatch('tallyshift/successalerting', this.SuccessAlert);
  },

  computed: {

    ...mapGetters({

      getTallyShiftDetails: 'tallyshift/getTallyShiftDetails',
      getMyLoader: 'tallyshift/getMyLoader',
      getMyFirstLoader: 'tallyshift/getMyFirstLoader',
      getNoDataFound: 'tallyshift/getNoDataFound',
      getSuccessAlert: 'tallyshift/getSuccessAlert',
      getErrorMessage: 'tallyshift/getErrorMessage',
      getErrorAlert: 'tallyshift/getErrorAlert',
      getSuccessMessage: 'tallyshift/getSuccessMessage',

      getShiftName: 'tallyshift/getShiftName',
      getStartTime: 'tallyshift/getStartTime',
      getEndTime: 'tallyshift/getEndTime',
      getShiftId: 'tallyshift/getShiftId'
    })

  },

  data () {
    return {

      startTime: '',
      StartDateError: false,
      endTime: '',
      EndDateError: false,
      todayDate: '',
      name: '',
      nameError: false,
      loading: false,
      color: 'green',
      size: '15px',
      edit: false,
      shiftId: '',

      tableHeaders: ['Shift Name', 'Start Time', 'End Time', 'Status'],
      tableDataKeys: ['name', 'startTime', 'endTime', 'active'],
      myFirstLoader: false,
      noDataFound: false,
      myLoader: false,
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: '',

      filteredRequests: [],

    };
  },

  methods: {

    ...mapActions('tallyshift', [

      'loadShifts',
      'createShift',
      'editShift',
      'deleteShift'

    ]),

    loadTallyShifts () {

      this.ErrorMessage = []

      this.loadShifts({}).then(() => {

        this.requests = this.getTallyShiftDetails;
        this.sortData(this.requests)
        this.myFirstLoader = this.getMyFirstLoader;
        this.noDataFound = this.getNoDataFound;
        this.SuccessMessage = this.getSuccessMessage;
        this.ErrorMessage = this.getErrorMessage;
        this.ErrorAlert = this.getErrorAlert;
        this.SuccessAlert = this.getSuccessAlert;
        
      }).catch(() => {

      });

      this.myFirstLoader = this.getMyFirstLoader;
      this.requests = this.getTallyShiftDetails;
    },

    sortData (data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x],
          active: data[x].active ? 'ACTIVE' : 'INACTIVE'

        };

            this.filteredRequests.push(requestObject)

      }

    },

    loadCreateShiftPayload () {
      return new Promise((resolve) => {
        this.$store.dispatch('tallyshift/shiftnaming', this.name);
        this.$store.dispatch('tallyshift/starttiming', this.startTime);
        this.$store.dispatch('tallyshift/endtiming', this.endTime);
        this.$store.dispatch('tallyshift/shiftiding', this.shiftId);

        resolve();
      });
    },

    createTallyShift () {

      if (this.$v.name.$invalid || this.$v.startTime.$invalid || this.$v.endTime.$invalid) {
        if (this.$v.name.$invalid) { this.nameError = true; }

        if (this.$v.startTime.$invalid) { this.StartDateError = true; }

        if (this.$v.endTime.$invalid) { this.EndDateError = true; }
      } else {

        this.loadCreateShiftPayload().then(() => {

          this.createShift({ startTime: this.startTime, endTime: this.endTime, name: this.name }).then((response) => {

            if(response.data.status) {

              this.loading = this.getMyLoader;
              this.SuccessMessage = this.getSuccessMessage;
              this.ErrorMessage = this.getErrorMessage;
              this.ErrorAlert = this.getErrorAlert;
              this.SuccessAlert = this.getSuccessAlert;
              this.$store.dispatch('tallyshift/setTallyShifts', [])
              this.loadTallyShifts();
              this.startTime = '';
              this.endTime = '';
              this.name = '';

            } else {

              this.loadNotification('error', 4000, '', response.data.message, 'response', 1000 , true  , true)

            }

          }).catch(() => {

          });

          this.loading = this.getMyLoader;
          this.EndDateError = false;
          this.StartDateError = false;
          this.nameError = false;
        }).catch(() => {

        });
      }
    },

    editTallyShift () {
      if (this.$v.name.$invalid && this.$v.startTime.$invalid && this.$v.endTime.$invalid) {
        if (this.$v.name.$invalid) {
          this.nameError = true;
        }

        if (this.$v.startTime.$invalid) {
          this.StartDateError = true;
        }

        if (this.$v.endTime.$invalid) {
          this.EndDateError = true;
        }
      } else {
        this.loadCreateShiftPayload().then(() => {
          this.editShift({
            startTime: this.startTime,
            endTime: this.endTime,
            name: this.name,
            id: this.shiftId
          }).then(() => {
            this.loading = this.getMyLoader;
            this.SuccessMessage = this.getSuccessMessage;
            this.ErrorMessage = this.getErrorMessage;
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessAlert = this.getSuccessAlert;
            this.$store.dispatch('tallyshift/setTallyShifts', [])
            this.loadTallyShifts();
            this.startTime = '';
            this.endTime = '';
            this.name = '';
            this.edit = false;
          }).catch(() => {

          });

          this.loading = this.getMyLoader;
          this.EndDateError = false;
          this.StartDateError = false;
          this.nameError = false;
        }).catch(() => {

        });
      }
    },

    deleteTallyShift (object) {
      this.$dialog.confirm('You are about to delete tally shift <br><br>' +
                    ' Shift Name. - [ ' + object.name + ' ] <br>' +
                    ' Start Time. - [ ' + object.startTime + ' ] <br>' +
                    ' End Time - [ ' + object.endTime + ' ] <br><br>' +
                    ' Are you sure!.', {
        loader: true,
        html: true

      }).then((dialog) => {
        this.$store.dispatch('tallyshift/shiftiding', object.id)
        this.deleteShift({}).then(() => {
          this.loading = this.getMyLoader;
          this.SuccessMessage = this.getSuccessMessage;
          this.ErrorMessage = this.getErrorMessage;
          this.ErrorAlert = this.getErrorAlert;
          this.SuccessAlert = this.getSuccessAlert;
          this.$store.dispatch('tallyshift/setTallyShifts', [])
          this.loadTallyShifts();
          this.edit = false;
        }).catch(() => {

        });

        this.loading = this.getMyLoader;
        this.EndDateError = false;
        this.StartDateError = false;
        this.nameError = false;

        dialog.close();
      });
    },

    selectedShift (object) {
      this.startTime = object.startTime;
      this.endTime = object.endTime;
      this.name = object.name;
      this.shiftId = object.id;
      this.edit = true;
    },

    cancelEdit () {
      this.startTime = '';
      this.endTime = '';
      this.name = '';
      this.edit = false;
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
        this.$store.dispatch('tallyshift/successalerting', this.SuccessAlert);
      } else {
        this.ErrorAlert = !this.ErrorAlert;
        this.$store.dispatch('tallyshift/erroralerting', this.ErrorAlert);
      }
    },

    enter: function (el, done) {
      const that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
        this.$store.dispatch('tallyshift/erroralerting', that.ErrorAlert);
        this.$store.dispatch('tallyshift/successalerting', that.SuccessAlert);
      }, 3000); // hide the message after 3 seconds
    }

  }

};

// methods: {
//
//   ...mapActions('tallyshift', [
//
//     'loadShifts',
//     'createShift',
//     'editShift',
//     'deleteShift'
//
//   ]),
//
//   loadTallyShifts () {
//     this.loadShifts({}).then(() => {
//       this.requests = this.getTallyShiftDetails
//       this.myFirstLoader = this.getMyFirstLoader
//       this.noDataFound = this.getNoDataFound
//       this.SuccessMessage = this.getSuccessMessage
//       this.ErrorMessage = this.getErrorMessage
//       this.ErrorAlert = this.getErrorAlert
//       this.SuccessAlert = this.getSuccessAlert
//     }).catch(() => {
//
//     })
//
//     this.myFirstLoader = this.getMyFirstLoader
//     this.requests = this.getTallyShiftDetails
//   },
//
//   loadCreateShiftPayload () {
//     return new Promise((resolve) => {
//       this.$store.dispatch('tallyshift/shiftnaming', this.name)
//       this.$store.dispatch('tallyshift/starttiming', this.startTime)
//       this.$store.dispatch('tallyshift/endtiming', this.endTime)
//       this.$store.dispatch('tallyshift/shiftiding', this.shiftId)
//
//       resolve()
//     })
//   },
//
//   createTallyShift () {
//     if (this.$v.name.$invalid || this.$v.startTime.$invalid || this.$v.endTime.$invalid) {
//       if (this.$v.name.$invalid) { this.nameError = true }
//
//       if (this.$v.startTime.$invalid) { this.StartDateError = true }
//
//       if (this.$v.endTime.$invalid) { this.EndDateError = true }
//     } else {
//       this.loadCreateShiftPayload().then(() => {
//         this.createShift({
//           startTime: this.startTime,
//           endTime: this.endTime,
//           name: this.name
//         }).then(() => {
//           this.loading = this.getMyLoader
//           this.SuccessMessage = this.getSuccessMessage
//           this.ErrorMessage = this.getErrorMessage
//           this.ErrorAlert = this.getErrorAlert
//           this.SuccessAlert = this.getSuccessAlert
//           this.loadTallyShifts()
//           this.startTime = ''
//           this.endTime = ''
//           this.name = ''
//         }).catch(() => {
//
//         })
//
//         this.loading = this.getMyLoader
//         this.EndDateError = false
//         this.StartDateError = false
//         this.nameError = false
//       }).catch(() => {
//
//       })
//     }
//   },
//
//   editTallyShift () {
//     if (this.$v.name.$invalid && this.$v.startTime.$invalid && this.$v.endTime.$invalid) {
//       if (this.$v.name.$invalid) { this.nameError = true }
//
//       if (this.$v.startTime.$invalid) { this.StartDateError = true }
//
//       if (this.$v.endTime.$invalid) { this.EndDateError = true }
//     } else {
//       this.loadCreateShiftPayload().then(() => {
//         this.editShift({
//           startTime: this.startTime,
//           endTime: this.endTime,
//           name: this.name,
//           id: this.shiftId
//         }).then(() => {
//           this.loading = this.getMyLoader
//           this.SuccessMessage = this.getSuccessMessage
//           this.ErrorMessage = this.getErrorMessage
//           this.ErrorAlert = this.getErrorAlert
//           this.SuccessAlert = this.getSuccessAlert
//           this.loadTallyShifts()
//           this.startTime = ''
//           this.endTime = ''
//           this.name = ''
//           this.edit = false
//         }).catch(() => {
//
//         })
//
//         this.loading = this.getMyLoader
//         this.EndDateError = false
//         this.StartDateError = false
//         this.nameError = false
//       }).catch(() => {
//
//       })
//     }
//   },
//
//   deleteTallyShift (object) {
//     this.$dialog.confirm('You are about to delete tally shift <br><br>' +
//                   ' Shift Name. - [ ' + object.name + ' ] <br>' +
//                   ' Start Time. - [ ' + object.startTime + ' ] <br>' +
//                   ' End Time - [ ' + object.endTime + ' ] <br><br>' +
//                   ' Are you sure!.', {
//       loader: true,
//       html: true
//
//     }).then((dialog) => {
//       this.deleteShift(object.shiftId).then(() => {
//         this.loading = this.getMyLoader
//         this.SuccessMessage = this.getSuccessMessage
//         this.ErrorMessage = this.getErrorMessage
//         this.ErrorAlert = this.getErrorAlert
//         this.SuccessAlert = this.getSuccessAlert
//         this.loadTallyShifts()
//         this.edit = false
//       }).catch(() => {
//
//       })
//
//       this.loading = this.getMyLoader
//       this.EndDateError = false
//       this.StartDateError = false
//       this.nameError = false
//
//       dialog.close()
//     })
//   },
//
//   selectedShift (object) {
//     this.startTime = object.startTime
//     this.endTime = object.endTime
//     this.name = object.name
//     this.shiftId = object.id
//     this.edit = true
//   },
//
//   cancelEdit () {
//     this.startTime = ''
//     this.endTime = ''
//     this.name = ''
//     this.edit = false
//   },
//
//   fadeMe: function () {
//     if (this.SuccessAlert) {
//       this.SuccessAlert = !this.SuccessAlert
//       this.$store.dispatch('tallyshift/successalerting', this.SuccessAlert)
//     } else {
//       this.ErrorAlert = !this.ErrorAlert
//       this.$store.dispatch('tallyshift/erroralerting', this.ErrorAlert)
//     }
//   },
//
//   enter: function (el, done) {
//     const that = this
//
//     setTimeout(function () {
//       that.SuccessAlert = false
//       that.ErrorAlert = false
//       this.$store.dispatch('tallyshift/erroralerting', that.ErrorAlert)
//       this.$store.dispatch('tallyshift/successalerting', that.SuccessAlert)
//     }, 3000) // hide the message after 3 seconds
//   }
//
// }

</script>

<style scoped>

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: 100px;
        background-color: #FFFFFF !important;
    }

    .card-space {
        padding: 2px 5px 2px 5px !important;
    }

</style>
