<template>
  <card>
    <div class="row">
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successful!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <label>Select Clerk</label>
        <list-select
          :list="allUsers"
          :selected-item="selectedClerkObject"
          option-value="userId"
          option-text="fullName"
          class="form-control"
          placeholder="Clerk name"
          @select="populateDcUserDetails"
        />
        <div
          class="error text-danger"
          v-if="selectedClerkError"
        >
          <span v-if="!$v.selectedClerk.required">Field is required</span>
        </div>
      </div>
      <div class="col-md-12">
        <resource-view
          type="showSelect"
          @selectedResource="selectResource"
          ref="resource"
        />
        <div
          class="error text-danger"
          v-if="selectedResourceIdError"
        >
          <span v-if="!$v.selectedResourceId.required">Field is required</span>
        </div>
      </div>
      <button
        class="btn btn-default ml-2"
        @click.prevent="createResourceClerk"
      >
        Submit
      </button>
    </div>
  </card>
</template>

<script>
import API from '../../../api'
import TALLY_API from '../../../api/tally'
import { ModelListSelect, ListSelect } from 'vue-search-select'
import resourceView from './resourceView'
import { required } from 'vuelidate/lib/validators'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'ResourceClerk',

  validations: {
    selectedClerk: { required },
    selectedResourceId: { required },
    active: { required }
  },

  components: {
    ModelListSelect,
    'resource-view': resourceView,
    PulseLoader,
    ListSelect
  },

  created () {
    this.$store.dispatch("pagenames/currentPageName", "Clerks");
    this.getUsers()
  },

  data () {
    return {
      allUsers: [],
      selectedClerk: '',
      selectedResourceId: '',
      selectedClerkError: false,
      selectedResourceIdError: false,
      activeError: false,
      active: true,
      selectedClerkObject: {},

      loading: false,
      color: 'green',
      size: '15px',

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: ''
    }
  },
  methods: {

    populateDcUserDetails (object) {

      this.selectedClerkObject = object,
      this.selectedClerk = object.userId

    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },
    getUsers () {
      this.loading = true
      API.getUsers().then((response) => {
        this.loading = false
        this.allUsers = response.data.data
      }).catch(() => {
        this.ErrorMessage = ' Failed To Load Clerks'
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.ErrorAlert = true
      })
    },
    fullName (name) {
      return `${name.firstName} - ${name.lastName}`
    },

    selectResource (resourceId) {
      this.selectedResourceId = resourceId
    },

    createResourceClerk () {

      if (this.$v.selectedResourceId.required && this.$v.selectedClerk.required && this.$v.active.required) {
        this.selectedClerkError = false
        this.selectedResourceIdError = false
        this.loading = true

        TALLY_API.createResourceClerk({
          active: this.active,
          clerkId: this.selectedClerk,
          deviceId: this.selectedResourceId
        }).then(() => {

          this.loading = false
          this.selectedClerk = ''
          this.active = ''
          this.selectedResourceId = ''
          this.$refs.resource.resource = ''
          this.selectedClerkObject = {}
          // this.ErrorMessage = ''
          // this.SuccessMessage = ' '
          this.loadNotification('success', 4000, '', 'SuccessFully Associated Resource to Clerk', 'response', 1000 , true  , true)
          // this.SuccessAlert = true
          // this.ErrorAlert = false

          this.selectedClerkError = false
          this.selectedResourceIdError = false
          this.activeError = false
        }).catch(() => {

          this.loading = false
          this.ErrorMessage = ' Failed To Associate Resource with Clerk'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
        })
      } {
        this.selectedClerkError = true
        this.selectedResourceIdError = true
        this.activeError = true
      }
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    }

  }
}
</script>

<style scoped>

</style>
