<template>
  <div>
    <card>
      <data-table
        :table-headers="tableHeaders"
        :table-data="requests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :no-data-found="noDataFound"
        :loading-title="'Please wait while system is loading tally charge details'"
        :has-manage-button="false"
        :has-search-form="false"
        :is-pageable="false"
      />
      <div class="col-md-12">
        <hr>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-9 left loader-div-gif" />
          <div class="col-md-3">
            <button type="button" class="btn btn-fill btn-outline-info float-right" @click="goBack">
              Back
            </button>
            <button type="button" class="btn btn-success mr-3 float-right" @click="generateBill"
                    v-if="requests.length > 0 && !requests[0].billed"
            >
              Generate Bill
            </button>
          </div>
        </div>
      </div>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Please wait while system is generating requested bill
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader :loading="loading" :color="color" :size="size" />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import GlobalModal from '../../../../components/globalLoader';
import DataTable from '../../../../components/DataTable/updatedTable';

export default {

  name: 'GenerateBill',

  components: {

    PulseLoader,
    GlobalModal,
    DataTable,

  },

  created () {

    this.$store.dispatch('pagenames/currentPageName', 'Tally Charge Billing');
    this.documentId = this.$route.params.chargeId;
    this.operationType = this.$route.params.operationType;
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadPageableRequestOnCreated();
    this.$store.dispatch('tallybilling/erroralerting', false);
    this.$store.dispatch('tallybilling/successalerting', false);
  },

  data () {
    return {

      color: 'green',
      size: '12px',
      loading: true,

      noDataFound: false,
      myLoader: false,
      myFirstLoader: false,
      requests: [],
      documentId: '',
      operationType: '',
      finalUserPermissions: [],
      tableHeaders: ['Voyage', 'vessel Name', 'container Size (description)', 'container Count', 'car Count', 'Total Weight', 'tariff Item', 'tariff Fee'],
      tableDataKeys: ['voyage', 'vesselName', 'containerSize', 'containerCount', 'carCount', 'cargoTotalWeight', 'tariffItemName', 'tariffFeesName'],

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: ''

    };
  },

  computed: {

    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),

    ...mapGetters('shippingtallybilling', [
      'getRequestList',

      'getMyFirstLoader',
      'getNoDataFound',

      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
    ]),


  },

  methods: {

    ...mapActions('shippingtallybilling', [

      'getAllUnbilledTallyChargeDetails',
      'getUnbilledTallyChargeDetailsStuffing',
      'generateBillForTalliedChargesAwaitingBilling'

    ]),

    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('shippingtallybilling/tallieddocumentiding', this.documentId);
        this.$store.dispatch('shippingtallybilling/operationType', this.operationType)
        resolve();
      });
    },

    formatDetails(data){
      if (data){
        let len  = Object.keys(data).length
        for (let i = 0; i< len; i++){
          data[i].tariffFeesName = data[i]?.tariffFee?.tariffFeeName;
          data[i].tariffItemName = data[i]?.tariffItem?.tariffItemName;
        }
      }
      return data
    },

    loadPageableRequestOnCreated () {
      this.loadParameters().then(() => {

        if(this.operationType === 'STUFFING' ||  this.operationType === 'DESTUFFING'){
          this.getUnbilledTallyChargeDetailsStuffing({}).then(() => {
            this.requests = this.formatDetails(this.getRequestList);
            this.noDataFound = this.getNoDataFound;
            this.myFirstLoader = this.getMyFirstLoader;
          }).catch(() => {
            this.myFirstLoader = false;
          });

        }else{
          this.getAllUnbilledTallyChargeDetails({}).then(() => {
            this.requests = this.formatDetails(this.getRequestList);
            this.noDataFound = this.getNoDataFound;
            this.myFirstLoader = this.getMyFirstLoader;
          }).catch(() => {
            this.myFirstLoader = false;
          });
        }

      }).catch(() => {

      });
    },

    setGettersForNotification () {
      return new Promise((resolve) => {
        this.myLoader = this.getMyLoader;
        if(this.getSuccessAlert) {

          this.loadNotification('success', 4000, 'Request Message',
            'Bill has been successifully generated',
            'response', 1000 , true  , true);

        }

        if(this.getErrorAlert) {

          this.loadNotification('error', 4000, 'Request Message',
            'Bill for selected charge not generated',
            'response', 1000 , false  , true);

        }

        resolve();
      });
    },

    generateBill () {

      this.$dialog.confirm('You are about to create bill for the currently selected tallying charge. <br><br>' +
          ' Are you sure!.', {
        loader: true,
        html: true

      }).then((dialog) => {
        this.$store.dispatch('shippingtallybilling/operationType', this.operationType)
        this.generateBillForTalliedChargesAwaitingBilling({}).then(() => {

          this.setGettersForNotification();
          this.loadPageableRequestOnCreated()
        }).catch(() => {

        });

        this.myLoader = this.getMyLoader;

        dialog.close();
      }).catch(()=>{

      });

    },

    goBack () {
      if (window.$cookies.isKey('browser-local-cashed-url-back')) {
        this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));

        window.$cookies.remove('browser-local-cashed-url-back');
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
        this.$store.dispatch('shippingtallybilling/successalerting', this.SuccessAlert);
      } else {
        this.ErrorAlert = !this.ErrorAlert;
        this.$store.dispatch('shippingtallybilling/erroralerting', this.ErrorAlert);
      }
    },

    enter: function (el, done) {
      const that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
        this.$store.dispatch('shippingtallybilling/erroralerting', that.ErrorAlert);
        this.$store.dispatch('shippingtallybilling/successalerting', that.SuccessAlert);
      }, 3000); // hide the message after 3 seconds
    }

  }

};
</script>

<style scoped>

</style>
