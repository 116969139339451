<template>
  <div>
    <card style="font-size: 12px">
      <div class="row" v-if="thisFirstRequestLoader">
        <div class="col-md-12 text-center loader-color">
          <b>Please wait while system is loading manifest charge details</b>
        </div>
        <div class="col-md-12 text-center">
          <br />
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader :loading="loading" :color="color" :size="size" />
        </div>
      </div>
      <div class="row" v-if="!thisFirstRequestLoader">
        <div class="col-md-12">
          <div class="legend" v-if="charge.manifest !== null">
            <h6 class="legend-title">Document Details</h6>
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">Carrier Name</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.senderId != null
                    ? charge.manifest.senderId
                    : "Not Specified"
                }}
              </div>

              <div class="col-md-3 div-table div-table-title">Ship Name</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.transportMeansName != null
                    ? charge.manifest.transportMeansName
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">Arrival Port</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.arrivalPort != null
                    ? charge.manifest.arrivalPort
                    : "Not Specified"
                }}
              </div>

              <div class="col-md-3 div-table div-table-title">
                Voyage Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.voyageNumber != null
                    ? charge.manifest.voyageNumber
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Tasac Control Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.applicationReference != null
                    ? charge.manifest.applicationReference
                    : "Not Specified"
                }}
              </div>
              <div class="col-md-3 div-table div-table-title">Arrival Date</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.arrivalDate != null
                    ? charge.manifest.arrivalDate
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Departure Date
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.departureDate != null
                    ? charge.manifest.departureDate
                    : "Not Specified"
                }}
              </div>

              <div class="col-md-3 div-table div-table-title">Created Date</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.manifest.createDateTime != null
                    ? charge.manifest.createDateTime
                    : "Not Specified"
                }}
              </div>
            </div>
          </div>
          <div class="legend" v-if="charge.document !== null">
            <h6 class="legend-title">Document Details</h6>
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">Carrier Name</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.document.carrierName != null
                    ? charge.document.carrierName
                    : "Not Specified"
                }}
              </div>

              <div class="col-md-3 div-table div-table-title">
                Control Reference
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.document.controlRef != null
                    ? charge.document.controlRef
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">Call Sign</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.document.callSign != null
                    ? charge.document.callSign
                    : "Not Specified"
                }}
              </div>

              <div class="col-md-3 div-table div-table-title">
                Voyage Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.document.voyageNumber != null
                    ? charge.document.voyageNumber
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Tasac Control Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.document.applicationReference != null
                    ? charge.document.applicationReference
                    : "Not Specified"
                }}
              </div>
              <div class="col-md-3 div-table div-table-title">Message Code</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.document.messageCode != null
                    ? charge.document.messageCode
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Message Function
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.document.messageFunction != null
                    ? charge.document.messageFunction
                    : "Not Specified"
                }}
              </div>
            </div>
          </div>
        </div>

        <br />

        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">Charge Details</h6>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Billing Status
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ charge.billed ? "BILLED" : "CHARGED" }}
              </div>

              <div class="col-md-3 div-table div-table-title">Company Name</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.company.companyName != null
                    ? charge.company.companyName
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Company Address
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.company.companyAddress1 != null
                    ? charge.company.companyAddress1
                    : "Not Specified"
                }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Company Address 2
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.company.companyAddress2
                    ? charge.company.companyAddress2
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Company Website
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.company.website != null
                    ? charge.company.website
                    : "Not Specified"
                }}
              </div>

              <div class="col-md-3 div-table div-table-title">
                Company Telephone
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.company.companyTelephone != null
                    ? charge.company.companyTelephone
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Company Email
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.company.email ? charge.company.email : "Not Specified"
                }}
              </div>

              <div class="col-md-3 div-table div-table-title">Company Tin</div>
              <div class="col-md-3 div-table div-table-value">
                {{
                  charge.company.tinNo != null
                    ? charge.company.tinNo
                    : "Not Specified"
                }}
              </div>
            </div>
            <br />
            <div class="row  div-table-row">
                <div class="col-md-3 div-table div-table-title">Company Vrn</div>
                <div class="col-md-3 div-table div-table-value">
                  {{
                    charge.company.vrn != null
                      ? charge.company.vrn
                      : "Not Specified"
                  }}
                </div>
                <div class="col-md-3 div-table div-table-title">
                  Manifest Count
                </div>
                <div class="col-md-3 div-table div-table-value">
                  {{
                    charge.manifestCount ? charge.manifestCount : "Not Specified"
                  }}
                </div>
            </div>
          </div>
          
        </div><!-- ------------->
      </div>
      <div class="row text-right" v-if="!thisFirstRequestLoader">
        <div class="col-md-12">
          <button
            type="submit"
            class="btn btn-primary btn-space mr-2 custom-btn"
            @click="openBillingModal()"
            v-if="!charge.billed"
          >
            <font-awesome-icon icon="file-invoice-dollar" /> &nbsp; Generate
            Bill
          </button>
          <button
            class="btn btn-danger mr-2 custom-btn"
            @click="openCancelationModal()"
            v-if="!charge.billed"
          >
            <b>Cancel Charge</b>
          </button>
          <button class="btn btn-default btn-fill custom-btn" @click.prevent="goBack">
            <font-awesome-icon icon="undo" /> &nbsp; Back
          </button>
        </div>
      </div>
      <br />
    </card>

    <modal v-if="showBillingModel">
      <!-- GENERATE BILL COMMENT  -->
      <div slot="body" class="modal-title col-md-12">
        <div class="row" style="padding: 0 15px 0 15px">
            <div class="col-md-12">
              <label>BILL GENERATION</label>
              <br/>
              <label>Provide Comment for bill generation</label>
              <br/>
              <textarea
                class="form-control"
                id="generateCharge"
                rows="4"
                v-model="billComment"
              ></textarea>
              <div v-if="billCommentError">
                <div
                  class="error"
                  v-if="$v.billComment.required"
                >
                  This field is required
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <br />
            </div>
            <div class="col-md-12 text-right">
              <div class="row">
                  <div class="col-md-9 pt-3">
                    <pulse-loader
                      :loading="billingLoader"
                      :color="color"
                      :size="size"
                    />
                  </div>
                  <div class="col-md-3">
                    <button
                      type="submit"
                      class="btn btn-primary mr-2 custom-btn"
                      data-dismiss="modal"
                      :disabled="billingLoader"
                      @click.prevent="generateDCBill()"
                    >
                      Generate Bill
                    </button>
                    <button
                      class="btn btn-default btn-fill custom-btn"
                      :disabled="billingLoader"
                      @click.prevent="closeModal"
                    >
                      Close
                    </button>
                  </div>
              </div>
            </div>
        </div>
      </div>
    </modal>

    <modal v-if="showCancelationModal">
      <!-- CANCEL BILL COMMENT  -->
      <div slot="body" class="modal-title col-md-12">
        <div class="row" style="padding: 0 15px 0 15px">
            <div class="col-md-12">
              <label>CHARGE CANCELATION</label>
              <br/>
              <label>Provide Comment for charge cancelation</label>
              <br/>
              <textarea
                class="form-control"
                id="generateCharge"
                rows="4"
                v-model="chargeCancelationComment"
              ></textarea>
            </div>
            <div class="col-md-12">
              <br />
            </div>
            <div class="col-md-12 text-right">
              <button
                type="submit"
                class="btn btn-danger mr-2 custom-btn"
                data-dismiss="modal"
                @click.prevent="cancelCharge()"
              >
                Cancel Charge
              </button>

              <button
                class="btn btn-default btn-fill custom-btn"
                @click.prevent="closeModal"
              >
                Close
              </button>
            </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { required } from "vuelidate/lib/validators";
import GlobalModal from "../../../../components/globalLoader";
import Modal from "../../../../components/Inputs/Modal";
import API_REVENUE from "../../../../api/rev";
import API_DC from '../../../../api/dc'

export default {
  name: "Application",

  components: {
    PulseLoader,
    GlobalModal,
    Modal,
  },

  validations: {
    chargeCancelationComment: { required },
    billComment: { required },
  },

  created() {

    if (this.$route.params.documentChargeId) {
      this.$store.dispatch('pagenames/currentPageName', 'Manifest Charge Billing')

      this.documentChargeId = this.$route.params.documentChargeId

      this.getSingleCharge(this.documentChargeId)
    }
  },

  data() {
    return {
      color: "green",
      size: "12px",
      loading: true,
      showBillingModel: false,
      showCancelationModal: false,
      billComment: "",
      chargeCancelationComment: "",
      thisFirstRequestLoader: false,
      documentChargeId: "",
      billingLoader: false,
      billCommentError: false,
      charge: {}
    };
  },

  computed: {},

  methods: {
    openBillingModal() {
      this.showBillingModel = true;
    },

    openCancelationModal() {
      this.showCancelationModal = true;
    },

    closeModal() {
      this.showBillingModel = false;
      this.showCancelationModal = false;
    },

    getSingleCharge(id) {

      this.thisFirstRequestLoader = true

      API_DC.getV2DocumentChargeDetails(id)
        .then((response) => {

          if (response.status === 200) {

              this.charge = response.data.data
              this.thisFirstRequestLoader = false

          }else {

              this.charge = {};
              this.thisFirstRequestLoader = false

          }

        })
        .catch(() => {

        });
    },

    generateDCBill() {

      // if(this.$v.billComment.$invalid) {

      //     this.billCommentError = true

      // } else {

          let dc = this.charge;
          this.billingLoader = true

          if (dc.document != null) {

            API_REVENUE.generateManifestBills(dc.document.documentId, this.billComment)
              .then((response) => {

                this.billingLoader = false

                  if (response.data.status) {

                    this.loadNotification('success', 4000, 'Bill Generation', 'Bill has been successfully created', 'response', 1000 , true  , true)
                    this.getSingleCharge(this.documentChargeId)
                    this.closeModal();

                  } else {

                    this.loadNotification('error', 4000, 'Bill Generation', response.data.message, 'response', 1000 , true  , true)

                  }
                
              })
              .catch(() => {

                this.loadNotification('error', 4000, 'Bill Message','Bill not generated, please try or contact system admin','response', 1000 , true  , true);
                this.closeModal();

              });
          }

          if (dc.manifest != null) {

            API_REVENUE.generateV2ManifestBills(dc.manifest.cargoManifestId, this.billComment)
              .then((response) => {

                this.billingLoader = false

                if (response.data.status) {

                    this.loadNotification('success', 4000, 'Bill Generation', 'Bill has been successfully created', 'response', 1000 , true  , true)
                    this.getSingleCharge(this.documentChargeId)
                    this.closeModal();

                  } else {

                    this.loadNotification('error', 4000, 'Bill Generation', response.data.message, 'response', 1000 , true  , true)

                  }
                
              })
              .catch(() => {

                this.loadNotification('error', 4000, 'Bill Message','Bill not generated, please try or contact system admin','response', 1000 , true  , true);
                this.closeModal();

              });
          }

      // }
      
    },

    goBack() {

      if (window.$cookies.isKey("browser-local-cashed-url-back")) {

        this.$router.push(window.$cookies.get("browser-local-cashed-url-back"));
        window.$cookies.remove("browser-local-cashed-url-back");

      }

    }

  },
};
</script>

<style scoped>
.legend {
  position: relative;
  border: 1px solid #dcdcdc;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, 0.5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #dcdcdc;
  font-weight: 900;
}

.notice-table-label {
  width: 250px;
  background-color: #99badd;
  font-weight: normal;
}

.custom-table,
.custom-table th,
.custom-table td {
  border: 1px solid #dcdcdc;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 8px;
  text-align: left;
}

.cancel-charge:hover {
  cursor: pointer !important;
}

ul.nav-justified {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ededee;
  border-radius: 3px;
}

ul.nav-justified li {
  float: left;
}

ul.nav-justified li a:hover {
  color: #d59a18;
}

.text-link {
  color: blue;
}

.text-link:hover {
  color: #d59a18;
  cursor: pointer;
}

ul.nav-justified li a {
  display: block;
  text-align: center;
  padding: 16px 20px 10px 21px;
  text-decoration: none;
  color: blue;
  font-weight: bold;
  text-transform: uppercase;
}

.custom-btn {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  padding: 5px 9px 5px 9px !important;
  font-size: 14px !important;
}

.btn-blue:hover {
  border-color: #0000ff;
  color: #0000ff;
  background-color: #ffffff;
}

.btn-blue {
  border-color: #4169e1;
  color: #4169e1;
}

.btn-blue-fill {
  background-color: #4169e1;
  opacity: 1;
  color: #ffffff;
}

.btn-purple:hover {
  border-color: #800080;
  color: #800080;
  background-color: #ffffff;
}

.btn-purple {
  border-color: #9932cc;
  color: #9932cc;
}

.btn-purple-fill {
  background-color: #9932cc;
  opacity: 1;
  color: #ffffff;
}

.alert-success {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-danger {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
