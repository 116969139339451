<template>
  <div class="row">
    <div class="col-md-12 text-center">
      <img
        src="/img/logo.png"
        alt
        width="700"
        height="500"
      >
      <h3>WELCOME TO TANZANIA SHIPPING AGENCIES CORPORATION</h3>
    </div>
  </div>
</template>

<script>
    export default {
        name: "dashboard",

      created() {

        this.$store.dispatch('pagenames/currentPageName', 'My Dashboard')

      }
    }
</script>

<style scoped>

</style>
