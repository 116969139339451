<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12 text-left">
          <b>Date range Option</b>
        </div>
        <div class="col-md-3">
          <base-input
            type="date"
            label="Start Date"
            v-model="startDate"
            :max="todayDate"
            @change="StartDateError = false"
          />
          <div v-if="StartDateError">
            <div class="error">
              This field is required
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <base-input
            type="date"
            label="End Date"
            v-model="endDate"
            :min="startDate"
            :max="todayDate"
            @change="EndDateError = false"
          />
          <div v-if="EndDateError">
            <div class="error">
              This field is required
            </div>
          </div>
        </div>
      </div>
      <hr>
      <br>
      <div class="row">
        <div class="col-md-12">
          <button
            @click="reset"
            class="btn btn-fill btn-outline-info float-right mr-2"
            data-dismiss="modal"
          >
            Reset Options
          </button>
          <button
            class="btn btn-primary btn-space float-right mr-2"
            data-dismiss="modal"
            @click="getRequestData"
          >
            Search
          </button>
        </div>
      </div>
    </card>
    <card>
      <div class="row">
        <div class="col-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
            v-if="loading"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  Date
                </th>
                <th scope="col">
                  BL Number
                </th>
                <th scope="col">
                  File Number
                </th>
                <th scope="col">
                  Customer
                </th>
                <th scope="col">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(list,index) in requestData">
                <th scope="row">
                  {{ index + 1 }}
                </th>
                <td>{{ dateToHuman(list.requestDate) }}</td>
                <td>{{ list.blNumber }}</td>
                <td>{{ list.fileNo }}</td>
                <td>{{ list.customer==null? null : list.customer.name }}</td>
                <td>{{ list.status }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
            </div>
            <div
              class="col-md-9 text-right disable-highlight"
              unselectable="on"
              style="line-height: 5px;font-size: 20px"
            >
              <span
                class="btn-space"
                :class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="firstPage"
                title="go to first page"
              >First
              </span>
              <span
                class="btn-space mr-4"
                :class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="prevPage"
                title="go to previous page"
              >
                <font-awesome-icon icon="angle-double-left" />
              </span>

              <span class="btn-space" />
              <span class="btn-space" />

              <span
                class="btn-space mr-2"
                :class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="nextPage"
                title="go to next page"
              >
                <font-awesome-icon icon="angle-double-right" />
              </span>
              <span
                class="btn-space"
                :class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="lastPage"
                title="go to last page"
              >Last
              </span>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>

import API from '../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'RequestReport',

  components: {
    PulseLoader
  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Applications General Report')

    this.getRequestData()
  },

  data () {
    return {

      requestData: [],
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      loading: false,
      startDate: '',
      endDate: '',
      todayDate: new Date().toJSON().slice(0, 10),
      StartDateError: false,
      EndDateError: false,

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      color: 'green',
      size: '16px'

    }
  },

  methods: {

    reset () {
      this.startDate = ''
      this.endDate = ''

      this.getRequestData()
    },
    getRequestData () {
      this.loading = true

      let currentTime = ''
      let hours = new Date().getHours()
      let minute = new Date().getMinutes()
      let second = new Date().getSeconds()
      let millisecond = new Date().getMilliseconds()

      if (hours < 10) {
        hours = '0' + hours
      }
      if (minute < 10) {
        minute = '0' + minute
      }
      if (second < 10) {
        second = '0' + second
      }
      if (millisecond < 10) {
        millisecond = '00' + millisecond
      }
      if (millisecond >= 10 && millisecond < 100) {
        millisecond = '0' + millisecond
      }

      currentTime = 'T00:00:00'

      let startDate = ''
      let endDate = ''
      let params = ''

      if (this.startDate !== '') {
        startDate = '?&startDate=' + this.startDate + currentTime
      } else {
        startDate = ''
      }

      if (this.endDate !== '') {
        endDate = '&endDate=' + this.endDate + currentTime
      } else {
        endDate = ''
      }

      if (endDate === '' && startDate === '') {
        params = '?pageNo=' + this.pageNo + '&pageSize=' + this.pageSize
      } else {
        params = startDate + endDate + '&pageNo=' + this.pageNo + '&pageSize=' + this.pageSize
      }

      API.getRequestparams(params).then((response) => {
        this.loading = false
        this.requestData = response.data.data
        this.pageCount = response.data.message
      })
    },

    dateToHuman (stringDate) {
      if (stringDate !== null || stringDate !== undefined) {
        const splittedDate = stringDate.split('T')

        return splittedDate[0]
      }
    },
    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.viewBills()
      } else {
        this.getRequestData()
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.viewBills()
      } else {
        this.getRequestData()
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.viewBills()
      } else {
        this.getRequestData()
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.viewBills()
      } else {
        this.getRequestData()
      }
    }

  },

  fadeMe: function () {
    if (this.SuccessAlert) {
      this.SuccessAlert = !this.SuccessAlert
      this.singlePlanAddLoader = []
    } else {
      this.ErrorAlert = !this.ErrorAlert
      this.singlePlanAddLoader = []
    }
  }
}
</script>

<style scoped>

</style>
