<template>
  <div>
    <form
      @submit.prevent="loadReport"
      v-if="finalUserPermissions.includes('DOCUMENTS_DOCUMENTS-LODGED-LOGS_GET')"
    >
      <card>
        <div class="col-md-12 text-center">
          <b>DC MANIFEST STATUS REPORT DOWNLOAD</b>
        </div>
        <div
          class="col-md-12 alert-dismissible fade show"
          role="alert"
          style="border-radius:5px;"
          :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
          @enter="enter"
        >
          <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
          <strong v-if="ErrorAlert">Notification!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

          <button
            aria-label="Close"
            class="close close-custom"
            data-dismiss="alert"
            style="line-height:2px;"
            type="button"
            v-if="SuccessAlert"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <button
            aria-label="Close"
            class="close close-custom"
            data-dismiss="alert"
            style="line-height:2px;"
            type="button"
            v-if="ErrorAlert"
            @click="fadeMe"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <br>

        <div class="row">
          <div class="col-md-12 text-left">
            <b>Date range Option</b>
          </div>

          <div class="col-md-3">
            <base-input
              type="date"
              label="Start Date"
              v-model="startDate"
              :max="todayDate"
              @change="StartDateError = false; StartAndEndDateError = false"
            />
            <div v-if="StartDateError">
              <div class="error">
                This field is required
              </div>
            </div>
            <div v-if="StartAndEndDateError">
              <div class="error">
                Fill in all fields
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <base-input
              type="date"
              label="End Date"
              v-model="endDate"
              :min="startDate"
              :max="todayDate"
              @change="EndDateError = false; StartAndEndDateError = false"
            />
            <div v-if="EndDateError">
              <div class="error">
                This field is required
              </div>
            </div>
          </div>
        </div>

        <hr>

        <br>

        <div class="text-center row">
          <div class="col-md-6 text-right loader-div">
            <span v-if="isGeneratingReport">Please wait system is generating requested report ....</span>
          </div>
          <div class="col-md-2 left loader-div-gif">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="isGeneratingReport"
            />
          </div>
          <div class="col-md-4">
            <button
              @click.prevent="resetParams"
              class="btn btn-fill btn-outline-info float-right"
              data-dismiss="modal"
            >
              Reset Options
            </button>
            <button
              type="submit"
              class="btn btn-primary btn-space float-right"
              data-dismiss="modal"
            >
              Download Report
            </button>
          </div>
        </div>
      </card>
    </form>
  </div>
</template>

<script>

import API_DC from '../../api/dc'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {

  name: 'ManifestStatus',

  components: {

    PulseLoader

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Manifest Status Report')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
  },

  data () {
    return {

      todayDate: new Date().toJSON().slice(0, 10),

      finalUserPermissions: [],

      startDate: '',
      endDate: '',

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: '',

      color: 'green',
      size: '15px',
      loading: true,

      StartDateError: false,
      EndDateError: false,
      isGeneratingReport: false,
      StartAndEndDateError: false

    }
  },

  methods: {

    loadReport () {
      let startDate = ''
      let endDate = ''
      let params = ''

      if (this.startDate !== '') {
        startDate = '&startDate=' + this.startDate
      } else {
        startDate = ''
      }

      if (this.endDate !== '') {
        endDate = '&endDate=' + this.endDate
      } else {
        endDate = ''
      }

      if (endDate === '' && startDate === '') {
        params = ''
      } else {
        params = '?' + startDate + endDate
      }

      if (this.startDate === '' && this.endDate !== '') {
        this.StartDateError = true
      } else if (this.startDate !== '' && this.endDate === '') {
        this.EndDateError = true
      } else if (this.startDate === '' && this.endDate === '') {
        this.StartAndEndDateError = true
      } else {
        this.isGeneratingReport = true

        API_DC.generateManifestStatusReport(params).then(
          response => {
            if (response.message === 'No content found' || response.status === 204) {
              this.ErrorMessage = ', No report found on requested options'
              this.SuccessAlert = false
              this.ErrorAlert = true
              this.SuccessMessage = ''
              this.isGeneratingReport = false
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', 'MANIFEST-STATUS-REPORT.xlsx')
              document.body.appendChild(link)
              link.click()
              this.isGeneratingReport = false
              this.SuccessAlert = false
              this.ErrorAlert = false
              this.SuccessMessage = ''
              this.ErrorMessage = ''
              this.startDate = ''
              this.endDate = ''
            }
          }
        ).catch(() => {

        })
      }
    },

    resetParams () {
      this.startDate = ''
      this.endDate = ''
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }

  }

}
</script>

<style scoped>
  .btn-space {
    margin-right: 10px;
  }
</style>
