<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12">
          <button
            type="submit"
            class="btn btn-primary btn-space mr-2 custom-btn float-right"
            @click.prevent="openModal"
          > Add Vessel Call Notifications
          </button>
        </div>
      </div>
      <hr />
      <data-table
        :table-headers="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :myLoader="myLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :total-elements="totalElements"
        :total-pages="totalPages"
        :is-first="isFirst"
        :is-last="isLast"
        :page-number="pageNumber"
        :no-data-found="noDataFound"
        :has-manage-button="true"
        :has-custom-button="true"
        custom-button-name="Edit"
        :loading-title="'Please wait while the system is loading vessel call notifications'"
        :permissions="[ {key: 'searchPermission', value: 'VESSEL_SEARCH_GET'} ]"
        :whichModuleAndType="whichModuleAndType"
        @fireCustomMethod="editVessel"
        @resetParams="resetParams"
        @search="searchGeneralChargeParams"
        @goToApplication="goToApplication"
        @goToPage="goToPage"
        @onChange="onChange"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading maritime vessels, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <div>
      <create-vessel-call :close-modal="closeModal" v-if="showModal" :edit="edit" ref="editVessels" :reload="loadRequest"></create-vessel-call>
    </div>

  </div>
</template>
<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapActions, mapGetters } from 'vuex'
import { ModelListSelect } from 'vue-search-select'
import Modal from '@/components/Inputs/Modal'
import createVesselCall from "./createVesselCall"
import GlobalModal from '../../../components/globalLoader'

export default {

  name: 'VesselList',

  components: {

    PulseLoader,
    Modal,
    ModelListSelect,
    GlobalModal,
    createVesselCall,
    DataTable: () => import('../../../components/DataTable/dataTable')

  },

  created () {

    this.$store.dispatch('pagenames/currentPageName', 'Maritime Vessel Call Notifications');
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadRequest();

  },

  computed:{

    ...mapGetters('vesselParticulars', [

      'getRequestList',
      'getSaveDetails',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getMyLoader',
      'getHasSearchedVariable',
      'getPageNo',
      'getTotalElement',
      'getTotalPage',
      'getPageNumber',
      'getIsFirst',
      'getIsLast',
      'getPageSize'

    ]),

    getIsPage: {

      get: function () {

        return this.isPage

      },

      set: function (newValue) {

        this.isPage = newValue;

      }

    }

  },

  data () {

    return {

      newParams: '',
      whichModuleAndType: 'vesselParticular',
      initialParams: '?size=' + 15 + '&page=' + 0,
      isPage: 0,
      pageNoPart: 0,
      color: 'green',
      size: '13px',
      requests: [],
      filteredRequests: [],
      finalUserPermissions: [],
      hasSearchedVariable: false,
      noDataFound: false,
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      totalElements: '',
      dateCategory:"",
      totalPages: '',
      isFirst: false,
      isLast: false,
      pageNumber: '',
      myFirstLoader: false,
      loading:true,
      tableHeaders: ['IMO','Vessel Name', 'Voyage Number', 'Vessel Status','Vessel Type','Expected Time Of Arrival (ETA)', 'Operation', 'Stay Time (Hrs)'],
      tableDataKeys: ['imo','vesselName', 'voyageNo', 'status','vesselType', 'estimatedTimeArrival', 'operation', 'stayTime'],
      color: 'green',
      size: '12px',
      myLoader: false,
      edit:false,
      showModal: false,
      params:""

    }

  },

  methods: {

    ...mapActions('vesselParticulars', [

      'loadSearchedVesselParticulars',
      'loadVesselParticularsPageableOnCreated',
      'loadVesselParticularsPageableAfterCreated',
      'getVesselParticularByVoyageNo',
      'savingDetails',
      'addVesselParticulars'

    ]),

    loadRequest() {

      if (localStorage.getItem("search-params") !== null && localStorage.getItem("search-params") !== '') {

        this.newParams = "";
        this.newParams = localStorage.getItem("search-params");

        let secondPart = this.newParams.split("size=")[1];
        this.pageNoPart = parseInt(secondPart.split("page=")[1]);
        this.isPage = this.pageNoPart + 1;

        this.searchGeneralChargeParams(this.newParams, this.pageNoPart)

      } else {

          this.loadPageableRequestOnCreated(this.initialParams , 0);

          this.$store.dispatch("vesselParticulars/hasSearchngParams", false);
          this.newParams = "";
          this.pageNoPart = 0

      }

      this.filteredRequests = []

    },

    sortingData(data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x],
          estimatedTimeArrival:this.dateTimeToHuman(data[x].eta),
          vesselType: this.underscoreToSpaceString(data[x].vesselType),
          operation: data[x].operation.toUpperCase()

        };

        this.filteredRequests.push(requestObject)

      }

    },

    resetParams () {

      this.loadPageableRequestOnCreated(this.initialParams , 0);
      this.$store.dispatch("vesselParticulars/hasSearchngParams", false);
      this.newParams = "";
      this.pageNoPart = 0;
      this.isPage = this.pageNoPart + 1;
      this.filteredRequests = []

    },

    setAllGetters(flag) {

      this.requests = this.getRequestList;
      flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
      flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
      this.noDataFound = this.getNoDataFound;
      this.SuccessMessage = this.getSuccessMessage;
      this.ErrorMessage = this.getErrorMessage;
      this.ErrorAlert = this.getErrorAlert;
      this.SuccessAlert = this.getSuccessAlert;
      this.pageNo = this.getPageNo;
      this.pageSize = this.getPageSize;

      this.totalElements = this.getTotalElement;
      this.totalPages = this.getTotalPage;
      this.pageNumber = this.getPageNumber;
      this.isFirst = this.getIsFirst;
      this.isLast = this.getIsLast;

      this.sortingData(this.requests)

    },

    loadSaveDetailsParameters () {

      return new Promise((resolve) => {

        this.$store.dispatch('vesselParticulars/savingDetails', this.saveDetails);

        resolve()
      })
    },

    loadSearchParameters(params, fromApplicationPageNo) {

      let fromApplicationPageNoOrNot;
      typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0;

      let firstPart = params.split("&page=")[0];
      let secondPart = firstPart.split("size=")[0];
      this.newParams = "";

      this.isPage = fromApplicationPageNo;
      this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + fromApplicationPageNoOrNot;

      return new Promise((resolve) => {

        this.$store.dispatch("vesselParticulars/pageSizing", this.pageSize);
        this.$store.dispatch("vesselParticulars/pageNumbering", 0);
        this.$store.dispatch("vesselParticulars/paramsSearching", params);

        resolve();

      })

    },

    searchGeneralChargeParams(params, fromApplicationPageNo) {

      this.newParams = params;

      this.filteredRequests = [];

      this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.loadSearchedVesselParticulars({}).then(() => {

          this.setAllGetters('onCreated')

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

      }).catch(() => {


      });

    },

    loadPageableRequestOnCreated(params, fromApplicationPageNo) {

      this.newParams = params;

      this.filteredRequests = [];

      this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.loadVesselParticularsPageableOnCreated({}).then(() => {

          this.setAllGetters('onCreated')

        }).catch(() => {


        });

        this.myFirstLoader = this.getMyFirstLoader;

      }).catch(() => {


      });

    },

    loadPageableRequestsAfterCreated(params, fromApplicationPageNo) {

      this.newParams = params;

      this.filteredRequests = [];

      this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

        this.loadVesselParticularsPageableAfterCreated({}).then(() => {

          this.setAllGetters('afterCreated')

        }).catch(() => {


        });

        this.myLoader = this.getMyLoader;

      }).catch(() => {


      });

    },

    goToApplication(object) {

      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict';
      this.$router.push('/shipping-agency/vessel-operations/' + object.vesselParticularId);

      if (this.getHasSearchedVariable) {

        localStorage.setItem("search-params", this.newParams);
        // localStorage.setItem("cfa-customer", JSON.stringify(this.allCustomers));
        this.newParams = ""

      } else {

        localStorage.setItem("page-no", this.getPageNo);
        localStorage.setItem("page-size", this.getPageSize);
        localStorage.setItem("search-params", '?size=' + this.getPageSize + '&page=' + this.getPageNo);

      }

    },

    onChange(event) {

      this.$store.dispatch("vesselParticulars/pageSizing", event);
      this.$store.dispatch("vesselParticulars/pageNumbering", 0);
      localStorage.removeItem("page-no");
      localStorage.removeItem("page-size");
      this.isPage = 1;

      this.pageSize = this.getPageSize;
      this.pageNo = this.getPageNo;

      if (this.getHasSearchedVariable) {

        let firstPart = this.newParams.split("&page=")[0];
        let secondPart = firstPart.split("size=")[0];
        let thirdPart = firstPart.split("size=")[1];
        this.newParams = "";
        this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + 0;
        this.pageNoPart = 0;

        this.searchGeneralChargeParams(this.newParams , this.pageNoPart);
        this.filteredRequests = []

      } else {

        this.newParams = '?size=' + this.pageSize + '&page=' + this.pageNo;
        this.pageNoPart = this.isPage;

        this.loadPageableRequestsAfterCreated(this.newParams , 0);
        this.filteredRequests = []

      }

    },

    goToPage(page) {

      this.filteredRequests = [];

      if(page > 0) {page = page - 1;}
      this.isPage = page + 1;

      this.pageNo = page;

      if (this.getHasSearchedVariable) {

        let firstPart = this.newParams.split("page=")[0];
        this.newParams = "";
        this.newParams = firstPart + '&page=' + this.pageNo;
        this.pageNoPart = this.isPage;

        this.searchGeneralChargeParams(this.newParams, this.pageNoPart);
        this.filteredRequests = []

      } else {

        this.newParams = '?size=' + this.pageSize + '&page=' + this.pageNo;
        this.loadPageableRequestsAfterCreated(this.newParams, this.pageNoPart);
        this.filteredRequests = []
      }

    },



    showForm(){

      return new Promise((resolve) => {

        this.showModal = true;
        this.edit = true;

        resolve();
      });
    },

    editVessel(data){

      this.showForm(data).then(() => {

        this.$refs.editVessels.editVessel( data);

      }).catch(() => {


      });

    },

    openModal () {

      this.showModal = true;

    },

    closeModal () {

      this.showModal = false;
      this.edit = false;

    }

  }

}
</script>
<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-top: 1px solid #DCDCDC;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: none !important;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: auto;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-main-details, .is-active-main-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-additional-details, .is-active-additional-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto;
  }

  .medium-modal-dialog {
    /*width: calc(80vw);*/
    /*min-width: calc(60vw);*/
    max-width: calc(90vw) !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
  }

  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  .back-color-vat {
    background-color: rgba(216, 225, 232, .3);
  }

  .custom-btn, .custom-btn-all {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .custom-btn:hover, .custom-btn-all:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

</style>
