<template>
  <div class="row">
    <div class="col-12 text-center">
      <pulse-loader
        :loading="loading"
        :color="color"
        :size="size"
        v-if="loading"
      />
    </div>
    <div class="col-md-12">
      <table
        class="table table-striped"
        v-if="table === 'showTable'"
      >
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Shift Name
            </th>
            <th scope="col">
              Start Date
            </th>
            <th scope="col">
              End Date
            </th>
            <th scope="col">
              Active
            </th>
            <th scope="col">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(shift,index) in allShifts.shifts.data"
            v-if="type==='configuration'"
          >
            <td>{{ 1 + index }}</td>
            <td>{{ shift.name }}</td>
            <td>{{ shift.startTime }}</td>
            <td>{{ shift.endTime }}</td>
            <td>{{ shift.active? "ACTIVE":"NOT ACTIVE" }}</td>
            <td>
              <span
                class="pointer edit-name"
                @click="clickedShift(shift)"
              >Edit</span>
              <span
                class="pointer delete-name"
                @click="deleteShift(shift.id)"
              >Delete</span>
            </td>
          </tr>
        </tbody>
      </table>
      <select
        class="custom-select w-100"
        v-if="type === 'shiftClerk'"
        v-model="selectedShift"
      >
        <option selected />
        <option
          v-for="(shift,index) in allShifts.shifts.data"
          :value="shift"
        >
          {{ shift.name }} -  ( {{ shift.startTime }} - {{shift.endTime}} )
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: 'Shifts',

  props: ['type', 'table', 'callback'],

  components: {
    PulseLoader
  },
  computed: {
    allShifts () {
      return this.$store.state.shifts
    }
  },

  created () {
    this.loading = true
    this.$store.dispatch('shifts/getShifts').then(() => {
      this.loading = false
    })

    this.$parent.$on('clearSelectedShip', () => {
      this.selectedShift = ''
    })
  },
  data () {
    return {
      loading: false,
      color: 'green',
      size: '15px',
      selectedShift: ''
    }
  },

  watch: {

    selectedShift: function () {
      this.$emit('selectedShiftEvent', this.selectedShift)
    }
  },

  methods: {

    clickedShift (shift) {
      this.selectedShift = shift
      this.$emit('selectedShiftEvent', this.selectedShift)
    },

    deleteShift (shiftId) {
      this.loading = true
      this.$store.dispatch('shifts/deleteShift', shiftId).then(() => {
        this.loading = false
      })
    }

  }
}
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }

    .edit-name {
        color: green;
    }

    .delete-name {
        color: red;
    }
</style>
