<template>
  <div class="full-screen">
    <div class="container text-muted">
      <div class="row">
        <div class="col-md-12 text-center">
          <h1 class="title">
            Tanzania Shipping Agency Corporation
          </h1>
          <h2 class="title text-danger">
            404 Not Found
          </h2>
          <h2 class="title">
            Oops! It seems that this page does not exist, Please contact system administrator
          </h2>
        </div>
        <div class="col-md-12 text-center">
          <font-awesome-icon
            class="ban-icon"
            icon="exclamation-triangle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}

</script>
<style scoped>

  .full-screen {

    height: 500px;

  }

  .ban-icon {
    font-size: 450px;
    color: #DCDCDC;

  }

</style>
