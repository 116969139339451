<template>
  <card>
    <div class="row">
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table
          class="table table-striped" v-if="table==='showTable'">
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                Resource Name
              </th>
              <th scope="col">
                Serial Number
              </th>
              <th scope="col">
                May Shift Ship Before Completion
              </th>
              <th scope="col">
                Shared By Clerks
              </th>
              <th scope="col">
                Used In
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(resource,index1) in allResources" v-if="type === 'configuration'"  :key="index1">
              <td>{{ index1 + 1 }}</td>
              <td>{{ resource.name }}</td>
              <td>{{ resource.serial }}</td>
              <td>{{ resource.usedIn }}</td>
              <td>{{ resource.sharedByClerks }}</td>
              <td>{{ resource.active ? "ACTIVE" : "NOT ACTIVE" }}</td>
              <td>{{ resource.mayShiftShipBeforeCompletion }}</td>
              <td class="pointer" @click="editResourse(resource.id)">
                <font-awesome-icon
                  icon="edit"
                  color="#fca130"
                />Edit
              </td>
              <td
                class="pointer"
                @click="deleteResource(resource.id)"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  color="red"
                />Delete
              </td>
            </tr>
          </tbody>
        </table>
        <div class="form-group" v-if="type === 'showSelect'">
          <label for="resource">Select Resource</label>
          <select
            class="form-control"
            id="resource"
            v-model="resource"
          >
            <option />
            <option
              v-for="(resource , index) in allResources" :value="resource.id" :key="index">
              {{ resource.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'

export default {
  name: 'ResourceView',

  props: ['type', 'table', 'select'],

  components: {
    PulseLoader
  },
  computed: {
    allResources () {
      return this.$store.state.resources.resources
    }
  },

  created () {
    this.loading = true
    this.$store.dispatch('resources/getResources').then(() => {
      this.loading = false
    })
  },

  data () {

    return {

      loading: false,
      color: 'green',
      size: '15px',
      resource: ''
    }

  },

  methods: {

    editResourse(resourceId){

      this.$router.push({name:'resourceEdit',params:{"resourceId":resourceId}})
    },

    deleteResource (resourceId) {
      this.loading = true;
      this.$store.dispatch('resources/deleteResource', resourceId).then(() => {
        this.loading = false
      })
    }
  },

  watch: {

    resource: function () {

      this.$emit('selectedResource', this.resource)

    }

  }

}
</script>

<style scoped>
.pointer{
    cursor: pointer;
}
</style>
