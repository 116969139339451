<template>
  <card>
    <div class="col-md-12">
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
      <div class="col-md-12 text-center">
        <h5>Clerks Assigned</h5>
      </div>
      <div
        class="legend mt-5"
        v-for="(clerk,index) in clerks"
      >
        <h6 class="legend-title">
          <span class="index">{{ index + 1 }}</span>
          Clerk Details
        </h6>
        <div class="row div-table-row">
          <div class="col-md-3 div-table div-table-title">
            First Name
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ clerk.firstName }}
          </div>

          <div class="col-md-3 div-table div-table-title">
            Last Name
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ clerk.lastName }}
          </div>
        </div>

        <div class="row div-table-row mt-2">
          <div class="col-md-4 div-table div-table-title ">
            Email
          </div>
          <div class="col-md-3 div-table div-table-value">
            {{ clerk.email }}
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import TALLYAPI from '../../../api/tally'

export default {
  name: 'ClerkAsignedView',
  created () {
    this.getClerks()
  },
  data () {
    return {
      clerks: [],

      loading: false,
      color: 'green',
      size: '15px'
    }
  },
  methods: {

    getClerks () {
      const paramObject = {
        day: this.dateToHuman(this.$route.params.day),
        notificationId: this.$route.params.notificationId,
        shiftId: this.$route.params.shiftId
      }
      this.loading = true
      TALLYAPI.getClerksOnShiftOnDay(paramObject).then((response) => {
        this.loading = false
        this.clerks = response.data.data
      })
    },

    dateToHuman (stringDate) {
      if (stringDate !== null || stringDate !== undefined) {
        const splittedDate = stringDate.split('T')
        return splittedDate[0]
      }
    }
  }
}
</script>

<style scoped>
    .index{
        color: black;
        border-radius: 50%;
        padding: 5px;
    }

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .div-table-value{
        border: 1px solid #DCDCDC;
    }
    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

</style>
