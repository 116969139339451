<template>
  <div>
    <sidebar-link
      :to="{ name: 'Overview' }"
      v-if="finalUserPermissions.includes('DASHBOARD_VIEW')"
      title="High Level Dashboard"
    >
      <svg-icon
        class="nc-icon"
        icon="icon-home"
        :has-fill="true"
      />
      <p>DASHBOARD</p>
    </sidebar-link>

    <sidebar-link
      :to="{ name: 'OverviewCustomer' }"
      v-if="finalUserPermissions.includes('CUSTOMER_DASHBOARD_VIEW')"
      title="Customer Dashboard"
    >
      <svg-icon
        class="nc-icon"
        icon="icon-home"
        :has-fill="true"
      /> <p>DASHBOARD</p>
    </sidebar-link>

    <sidebar-link
      :to="{ name: 'OverviewCfa' }"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
      title="Clearing and Forwarding Dashboard"
    >
      <svg-icon
        class="nc-icon"
        icon="icon-home"
        :has-fill="true"
      /> <p>DASHBOARD</p>
    </sidebar-link>

    <sidebar-link
      :to="{ name: 'OverviewDc' }"
      v-if="finalUserPermissions.includes('DC_DASHBOARD_VIEW')"
      title="Document Control Dashboard"
    >
      <svg-icon
        class="nc-icon"
        icon="icon-home"
        :has-fill="true"
      /> <p>DASHBOARD</p>
    </sidebar-link>

    <sidebar-link
      :to="{ name: 'OverviewTally' }"
      v-if="finalUserPermissions.includes('ST_DASHBOARD_VIEW')"
      title="Ship Cargo Tallying Dashboard"
    >
      <svg-icon
        class="nc-icon"
        icon="icon-home"
        :has-fill="true"
      /> <p>DASHBOARD</p>
    </sidebar-link>
  </div>
</template>

<script>

import SvgIcon from '../../components/SvgLoader/svgLoader'

export default {

  name: 'Dashboard',

  data () {
    return {

      finalUserPermissions: []

    }
  },

  components: {
    SvgIcon
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
  }

}
</script>

<style scoped>

    .menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 12px !important;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        font-weight: normal !important;
    }

    .sidebar .nav .nav-link:hover .nc-icon, body > .navbar-collapse .nav .nav-link:hover  .nc-icon {
        fill: #d59a18;
    }

    .nc-icon {
        position: relative;
        top: 8px;
        left: -5px;
        font-size: 25px !important;
        fill: #FFFFFF;
        padding-right: 8px;
    }

</style>
