<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12 text-right">
          <button
            @click.prevent="addNotification = !addNotification"
            v-if="finalUserPermissions.includes('COMPANY_ALL_GET') && finalUserPermissions.includes('API_TALLY-NOTIFICATIONS_POST')"
            class="btn btn-success mr-2 custom-btn">
            Add Tally Notification
          </button>
        </div>
      </div>
      <data-table
        :has-manage-button="true"
        :is-first="isFirst"
        :is-last="isLast"
        :hasFilter="false"
        :loading-title="'Please wait while system is loading Tally Notifications'"
        :permissions="[ {key: 'searchPermission', value: 'API_TALLY-NOTIFICATIONS_POST'} ]"
        :my-first-loader="loading"
        :no-data-found="noDataFound"
        :page-no="pageNo"
        :page-number="pageNumber"
        :page-size-prop="pageSize"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :table-headers="tableHeaders"
        :total-elements="totalElements"
        :total-pages="totalPages"
        @goToApplication="selectNotification"
        @goToPage="goToPage"
        @onChange="onChange"
      >
        <div slot="pagination">
          <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
          </paginate>
        </div>
      </data-table>
    </card>
    <modal v-if="addNotification">
      <div class="modal-title col-md-12" slot="header">
        <div class="row">
          <div class="col-md-12">
            <b> Add Tally Notification</b>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <div class="col-md-12">
          <form>

            <!--            STUFFING AND DISTUFFING FIELDS-->
            <div class="row" v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
              <div class="col-md-6">
                <!--            STUFFING AND DISTUFFING-->
                <div
                  class="form-group"
                  v-if=" tallyType !== 'DISCHARGING' &&  tallyType !== 'LOADING' &&  tallyType !== '' ">
                  <label class="notification-title" for="containerNo">Container Number</label>
                  <input
                    class="form-control"
                    id="containerNo"
                    type="text"
                    v-model="containerNo"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <!--            STUFFING AND DISTUFFING-->
                <div
                  class="form-group"
                  v-if="
                  tallyType !== 'DISCHARGING' &&
                  tallyType !== 'LOADING' &&
                  tallyType !== ''
              "
                >
                  <label class="notification-title" for="masterBl">Master BL</label>
                  <input
                    class="form-control"
                    id="masterBl"
                    type="text"
                    v-model="masterBl"
                  />
                </div>
              </div>
            </div>

            <!--            LOADING AND DISCHARGE vs stuffing and distuffing-->
            <!--<div class="row" v-if="tallyType !== ''">-->
                <!--&lt;!&ndash;            STUFFING AND DISTUFFING&ndash;&gt;-->
                <!--<div class="form-group" v-if="-->
                  <!--tallyType !== 'DISCHARGING' &&-->
                  <!--tallyType !== 'LOADING' &&-->
                  <!--tallyType !== 'DESTUFFING'">-->
                  <!--<label class="notification-title" for="shippingOrderNo">Shipping Order Number</label>-->
                  <!--<input-->
                    <!--class="form-control"-->
                    <!--id="shippingOrderNo"-->
                    <!--type="text"-->
                    <!--v-model="shippingOrderNo"/>-->
                <!--</div>-->
              <!---->
              <!--<div class="col-md-6">-->
                <!--<div class="form-group">-->
                  <!--<label class="notification-title" for="quantity">No of Containers</label>-->
                  <!--<input-->
                    <!--class="form-control"-->
                    <!--id="quantity"-->
                    <!--type="number"-->
                    <!--v-model="quantity"-->
                  <!--/>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->

            <!--<div class="row" v-if="tallyType !== ''">-->
              <!--<div class="col-md-6" v-if="tallyType !== 'STUFFING' && tallyType !== 'DESTUFFING'">-->
                <!--<div class="form-group">-->
                  <!--<label class="notification-title" for="cargoQuantity">Cargo Quantity</label>-->
                  <!--<input-->
                    <!--class="form-control"-->
                    <!--id="cargoQuantity"-->
                    <!--type="number"-->
                    <!--v-model="cargoQuantity"-->
                  <!--/>-->
                <!--</div>-->
              <!--</div>-->

              <!--<div class="col-md-6" v-if="tallyType !== 'STUFFING' && tallyType !== 'DESTUFFING'">-->
                <!--<div class="form-group">-->
                  <!--<label class="notification-title" for="weight">Weight (MT)</label>-->
                  <!--<input-->
                    <!--class="form-control"-->
                    <!--id="weight"-->
                    <!--type="text"-->
                    <!--v-model="weight"-->
                  <!--/>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->

            <div class="row" v-if="tallyType !== ''">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="notification-title">Shipping Agent</label>
                  <model-list-select :custom-text="companyName"
                    :list="companies"
                    option-text="Company"
                    option-value="companyId"
                    placeholder="select Company"
                    v-model="selectedCompany">
                  </model-list-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="notification-title" for="locationName">Location Name</label>
                  <input
                    class="form-control"
                    id="locationName"
                    type="text"
                    v-model="locationName"/>
                  <div class="error" v-if="locationNameError">
                    <span class="text-danger" v-if="!$v.locationName.required">This Field is Required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group" v-if="tallyType !== ''">
                  <label class="notification-title">Location Type</label>
                  <select class="form-control" v-model="locationType">
                    <option value="ICD">
                      ICD
                    </option>
                    <option value="TERMINAL">
                      TERMINAL
                    </option>
                    <option value="DEPOT">
                      DEPOT
                    </option>
                  </select>
                  <div class="error" v-if="locationTypeError">
                    <span class="text-danger" v-if="!$v.locationType.required">This Field is Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" v-if="tallyType !== ''">
                  <label for="vesselType" class="notification-title">Vessel Type</label>
                  <select class="form-control" v-model="cargoType" id="vesselType">
                    <option value="Container">
                      Container
                    </option>
                    <option value="Vehicle">
                      Vehicle
                    </option>
                    <option value="Dry Bulk">
                      Dry Bulk
                    </option>
                    <option value="Liquid Bulk">
                      Liquid Bulk
                    </option>
                    <option value="Break Bulk">
                      Break Bulk
                    </option>
                    <option value="General Cargo">
                      General Cargo
                    </option>
                  </select>
                  <div class="error" v-if="cargoTypeError">
                    <span class="text-danger" v-if="!$v.cargoType.required">This Field is Required</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6" v-if="tallyType !== 'STUFFING' && tallyType !== 'DESTUFFING'">
                <div class="form-group" v-if="tallyType !== ''">
                  <label class="notification-title" for="vesselName">Vessel Name</label>
                  <input
                    class="form-control"
                    id="vesselName"
                    type="text"
                    v-model="vesselName"/>
                </div>
              </div>
              <div class="col-md-6" v-if="tallyType !== 'STUFFING' && tallyType !== 'DESTUFFING'">
                <div class="form-group" v-if="tallyType !== ''">
                  <label class="notification-title" for="voyage">Voyage</label>
                  <input
                    class="form-control"
                    id="voyage"
                    type="text"
                    v-model="voyage"/>
                </div>
              </div>
            </div>

            <div class="row" v-if="tallyType !== ''">
              
              <div class="col-md-6">
                <!-- <base-input
                  class="notification-title"
                  label="Start Date"
                  type="datetime-local"
                  v-model="startTime"/> -->
                <label class="custom-font">Start Time</label>
                <date-picker  style="width:100% !important;" v-model="startTime" @change="setFormattedStartDate" :disabled-date="validateStartDate" type="datetime"></date-picker>

                <div class="error" v-if="startTimeError">
                  <span class="error text-danger" v-if="!$v.startTime.required">This field is required</span>
                </div>
              </div>
              <div class="col-md-6">

                <label class="custom-font">End Time</label>
                <date-picker  style="width:100% !important;" v-model="endTime" @change="setFormattedEndDate" :disabled-date="validateEndDate" type="datetime"></date-picker>
                <!-- <base-input
                  class="notification-title"
                  :min="startTime"
                  label="End Date"
                  type="datetime-local"
                  v-model="endTime"/> -->
              </div>
            </div>

            <hr/>
            <div class="row">
              <div class="col-md-12 text-right">
                <button @click.prevent="createNotification" class="btn btn-primary mr-2 custom-btn">Submit</button>
                <button @click.prevent="addNotification = !addNotification" class="btn-outline-info btn btn-fill mr-2 custom-btn">
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </modal>

  </div>
</template>

<script>
  import TALLYAPI from "../../../api/tally";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import Modal from "../../../components/Inputs/Modal"
  import Table from "../../../components/DataTable/table";
  import {ModelListSelect, MultiSelect} from "vue-search-select";
  import {required} from "vuelidate/lib/validators";
  import API from "../../../api";
  import Shifts from "../subComponents/shifts";
  import DataTable from '../../../components/DataTable/dataTable'
  import CrudNotification from '../../../components/DataTable/crudNotification'
  import {mapActions, mapGetters} from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';


  export default {
    name: "NotificationView",

    props: ["type", "table", "callback", "selectedTallyType"],

    validations: {
      selectedClerk: {required},
      active: {required},
      selectedShift: {required},
      startTime: {required},
      locationName: {required},
      locationType: {required},
      cargoType: {required}
    },

    components: {
      Table,
      PulseLoader,
      Modal,
      ModelListSelect,
      MultiSelect,
      CrudNotification,
      DataTable,
      Shifts,
      DatePicker
    },

    created() {

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.operationType = this.selectedTallyType;
      this.tallyType = this.selectedTallyType;
      this.$store.dispatch('pagenames/currentPageName', 'Ship Tallying Notifications');
      this.getNotifications();
      this.getCompanies();


      // this.getUsers();
      // this.getAllResources();
    },

    mounted() {

    },

    computed: {

      ...mapGetters("shippingtallying", [
        "getRequestList",
        "getPageCount",
        "getMyFirstLoader",
        "getNoDataFound",
        "getSuccessAlert",
        "getErrorMessage",
        "getErrorAlert",
        "getSuccessMessage",
        "getMyLoader",
        "getPageNo",
        "getPageSize",
        "getTotalPage",
        "getTotalElement",
        "getPageNumber"
      ]),

      getIsPage: {

        get: function () {
          return this.isPage
        },
        set: function (newValue) {
          this.isPage = newValue;
        }

      }
    },

    data() {

      return {

        isPage: 0,
        finalUserPermissions: [],
        filteredRequests: [],
        companies: [],
        quantity: null,
        numberOfGangs: null,
        selectedCompany: "",
        tallyType: "",
        vesselCallId: "",
        voyage: "",
        callId: null,
        masterBl: "",
        shippingOrderNo: "",
        houseBl: "",
        containerNo: "",
        cargoType: "",
        cargoTypeError: false,
        startTime: "",
        startTimeError: false,
        endTime: "",
        endTimeError: false,
        locationType: "",
        locationTypeError: false,
        locationName: "",
        locationNameError: false,
        berth: "",
        weight: "",
        vesselName: null,
        berthError: false,
        completed: false,
        completedError: false,


        selectedNotification: {},
        showSelectedNotification: false,
        showAssignments: false,
        notifications: [],
        items: [],
        operationType: "",
        addNotification: false,
        resources: [],
        itemsResources: [],
        lastSelectItemResource: {},

        loading: false,
        color: 'green',
        size: '15px',
        operation: {
          id: "",
          shift: {
            id: "",
            clerks: []
          }
        },
        assignments: [],
        ErrorMessage: "",
        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: "",
        assignmentsError: false,


        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        sortBy: 'createdAt',
        totalPages: 1,
        isPageable: true,
        pageNumber: 0,
        isFirst: true,
        isLast: false,
        nodataFound: false,
        myFirstLoader: false,
        totalElements: '',
        noDataFound: false,
        tableHeaders: ['Operation Type', 'Voyage Number', 'Cargo Type', 'Start Time', 'End Time', 'Shipping Order Number'],
        tableDataKeys: ['operationType', 'voyage', 'cargoType', 'startTime', 'endTime', 'shippingOrderNo'],


        allUsers: [],
        selectedClerk: "",
        active: true,
        activeError: false,
        StartDateError: false,
        endDateError: false,
        selectedShift: "",
        operationShiftId: null,

        viewAssignment: true,
        newAssignment: false,

        startTimeFormatted: '',
        endTimeFormatted: '',
      }
    },

    methods: {

      ...mapActions("shippingtallying", [

        "getTallyNotification"

      ]),

      validateEndDate(date) {

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today || this.$moment(date).format("YYYY-MM-DD") < (this.startTimeFormatted + 1)

      },

      validateStartDate (date) {

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return date < today || date < this.endDate

      },

      setFormattedStartDate () {

        this.startTimeFormatted = this.$moment(this.startTime).format("YYYY-MM-DD")

      },

      setFormattedEndDate () {

        this.endTimeFormatted = this.$moment(this.endTime).format("YYYY-MM-DD")

      },

      onSelect(items, lastSelectItem) {

        this.items = items;
        this.lastSelectItem = lastSelectItem;

      },

      getAllResources() {

        TALLYAPI.getAllResources().then((response) => {

          response.data.data.map((data) => {

            this.resources.push({value: data.id, text: data.name});

          });

        });

      },

      onSelectResource(items, lastSelectItem) {
        this.itemsResources = items;
        this.lastSelectItemResource = lastSelectItem;
      },

      // approve() {
      //   this.loading = true;
      //   this.showSelectedNotification = false;
      //   TALLYAPI.approveAssignmentsByNotification(this.selectedNotification.id).then(() => {
      //     this.loading = false;
      //     this.ErrorMessage = '';
      //     this.SuccessAlert = true;
      //     this.ErrorAlert = false;
      //     this.SuccessMessage = 'Notification Approved';
      //     this.getNotifications = this.getNotifications();
      //   }).catch(() => {
      //     this.loading = false;
      //     this.loading = false;
      //     this.ErrorMessage = '';
      //     this.SuccessAlert = true;
      //     this.ErrorAlert = false;
      //     this.SuccessMessage = 'Notification Failed to Approve';
      //   })
      // },

      showAssignmentDetails() {
        this.showAssignments = true;
        this.showSelectedNotification = !this.showSelectedNotification;
      },

      closeModal(notification = {}) {
        this.showSelectedNotification = !this.showSelectedNotification;
      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
        } else {
          this.ErrorAlert = !this.ErrorAlert;
        }
      },

      enter: function (el, done) {
        var that = this;

        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
        }, 3000); // hide the message after 3 seconds
      },

      // assignClerk() {

      //   if (this.operationType !== "" && this.operationShiftId && this.operationShiftId !== "" && this.startTime !== ""
      //     && this.tallyDeStaffingNotificationId !== "") {
      //     this.loading = true;
      //     this.assignmentsError = false;

      //     const clerkIds = [];
      //     const resourceIds = [];


      //     this.items.map((data) => {
      //       clerkIds.push(data.value);
      //     });

      //     this.itemsResources.map((data) => {
      //       resourceIds.push(data.value);
      //     });

      //     if (
      //       this.active !== "" &&
      //       this.clerkId !== "" &&
      //       this.endTime !== "" &&
      //       this.startTime !== "" &&
      //       this.operationShiftId !== "" &&
      //       this.operationType !== ""

      //     ) {

      //     } else {

      //     }

      //     TALLYAPI.assignSingleClerk({
      //       clerkIds: clerkIds,
      //       operationShiftId: this.operationShiftId,
      //       resourceIds: resourceIds,
      //       operationType: this.operationType,
      //       startTime: this.startTime + "T00:00:00.000Z",
      //       tallyDeStaffingNotificationId: this.selectedNotification.id,

      //     }).then((response) => {
      //       this.loading = false;
      //       this.getAssignMentByNotification();

      //       this.operationShiftId = "";
      //       this.startTime = "";
      //       this.items = [];
      //       this.itemsResources = [];

      //       this.ErrorMessage = '';
      //       this.SuccessAlert = true;
      //       this.ErrorAlert = false;
      //       this.SuccessMessage = 'Clerk Assigned';
      //     }).catch(() => {
      //       this.loading = false;
      //       this.ErrorMessage = 'Clerk Assignment Failed';
      //       this.SuccessAlert = false;
      //       this.ErrorAlert = true;
      //       this.SuccessMessage = '';
      //     })
      //   } else {

      //     this.assignmentsError = true;
      //   }

      // },


      closeNewAssignments() {

        this.viewAssignment = false;
        this.newAssignment = true;
      },

      closeViewAssignments() {
        this.viewAssignment = true;
        this.newAssignment = false;
      },

      onSelectedShift(data) {
        this.selectedShift = data;
        this.operationShiftId = data.id;
      },

      fullName(name) {
        return `${name.firstName} - ${name.lastName}`
      },

      getUsers() {
        this.loading = true;
        TALLYAPI.getShiftClerks().then((response) => {
          this.loading = false;
          response.data.data.map((data) => {
            this.allUsers.push({value: data.id, text: this.fullName(data)});
          });
        });
      },

      // deleteAssignment(assignmentId) {

      //   this.loading = true;
      //   this.getAssignMentByNotification();

      //   TALLYAPI.deleteAssignment(assignmentId).then((response) => {
      //     this.loading = false;

      //     this.ErrorMessage = "Assignment Deleted";
      //     this.SuccessAlert = true;
      //     this.ErrorAlert = false;
      //     this.SuccessMessage = "";
      //     this.getAssignMentByNotification();


      //   }).catch(() => {
      //     this.loading = false;
      //     this.ErrorMessage = "Failed To delete Assignment";
      //     this.SuccessAlert = false;
      //     this.ErrorAlert = true;
      //     this.SuccessMessage = "";
      //   })
      // },

      getAssignMentByNotification() {
        this.loading = true;

        TALLYAPI.getAssignMentById(this.selectedNotification.id).then((response) => {
          this.loading = false;
          this.assignments = response.data.data;
        }).catch(() => {
          this.ErrorMessage = "Failed To get Assignments";
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = "";
          this.loading = false;
        })
      },

      // goOperation(notificationId) {
      //   this.$router.push({name: 'clerkAssignment', params: {notificationId}})
      // },

      selectNotification(notification = {}) {

        document.cookie = "operation-type-shift-clerk-assignment=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
        this.selectedNotification = notification;
        let notificationId = this.selectedNotification.id;
        this.$router.push({
          name: 'tallyingNotificationViewDetails',
          params: {documentId: notificationId}
          
        })
      },

      getNotifications() {

        this.loadParameters().then(() => {

          this.getTallyNotification({}).then(() => {

            this.notifications = this.getRequestList;
            this.sortingData(this.notifications)
            this.loading = this.getMyFirstLoader;
            this.noDataFound = this.getNoDataFound;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;
            this.totalPages = this.getTotalPage;
            this.totalElements = this.getTotalElement;

          }).catch(() => {

          })
          this.loading = this.getMyFirstLoader;
        }).catch(() => {

        })
      },

      sortingData(data) {

          for (let x = 0; x < data.length; x++) {

            const requestObject = {
              ...data[x],
              startTime: this.dateTimeToHuman(data[x].startTime),
              endTime: this.dateTimeToHuman(data[x].endTime),
              bulk: data[x].bulk ? 'BULK' : 'SINGLE'
            }

            this.filteredRequests.push(requestObject)

          }

      },

      loadParameters() {
        return new Promise((resolve) => {
          this.$store.dispatch("shippingtallying/pageSizing", this.pageSize);
          this.$store.dispatch("shippingtallying/pageNumbering", this.pageNo);
          // this.$store.dispatch("shippingtallying/requestid", this.documentId);
          this.$store.dispatch("shippingtallying/operationTypeAction", this.operationType);
          resolve();
        })
      },

      onChange(event) {

        this.pageSize = event;
        this.pageNo = 0;
        this.isPage = 0
        this.getNotifications()

      },

      goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;
        this.getNotifications()

      },

      companyName(company) {
        return company.companyName;
      },

      getCompanies() {
        API.getCompany().then((response) => {
          this.companies = response.data.data;
        }).catch((error) => {

        })
      },

      createNotification() {

        let hoursS
        let minutesS
        let secondsS
        let hoursE
        let minutesE
        let secondsE

        let startTimeFormatted
        let endTimeFormatted

        if(this.startTime.getHours() <= 9) {hoursS = '0' + this.startTime.getHours()} else {hoursS = this.startTime.getHours()}
        if(this.startTime.getMinutes() <= 9) {minutesS = '0' + this.startTime.getMinutes()} else {minutesS = this.startTime.getMinutes()}
        if(this.startTime.getSeconds() <= 9) {secondsS = '0' + this.startTime.getSeconds()} else {secondsS = this.startTime.getSeconds()}

        if(this.endTime.getHours() <= 9) {hoursE = '0' + this.endTime.getHours()} else {hoursE = this.endTime.getHours()}
        if(this.endTime.getMinutes() <= 9) {minutesE = '0' + this.endTime.getMinutes()} else {minutesE = this.endTime.getMinutes()}
        if(this.endTime.getSeconds() <= 9) {secondsE = '0' + this.endTime.getSeconds()} else {secondsE = this.endTime.getSeconds()}

        startTimeFormatted = this.startTimeFormatted + 'T' + hoursS + ':' + minutesS + ':' + secondsS + '.000Z'
        endTimeFormatted = this.endTimeFormatted + 'T' + hoursE + ':' + minutesE + ':' + secondsE + '.000Z'

        if(this.$v.startTime.$invalid || this.$v.locationType.$invalid || this.$v.locationName.$invalid || this.$v.cargoType.$invalid) {

          if(this.$v.startTime.$invalid) {

            this.startTimeError = true

          }

          if(this.$v.locationType.$invalid) {

            this.locationTypeError = true

          }

          if(this.$v.locationName.$invalid) {

            this.locationNameError = true

          }

          if(this.$v.cargoType.$invalid) {

            this.cargoTypeError = true

          }

        } else {

          let object = {

            berth: this.berth === "" ? null : this.berth,
            vesselName: this.vesselName,
            // containerQuantity: this.quantity,
            quantity: this.declaredQuantity,
            // weight: this.weight,
            numberOfGangs: this.numberOfGangs,
            callId: this.callId === "" ? null : this.callId,
            cargoType: this.cargoType === "" ? null : this.cargoType,
            completed: this.completed,
            // cargoQuantity: this.cargoQuantity,
            companyId: this.selectedCompany,
            containerNo: this.containerNo === "" ? null : this.containerNo,
            documentId: this.documentId === "" ? null : this.documentId,
            endTime: endTimeFormatted,
            houseBl: this.houseBl === "" ? null : this.houseBl,
            // id: null,
            locationName: this.locationName === "" ? null : this.locationName,
            locationType: this.locationType === "" ? null : this.locationType,
            masterBl: this.masterBl === "" ? null : this.masterBl,
            shippingOrderNo: this.shippingOrderNo === "" ? null : this.shippingOrderNo,
            startTime: startTimeFormatted,
            vesselCallId: this.vesselCallId === "" ? null : this.vesselCallId,
            voyage: this.voyage === "" ? null : this.voyage,
            operationType: this.tallyType
            
          }

          TALLYAPI.createNotification(object)
            .then(() => {

              this.getNotifications();
              this.loading = false;
              this.vesselCallId = "";
              this.voyage = "";
              this.callId = "";
              this.locationType = "";
              this.locationName = "";
              this.masterBl = "";
              this.shippingOrderNo = "";
              this.houseBl = "";
              this.containerNo = "";
              this.startTime = "";
              this.endTime = "";
              this.berth = "";
              this.tallyType = "";
              this.addNotification = false

              this.loadNotification('success', 4000, '', 'Notification has been successfully created', 'response', 1000 , true  , true)
            })
            .catch(() => {

              this.loading = false;
              this.loadNotification('error', 4000, '', 'Notification not created', 'response', 1000 , true  , true)

            });

        }

      }
    }
  }
</script>

<style scoped>
  .btn {
    font-size: 0.9rem !important;
  }

  .index {
    color: black;
    border-radius: 50%;
    padding: 5px;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
  }

  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  /* .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: 120px;
    background-color: #FFFFFF !important;
  } */

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
}

</style>
<style>
.mx-datepicker-popup{
  z-index: 9999 !important;
}
</style>
