<template>
    <div >
        <!-- <div
        class="menu-title row"
        v-if="finalUserPermissions.includes('CFA_CUSTOMER')"
        @click="toggleMenu"
        title="System Users Configurations"
        >
        <div class="col-md-10">
            <svg-icon
            class="nc-icon"
            icon="user_management"
            :has-fill="true"
            @click.prevent="removeSearchParams"
            />
            <span class="menu-title-name">CUSTOMER PORTAL</span>
        </div>
        <div class="col-md-2">
            <svg-icon
            class="nc-icon-page"
            icon="down_arrow"
            :has-fill="true"
            v-show="openMenu"
            />
            <svg-icon
            class="nc-icon-page"
            icon="right_arrow"
            :has-fill="true"
            v-show="!openMenu"
            />
        </div>
        </div> -->

        <!-- <transition name="list"> -->
        <div class="menu-value" @click.prevent="removeSearchParams" v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH_REQUESTS_GET') && finalUserPermissions.includes('CFA_CUSTOMER')">
            <sidebar-link
                :to="{ name: 'NewApplication' }"
                v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH_REQUESTS_GET') && finalUserPermissions.includes('CFA_CUSTOMER')"
                >
                <svg-icon
                    class="nc-icon"
                    icon="opened-folder"
                    style="width:40px;height:20px;"
                    :has-fill="true"
                /><p>New application</p>
            </sidebar-link>
            <sidebar-link
            :to="{ name: 'MyApplicationList', params: { status: 'my'}}"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH_REQUESTS_GET') && finalUserPermissions.includes('CFA_CUSTOMER')"
            >
            <svg-icon
                class="nc-icon"
                icon="icon-folders"
                style="width:40px;height:20px;"
                :has-fill="true"
            /><p>My applications</p>
            </sidebar-link>
            <sidebar-link
            :to="{ name: 'MyBillList' }"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH_REQUESTS_GET') && finalUserPermissions.includes('CFA_CUSTOMER')"
            >
            <svg-icon
                class="nc-icon"
                icon="revenue"
                :has-fill="true"
            /><p>My Bills</p>
            </sidebar-link>
            <sidebar-link
            :to="{ name: 'cfaNwReprt' }"
            v-if="finalUserPermissions.includes('CFAREPORT_ASYNC_REPORT_GET') && finalUserPermissions.includes('CFA_CUSTOMER')"
            >
            <svg-icon
                class="nc-icon"
                icon="reports"
                :has-fill="true"
            /><p>My Report</p>
            </sidebar-link>
        </div>
        <!-- </transition> -->
        </div>
</template>
<script>

    import SvgIcon from '../../components/SvgLoader/svgLoader'

    export default {

    name: 'UserManagement',

    data () {
        
        return {

        finalUserPermissions: [],
        openMenu: false

        }
    },

    components: {

        SvgIcon

    },

    created () {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.openMenu = typeof (localStorage.getItem('user-side-menu')) ? JSON.parse(localStorage.getItem('user-side-menu')) : false

    },

    methods: {

        toggleMenu () {

        this.openMenu = !this.openMenu
        localStorage.setItem('user-side-menu', this.openMenu)

        },

        removeSearchParams () {

            localStorage.removeItem('search-params')
            localStorage.removeItem("page-no")
            localStorage.removeItem("page-size")
            localStorage.removeItem('search-params-rlist')
            localStorage.removeItem('search-params-slist')
            localStorage.removeItem('search-params-alist')
            localStorage.removeItem('search-params-tlist')

        }

    }

}
</script>

<style scoped>

.menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 12px !important;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        font-weight: normal !important;
    }

    .sidebar .nav .nav-link:hover .nc-icon, body > .navbar-collapse .nav .nav-link:hover  .nc-icon {
        fill: #d59a18;
    }

    .nc-icon {
        position: relative;
        top: 8px;
        left: -5px;
        font-size: 25px !important;
        fill: #FFFFFF;
        padding-right: 8px;
    }

</style>
