<template>
  <div>
    <card>
      <div class="col-md-12">
        <div class="legend">
          <h6 class="legend-title">
            Disbursement Category Details
          </h6>
          <div class="row div-table-row">
            <div class="col-md-3 div-table div-table-title">
              Category Code
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ categoryDetails.code }}
            </div>
            <div class="col-md-3 div-table div-table-title">
              Category Name
            </div>
            <div class="col-md-3 div-table div-table-value">
              {{ categoryDetails.name }}
            </div>
          </div>
          <br>
          <div class="row div-table-row">
            <div class="col-md-4 div-table div-table-title">
              Category Description
            </div>
            <div class="col-md-8 div-table div-table-value">
              {{ categoryDetails.description }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success mr-2 custom-btn"
            style="float:right; width: 180px"
            @click.prevent="openModal('ADD')"
          >
            <b>Add Disbursement Item</b>
          </button>
        </div>
      </div>
      <br>

      <card>
        <div class="legend">
          <h6 class="legend-title">
            Disbursement Items
          </h6>
          <div>
            <data-table
              :table-headers="tableHeaders"
              :table-data="requests"
              :table-data-keys="tableDataKeys"
              :page-size-prop="pageSize"
              :page-no="pageNo"
              :is-hovered="isHovered"
              :my-loader="myLoader"
              :page-count="pageCount"
              :has-filter="false"
              :isPageable="false"
              :hasPagination="false"
              :hasDeleteButton="true"
              :reload-all-request="reloadAllRequest"
              :my-first-loader="myFirstLoader"
              :no-data-found="noDataFound"
              :search-parameter-prop="searchParameter"
              :status="status"
              :hasEditButton="true"
              :loading-title="'Please wait while system is loading disbursement items'"
              :permissions="[ {key: 'searchPermission', value: 'SERVICE_CATEGORY_GET_{CATEGORYID}_GET'} ]"
              @goToEdit="editItemsDetails"
              @goToDelete="editItemsDetails"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              class="btn btn-default btn-fill btn-space custom-btn-all"
              style="float:right;"
              @click.prevent="goBack"
            > Back
            </button>
          </div>
        </div>
      </card>
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            <b> Edit Disbursement Item</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            <b> Register Disbursement Item</b>
          </div>

          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveFormDetails"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Disbursement Item Name"
                  placeholder="Disbursement Item Name"
                  v-model="disbursementItems.name"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementItemsNameError">
                  <div
                    class="error"
                    v-if="!$v.disbursementItems.name.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="number"
                  step="0.01"
                  label="Disbursement Item Rate"
                  placeholder="Disbursement Item Rate"
                  v-model="disbursementItems.rate"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementItemsRateError">
                  <div
                    class="error"
                    v-if="!$v.disbursementItems.rate.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label>Select VAT status {{disbursementItems.vatApplicable}}</label>
                <select class="form-control" v-model="disbursementItems.vatApplicable">
                    <option value="" selected>Select VAT status</option>
                    <option value="true">VATABLE</option>
                    <option value="false">NOT VATABLE</option>
                </select>
                
                <div v-if="disbursementItemsvatApplicableError">
                  <div
                    class="error"
                    v-if="!$v.vatApplicable.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Description</label>
                <textarea class="special-bottom form-control col-md-12" placeholder="Description" rows="5"
                type="textarea" v-model="disbursementItems.description" value="description"></textarea>
                <!-- <base-input
                  type="textarea"
                  label="Disbursement Item Description"
                  placeholder="Disbursement Item Description"
                  v-model="disbursementItems.description"
                  class="special-bottom"
                /> -->
                <div v-if="disbursementItemsDescriptionError">
                  <div
                    class="error"
                    v-if="!$v.disbursementItems.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right custom-btn-all"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right custom-btn"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--     EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editSaveFormDetails"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  type="text"
                  label="Disbursement Item Name"
                  placeholder="Disbursement Item Name"
                  v-model="name"
                  :value="name"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementItemsNameError">
                  <div
                    class="error"
                    v-if="!$v.name.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  type="number"
                  step="0.01"
                  label="Disbursement Item Rate"
                  placeholder="Disbursement Item Rate"
                  v-model="rate"
                  :value="rate"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="disbursementItemsRateError">
                  <div
                    class="error"
                    v-if="!$v.rate.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label>Select VAT status</label>
                <select class="form-control" v-model="vatApplicable">
                    <option value="" selected>Select VAT status</option>
                    <option value="true">VATABLE</option>
                    <option value="false">NOT VATABLE</option>
                </select>
                <div v-if="disbursementItemsvatApplicableError">
                  <div
                    class="error"
                    v-if="!$v.vatApplicable.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <label>HAS TARIFF?</label>
                <select class="form-control" v-model="hasTariffItem">
                    <option value="" selected>Selcect yes or no</option>
                    <option value="true">YES</option>
                    <option value="false">NO</option>
                </select>
                
                <!-- <div v-if="disbursementItemsvatApplicableError">
                  <div
                    class="error"
                    v-if="!$v.vatApplicable.required"
                  >
                    This field is required
                  </div>
                </div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>Description</label>
                <textarea class="special-bottom form-control col-md-12" placeholder="Description" rows="5"
                type="textarea" v-model="description" value="description"></textarea>
                <!-- <base-input
                  type="textarea"
                  label="Disbursement Item Description"
                  placeholder="Disbursement Item Description"
                  v-model="description"
                  :value="description"
                  class="special-bottom"
                /> -->
                <div v-if="disbursementItemsDescriptionError">
                  <div
                    class="error"
                    v-if="!$v.description.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="editMyLoader"
                  :color="color"
                  :size="size"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right pr-2 custom-btn-all"
                  style="margin-inline-start:20px"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right custom-btn"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import GlobalModal from '@/components/globalLoader'
import DataTable from '@/components/DataTable/dataTable'
import Modal from '@/components/Inputs/Modal'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DisbursementItems',
  components: {

    PulseLoader,
    GlobalModal,
    DataTable,
    Modal

  },

  created () {

    this.$store.dispatch('pagenames/currentPageName', 'Disbursement Items')
    this.categoryId = this.$route.params.categoryId

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableDisbursementItemsOnCreated()

  },

  computed: {

    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),

    ...mapGetters('disbursement', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getSuccessAlert',
      'getErrorMessage',
      'getErrorAlert',
      'getSuccessMessage',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestId'
      
    ])
  },
  validations: {

    rate: { required },
    description: { required },
    name: { required },
    vatApplicable: { required },

    disbursementItems: {

      rate: { required },
      description: { required },
      name: { required },
      vatApplicable: { required }

    }
  },

  data () {
    
    return {

      disbursementItems: {
        rate: '',
        description: '',
        name: '',
        vatApplicable: ''
      },
      rate: '',
      description: '',
      name: '',
      serviceItemId: '',
      serviceCategoryId: '',
      vatApplicable: '',
      hasTariffItem: '',

      disbursementItemsNameError: false,
      disbursementItemsRateError: false,
      disbursementItemsDescriptionError: false,
      disbursementItemsvatApplicableError: false,
      disbursementItemsHasTariffItemError: false,

      AddModalDiv: false,
      EditModalDiv: false,
      showModal: false,
      editMyLoader: false,

      categoryId: '',
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      sortBy: 'createdAt',
      hasAction: false,
      color: 'green',
      size: '12px',
      loading: true,
      editable: true,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      pdfFormatError: false,
      loadAllHeaderLoader: false,
      allAttachmentVerified: false,
      myLoader: false,
      myFirstLoader: false,
      status: 'NO_ACTION_BUTTON',
      isSearchable: false,
      requests: [],
      requestFlag: 'CONFIG',
      categoryDetails: {},

      SuccessAlert: false,
      ErrorAlert: false,

      SuccessMessage: '',
      ErrorMessage: '',

      isHovered: [],
      saveDetails: {},
      ifThereIsParam: '',
      editDetails: false,
      finalUserPermissions: [],
      tableHeaders: ['Name', 'Rate', 'Description', 'Has Tariff'],
      tableDataKeys: ['name', 'rate', 'description', 'hasTariff']

    }
  },
  methods: {
    ...mapActions('disbursement', [
      'addDisbursementItem',
      'updateDisbursementItem',
      'loadDisbursementCategoriesById'

    ]),

    loadParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('disbursement/pageSizing', this.pageSize)
        this.$store.dispatch('disbursement/pageNumbering', this.pageNo)
        this.$store.dispatch('disbursement/categoryIding', this.categoryId)
        resolve()
      })
    },
    loadSaveDetailsParameters () {
      return new Promise((resolve) => {
        this.$store.dispatch('disbursement/savingDetails', this.saveDetails)
        resolve()
      })
    },

    loadPageableDisbursementItemsOnCreated () {
      this.loadParameters().then(() => {
        this.loadDisbursementCategoriesById({}).then(() => {
          this.pageCount = this.getPageCount
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          // this.pageNo = this.getPageNo;

          this.categoryDetails = this.getRequestList
          if (this.categoryDetails.serviceItems === null) { this.noDataFound = true } else { this.requests = this.categoryDetails.serviceItems }

        }).catch(() => {})
        this.myFirstLoader = this.getMyFirstLoader
      })
    },
    
    saveDisbursementItems () {
      this.loadSaveDetailsParameters().then(() => {
        this.addDisbursementItem({}).then(() => {
          // this.requests = this.getRequestList;
          // this.pageCount = this.getPageCount;
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          this.SuccessMessage = 'Disbursement Item added successfully.'
          this.ErrorMessage = 'Sorry, something went wrong...'
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessAlert
          // this.pageNo = this.getPageNo;
          // console.log(this.getRequestList)
          // this.pageSize = this.getPageSize;
          this.loadDisbursementCategoriesById({}).then(() => {
            this.pageCount = this.getPageCount
            this.myFirstLoader = this.getMyFirstLoader
            this.noDataFound = this.getNoDataFound
            // this.pageNo = this.getPageNo;

            this.categoryDetails = this.getRequestList
            if (this.categoryDetails.serviceItems === null) { this.noDataFound = true } else { this.requests = this.categoryDetails.serviceItems }
          }).catch(() => {})
        }).catch(() => {})
        this.myFirstLoader = this.getMyFirstLoader
      })
    },

    editDisbursementItems () {

      this.loadSaveDetailsParameters().then(() => {
        this.updateDisbursementItem({}).then(() => {
          // this.requests = this.getRequestList;
          // this.pageCount = this.getPageCount;
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          this.SuccessMessage = 'Disbursement Item added successfully.'
          this.ErrorMessage = 'Sorry, something went wrong...'
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessAlert
          // this.pageNo = this.getPageNo;
          // console.log(this.getRequestList)
          // this.pageSize = this.getPageSize;
          this.loadDisbursementCategoriesById({}).then(() => {
            this.pageCount = this.getPageCount
            this.myFirstLoader = this.getMyFirstLoader
            this.noDataFound = this.getNoDataFound
            // this.pageNo = this.getPageNo;

            this.categoryDetails = this.getRequestList
            if (this.categoryDetails.serviceItems === null) { this.noDataFound = true } else { this.requests = this.categoryDetails.serviceItems }
          }).catch(() => {})
        }).catch(() => {})
        this.myFirstLoader = this.getMyFirstLoader
      })
    },
    getCategoryDetails () {

    },
    editItemsDetails (details) {
      
      this.active = details.active
      this.description = details.description
      this.name = details.name
      this.rate = details.rate
      this.vatApplicable = details.vatApplicable
      this.serviceCategoryId = details.serviceCategoryId
      this.serviceItemId = details.serviceItemId
      this.openModal('EDIT')
    },
    openModal (modalType) {
      if (modalType === 'ADD') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.showModal = true
      } else {
        this.EditModalDiv = true
        this.AddModalDiv = false
        this.showModal = true
      }
    },
    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.disbursementItemsDescriptionError = false
      this.disbursementItemsRateError = false
      this.disbursementItemsNameError = false
    },

    saveFormDetails () {

      if (this.$v.disbursementItems.name.$invalid || this.$v.disbursementItems.rate.$invalid || this.$v.disbursementItems.description.$invalid || this.$v.disbursementItems.vatApplicable.$invalid) {

        if (this.$v.disbursementItems.name.$invalid) {

          this.disbursementItemsNameError = true

        }

        if (this.$v.disbursementItems.rate.$invalid) {

          this.disbursementItemsRateError = true

        }

        if (this.$v.disbursementItems.description.$invalid) {

          this.disbursementItemsDescriptionError = true

        }

        if (this.$v.disbursementItems.vatApplicable.$invalid) {

          this.disbursementItemsvatApplicableError = true

        }

      } else {

          this.saveDetails = {

            rate: Number(this.disbursementItems.rate),
            description: this.disbursementItems.description,
            name: this.disbursementItems.name,
            serviceCategoryId: Number(this.categoryId),
            vatApplicable: this.disbursementItems.vatApplicable === 'true' ? true : false,
            active: true

          }

          this.disbursementItems = {}
          this.closeModal()
          this.saveDisbursementItems()

        }
    },

    editSaveFormDetails () {

      if (this.$v.name.$invalid || this.$v.rate.$invalid || this.$v.description.$invalid || this.$v.vatApplicable.$invalid) {

        if (this.$v.name.$invalid) {

          this.disbursementItemsNameError = true

        }

        if (this.$v.rate.$invalid) {

          this.disbursementItemsRateError = true

        }

        if (this.$v.description.$invalid) {

          this.disbursementItemsDescriptionError = true

        }

        if (this.$v.vatApplicable.$invalid) {

          this.disbursementItemsvatApplicableError = true

        }

      } else {

        this.saveDetails = {

          rate: this.rate,
          description: this.description,
          name: this.name,
          serviceCategoryId: Number(this.serviceCategoryId),
          serviceItemId: Number(this.serviceItemId),
          vatApplicable: Boolean(this.vatApplicable),
          active: this.active,
          hasTariff: this.hasTariffItem === 'false' ? false : true
        }

        // console.log("saveDetails", this.saveDetails)

        this.editDisbursementItems()

        this.rate = ''
        this.name = ''
        this.description = ''
        this.serviceItemId = ''
        this.vatApplicable = ''
        this.hasTariffItem = ''
        this.closeModal()
      }
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },
    onChange () {},
    enter: function (el, done) {
      var that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },
    goBack () {
      if (window.$cookies.isKey('browser-local-cashed-url-back')) {
        this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))

        window.$cookies.remove('browser-local-cashed-url-back')
      }
    }

  }

}
</script>

<style scoped>

.legend {
  position: relative;
  border: 1px solid #DCDCDC;
  padding: 1.5rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  border-radius: 3px;
  margin-top: 1rem;
}

.legend-title {
  background-color: inherit;
  position: absolute;
  top: -0.6rem;
  padding: 0.2rem 1rem;
  background-color: white;
  color: #d59a18;
}

.btn-space {
  margin-right: 10px !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}
.error{
  color:#dc3545;
  margin-left: 2px;
  margin-top: 2px;
}
.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}
.div-table-row {
  padding: 0 15px 0 15px;
}

.div-table {
  padding: 9px 8px 9px 8px;
}

.div-table-title {
  width: 250px;
  background-color: rgba(153, 186, 221, .5);
  font-weight: normal;
}

.div-table-value {
  border: 1px solid #DCDCDC;
  font-weight: 900;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
</style>
