<template>
  <card>
    <div>
      <div
        class="col-md-12 text-center"
        v-if="submitError"
      >
        <span class="text-danger">Please Fill All Fields and click Insert Then Submit</span>
      </div>
      <div
        class="col-md-12 text-center"
        v-if="insertError"
      >
        <span class="text-danger">Please Fill All Fields</span>
      </div>
      <div class="col-md-12 text-center">
        <pulse-loader
          :color="color"
          :loading="loading"
          :size="size"
          v-if="loading"
        />
      </div>
      <div class="row">
        <div class="col-md-12">
          <crud-notification
            :error-message="ErrorMessage"
            :success-message="SuccessMessage"
            :error-alert="ErrorAlert"
            :success-alert="SuccessAlert"
            @fadeMe="fadeMe"
            @enter="enter"
          />
        </div>
      </div>
      <div class="row">
        <div :class="{'col-md-12':columnLength,'col-md-6':!columnLength}">
          <div>
            <p class="mt-3">
              Working Days
            </p>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="monday"
                v-model="monday"
              >
              <label
                class="custom-control-label"
                for="monday"
              >Monday</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="tue"
                v-model="tuesday"
              >
              <label
                class="custom-control-label"
                for="tue"
              >Tuesday</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="wedn"
                v-model="wednesday"
              >
              <label
                class="custom-control-label"
                for="wedn"
              >Wednesday</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="thurs"
                v-model="thursday"
              >
              <label
                class="custom-control-label"
                for="thurs"
              >Thursday</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="frid"
                v-model="friday"
              >
              <label
                class="custom-control-label"
                for="frid"
              >Friday</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="sat"
                v-model="saturday"
              >
              <label
                class="custom-control-label"
                for="sat"
              >Saturday</label>
            </div>
            <div class="custom-control custom-checkbox custom-control-inline">
              <input
                type="checkbox"
                class="custom-control-input"
                id="sun"
                v-model="sunday"
              >
              <label
                class="custom-control-label"
                for="sun"
              >Sunday</label>
            </div>
          </div>
          <br />
          <label>Select Shift</label>
          <Shifts
            type="shiftClerk"
            @selectedShiftEvent="onSelectedShift"
            ref="shifts"
          />
          <br />
          <label>Search Clerk Name</label>
          <list-select
            :list="allUsers"
            :selected-item="selectedClerkObject"
            option-value="userId"
            option-text="fullName"
            class="form-control"
            placeholder="Clerk name"
            @select="getUserFullName"
          />
          <div v-if="SingleClerkNameError">
            <div
              class="error"
              v-if="!$v.SingleClerkName.required"
            >
              This field is required
            </div>
          </div>
        </div>
        
        <div :class="{'col-md-6':!columnLength}" :style="[columnLength===true? {'display':'none'}:'']" style="border-left: 1px solid #dcdcdc;">
          <div class="row" style="max-height: 400px;overflow-y: scroll">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Clerk Name</th>
                  <th>Shift</th>
                  <th>Shift Status</th>
                  <th>Day(s)</th>
                  <th>Action</th>
                </tr>
              </thead>
                <tr v-for="(data,index1) in shiftClerkArray" :key="index1">
                  <td>{{ data.name }}</td>
                  <td>{{ data.operationShift.name + " : ( " }} {{ data.operationShift.startTime + " - " }}{{ data.operationShift.endTime + ' )'}}</td>
                  <td>{{ data.active? "ACTIVE":"NOT ACTIVE" }}</td>
                  <td>
                    <span v-for="(day,index) in data.day" :key="index">{{ day + (data.day.length === 1 ? '' : ",") }}</span>
                  </td>
                  <td>
                    <font-awesome-icon icon="trash-alt" class="text-danger download-link" @click="removeClerk"/>
                  </td>
                </tr>
              <tbody>

              </tbody>
            </table>
            <!-- <div class="card col-md-12" style="width: 18rem;" v-for="(data,index) in shiftClerkArray">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <strong>Clerk Name</strong> {{ fullName }}
                </li>
                <li class="list-group-item">
                  <strong>Shift</strong> {{ data.operationShift.name + ":" }}{{ data.operationShift.startTime + "-" }}{{ data.operationShift.endTime }}
                </li>
                <li class="list-group-item">
                  <strong>Active</strong> {{ data.active? "ACTIVE":"NOT ACTIVE" }}
                </li>
                <li class="list-group-item">
                  <span v-for="(day,index) in data.day">{{ day + "," }}</span>
                </li>
              </ul>
            </div> -->
          </div>
        </div>

        <div class="col-md-12">
          <br />
          <hr />
        </div>

        <div :class="{'col-md-12 text-right':columnLength,'col-md-6 text-right':!columnLength}">
          <button
            type="button"
            class="btn btn-primary mt-3 mr-3 custom-btn"
            @click="insertShiftInArray({operation:'add'})"
          >
            Insert
          </button>
        </div>
        <div :class="{'col-md-6 text-right':!columnLength}" :style="[columnLength===true? {'display':'none'}:'']" style="border-left: 1px solid #dcdcdc;">
          <button
            type="button"
            class="btn btn-primary mt-3 custom-btn"
            @click.prevent="createShiftClerk"
            v-if="shiftClerkArray.length>0"
          >
            Submit
          </button>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-12">
          <hr />
        </div>
        <div class="col-md-12 text-right">
          <button
            type="button"
            class="btn btn-primary mt-3 mr-3 custom-btn"
            @click="insertShiftInArray({operation:'add'})"
          >
            Insert
          </button>
          <button
            type="button"
            class="btn btn-primary mt-3 custom-btn"
            @click.prevent="createShiftClerk"
            v-if="shiftClerkArray.length>0"
          >
            Submit
          </button>
        </div>
      </div> -->
    </div>
  </card>
</template>

<script>
import { ModelListSelect, ListSelect } from 'vue-search-select'
import { required } from 'vuelidate/lib/validators'
import TALLY_API from '../../../api/tally'
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Shifts from '../subComponents/shifts'
import CrudNotification from '../../../components/DataTable/crudNotification';

export default {
  name: 'ShiftClerk',
  components: {
    Shifts,
    ModelListSelect,
    ListSelect,
    PulseLoader,
    CrudNotification
  },
  data () {
    return {
      allUsers: [],
      selectedClerk: '',
      SingleClerkNameError: false,
      active: true,
      loading: false,
      color: 'green',
      size: '15px',
      activeError: false,
      fullName: '',
      selectedClerkObject: {},

      shiftClerkArray: [],

      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,

      selectedShift: '',
      daysError: false,
      columnLength: true,

      insertError: false,
      submitError: false,

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',

    }
  },
  created () {

    this.$store.dispatch("pagenames/currentPageName", "Shift Clerk");
    this.getUsers()

  },

  validations: {
    selectedClerk: { required },
    active: { required },
    selectedShift: { required }
  },
  methods: {

    onSelectedShift (data) {

      this.selectedShift = data
    },

    // fullName (name) {
    //   return `${name.firstName} - ${name.lastName}`
    // },

    getUsers () {

      this.loading = true
      API.getUsers().then((response) => {

        this.loading = false
        this.allUsers = response.data.data

      })
      
    },

    getUserFullName (object) {
      
      this.selectedClerkObject = object
      this.fullName = object.fullName
      this.selectedClerk = object.userId
    },

    removeClerk (object) {

      this.shiftClerkArray.splice(this.shiftClerkArray.indexOf(object), 1)
      if(this.shiftClerkArray.length === 0)
        this.columnLength = true

    },

    insertShiftInArray (selection) {
      
      if (selection.operation === 'add') {

        this.insertError = false
        const days = this.daysObject();

        if (this.active !== '' && this.clerkId !== '' && days.length > 0 && this.selectedShift !== '') {

          const data = {

            name: this.fullName,
            active: this.active,
            clerkId: this.selectedClerk,
            day: days,
            operationShift: this.selectedShift

          };

          this.shiftClerkArray.push(data)

          this.selectedClerkObject = {}

          this.fullName = ''
          this.selectedClerk = ''

          this.active = true;
          this.selectedClerk = ''
          this.selectedShift = ''

          this.monday = false
          this.tuesday = false
          this.wednesday = false
          this.thursday = false
          this.friday = false
          this.saturday = false
          this.sunday = false

          this.$refs.shifts.selectedShift = ''
        } else {
          this.insertError = true
        }
      } else {
        const index = this.shiftClerkArray.indexOf(selection.index)
        if (index > -1) {
          this.shiftClerkArray.splice(index, 1)
        }
      }

      if (this.shiftClerkArray.length > 0) {
        this.columnLength = false
      } else {
        this.columnLength = true
      }
    },

    daysObject () {
      const dataObject = [];

      if (this.monday) {
        dataObject.push('MONDAY')
      }
      if (this.tuesday) {
        dataObject.push('TUESDAY')
      }

      if (this.wednesday) {
        dataObject.push('WEDNESDAY')
      }

      if (this.thursday) {
        dataObject.push('THURSDAY')
      }

      if (this.friday) {
        dataObject.push('FRIDAY')
      }

      if (this.saturday) {
        dataObject.push('SATURDAY')
      }

      if (this.sunday) {
        dataObject.push('SUNDAY')
      }

      return dataObject
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
      } else {
        this.ErrorAlert = !this.ErrorAlert;
      }
    },
    enter: function (el, done) {
      var that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000); // hide the message after 3 seconds
    },

    createShiftClerk () {

      this.$dialog.confirm('You are about to save selected clerks to shifts, are you sure?', {

        loader: true,
        html: true,
        animation: 'zoom', // Available: "zoom", "bounce", "fade"
        okText: 'Yes, save',
        cancelText: 'No, close',

      }).then((dialog) => {

      if (this.shiftClerkArray.length >= 1) {
        this.submitError = false
        this.loading = true

        const dataToSend = []

        this.shiftClerkArray.map((data) => {
          data.day.map((day) => {
            dataToSend.push({
              active: data.active,
              clerkId: data.clerkId,
              day: day,
              operationShiftId: data.operationShift.id
            })
          })
        });

        this.daysError = false

        TALLY_API.createShiftClerk(dataToSend).then((response) => {

          if(response.data.status) {

            this.shiftClerkArray = [];
            this.columnLength = true
            this.loadNotification('success', 4000, '', 'Clerk has been successfully added', 'response', 1000 , true  , true)

          } else {

            this.loadNotification('error', 4000, '', 'Clerk not added', 'response', 1000 , true  , true)

          }
          this.loading = false;

        }).catch(()=>{

          this.loadNotification('error', 4000, '', 'Clerk not added', 'response', 1000 , true  , true)
          this.loading = false;

        })

      } else {

        this.submitError = true;

      }

      dialog.close();

      });

    }
  }

}
</script>

<style scoped>
.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}
.download-link {
    color: green;
}

.download-link:hover {
    color: blue;
    cursor: pointer;
}
</style>
