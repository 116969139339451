<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12">
        </div>
      </div>
          <data-table
            :table-headers="tableHeaders"
            :table-data="requests"
            :table-data-keys="tableDataKeys"
            :my-first-loader="myFirstLoader"
            :no-data-found="noDataFound"
            :has-manage-button="false"
            :is-pageable="false"
            :has-pagination="false"
            :has-filter="false"
            :has-delete-button="true"
            :has-edit-button="true"
            :permissions="[ {key: 'searchPermission', value: 'API_TALLIED-DE-STUFFING_ALL_PAGEABLE_{STUFFINGTYPE}_GET'} ]"
            :buttonList="finalUserPermissions.includes('API_TALLY-CLERK-SHIFTS_GET-USERS-IN-SHIFT_{SHIFTID}_GET') ? [{'code' : 'ViewClerk' , 'name' : 'View Clerks' , 'class' : 'btn-primary'} ] : []"
            :loading-title="'Please wait while system is loading tally shifts list'"
            @goToViewClerk="goToClerks"
          />
    </card>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import DataTable from '../../../components/DataTable/dataTable';
import {mapGetters, mapActions} from "vuex";

export default {

  name: 'ShiftsView',

  components: {

    PulseLoader,
    DataTable

  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    this.$store.dispatch('pagenames/currentPageName', 'Tally Shifts List');

    this.loadPageableRequestOnCreated();

    this.$parent.$on('clearSelectedShip', () => {

      this.selectedShift = ''

    })

  },

  data () {

    return {

      finalUserPermissions:[],

      selectedShift: '',

      myFirstLoader: false,
      noDataFound: false,
      requests: [],

      tableHeaders: ['Shift Name', 'Start Time', 'End Time', 'Status'],
      tableDataKeys: ['name', 'startTime', 'endTime', 'active'],

    }

  },

  watch: {

    selectedShift: function () {

      this.$emit('selectedShiftEvent', this.selectedShift)

    }
  },

  computed: {

    ...mapGetters('tallyshift',[
      'getTallyShiftDetails',
      'getMyFirstLoader',
      'getNoDataFound'
    ]),

    // ...mapGetters({
    //
    //   getTallyShiftDetails: "tallyshift/getTallyShiftDetails",
    //   getMyFirstLoader: "tallyshift/getMyFirstLoader",
    //   getNoDataFound: 'tallyshift/getNoDataFound'
    // }),

  },

  methods: {

    ...mapActions("tallyshift", [

      "loadShifts",

    ]),

    sortData (data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x],
          active: data[x].active ? 'ACTIVE' : 'INACTIVE'

        };

            this.filteredRequests.push(requestObject)

      }

    },

    loadPageableRequestOnCreated() {
      
      this.filteredRequests = []

      this.loadShifts({}).then(() => {
        
        this.requests = this.getTallyShiftDetails;
        this.sortData(this.requests)
        this.myFirstLoader = this.getMyFirstLoader;
        this.noDataFound = this.getNoDataFound;

      }).catch(() => {

      });

      this.myFirstLoader = this.getMyFirstLoader;

    },

    goToClerks (object) {

      document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
      this.$router.push({ name: 'clerkinshift', params: { shiftId : object.id } })

    },

  }
}
</script>

<style scoped>
    .pointer{
        cursor: pointer;
    }
</style>
