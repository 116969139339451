<template>
  <div class="content">
    <div class="row">
      <div class="col-md-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="myloader"
        />
      </div>
      <div class="col-md-12 text-center">
        <br>
      </div>
    </div>
    <div
      class="row"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >
      <div class="col-xl-9 col-md-12">
        <stats-card>
          <div
            slot="header"
            class="text-left"
          >
            <font-awesome-icon
              icon="file-import"
              class="text-warning"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Import Applications
            </p>
            <h4 class="card-title clickable-link" @click="goToApplication('all' , 'import')">
              <b>{{ importTotal }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total number of import applications
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="text-muted"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Draft
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('draft-request' , 'import')"
            >
              <b>{{ draft }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total draft applications
          </div>
        </stats-card>
      </div>
    </div>

    <br
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >

    <div
      class="row"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >
      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="hands"
              class="received-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              New Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-submitted' , 'import')"
            >
              <b>{{ received }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total New applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="check"
              class="accepted-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Accepted Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-accepted' , 'import')"
            >
              <b>{{ approved }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Accepted applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div
            slot="header"
            class="icon-danger"
          >
            <font-awesome-icon
              icon="luggage-cart"
              class="request-in-action"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Tariffed Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-assessed' , 'import')"
            >
              <b>{{ assessed }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total tariffed applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="luggage-cart"
              class="text-danger"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Rejected Tariff
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-assessment-rejected' , 'import')"
            >
              <b>{{ assessedRejected
              }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Rejected Tariff
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="check"
              class="text-success"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Approved Bills
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-assessment-sent-to-gepg' , 'import')"
            >
              <b>{{ billApproved }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total application bills approved
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              class="bill-non-icon"
              style="color:#118C4F;"
              icon="dollar-sign"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Paid Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-bill-paid' , 'import')"
            >
              <b>{{ paid }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total paid applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="edit"
              class="accepted-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Amended
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-resubmitted-after-changes' , 'import')"
            >
              <b>{{ amended }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Amended applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="text-muted"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Rejected Charged
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-charge-rejected' , 'import')"
            >
              <b>{{ rejectedCharge }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Rejected Charged applications
          </div>
        </stats-card>
      </div>
    </div>

    <br
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >

    <div
      class="row"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >
      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="text-muted"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Charged Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-charged' , 'import')"
            >
              <b>{{ charged }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total charged applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="accepted-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Billed Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-billed' , 'import')"
            >
              <b>{{ billed }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total billed applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="times"
              class="accepted-request text-danger"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Dismissed Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-is-dismissed' , 'import')"
            >
              <b>{{ dismissed }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Dismissed applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              class="bill-non-icon"
              style="color:#118C4F;"
              icon="dollar-sign"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Paid Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-bill-paid' , 'import')"
            >
              <b>{{ paid }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total paid applications
          </div>
        </stats-card>
      </div>
    </div>

    <br
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >

    <div
      class="row"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >
      <div class="col-xl-6 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="exclamation-triangle"
              style="color:#ffcc00;"
              class="returned-for-changes"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Minor Issues Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-returned-for-minor-changes' , 'import')"
            >
              <b>{{ minorRejected }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total application with minor issues
          </div>
        </stats-card>
      </div>

      <div class="col-xl-6 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="exclamation-triangle"
              style="color:red;"
              class="declined-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Major Issues Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-returned-for-major-changes' , 'import')"
            >
              <b>{{ majorRejected }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total application with major issues
          </div>
        </stats-card>
      </div>
    </div>

    <hr
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >

    <br
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >                                             <!-- THE SEPARATOR -->

    <div
      class="row"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >
      <div class="col-xl-9 col-md-12">
        <stats-card>
          <div
            slot="header"
            class="text-left"
          >
            <font-awesome-icon
              icon="file-export"
              class="text-success"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Export Applications
            </p>
            <h4 class="card-title clickable-link" @click="goToApplication('all' , 'export')">
              <b>{{ exportTotal }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total number of export applications
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="text-muted"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Draft
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('draft-request' , 'export')"
            >
              <b>{{ draftEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total draft applications
          </div>
        </stats-card>
      </div>
    </div>

    <br
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >

    <div
      class="row"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >
      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="hands"
              class="received-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              New Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-submitted' , 'export')"
            >
              <b>{{ receivedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total New applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="check"
              class="accepted-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Accepted Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-accepted' , 'export')"
            >
              <b>{{ approvedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Accepted applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div
            slot="header"
            class="icon-danger"
          >
            <font-awesome-icon
              icon="luggage-cart"
              class="request-in-action"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Tariffed Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-assessed' , 'export')"
            >
              <b>{{ assessedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total tariffed applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="luggage-cart"
              class="text-danger"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Rejected Tariff
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-assessment-rejected' , 'export')"
            >
              <b>{{
                assessedRejectedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Rejected Tariff
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="check"
              class="text-success"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Approved Bills
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-assessment-sent-to-gepg' , 'export')"
            >
              <b>{{ billApprovedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total application bills approved
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              class="bill-non-icon"
              style="color:#118C4F;"
              icon="dollar-sign"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Paid Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-bill-paid' , 'export')"
            >
              <b>{{ paidEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total paid applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="edit"
              class="accepted-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Amended
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-resubmitted-after-changes' , 'export')"
            >
              <b>{{ amendedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Amended applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="text-muted"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Charged Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-charged' , 'export')"
            >
              <b>{{ chargedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total charged applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="text-muted"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Rejected Charged
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-charge-rejected' , 'export')"
            >
              <b>{{ rejectedChargeEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Rejected Charged applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="file-invoice-dollar"
              class="accepted-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Billed Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-billed' , 'export')"
            >
              <b>{{ billedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total billed applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="times"
              class="accepted-request text-danger"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Dismissed Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-is-dismissed' , 'export')"
            >
              <b>{{ dismissedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total Dismissed applications
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              class="bill-non-icon"
              style="color:#118C4F;"
              icon="dollar-sign"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Paid Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-bill-paid' , 'export')"
            >
              <b>{{ paidEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total paid applications
          </div>
        </stats-card>
      </div>
      
    </div>

    <br
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >

    <div
      class="row"
      v-if="finalUserPermissions.includes('CFA_DASHBOARD_VIEW')"
    >
      <div class="col-xl-6 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="exclamation-triangle"
              style="color:#ffcc00;"
              class="returned-for-changes"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Minor Issues Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-returned-for-minor-changes' , 'export')"
            >
              <b>{{ minorRejectedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total application with minor issues
          </div>
        </stats-card>
      </div>

      <div class="col-xl-6 col-md-6">
        <stats-card>
          <div slot="header">
            <font-awesome-icon
              icon="exclamation-triangle"
              style="color:red;"
              class="declined-request"
            />
          </div>
          <div slot="content">
            <p class="card-category">
              Major Issues Applications
            </p>
            <h4
              class="card-title clickable-link"
              @click="goToApplication('request-returned-for-major-changes' , 'export')"
            >
              <b>{{ majorRejectedEx }}</b>
            </h4>
          </div>
          <div slot="footer">
            Total application with major issues
          </div>
        </stats-card>
      </div>
    </div>
  </div>
</template>
<script>

import StatsCard from 'src/components/Cards/StatsCard.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import API from '../api/index'

export default {

  components: {

    StatsCard,
    PulseLoader

  },

  created () {

    this.$store.dispatch('pagenames/currentPageName', 'Dashboard')

    // this.loadRejectedChargeRequestCfa('REJECTED_CHARGE', 'IMP').then(() => {

    //   this.loadBilledRequestCfa('BILLED', 'IMP').then(() => {

    //     this.loadChargedRequestCfa('CHARGED', 'IMP').then(() => {

    //       this.loadAssessedRequestCfa('ASSESSED', 'IMP').then(() => {

    //         this.loadAssessedRejectedRequestCfa('REJECTED_ASSESSMENT', 'IMP').then(() => {

    //           this.loadDismissedRequestCfa('DISMISSED', 'IMP').then(() => {

    //             this.loadImportRequestCfa('IMP')
              
    //           }).catch(() => {})
          
    //         }).catch(() => {})
          
    //       }).catch(() => {})
      
    //     }).catch(() => {})
      
    //   }).catch(() => {})

    // }).catch(() => {})

    // this.loadRejectedChargeRequestCfaEx('REJECTED_CHARGE', 'EXP').then(() => {

    //   this.loadBilledRequestCfaEx('BILLED', 'EXP').then(() => {

    //     this.loadChargedRequestCfaEx('CHARGED', 'EXP').then(() => {

    //       this.loadAssessedRequestCfaEx('ASSESSED', 'EXP').then(() => {

    //         this.loadAssessedRejectedRequestCfaEx('REJECTED_ASSESSMENT', 'EXP').then(() => {

    //           this.loadDismissedRequestCfaEx('DISMISSED', 'EXP').then(() => {

    //             this.loadExportRequestCfa('EXP')
              
    //           }).catch(() => {})
          
    //         }).catch(() => {})
          
    //       }).catch(() => {})
      
    //     }).catch(() => {})
      
    //   }).catch(() => {})

    // }).catch(() => {})

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadImportRequestCfa('IMP')
    this.loadExportRequestCfa('EXP')

    this.loadReceivedRequestCfa('SUBMITTED', 'IMP')
    this.loadDraftRequestCfa('DRAFT', 'IMP')
    this.loadAmendedRequestCfa('RETURNED', 'IMP')
    this.loadDismissedRequestCfa('DISMISSED', 'IMP')
    this.loadRejectedChargeRequestCfa('REJECTED_CHARGE', 'IMP')
    this.loadApprovedRequestCfa('APPROVED', 'IMP')
    this.loadAssessedRequestCfa('ASSESSED', 'IMP')
    this.loadAssessedRejectedRequestCfa('REJECTED_ASSESSMENT', 'IMP')
    this.loadChargedRequestCfa('CHARGED', 'IMP')
    this.loadBilledRequestCfa('BILLED', 'IMP')
    this.loadBillApprovedRequestCfa('BILL_APPROVED', 'IMP')
    this.loadPaidRequestCfa('PAID', 'IMP')
    this.loadMinorRejectedRequestCfa('REJECTED_MINOR', 'IMP')
    this.loadMajorRejectedRequestCfa('REJECTED_MAJOR', 'IMP')

    this.loadReceivedRequestCfaEx('SUBMITTED', 'EXP')
    this.loadDraftRequestCfaEx('DRAFT', 'EXP')
    this.loadAmendedRequestCfaEx('RETURNED', 'EXP')
    this.loadDismissedRequestCfaEx('DISMISSED', 'EXP')
    this.loadRejectedChargeRequestCfaEx('REJECTED_CHARGE', 'EXP')
    this.loadApprovedRequestCfaEx('APPROVED', 'EXP')
    this.loadAssessedRequestCfaEx('ASSESSED', 'EXP')
    this.loadAssessedRejectedRequestCfaEx('REJECTED_ASSESSMENT', 'EXP')
    this.loadChargedRequestCfaEx('CHARGED', 'EXP')
    this.loadBilledRequestCfaEx('BILLED', 'EXP')
    this.loadBillApprovedRequestCfaEx('BILL_APPROVED', 'EXP')
    this.loadPaidRequestCfaEx('PAID', 'EXP')
    this.loadMinorRejectedRequestCfaEx('REJECTED_MINOR', 'EXP')
    this.loadMajorRejectedRequestCfaEx('REJECTED_MAJOR', 'EXP')
  },

  data () {
    return {

      color: 'green',
      size: '15px',
      loading: true,

      finalUserPermissions: [],

      importTotal: 0,
      exportTotal: 0,
      received: 0,
      approved: 0,
      assessed: 0,
      assessedRejected: 0,
      charged: 0,
      billed: 0,
      billApproved: 0,
      paid: 0,
      minorRejected: 0,
      majorRejected: 0,
      draft: 0,
      rejectedCharge: 0,
      dismissed: 0,
      amended: 0,

      receivedEx: 0,
      approvedEx: 0,
      assessedEx: 0,
      assessedRejectedEx: 0,
      chargedEx: 0,
      billedEx: 0,
      billApprovedEx: 0,
      paidEx: 0,
      minorRejectedEx: 0,
      majorRejectedEx: 0,
      draftEx: 0,
      rejectedChargeEx: 0,
      dismissedEx: 0,
      amendedEx: 0,
      myLoader: false

    }
  },

  methods: {

    goToApplication (requestStatus, requestType) {

      this.$router.push({ name: 'application', params: { status: requestStatus,  requestType: requestType} })

    },

    loadImportRequestCfa (importFlag) {
      this.myloader = true;

      API.getImportOrExportRequestCfa(importFlag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.importTotal = response.data.data
            
            } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadExportRequestCfa (exportFlag) {
      this.myloader = true

      API.getImportOrExportRequestCfa(exportFlag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.exportTotal = response.data.data

            } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadReceivedRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.received = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadAmendedRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.amended = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadAmendedRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.amendedEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadRejectedChargeRequestCfa (status, flag) {

      return new Promise((resolve, reject) => {

        this.myloader = true

        API.getStatusImportOrExportRequestCfa(status, flag).then(
          response => {
            if (response.data.status) {
              this.myloader = false
              this.rejectedCharge = response.data.data
              resolve()
            } else {

            }
          }
        ).catch(() => {
          this.myloader = false
          reject()
        })

      })

    },

    loadRejectedChargeRequestCfaEx (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.rejectedChargeEx = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },

    loadDismissedRequestCfa (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.dismissed = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },
    loadDismissedRequestCfaEx (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.dismissedEx = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },

    loadDraftRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.draft = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadDraftRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.draftEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadApprovedRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.approved = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadAssessedRequestCfa (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.assessed = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })

      })
    },

    loadAssessedRejectedRequestCfa (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.assessedRejected = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },

    loadChargedRequestCfa (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.charged = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })

      })

    },

    loadBilledRequestCfa (status, flag) {

      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getStatusImportOrExportRequestCfa(status, flag).then(
          response => {
            if (response.data.status) {
              this.myloader = false
              this.billed = response.data.data
              resolve()
            } else {

            }
          }
        ).catch(() => {
          this.myloader = false
          reject()
        })

      })

    },

    loadBillApprovedRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.billApproved = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadPaidRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.paid = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadMajorRejectedRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.majorRejected = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadMinorRejectedRequestCfa (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.minorRejected = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    //  EXPORT

    loadReceivedRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.receivedEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadApprovedRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.approvedEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadAssessedRequestCfaEx (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.assessedEx = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },

    loadAssessedRejectedRequestCfaEx (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.assessedRejectedEx = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },

    loadChargedRequestCfaEx (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.chargedEx = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },

    loadBilledRequestCfaEx (status, flag) {

      return new Promise((resolve, reject) => {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false
            this.billedEx = response.data.data
            resolve()
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
        reject()
      })
      })
    },

    loadBillApprovedRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.billApprovedEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadPaidRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.paidEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadMajorRejectedRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.majorRejectedEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    },

    loadMinorRejectedRequestCfaEx (status, flag) {
      this.myloader = true

      API.getStatusImportOrExportRequestCfa(status, flag).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            this.minorRejectedEx = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.myloader = false
      })
    }

  }
}
</script>
<style scoped>

  .clickable-link:hover {
    cursor: pointer;
    color: green;
  }

</style>