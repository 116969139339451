<template>
  <div
    class="content"
    v-if="finalUserPermissions.includes('DASHBOARD_VIEW')"
  >
    <div
      class="row"
      v-if="dashboardLoader"
    >
      <div class="col-md-12 text-center">
        <b>Please wait while system is loading dashboard contents</b>
      </div>
      <div class="col-md-12 text-center">
        <br>
      </div>
      <div class="col-md-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
        />
      </div>
    </div>

    <div class="row">

      <div class="col-md-4 text-center">

        <!-- <h4>Total Revenue</h4>
        <GChart
          type="PieChart"
          :options="optionsRevenue"
          :data="chartDataRevenue"
        /> -->

      </div>

      <div class="col-md-4 text-center">

        <h4>Documents Controlled</h4>
        <GChart
          type="PieChart"
          :options="options"
          :data="chartData"
        />

      </div>

      <div class="col-md-4 text-center">

        <h4>CFA Applications</h4>
        <GChart
          type="PieChart"
          :options="options"
          :data="chartData"
        />

      </div>

    </div>

    <div
      class="row"
      v-if="!dashboardLoader"
    >
      <div class="col-md-3">
        <div class="widget col-md-12">
          <div class="details">
            <div class="summary col-md-12">
              <p class="summaryText col-md-12">
                Documents Controlled
              </p>
            </div>
            <div class="temperature col-md-12">
              Total:
              {{ (totalDocumentsReceived.total + totalDocumentsReceivedExp.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
            <div class="precipitation col-md-12">
              IMPORT: {{ totalDocumentsReceived.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
            <div class="wind col-md-12">
              EXPORT: {{ totalDocumentsReceivedExp.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-3">
        <div class="widget col-md-12">
          <div class="details">
            <div class="summary col-md-12">
              <p class="summaryText col-md-12">
                Total Revenue
              </p>
            </div>
            <div class="temperature col-md-12">
              Total:
              {{ ((totalRevenueDc.totalBillAmountTzs + totalRevenueCfa.totalBillAmountTzs).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
            <div class="wind col-md-12">
              CFA: {{ totalRevenueCfa.totalBillAmountTzs.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
            <div class="precipitation col-md-12">
              DC: {{ totalRevenueDc.totalBillAmountTzs.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-md-3">
        <div class="widget col-md-12">
          <div class="details">
            <div class="summary col-md-12">
              <p class="summaryText col-md-12">
                CFA Applications
              </p>
            </div>
            <div class="temperature col-md-12">
              Total:
              {{ totalRequests.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
            <span
              v-for="(item, index) in requestTypes"
              :key="index"
            >
              <total-summary
                v-if="item.requestTypeFlag==='IMP'"
                class="precipitation col-md-12"
                style="margin-top: 5px"
                icon="IMPORT: "
                :request-type-id="item.requestTypeId.toString().replace(/\B(?=(\d{3})+(?!\d))/g, &quot;,&quot;)"
              />

              <total-summary
                v-if="item.requestTypeFlag==='EXP'"
                class="wind col-md-12"
                style="margin-top: -10px"
                icon="EXPORT: "
                :request-type-id="item.requestTypeId.toString().replace(/\B(?=(\d{3})+(?!\d))/g, &quot;,&quot;)"
              />
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="widget col-md-12">
          <div class="details">
            <div class="summary col-md-12">
              <p class="summaryText col-md-12">
                Marine Vessels
              </p>
            </div>
            <div class="temperature col-md-12">
              Total:
              {{ totalVessels.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <br v-if="!dashboardLoader"><br v-if="!dashboardLoader">
    <div
      class="row"
      v-if="!dashboardLoader"
    >
      <total-summary-line-chart
        v-if="totalRevenueThisYearMonthlyCfaLoaded && totalRevenueThisYearMonthlyDcLoaded"
        :display-data="[totalRevenueThisYearMonthlyDc,totalRevenueThisYearMonthlyCfa]"
        :footer-text="'DC Revenues vs CFA Revenues monthly '+(thisYear-1)+'/'+thisYear"
        :is-revenue="true"
        :label-title-one="'DC Revenues '+(thisYear-1)+'/'+thisYear"
        :label-title-two="'CFA Revenues '+(thisYear-1)+'/'+thisYear"
        :title="'DC and CFA Revenue Comparison for the FY '+(thisYear-1)+'/'+thisYear"
        :sub-title="(thisYear-1)+'/'+thisYear+' DC CFA Revenue Summary'"
      />
    </div>
    <br v-if="!dashboardLoader">
    <div
      class="row"
      v-if="!dashboardLoader"
    >
      <total-summary-line-chart
        v-if="totalDocumentsReceivedMonthlyExpLoaded && totalDocumentsReceivedMonthlyImpLoaded"
        :display-data="[totalDocumentsReceivedMonthlyImp,totalDocumentsReceivedMonthlyExp]"
        :footer-text="'Import vs Export monthly '+(thisYear-1)+'/'+thisYear"
        :is-revenue="false"
        :label-title-one="'DC Import '+(thisYear-1)+'/'+thisYear"
        :label-title-two="'DC Export '+(thisYear-1)+'/'+thisYear"
        :title="'Document Control Import and Export Comparison for the FY '+(thisYear-1)+'/'+thisYear"
        :sub-title="(thisYear-1)+'/'+thisYear+' Import Export Summary'"
      />
    </div>
    <br v-if="!dashboardLoader">
    <div
      class="row"
      v-if="!dashboardLoader"
    >
      <total-summary-line-chart
        v-if="totalRequestsYearMonthlyExpLoaded && totalRequestsYearMonthlyImpLoaded"
        :display-data="[totalRequestsYearMonthlyImp,totalRequestsYearMonthlyExp]"
        :footer-text="'CFA Import vs Export monthly '+(thisYear-1)+'/'+thisYear"
        :is-revenue="false"
        :label-title-one="'CFA Import '+(thisYear-1)+'/'+thisYear"
        :label-title-two="'CFA Export '+(thisYear-1)+'/'+thisYear"
        :title="'CFA Import and Export Comparison for the FY '+(thisYear-1)+'/'+thisYear"
        :sub-title="(thisYear-1)+'/'+thisYear+' CFA Import Export Summary'"
      />
    </div>
  </div>
</template>
<script>
import ChartCard from 'src/components/Cards/ChartCard.vue'
import StatsCard from 'src/components/Cards/StatsCard.vue'
import TotalSummary from './dashboardChartComponents/TotalSummary.vue'
import TotalSummaryLineChart from './dashboardChartComponents/TotalSummaryLineChart.vue'

import API from '../api/index'
import API_REVENUE from '../api/rev'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

import { GChart } from "vue-google-charts";

export default {

  components: {

    ChartCard,
    StatsCard,
    PulseLoader,
    TotalSummary,
    TotalSummaryLineChart,
    GChart

  },

  data () {
    return {

      chartDataRevenue: [],
      optionsRevenue: {},

      chartData: [
        ['Task', 'Hours every day'],
        ['Work',     11],
        ['Eat',      2],
        ['Commute',  2],
        ['Watch TV', 2],
        ['Sleep',    7]
      ],
      options: {
        width: 500,
        height: 250,
        is3D: false,
        title: "Company Performance",
        subtitle: "blog in total: languages"

      },

      color: 'green',
      size: '12px',
      loading: true,
      dashboardLoader: false,

      finalUserPermissions: [],
      thisYear: 0,
      requestTypes: [],
      totalVessels: {
        title: '',
        total: 0
      },
      totalDocumentsReceived: {
        title: '',
        total: 0
      },
      totalDocumentsReceivedExp: {
        title: '',
        total: 0
      },
      totalDocumentsReceivedMonthlyImp: [],
      totalDocumentsReceivedMonthlyImpLoaded: false,
      totalDocumentsReceivedMonthlyExp: [],
      totalDocumentsReceivedMonthlyExpLoaded: false,
      totalDocumentsReceivedGroupedYearly: [],
      totalVesselsForYearMonthly: [],
      totalVesselsYearly: [],
      // revenue
      totalRevenue: {
        yearMonth: '',
        vat: 0,
        billVatAmount: 0,
        totalBillAmount: 0,
        totalVatedAmount: 0,
        billVatAmountTzs: 0,
        totalBillAmountTzs: 0,
        billVatedAmountTzs: 0,
        amountPaid: 0
      },
      totalRevenueCfa: {
        yearMonth: '',
        vat: 0,
        billVatAmount: 0,
        totalBillAmount: 0,
        totalVatedAmount: 0,
        billVatAmountTzs: 0,
        totalBillAmountTzs: 0,
        billVatedAmountTzs: 0,
        amountPaid: 0
      },
      totalRevenueDc: {
        yearMonth: '',
        vat: 0,
        billVatAmount: 0,
        totalBillAmount: 0,
        totalVatedAmount: 0,
        billVatAmountTzs: 0,
        totalBillAmountTzs: 0,
        billVatedAmountTzs: 0,
        amountPaid: 0
      },
      totalRevenueThisYearMonthlyDc: [],
      totalRevenueThisYearMonthlyDcLoaded: false,
      totalRevenueThisYearMonthlyCfa: [],
      totalRevenueThisYearMonthlyCfaLoaded: false,
      totalRevenueYearly: [],

      // cfa requests  Ship icon 1F6A2
      totalRequests: {
        title: '',
        total: 0
      },
      totalRequestsImport: {
        title: '',
        total: 0
      },
      totalRequestsExport: {
        title: '',
        total: 0
      },
      totalRequestsYearMonthlyExp: [],
      totalRequestsYearMonthlyExpLoaded: false,
      totalRequestsYearMonthlyImp: [],
      totalRequestsYearMonthlyImpLoaded: false,
      totalRequestsYearlyImp: [],
      totalRequestsYearlyExp: []

    }
  },

  watch: {

    requestTypes: function (newRequestTypes, oldRequestTypes) {
      this.getTotalRequestByTypeMonthly()
    }

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Dashboard')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.getRequestTypes()
    this.getTotalDocumentsReceived()
    this.getTotalDocumentsReceivedMonthly()
    this.getTotalRevenueForAllYears()
    this.getTotalRevenueForYearMonthly()
    this.getTotalRequestsReceived()
    // this.getTotalBillRevenueForStatusAndIsCfa()
    this.loadRevenuePieChart()
    this.getTotalVessels()
  },

  methods: {



    getRequestTypes () {
      this.dashboardLoader = true

      API.getRequestType().then(responce => {
        if (responce.data.status) {
          this.requestTypes = responce.data.data
          this.dashboardLoader = false
        }
      })
    },

    getTotalDocumentsReceived () {
      this.dashboardLoader = true

      API.getTotalDocumentsReceived(1, false).then(responce => {
        if (responce.data.status) {
          this.totalDocumentsReceived = responce.data.data[0]
          this.dashboardLoader = false
        }
      })

      API.getTotalDocumentsReceived(0, false).then(responce => {
        if (responce.data.status) {
          this.totalDocumentsReceivedExp = responce.data.data[0]
          this.dashboardLoader = false
        }
      })
    },

    getTotalDocumentsReceivedMonthly () {
      this.dashboardLoader = true

      const newDate = new Date()

      let year = 0

      newDate.getUTCMonth() + 1 > 6 ? year = newDate.getFullYear() : year = newDate.getFullYear() - 1

      this.thisYear = year + 1

      const loadedImp = API.getTotalDocumentsReceivedMonthly(year, 1, false).then(response => {
        if (response.data.status) {
          this.totalDocumentsReceivedMonthlyImp = response.data.data
          this.dashboardLoader = false
        }
      })

      loadedImp.then(d => this.totalDocumentsReceivedMonthlyImpLoaded = true)

      const loadedExp = API.getTotalDocumentsReceivedMonthly(year, 0, false).then(response => {
        if (response.data.status) {
          this.totalDocumentsReceivedMonthlyExp = response.data.data
          this.dashboardLoader = false
        }
      })

      loadedExp.then(d => this.totalDocumentsReceivedMonthlyExpLoaded = true)
    },

    // Vessels

    getTotalVessels () {
      API.getTotalVesselArrived().then(responce => {
        if (responce.data.status) {
          if (responce.data.data.length > 0) {
            this.totalVessels = responce.data.data[0]
          }

          this.dashboardLoader = false
        }
      })
    },

    getTotalVesselArrivedGroupedYearly () {
      this.dashboardLoader = true

      API.getTotalVesselArrivedGroupedYearly().then(responce => {
        if (responce.data.status) {
          this.totalVesselsYearly = responce.data
          this.dashboardLoader = false
        }
      })
    },

    getTotalVesselArrivedMonthly () {
      this.dashboardLoader = true

      const newDate = new Date()

      let year = 0

      newDate.getUTCMonth() + 1 > 6 ? year = newDate.getFullYear() : year = newDate.getFullYear() - 1
      API.getTotalVesselArrivedMonthly(year).then(responce => {
        if (responce.data.status) {
          this.totalVesselsYearly = responce.data
          this.dashboardLoader = false
        }
      })
    },

    // Revenue methods
    getTotalRevenueForAllYears () {
      this.dashboardLoader = true

      API_REVENUE.getTotalRevenueDcPlusCfaAllYears('Paid').then(responce => {
        if (responce.data.status) {
          if (responce.data.data) {
            this.totalRevenue = responce.data.data
          }

          this.dashboardLoader = false
        }
      })
    },

    loadRevenuePieChart () {

      this.getTotalBillRevenueForStatusAndIsCfa().then((totalRevenueCfa, totalRevenueDc) => {

        this.chartDataRevenue.push(
          ['Module', 'Total'],
          ['CFA',     totalRevenueCfa],
          ['DC',      totalRevenueDc]
        )
        console.log('this.chartDataRevenue', this.chartDataRevenue)

        this.optionsRevenue = {
          width: 500,
          height: 250,
          is3D: false,
          title: "Total: " + ((totalRevenueDc + totalRevenueCfa).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        console.log('this.optionsRevenue', this.optionsRevenue)

      })

    },

    getTotalBillRevenueForStatusAndIsCfa () {

      return new Promise((resolve, reject) => {

        this.dashboardLoader = true

        API_REVENUE.getTotalBillRevenueForStatusAndIsCfa('Paid', true).then(responce => {
          if (responce.data.status) {
            if (responce.data.data) {
              this.totalRevenueCfa = responce.data.data.totalBillAmountTzs
            }
            console.log('this.totalRevenueCfa', this.totalRevenueCfa)
            this.dashboardLoader = false
            resolve(this.totalRevenueCfa)
          }
        }).catch(() => {reject()})

        API_REVENUE.getTotalBillRevenueForStatusAndIsCfa('Paid', false).then(responce => {
          if (responce.data.status) {
            if (responce.data.data) {
              this.totalRevenueDc = responce.data.data.totalBillAmountTzs
            }
            console.log('this.totalRevenueDc', this.totalRevenueDc)
            this.dashboardLoader = false
            resolve(this.totalRevenueDc)
          }
        }).catch(() => {reject()})

      });

    },

    getTotalRevenueForYearMonthly () {
      this.dashboardLoader = true

      const newDate = new Date()

      let year = 0

      newDate.getUTCMonth() + 1 > 6 ? year = newDate.getFullYear() : year = newDate.getFullYear() - 1

      const revenueDc = API_REVENUE.getTotalBillsMonthlyForYear(year, 'Paid', false).then(responce => {
        if (responce.data.status) {
          if (responce.data.data.length > 0) {
            this.totalRevenueThisYearMonthlyDc = responce.data.data
          }

          this.dashboardLoader = false
        }
      })

      revenueDc.then(r => this.totalRevenueThisYearMonthlyDcLoaded = true)

      const revenueCfa = API_REVENUE.getTotalBillsMonthlyForYear(year, 'Paid', true).then(responce => {
        if (responce.data.status) {
          if (responce.data.data.length > 0) {
            this.totalRevenueThisYearMonthlyCfa = responce.data.data
          }

          this.dashboardLoader = false
        }
      })

      revenueCfa.then(r => this.totalRevenueThisYearMonthlyCfaLoaded = true)
    },

    // CFA Requests
    getTotalRequestsReceived () {
      this.dashboardLoader = true

      API.getTotalRequestsReceived().then(responce => {
        if (responce.data.status) {
          if (Object.keys(responce.data.data).length > 0) {
            this.totalRequests = responce.data.data
          }

          this.dashboardLoader = false
        }
      })
    },

    getTotalRequestByTypeMonthly () {
      this.dashboardLoader = true

      if (this.requestTypes.length > 0) {
        const newDate = new Date()
        let year = 0

        newDate.getUTCMonth() + 1 > 6 ? year = newDate.getFullYear() : year = newDate.getFullYear() - 1

        for (let i = 0; i < this.requestTypes.length; i++) {
          const requestType = this.requestTypes[i]

          if (requestType.requestTypeFlag === 'IMP') {
            const requestImp = API.getTotalRequestByTypeMonthly(year, requestType.requestTypeId).then(responce => {
              if (responce.data.status) {
                this.totalRequestsYearMonthlyImp = responce.data.data
                this.dashboardLoader = false
              }
            })

            requestImp.then(rq => this.totalRequestsYearMonthlyImpLoaded = true)
          } else {
            const requestExp = API.getTotalRequestByTypeMonthly(year, requestType.requestTypeId).then(responce => {
              if (responce.data.status) {
                this.totalRequestsYearMonthlyExp = responce.data.data
                this.dashboardLoader = false
              }
            })

            requestExp.then(rq => this.totalRequestsYearMonthlyExpLoaded = true)
          }
        }
      }
    },

    getTotalRequestsByTypeYearly () {
      this.dashboardLoader = true

      if (this.requestTypes.length > 0) {
        for (let i = 0; i < this.requestTypes.length; i++) {
          const requestType = this.requestTypes[i]

          if (requestType.requestTypeFlag === 'IMP') {
            API.getTotalRequestsByTypeYearly(requestType.requestTypeId).then(responce => {
              if (responce.data.status) {
                this.totalRequestsYearlyImp = responce.data.data
                this.dashboardLoader = false
              }
            })
          } else {
            API.getTotalRequestsByTypeYearly(requestType.requestTypeId).then(responce => {
              if (responce.data.status) {
                this.totalRequestsYearlyExp = responce.data.data
                this.dashboardLoader = false
              }
            })
          }
        }
      }
    }

  }
}
</script>
<style scoped>
  .document::before{
    content: "\1F4D1";
  }
  .vessel::before{
    /* content: "\1F6A4"; */
    content: "\1F6A2";
  }
  .to-lower {
    text-transform: lowercase !important;
  }

  .to-lower::first-letter {
    text-transform: uppercase !important;
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  .widget {
    background: linear-gradient(to bottom right, #6fb5e3 20%, #3e70af);
    border-radius: 6px;
    box-shadow: 0 60px 80px -20px rgba(39 , 165 , 253 , 0.4);
    max-height: 120px;
    min-height: 120px;
  }

  .details{
    margin-left: 0;
  }

  .temperature {
    color: white;
    font-weight: 300;
    font-size: 18px;
    margin-top:10px;
  }
  .summary {
    color: #d2e9fa;
    font-weight: 300;
    border-bottom: 2px solid #9cd0ff;
    margin-left: 12px;
  }
  .summaryText {
    margin: 0 0 0 -32px;
    font-size: 22px;
  }
  .precipitation, .wind {
    color: #d2e9fa;
    font-size: 13px;
    font-weight: 300;
  }
  .precipitation {
    color: white;
  }
  .wind {
    color: white;
  }
</style>
