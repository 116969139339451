<template>
  <div>
    <card>
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="requests"
        :tableDataKeys="tableDataKeys"
        :myLoader="myLoader"
        :myFirstLoader="myFirstLoader"
        :noDataFound="noDataFound"
        :loadingTitle="'Please wait while system is loading discharge and loading tally charges'"
        :hasManageButton="false"
        :hasSearchForm="false"
        :buttonList="[{'code' : 'BillGenerate' , 'name' : 'View Charge' , 'class' : 'btn-primary'} ]"
        :isPageable="false"
        @goToBillGenerate="goToApplication">

      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">Loading, Please wait</div>
          <div class="col-md-12">
            <br/>
          </div>
          <div class="col-md-12">
            <pulse-loader :loading="loading" :color="color" :size="size"></pulse-loader>
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import PulseLoader from "vue-spinner/src/PulseLoader.vue";
  import GlobalModal from "../../../../components/globalLoader";
  import DataTable from "../../../../components/DataTable/updatedTable";

  export default {

    name: 'TallyCharges',

    components: {

      PulseLoader,
      GlobalModal,
      DataTable,

    },

    created() {

      this.$store.dispatch("pagenames/currentPageName", "Loading / Discharge Charges");

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
      this.loadPageableRequestOnCreated();

    },

    data() {

      return {

        color: "green",
        size: "12px",
        loading: true,

        noDataFound: false,
        myLoader: false,
        myFirstLoader: false,
        requests: [],

        finalUserPermissions: [],
        tableHeaders: ['Voyage', 'Vessel Name', 'Vessel Call ID', 'operation Type' , 'cargo Type', 'Created At'],
        tableDataKeys: ['voyage' , 'notification', 'vesselCallId', 'operationType' , 'cargoTypes', 'createdAt'],

      }

    },

    computed: {

      ...mapGetters({

        getRequestList: "tallybilling/getRequestList",
        getMyLoader: "tallybilling/getMyLoader",
        getMyFirstLoader: "tallybilling/getMyFirstLoader",
      }),

    },

    methods: {

      ...mapActions("tallybilling", [

        "getAllUnbilledTallyCharges",

      ]),

      setAllGetters(flag) {

        this.requests = this.getRequestList;
        flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
        flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
        this.noDataFound = this.getNoDataFound;

      },

      loadPageableRequestOnCreated() {

          this.getAllUnbilledTallyCharges({}).then(() => {

            this.setAllGetters('onCreated');

          }).catch(() => {


          });

          this.myFirstLoader = this.getMyFirstLoader;

      },

      goToApplication(chargeObject) {
        document.cookie = "browser-local-cashed-url-back=" + this.$route.fullPath + ";Path = /;SameSite = Strict";
        this.$router.push("/shipping-tallying/generate-bill/" + chargeObject.operationType+ "/" + chargeObject.id);

      }

    }

  };
</script>

<style scoped>

</style>
