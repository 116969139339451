var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{on:{"click":_vm.removeSearchParams}},[(_vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('div',{staticClass:"menu-title row",attrs:{"title":"Clearing and Forwarding - Import Applications"},on:{"click":function($event){return _vm.toggleMenu('im')}}},[_c('div',{staticClass:"col-md-10"},[_c('svg-icon',{staticClass:"nc-icon",attrs:{"icon":"cfa_import","has-fill":true}}),_c('span',{staticClass:"menu-title-name"},[_vm._v("CFA - IMPORT APPLICATIONS")])],1),_c('div',{staticClass:"col-md-2"},[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.openMenuImport),expression:"openMenuImport"}],staticClass:"nc-icon-page",attrs:{"icon":"down_arrow","has-fill":true}}),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.openMenuImport),expression:"!openMenuImport"}],staticClass:"nc-icon-page",attrs:{"icon":"right_arrow","has-fill":true}})],1)]):_vm._e(),_c('transition',{attrs:{"name":"list"}},[(_vm.openMenuImport)?_c('div',{staticClass:"menu-value"},[(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'MyAssignedActivity' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("My Assigned Activities")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-submitted' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("New")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-accepted' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Accepted")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-returned-for-major-changes' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Major Issue")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-returned-for-minor-changes' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Minor Issue")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-resubmitted-after-changes' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Returned")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-assessed' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Tariffed")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-assessment-rejected' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Rejected Tariff")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-charged' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Charged")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-charge-rejected' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Rejected Charges")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-billed' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Billed")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-assessment-sent-to-gepg' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Bill Approved")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-bill-paid' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Paid")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-is-dismissed' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Dismissed")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'all' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("All")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-closed' ,requestType:'import'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Closed")])],1):_vm._e()],1):_vm._e()]),(_vm.finalUserPermissions.includes('CFA_EXPORT'))?_c('div',{staticClass:"menu-title row",attrs:{"title":"Clearing and Forwarding - Export Applications"},on:{"click":function($event){return _vm.toggleMenu('ex')}}},[_c('div',{staticClass:"col-md-10"},[_c('svg-icon',{staticClass:"nc-icon",attrs:{"icon":"cfa_export","has-fill":true}}),_c('span',{staticClass:"menu-title-name"},[_vm._v("CFA - EXPORT APPLICATIONS")])],1),_c('div',{staticClass:"col-md-2"},[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.openMenuExport),expression:"openMenuExport"}],staticClass:"nc-icon-page",attrs:{"icon":"down_arrow","has-fill":true}}),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.openMenuExport),expression:"!openMenuExport"}],staticClass:"nc-icon-page",attrs:{"icon":"right_arrow","has-fill":true}})],1)]):_vm._e(),_c('transition',{attrs:{"name":"list"}},[(_vm.openMenuExport)?_c('div',{staticClass:"menu-value"},[(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_EXPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'MyAssignedActivity2' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("My Assigned Activities")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application', params: { status: 'request-submitted' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("New")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-accepted' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Accepted")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-returned-for-major-changes' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Major Issue")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-returned-for-minor-changes' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Minor Issue")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-resubmitted-after-changes' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Returned")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-assessed' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Tariffed")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-assessment-rejected' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Rejected Tariff")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-charged' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Charged")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-charge-rejected' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Rejected Charges")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-billed' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Billed")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-assessment-sent-to-gepg' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Bill Approved")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-bill-paid' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Paid")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-is-dismissed' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Dismissed")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'all' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("All")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_LIST_{REQUESTTYPEFLAG}_GET')
          && _vm.finalUserPermissions.includes('CFA_IMPORT'))?_c('sidebar-link',{attrs:{"to":{ name: 'application',params: { status: 'request-closed' ,requestType:'export'}}}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Closed")])],1):_vm._e()],1):_vm._e()]),(_vm.finalUserPermissions.includes('CFA_APPLICATIONS'))?_c('sidebar-link',{attrs:{"to":{ name: 'TASACNewApplication' }}},[_c('svg-icon',{staticClass:"not-inner-but-outer-icon",attrs:{"icon":"opened-folder","has-fill":true}}),_c('div',{staticClass:"menu-title-name-solo"},[_vm._v("NEW APPLICATION")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CFA_APPLICATIONS'))?_c('sidebar-link',{attrs:{"to":{ name: 'AllAssignedActivity' }}},[_c('svg-icon',{staticClass:"not-inner-but-outer-icon",attrs:{"icon":"icon-document","has-fill":true}}),_c('div',{staticClass:"menu-title-name-solo"},[_vm._v("ACTIVITY ASSIGNMENTS")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }