<template>
  <div class="row">
    <div class="col-md-12" v-if="!showUploadForm">
      <form>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="gepgControlNo">GEPG Control Number</label>
            <input type="text" class="form-control" id="gepgControlNo" v-model="refundData.gepgControlNo" placeholder="Control Number">
          </div>
          <div class="form-group col-md-6">
            <label for="bankReference">Bank Reference</label>
            <input type="text" class="form-control" id="bankReference" v-model="refundData.bankReference" placeholder="Reference Number">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="paymentMethod">Payment Method</label>
            <select id="paymentMethod" class="form-control"  v-model="refundData.paymentMethod">
              <option selected>Choose...</option>
              <option value="CASH">Cash</option>
              <option value="BANK">Bank</option>
              <option value="MOBILE">Mobile</option>
            </select>
            <div v-if="paymentMethodError">
              <span v-if="!$v.refundData.paymentMethod.required" class="text-danger" > This field is required </span>
            </div>
          </div>
          <div class="form-group col-md-8">
            <label for="invoiceNo">Invoice Number</label>
            <input type="text" class="form-control" id="invoiceNo" v-model="refundData.invoiceNo" placeholder="Invoice Number">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="recipient">Recipient</label>
            <input type="text" class="form-control" id="recipient" placeholder="Recipient" v-model="refundData.recipient" >
          </div>
          <div class="form-group col-md-6">
            <label for="total">Amount<strong>(USD)</strong></label>
            <input type="number" class="form-control" id="total" placeholder="Amount" v-model="refundData.total">
            <div v-if="totalError">
              <span v-if="!$v.refundData.total.required" class="text-danger" > This field is required </span>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="referenceNo">Reference No</label>
            <input type="text" class="form-control" id="referenceNo" v-model="refundData.referenceNo" placeholder="Reference No">
          </div>
          <div class="form-group col-md-4">
            <label for="transactionType">Transaction Type</label>
            <select id="transactionType" class="form-control" v-model="refundData.transactionType">
              <option selected>Choose...</option>
              <option value="Credit">Credit</option>
              <option value="Debit">Debit</option>
            </select>
            <div v-if="transactionTypeError">
              <span v-if="!$v.refundData.transactionType.required" class="text-danger" > This field is required </span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="description">Description</label>
          <textarea class="form-control" id="description" rows="3" v-model="refundData.description" placeholder="Description"></textarea>
          <div v-if="descriptionError">
            <span v-if="!$v.refundData.description.required" class="text-danger" > This field is required </span>
          </div>
        </div>
        <button type="submit" class="btn btn-primary float-right" @click.prevent="submit">Submit</button>
        <button type="submit" class="btn btn-default float-right mr-2" @click.prevent="closeModal">Close</button>
        <pulse-loader :color="color" :size="size1"  v-if="loading" class="float-left mr-4 pt-1"/>
      </form>
    </div>
    <div class="col-md-12" v-if="showUploadForm">
      <principal-refunds-attachments :disbursement-refund-id="disbursementRefundId"></principal-refunds-attachments>
    </div>
  </div>

</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import PulseLoader from "vue-spinner/src/PulseLoader";
  import AGENCYAPI from "../../../../api/agency";
  import principalRefundsAttachments from "../../principal/principalRefundsAttachments";

  export default {
        name: "subDisbersmentAdhock",


    components:{
      PulseLoader,
      principalRefundsAttachments,
    },

    props: {
      subDisbursementId:{
        type:String,
        required:true
      },
    },

    validations: {
      refundData:{
        description: {required},
        paymentMethod: {required},
        total: {required},
        transactionType: {required},
      }
    },



      data(){

          return{

            refundData:{
              bankReference: "",
              description: "",
              gepgControlNo: "",
              invoiceNo: "",
              paymentMethod: "",
              recipient: "",
              referenceNo: "",
              total: "",
              transactionType: "",
              subDisbursementId:this.subDisbursementId,
              vat: 0
            },
            disbursementRefundId:null,
            showUploadForm:false,
            color: 'green',
            size: '12px',
            size1: '9px',
            loading: false,

            descriptionError: false,
            paymentMethodError: false,
            totalError: false,
            transactionTypeError: false,

          }
      },

    methods:{

      submit(){
        if(
          this.refundData.description==="" &&
          this.refundData.paymentMethod==="" &&
          this.refundData.total==="" &&
          this.refundData.transactionType===""
        ){
          this.descriptionError=true;
          this.paymentMethodError=true;
          this.totalError=true;
          this.transactionTypeError=true;

        }else {
          this.descriptionError=false;
          this.paymentMethodError=false;
          this.totalError=false;
          this.transactionTypeError=false;

          this.loading=true;
          //this.showUploadForm=true;
          AGENCYAPI.refundAdd(this.refundData).then((resp)=>{
            this.loading=false;

            this.refundData={
              bankReference: "",
                description: "",
                gepgControlNo: "",
                invoiceNo: "",
                paymentMethod: "",
                recipient: "",
                referenceNo: "",
                total: "",
                transactionType: "",
                vat: 0
            };
            this.disbursementRefundId=resp.data.data.disbursementRefundId;

            this.loadNotification('success', 4000, 'Success',
              'Refund Added',
              'response', 1000 , true  , true);
          }).catch((err)=>{
            this.loading=false;
            this.loadNotification('error', 4000, 'Failed to Add Refund',
              'Failed Save Refund,please Consult System Admin',
              'response', 1000 , true  , true)
          });

        }

      },

      closeModal(){
        this.showUploadForm=false;
        this.$emit('closemodal')
      }


    }
    }
</script>

<style scoped>

</style>
