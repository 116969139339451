<template>
  <div>
    <card style="font-size: 12px" v-if="!thisFirstRequestLoader">
      <div class="row">
        <div class="col-md-12">
          <ul
            class="nav-justified"
            style="padding-left: 10px;padding-right: 10px"
          >
            <li
              v-if="finalUserPermissions.includes('DOCUMENTS_MANIFESTEXCEL_{DOCUMENTID}_GET')
                || finalUserPermissions.includes('DOCUMENTS_DISCHARGELISTEXCEL_{DOCUMENTID}_GET')
                || finalUserPermissions.includes('DOCUMENTS_BAYPLANEXCEL_{DOCUMENTID}_GET')
                || finalUserPermissions.includes('DOCUMENTS_DANGEROUSGOODEXCEL_{DOCUMENTID}_GET')"
            >
              <button
                @click="downloadExcel(messageType , documentId , singleDocument.tasacControlNumber)"
                class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn"
              >
                <font-awesome-icon
                  icon="file-excel"
                  class="file-excel"
                /> &nbsp; Download Excel
              </button>
            </li>

            <li
              v-if="messageType === 'COPRAR'
                && finalUserPermissions.includes('DOCUMENTS_DISCHARGELISTPDF_{DOCUMENTID}_GET')"
            >
              <button
                @click="downloadPdf(messageType , documentId)"
                class="btn btn-olive btn-olive-fill mr-2 custom-btn"
              >
                <font-awesome-icon
                  icon="file-pdf"
                  class="file-pdf"
                /> &nbsp; Download PDF
              </button>
            </li>

            <li v-if="finalUserPermissions.includes('EDIMESSAGE_CONFIG_ALL_{CONTROLREFERENCE}_GET')">
              <button
                @click="sendMail(documentId , singleDocument.carrierName , 'send')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="envelope" /> &nbsp; Send as Mail
              </button>
            </li>

            <li v-if="finalUserPermissions.includes('EDIMESSAGE_CONFIG_ALL_{CONTROLREFERENCE}_GET')">
              <button
                @click="sendMail(documentId , singleDocument.carrierName , 'resend')"
                class="btn btn-blue btn-blue-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="envelope" /> &nbsp; Resend as Mail
              </button>
            </li>

            <li v-if="messageType === 'CUSCAR'">
              <button
                @click="openModal(singleDocument , 'EDI_MESSAGE_HISTORY')"
                class="btn btn-purple btn-purple-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="history" /> &nbsp; EDI Message History
              </button>
            </li>

            <li
              v-if="messageType === 'CUSCAR'
                && finalUserPermissions.includes('MANIFESTS_SPECIFIED_{DOCUMENTID}_GET')"
            >
              <button
                @click="openModal(singleDocument , 'SPECIFIED_GOODS')"
                class="btn btn-linen btn-linen-fill mr-2 custom-btn"
              >
                <font-awesome-icon icon="luggage-cart" /> &nbsp; Specified Goods
              </button>
            </li>
          </ul>
        </div>
      </div>
    </card>

    <card style="font-size: 12px">
      <div
        class="row"
        style="padding: 0 15px 0 15px"
      >
        <div
          class="col-md-12 alert-dismissible fade show"
          role="alert"
          :class="[ SuccessAlertTop ? 'alert-success' : '' , ErrorAlertTop ? 'alert-danger' : '' ]"
          style="border-radius:5px;"
          @enter="enterTop"
        >
          <strong v-if="SuccessAlertTop">Successfully!</strong> <span
            v-if="SuccessAlertTop"
          >{{ SuccessMessageTop }}</span>
          <strong v-if="ErrorAlertTop">Error!</strong> <span v-if="ErrorAlertTop">{{ ErrorMessageTop }}</span>

          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            v-if="SuccessAlertTop"
            style="line-height:2px;"
            @click="fadeMeTop"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            v-if="ErrorAlertTop"
            style="line-height:2px;"
            @click="fadeMeTop"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <br>
      <div
        class="row"
        v-if="thisFirstRequestLoader"
      >
        <div class="col-md-12 text-center">
          <b>Please wait while system is loading document details</b>
        </div>
        <div class="col-md-12 text-center">
          <br>
        </div>
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
      <div class="row" v-if="!thisFirstRequestLoader">
        <div class="col-md-12">
          <div class="legend">
            <h6 class="legend-title">
              Document Details
            </h6>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                TASAC Control Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.tasacControlNumber }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Message Name
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ messageName }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Sender
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.sender }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Receiver
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.receiver }}
              </div>
            </div>

            <br />

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Carrier Name
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.carrierName }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Received Date
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ dateTimeToHuman(singleDocument.messageCreatedDate) }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Ship Name
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.shipName }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Voyage Number
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.voyageNumber }}
              </div>
            </div>

            <br>

            <div class="row div-table-row">
              <div class="col-md-3 div-table div-table-title">
                Message Function
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.messageFunction }}
              </div>
              <div class="col-md-3 div-table div-table-title">
                Call Sign
              </div>
              <div class="col-md-3 div-table div-table-value">
                {{ singleDocument.callSign }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <br v-if="!thisFirstRequestLoader && !showCreateCharge">
      <br v-if="!thisFirstRequestLoader && showCreateCharge">
      <div
        class="row"
        v-if="!thisFirstRequestLoader && !showCreateCharge && !showMoreDetails"
      >
        <div class="col-md-12 text-right">
          <button
            type="submit"
            class="btn btn-primary mr-2 custom-btn"
            data-dismiss="modal"
            @click="showCreateChargeForm('show')"
            v-if="messageType === 'CUSCAR' && finalUserPermissions.includes('BILLS_GENERATE_{DOCUMENTID}_GET')"
          >
            <font-awesome-icon icon="file-invoice-dollar" /> &nbsp; Create Charge
          </button>
          <button
            type="submit"
            class="btn btn-default mr-2 custom-btn"
            data-dismiss="modal"
            @click="showOtherDetailsCard(messageType , 'show')"
            v-if="finalUserPermissions.includes('BAYPLAN_CONFIG_PAGEABLE_{DOCUMENTID}_GET')
              || finalUserPermissions.includes('MANIFESTS_CONFIG_SINGLE_PAGEABLE_{DOCUMENTID}_GET') ||
              finalUserPermissions.includes('DISCHARGELISTS_CONFIG_SINGLE_PAGEABLE_{DOCUMENTID}_GET') ||
              finalUserPermissions.includes('DANGEROUSGOODS_CONFIG_ALL_PAGEABLE_{DOCUMENTID}_GET')"
          >
            <font-awesome-icon icon="info-circle" /> &nbsp; More {{ messageName }} Details
          </button>
          <button
            type="submit"
            class="btn btn-default btn-fill mr-2 custom-btn-all"
            data-dismiss="modal"
            @click="goBack"
          >Back 
          </button>
        </div>
      </div>

      <div
        class="row"
        v-if="!thisFirstRequestLoader && showCreateCharge"
        style="padding-right: 10px;padding-left: 10px"
      >
        <div class="col-md-12">
          <form class="form">
            <div class="row">
              <div class="col-md-6 form-group">
                <label>Search Tariff Item</label>
                <model-list-select
                  :list="tariffItems"
                  v-model="tariffItemIdValue"
                  class="form-control"
                  option-value="tariffItemId"
                  option-text="tariffItemName"
                  placeholder="Search Tariff Item"
                />
                <div v-if="tariffItemNameError">
                  <div
                    class="text-danger"
                    v-if="!$v.tariffItemIdValue.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label>Search Tariff Fee</label>
                <model-list-select
                  :list="tariffFee"
                  v-model="tariffFeeIdValue"
                  class="form-control"
                  option-value="tariffFeeId"
                  option-text="tariffFeeName"
                  placeholder="Search Tariff Fee"
                />
                <div v-if="tariffFeeError">
                  <div
                    class="text-danger"
                    v-if="!$v.tariffFeeIdValue.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <hr>
              <div class="col-md-12 text-right">
                <div class="row">
                  <div class="col-md-8 pt-3">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="createManifestChargeLoader"
                    />
                  </div>
                  <div class="col-md-4">
                    <button
                      type="submit"
                      class="btn btn-primary mr-2"
                      data-dismiss="modal"
                      @click.prevent="createManifestCharge"
                      v-if="messageType === 'CUSCAR' "
                    >
                      <font-awesome-icon icon="file-invoice-dollar" /> &nbsp; Create Charge
                    </button>
                    <button
                      type="submit"
                      class="btn btn-default btn-fill"
                      data-dismiss="modal"
                      @click="showCreateChargeForm('hide')"
                    >
                      Close Form
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        class="row"
        v-if="!thisFirstRequestLoader"
        style="padding-right: 10px;padding-left: 10px"
      />
    </card>

    <card v-if="showMoreDetails">
      <div
        class="row"
        v-if="messageLoader"
      >
        <div class="col-md-12 text-center">
          <pulse-loader
            :loading="loading"
            :color="color"
            :size="size"
          />
        </div>
      </div>
      <div
        class="row"
        v-if="!messageLoader"
      >
        <div class="col-md-12 text-right">
          <button
            type="submit"
            class="btn btn-default btn-fill mr-2"
            data-dismiss="modal"
            @click="showOtherDetailsCard(null , 'hide')"
          >
            Close {{ messageName }} Details
          </button>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6 float-left">
              <div class="row">
                <div class="col-md-3 text-left">
                  Entries per page
                </div>

                <div class="col-md-2 float-left">
                  <select
                    name="PageSize"
                    @change="onChange($event)"
                    class=""
                    v-model="pageSize"
                    style="width:73px;"
                  >
                    <option value="15">
                      15
                    </option>
                    <option value="25">
                      25
                    </option>
                    <option value="50">
                      50
                    </option>
                    <option value="75">
                      75
                    </option>
                    <option value="100">
                      100
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-2 text-left">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="myloader"
              />
            </div>

            <div
              class="col-md-4 text-right"
              style="padding-right: 0"
            >
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First</span>
                <span
                  class="btn-space"
                  :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                ><font-awesome-icon
                  icon="angle-double-left"
                /></span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                ><font-awesome-icon
                  icon="angle-double-right"
                /></span>
                <span
                  class="btn-space"
                  :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <hr>
        </div>
      </div>

      <div
        class="row"
        style="overflow-y:auto"
        v-if="!messageLoader"
      >
        <table
          class="table table-striped"
          v-if="messageType ==='CUSCAR' "
        >
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                billOfLading
              </th>
              <th scope="col">
                masterBillOfLading
              </th>
              <th scope="col">
                sealNumber
              </th>
              <th scope="col">
                containerNo
              </th>
              <th scope="col">
                marksNumbers
              </th>
              <th scope="col">
                loadPort
              </th>
              <th scope="col">
                arrivalPort
              </th>
              <th scope="col">
                dischargePort
              </th>
              <th scope="col">
                deliveryPort
              </th>
              <th scope="col">
                originalLoadPort
              </th>
              <th scope="col">
                destinationPlace
              </th>
              <th scope="col">
                temperature
              </th>
              <th scope="col">
                measurement
              </th>
              <th scope="col">
                dimensions
              </th>
              <th scope="col">
                description
              </th>
              <th scope="col">
                numberOfPackage
              </th>
              <th scope="col">
                packageType
              </th>
              <th scope="col">
                documentMessageNo
              </th>
              <th scope="col">
                consolidationItemNumber
              </th>
              <th scope="col">
                blType
              </th>
              <th scope="col">
                operationType
              </th>
              <th scope="col">
                consignee
              </th>
              <th scope="col">
                notifyName
              </th>
              <th scope="col">
                grossWeight
              </th>
              <th scope="col">
                weightUnit
              </th>
              <th scope="col">
                netWeight
              </th>
              <th scope="col">
                grossVolume
              </th>
              <th scope="col">
                grossVolumeUnit
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in getManifestDetails"
              :key="list.manifestBlId"
            >
              <th scope="row">
                {{ index + 1 +(pageNo * pageSize) }}
              </th>
              <td>{{ list.billOfLading }}</td>
              <td>{{ list.masterBillOfLading }}</td>
              <td>{{ list.sealNumber }}</td>
              <td>{{ list.containerNo }}</td>
              <td>{{ list.marksNumbers }}</td>
              <td>{{ list.loadPort }}</td>
              <td>{{ list.arrivalPort }}</td>
              <td>{{ list.dischargePort }}</td>
              <td>{{ list.deliveryPort }}</td>
              <td>{{ list.originalLoadPort }}</td>
              <td>{{ list.destinationPlace }}</td>
              <td>{{ list.temperature }}</td>
              <td>{{ list.measurement }}</td>
              <td>{{ list.dimensions }}</td>
              <td>{{ list.description }}</td>
              <td>{{ list.numberOfPackage }}</td>
              <td>{{ list.packageType }}</td>
              <td>{{ list.documentMessageNo }}</td>
              <td>{{ list.consolidationItemNumber }}</td>
              <td>{{ list.blType }}</td>
              <td>{{ list.operationType }}</td>
              <td>{{ list.consignee }}</td>
              <td>{{ list.notifyName }}</td>
              <td>{{ list.grossWeight }}</td>
              <td>{{ list.weightUnit }}</td>
              <td>{{ list.netWeight }}</td>
              <td>{{ list.grossVolume }}</td>
              <td>{{ list.grossVolumeUnit }}</td>
            </tr>
          </tbody>
        </table>

        <table
          class="table table-striped"
          v-if="messageType ==='COPRAR' "
        >
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                container No
              </th>
              <th scope="col">
                Iso Code
              </th>
              <th scope="col">
                Full Empty
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                Partner
              </th>
              <th scope="col">
                Weight
              </th>
              <th scope="col">
                Booking
              </th>
              <th scope="col">
                Reference
              </th>
              <th scope="col">
                Load Port
              </th>
              <th scope="col">
                Discharge Port
              </th>
              <th scope="col">
                Delivery Port
              </th>
              <th scope="col">
                dgHzd No
              </th>
              <th scope="col">
                dgUn No
              </th>
              <th scope="col">
                Dg Regulation
              </th>
              <th scope="col">
                Dg PackageDgLevel
              </th>
              <th scope="col">
                Dg ExtraInfo
              </th>
              <th scope="col">
                Reference Temp
              </th>
              <th scope="col">
                Stowage Cell
              </th>
              <th scope="col">
                Dimensions
              </th>
              <th scope="col">
                Description
              </th>
              <th scope="col">
                Bill Of Lading
              </th>
              <th scope="col">
                MasterBill Of Lading
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in getDischargeListDetails"
              :key="list.dischargeListId"
            >
              <th scope="row">
                {{ index + 1 +(pageNo * pageSize) }}
              </th>
              <td>{{ list.containerNo }}</td>
              <td>{{ list.isoCode }}</td>
              <td>{{ list.fullEmpty }}</td>
              <td>{{ list.status }}</td>
              <td>{{ list.partner }}</td>
              <td>{{ list.weight }}</td>
              <td>{{ list.booking }}</td>
              <td>{{ list.reference }}</td>
              <td>{{ list.loadPort }}</td>
              <td>{{ list.dischargePort }}</td>
              <td>{{ list.deliveryPort }}</td>
              <td>{{ list.dgHzdNo }}</td>
              <td>{{ list.dgUnNo }}</td>
              <td>{{ list.dgRegulation }}</td>
              <td>{{ list.dgPackageDgLevel }}</td>
              <td>{{ list.dgExtraInfo }}</td>
              <td>{{ list.referenceTemp }}</td>
              <td>{{ list.stowageCell }}</td>
              <td>{{ list.dimensions }}</td>
              <td>{{ list.description }}</td>
              <td>{{ list.billOfLading }}</td>
              <td>{{ list.masterBillOfLading }}</td>
            </tr>
          </tbody>
        </table>

        <table
          class="table table-striped"
          v-if="messageType ==='IFTDGN' "
        >
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                container No
              </th>
              <th scope="col">
                Num Of Packages
              </th>
              <th scope="col">
                Iso Code
              </th>
              <th scope="col">
                Full Empty
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col">
                Marks Numbers
              </th>
              <th scope="col">
                Load Port
              </th>
              <th scope="col">
                Discharge Port
              </th>
              <th scope="col">
                Delivery Port
              </th>
              <th scope="col">
                DgHzdNo
              </th>
              <th scope="col">
                DgUnNo
              </th>
              <th scope="col">
                Dg Regulation
              </th>
              <th scope="col">
                Dg Package Level
              </th>
              <th scope="col">
                Dg Extra Info
              </th>
              <th scope="col">
                Stowage Cell
              </th>
              <th scope="col">
                Dimensions
              </th>
              <th scope="col">
                Description
              </th>
              <th scope="col">
                Bill Of Lading
              </th>
              <th scope="col">
                Master Bill Of Lading
              </th>
              <th scope="col">
                Gross Weight
              </th>
              <th scope="col">
                Net Weight
              </th>
              <th scope="col">
                Net Explosive Content
              </th>
              <th scope="col">
                Emergency Procedure No
              </th>
              <th scope="col">
                Type Of Package
              </th>
              <th scope="col">
                Booking No
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in getDangerousGoodsPageableByDocId"
              :key="list.dangerousGoodsId"
            >
              <th scope="row">
                {{ index + 1 +(pageNo * pageSize) }}
              </th>
              <td>{{ list.containerNo }}</td>
              <td>{{ list.numOfPackages }}</td>
              <td>{{ list.isoCode }}</td>
              <td>{{ list.fullEmpty }}</td>
              <td>{{ list.status }}</td>
              <td>{{ list.marksNumbers }}</td>
              <td>{{ list.loadPort }}</td>
              <td>{{ list.dischargePort }}</td>
              <td>{{ list.deliveryPort }}</td>
              <td>{{ list.dgHzdNo }}</td>
              <td>{{ list.dgUnNo }}</td>
              <td>{{ list.dgRegulation }}</td>
              <td>{{ list.dgPackageLevel }}</td>
              <td>{{ list.dgExtraInfo }}</td>
              <td>{{ list.stowageCell }}</td>
              <td>{{ list.dimensions }}</td>
              <td>{{ list.description }}</td>
              <td>{{ list.billOfLading }}</td>
              <td>{{ list.masterBillOfLading }}</td>
              <td>{{ list.grossWeight }}</td>
              <td>{{ list.netWeight }}</td>
              <td>{{ list.netExplosiveContent }}</td>
              <td>{{ list.emergencyProcedureNo }}</td>
              <td>{{ list.typeOfPackage }}</td>
              <td>{{ list.bookingNo }}</td>
            </tr>
          </tbody>
        </table>

        <table
          class="table table-striped"
          v-if="messageType ==='BAPLIE'"
        >
          <thead>
            <tr>
              <th scope="col">
                #
              </th>
              <th scope="col">
                container No
              </th>
              <th scope="col">
                Iso Code
              </th>
              <th scope="col">
                Full Empty
              </th>
              <th scope="col">
                Weight
              </th>
              <th scope="col">
                Partner
              </th>
              <th scope="col">
                Load Port
              </th>
              <th scope="col">
                Discharge Port
              </th>
              <th scope="col">
                Delivery Port
              </th>
              <th scope="col">
                Reference Temp
              </th>
              <th scope="col">
                Stowage Cell
              </th>
              <th scope="col">
                Description
              </th>
              <th scope="col">
                Bill Of Lading
              </th>
              <th scope="col">
                Master Bill Of Lading
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(list, index) in getBayPlanPageableByDocId"
              :key="list.BayPlanId"
            >
              <th scope="row">
                {{ index + 1 +(pageNo * pageSize) }}
              </th>
              <td>{{ list.containerNo }}</td>
              <td>{{ list.isoCode }}</td>
              <td>{{ list.fullEmpty }}</td>
              <td>{{ list.weight }}</td>
              <td>{{ list.partner }}</td>
              <td>{{ list.loadPort }}</td>
              <td>{{ list.dischargePort }}</td>
              <td>{{ list.deliveryPort }}</td>
              <td>{{ list.referenceTemp }}</td>
              <td>{{ list.stowageCell }}</td>
              <td>{{ list.description }}</td>
              <td>{{ list.billOfLading }}</td>
              <td>{{ list.masterBillOfLading }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3">
              Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
            </div>

            <div
              class="col-md-9 text-right disable-highlight"
              unselectable="on"
              style="line-height: 5px;font-size: 20px"
            >
              <span
                class="btn-space"
                :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="firstPage"
                title="go to first page"
              >First</span>
              <span
                class="btn-space"
                :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="prevPage"
                title="go to previous page"
              ><font-awesome-icon
                icon="angle-double-left"
              /></span>

              <span class="btn-space" />
              <span class="btn-space" />

              <span
                class="btn-space"
                :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="nextPage"
                title="go to next page"
              ><font-awesome-icon
                icon="angle-double-right"
              /></span>
              <span
                class="btn-space"
                :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                @click="lastPage"
                title="go to last page"
              >Last</span>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div
        class="row"
        v-if="!messageLoader"
      >
        <div class="col-md-12 text-right">
          <button
            type="submit"
            class="btn btn-default btn-fill mr-2"
            data-dismiss="modal"
            @click="showOtherDetailsCard(null , 'hide')"
          >
            Close {{ messageName }} Details
          </button>
        </div>
      </div>
    </card>

    <global-modal v-if="globalLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Downloading requested {{ globalLoaderName }}, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>

    <modal-manifest v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="showEdiMessageHistory"
          >
            EDI Message History for TASAC Control Number [ <b>{{ this.headerControlRef }}</b> ]
          </div>
          <div
            class="col-md-12"
            v-if="showSpecifiedGoods"
          >
            Specified Goods for Manifest with TASAC Control Number [ <b>{{ this.headerControlRef }}</b> ]
          </div>
        </div>
      </div>

      <div slot="body">
        <card v-if="showEdiMessageHistory">
          <form
            method="POST"
            @submit.prevent="editEDIMessage"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">
                    #
                  </th>
                  <th scope="col">
                    Message Date
                  </th>
                  <th scope="col">
                    Carrier Name
                  </th>
                  <th scope="col">
                    Control Reference
                  </th>
                  <th scope="col">
                    Ship Name
                  </th>
                  <th scope="col">
                    Message Type
                  </th>
                  <th scope="col">
                    Sender
                  </th>
                  <th scope="col">
                    Departure Date
                  </th>
                  <th scope="col">
                    Arrival Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(list, index) in ediMessageByControlRef"
                  :key="list.EdiMessageId"
                >
                  <th scope="row">
                    {{ index + 1 }}
                  </th>
                  <td>
                    {{ list.messageDate }}
                  </td>
                  <td>
                    {{ list.carrierName }}
                  </td>
                  <td>
                    {{ list.controlReference }}
                  </td>

                  <td>
                    {{ list.shipName }}
                  </td>
                  <td>
                    {{ list.messageType }}
                  </td>

                  <td>
                    {{ list.sender }}
                  </td>

                  <td>
                    {{ list.arrivalDate }}
                  </td>

                  <td>
                    {{ list.departureDate }}
                  </td>
                </tr>
              </tbody>
            </table>

            <hr>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div" />
              <div class="col-md-2 left loader-div-gif" />
              <div class="col-md-3">
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </card>
        <card v-if="showSpecifiedGoods">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6 float-left">
                  <div class="row">
                    <div class="col-md-3 text-left">
                      Entries per page
                    </div>

                    <div class="col-md-2 float-left">
                      <select
                        name="PageSize"
                        @change="onChange($event)"
                        class=""
                        v-model="pageSize"
                        style="width:73px;"
                      >
                        <option value="15">
                          15
                        </option>
                        <option value="25">
                          25
                        </option>
                        <option value="50">
                          50
                        </option>
                        <option value="75">
                          75
                        </option>
                        <option value="100">
                          100
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-2 text-left">
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                    v-if="myloader"
                  />
                </div>

                <div
                  class="col-md-4 text-right"
                  style="padding-right: 0"
                >
                  <div
                    class="col-md-12 disable-highlight"
                    unselectable="on"
                    style="font-size: 20px"
                  >
                    <span
                      class="btn-space"
                      :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                      @click="firstPage"
                      title="go to first page"
                    >First</span>
                    <span
                      class="btn-space"
                      :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                      @click="prevPage"
                      title="go to previous page"
                    ><font-awesome-icon
                      icon="angle-double-left"
                    /></span>

                    <span class="btn-space" />
                    <span class="btn-space" />

                    <span
                      class="btn-space"
                      :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                      @click="nextPage"
                      title="go to next page"
                    ><font-awesome-icon
                      icon="angle-double-right"
                    /></span>
                    <span
                      class="btn-space"
                      :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                      @click="lastPage"
                      title="go to last page"
                    >Last</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <hr>
            </div>
          </div>
          <div style="overflow-y:auto">
            <table
              class="table table-striped"
              v-if="!noDataFound"
            >
              <thead>
                <tr>
                  <th scope="col">
                    #
                  </th>
                  <th scope="col">
                    billOfLading
                  </th>
                  <th scope="col">
                    masterBillOfLading
                  </th>
                  <th scope="col">
                    sealNumber
                  </th>
                  <th scope="col">
                    containerNo
                  </th>
                  <th scope="col">
                    marksNumbers
                  </th>
                  <th scope="col">
                    loadPort
                  </th>
                  <th scope="col">
                    arrivalPort
                  </th>
                  <th scope="col">
                    dischargePort
                  </th>
                  <th scope="col">
                    deliveryPort
                  </th>
                  <th scope="col">
                    originalLoadPort
                  </th>
                  <th scope="col">
                    destinationPlace
                  </th>
                  <th scope="col">
                    temperature
                  </th>
                  <th scope="col">
                    measurement
                  </th>
                  <th scope="col">
                    dimensions
                  </th>
                  <th scope="col">
                    description
                  </th>
                  <th scope="col">
                    numberOfPackage
                  </th>
                  <th scope="col">
                    packageType
                  </th>
                  <th scope="col">
                    documentMessageNo
                  </th>
                  <th scope="col">
                    consolidationItemNumber
                  </th>
                  <th scope="col">
                    blType
                  </th>
                  <th scope="col">
                    operationType
                  </th>
                  <th scope="col">
                    consignee
                  </th>
                  <th scope="col">
                    notifyName
                  </th>
                  <th scope="col">
                    grossWeight
                  </th>
                  <th scope="col">
                    weightUnit
                  </th>
                  <th scope="col">
                    netWeight
                  </th>
                  <th scope="col">
                    grossVolume
                  </th>
                  <th scope="col">
                    grossVolumeUnit
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(list, index) in manifestSpecifiedGoods"
                  :key="list.manifestBlId"
                >
                  <th scope="row">
                    {{ index + 1 +(pageNo * pageSize) }}
                  </th>
                  <td>{{ list.billOfLading }}</td>
                  <td>{{ list.masterBillOfLading }}</td>
                  <td>{{ list.sealNumber }}</td>
                  <td>{{ list.containerNo }}</td>
                  <td>{{ list.marksNumbers }}</td>
                  <td>{{ list.loadPort }}</td>
                  <td>{{ list.arrivalPort }}</td>
                  <td>{{ list.dischargePort }}</td>
                  <td>{{ list.deliveryPort }}</td>
                  <td>{{ list.originalLoadPort }}</td>
                  <td>{{ list.destinationPlace }}</td>
                  <td>{{ list.temperature }}</td>
                  <td>{{ list.measurement }}</td>
                  <td>{{ list.dimensions }}</td>
                  <td>{{ list.description }}</td>
                  <td>{{ list.numberOfPackage }}</td>
                  <td>{{ list.packageType }}</td>
                  <td>{{ list.documentMessageNo }}</td>
                  <td>{{ list.consolidationItemNumber }}</td>
                  <td>{{ list.blType }}</td>
                  <td>{{ list.operationType }}</td>
                  <td>{{ list.consignee }}</td>
                  <td>{{ list.notifyName }}</td>
                  <td>{{ list.grossWeight }}</td>
                  <td>{{ list.weightUnit }}</td>
                  <td>{{ list.netWeight }}</td>
                  <td>{{ list.grossVolume }}</td>
                  <td>{{ list.grossVolumeUnit }}</td>
                </tr>
              </tbody>
            </table>
            <div
              class="col-md-12 text-center bg-grey"
              v-if="noDataFound"
            >
              No data found
            </div>
          </div>

          <hr>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-3">
                  Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
                </div>

                <div
                  class="col-md-9 text-right disable-highlight"
                  unselectable="on"
                  style="line-height: 5px;font-size: 20px"
                >
                  <span
                    class="btn-space"
                    :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="firstPage"
                    title="go to first page"
                  >First</span>
                  <span
                    class="btn-space"
                    :class="[ pageNo === 0 || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="prevPage"
                    title="go to previous page"
                  ><font-awesome-icon
                    icon="angle-double-left"
                  /></span>

                  <span class="btn-space" />
                  <span class="btn-space" />

                  <span
                    class="btn-space"
                    :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="nextPage"
                    title="go to next page"
                  ><font-awesome-icon
                    icon="angle-double-right"
                  /></span>
                  <span
                    class="btn-space"
                    :class="[ pageCount - 1 === pageNo || pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                    @click="lastPage"
                    title="go to last page"
                  >Last</span>
                </div>
              </div>
            </div>
          </div>
          <hr>

          <div class="text-center row">
            <div class="col-md-7 text-right loader-div" />
            <div class="col-md-2 left loader-div-gif" />
            <div class="col-md-3">
              <button
                type="button"
                class="btn btn-fill btn-outline-info float-right"
                @click="closeModal"
              >
                Close
              </button>
            </div>
          </div>
        </card>
      </div>
    </modal-manifest>
  </div>
</template>

<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import API from '../../../api/index'
import API_REVENUE from '../../../api/rev'
import API_DC from '../../../api/dc'
import GlobalModal from '../../../components/globalLoader'
import ModalManifest from '../../../components/Inputs/ModalManifest'
import { ModelListSelect } from 'vue-search-select'
import { required } from 'vuelidate/lib/validators'

export default {

  name: 'Document',

  components: {

    PulseLoader,
    GlobalModal,
    ModelListSelect,
    ModalManifest

  },

  data () {
    return {

      finalUserPermissions: [],

      color: 'green',
      size: '12px',
      loading: true,

      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
      ErrorMessage: '',

      singleDocument: {},

      SuccessAlertTop: false,
      ErrorAlertTop: false,
      SuccessMessageTop: '',
      ErrorMessageTop: '',
      messageName: '',
      messageType: '',
      documentId: '',
      thisFirstRequestLoader: false,

      globalLoader: false,
      globalLoaderName: '',

      tariffItemIdValue: '',
      tariffFeeIdValue: '',
      tariffItems: [],
      tariffFee: [],
      showCreateCharge: false,
      tariffItemNameError: false,
      tariffFeeError: false,
      createManifestChargeLoader: false,

      ediMessageByControlRef: [],
      headerControlRef: '',
      showModal: false,
      showEdiMessageHistory: false,
      manifestSpecifiedGoods: [],
      myloader: false,

      showSpecifiedGoods: false,
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      noDataFound: false,
      showMoreDetails: false,
      getManifestDetails: [],
      getDischargeListDetails: [],
      getBayPlanDetails: [],
      getDangerousGoodsDetails: [],
      messageLoader: false

    }
  },

  created () {

    this.$store.dispatch('pagenames/currentPageName', this.$route.params.flag.charAt(0).toUpperCase() + this.$route.params.flag.slice(1).toLowerCase() + ' Document Details')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    if (this.$route.params.documentId && this.$route.params.documentType) {
        this.documentId = this.$route.params.documentId

        if ((this.$route.params.documentType).toUpperCase() === 'CUSCAR') {
          this.messageName = 'Manifest'
          this.messageType = this.$route.params.documentType
        } else if ((this.$route.params.documentType).toUpperCase() === 'BAPLIE') {
          this.messageName = 'Bay Plan'
          this.messageType = this.$route.params.documentType
        } else if ((this.$route.params.documentType).toUpperCase() === 'IFTDGN') {
          this.messageName = 'Dangerous Goods'
          this.messageType = this.$route.params.documentType
        } else if ((this.$route.params.documentType).toUpperCase() === 'COPRAR') {
          this.messageName = 'Discharge List'
          this.messageType = this.$route.params.documentType
        } else {

        }

        this.loadThisDocumentOnCreated(this.$route.params.documentId, this.$route.params.documentType)
    }
  },

  validations: {

    tariffFeeIdValue: { required },
    tariffItemIdValue: { required }

  },

  methods: {

    loadThisDocumentOnCreated (data, type) {
      this.thisFirstRequestLoader = true

      let url = API.manifestData(data)

      if (type === 'CUSCAR') {
        url = API.manifestData(data)
      } else if (type === 'BAPLIE') {
        url = API.bayPlanData(data)
      } else if (type === 'IFTDGN') {
        url = API.dangerousGoodData(data)
      } else { // COPRAR
        url = API.dischargeListData(data)
      }

      url.then(
        response => {
          if (response.data.status) {
            this.singleDocument = response.data.data

            this.thisFirstRequestLoader = false
          } else {

          }
        }
      ).catch(() => {
        this.thisFirstRequestLoader = false
      })
    },

    showOtherDetailsCard (messageType = null, flag) {
      if (flag === 'show') {
        if (messageType === 'CUSCAR') {
          this.showMoreDetails = true
          this.sortBy = 'manifestBlId'
          this.getManifestPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy)
        }

        if (messageType === 'COPRAR') {
          this.showMoreDetails = true
          this.sortBy = 'dischargeListId'
          this.getDischargeListPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy)
        }

        if (messageType === 'BAPLIE') {
          this.showMoreDetails = true
          this.sortBy = 'bayPlanId'
          this.getBayPlanPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy)
        }

        if (messageType === 'IFTDGN') {
          this.showMoreDetails = true
          this.sortBy = 'dangerousGoodsId'
          this.getDangerousGoodsPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy)
        }
      } else {
        this.showMoreDetails = false
        this.getManifestDetails = []
        this.getDischargeListDetails = []
        this.getBayPlanDetails = []
        this.getDangerousGoodsDetails = []
      }
    },

    showCreateChargeForm (flag) {
      if (flag === 'show') {
        this.showCreateCharge = true
        this.loadTariffFees()
        this.loadTariffItems()
      } else {
        this.showCreateCharge = false
        this.tariffItems = []
        this.tariffFee = []
      }
    },

    createManifestCharge () {
      if (this.$v.tariffFeeIdValue.$invalid || this.$v.tariffItemIdValue.$invalid) {
        if (this.$v.tariffFeeIdValue.$invalid) { this.tariffFeeError = true }

        if (this.$v.tariffItemIdValue.$invalid) { this.tariffItemNameError = true }
      } else {
        this.$dialog.confirm('You are about to generate manifest charge for company [ ' + this.singleDocument.carrierName + ' ], Are you sure!.', {

          loader: true
        })
          .then((dialog) => {
            this.createManifestChargeLoader = true

            API_DC.createManifestCharge(this.documentId, this.tariffItemIdValue, this.tariffFeeIdValue).then(
              response => {
                if (response.data.status) {
                  this.ErrorMessageTop = ''
                  this.SuccessAlertTop = true
                  this.ErrorAlertTop = false
                  this.SuccessMessageTop = ', Manifest Charge created'
                  this.tariffItemIdValue = ''
                  this.tariffFeeIdValue = ''
                  this.createManifestChargeLoader = false
                  this.loadThisDocumentOnCreated(this.$route.params.documentId, this.$route.params.documentType)
                  this.tariffItemNameError = false
                  this.tariffFeeError = false
                  this.showCreateCharge = false
                  this.tariffItems = []
                  this.tariffFee = []
                } else {
                  this.ErrorMessageTop = ', Manifest Charge not created, please contact system administrator'
                  this.SuccessAlertTop = false
                  this.ErrorAlertTop = true
                  this.SuccessMessageTop = ''
                  this.tariffItemIdValue = ''
                  this.tariffFeeIdValue = ''
                  this.createManifestChargeLoader = false
                  this.tariffItemNameError = false
                  this.tariffFeeError = false
                }
              }
            ).catch(() => {
              this.ErrorMessageTop = ', Manifest Charge not created, please contact system administrator'
              this.SuccessAlertTop = false
              this.ErrorAlertTop = true
              this.SuccessMessageTop = ''
              this.tariffItemIdValue = ''
              this.tariffFeeIdValue = ''
              this.createManifestChargeLoader = false
              this.tariffItemNameError = false
              this.tariffFeeError = false
            })

            dialog.close()
            // }, 2500);
          }).catch(() => {
            this.tariffItemIdValue = ''
            this.tariffFeeIdValue = ''
            this.tariffItemNameError = false
            this.tariffFeeError = false
          })
      }
    },

    loadTariffFees () {
      API_REVENUE.getActiveTariffFee().then((response) => {
        if (response.data.status) {
          this.tariffFee = response.data.data
        } else {

        }
      }).catch(() => {

      })
    },

    loadTariffItems () {
      API_REVENUE.getTariffItemsActiveByService('DC').then((response) => {
        if (response.data.status) {
          this.tariffItems = response.data.data
        } else {

        }
      }).catch(() => {

      })
    },

    downloadExcel (MessageType, MessageId, fileName) {

      this.globalLoader = true
      this.globalLoaderName = 'Document'

      if (MessageType.toUpperCase() === 'CUSCAR') {
        API.manifestExcel(MessageId).then(
          response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName + '.xlsx') // or any other extension
            document.body.appendChild(link)
            link.click()

            this.globalLoader = false
          }
        ).catch(e => {
          this.ErrorMessage = ' Not downloaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.globalLoader = false
        })

      } else if (MessageType.toUpperCase() === 'COPRAR') {

        API.dischargeListExcel(MessageId).then(
          response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName + '.xlsx') // or any other extension
            document.body.appendChild(link)
            link.click()

            this.globalLoader = false
          }
        ).catch(e => {
          this.ErrorMessage = ' Not downloaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.globalLoader = false
        })

      } else if (MessageType.toUpperCase() === 'BAPLIE') {

        API.bayPlanExcel(MessageId).then(
          response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName + '.xlsx') // or any other extension
            document.body.appendChild(link)
            link.click()

            this.globalLoader = false
          }
        ).catch(e => {
          this.ErrorMessage = ' Not downloaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.globalLoader = false
        })

      } else {

        API.dangerousGoodExcel(MessageId).then(
          response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName + '.xlsx') // or any other extension
            document.body.appendChild(link)
            link.click()

            this.globalLoader = false
          }
        ).catch(e => {
          this.ErrorMessage = ' Not downloaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.globalLoader = false
        })
      }

    },

    downloadPdf (MessageType, MessageId) {
      this.globalLoader = true
      this.globalLoaderName = 'Document'

      if (MessageType.toUpperCase() === 'CUSCAR') {
        API.manifestExcel(MessageId).then(
          response => {
            if (response.data.status) {
              this.globalLoader = false
            } else {
              this.globalLoader = false
            }
          }
        ).catch(e => {
          this.ErrorMessage = ' Not downloaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.globalLoader = false
        })
      } else if (MessageType.toUpperCase() === 'COPRAR') {
        API.DischargeListPdf(MessageId).then(
          response => {
            if (response.data.status) {
              this.globalLoader = false
            } else {
              this.globalLoader = false
            }
          }
        ).catch(e => {
          this.ErrorMessage = ' Not downloaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.globalLoader = false
        })
      } else if (MessageType.toUpperCase() === 'BAPLIE') {
        this.globalLoader = false
      }
    },

    sendMail (data, name, flag) {
      this.$dialog.confirm('You are about to ' + flag + ' MANIFEST as mail attachment to company [ ' + name + ' ], Are you sure!.', {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          this.myloader = true

          API.sendMailManifest(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ' Mail sent to ' + name
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.loadThisDocumentOnCreated(this.$route.params.documentId, this.$route.params.documentType)
              } else {

              }
            }
          ).catch(() => {
            this.ErrorMessage = ' Mail not sent'
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.myloader = false
          })

          dialog.close()
          // }, 2500);
        }).catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    getEDIMessageByControlRefNo (data) {
      API.getEDIMessageByControlRefNo(data).then(
        response => {
          if (response.data.status) {
            this.ediMessageByControlRef = response.data.data
          } else {

          }
        }
      ).catch(() => {
        this.ErrorMessage = ''
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
      })
    },

    getManifestSpecified (data, pageNo, pageSize) {
      this.myloader = true

      API.getManifestSpecifiedByDocumentId(data, pageNo, pageSize).then(
        response => {
          if (response.data.status) {
            this.pageCount = response.data.message
            this.manifestSpecifiedGoods = response.data.data
            this.myloader = false

            this.noDataFound = this.manifestSpecifiedGoods.length <= 0;
          } else {

          }
        }
      ).catch(() => {
        this.ErrorMessage = ''
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    getManifestPageableByDocumentId (data, pageNo, pageSize, sortBy) {
      this.messageLoader = true

      API.getManifestPageableByDocumentId(data, pageNo, pageSize, sortBy).then(
        response => {
          if (response.data.status) {
            this.pageCount = response.data.message
            this.getManifestDetails = response.data.data
            this.messageLoader = false
          } else {
            this.messageLoader = false
          }
        }
      ).catch(() => {
        this.ErrorMessage = ''
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.messageLoader = false
      })
    },

    getDischargeListPageableByDocumentId (data, pageNo, pageSize, sortBy) {
      this.messageLoader = true

      API.getDischargeListPageableByDocumentId(data, pageNo, pageSize, sortBy).then(
        response => {
          if (response.data.status) {
            this.pageCount = response.data.message
            this.getDischargeListDetails = response.data.data
            this.messageLoader = false
          } else {

          }
        }
      ).catch(() => {
        this.ErrorMessage = ''
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.messageLoader = false
      })
    },

    getDangerousGoodsPageableByDocumentId (data, pageNo, pageSize, sortBy) {
      this.messageLoader = true

      API.getDangerousGoodsPageableByDocumentId(data, pageNo, pageSize, sortBy).then(
        response => {
          if (response.data.status) {
            this.pageCount = response.data.message
            this.getDangerousGoodsDetails = response.data.data
            this.messageLoader = false
          } else {

          }
        }
      ).catch(() => {
        this.ErrorMessage = ''
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.messageLoader = false
      })
    },

    getBayPlanPageableByDocumentId (data, pageNo, pageSize, sortBy) {
      this.messageLoader = true

      API.getBayPlanPageableByDocumentId(data, pageNo, pageSize, sortBy).then(
        response => {
          if (response.data.status) {
            this.pageCount = response.data.message
            this.getBayPlanDetails = response.data.data
            this.messageLoader = false
          } else {

          }
        }
      ).catch(() => {
        this.ErrorMessage = ''
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.messageLoader = false
      })
    },

    openModal (objectData = null, actionButton) {
      if (actionButton === 'EDI_MESSAGE_HISTORY') {
        this.getEDIMessageByControlRefNo(objectData.controlRef)
        this.headerControlRef = objectData.tasacControlNumber
        this.showEdiMessageHistory = true
        this.showSpecifiedGoods = false
        this.showModal = true
      }

      if (actionButton === 'SPECIFIED_GOODS') {
        this.getManifestSpecified(objectData.controlRef, this.pageNo, this.pageSize)
        this.headerControlRef = objectData.tasacControlNumber
        this.showEdiMessageHistory = false
        this.showSpecifiedGoods = true
        this.sortBy = 'specifiedGoodsId'
        this.showModal = true
      }
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.sortBy === 'specifiedGoodsId') { this.getManifestSpecified(this.singleDocument.controlRef, this.pageNo, this.pageSize) }

      if (this.sortBy === 'manifestBlId') { this.getManifestPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dischargeListId') { this.getDischargeListPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'bayPlanId') { this.getBayPlanPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dangerousGoodsId') { this.getDangerousGoodsPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }
    },

    nextPage () {
      this.pageNo++
      if (this.sortBy === 'specifiedGoodsId') { this.getManifestSpecified(this.singleDocument.controlRef, this.pageNo, this.pageSize) }

      if (this.sortBy === 'manifestBlId') { this.getManifestPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dischargeListId') { this.getDischargeListPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'bayPlanId') { this.getBayPlanPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dangerousGoodsId') { this.getDangerousGoodsPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }
    },

    prevPage () {
      this.pageNo--
      if (this.sortBy === 'specifiedGoodsId') { this.getManifestSpecified(this.singleDocument.controlRef, this.pageNo, this.pageSize) }

      if (this.sortBy === 'manifestBlId') { this.getManifestPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dischargeListId') { this.getDischargeListPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'bayPlanId') { this.getBayPlanPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dangerousGoodsId') { this.getDangerousGoodsPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }
    },

    firstPage () {
      this.pageNo = 0
      if (this.sortBy === 'specifiedGoodsId') { this.getManifestSpecified(this.singleDocument.controlRef, this.pageNo, this.pageSize) }

      if (this.sortBy === 'manifestBlId') { this.getManifestPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dischargeListId') { this.getDischargeListPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'bayPlanId') { this.getBayPlanPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dangerousGoodsId') { this.getDangerousGoodsPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      if (this.sortBy === 'specifiedGoodsId') { this.getManifestSpecified(this.singleDocument.controlRef, this.pageNo, this.pageSize) }

      if (this.sortBy === 'manifestBlId') { this.getManifestPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dischargeListId') { this.getDischargeListPageableByDocumentId(this.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'bayPlanId') { this.getBayPlanPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }

      if (this.sortBy === 'dangerousGoodsId') { this.getDangerousGoodsPageableByDocumentId(this.document.documentId, this.pageNo, this.pageSize, this.sortBy) }
    },

    goBack () {

      if (window.$cookies.isKey('browser-local-cashed-url-back')) {

        this.$router.push(window.$cookies.get('browser-local-cashed-url-back'))
        // window.$cookies.remove('browser-local-cashed-url-back')
        
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enterTop: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlertTop = false
        that.ErrorAlertTop = false
      }, 3000) // hide the message after 3 seconds
    },

    fadeMeTop: function () {
      if (this.SuccessAlertTop) {
        this.SuccessAlertTop = !this.SuccessAlertTop
      } else {
        this.ErrorAlertTop = !this.ErrorAlertTop
      }
    },

    closeModal () {
      this.showModal = false
      this.showEdiMessageHistory = false
      this.showSpecifiedGoods = false
      this.sortBy = ''
    }

  }

}

</script>

<style scoped>

    .custom-btn-all {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        width: auto;
    }

    .btn-space {
        margin-right: 10px !important;
    }

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }

    .div-table-row {
        padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
    }

    .btn-success-custom:hover {
        border-color: #008000;
        color: #008000;
        background-color: #ffffff;
    }

    .btn-success-custom:hover .file-excel {
        color: green;
    }

    .btn-success-custom {
        border-color: rgba(0, 128, 0, .5);
        color: rgba(0, 128, 0, .5);
    }

    .btn-success-custom-fill {
        background-color: rgba(0, 128, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-blue:hover {
        border-color: #0000ff;
        color: #0000ff;
        background-color: #ffffff;
    }

    .btn-blue {
        border-color: #4169e1;
        color: #4169e1;
    }

    .btn-blue-fill {
        background-color: #4169e1;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-olive:hover {
        border-color: #4B0082;
        color: #4B0082;
        background-color: #ffffff;
    }

    .btn-olive:hover .file-pdf {
        color: red;
    }

    .btn-olive {
        border-color: #6A5ACD;
        color: #6A5ACD;
    }

    .btn-olive-fill {
        background-color: #6A5ACD;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-purple:hover {
        border-color: #800080;
        color: #800080;
        background-color: #ffffff;
    }

    .btn-purple {
        border-color: #9932cc;
        color: #9932cc;
    }

    .btn-purple-fill {
        background-color: #9932cc;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-linen:hover {
        border-color: #D2691E;
        color: #D2691E;
        background-color: #ffffff;
    }

    .btn-linen {
        border-color: rgba(210, 105, 30, .5);
        color: rgba(210, 105, 30, .5);
    }

    .btn-linen-fill {
        background-color: rgb(210, 105, 30);
        opacity: 1;
        color: #FFFFFF;
    }

    .alert-success {
        padding-top: 10px;
        padding-bottom: 10px;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert-danger {
        padding-top: 10px;
        padding-bottom: 10px;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    .disabled-click {
        pointer-events:none;
        color:#dcdcdc;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .enabled-click {
        pointer-events:auto;
    }

    .disable-highlight:hover span {
        cursor: pointer;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

</style>
