<template>
  <div>
    <div class="before-container" />

    <div class="container">
      <card
        class="row justify-content-center"
        style="background-color: white;opacity: .5"
      >
        <div class="row">
          <div class="col-md-12">
            Your new password
            <i>
              <b>must</b>
            </i>
            have the following rules
            <br>
            <ul class="pass-rules">
              <li>
                <b>i)</b> Eight (8) or more characters
              </li>
              <li>
                <b>ii)</b> Atleast a capital letter
              </li>
              <li>
                <b>iii)</b> Atleast a small letter
              </li>
              <li>
                <b>iv)</b> Atleast a special character
              </li>
              <li>
                <b>v)</b> Contains no space
              </li>
            </ul>
          </div>
        </div>
      </card>

      <div
        class="row justify-content-center"
        style="background-color: transparent;opacity: .7"
      >
        <div class="col-lg-5 col-md-7">
          <div class="card shadow border-0">
            <div class="card-header bg-transparent pb-5">
              <div class="logo-img text-center">
                <img
                  src="/img/logo.png"
                  alt
                  style="width:300px;height:230px;"
                >
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-2">
              <form
                role="form"
                @submit.prevent="checkToken"
              >
                <div class="input-container">
                  <i class="nc-icon nc-key-25 icon" />
                  <input
                    type="password"
                    placeholder="new password"
                    id="newPassword"
                    autocomplete="off"
                    class="input-field"
                    v-model="newPass"
                  >
                </div>

                <div class="input-container">
                  <i class="nc-icon nc-key-25 icon" />
                  <input
                    type="password"
                    placeholder="confirm new password"
                    id="confNewPassword"
                    autocomplete="off"
                    class="input-field"
                    v-model="confNewPass"
                    @paste.prevent="noPaste"
                  >
                </div>

                <div class="text-center">
                  <div
                    class="form-group"
                    style="height: 30px"
                  >
                    <div>
                      <div
                        class="form-group text-danger"
                        v-if="showIncorrectError"
                      >
                        {{ incorrectError }}
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    name="button"
                    :class="[ markButtonDisabled ? 'btn-disabled' : 'btn' ]"
                    @keyup.enter="submit"
                    :disabled="disabledSignIn"
                  >
                    <div class="row">
                      <div :class="[ loadingPending && loadingPendingIcon ? 'col-md-7 text-right' : 'col-md-12 text-center' ]">
                        {{ signInText }}
                      </div>
                      <div
                        v-if="loadingPendingIcon"
                        :class="[ loadingPendingIcon ? 'col-md-5 text-left' : '' ]"
                      >
                        <pulse-loader
                          :loading="loading"
                          :color="color"
                          :size="size"
                          class="loader-div-gif"
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </form>
              <div class="row mt-3 forgot-password text-center">
                <div class="col-12">
                  <router-link :to="{name : 'login'}">
                    <!--                    <small>Login</small>-->
                  </router-link>
                </div>
                <div class="col-6 text-right" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import API from '../api/index'

export default {

  name: 'ResetPassword',

  components: {

    PulseLoader

  },

  created () {
    this.token = this.$route.query.token
  },

  data () {
    return {

      rules: [
        { regex: /[a-z]+/ },
        { regex: /[A-Z]+/ },
        { regex: /.{8,}/ },
        { regex: /[0-9]+/ },
        { regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/ }
      ],

      timer: null,
      signInText: 'Reset Password',
      disabledSignIn: false,
      showIncorrectError: false,
      loadingPending: false,
      loadingPendingIcon: false,
      incorrectError: '',
      markButtonDisabled: false,

      newPass: '',
      confNewPass: '',
      token: '',
      email: '',

      color: 'DimGray',
      size: '12px',
      loading: true
    }
  },

  methods: {

    noPaste () {
      const myInput = document.getElementById('confNewPassword')
      myInput.onpaste = function (e) {
        e.preventDefault()
      }
    },

    checkToken () {
      if (this.newPass.length <= 0 || this.confNewPass.length <= 0) {
        this.incorrectError = 'All fields are required'
        this.newPass = ''
        this.confNewPass = ''
        this.showIncorrectError = true
      } else if (this.newPass !== this.confNewPass) {
        this.incorrectError = 'Password did not match'
        this.newPass = ''
        this.confNewPass = ''
        this.showIncorrectError = true
      } else {
        let x = 0
        let y = 0

        for (const condition of this.rules) {
          if (!condition.regex.test(this.newPass)) {
            x++
          } else {
            y++
          }
        }

        if (y > 0 && x === 0) {
          if (this.newPass.indexOf(' ') >= 0) {
            this.incorrectError = 'Make sure your password adheres to above rules'
            this.newPass = ''
            this.confNewPass = ''
            this.showIncorrectError = true
          } else {
            if (this.disabledSignIn) {
              return
            }

            this.signInText = 'Resetting Password...'
            this.disabledSignIn = true
            this.loadingPending = true
            this.loadingPendingIcon = true
            this.markButtonDisabled = true

            if (this.showIncorrectError) {
              this.showIncorrectError = false
            }

            return new Promise((resolve, reject) => {
              API.checkTokenForResetPassword(this.token)

                .then(res => {
                  if (res.data.status) {
                    this.email = res.data.data

                    const newRequest = {

                      token: this.token,
                      email: this.email,
                      newPassword: this.newPass

                    }

                    let redirect_to = this.$route.query.redirect

                    if (redirect_to == null) {
                      redirect_to = '/login'
                    }

                    return new Promise((resolve, reject) => {
                      API.resetUserPassword(JSON.stringify(newRequest))

                        .then(res => {
                          if (res.data.status) {
                            this.loadingPendingIcon = false
                            this.signInText = ', Success reset password, go to login'
                            this.newPass = ''
                            this.confNewPass = ''
                            this.disabledSignIn = true

                            this.timer = setInterval(() => {
                              this.loadingPending = false
                              this.markButtonDisabled = false

                              this.$router.push(redirect_to)
                            }, 5000)
                          } else {
                            this.loadingPendingIcon = false
                            this.signInText = ', Success reset password, go to login'
                            this.newPass = ''
                            this.confNewPass = ''
                            this.disabledSignIn = true

                            this.timer = setInterval(() => {
                              this.loadingPending = false
                              this.markButtonDisabled = false

                              this.$router.push(redirect_to)
                            }, 5000)
                          }

                          resolve()
                        })

                        .catch(err => {
                          reject(err)
                        })
                    })
                  } else {
                    this.incorrectError = 'Token has expired'
                    this.loadingPending = false
                    this.markButtonDisabled = false
                    this.loadingPendingIcon = false
                    this.newPass = ''
                    this.confNewPass = ''
                    this.showIncorrectError = true
                  }

                  resolve()
                })

                .catch(err => {
                  reject(err)
                })
            })
          }
        } else {
          this.incorrectError = 'Make sure your password adheres to above rules'
          this.newPass = ''
          this.confNewPass = ''
          this.showIncorrectError = true
        }
      }
    },

    destroyed () {
      if (this.timer != null) {
        clearInterval(this.timer)
      }
    }
  }
}

</script>

<style scoped>

  .container {
    position: absolute;
    top: 50%;
    left: 49.67%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .forgot-password {
    color: #00BFFF;
    font-size: 18px;
  }

  .forgot-password:hover div a small {
    color: #000000;
    font-size: 15px;
  }

  .before-container {
    width: 100%;
    height: 100%;
    background-image: url("../../public/img/10.jpg"); /* The image used */
    background-color: #87CEFA; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    /*filter: blur(10px);*/
    /*-webkit-filter: blur(10px);*/

    /*opacity: .5;*/

    position: absolute;
    padding-top: 200px;
  }

  .loader-div-gif {
    line-height: 34px;
  }

  .input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    margin-bottom: 15px;
  }

  .icon {
    padding: 10px;
    background: dodgerblue;
    color: white;
    min-width: 50px;
    text-align: center;
  }

  .input-field {
    width: 100%;
    padding: 10px;
    outline: none;
  }

  .input-field:focus {
    border: 2px solid dodgerblue;
  }

  .btn {
    background-color: dodgerblue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 0.9;
    min-height: 55px;
    max-height: 55px;
  }

  .btn:hover {
    background-color: dodgerblue;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    opacity: 1;
    min-height: 55px;
    max-height: 55px;
  }

  .btn-disabled {
    background-color: #DCDCDC;
    cursor: not-allowed;
    color: #696969;
    padding: 10px 20px;
    border: none;
    width: 100%;
    opacity: 0.9;
    min-height: 55px;
    max-height: 55px;
  }

  .btn-disabled:hover {
    background-color: #DCDCDC;
    cursor: not-allowed;
    color: #696969;
    padding: 10px 20px;
    border: none;
    width: 100%;
    opacity: 1;
    min-height: 55px;
    max-height: 55px;
  }

  .nc-icon {
    padding-top: 14px;
    font-size: 23px;
  }

  .pass-rules {
    list-style-type: none;
  }

  .pass-rules li {
    display: inline;
    padding-right: 20px;
    float: left;
  }

</style>
