<template>
  <div @click="clearLocalStorage">
    <div
      class="menu-title row"
      v-if="finalUserPermissions.includes('DC_IMPORT')"
      @click="toggleMenu('im')"
      title="Document Control - Import Documents"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="doc_import2"
          :has-fill="true"
        />
        <span class="menu-title-name">DC - IMPORT</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenuImport"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenuImport"
        />
      </div>
    </div>

    <transition name="list">
      <div
        v-if="openMenuImport"
        class="menu-value"
      >
        <sidebar-link
          :to="{ name: 'ImportEdiDocuments'}"
          v-if="finalUserPermissions.includes('DOCUMENTS_ALL_PAGEABLE_GET')
            && finalUserPermissions.includes('DC_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>EDI Documents</p>
        </sidebar-link>

        <!-- <sidebar-link
          :to="{ name: 'v2ImportEdiDocument', params: { direction : 'IMPORT' } }"
          v-if="finalUserPermissions.includes('DOCUMENTS_ALL_PAGEABLE_GET')
            && finalUserPermissions.includes('DC_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>V2 Import EDI Documents</p>
        </sidebar-link> -->

        <!-- <sidebar-link
          :to="{ name: 'ImportEdiDocument' }"
          v-if="finalUserPermissions.includes('DOCUMENTS_ALL_PAGEABLE_GET')
            && finalUserPermissions.includes('DC_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>EDI Documents</p>
        </sidebar-link> -->

        <!-- <sidebar-link
          :to="{ name: 'ImportAddTancisExtractedCargoManifest' }"
          v-if="finalUserPermissions.includes('TANCIS_TANCIS-DOCUMENTS-PAGEABLE_GET')
            && finalUserPermissions.includes('DC_IMPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>TANCIS Manifest</p>
        </sidebar-link> -->
      </div>
    </transition>

    <div
      class="menu-title row"
      v-if="finalUserPermissions.includes('DC_EXPORT')"
      @click="toggleMenu('ex')"
      title="Document Control - Export Documents"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="doc_export2"
          :has-fill="true"
        />
        <span class="menu-title-name">DC - EXPORT</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openMenuExport"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openMenuExport"
        />
      </div>
    </div>

    <transition name="list">
      <div
        v-if="openMenuExport"
        class="menu-value"
      >

        <sidebar-link
          :to="{ name: 'ExportEdiDocuments'}"
          v-if="finalUserPermissions.includes('DOCUMENTS_ALL_PAGEABLE_GET')
            && finalUserPermissions.includes('DC_EXPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>EDI Documents</p>
        </sidebar-link>

        <!-- <sidebar-link
          :to="{ name: 'v2ExportEdiDocument', params: { direction : 'EXPORT' } }"
          v-if="finalUserPermissions.includes('DOCUMENTS_ALL_PAGEABLE_GET')
            && finalUserPermissions.includes('DC_EXPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>V2 Export EDI Documents</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'ExportEdiDocument' }"
          v-if="finalUserPermissions.includes('DOCUMENTS_ALL_PAGEABLE_GET')
            && finalUserPermissions.includes('DC_EXPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>EDI Documents</p>
        </sidebar-link>

        <sidebar-link
          :to="{ name: 'ExportAddCargoDocument' }"
          v-if="finalUserPermissions.includes('LOADINGPERMISSION_ALL_PAGEABLE_GET')
            && finalUserPermissions.includes('DC_EXPORT')"
        >
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Cargo Documents</p>
        </sidebar-link> -->
      </div>
    </transition>


  </div>
</template>

<script>

  import SvgIcon from '../../components/SvgLoader/svgLoader'
  import NewNotification from "../../components/DataTable/newNotification";

  export default {

  name: 'DocumentControl',

  data () {
    return {

      finalUserPermissions: [],
      openMenuExport: false,
      openMenuImport: false

    }
  },

  components: {
    NewNotification,
    SvgIcon
  },

  created () {
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))

    this.openMenuImport = typeof (localStorage.getItem('dc-import-side-menu')) ? JSON.parse(localStorage.getItem('dc-import-side-menu')) : false
    this.openMenuExport = typeof (localStorage.getItem('dc-export-side-menu')) ? JSON.parse(localStorage.getItem('dc-export-side-menu')) : false
  },

  methods: {

    toggleMenu (type) {
      if (type === 'im') {
        this.openMenuImport = !this.openMenuImport

        localStorage.setItem('dc-import-side-menu', this.openMenuImport)
      }

      if (type === 'ex') {
        this.openMenuExport = !this.openMenuExport

        localStorage.setItem('dc-export-side-menu', this.openMenuExport)
      }
    }

  }

}
</script>

<style scoped>

    .menu-title {
        /*background-color: rgba(203, 203, 210, 0.20);*/
        /*background-color: rgba(0, 0, 0, .2);*/
        padding: 10px 2px 10px 0;
        text-align: left;
        font-weight: normal;
        font-size: 12px !important;
        color: #FFFFFF;
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-top: 12px;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                         supported by Chrome, Edge, Opera and Firefox */
    }

    .menu-title:hover {
        background-color: rgba(203, 203, 210, 0.10);
        cursor: pointer;
    }

    .menu-title-name {
        margin-left: 32px;
    }

    .menu-value {
        background-color: rgba(0, 0, 0, .2);
        padding-top: 5px;
        padding-bottom: 5px;
    }

    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }

    .list-enter-active, .list-leave-active {
        transition: all .7s;
    }

    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: translateY(30px);
    }

    .nc-icon {
        padding-right: 8px;
        padding-top: 4px;
        font-size: 20px !important;
    }

    div.drop-down-sub-menu {
        display: block;
        padding-left: 7px;
    }

    .sub-menu-display {
        display: block !important;
    }

    .sub-menu-hidden, .nc-important-hidden {
        display: none !important;
    }

    .sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
        text-transform: capitalize !important;
        padding-left: 10px;
        font-weight: normal !important;
    }

    .sidebar .nav li .nav-link:hover .nc-icon-inner, body > .navbar-collapse .nav li .nav-link:hover .nc-icon-inner {
        fill: #d59a18;
    }

    .menu-title:hover .nc-icon, .menu-title:hover .nc-icon-page {
        fill: #d59a18;
    }

    .nc-icon {
        position: absolute;
        top: -10px;
        left: 15px;
        font-size: 25px !important;
        fill: #FFFFFF;
    }

    .nc-icon-inner {
        font-size: 10px !important;
        padding-left: 30px;
        fill: #FFFFFF;
    }

    .nc-icon-page {
        font-size: 10px !important;
        fill: #FFFFFF;
    }

</style>
