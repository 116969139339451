<template>
  <div class="content">
    {{ checkNotification }}
    <card>
      <div class="row">
        <div
          @click="toggle"
          class="col-md-12 toggle-code-key"
        >
          <div class="row">
            <div class="col-md-6">
              Application icons and status color keys
            </div>
            <div class="col-md-6 text-right">
              <font-awesome-icon
                icon="angle-down"
                v-show="!showSection"
              />
              <font-awesome-icon
                icon="angle-up"
                v-show="showSection"
              />
            </div>
          </div>
        </div>
        <div
          class="col-md-12"
          v-show="showSection"
        >
          <hr>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div
                class="col-md-12"
                style="padding-left: 30px"
                v-show="showSection"
              >
                <b>Status color keys</b>
              </div>
              <div class="col-md-12">
                <div
                  class="col-md-12"
                  v-show="showSection"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="received-request"
                          icon="circle"
                        /><i
                          class=" text-muted"
                        > Application Sent</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="accepted-request"
                          icon="circle"
                        /><i
                          class=" text-muted"
                        > Application Accepted</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="declined-request"
                          icon="circle"
                        /><i class=" text-muted">
                          Application Returned for Major Changes</i>
                      </span>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="request-after-changes"
                          icon="circle"
                        /><i class=" text-muted">
                          Application Resubmitted After Changes</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="returned-for-changes"
                          icon="circle"
                        /><i class=" text-muted">
                          Application Returned for Minor Changes</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          icon="circle"
                          class="request-in-action"
                        /><i
                          class=" text-muted"
                        > Application Assessed</i>
                      </span>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          icon="circle"
                          class="rejected-app-assess"
                        />
                        <i class=" text-muted">
                          Rejected Application Assessment</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          icon="circle"
                          class="request-charged"
                        />&nbsp;
                        <i class=" text-muted"> Application Charged</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          icon="circle"
                          class="request-billed"
                        /><i class=" text-muted">
                          Application Billed</i>
                      </span>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          icon="circle"
                          class="app-bill-approved"
                        />
                        <i class=" text-muted">
                          Application Bill Approved</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          icon="circle"
                          class="app-bill-paid"
                        /><i
                          class=" text-muted"
                        > Application Bill Paid</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          icon="circle"
                          class="app-closed"
                        /><i class=" text-muted">
                          Application Closed</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            v-show="showSection"
          >
            <br>
          </div>

          <div class="col-md-12">
            <div class="row">
              <div
                class="col-md-12"
                style="padding-left: 30px"
                v-show="showSection"
              >
                <b>icons keys</b>
              </div>
              <div class="col-md-12">
                <div
                  class="col-md-12"
                  v-show="showSection"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon icon="plus" /><i class=" text-muted"> Add Application</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="edit-fa-icon"
                          icon="edit"
                        /><i class=" text-muted"> Edit Application</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="view-fa-icon"
                          icon="eye"
                        /><i class=" text-muted"> View Application</i>
                      </span>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="delete-fa-icon"
                          icon="trash-alt"
                        /><i class=" text-muted">
                          Delete Application</i>
                      </span>
                    </div>
                    <div class="col-md-4">
                      <span>
                        <font-awesome-icon
                          class="verify-fa-icon"
                          icon="envelope"
                        />
                        <i class=" text-muted">
                          Application Conversation</i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        style="border-radius:5px;"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          aria-label="Close"
          class="close close-custom"
          data-dismiss="alert"
          style="line-height:2px;"
          type="button"
          v-if="SuccessAlert"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          aria-label="Close"
          class="close close-custom"
          data-dismiss="alert"
          style="line-height:2px;"
          type="button"
          v-if="ErrorAlert"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>
      <div class="row">
        <div class="col-md-4">
          <div class="text-left">
            Applications List
          </div>
        </div>
        <div class="col-md-4 text-center">
          <pulse-loader
            :color="color"
            :loading="loading"
            :size="size"
            v-if="myloader"
          />
        </div>
        <div class="col-md-4 text-right">
          <span
            @click.prevent="openModal(null , actionButton = 'AddModal')"
            class="all-fa-icons btn-space"
            title="add new request"
            v-if="finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_POST')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('CLEARINGFORWARDING_SEARCH_{CUSTOMERID}_POST')"
            >
              <form
                class="search"
                @submit.prevent="searchRequestByName(pageNo , pageSize , sortBy)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParameter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="searchRequestByName(pageNo , pageSize , sortBy)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table
        class="table table-striped"
        v-if="!noDataFound"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>BL/Airway Bill/RCN</th>
            <th>TASAC File no.</th>
            <th>Type</th>
            <th>Requested Date</th>
            <th>Status</th>
            <th
              class="special-limit"
              scope="col"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            :key="index"
            v-for="(list, index) in requests"
          >
            <td scope="row">
              {{ index + 1 +(pageNo * pageSize) }}
            </td>
            <td>
              {{ list.blNumber }}
            </td>
            <td v-if="list.fileNo">
              {{ list.fileNo }}
            </td>
            <td
              v-else=""
              class="text-muted"
            >
              <i>NIL</i>
            </td>
            <td>
              {{ list.requestTypeName }}
            </td>
            <td>
              {{ dateTimeToHuman(list.requestDate) }}
            </td>
            <td>
              <span
                title="Application Sent"
                v-if="list.status === 'Request Submitted' "
              >
                <font-awesome-icon
                  class="received-request"
                  icon="circle"
                />
              </span>
              <span
                title="Plan Approved"
                v-if="list.status === 'Plan Approved' "
              >
                <font-awesome-icon
                  class="approved-plan"
                  icon="circle"
                />
              </span>
              <span
                title="Request Returned For Major Changes"
                v-if="list.status === 'Request Returned For Major Changes' "
              >
                <font-awesome-icon
                  class="declined-request"
                  icon="circle"
                />
              </span>
              <span
                title="Application Accepted"
                v-if="list.status === 'Request Accepted' "
              >
                <font-awesome-icon
                  class="accepted-request"
                  icon="circle"
                />
              </span>
              <span
                title="Application Returned For Minor Changes"
                v-if="list.status === 'Request Returned For Minor Changes' "
              >
                <font-awesome-icon
                  class="returned-for-changes"
                  icon="circle"
                />
              </span>
              <span
                title="Application Resubmitted After Changes"
                v-if="list.status === 'Request Resubmitted After Changes' "
              >
                <font-awesome-icon
                  class="request-after-changes"
                  icon="circle"
                />
              </span>
              <span
                v-if="list.status === 'Request Assessed' "
                :title="list.status"
              >
                <font-awesome-icon
                  icon="circle"
                  class="request-in-action"
                />
              </span>
              <span
                v-if="list.status === 'Request Assessment Rejected' "
                title="Rejected Application Assessment"
              >
                <font-awesome-icon
                  icon="circle"
                  class="rejected-app-assess"
                />
              </span>
              <span
                v-if="list.status === 'Request Charged' "
                title="Application Charged"
              >
                <font-awesome-icon
                  icon="circle"
                  class="request-charged"
                />
              </span>
              <span
                v-if="list.status === 'Request Assessment Sent To GEPG' "
                title="Application Bill Approved and Sent To GEPG"
              >
                <font-awesome-icon
                  icon="circle"
                  class="app-bill-approved"
                />
              </span>
              <span
                v-if="list.status === 'Request Bill Paid' "
                :title="list.status"
              >
                <font-awesome-icon
                  icon="circle"
                  class="app-bill-paid"
                />
              </span>
              <span
                v-if="list.status === 'Request Billed' "
                :title="list.status"
              >
                <font-awesome-icon
                  icon="circle"
                  class="request-billed"
                />
              </span>
              <span
                v-if="list.status === 'Request Closed' "
                :title="list.status"
              >
                <font-awesome-icon
                  icon="circle"
                  class="app-closed"
                />
              </span>
            </td>
            <td>
              <div>
                <div
                  @click.prevent="openModal(list , actionButton = 'EditModal')"
                  class="all-fa-icons edit-fa-icon"
                  title="edit"
                  v-if="(list.status === 'Request Submitted'
                    || list.status === 'Request Returned For Minor Changes'
                    || list.status === 'Request Returned For Major Changes'
                    || list.isAmended === 'Request amendment')
                    && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_PUT')"
                >
                  <font-awesome-icon icon="edit" />
                </div>

                <div
                  class=" all-fa-icons edit-fa-icon"
                  style="width:17px;"
                  v-else=""
                />
              </div>
              <div>
                <div
                  @click.prevent="openModal(list , actionButton = 'ShowModal')"
                  class="all-fa-icons fa-icons-space view-fa-icon"
                  title="view"
                  v-if="finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_{REQUESTID}_GET')"
                >
                  <font-awesome-icon icon="eye" />
                </div>
              </div>

              <div>
                <div
                  @click.prevent="deleteSingleRequest(list.requestId , list.blNumber)"
                  class="all-fa-icons fa-icons-space delete-fa-icon"
                  title="delete"
                  v-if="(list.status === 'Request Submitted'
                    || list.status === 'Request Returned For Minor Changes'
                    || list.status === 'Request Returned For Major Changes')
                    && finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_{REQUESTID}_DELETE')"
                >
                  <font-awesome-icon icon="trash-alt" />
                </div>

                <div
                  class=" all-fa-icons edit-fa-icon"
                  style="width:24px;"
                  v-else=""
                />
              </div>

              <!--            <div>-->
              <!--              <div @click.prevent="showModalRejectedMessage(list.rejectReason)"-->
              <!--                   class="all-fa-icons fa-icons-space envelop-fa-icon"-->
              <!--                   title="returned message for changes"-->
              <!--                   v-if="list.status === 'Request Returned For Major Changes' ||-->
              <!--                   list.status === 'Request Returned For Minor Changes' ||-->
              <!--                   list.isAmended === 'Request amendment' ||-->
              <!--                   list.isAmended === 'Request amendment done' &&-->
              <!--                   finalUserPermissions.includes('CLEARINGFORWARDING_REQUEST_{REQUESTID}_GET')">-->
              <!--                <font-awesome-icon icon="envelope"/>-->
              <!--              </div>-->
              <!--              <div class=" all-fa-icons edit-fa-icon" style="width:24px;" v-else="">-->

              <!--              </div>-->
              <!--            </div>-->

              <div>
                <div
                  class="all-fa-icons fa-icons-space verify-fa-icon"
                  @click.prevent="openModal(list, actionButton = 'ShowFlagModal')"
                  title="Application conversation with TASAC"
                  v-if=" finalUserPermissions.includes('REQUEST-COMMENTS_COMMENTS_{REQUESTID}_GET')"
                >
                  <font-awesome-icon icon="envelope" />
                </div>
                <div
                  class=" all-fa-icons edit-fa-icon"
                  style="width:24px;"
                  v-else=""
                />
              </div>

              <div>
                <div
                  @click="viewAssesment(list)"
                  class="all-fa-icons fa-icons-space"
                >
                  <font-awesome-icon
                    icon="book-open"
                    class="pointer"
                  />
                </div>
                <div
                  class=" all-fa-icons edit-fa-icon"
                  style="width:24px;"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <span v-if="requests.length > 0">Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}</span>
          </div>

          <div class="col-md-3 text-right">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>

          <div
            class="col-md-6 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo || this.pageCount - 1 < 0 ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="ShowNonAndMonetaryListPlan">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="text-center row">
          <div class="col-md-7 text-right loader-div" />
          <div class="col-md-2 left loader-div-gif">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="editMyLoader"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <br>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-11">
                Activities List for Application: File No. - [ <b>
                  {{ listFileNo ? listFileNo : 'NIL' }}</b> ] |
                BL/Airway Bill/RCN - [ <b>{{ listBlNumber }}</b> ]
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="body">
        <card>
          <div class="content">
            <div>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th scope="col">
                      Activity Name
                    </th>
                    <th scope="col">
                      Activity Status Name
                    </th>
                    <th scope="col">
                      Activity Date
                    </th>
                    <th scope="col">
                      Attachment Link
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(activity, index) in loadSavedNonMonetaryActivity"
                    :key="activity.activityLogId"
                  >
                    <th scope="row">
                      {{ index + 1 }}
                    </th>
                    <td>
                      {{ activity.activityName }}
                    </td>
                    <td>
                      {{ activity.status }}
                    </td>
                    <td>
                      {{ activity.activityDate }}
                    </td>
                    <td
                      v-if="activity.attachUrl !== null "
                      @click.prevent="getFileFromMinio(activity.attachUrl , 'ATTACHMENT')"
                      class="text-success download-attachment"
                    >
                      view
                    </td>
                    <td v-else>
                      No Attachment
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </card>
        <br>
        <div class="text-center row">
          <div class="col-md-7 text-right loader-div" />
          <div class="col-md-2 left loader-div-gif">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="editMyLoader"
            />
          </div>
          <div class="col-md-3">
            <button
              type="button"
              class="btn btn-fill btn-outline-info float-right"
              @click="closeModal()"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal v-if="showModal">
      <div
        class="modal-title col-md-12"
        slot="header"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv && ifRequestSubmitted"
          >
            Add / Remove Attachment(s) for Application: File No. - [ <b>
              {{ editFileNo ? editFileNo : 'NIL' }}</b> ] |
            BL/Airway Bill/RCN - [ <b>{{ requestName }}</b> ]
          </div>
          <div
            class="col-md-12"
            v-if="EditModalDiv && !ifRequestSubmitted"
          >
            Edit Application: File No. - [ <b>
              {{ editFileNo ? editFileNo : 'NIL' }}</b> ] |
            BL/Airway Bill/RCN - [ <b>{{ requestName }}</b> ]
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            <span v-if="!ifRequestSubmitted">
              Continue to attachment
              <br>
              1) <b><i>BL/Airway Bill/RCN</i></b> that will be added will be recognized under the same application type
              <b><i>Import </i></b> or <b><i>Export</i></b>.
              <br>
              2) user icon
              <font-awesome-icon icon="plus" />
              to add <b><i>BL/Airway Bill/RCN</i></b> if there are more than entry.
              <br>
              3) use icon
              <font-awesome-icon
                class="delete-fa-icon"
                icon="trash-alt"
              /> remove a <b><i>BL/Airway Bill/RCN</i></b>
              from an application
              <br>
              4) click button <b>"Continue to attachment"</b> to attach required documents to your application only
              and only if all of your <b><i>BL/Airway Bill/RCN</i></b> have been added.
            </span>
            <span v-if="ifRequestSubmitted">
              Attach required attachment(s) for each <b><i>BL/Airway Bill/RCN</i></b> and click Close to leave the page.
            </span>
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            <div class="row">
              <div class="col-md-10">
                Details for Application: File No. - [ <b>
                  {{ singleRequest.fileNo ? singleRequest.fileNo : 'NIL' }}</b> ] |
                BL/Airway Bill/RCN - [ <b>{{ singleRequest.requestName }}</b> ]
              </div>
              <div class="col-md-2">
                <pulse-loader
                  :color="color"
                  :loading="loading"
                  :size="size"
                  v-if="loadingShowRequest"
                />
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            v-if="showFlag"
          >
            <div class="row">
              <div class="col-md-11">
                Application Amendment Request: File No. - [ <b>
                  {{ fileNo ? fileNo : 'NIL' }}</b> ] |
                BL/Airway Bill/RCN - [ <b>{{ blNumber }}</b> ]
              </div>
              <div class="col-md-1 text-right">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="loadAllHeaderLoader"
                />
                <font-awesome-icon
                  icon="sync"
                  class="refresh-my-icon verify-fa-icon"
                  v-if="!loadAllHeaderLoader"
                  title="refresh for new messages"
                  @click="getAllMessagesPerRequest(requestId)"
                />
              </div>
            </div>
          </div>

          <div
            class="col-md-12"
            v-if="!showRejectedMessage"
          >
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <div v-if="showRejectedMessage">
          <card>
            <p>
              {{ rejectedMessage }}
            </p>
          </card>

          <div class="text-center row">
            <div class="col-md-7 text-right loader-div" />
            <div class="col-md-2 left loader-div-gif" />
            <div class="col-md-3">
              <button
                @click="closeModal()"
                class="btn btn-fill btn-outline-info float-right"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>

        <!--   MESSAGE CHAT ROOM    -->
        <form v-if="showFlag">
          <div
            class="row"
            style="max-height: 350px;overflow-y: scroll;"
            ref="myMessageDiv"
          >
            <div
              v-for="(message , index) in requestMessages"
              :key="index"
              class="col-md-12"
            >
              <div
                class="row chat-message-box text-right"
                v-if="message.customerId === null"
                :class="[ hasThisId.includes(message.id) ? 'background-color-message-to-edit' : '']"
              >
                <div class="col-md-11">
                  <b>{{ message.comment }}</b>
                </div>

                <div class="col-md-1">
                  <span style="color: blue">#{{ index + 1 }}</span> -
                </div>

                <div class="col-md-12">
                  <br>
                </div>

                <div class="col-md-12">
                  <table
                    style="table-layout: auto;width: 100%; "
                    v-if="message.commentAttachments.length > 0"
                  >
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Severity</th>
                        <th>Status</th>
                        <th class="text-center">
                          Responded
                        </th>
                        <th>Sent Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="index"
                        v-for="(messageAttachment, index) in message.commentAttachments"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>{{ messageAttachment.attachmentType.attachmentTypesName }}</td>
                        <td
                          v-if="messageAttachment.severity === 1"
                          style="color:red;"
                        >
                          High
                        </td>
                        <td v-else>
                          Low
                        </td>
                        <td>{{ messageAttachment.additional ? 'Addtional' : 'Amendement' }}</td>
                        <td
                          v-if="messageAttachment.responded"
                          class="text-center"
                        >
                          <font-awesome-icon
                            icon="check"
                            class="attended"
                          />
                        </td>
                        <td
                          v-else
                          class="text-center"
                        >
                          <font-awesome-icon
                            icon="times"
                            class="not-attended"
                          />
                        </td>
                        <td>{{ dateTimeToHuman(messageAttachment.createdAt) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12">
                  <br>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4 text-right">
                      <i>Sent At <span style="color:#d59a18;">{{ dateTimeToHuman(message.createdDate) }}</span></i>
                    </div>
                    <div class="col-md-5 text-right">
                      <i>Sent By <span style="color:#d59a18;">{{ message.createdBy }}</span></i>
                    </div>
                    <div class="col-md-2 text-right">
                      <pulse-loader
                        :color="color"
                        :loading="loading"
                        :size="size"
                        v-if="loadSingleEditableMessage.includes(message.id)
                          && message.commentAttachments.length > 0"
                      />
                      <font-awesome-icon
                        class="edit-tasac-messages"
                        icon="edit"
                        title="respond to this message"
                        v-if="!loadSingleEditableMessage.includes(message.id)
                          && message.commentAttachments.length > 0"
                        @click="showAllowEdit(message.id)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row chat-message-box-customer text-left"
                v-if="message.customerId !== null"
              >
                <div class="col-md-1 text-left">
                  - <span style="color: green">#{{ index + 1 }}</span>
                </div>
                <div class="col-md-11">
                  <span><b>{{ message.comment }}</b></span>
                </div>

                <div class="col-md-12">
                  <br>
                </div>
                <div class="col-md-12 text-left">
                  <div class="row">
                    <div class="col-md-3 text-left">
                      <i>Sent By <span style="color:#d59a18;">{{ message.createdBy }}</span></i>
                    </div>
                    <div class="col-md-3 text-left">
                      <i>Sent At <span style="color:#d59a18;">{{ dateTimeToHuman(message.createdDate) }}</span></i>
                    </div>
                    <div class="col-md-6 text-left" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br>

          <div
            class="row"
            v-if="!allowEdit"
          >
            <div class="col-md-12">
              <label>Please provide any other related description on this application (click
                <font-awesome-icon
                  class="edit-tasac-messages"
                  icon="edit"
                />
                to provide feedback on respective message)</label>
              <textarea
                type="text"
                placeholder="Please provide any other related description on this application"
                class="form-control"
                rows="7"
                v-model="tasacRejectMessageNew"
              />
              <div v-if="rejectedMessageError">
                <div
                  class="error"
                  v-if="!$v.tasacRejectMessageNew.required"
                >
                  This field is required
                </div>
              </div>
            </div>
          </div>

          <br v-if="!allowEdit">

          <div
            class="text-center row"
            v-if="!allowEdit"
          >
            <div class="col-md-6 text-right loader-div-gif">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="sendRequestLoader"
              />
            </div>
            <div class="col-md-6 text-right">
              <button
                type="submit"
                class="btn btn-success btn-space"
                data-dismiss="modal"
                @click.prevent="messageChatRoom"
              >
                Send
              </button>
              <button
                class="btn btn-default btn-fill"
                @click.prevent="closeModal"
              >
                Close
              </button>
            </div>
          </div>

          <!--     EDIT SPECIFIC MESSAGE     -->

          <hr v-if="allowEdit">

          <div
            class="col-md-12"
            v-if="requestSingleMessage.commentAttachments !== undefined && allowEdit
              && requestSingleMessage.commentAttachments.length > 0"
          >
            <table style="table-layout: auto;width: 100%; ">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Severity</th>
                  <th>Status</th>
                  <th class="text-center">
                    Responded
                  </th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :key="index"
                  v-for="(messageAttachmentToShow, index) in requestSingleMessage.commentAttachments"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ messageAttachmentToShow.attachmentType.attachmentTypesName }}</td>
                  <td
                    v-if="messageAttachmentToShow.severity === 1"
                    style="color:red;"
                  >
                    High
                  </td>
                  <td v-else>
                    Low
                  </td>
                  <td>{{ messageAttachmentToShow.additional ? 'Addtional' : 'Amendement' }}</td>
                  <td
                    v-if="messageAttachmentToShow.responded"
                    class="text-center"
                  >
                    <font-awesome-icon
                      icon="check"
                      class="attended"
                    />
                  </td>
                  <td
                    v-else
                    class="text-center"
                  >
                    <font-awesome-icon
                      icon="times"
                      class="not-attended"
                    />
                  </td>

                  <td v-if="!messageAttachmentToShow.responded">
                    <div
                      class="fileUpload2 btn btn-info btn-fill"
                      v-if="!pdfFormatAmendmentSuccess.includes(messageAttachmentToShow.id)"
                    >
                      <span>Choose Document..</span>
                      <input
                        @change.prevent="uploadAttachment($event , messageAttachmentToShow.id)"
                        class="upload"
                        id="first_upload22"
                        label="Upload File"
                        name="attachedFile"
                        type="file"
                      >
                    </div>
                    <div
                      v-if="pdfFormatAmendmentError.includes(messageAttachmentToShow.id) && !pdfFormatAmendmentSuccess.includes(messageAttachmentToShow.id)"
                      class="red-color"
                    >
                      Attach pdf document only
                    </div>

                    <pulse-loader
                      :color="color"
                      :loading="loading"
                      :size="size2"
                      v-if="pdfFormatAmendmentSuccess.includes(messageAttachmentToShow.id)"
                    />
                  </td>

                  <td
                    style="color:green;"
                    v-if="messageAttachmentToShow.responded"
                  >
                    Uploaded
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <br v-if="allowEdit">

          <div
            class="text-center row"
            v-if="allowEdit"
          >
            <div class="col-md-6 text-right loader-div-gif">
              <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                v-if="sendRequestLoader"
              />
            </div>
            <div class="col-md-6 text-right">
              <button
                type="submit"
                class="btn btn-warning btn-space"
                data-dismiss="modal"
                @click.prevent="hideAllowEdit"
              >
                Back to Composer
              </button>
              <button
                class="btn btn-default btn-fill"
                @click.prevent="closeModal"
              >
                Close
              </button>
            </div>
          </div>

          <!--     END EDIT SPECIFIC MESSAGE     -->
        </form>

        <!--     EDIT FORM       -->
        <div v-if="EditModalDiv">
          <card v-if="!ifRequestSubmitted">
            <form
              @submit.prevent="editRequest"
              method="POST"
              v-if="EditModalDiv"
            >
              <div class="row">
                <div class="col-md-6">
                  <base-input
                    class="special-bottom"
                    label="Shipping Document Number (BL/Airway Bill/RCN)"
                    placeholder="Shipping Document Number (BL/Airway Bill/RCN)"
                    type="text"
                    v-model="editBlNumber"
                    value="editBlNumber"
                  />
                  <div v-if="requestNameError">
                    <div
                      class="error"
                      v-if="!$v.editBlNumber.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <label
                    for="myselect"
                    style="margin-top: 7px"
                  >Select regime type</label>
                  <select
                    class="form-control"
                    id="myselect1"
                    name="myselect1"
                    ref="editRegime"
                  >
                    <option
                      disabled
                      value=""
                    >
                      Select regime type
                    </option>
                    <option
                      :value="regime.regimeId"
                      :key="index"
                      v-for="(regime, index) in regimes"
                      :selected="regimeId === regime.regimeId"
                    >
                      {{ regime.regimeName }}
                    </option>
                  </select>
                  <div v-if="requestUniqueIdentifierError">
                    <div
                      class="error"
                      v-if="!$v.editRegime.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-12">
                  <label>Application Details</label>
                  <textarea
                    class="special-bottom form-control col-md-12"
                    placeholder="Request Details"
                    rows="10"
                    type="textarea"
                    v-model="description"
                    value="description"
                  />
                  <div v-if="descriptionError">
                    <div
                      class="error"
                      v-if="!$v.description.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
              </div>

              <br>

              <div class="text-center row">
                <div class="col-md-4 text-right loader-div">
                  <span v-if="sendRequestLoader">Please wait ....</span>
                </div>
                <div class="col-md-2 loader-div-gif">
                  <pulse-loader
                    :color="color"
                    :loading="loading"
                    :size="size"
                    v-if="sendRequestLoader"
                  />
                </div>
                <div
                  class="col-md-6"
                  v-if="EditModalDiv"
                >
                  <button
                    @click="closeModal()"
                    class="btn btn-fill btn-outline-info float-right"
                    type="button"
                  >
                    Close
                  </button>
                  <button
                    class="btn btn-primary btn-space  float-right"
                    data-dismiss="modal"
                    type="submit"
                  >
                    Edit
                  </button>
                  <button
                    @click.prevent="showAddOrRemoveAttachment"
                    class="btn btn-primary btn-space  float-right"
                    data-dismiss="modal"
                    type="submit"
                  >
                    Add / Remove Attachment
                  </button>
                </div>
              </div>
            </form>
          </card>

          <br v-if="ifRequestSubmitted">

          <div
            class="col-md-12 alert-dismissible fade show"
            v-if="ifRequestSubmitted"
            role="alert"
            style="border-radius:5px;"
            :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
            @enter="enter"
          >
            <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button
              aria-label="Close"
              class="close close-custom"
              data-dismiss="alert"
              style="line-height:2px;"
              type="button"
              v-if="SuccessAlert"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              aria-label="Close"
              class="close close-custom"
              data-dismiss="alert"
              style="line-height:2px;"
              type="button"
              v-if="ErrorAlert"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <card v-if="ifRequestSubmitted">
            <div
              class="col-md-12"
              v-if="ifRequestSubmitted"
            >
              <div class="row">
                <div class="col-md-10">
                  Attach document(s) for supporting application for [ <b>{{ editBlNumber }}</b> ]
                </div>
                <div class="col-md-2 text-right">
                  <pulse-loader
                    :color="color"
                    :loading="loading"
                    :size="size"
                    v-if="loadAttachmentLoader"
                  />
                </div>
              </div>
            </div>

            <hr v-if="ifRequestSubmitted">

            <div v-if="ifRequestSubmitted">
              <table
                class="table table-striped"
                v-if="responseAttachmentSingle.length > 0"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th>Attachment Type</th>
                    <th scope="col">
                      Download url
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :key="index"
                    v-for="(attachment , index) in responseAttachmentSingle"
                  >
                    <th scope="row">
                      {{ index + 1 }}
                    </th>
                    <td>{{ attachment.attachmentName }}</td>
                    <td
                      class="text-primary"
                      @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)"
                    >
                      <a href=""><span class="text-success">view</span></a>
                    </td>
                    <td class="text-success">
                      Uploaded
                    </td>
                    <td>
                      <div
                        @click.prevent="deleteSingleRequestAttachment(attachment.requestAttachmentId, editBlNumber ,
                                                                      attachment.attachmentName)"
                        class="all-fa-icons fa-icons-space delete-fa-icon"
                        title="remove"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <form
              enctype="multipart/form-data"
              v-if="ifRequestSubmitted"
            >
              <div class="row">
                <div class="col-md-4">
                  <label>Search attachment type</label>
                  <model-list-select
                    :list="options"
                    v-model="documentTypeIdSingle"
                    class="form-control"
                    option-value="attachmentTypeId"
                    option-text="attachmentTypesName"
                    placeholder="attachment type"
                  />

                  <div v-if="documentTypeIdSingleError">
                    <div class="error">
                      Select document type
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="fileUpload btn btn-info btn-fill">
                    <span>Choose Document..</span>
                    <input
                      @change.prevent="EditAttachment($event , requestId , editBlNumber)"
                      class="upload"
                      id="first_upload"
                      label="Upload File"
                      name="attachment"
                      type="file"
                    >
                  </div>
                  <label
                    for="first_upload"
                    class="label-upload"
                  >
                    {{ file ? '" ' + file + ' "' : 'No File Selected' }} </label>
                  <div
                    v-if="pdfFormatSingleError"
                    class="red-color"
                  >
                    Attach pdf document only
                  </div>
                </div>
                <div class="col-md-2">
                  <pulse-loader
                    :color="color"
                    :loading="loading"
                    :size="size"
                    class="float-center"
                    style="margin-top: 38px"
                    v-if="uploadOneByOneSingle"
                  />
                </div>
              </div>
            </form>
          </card>

          <div class="text-center row">
            <div
              class="col-md-12"
              v-if="ifRequestSubmitted"
            >
              <button
                @click="closeAttachModal()"
                class="btn btn-fill btn-outline-info btn-space float-right"
                type="button"
              >
                Send
              </button>
            </div>
          </div>
        </div>

        <!--      ADD FORM      -->
        <div v-if="AddModalDiv">
          <div class="content">
            <div class="col-md-12">
              <card v-if="!ifRequestSubmitted">
                <form
                  @submit.prevent="saveRequest"
                  method="post"
                  v-if="!ifRequestSubmitted"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <label for="myselect">Select application type</label>
                      <select
                        class="form-control"
                        id="myselect"
                        name="myselect"
                        v-model="request.requestTypeId"
                        @change="changeMyTittle(request.requestTypeId)"
                      >
                        <option
                          disabled
                          value=""
                        >
                          Select application type
                        </option>
                        <option
                          :value="RequestType.requestTypeId"
                          :key="index"
                          v-for="(RequestType, index) in getRequestType"
                        >
                          {{ RequestType.requestTypeName }}
                        </option>
                      </select>
                      <div v-if="requestTypeIdError">
                        <div
                          class="error"
                          v-if="!$v.request.requestTypeId.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <label>Search Regime</label>
                      <model-list-select
                        :list="regimes"
                        v-model="request.requestUniqueIdentifier"
                        class="form-control"
                        option-value="regimeId"
                        option-text="regimeName"
                        placeholder="regime name"
                      />
                      <div v-if="requestUniqueIdentifierError">
                        <div
                          class="error"
                          v-if="!$v.request.requestUniqueIdentifier.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="row dynamic-bg"
                    v-for="(row , index) in rows"
                    :key="index"
                  >
                    <div class="col-md-12">
                      <hr>
                      <b>{{ index + 1 }}</b>
                    </div>

                    <div class="col-md-10">
                      <base-input
                        class="special-bottom"
                        :label="myTittle"
                        :placeholder="myTittle"
                        type="text"
                        v-model="row.requestName"
                      />
                      <div v-if="requestNameError">
                        <div
                          class="error"
                          v-if="row.requestName === '' "
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-md-2 text-center remove-bl"
                      title="remove BL/Airway Bill/RCN"
                      @click="deleteRow(row)"
                    >
                      <font-awesome-icon
                        class="delete-fa-icon minus-bl"
                        icon="trash-alt"
                      />
                    </div>

                    <div class="col-md-12">
                      <label>Application Details</label>
                      <textarea
                        class="special-bottom form-control col-md-12"
                        placeholder="Request Details"
                        rows="8"
                        type="textarea"
                        v-model="row.description"
                      />
                      <div v-if="descriptionError">
                        <div
                          class="error"
                          v-if="row.description === '' "
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 add-bl"
                    style="padding-right: 70px"
                    title="add BL/Airway Bill/RCN"
                    @click="addRow"
                  >
                    <font-awesome-icon
                      class="plus-bl"
                      icon="plus"
                      title="add BL/Airway Bill/RCN"
                    />
                  </div>

                  <hr>

                  <div class="text-center row">
                    <div class="col-md-4 text-right loader-div">
                      <span v-if="sendRequestLoader">Please wait ....</span>
                    </div>
                    <div class="col-md-2 left loader-div-gif">
                      <pulse-loader
                        :color="color"
                        :loading="loading"
                        :size="size"
                        v-if="sendRequestLoader"
                      />
                    </div>
                    <div
                      class="col-md-6"
                      v-if="AddModalDiv"
                    >
                      <button
                        @click="closeModal()"
                        class="btn btn-fill btn-outline-info float-right"
                        type="button"
                        v-if="!ifRequestSubmitted"
                      >
                        Close
                      </button>
                      <button
                        class="btn btn-primary btn-space  float-right"
                        data-dismiss="modal"
                        type="submit"
                      >
                        Continue to attachment
                      </button>
                    </div>
                  </div>
                </form>
              </card>

              <br v-if="ifRequestSubmitted">

              <card
                v-if="ifRequestSubmitted"
                v-for="(request , index) in requestIdArray"
                :key="index"
              >
                <div
                  class="col-md-12 alert-dismissible fade show"
                  v-if="displayFeedback.includes(request.requestMyId)"
                  role="alert"
                  style="border-radius:5px;"
                  :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
                  @enter="enter"
                >
                  <strong v-if="SuccessAlert">Successifully!</strong> <span
                    v-if="SuccessAlert"
                  >{{ SuccessMessage }}</span>
                  <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

                  <button
                    aria-label="Close"
                    class="close close-custom"
                    data-dismiss="alert"
                    style="line-height:2px;"
                    type="button"
                    v-if="SuccessAlert"
                    @click="fadeMe"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                  <button
                    aria-label="Close"
                    class="close close-custom"
                    data-dismiss="alert"
                    style="line-height:2px;"
                    type="button"
                    v-if="ErrorAlert"
                    @click="fadeMe"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div
                  class="col-md-12"
                  v-if="ifRequestSubmitted"
                >
                  <div class="row">
                    <div class="col-md-10">
                      Attach document(s) for supporting application with BL/Airway Bill/RCN
                      [ <b>{{ requestBlArray[index] }}</b> ]
                    </div>
                    <div class="col-md-2 text-right">
                      <!-- <pulse-loader :color="color" :loading="loading" :size="size" v-if="loadAttachmentLoader">
                         </pulse-loader>-->
                    </div>
                  </div>
                </div>

                <hr v-if="ifRequestSubmitted">

                <div v-if="ifRequestSubmitted">
                  <table
                    class="table table-striped"
                    v-if="request.responseAttachment.length > 0"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          #
                        </th>
                        <th scope="col">
                          Attachment Type
                        </th>
                        <th scope="col">
                          Download url
                        </th>
                        <th scope="col">
                          Status
                        </th>
                        <th scope="col">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="index"
                        v-for="(attachment , index) in request.responseAttachment"
                        v-if="attachment.requestId === request.requestMyId"
                      >
                        <th scope="row">
                          {{ index + 1 }}
                        </th>
                        <td>{{ attachment.attachmentName }}</td>
                        <td
                          class="text-primary"
                          @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)"
                        >
                          <a href=""><span class="text-success">view</span></a>
                        </td>
                        <td class="text-success">
                          Uploaded
                        </td>
                        <td>
                          <div
                            @click.prevent="deleteArrayRequestAttachment(attachment.requestAttachmentId ,
                                                                         request.requestMyId , requestBlArray[index] , attachment.attachmentName)"
                            class="all-fa-icons fa-icons-space delete-fa-icon"
                            title="remove"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <form
                  enctype="multipart/form-data"
                  v-if="ifRequestSubmitted"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <label>Search attachment type</label>

                      <model-list-select
                        :list="options"
                        v-model="documentTypeId[request.requestMyId]"
                        class="form-control"
                        option-value="attachmentTypeId"
                        option-text="attachmentTypesName"
                        placeholder="attachment type"
                      />

                      <div v-if="documentTypeIdErrorMany.includes(request.requestMyId)">
                        <div class="error">
                          Select document type
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="fileUpload btn btn-info btn-fill">
                        <span>Choose Document..</span>
                        <input
                          @change.prevent="saveAttachment($event , request.requestMyId , requestBlArray[index])"
                          class="upload"
                          id="first_upload2"
                          label="Upload File"
                          name="attachment"
                          type="file"
                        >
                      </div>
                      <label
                        for="first_upload2"
                        class="label-upload"
                        v-if="uploadOneByOne.includes(request.requestMyId)"
                      >
                        {{ file ? '" ' + file + ' "' : 'No File Selected' }} </label>
                      <div
                        v-if="pdfFormatError.includes(request.requestMyId)"
                        class="red-color"
                      >
                        Attach pdf document only
                      </div>
                    </div>
                    <div class="col-md-2">
                      <pulse-loader
                        :color="color"
                        :loading="loading"
                        :size="size"
                        class="float-center"
                        style="margin-top: 38px"
                        v-if="uploadOneByOne.includes(request.requestMyId)"
                      />
                    </div>
                  </div>
                </form>
              </card>
            </div>

            <div class="text-center row">
              <div
                class="col-md-12"
                v-if="ifRequestSubmitted"
              >
                <button
                  @click="closeAttachManyModal()"
                  class="btn btn-fill btn-outline-info btn-space float-right"
                  type="button"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--      SHOW FORM      -->
        <div v-if="ShowModalDiv">
          <card v-if="!loadingShowRequest">
            <div class="row">
              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 text-bold">
                    <b>Application Type</b>
                  </div>
                  <div class="col-md-12 text-value special-data-bg">
                    {{ singleRequest.requestTypeName }}
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="row"
                  v-if="typeof singleRequest.regime !== 'undefined'"
                >
                  <div class="col-md-12 text-bold">
                    <b>Regime Type</b>
                  </div>
                  <div
                    class="col-md-12 text-value special-data-bg"
                    v-if="singleRequest.regime !== null"
                  >
                    {{ singleRequest.regime.regimeName }}
                  </div>
                  <div
                    class="col-md-12 text-value special-data-bg"
                    v-else=""
                  >
                    <i>NIL</i>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="row">
                  <div class="col-md-12 text-bold">
                    <b>Application BL/Airway Bill/RCN</b>
                  </div>
                  <div class="col-md-12 text-value special-data-bg">
                    {{ singleRequest.blNumber }}
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12 text-bold">
                <b>Description</b>
              </div>
              <div
                class="col-md-12 text-value special-data-bg"
                v-if="singleRequest.description === null ||
                  singleRequest.description === '' "
              >
                <i class="text-muted">NIL</i>
              </div>
              <div class="col-md-12 text-value special-data-bg">
                {{ singleRequest.description }}
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-10">
                    <b>Associated Attachment(s)</b>
                  </div>
                  <div class="col-md-2 text-right">
                    <pulse-loader
                      :color="color"
                      :loading="loading"
                      :size="size"
                      v-if="loadAttachmentLoader"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <table
                    class="table table-striped"
                    v-if="responseAttachmentSingle.length > 0"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          #
                        </th>
                        <th>Attachment Type</th>
                        <th scope="col">
                          Download url
                        </th>
                        <th scope="col">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr :key="index" v-for="(attachment , index) in responseAttachmentSingle"
                      >
                        <th scope="row">
                          {{ index + 1 }}
                        </th>
                        <td>{{ attachment.attachmentName }}</td>
                        <td
                          class="text-primary" @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)"
                        >
                          <a href=""><span class="text-success">view</span></a>
                        </td>
                        <td class="text-success">
                          Uploaded
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div
                    class="table table-striped"
                    v-if="responseAttachmentSingle.length === 0"
                  >
                    <div style="margin-left: 15px">
                      No attachment
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <b>Application progress</b>
              </div>
              <div class="col-md-12 text-value special-data-bg">
                status:
                <span
                  title="Application Sent"
                  v-if="singleRequest.status === 'Request Submitted' "
                >
                  Application Submitted <font-awesome-icon
                    class="received-request"
                    icon="circle"
                  />
                </span>
                <span
                  :title="singleRequest.status"
                  v-if="singleRequest.status === 'Plan Approved' "
                >
                  {{ singleRequest.status }} <font-awesome-icon
                    class="approved-plan"
                    icon="circle"
                  />
                </span>
                <span
                  title="Application Returned For Major Changes"
                  v-if="singleRequest.status === 'Request Returned For Major Changes' "
                >
                  Application Returned For Major Changes <font-awesome-icon
                    class="declined-request"
                    icon="circle"
                  />
                </span>
                <span
                  title="Application Returned For Minor Changes"
                  v-if="singleRequest.status === 'Request Returned For Minor Changes' "
                >
                  Application Returned For Minor Changes <font-awesome-icon
                    class="declined-request"
                    icon="circle"
                  />
                </span>
                <span
                  title="Application Resubmitted After Changes"
                  v-if="singleRequest.status === 'Request Resubmitted After Changes' "
                >
                  Application Resubmitted After Changes <font-awesome-icon
                    class="request-after-changes"
                    icon="circle"
                  />
                </span>
                <span
                  title="Request Accepted"
                  v-if="singleRequest.status === 'Request Accepted' "
                >
                  Application Accepted <font-awesome-icon
                    class="accepted-request"
                    icon="circle"
                  />
                </span>
                <span
                  v-if="singleRequest.status === 'Request Assessed' "
                  title="Application Assessed"
                >
                  Application Assessed <font-awesome-icon
                    icon="circle"
                    class="request-in-action"
                  />
                </span>
                <span
                  v-if="singleRequest.status === 'Request Assessment Rejected' "
                  title="Rejected Application Assessment"
                >
                  Rejected Application Assessment <font-awesome-icon
                    icon="circle"
                    class="rejected-app-assess"
                  />
                </span>
                <span
                  v-if="singleRequest.status === 'Request Charged' "
                  title="Application Charged"
                >
                  Application Charged <font-awesome-icon
                    icon="circle"
                    class="request-charged"
                  />
                </span>
                <span
                  v-if="singleRequest.status === 'Request Assessment Sent To GEPG' "
                  title="Application Bill Approved and Sent To GEPG"
                >
                  Application Bill Approved and Sent To GEPG
                  <font-awesome-icon
                    icon="circle"
                    class="app-bill-approved"
                  />
                </span>
                <span
                  v-if="singleRequest.status === 'Request Bill Paid' "
                  title="Application Bill Paid"
                >
                  Application Bill Paid <font-awesome-icon
                    icon="circle"
                    class="app-bill-paid"
                  />
                </span>
                <span
                  v-if="singleRequest.status === 'Request Billed' "
                  title="Application Billed"
                >
                  Application Billed <font-awesome-icon
                    icon="circle"
                    class="request-billed"
                  />
                </span>
                <span
                  v-if="singleRequest.status === 'Request Closed' "
                  title="Application Closed"
                >
                  Application Closed <font-awesome-icon
                    icon="circle"
                    class="app-closed"
                  />
                </span>
              </div>
            </div>

            <hr>

            <div
              class="text-center row"
              v-if="!docViewer"
            >
              <div class="col-md-12">
                <button
                  @click="closeModal()"
                  class="btn btn-default btn-fill float-right"
                >
                  Back
                </button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </modal>

    <modal v-if="showConfirmModal">
      <div
        class="modal-title col-md-12"
        slot="header"
      />

      <div slot="body">
        <!--      CONFIRM LEAVING WITHOUT ATTACHING ANY DOC   -->
        <div v-if="ShowConfirmModalDiv">
          <card class="text-center">
            <p> {{ attachmentAlertMessage }} </p>

            <br><br><br>

            <div class="text-center row">
              <div class="col-md-12 text-center">
                <button
                  @click="closeModal()"
                  class="btn btn-fill btn-outline-info btn-space float-right"
                  type="button"
                >
                  No!, Send
                </button>
                <button
                  @click="closeBackModal()"
                  class="btn btn-success btn-space float-right"
                  type="button"
                >
                  Yes!
                </button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </modal>

    <modal v-if="showConfirmManyModal">
      <div
        class="modal-title col-md-12"
        slot="header"
      />

      <div slot="body">
        <!--      MANY CONFIRM LEAVING WITHOUT ATTACHING ANY DOC   -->
        <div v-if="ShowConfirmManyModalDiv">
          <card class="text-center">
            <p> {{ attachmentManyAlertMessage }} </p>

            <br><br><br>

            <div class="text-center row">
              <div class="col-md-12 text-center">
                <button
                  @click="closeModal()"
                  class="btn btn-fill btn-outline-info btn-space float-right"
                  type="button"
                >
                  No!, Send
                </button>
                <button
                  @click="closeBackModal()"
                  class="btn btn-success btn-space float-right"
                  type="button"
                >
                  Yes!
                </button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </modal>
    <global-modal v-if="globalLoader">
          <div slot="globalLoader">
            <div class="row text-center">
              <div class="col-md-12">
                Downloading requested file, Please wait
              </div>
              <div class="col-md-12">
                <br>
              </div>
              <div class="col-md-12">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                />
              </div>
            </div>
          </div>
    </global-modal>
  </div>
</template>

<script>

import API from '../../../api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import ModalConfirm from '../../../components/Inputs/ModalConfirmExit'
import ModalEditPlan from '../../../components/Inputs/ModalEditPlan'
import { mapActions, mapGetters } from 'vuex';
import { ModelListSelect } from 'vue-search-select'
import { required } from 'vuelidate/lib/validators'
import GlobalModal from '../../../components/globalLoader'
import {CFA_REQUEST_STATUSE} from "../../../mixins/constants";

export default {

  name: 'ClearingRequest',

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Applications List')

    if (this.getChatRoomPage === 'hasChatRoom') {
      this.$store.dispatch('notifications/chatRoomPage', '')
      this.requestMessages = []
      this.getAllMessagesPerRequest(this.$route.params.requestId)

      this.EditModalDiv = false
      this.showFlag = true
      this.AddModalDiv = false
      this.ShowModalDiv = false
      this.showModal = true
      this.showConfirmModal = false
      this.ShowConfirmModalDiv = false
      this.ShowConfirmManyModalDiv = false
      this.showConfirmManyModal = false
      this.showRejectedMessage = false
    } else {
      if (this.$route.params.requestId) {
        this.ifThereIsParam = this.$route.params.requestId

        this.loadASingleRequestFromNotification(this.$route.params.requestId)
      } else {
        this.loadPageableRequest(this.pageNo, this.pageSize)
      }

      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      this.loadDocumentType()
      this.loadRequestType()
      this.loadRegime()
    }
  },

  updated () {
    this.scrollToElement()
  },

  mounted () {
    this.scrollToElement()
  },

  data () {
    return {

      rows: [
        { requestName: '', description: '' }
      ],

      ifThereIsParam: '',
      loader: false,
      color: 'green',
      size: '15px',
      size2: '13px',
      loading: true,
      sendRequestLoader: false,
      attachLoader: false,
      uploadOneByOne: [],
      displayFeedback: [],
      uploadOneByOneSingle: false,
      uploadedMessage: '',
      userDetails: {},

      request: {

        requestName: '',
        description: '',
        customerId: '',
        requestTypeId: '',
        status: 'SUBMITTED',
        isAmended: 'DEFAULT_AMENDED',
        requestUniqueIdentifier: ''

      },

      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'requestId',
      sortBy: 'requestId',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      myTittle: 'Shipping Document Number (BL/Airway Bill/RCN)',

      documentTypeId: [],
      documentTypeIdSingle: '',
      file: '',
      fileData: '',
      options: [],
      getDocType: [],
      getRequestType: [],
      responseAttachment: [],
      responseAttachmentSingle: [],
      requests: [],
      singleRequest: {},
      requestSeached: {},
      finalUserPermissions: [],
      permissionGroups: [],
      requestBlArray: [],
      requestIdArray: [],
      incomingData: [],
      documentTypeIdErrorMany: [],
      editRegime: '',

      requestMessages: [],
      hasThisId: [],
      tasacRejectMessageNew: '',
      allowEdit: false,
      attachmentsPerRequest: [],
      attachmentDetails: '',
      severity: '',
      additional: '',
      attachmentDetailsError: false,
      severityError: false,
      additionalError: false,
      rejectedMessageError: false,
      loadAllHeaderLoader: false,
      blNumber: '',
      fileNo: '',
      loadSingleEditableMessage: [],
      requestSingleMessage: {},
      heldMessageId: '',
      pdfFormatAmendmentError: [],
      pdfFormatAmendmentSuccess: [],
      tasacRejectMessageResponce: '',
      file2: '',
      fileData2: '',

      requestName: '',
      description: '',
      RequestTypeId: '',
      requestId: '',
      status: '',
      requestDate: '',
      rejectedMessage: '',
      customerId: '',
      rejectReason: '',
      editBlNumber: '',
      editFileNo: '',
      editRequestNumber: '',
      editCustomerId: '',
      stationId: '',
      transportMethodId: '',
      approveDate: '',
      approvedBy: '',
      regimeId: '',
      getAttachmentTypeName: '',
      attachmentAlertMessage: '',
      attachmentManyAlertMessage: '',
      isAmended: '',

      responseRequestName: '',

      SuccessMessage: '',
      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      deciderReturn: false,
      showFlag: false,

      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,

      showConfirmModal: false,
      showConfirmManyModal: false,

      ShowConfirmModalDiv: false,
      ShowConfirmManyModalDiv: false,

      ifRequestSubmitted: false,
      ifRequestSubmittedEdited: false,
      loadingShowRequest: false,
      showRejectedMessage: false,
      pdfFormatError: [],
      pdfFormatSingleError: false,
      showSection: true,
      requestNameError: false,
      descriptionError: false,
      documentTypeIdError: false,
      documentTypeIdSingleError: false,
      requestTypeIdError: false,
      requestUniqueIdentifierError: false,
      docViewer: false,
      loadAttachmentLoader: false,
      hasUserUploadedAnyThing: false,
      applicationStatuses: CFA_REQUEST_STATUSE,


      listFileNo: '',
      listBlNumber: '',
      loadAllResourceAndPlans: false,
      ShowNonAndMonetaryListPlan: false,
      loadSavedNonMonetaryActivity: [],
      globalLoader: false,
    }
  },

  computed: {

    ...mapGetters('clearingrequests', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete',
      'getRequestId',
      'getAttachmentNameBeforeDelete',
      'getCustomerNameBeforeDelete'

    ]),

    ...mapGetters('notifications', [

      'getCustomerPage',
      'getChatRoomPage'

    ]),

    ...mapGetters('global', [

      'getGlobalLoader'

    ]),

    checkNotification () {
      if (this.getCustomerPage === 'hasCustomer') {
        this.$store.dispatch('notifications/customerPage', '')

        return this.loadASingleRequestFromNotification(this.$route.params.requestId)
      }

      if (this.getChatRoomPage === 'hasChatRoom') {
        this.$store.dispatch('notifications/chatRoomPage', '')
        this.requestMessages = []
        this.getAllMessagesPerRequest(this.$route.params.requestId)

        this.EditModalDiv = false
        this.showFlag = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.showModal = true
        this.showConfirmModal = false
        this.ShowConfirmModalDiv = false
        this.ShowConfirmManyModalDiv = false
        this.showConfirmManyModal = false
        this.showRejectedMessage = false
      }
    }

  },

  validations: {

    request: {

      requestTypeId: { required },
      requestName: { required },
      description: { required },
      requestUniqueIdentifier: { required }

    },

    searchParameter: { required },

    tasacRejectMessageNew: { required },

    requestTypeId: { required },
    editBlNumber: { required },
    description: { required },
    documentTypeId: { required }

  },

  components: {

    PulseLoader,
    Modal,
    ModalEditPlan,
    ModelListSelect,
    ModalConfirm,
    assessmentDetails: {},
    GlobalModal,

  },

  methods: {

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader

        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

    viewAssesment (data) {
      this.loadSavedNonMonetaryActivity = data.activityLogs

      this.openActivitiesModal(data, 'activities')
    },

    getAllMessagesPerRequest (data) {
      this.loadAllHeaderLoader = true

      API.getMessagesOnChatRoomPerRequest(data).then(
        response => {
          if (response.data.status) {
            this.requestMessages = response.data.data

            this.clearMessageAsRead(data)
            this.getRequestDetails(data)

            this.loadAllHeaderLoader = false
          } else {

          }
        }
      ).catch(() => {

      })
    },

    getRequestDetails (data) {
      API.getSingleRequest(data).then(
        response => {
          if (response.data.status) {
            this.requestId = response.data.data.requestId
            this.blNumber = response.data.data.blNumber

            if (response.data.data.requestId !== null) { this.fileNo = response.data.data.fileNo } else this.fileNo = 'NIL'
          } else {

          }
        }
      ).catch(() => {

      })
    },

    clearMessageAsRead (data) {
      API.markChatMessagesAsRead(data).then(
        response => {
          if (response.data.status) {

          } else {

          }
        }
      ).catch(() => {

      })
    },

    scrollToElement () {
      if (this.$refs.myMessageDiv) {
        const content = this.$refs.myMessageDiv

        content.scrollTop = content.scrollHeight
      }
    },

    showAllowEdit (data) {
      this.hasThisId = []
      this.allowEdit = true
      this.getSingleMessagePerRequest(data)
      this.hasThisId.push(data)
      this.heldMessageId = data
    },

    hideAllowEdit () {
      this.hasThisId = []
      this.allowEdit = false
      this.requestSingleMessage = {}
    },

    getSingleMessagePerRequest (data) {
      this.loadSingleEditableMessage.push(data)

      API.getSingleMessageChatPerRequest(data).then(
        response => {
          if (response.data.status) {
            this.requestSingleMessage = response.data.data

            this.loadSingleEditableMessage = []
          } else {

          }
        }
      ).catch(() => {

      })
    },

    messageChatRoom () {
      if (this.$v.tasacRejectMessageNew.$invalid) {
        this.rejectedMessageError = true
      } else {
        this.sendRequestLoader = true

        const myObject = {

          comment: this.tasacRejectMessageNew,
          requestCommentAttachmentDtos: [],
          requestId: Number(this.requestId)
        }

        API.sendNewMessageOnChatRoom(JSON.stringify(myObject)).then(
          response => {
            if (response.data.status) {
              this.tasacRejectMessageNew = ''
              this.sendRequestLoader = false

              this.getAllMessagesPerRequest(this.requestId)
            } else {
              this.tasacRejectMessageNew = ''
              this.requestId = ''
              this.sendRequestLoader = false
            }
          }
        ).catch(() => {
          this.tasacRejectMessageNew = ''
          this.requestId = ''
          this.sendRequestLoader = false
        })
      }
    },

    toggle () {
      this.showSection = !this.showSection
    },

    changeMyTittle (flag) {
      API.getIndividualRequestType(flag).then(
        response => {
          if (response.data.status) {
            if (response.data.data.requestTypeFlag === 'EXP') {
              this.myTittle = 'REFERENCE NUMBER'
            } else {
              this.myTittle = 'Shipping Document Number (BL/Airway Bill/RCN)'
            }
          } else {

          }
        }
      ).catch(e => {

      })
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize)
        }
      }
      this.docPageNo = this.pageNo
      this.docPageSize = this.pageSize
      this.docPageCount = this.pageCount
      this.docSortBy = this.sortBy
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize)
        }
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize)
        }
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize)
        }
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
      } else {
        if (this.$route.params.requestId) {
          this.ifThereIsParam = this.$route.params.requestId

          this.loadASingleRequestFromNotification(this.$route.params.requestId)
        } else {
          this.loadPageableRequest(this.pageNo, this.pageSize)
        }
      }
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableRequest(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParameter = ''
      }
    },

    reloadByIcon () {
      this.loadPageableRequest(this.pageNo, this.pageSize)

      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParameter = ''

      this.$router.replace('/clearing-and-forwarding/applications/')
    },

    searchRequestByName (pageNo, pageSize, sortBy) {
      if (this.$v.searchParameter.$invalid) {

      } else {
        this.myloader = true
        this.reloadAllRequest = true
        this.hasSearchedVariable = true
        this.noDataFound = false

        API.searchCustomerRequest(pageNo, pageSize, sortBy, this.searchParameter,
          window.$cookies.get('sidebar-custom-width-pixel').split('x')[1]).then(
          response => {
            if (response.data.status) {
              this.myloader = false

              if (response.data.data.length > 0) {
                this.requests = response.data.data
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              } else {
                this.noDataFound = true
                this.pageCount = response.data.message
                this.docPageCount = this.pageCount
                this.docPageNo = this.pageNo
                this.docPageSize = this.pageSize
                this.myloader = false
              }
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getPageableDoc = response.data.data
              this.myloader = false
            }
          }
        ).catch(e => {
          this.myloader = false
        })
      }
    },

    loadPageableRequest (pageNo, pageSize) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getPageableRequestForSingleCustomer(pageNo, pageSize,
          window.$cookies.get('sidebar-custom-width-pixel').split('x')[1]).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.requests = response.data.data
              this.myloader = false
            } else {
              this.myloader = false
            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded, please contact system administrator'
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    loadRegime () {
      API.getRegime().then(
        response => {
          if (response.data.status) {
            this.regimes = response.data.data
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Regimes not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    addRow: function () {
      this.rows.push({ requestName: '', description: '' })
    },
    deleteRow: function (row) {
      // this.rows.$remove(row);
      this.rows.splice(this.rows.indexOf(row), 1)
    },

    showModalRejectedMessage (data) {
      this.rejectedMessage = data
      this.showRejectedMessage = true
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.showModal = true
      this.ShowModalDiv = false
    },

    loadDocumentType () {
      API.getAttachments().then(
        response => {
          if (response.data.status) {
            this.options = response.data.data

            for (let i = 0; i < this.options.length; i++) {
              if (this.options[i].attachmentGroupCode === 'CRA') {
                this.options.splice(this.options.indexOf(this.options[i].attachmentTypeId), 1)
              }
            }

          } else {

          }
        }
      ).catch(e => {

      })
    },

    loadRequestType () {
      this.myloader = true

      API.getRequestType().then(
        response => {
          if (response.data.status) {
            this.getRequestType = response.data.data
            this.myloader = false
          } else {

          }
        }
      ).catch(() => {
        this.ErrorMessage = ', Application types not loaded, please contact system administrator'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    showAddOrRemoveAttachment () {
      this.ifRequestSubmitted = true
      this.deciderReturn = true
      this.hasUserUploadedAnyThing = true
      this.loadRequestAttachmentSingle(this.requestId)
    },

    saveRequest () {
      if (this.$v.request.requestUniqueIdentifier.$invalid || this.$v.request.requestTypeId.$invalid) {
        if (this.$v.request.requestTypeId.$invalid) {
          this.requestTypeIdError = true
        }
        if (this.$v.request.requestUniqueIdentifier.$invalid) {
          this.requestUniqueIdentifierError = true
        }
      } else {
        let errorFree = 0

        for (let w = 0; w < this.rows.length; w++) {
          if (this.rows[w].requestName === '' || this.rows[w].description === '') {
            if (this.rows[w].requestName === '') {
              this.requestNameError = true
            }
            if (this.rows[w].description === '') {
              this.descriptionError = true
            }

            errorFree++
          }
        }

        if (errorFree === 0) {
          this.sendRequestLoader = true

          const requestBls = []

          for (let y = 0; y < this.rows.length; y++) {
            const singleBl = {

              blNumber: this.rows[y].requestName,
              regimeId: this.request.requestUniqueIdentifier,
              description: this.rows[y].description

            }

            requestBls.push(singleBl)
          }

          const request = {

            customerId: window.$cookies.get('sidebar-custom-width-pixel').split('x')[1], // this.userDetails.customerId
            requestBls,
            requestName: null,
            requestTypeId: this.request.requestTypeId

          }

          API.postRequest(JSON.stringify(request)).then(
            response => {
              if (response.data.status) {
                this.sendRequestLoader = false
                this.incomingData = response.data.data
                this.request.requestUniqueIdentifier = ''
                this.request.requestTypeId = ''
                if (this.$route.params.requestId) {
                  this.ifThereIsParam = this.$route.params.requestId

                  this.loadASingleRequestFromNotification(this.$route.params.requestId)
                } else {
                  this.loadPageableRequest(this.pageNo, this.pageSize)
                }

                for (let ww = 0; ww < this.rows.length; ww++) {
                  this.rows[ww].requestName = ''
                  this.rows[ww].description = ''
                }

                for (let a = 0; a < this.incomingData.length; a++) {
                  this.requestBlArray.push(this.incomingData[a].blNumber)

                  const newObject = {

                    requestMyId: this.incomingData[a].requestId,
                    responseAttachment: []

                  }

                  this.requestIdArray.push(newObject)
                }

                // this.$store.dispatch("clearingrequests/requestid", response.data.data.requestId);

                setTimeout(() => {
                  this.ifRequestSubmitted = true
                  this.sendRequestLoader = false
                  this.hasUserUploadedAnyThing = true
                }, 2000)
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Application not added, please contact system administrator'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.sendRequestLoader = false
            this.request.requestName = ''
            this.request.description = ''
            this.closeModal()
          })
        }
      }
    },

    saveAttachment (event, requestId, responseRequestBl) {
      for (let z = 0; z < this.requestIdArray.length; z++) {
        if (this.requestIdArray[z].requestMyId === requestId) {
          this.fileData = event.target.files[0]

          if (this.documentTypeId[this.requestIdArray[z].requestMyId] === undefined ||
                    this.fileData.type !== 'application/pdf') {
            if (this.documentTypeId[this.requestIdArray[z].requestMyId] === undefined) {
              this.documentTypeIdErrorMany = [this.requestIdArray[z].requestMyId]
            }
            if (this.fileData.type !== 'application/pdf') {
              this.pdfFormatError = [this.requestIdArray[z].requestMyId]
            }
          } else {
            this.uploadOneByOne = [this.requestIdArray[z].requestMyId]
            this.displayFeedback = [this.requestIdArray[z].requestMyId]

            this.file = this.fileData.name

            const formData = new FormData()

            formData.append('file', this.fileData)
            formData.append('requestId', requestId)
            formData.append('attachmentTypeId', this.documentTypeId[this.requestIdArray[z].requestMyId])

            // setTimeout(() => {

            API.uploadRequestAttachment(formData).then(
              response => {
                if (response.data.status) {
                  this.SuccessMessage = ', Attachment [ ' + this.file + ' ] for application for ' +
                                  '[ ' + responseRequestBl + ' ] has been saved'
                  this.SuccessAlert = true
                  this.ErrorMessage = ''
                  this.ErrorAlert = false
                  this.fileData = ''
                  this.loadRequestAttachment(response.data.data.requestId)
                  this.pdfFormatError = []
                  this.uploadOneByOne = []
                  this.documentTypeId = []
                  this.documentTypeIdErrorMany = []
                  this.file = ''

                  if (this.deciderReturn) {
                    this.editRequest()
                  }

                  setTimeout(() => {
                    this.uploadedMessage = ''
                  }, 7000)
                } else {

                }
              }
            ).catch(() => {
              this.editMyLoader = false
              this.ErrorMessage = ' Attachment not uploaded, please contact system administrator'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
              this.attachLoader = false
            })

            // }, 2500);
          }
        }
      }
    },

    uploadAttachment (event2, attachmentId) {
      this.fileData2 = event2.target.files[0]

      if (this.fileData2.type !== 'application/pdf') {
        this.pdfFormatAmendmentError.push(attachmentId)
      } else {
        this.pdfFormatAmendmentSuccess.push(attachmentId)

        this.file2 = this.fileData2.name

        const formData = new FormData()

        formData.append('file', this.fileData2)
        formData.append('commentAttachmentId', attachmentId)

        API.uploadRequestedFile(formData, attachmentId).then(
          response => {
            if (response.data.status) {
              this.fileData2 = ''
              this.getSingleMessagePerRequest(this.heldMessageId)
              this.getAllMessagesPerRequest(this.requestId)
              this.pdfFormatAmendmentError = []
              this.pdfFormatAmendmentSuccess = []
              this.file2 = ''
            } else {

            }
          }
        ).catch(() => {

        })
      }
    },

    EditAttachment (event, requestId, responseRequestBl) {
      this.fileData = event.target.files[0]

      if (this.documentTypeIdSingle === '' || this.fileData.type !== 'application/pdf') {
        if (this.documentTypeIdSingle === '') {
          this.documentTypeIdSingleError = true
        }
        if (this.fileData.type !== 'application/pdf') {
          this.pdfFormatSingleError = true
        }
      } else {
        this.uploadOneByOneSingle = true

        this.file = this.fileData.name

        const formData = new FormData()

        formData.append('file', this.fileData)
        formData.append('requestId', requestId)
        formData.append('attachmentTypeId', this.documentTypeIdSingle)

        // setTimeout(() => {

        API.uploadRequestAttachment(formData).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ', Attachment [ ' + this.file + ' ] for application ' +
                              '[ ' + responseRequestBl + ' ] has been saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.fileData = ''
              this.loadRequestAttachmentSingle(response.data.data.requestId)
              this.pdfFormatSingleError = false
              this.documentTypeIdSingleError = false
              this.uploadOneByOneSingle = false
              this.documentTypeIdSingle = ''
              this.file = ''

              if (this.deciderReturn) {
                this.editRequest()
                this.hasUserUploadedAnyThing = true
              }

              setTimeout(() => {
                this.uploadedMessage = ''
              }, 7000)
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Attachment not uploaded, please contact system administrator'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.attachLoader = false
        })

        // }, 2500);
      }
    },

    loadRequestAttachment (data) {
      this.loadAttachmentLoader = true

      for (let q = 0; q < this.requestIdArray.length; q++) {
        if (this.requestIdArray[q].requestMyId === data) {
          API.getFullRequestDetails(data).then(
            response => {
              if (response.data.status) {
                this.requestIdArray[q].responseAttachment = response.data.data.requestAttachDtos

                this.loadAttachmentLoader = false
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ', Application attachment not loaded, please contact system administrator'
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.SuccessMessage = ''
            this.SuccessAlert = false
          })
        }
      }
    },

    loadASingleRequestFromNotification (data) {
      this.myloader = true

      this.reloadAllRequest = true

      API.getRequestFromNotification(data).then(
        response => {
          if (response.data.status) {
            this.requests = response.data.data

            this.myloader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ', Application attachment not loaded, please contact system administrator'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
      })
    },

    loadRequestAttachmentSingle (data) {
      this.loadAttachmentLoader = true

      API.getFullRequestDetails(data).then(
        response => {
          if (response.data.status) {
            this.singleRequest = response.data.data

            this.responseAttachmentSingle = response.data.data.requestAttachDtos

            this.loadAttachmentLoader = false
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ', Application attachment not loaded, please contact system administrator'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
      })
    },

    editRequest () {
      let stationId = ''
      let transportMethodId = ''
      let status = ''
      let isAmended = ''

      if (this.stationId === 0) {
        stationId = null
      } else {
        stationId = this.stationId
      }

      if (this.transportMethodId === 0) {
        transportMethodId = null
      } else {
        transportMethodId = this.transportMethodId
      }

      if (this.status === 'Request Returned For Major Changes' ||
                this.status === 'Request Returned For Minor Changes') {
        status = 'RETURNED'
      } else {
        status = this.status
      }

      if (this.isAmended === 'Request amendment') {
        isAmended = 'AMENDED'
      } else {
        isAmended = this.isAmended
      }

      if (this.deciderReturn) {
        this.sendRequestLoader = true

        const requestData = {

          approveDate: this.approveDate,
          approvedBy: this.approvedBy,
          blNumber: this.editBlNumber,
          customerId: this.customerId,
          description: this.description,
          fileNo: this.editFileNo,
          rejectReason: this.rejectReason,
          requestDate: this.requestDate,
          requestId: this.requestId,
          regimeId: this.$refs.editRegime.value,
          requestName: this.requestName,
          requestNumber: this.editRequestNumber,
          requestTypeId: this.requestTypeId,
          stationId: stationId,
          status: status,
          isAmended: isAmended,
          transportMethodId: transportMethodId

        }

        API.updateSingleRequest(JSON.stringify(requestData)).then(
          response => {
            if (response.data.status) {
              if (!this.hasUserUploadedAnyThing) {
                this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                                [response.data.data.blNumber, response.data.data.description], 'Request',
                                response.data.data.blNumber)}`
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
              }
              this.sendRequestLoader = false

              if (this.hasSearchedVariable) {
                this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
              } else {
                if (this.$route.params.requestId) {
                  this.ifThereIsParam = this.$route.params.requestId

                  this.loadASingleRequestFromNotification(this.$route.params.requestId)
                } else {
                  this.loadPageableRequest(this.pageNo, this.pageSize)
                }
              }

              window.scrollTo(10, 0)

              this.$store.dispatch('clearingrequests/valuesBeforeEdit', [])
              this.$store.dispatch('clearingrequests/requestid', response.data.data.requestId)
            } else {

            }
          }
        ).catch(e => {
          this.ErrorMessage = ', Application not edited, please contact system administrator'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = false
          this.sendRequestLoader = false
          this.request.requestName = ''
          this.request.description = ''
        })
      } else {
        if (this.$v.editBlNumber.$invalid || this.$v.description.$invalid) {
          if (this.$v.editBlNumber.$invalid) {
            this.requestNameError = true
          }
          if (this.$v.description.$invalid) {
            this.descriptionError = true
          }
        } else {
          this.sendRequestLoader = true

          const requestData = {

            approveDate: this.approveDate,
            approvedBy: this.approvedBy,
            blNumber: this.editBlNumber,
            customerId: this.customerId,
            description: this.description,
            fileNo: this.editFileNo,
            rejectReason: this.rejectReason,
            requestDate: this.requestDate,
            requestId: this.requestId,
            regimeId: this.$refs.editRegime.value,
            requestName: this.requestName,
            requestNumber: this.editRequestNumber,
            requestTypeId: this.requestTypeId,
            stationId: stationId,
            status: status,
            isAmended: this.isAmended,
            transportMethodId: transportMethodId

          }

          API.updateSingleRequest(JSON.stringify(requestData)).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                                [response.data.data.blNumber, response.data.data.description], 'Request',
                                response.data.data.blNumber)}`
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.sendRequestLoader = false
                this.closeModal()

                if (this.hasSearchedVariable) {
                  this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
                } else {
                  if (this.$route.params.requestId) {
                    this.ifThereIsParam = this.$route.params.requestId

                    this.loadASingleRequestFromNotification(this.$route.params.requestId)
                  } else {
                    this.loadPageableRequest(this.pageNo, this.pageSize)
                  }
                }

                window.scrollTo(10, 0)
                this.closeModal()

                this.$store.dispatch('clearingrequests/valuesBeforeEdit', [])
                this.$store.dispatch('clearingrequests/requestid', response.data.data.requestId)
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Application not edited, please contact system administrator'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
            this.sendRequestLoader = false
            this.request.requestName = ''
            this.request.description = ''
            this.closeModal()
          })
        }
      }
    },

    deleteSingleRequestAttachment (data, blNumber, AttachmentName) {
      this.$store.dispatch('clearingrequests/customerNameBeforeDelete', blNumber)
      this.$store.dispatch('clearingrequests/attachmentNameBeforeDelete', AttachmentName)

      this.$dialog
        .confirm(
          'If you delete attachment [ ' +
                        AttachmentName +
                        " ], it'll be gone forever.",
          {
            loader: true
          }
        )
        .then(dialog => {
          // setTimeout(() => {

          API.deleteRequestAttachment(data)
            .then(response => {
              if (response.data.status) {
                this.SuccessMessage = ', Attachment [ ' + this.getAttachmentNameBeforeDelete + '] ' +
                                      'has been removed from BL/Airway Bill/RCN  [ ' + this.getCustomerNameBeforeDelete + ' ]'
                this.$store.dispatch('clearingrequests/customerNameBeforeDelete', '')
                this.$store.dispatch('clearingrequests/attachmentNameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.loadRequestAttachmentSingle(this.requestId)

                if (this.deciderReturn) {
                  this.editRequest()
                }

                window.scrollTo(10, 0)
              } else {

              }
            })
            .catch(() => {
              this.ErrorMessage = ', Record not deleted, please contact system administrator'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
            })

          dialog.close()

          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    deleteArrayRequestAttachment (data, myRequestId, blNumber, AttachmentName) {
      for (let k = 0; k < this.requestIdArray.length; k++) {
        if (this.requestIdArray[k].requestMyId === myRequestId) {
          this.$store.dispatch('clearingrequests/customerNameBeforeDelete', blNumber)
          this.$store.dispatch('clearingrequests/attachmentNameBeforeDelete', AttachmentName)

          this.$dialog
            .confirm(
              'If you delete attachment [ ' +
                            AttachmentName +
                            " ], it'll be gone forever.",
              {
                loader: true
              }
            )
            .then(dialog => {
              // setTimeout(() => {

              API.deleteRequestAttachment(data)
                .then(response => {
                  if (response.data.status) {
                    this.SuccessMessage = ', Attachment [ ' + this.getAttachmentNameBeforeDelete + ' ] ' +
                                          'has been removed from BL/Airway Bill/RCN  [ ' + this.getCustomerNameBeforeDelete + ' ]'
                    this.$store.dispatch('clearingrequests/customerNameBeforeDelete', '')
                    this.$store.dispatch('clearingrequests/attachmentNameBeforeDelete', '')
                    this.ErrorMessage = ''
                    this.SuccessAlert = true
                    this.ErrorAlert = false
                    this.loadRequestAttachment(myRequestId)

                    if (this.deciderReturn) {
                      this.editRequest()
                    }

                    window.scrollTo(10, 0)
                  } else {

                  }
                })
                .catch(e => {
                  this.ErrorMessage = ' Record not deleted, please contact system administrator'
                  this.SuccessMessage = ''
                  this.SuccessAlert = false
                  this.ErrorAlert = true
                })

              dialog.close()
              // }, 2500);
            })
            .catch(() => {
              this.myloader = false
              this.SuccessAlert = false
            })
        }
      }
    },

    deleteSingleRequest (data, name) {
      this.$store.dispatch('clearingrequests/nameBeforeDelete', name)

      this.$dialog
        .confirm(
          'If you delete Request [ ' +
                        name +
                        " ], it'll be gone forever.",
          {
            loader: true
          }
        )
        .then(dialog => {
          // setTimeout(() => {

          API.deleteARequest(data)
            .then(response => {
              if (response.data.status) {
                this.SuccessMessage = ', Application has been deleted' // [ " + this.getNameBeforeDelete + " ]
                this.$store.dispatch('clearingrequests/nameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false

                if (this.hasSearchedVariable) {
                  this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
                } else {
                  if (this.$route.params.requestId) {
                    this.ifThereIsParam = this.$route.params.requestId

                    this.loadASingleRequestFromNotification(this.$route.params.requestId)
                  } else {
                    this.loadPageableRequest(this.pageNo, this.pageSize)
                  }
                }

                window.scrollTo(10, 0)
              } else {

              }
            })
            .catch(e => {
              this.ErrorMessage = ' Record not deleted, please contact system administrator'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
            })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }

      this.displayFeedback = []
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },
    openActivitiesModal (requestDetails = null, actionButton) {
      this.ShowNonAndMonetaryListPlan = true
      this.requestName = requestDetails.requestName
      this.listBlNumber = requestDetails.blNumber
      this.listFileNo = requestDetails.fileNo
      this.status = requestDetails.status
      this.requestId = requestDetails.requestId
      this.companyId = requestDetails.companyId
      this.isAmended = requestDetails.isAmended
    },

    openModal (requestDetails = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.requestTypeId = requestDetails.requestTypeId
        this.requestName = requestDetails.blNumber
        this.responseRequestName = requestDetails.requestName
        this.description = requestDetails.description
        this.requestId = requestDetails.requestId
        this.status = requestDetails.status
        this.requestDate = requestDetails.requestDate
        this.rejectReason = requestDetails.rejectReason
        this.customerId = requestDetails.customerId
        this.loadRequestAttachment(requestDetails.requestId)
        this.editBlNumber = requestDetails.blNumber
        this.editFileNo = requestDetails.fileNo
        this.editRequestNumber = requestDetails.requestNumber
        this.editCustomerId = requestDetails.customerId
        this.approveDate = requestDetails.approveDate
        this.approvedBy = requestDetails.approvedBy
        this.regimeId = requestDetails.regime.regimeId
        this.isAmended = requestDetails.isAmended

        if (requestDetails.station === null) {
          this.stationId = 0
        } else {
          this.stationId = requestDetails.station.stationId
        }

        if (requestDetails.transportMethod === null) {
          this.transportMethodId = 0
        } else {
          this.transportMethodId = requestDetails.transportMethod.transportMethodId
        }

        this.EditModalDiv = true
        this.showConfirmModal = false
        this.showConfirmManyModal = false
        this.showModal = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.ShowConfirmModalDiv = false
        this.ShowConfirmManyModalDiv = false
        this.showRejectedMessage = false
        this.showFlag = false
        this.$store.dispatch('clearingrequests/requestid', requestDetails.requestId)
        this.$store.dispatch('clearingrequests/valuesBeforeEdit', [requestDetails.blNumber,
          requestDetails.description])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.showConfirmModal = false
        this.ShowConfirmModalDiv = false
        this.ShowConfirmManyModalDiv = false
        this.showConfirmManyModal = false
        this.showRejectedMessage = false
        this.showFlag = false
      } else if (actionButton === 'ShowFlagModal') {
        this.EditModalDiv = false
        this.showFlag = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.showModal = true
        this.showConfirmModal = false
        this.ShowConfirmModalDiv = false
        this.ShowConfirmManyModalDiv = false
        this.showConfirmManyModal = false
        this.showRejectedMessage = false

        this.requestId = requestDetails.requestId
        this.blNumber = requestDetails.blNumber

        if (requestDetails.requestId !== null) { this.fileNo = requestDetails.fileNo } else this.fileNo = 'NIL'

        this.getAllMessagesPerRequest(this.requestId)
      } else {
        this.loadRequestAttachmentSingle(requestDetails.requestId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
        this.showConfirmModal = false
        this.ShowConfirmModalDiv = false
        this.ShowConfirmManyModalDiv = false
        this.showConfirmManyModal = false
        this.showRejectedMessage = false
        this.showFlag = false
      }
    },

    openConfirmModal () {
      this.showConfirmModal = true
      this.ShowConfirmModalDiv = true

      if (this.documentTypeIdSingle !== '') {
        API.getIndividualAttachment(this.documentTypeIdSingle).then(
          response => {
            if (response.data.status) {
              this.getAttachmentTypeName = response.data.data.attachmentTypesName

              this.attachmentAlertMessage = 'You have selected attachment [ ' + this.getAttachmentTypeName + ' ] ' +
                              'but you have not uploaded any file, do you want to attach respective file?'
            } else {
              this.attachmentAlertMessage = "It's seems you are trying to attach document for unknown attachment " +
                              'type, please contact system administrator'
            }
          }
        ).catch(e => {

        })
      } else {
        this.attachmentAlertMessage = 'Do you have any other document(s) to attach?'
      }
    },

    openConfirmManyModal () {
      this.ShowConfirmManyModalDiv = true
      this.showConfirmManyModal = true
      let rightFlag = 0

      for (let c = 0; c < this.requestIdArray.length; c++) {
        if (typeof this.documentTypeId[this.requestIdArray[c].requestMyId] !== 'undefined') {
          if (this.documentTypeId[this.requestIdArray[c].requestMyId] !== []) {
            API.getIndividualAttachment(this.documentTypeId[this.requestIdArray[c].requestMyId]).then(
              response => {
                if (response.data.status) {
                  this.getAttachmentTypeName = response.data.data.attachmentTypesName

                  this.attachmentManyAlertMessage = 'You have selected attachment ' +
                                  '[ ' + this.getAttachmentTypeName + ' ] for BL/Airway Bill/RCN ' +
                                  '[ ' + this.requestBlArray[c] + ' ] but you have not uploaded any file, do you ' +
                                  'want to attach respective file?'
                } else {
                  this.attachmentManyAlertMessage = "It's seems you are trying to attach document for unknown " +
                                  'attachment type, please contact system administrator'
                }
              }
            ).catch(e => {

            })

            rightFlag++
          } else {

          }
        } else {

        }
      }

      if (rightFlag === 0) {
        this.attachmentManyAlertMessage = 'Do you have any other document(s) to attach?'
      }
    },

    closeBackModal () {
      this.ShowConfirmModalDiv = false
      this.showConfirmModal = false

      this.ShowConfirmManyModalDiv = false
      this.showConfirmManyModal = false

      this.documentTypeIdSingle = ''
      this.documentTypeId = []
      this.attachmentManyAlertMessage = ''
      this.attachmentAlertMessage = ''
      this.singleRequest = {}
      this.requestSeached = {}
    },

    closeAttachModal () {
      if (this.hasUserUploadedAnyThing) {
        this.openConfirmModal()
      } else {
        this.showModal = false
        this.EditModalDiv = false
        this.AddModalDiv = false

        this.documentTypeIdSingle = ''
        this.documentTypeId = []

        this.ifRequestSubmitted = false

        this.descriptionError = false
        this.requestNameError = false
        this.descriptionError = false
        this.showRejectedMessage = false
        this.ShowConfirmModalDiv = false
        this.showConfirmModal = false

        this.documentTypeIdError = false
        this.pdfFormatError = []

        this.request.requestName = ''
        this.request.description = ''
        this.request.requestTypeId = ''

        // after upload empty state
        this.$store.dispatch('clearingrequests/requestid', '')

        if (this.deciderReturn) {
          if (this.hasSearchedVariable) {
            this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
          } else {
            if (this.$route.params.requestId) {
              this.ifThereIsParam = this.$route.params.requestId

              this.loadASingleRequestFromNotification(this.$route.params.requestId)
            } else {
              this.loadPageableRequest(this.pageNo, this.pageSize)
            }
          }
        }
      }
    },

    closeAttachManyModal () {
      if (this.hasUserUploadedAnyThing) {
        this.openConfirmManyModal()
      } else {
        this.showModal = false
        this.EditModalDiv = false
        this.AddModalDiv = false

        this.documentTypeIdSingle = ''
        this.documentTypeId = []

        this.ifRequestSubmitted = false

        this.descriptionError = false
        this.requestNameError = false
        this.descriptionError = false
        this.showRejectedMessage = false
        this.ShowConfirmManyModalDiv = false
        this.showConfirmManyModal = false

        this.documentTypeIdError = false
        this.documentTypeIdErrorMany = []
        this.pdfFormatError = []

        this.request.requestName = ''
        this.request.description = ''
        this.request.requestTypeId = ''

        // after upload empty state
        this.$store.dispatch('clearingrequests/requestid', '')

        if (this.deciderReturn) {
          if (this.hasSearchedVariable) {
            this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
          } else {
            if (this.$route.params.requestId) {
              this.ifThereIsParam = this.$route.params.requestId

              this.loadASingleRequestFromNotification(this.$route.params.requestId)
            } else {
              this.loadPageableRequest(this.pageNo, this.pageSize)
            }
          }
        }
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.showFlag = false

      this.ShowNonAndMonetaryListPlan = false
      this.requestName = ''
      this.listBlNumber = ''
      this.listFileNo = ''
      this.status = ''
      this.requestId = ''
      this.companyId = ''
      this.isAmended = ''

      this.ifRequestSubmitted = false

      this.descriptionError = false
      this.requestNameError = false
      this.descriptionError = false
      this.showRejectedMessage = false
      this.ShowConfirmModalDiv = false
      this.showConfirmModal = false
      this.ShowConfirmManyModalDiv = false
      this.showConfirmManyModal = false

      this.documentTypeIdError = false
      this.documentTypeIdErrorMany = []
      this.pdfFormatError = []

      this.request.requestName = ''
      this.request.description = ''
      this.request.requestTypeId = ''
      this.request.requestUniqueIdentifier = ''

      this.documentTypeIdSingle = ''
      this.documentTypeId = []
      this.requestIdArray = []
      this.documentTypeIdSingleError = false
      this.attachmentManyAlertMessage = ''
      this.attachmentAlertMessage = ''
      this.singleRequest = {}
      this.requestSeached = {}

      this.SuccessMessage = ''
      this.ErrorMessage = ''
      this.SuccessAlert = false
      this.ErrorAlert = false

      this.myTittle = 'Shipping Document Number (BL/Airway Bill/RCN)'

      for (let ww = 0; ww < this.rows.length; ww++) {
        this.rows[ww].requestName = ''
        this.rows[ww].description = ''
      }

      this.rows = [
        { requestName: '', description: '' }
      ]

      this.requestUniqueIdentifierError = false
      this.requestUniqueIdentifierError = false

      // after upload empty state
      this.$store.dispatch('clearingrequests/requestid', '')

      if (this.deciderReturn) {
        if (this.hasSearchedVariable) {
          this.searchRequestByName(this.pageNo, this.pageSize, this.sortBy)
        } else {
          if (this.$route.params.requestId) {
            this.ifThereIsParam = this.$route.params.requestId

            this.loadASingleRequestFromNotification(this.$route.params.requestId)
          } else {
            this.loadPageableRequest(this.pageNo, this.pageSize)
          }
        }
      }
    }

  }

}
</script>

<style scoped>

  .my-swal {
    z-index: 5915 !important;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 23px;
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .label-upload {
    position: relative;
    top: 23px;
  }

  .fileUpload2 span {
    cursor: pointer;
  }

  .fileUpload2 {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    font-weight: normal;
  }

  .fileUpload2 input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  label2 {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .label-upload2 {
    position: relative;
    top: 23px;
  }

  .fileUpload2 span {
    cursor: pointer;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  .edit-tasac-messages:hover {
    cursor: pointer;
    color: blue;
  }

  .edit-tasac-messages {
    color: green;
  }

  .background-color-message-to-edit {
    background-color: #87cefa !important;
  }

</style>

<style>

  .refresh-my-icon:hover {
    cursor: pointer;
  }

  .attended {
    color:green;
  }

  .not-attended {
    color:red;
  }

  .red-color {
    color: red;
    margin-top: 13px;
  }

  .dynamic-bg {
    background-color: rgba(220, 220, 220, .2);
    border-radius: 3px;
  }

  .remove-bl .minus-bl {
    margin-top: 42px;
  }

  .remove-bl .minus-bl:hover, .add-bl .plus-bl {
    cursor: pointer;
  }

  .add-bl {
    text-align: right;
  }

  .search .form-group .input-group .input-group-addon {
    width: 40px;
    text-align: center;
    padding-top: 3.5px;
    background-color: rgba(220, 220, 220, .3);
  }

  .search .form-group .input-group #inputGroup {
    width: 250px;
    padding-top: 3.5px;
  }

  .search .form-group .input-group .input-group-addon:hover {
    cursor: pointer;
    background-color: rgba(220, 220, 220, .5);
  }

  .bg-grey {
    background-color: rgba(220, 220, 220, .5);
    padding: 10px 0 10px 0;
  }

  .refresh-all {
    margin-top: 6px;
    margin-right: 20px
  }

  .refresh-all:hover {
    cursor: pointer;
  }

  form.search input[type=text]:focus {
    border: 2px solid #DCDCDC;
  }

  .download-attachment:hover {
    cursor: pointer;
  }

</style>
