<template>
  <div>
    <card>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success mr-2 custom-btn"
            style="float:right;"
            @click.prevent="openModal('ADD')"
            v-if="finalUserPermissions.includes('PRINCIPAL_ADD_POST')"
          >
            <b>Add Principal</b>
          </button>
        </div>
      </div>
      <br>
      <data-table
        :table-headers="tableHeaders"
        :table-data="filteredRequests"
        :table-data-keys="tableDataKeys"
        :my-first-loader="myFirstLoader"
        :page-size-prop="pageSize"
        :page-no="pageNo"
        :total-elements="totalElements"
        :no-data-found="noDataFound"
        :is-first="isFirst"
        :is-last="isLast"
        :total-pages="totalPages"
        :page-number="pageNumber"
        :has-manage-button="true"
        :has-view-button="false"
        :has-edit-button="finalUserPermissions.includes('PRINCIPAL_UPDATE_PUT')"
        :loading-title="'Please wait while the system is loading Vessel Principals'"
        :permissions="[ {key: 'listPermission', value: 'PRINCIPAL_LIST_PAGEABLE_GET'} ]"
        :whichModuleAndType="whichModuleAndType"
        @resetParams="resetParams"
        @goToPage="goToPage"
        @filterWithMultipleParameters="searchGeneralChargeParams"
        @reloadByIcon="reloadRequests"
        @onChange="onChange"
        @goToEdit="editFormDetails"
        @goToApplication="goToApplication"
      >
      <div slot = "pagination">
        <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
        </paginate>
      </div>
      </data-table>
    </card>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            Loading, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            v-if="EditModalDiv"
            class="col-md-12"
          >
            <b> Edit Principal</b>
          </div>
          <div
            v-if="AddModalDiv"
            class="col-md-12"
          >
            <b> Register Principal</b>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--      ADD FORM      -->
          <form
            v-if="AddModalDiv"
            method="POST"
            @submit.prevent="saveFormDetails"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  v-model="principalDetails.name"
                  type="text"
                  label="Principals' Name"
                  placeholder="Principals' Name"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="principalDetailsNameError">
                  <div
                    v-if="!$v.principalDetails.name.required"
                    class="error"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  v-model="principalDetails.carrierCode"
                  type="text"
                  label="Principals' Carrier Code"
                  placeholder="Principals' Carrier Code"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="principalDetailsCarrierCodeError">
                  <div
                    v-if="!$v.principalDetails.carrierCode.required"
                    class="error"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  v-model="principalDetails.address"
                  type="textarea"
                  label="Principals' Address"
                  placeholder="Principals' Address"
                  class="special-bottom"
                  :is-text-area="true"
                />
                <div v-if="principalDetailsAddressError">
                  <div
                    v-if="!$v.principalDetails.address.required"
                    class="error"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  v-if="editMyLoader"
                  :loading="loading"
                  :color="color"
                  :size="size"
                />
              </div>
              <div
                v-if="AddModalDiv"
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right custom-btn-all"
                  @click.prevent="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space float-right custom-btn"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--     EDIT FORM       -->
          <form
            v-if="EditModalDiv"
            method="POST"
            @submit.prevent="editSaveFormDetails"
          >
            <div class="row">
              <div class="col-md-6">
                <base-input
                  v-model="name"
                  type="text"
                  label="Principals' Name"
                  placeholder="Principals' Name"
                  :value="name"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="principalDetailsNameError">
                  <div
                    v-if="!$v.name.required"
                    class="error"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <base-input
                  v-model="carrierCode"
                  type="text"
                  label="Principals' Carrier Code"
                  placeholder="Principals' Carrier Code"
                  :value="carrierCode"
                  class="special-bottom"
                  oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                />
                <div v-if="principalDetailsCarrierCodeError">
                  <div
                    v-if="!$v.carrierCode.required"
                    class="error"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <base-input
                  v-model="address"
                  type="textarea"
                  label="Principals' Address"
                  placeholder="Principals' Address"
                  :value="address"
                  :is-text-area="true"
                  class="special-bottom"
                />
                <div v-if="principalDetailsAddressError">
                  <div
                    v-if="!$v.address.required"
                    class="error"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  v-if="editMyLoader"
                  :loading="loading"
                  :color="color"
                  :size="size"
                />
              </div>
              <div
                v-if="EditModalDiv"
                class="col-md-3"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right custom-btn-all"
                  @click.prevent="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right custom-btn"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import GlobalModal from '../../../components/globalLoader'
import Modal from '../../../components/Inputs/Modal'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CrudNotification from '../../../components/DataTable/crudNotification'

export default {

  name: 'Principal',

  components: {

    CrudNotification,
    PulseLoader,
    GlobalModal,

    DataTable: () => import('../../../components/DataTable/dataTable'),
    Modal

  },

  created() {

    this.$store.dispatch('pagenames/currentPageName', 'Vessel Principals')
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageablePrincipalsOnCreated()

  },

  computed: {

    ...mapGetters('notifications', [

      'getNewAppPage'

    ]),

    ...mapGetters('principal', [

      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getReloadAllRequest',
      'getMyLoader',
      'getSearchParameter',
      'getHasSearchedVariable',
      'getPageNo',
      'getRequestId',
      'getTotalElement',
      'getTotalPage',
      'getPageNumber',
      'getIsFirst',
      'getIsLast',
      'getSearchParams',
      'getPageSize'

    ]),

    getIsPage: {

      get: function () {
          return this.isPage
      },
      set: function (newValue) {
          this.isPage = newValue;
      }

    }

  },

  validations: {

    carrierCode: { required },
    address: { required },
    name: { required },

    principalDetails: {
      carrierCode: { required },
      address: { required },
      name: { required }

    }

  },

  data() {

    return {

      principalDetails: {

        accountNo: '',
        address: '',
        carrierCode: '',
        name: ''

      },

      requests: [],
      filteredRequests: [],
      isPage: 0,
      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: 0,
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      whichModuleAndType: 'vesselPrincipal',
      accountNo: '',
      address: '',
      carrierCode: '',
      name: '',
      principalParticularId: '',

      principalDetailsNameError: false,
      principalDetailsCarrierCodeError: false,
      principalDetailsAddressError: false,

      AddModalDiv: false,
      EditModalDiv: false,
      SearchModalDiv: false,
      showModal: false,
      editMyLoader: false,
      nodataFound: false,
      myFirstLoader: false,
      totalElements: '',

      color: 'green',
      size: '12px',
      loading: true,

      editable: true,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,
      pdfFormatError: false,
      loadAllHeaderLoader: false,
      allAttachmentVerified: false,
      myLoader: false,
      isSearchable: false,
      requests: [],
      requestFlag: 'CONFIG',

      SuccessAlert: false,
      ErrorAlert: false,

      SuccessMessage: '',
      ErrorMessage: '',

      isHovered: [],
      saveDetails: {},
      ifThereIsParam: '',
      editDetails: false,
      finalUserPermissions: [],

      tableHeaders: ['Principal Name', 'Carrier Code', 'Account Number'],
      tableDataKeys: ['name', 'carrierCode', 'accountNo']

    }
  },
  methods: {

    ...mapActions('principal', [

      'loadPrincipalsParticularsPageableOnCreated',
      'loadPrincipalsParticularsPageableAfterCreated',
      'loadPrincipalsParticularsById',
      'loadPrincipalsParticularsByCarrierCode',
      'addPrincipalDetails',
      'updatePrincipalDetails',
      'searchPrincipalsParticulars'

    ]),

    resetParams () {

        this.loadPageablePrincipalsOnCreated()
        this.$store.dispatch("principal/hasSearchngParams", false)
        this.pageNoPart = 0
        this.isPage = this.pageNoPart + 1
        this.filteredRequests = []

      },

    loadSearchParameters(params, fromApplicationPageNo) {

      this.isPage = fromApplicationPageNo;
      this.loadedParams = params
      return new Promise((resolve) => {

        this.$store.dispatch("principal/pageSizing", this.pageSize);
        this.$store.dispatch("principal/pageNumbering", this.pageNo);
        this.$store.dispatch("principal/paramsSearching", this.loadedParams);

        resolve();

      })

    },

    searchGeneralChargeParams(params, fromApplicationPageNo) {

        this.filteredRequests = []

        this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

          this.searchPrincipalsParticulars({}).then(() => {

            this.setAllGetters('onCreated');

          }).catch(() => {});

          this.myFirstLoader = this.getMyFirstLoader;

        }).catch(() => {


        });

      },

    sortingData(data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {

          ...data[x],
          address: data[x].address.length > 100 ? data[x].address.substring(0, 100) + '...' : data[x].address

          }

          this.filteredRequests.push(requestObject)

      }

    },

    setAllGetters(flag) {

      this.requests = this.getRequestList;
      flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
      flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
      this.noDataFound = this.getNoDataFound;
      this.SuccessMessage = this.getSuccessMessage;
      this.ErrorMessage = this.getErrorMessage;
      this.ErrorAlert = this.getErrorAlert;
      this.SuccessAlert = this.getSuccessAlert;
      this.pageNo = this.getPageNo;
      this.pageSize = this.getPageSize;

      this.totalElements = this.getTotalElement;
      this.totalPages = this.getTotalPage;
      this.pageNumber = this.getPageNumber;
      this.isFirst = this.getIsFirst;
      this.isLast = this.getIsLast;

      this.sortingData(this.requests)

    },

    loadParameters() {

      return new Promise((resolve) => {

        this.$store.dispatch('principal/pageSizing', this.pageSize)
        this.$store.dispatch('principal/pageNumbering', this.pageNo)

        resolve()

      })

    },

    loadSaveDetailsParameters() {

      return new Promise((resolve) => {

        this.$store.dispatch('principal/savingDetails', this.saveDetails)
        resolve()

      })

    },

    loadPageablePrincipalsOnCreated() {

      this.filteredRequests = [];

      this.loadParameters().then(() => {

        this.loadPrincipalsParticularsPageableOnCreated({}).then(() => {

          this.setAllGetters('onCreated')

        }).catch(() => {})

        this.myFirstLoader = this.getMyFirstLoader
      })

    },

    loadPageableRequestsAfterCreated(pageNo , pageSize) {

        this.loadParameters(pageNo , pageSize).then(() => {
          this.loadPrincipalsParticularsPageableAfterCreated({}).then(() => {

            this.setAllGetters('afterCreated');

          });

          this.myLoader = this.getMyLoader;

        }).catch(() => {


        });

      },

    onChange(event) {

      this.$store.dispatch("principal/pageSizing", event);
      this.$store.dispatch("principal/pageNumbering", 0);
      this.isPage = 0

      this.pageSize = this.getPageSize;
      this.pageNo = this.getPageNo;

      this.loadPageableRequestOnCreated(this.pageNo , this.pageSize);
      this.filteredRequests = []

    },

    goToPage(page) {

      if(page > 0) {page = page - 1;}
      this.isPage = page + 1

      this.pageNo = page;

      this.loadPageableRequestsAfterCreated(this.pageNo , this.pageSize);
      this.filteredRequests = []

    },

    searchPrincipals(searchParam) {

      this.reloadAllRequest = true
      this.$store.dispatch('principal/searchingParameter', searchParam)
      this.loadPrincipalsParticularsByCarrierCode({}).then(() => {

        this.myFirstLoader = this.getMyFirstLoader
        this.requests = [this.getRequestList]
        // this.requests = this.requests.push(this.getRequestList);
        this.noDataFound = this.getNoDataFound

      }).catch(() => {

        this.myFirstLoader = false
        this.nodataFound = true

      })

    },

    savePrincipal() {

      this.loadSaveDetailsParameters().then(() => {

        this.addPrincipalDetails({}).then(() => {

          // this.requests = this.getRequestList;
          // this.pageCount = this.getPageCount;
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessAlert
          if (this.SuccessAlert) {

            this.notifyUser('success', 'Principal added successfully!', 'The Principal has been added successfully')
            this.SuccessAlert = false

          }
          if (this.ErrorAlert) {

            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false

          }
          // this.pageNo = this.getPageNo;
          // this.pageSize = this.getPageSize;
          this.loadPrincipalsParticularsPageableOnCreated({}).then(() => {
            this.myLoader = this.getMyFirstLoader
            this.requests = this.getRequestList
            // this.pageCount = this.getPageCount;//
            this.myFirstLoader = this.getMyFirstLoader
            this.isLast = this.getRequestList.last
            this.totalElements = this.getRequestList.totalElements
            this.totalPages = this.getRequestList.totalPages
            this.pageNumber = this.getRequestList.number
            this.isFirst = this.getRequestList.first
            this.isLast = this.getRequestList.last
            this.noDataFound = this.getNoDataFound
            this.pageNo = this.getPageNo
          }).catch(() => {
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert) {
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false
            }
          })
        }).catch(() => {
          this.ErrorAlert = this.getErrorAlert
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false
          }
        })
        this.myFirstLoader = this.getMyFirstLoader
      })
      this.saveDetails = {}
    },

    editPrincipal() {
      this.loadSaveDetailsParameters().then(() => {
        this.updatePrincipalDetails({}).then(() => {
          // this.requests = this.getRequestList;
          // this.pageCount = this.getPageCount;
          this.myFirstLoader = this.getMyFirstLoader
          this.noDataFound = this.getNoDataFound
          this.ErrorAlert = this.getErrorAlert
          this.SuccessAlert = this.getSuccessAlert
          if (this.SuccessAlert) {
            this.notifyUser('success', 'Principal edited successfully!', 'The Principal has been edited successfully')
            this.SuccessAlert = false
          }
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false
          }
          // this.pageNo = this.getPageNo;
          // this.pageSize = this.getPageSize;
          this.loadPrincipalsParticularsPageableOnCreated({}).then(() => {
            this.myLoader = this.getMyFirstLoader
            this.requests = this.getRequestList
            // this.pageCount = this.getPageCount;//
            this.myFirstLoader = this.getMyFirstLoader
            this.isLast = this.getRequestList.last
            this.totalElements = this.getRequestList.totalElements
            this.totalPages = this.getRequestList.totalPages
            this.pageNumber = this.getRequestList.number
            this.isFirst = this.getRequestList.first
            this.isLast = this.getRequestList.last
            this.noDataFound = this.getNoDataFound
            this.pageNo = this.getPageNo
          }).catch(() => {
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert) {
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false
            }
          })
        }).catch(() => {
          this.ErrorAlert = this.getErrorAlert
          if (this.ErrorAlert) {
            this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
            this.ErrorAlert = false
          }
        })
        this.myFirstLoader = this.getMyFirstLoader
      })
      this.saveDetails = {}
    },

    openModal(modalType) {

      if (modalType === 'ADD') {

        this.EditModalDiv = false
        this.AddModalDiv = true
        this.showModal = true

      } else {

        this.EditModalDiv = true
        this.AddModalDiv = false
        this.showModal = true

      }

    },

    closeModal() {

      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.principalDetailsAddressError = false
      this.principalDetailsCarrierCodeError = false
      this.principalDetailsNameError = false
    },

    saveFormDetails() {
      if (this.$v.name.$invalid || this.$v.carrierCode.$invalid || this.$v.address.$invalid) {
        if (this.$v.principalDetails.name.$invalid) {
          this.principalDetailsNameError = true
        }
        if (this.$v.principalDetails.carrierCode.$invalid) {
          this.principalDetailsCarrierCodeError = true
        }
        if (this.$v.principalDetails.address.$invalid) {
          this.principalDetailsAddressError = true
        } else {
          // this.saveDetails = this.principalDetails;
          this.saveDetails = {
            carrierCode: this.principalDetails.carrierCode,
            address: this.principalDetails.address,
            name: this.principalDetails.name

          }
          this.principalDetails = {}
          this.closeModal()
          this.savePrincipal()
        }
      }
    },

    editSaveFormDetails(details) {

      if (this.principalDetails.name.$invalid || this.principalDetails.carrierCode.$invalid || this.principalDetails.address.$invalid) {
        if (this.$v.name.$invalid) {
          this.principalDetailsNameError = true
        }
        if (this.$v.code.$invalid) {
          this.principalDetailsCodeError = true
        }
        if (this.$v.address.$invalid) {
          this.principalDetailsDescriptionError = true
        }
      } else {
        this.saveDetails = {
          carrierCode: this.carrierCode,
          address: this.address,
          name: this.name,
          accountNo: this.accountNo,
          principalParticularId: this.principalParticularId
        }
        this.editPrincipal()

        this.code = ''
        this.name = ''
        this.description = ''
        this.closeModal()
      }
    },

    editFormDetails(details) {
      this.accountNo = details.accountNo
      this.principalParticularId = details.principalParticularId
      this.carrierCode = details.carrierCode
      this.address = details.address
      this.name = details.name
      this.openModal('EDIT')
    },

    reloadRequests() {
      this.noDataFound = false
      this.loadPageablePrincipalsOnCreated()
      this.reloadAllRequest = false
    },

    goToApplication(principalDetails) {

      document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
      this.$router.push({name: 'PrincipalItems', params: { principalId : principalDetails.principalParticularId }})

    },

    fadeMe: function() {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function(el, done) {
      var that = this

      setTimeout(function() {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    }
  }
}
</script>

<style scoped>
.btn-space {
  margin-right: 10px !important;
}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

.special-limit {
  max-width: 100px !important;
  min-width: 90px !important;
}

.loader-div {
  line-height: 40px;
}

.loader-div-gif {
  line-height: 50px;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.fileUpload span {
  cursor: pointer;
}

label {
  position: relative;
  top: -7px;
  right: 0;
  margin: 0;
  padding: 0;
}

.special-bottom {
  margin-bottom: -1px;
}
</style>
