var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('div',{staticClass:"menu-title row",attrs:{"title":"System Configurations"},on:{"click":_vm.toggleMenu}},[_c('div',{staticClass:"col-md-10"},[_c('svg-icon',{staticClass:"nc-icon",attrs:{"icon":"settings","has-fill":true}}),_c('span',{staticClass:"menu-title-name"},[_vm._v("CONFIGURATIONS")])],1),_c('div',{staticClass:"col-md-2"},[_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.openMenu),expression:"openMenu"}],staticClass:"nc-icon-page",attrs:{"icon":"down_arrow","has-fill":true}}),_c('svg-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.openMenu),expression:"!openMenu"}],staticClass:"nc-icon-page",attrs:{"icon":"right_arrow","has-fill":true}})],1)]):_vm._e(),_c('transition',{attrs:{"name":"list"}},[(_vm.openMenu)?_c('div',{staticClass:"menu-value"},[(_vm.finalUserPermissions.includes('VESSEL_ATTACHMENTS_ADD_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'saAttachment' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("SA Attachment Type")])],1):_vm._e()],1):_vm._e()]),_c('transition',{attrs:{"name":"list"}},[(_vm.openMenu)?_c('div',{staticClass:"menu-value"},[(_vm.finalUserPermissions.includes('DELIVERY-ORDER-ATTACHMENT-TYPE_ADD_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'doAttachment' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("DO Attachment type")])],1):_vm._e()],1):_vm._e()]),_c('transition',{attrs:{"name":"list"}},[(_vm.openMenu)?_c('div',{staticClass:"menu-value"},[(_vm.finalUserPermissions.includes('ACTIVITY_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'ActivityList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Activity")])],1):_vm._e(),(_vm.finalUserPermissions.includes('SYSTEM_CONFIG') &&
        _vm.finalUserPermissions.includes('AGENCY-CHARGE_ADD_POST'))?_c('sidebar-link',{attrs:{"to":{ name: 'agencyCharges' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Delivery Order Charges")])],1):_vm._e(),(_vm.finalUserPermissions.includes('ATTACHMENTS_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'AttachmentType' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Attachment Type")])],1):_vm._e(),(_vm.finalUserPermissions.includes('REQUEST_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'RequestTypeList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Application Type")])],1):_vm._e(),(_vm.finalUserPermissions.includes('COMMON-CODE_PULL_POST'))?_c('sidebar-link',{attrs:{"to":{ name: 'CommonCode' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("TeWS Common Code")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CF-AGENT_ALL_GET'))?_c('sidebar-link',{attrs:{"to":{ name: 'CFAgent' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("CF Agents")])],1):_vm._e(),(_vm.finalUserPermissions.includes('COMPANY_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'CompanyList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Company")])],1):_vm._e(),(_vm.finalUserPermissions.includes('COMPANYTYPE_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'CompanyTypeList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Company Type")])],1):_vm._e(),(_vm.finalUserPermissions.includes('COUNTRY_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'CountryList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Country")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CITY_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'CityList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("City")])],1):_vm._e(),(_vm.finalUserPermissions.includes('DEPARTMENT_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'DepartmentList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Department")])],1):_vm._e(),(_vm.finalUserPermissions.includes('DEPARTMENT_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'ShippingAgencyServiceProvider' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("SA Service Provider")])],1):_vm._e(),_c('sidebar-link',{attrs:{"to":{ name: 'Disbursement' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Disbursement Services")])],1),(_vm.finalUserPermissions.includes('DESIGNATION_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'DesignationList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Designation")])],1):_vm._e(),(_vm.finalUserPermissions.includes('DOCTYPE_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'DocumentTypeList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Document Type")])],1):_vm._e(),(_vm.finalUserPermissions.includes('EXCHANGE_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'ExchangeRateList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Exchange Rate")])],1):_vm._e(),(_vm.finalUserPermissions.includes('HSCODE_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'HsCodeList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("HS-code")])],1):_vm._e(),(_vm.finalUserPermissions.includes('PACKAGE-TYPE_ADD_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'PackageType' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Package Type")])],1):_vm._e(),(_vm.finalUserPermissions.includes('PORT_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'PortList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Port")])],1):_vm._e(),(_vm.finalUserPermissions.includes('REGIMES_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'RegimeList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Regime")])],1):_vm._e(),(_vm.finalUserPermissions.includes('STATIONS_ADD_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'StationList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Station")])],1):_vm._e(),(_vm.finalUserPermissions.includes('STATUS_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'StatusList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Status")])],1):_vm._e(),(_vm.finalUserPermissions.includes('SECTOR_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'SectorList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Sector")])],1):_vm._e(),(_vm.finalUserPermissions.includes('CLEARINGFORWARDING_SPECIFIEDGOODS_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'SpecifiedGoodList' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Specified Goods")])],1):_vm._e(),(_vm.finalUserPermissions.includes('TARIFFS_CREATE_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'Tariffs' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Tariffs")])],1):_vm._e(),(_vm.finalUserPermissions.includes('TARIFF-ITEMS_CREATE_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'tariffItem' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Tariff Items")])],1):_vm._e(),(_vm.finalUserPermissions.includes('TRANSPORT-METHOD_ADD_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'TransportMethod' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Transport Method")])],1):_vm._e(),(_vm.finalUserPermissions.includes('VESSELSCHEDULE_CONFIG_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'VesselSchedule' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Vessel Schedule")])],1):_vm._e(),(_vm.finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_POST')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'vehicleProperties' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Vehicle Properties")])],1):_vm._e(),(_vm.finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_GET')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'vehiclePropertiesView' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("View Vehicle Properties")])],1):_vm._e(),(_vm.finalUserPermissions.includes('TALLY-EVENT-CODES_ADD_POST'))?_c('sidebar-link',{attrs:{"to":{ name: 'TallyEvents' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Tally Event Codes")])],1):_vm._e(),(_vm.finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_GET')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'tallyActivity' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Tally Activities")])],1):_vm._e(),(_vm.finalUserPermissions.includes('API_TALLY-VEHICLE-PROPERTIES_GET')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'tallyConfTariff' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Tally Tariff")])],1):_vm._e(),(_vm.finalUserPermissions.includes('SHIP-FEE_PRODUCT_LIST_GET')
          && _vm.finalUserPermissions.includes('SYSTEM_CONFIG'))?_c('sidebar-link',{attrs:{"to":{ name: 'ShippingFee' }}},[_c('svg-icon',{staticClass:"nc-icon-inner",attrs:{"icon":"circle","has-fill":true}}),_c('p',[_vm._v("Shipping Fees")])],1):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }