<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <div
        id="burger"
        :class="{ 'active' : isOpen }">
        <slot>
          <button
            type="button"
            class="burger-button"
            title="Menu"
            @click.prevent="toggle"
          >
            <span class="hidden">Toggle menu</span>
            <span class="burger-bar burger-bar--1" />
            <span class="burger-bar burger-bar--2" />
            <span class="burger-bar burger-bar--3" />
          </button>
        </slot>
      </div>
      
      <font-awesome-icon icon="compress-arrows-alt" v-if="isFullscreen" @click="toggleFullScreen" style="margin-right:20px; color:green;cursor:pointer;" title="exit fullscreen"/>
      <font-awesome-icon icon="expand-arrows-alt" v-if="!isFullscreen" @click="toggleFullScreen" style="margin-right:20px; color:green;cursor:pointer;" title="go fullscreen"/>

      <modal v-if="getError403" style="z-index: 9999;">
        <div class="modal-title col-md-12" slot="header"></div>
        <div class="text-center" slot="body">
          <div class="row">
            <div class="col-md-12 text-danger">
              You are not authorized to access this content, please contact system administrator
            </div>
            <div class="col-md-12">
              <hr />
              <button class="btn btn-fill btn-outline-info btn-space float-right custom-btn-all" @click.prevent="hideError403">
                  Close
              </button>
            </div>
          </div>
        </div>
      </modal>

      <span class="navbar-brand"><i>{{ isIdle || getAutoRefreshNotifications ? 'Inactive' : getCurrentPageName }}</i></span>

      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav ml-auto">
          <!-- <div class="tasac-tariff" @click.prevent="showTasacTariff"><b>View TASAC Tariff</b></div> -->
          <base-dropdown tag="li" v-if="finalUserPermissions.includes('CLEARINGFORWARDING_NEW_REJECTED-REQUESTS_GET') || finalUserPermissions.includes('CLEARINGFORWARDING_NEW_REQUEST-CREATED_GET')">
            <template slot="title">
                <font-awesome-icon icon="bell" :class="[ totalNotification > 0 ? 'animated' : '' ]" 
                class="faa-ring fa-2x" style="color:green;cursor:pointer;font-size:25px !important;" @click="isShowNotification ? isShowNotification = false : ''"/>
                <span
                class="notification"
                id="total-notification"
                style="background-color:#d59a18; color: #000000;"
              >{{ totalNotification }}</span>
            </template>
            <span id="notification-data-export" ref="closeNotification">
              <a v-for="(notificationType , noti_index) in notificationTypes" class="dropdown-item" :key="noti_index" @click.prevent="showNotification(notificationType.id)"
              :class="[ notificationType.id === 'CFA_APPLICATION_RETURNED_TO_CUSTOMER' ? 'text-danger' : '' ]">
                {{ notificationType.name }} ( {{ notificationType.quantity }} )
              </a>
            </span>
          </base-dropdown>

          <div class="notification-list" v-if="isShowNotification" v-click-outside-exclude="{ exclude: ['closeNotification'], handler: 'hideNotification' }">
            <ul class="mb-3">
              <li>
                  <div class="float-left" style="font-weight:bold;">{{ notificationTitle }}</div>
              </li>
            </ul>
            <hr class="charge-custom-hr"/>
            <div style="overflow-y: scroll;max-height: 450px;padding-top:20px;">
              <ul v-if="whichNotificationType === 'CFA_NEW_EXPORT_APPLICATION'">
                <div  v-if="newExport.length > 0">
                  <li v-for="(notificationNew , imp_index) in newExport" :key="imp_index" style="margin-bottom:28px;">
                    <a @click.prevent="toSpecificRequest(notificationNew, 'request-submitted')">
                      <span>
                        {{ imp_index + 1 }}) [ <b>B/L : </b>{{ notificationNew.blNumber }} ] - [ <b>Created By : </b>{{ notificationNew.createdBy }} ]
                      </span>
                    </a>
                  </li>
                </div>
                <div v-else style="padding-bottom:15px;">No notification found</div>
              </ul>
              <ul v-if="whichNotificationType === 'CFA_NEW_IMPORT_APPLICATION'">
                <div  v-if="newImport.length > 0">
                  <li v-for="(notificationNew , exp_index) in newImport" :key="exp_index" style="margin-bottom:28px;">
                    <a @click.prevent="toSpecificRequest(notificationNew, 'request-submitted')">
                      <span>
                        {{ exp_index + 1 }}) [ <b>B/L : </b>{{ notificationNew.blNumber }} ] - [ <b>Created By : </b>{{ notificationNew.createdBy }} ]
                      </span>
                    </a>
                  </li>
                </div>
                <div v-else style="padding-bottom:15px;">No notification found</div>
              </ul>
              <ul v-if="whichNotificationType === 'CFA_APPLICATION_RETURNED_FROM_CUSTOMER'">
                <div  v-if="AmendedRequests.length > 0">
                  <li v-for="(notificationNew , ame_index) in AmendedRequests" :key="ame_index" style="margin-bottom:28px;">
                    <a @click.prevent="toSpecificRequest(notificationNew, 'request-resubmitted-after-changes')">
                      <span>
                        {{ ame_index + 1 }}) [ <b>B/L : </b>{{ notificationNew.blNumber }} ] - [ {{ notificationNew.requestTypeFlag === 'IMP' ? 'IMPORT' : 'EXPORT' }} ]
                      </span>
                    </a>
                  </li>
                </div>
                <div v-else style="padding-bottom:15px;">No notification found</div>
              </ul>
              <ul v-if="whichNotificationType === 'CFA_APPLICATION_RETURNED_TO_CUSTOMER'">
                <div  v-if="requestsCustomer.length > 0">
                  <li v-for="(notificationNew , came_index) in requestsCustomer" :key="came_index" style="margin-bottom:28px;">
                    <a @click.prevent="toSpecificRequest(notificationNew, 'minor-or-major')">
                      <span>
                        {{ came_index + 1 }}) [ <b>B/L : </b>{{ notificationNew.blNumber }} ] - [ {{ notificationNew.requestTypeFlag === 'IMP' ? 'IMPORT' : 'EXPORT' }} ]
                        - [ <span :class="[notificationNew.status === 'Request Returned For Major Changes' ? 'text-danger' : 'text-warning']">
                          {{ notificationNew.status === 'Request Returned For Major Changes' ? 'Major Issue' : 'Minor Issue' }}
                          </span> ]
                      </span>
                    </a>
                  </li>
                </div>
                <div v-else style="padding-bottom:15px;">No notificatio found</div>
              </ul>
            </div>
          </div>

          <!--  -->

          <base-dropdown tag="li" v-if="finalUserPermissions.includes('CLEARINGFORWARDING_NEW_REJECTED-REQUESTS_GET') || finalUserPermissions.includes('CLEARINGFORWARDING_NEW_REQUEST-CREATED_GET')">
            <template slot="title">
                <font-awesome-icon icon="comments" :class="[ totalMessages > 0 ? 'animated' : '' ]" 
                class="faa-comments fa-2x" style="color:green;cursor:pointer;font-size:25px !important;" @click="isShowMessage ? isShowMessage = false : ''"/>
                <span
                class="notification"
                id="total-notification"
                style="background-color:#d59a18; color: #000000;"
              >{{ totalMessages }}</span>
            </template>
            <span id="notification-data-export" ref="closeMessage">
              <a v-for="(notificationType , noti_index) in messageTypes" class="dropdown-item" :key="noti_index" @click.prevent="showMessage(notificationType.id)"
              :class="[ notificationType.id === 'CFA_APPLICATION_RETURNED_TO_CUSTOMER' ? 'text-danger' : '' ]">
                {{ notificationType.name }} ( {{ notificationType.quantity }} )
              </a>
            </span>
          </base-dropdown>

          <div class="notification-list" v-if="isShowMessage" v-click-outside-exclude="{ exclude: ['closeMessage'], handler: 'hideMessage' }">
            <ul class="mb-3">
              <li>
                  <div class="float-left" style="font-weight:bold;">{{ messageTitle }}</div>
              </li>
            </ul>
            <hr class="charge-custom-hr"/>
            <div style="overflow-y: scroll;max-height: 450px;padding-top:20px;">
              <ul v-if="whichNotificationType === 'CFA_INCOMING_MESSAGE'">
                <div  v-if="requestMessagesStaff.length > 0">
                  <li v-for="(notificationNew , sms_index) in requestMessagesStaff" :key="sms_index" style="margin-bottom:28px;">
                    <a @click.prevent="goToChatRoom(notificationNew.requestId, 'ST')">
                      <span>
                        {{ sms_index + 1 }}) [ <b>B/L:</b>{{ notificationNew.blNumber }} ] - [ <b>Sent By:</b>{{ notificationNew.createdBy }} ] - [ {{ notificationNew.requestTypeFlag === 'IMP' ? 'IMPORT' : 'EXPORT' }} ]
                      </span>
                    </a>
                  </li>
                </div>
                <div v-else style="padding-bottom:15px;">No message notification found</div>
              </ul>
              <ul v-if="whichNotificationType === 'CFA_CUSTOMER_INCOMING_MESSAGE'">
                <div  v-if="requestMessagesCustomer.length > 0">
                  <li v-for="(notificationNew , csms_index) in requestMessagesCustomer" :key="csms_index" style="margin-bottom:28px;">
                    <a @click.prevent="goToChatRoom(notificationNew.requestId, 'CU')" v-if="requestMessagesCustomer.length > 0">
                      <span>
                        {{ csms_index + 1 }}) [ <b>B/L:</b>{{ notificationNew.blNumber }} ] - [ {{ notificationNew.requestTypeFlag === 'IMP' ? 'IMPORT' : 'EXPORT' }} ]
                      </span>
                    </a>
                  </li>
                </div>
                <div v-else style="padding-bottom:15px;">No message notification found</div>
              </ul>
            </div>
          </div>

          <li class="nav-item">
            <img
              class="avatar border-gray"
              src="/img/faces/face-0.jpg"
              alt="..."
              style="width:40px;"
            >
          </li>
          <base-dropdown :title="loggedInUserFullName">
            <a
              class="dropdown-item"
              @click.prevent="openCloseModal"
            >
              <font-awesome-icon
                icon="key"
                class="text-muted user-logs"
              />
              Change Password
            </a>
            <div class="divider" />
            <a
              class="dropdown-item"
              href="#"
            >
              <font-awesome-icon
                icon="book"
                class="text-muted user-logs"
              />
              System User Manual
            </a>
            <div class="divider" />
            <a
              class="dropdown-item"
              @click="showTasacTariff"
            >
              <font-awesome-icon
                icon="book"
                class="text-muted user-logs"
              />
              TASAC Tariff
            </a>
            <hr class="divider" style="margin-left:10px;"/>
            <a
              class="dropdown-item" style="margin-top:20px;"
              @click="logout"
            >
              <font-awesome-icon
                icon="sign-out-alt"
                class="text-muted user-logs"
              />
              Log out
            </a>
          </base-dropdown>
        </ul>
      </div>
    </div>

    <modal v-if="changePassword">
      <div
        class="modal-title col-md-12"
        slot="header"
      >
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-10" />
              <div class="col-md-2">
                <pulse-loader
                  :color="color"
                  :loading="loading"
                  :size="size"
                  v-if="loadingShowChangePassword"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                Your new password
                <i>
                  <b>must</b>
                </i>
                have the following rules
                <br>
                <br>
                <ul>
                  <li>
                    Eight (8) or more characters
                  </li>
                  <li>
                    Atleast a capital letter
                  </li>
                  <li>
                    Atleast a small letter
                  </li>
                  <li>
                    Atleast a special character
                  </li>
                  <li>
                    Contains no space
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <form @submit.prevent="changeCurrentUserPassword">
            <div class="row text-center">
              <div
                class="col-md-12 text-danger"
                v-if="passMatch"
              >
                Password did not match
              </div>
              <div
                class="col-md-12 text-danger"
                v-if="passMatchCurrent"
              >
                New password cannot be as the same as current one
              </div>
              <div
                class="col-md-12 text-danger"
                v-if="allCharacters"
              >
                Make sure your password adheres to above rules
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  class="special-bottom"
                  label="current password"
                  placeholder="current password"
                  type="password"
                  v-model="currentPass"
                  autocomplete="new-password"
                />
                <div v-if="currentPassError">
                  <div
                    class="error"
                    v-if="!$v.currentPass.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  class="special-bottom"
                  label="new password"
                  placeholder="new password"
                  type="password"
                  v-model="newPass"
                  autocomplete="new-password"
                />
                <div v-if="newPassError">
                  <div
                    class="error"
                    v-if="!$v.newPass.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  class="special-bottom"
                  label="confirm new password"
                  placeholder="confirm new password"
                  type="password"
                  v-model="confNewPass"
                  autocomplete="new-password"
                />
                <div v-if="confNewPassError">
                  <div
                    class="error"
                    v-if="!$v.confNewPass.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="sendRequestLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :color="color"
                  :loading="loading"
                  :size="size"
                  v-if="sendRequestLoader"
                />
              </div>
              <div class="col-md-3">
                <button
                  @click.prevent="openCloseModal"
                  class="btn btn-fill btn-outline-info float-right"
                  type="button"
                >
                  Close
                </button>
                <button
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                  type="submit"
                >
                  Change
                </button>
              </div>
            </div>
          </form>
        </card>
      </div>
    </modal>
    <div style="position: absolute;
                left: 0px;
                top: 0px;
                z-index: -1;"
    >
      {{ isConnectionLost }}
    </div>
    <!--    <div-->
    <!--      v-if="getNoInternetConnection"-->
    <!--      style="-->
    <!--    position: fixed;-->
    <!--    top:5px;-->
    <!--    left:45%;-->
    <!--    width: 300px;-->
    <!--    padding: 10px;-->
    <!--    background-color: #ff9800;-->
    <!--    color: white;-->
    <!--    text-align: center;-->
    <!--    opacity: .4;-->
    <!--    transition: opacity 0.6s;-->
    <!--    margin-bottom: 15px;-->
    <!--    max-height: 48px !important;-->
    <!--    z-index: 200;-->
    <!--    border-radius: 5px"-->
    <!--    >-->
    <!--      <p>-->
    <!--        <b>connection to the server failed</b>-->
    <!--      </p>-->
    <!--    </div>-->
    <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer" :download="false"></pdf-viewer>
  </nav>
</template>
<script>

import API from '../api/index';
import Modal from '../components/Inputs/Modal';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import PdfViewer from '../components/pdfViewer.vue'

export default {

  components: {

    Modal,
    PulseLoader,
    PdfViewer

  },

  computed: {

    isIdle () {
      let myIdle = '';

      if (this.$store.state.idleVue.isIdle) {
        document.cookie = 'browser-local-cashed-url=' + this.$route.fullPath + ';Path = /;SameSite = Strict';

        document.cookie = 'browser-local-cashed-url-agent=' +
                  window.$cookies.get('browser-local-permits-agent') + ';Path = /;SameSite = Strict';

        this.$router.push('/logout-on-session-expire').catch(() => {});

        myIdle = this.$store.state.idleVue.isIdle;

        if (document.getElementById('session-expiry')) { document.getElementById('session-expiry').style.display = 'block'; }

        this.loadSessionExpiryNotification('warn', -1, 'Session Message', 'Session has expired, please login to continue', 'session', 1000);

      } else {
        myIdle = this.$store.state.idleVue.isIdle;

        if (document.getElementById('session-expiry')) { document.getElementById('session-expiry').style.display = 'none'; }
      }

      return myIdle;
    },

    isConnectionLost () {
      let myIdle = '';

      if (this.getNoInternetConnection) {
        myIdle = this.getNoInternetConnection;

        if (document.getElementById('connection-lost')) { document.getElementById('connection-lost').style.display = 'block'; }

        this.loadConnectionLostNotification('warn', -1, 'Connection Message', 'Ooooops! connection to the server has failed', 'connection', 1000);

      } else {
        myIdle = this.getNoInternetConnection;

        if (document.getElementById('connection-lost')) { document.getElementById('connection-lost').style.display = 'none'; }
      }

      return myIdle;
    },

    ...mapGetters('pagenames', [

      'getCurrentPageName'

    ]),

    ...mapGetters('global', [

      'getNoInternetConnection',
      'getToggleSideBar',
      'getError403',
      'getAutoRefreshNotifications'

    ]),

    ...mapGetters('notifications', [

      'getExportPage',
      'getImportPage',
      'getCustomerPage'

    ])

  },

  data () {
    return {

      isOpen: false,

      rules: [
        { regex: /[a-z]+/ },
        { regex: /[A-Z]+/ },
        { regex: /.{8,}/ },
        { regex: /[0-9]+/ },
        { regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/ }
      ],

      color: 'green',
      size: '15px',
      loading: true,

      currentPass: '',
      newPass: '',
      confNewPass: '',
      timer: '',

      alertVisible: '',

      AmendedRequests: [],
      finalUserPermissions: [],
      requests: [],
      requestsCustomer: [],
      requestMessagesCustomer: [],
      requestMessagesStaff: [],

      currentPassError: false,
      newPassError: false,
      confNewPassError: false,
      passMatch: false,
      passMatchCurrent: false,
      allCharacters: false,

      activeNotifications: false,
      sendRequestLoader: false,
      changePassword: false,
      loadingShowChangePassword: false,
      userDetails: {},
      permissionGroups: [],
      loggedInUserFullName: '',
      isFullscreen: false,
      notificationTypes: [],
      messageTypes: [],
      isShowNotification: false,
      isShowMessage: false,
      whichNotificationType: '',
      newImport: [],
      newExport: [],
      notificationTitle: '',
      messageTitle: '',
      totalNotification: 0,
      totalMessages: 0,
      scr: '',
      showPdfViewer: false,
      message: 'My Old Message'

    };
  },



  created () {

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

    this.CheckToggleOnCreate();
    this.closeNotificationOnClickOutside()

    if (window.$cookies.isKey('browser-cache-agent-custom')) {
      this.loggedInUserFullName = window.$cookies.get('browser-cache-agent-custom');
    }

  },

  updated () {

    if(this.getAutoRefreshNotifications) {

      this.autoLoadNotification()
      this.$store.dispatch('global/autoRefreshingNotifications', false);

    }

  },

  mounted () {

    this.autoLoadNotification();
  
  },

  beforeDestroy () {

    clearInterval(this.timer);

  },

  validations: {

    currentPass: { required },
    newPass: { required },
    confNewPass: { required }

  },

  methods: {

    showTasacTariff () {

      this.showPdfViewer = true
      this.scr = 'https://www.tasac.go.tz/uploads/publications/sw1621601184-Shipping%20Business%20Fees_Charges%20and%20Commissions.pdf'

    },

    closePdfViewer () {

        this.showPdfViewer = false
        this.scr = ''

    },

    showNotification (identifier) {

        this.isShowNotification = true
        this.whichNotificationType = identifier

        switch (identifier) {

          case 'CFA_NEW_EXPORT_APPLICATION':

            this.notificationTitle = 'New export applications'
            
            break;

          case 'CFA_NEW_IMPORT_APPLICATION':

            this.notificationTitle = 'New import applications'
            
            break;

          case 'CFA_APPLICATION_RETURNED_FROM_CUSTOMER':

            this.notificationTitle = 'Amended applications'
            
            break;
        
          default: //CFA_APPLICATION_RETURNED_TO_CUSTOMER

            this.notificationTitle = 'Returned application with issues'

            break;
        }

    },

    hideNotification () {

        this.isShowNotification = false

    },

    showMessage (identifier) {

        this.isShowMessage = true
        this.whichNotificationType = identifier

        switch (identifier) {

          case 'CFA_INCOMING_MESSAGE':

            this.messageTitle = 'Incoming messages'
            
            break;
        
          default: //CFA_CUSTOMER_INCOMING_MESSAGE

            this.messageTitle = 'Incoming messages'

            break;
        }

    },

    hideMessage () {

        this.isShowMessage = false

    },

    clickOutside () {

      this.isShowNotification ? this.hideNotification() : ''

    },

    toggleFullScreen() {
    
        var doc = window.document;
        var docEl = doc.documentElement;

        var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
        var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

        if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
          requestFullScreen.call(docEl);
          this.isFullscreen = true
        }
        else {
          cancelFullScreen.call(doc);
          this.isFullscreen = false
        }
    },

    closeNotificationOnClickOutside (){

      if(document.getElementById('divToHide')) {

        var divToHide = document.getElementById('divToHide');

        document.onclick = function(e){

          if(e.target.id !== 'divToHide'){

            //element clicked wasn't the div; hide the div
            divToHide.style.display = 'none';

          }
        }

      }

    },

    logout(){

      localStorage.clear()
      this.$router.push({name:'Logout'})

    },

    clearMessageAsRead (data) {

      return new Promise((resolve, reject) => {

        API.markChatMessagesAsRead(data).then(
            response => {

                if (response.data.status) {

                  resolve()

                } else {}

            }
        ).catch(() => {

          reject()

        })

      })

    },

    goToChatRoom (requestId, identifier) {

      if (identifier === 'ST') {

        if (this.$route.name === 'TasacChatRoom') {

          this.clearMessageAsRead(requestId).then(() => {

            this.autoLoadNotification().then(() => {

                this.$store.dispatch('notifications/chatRoomPage', 'hasChatRoom')
                document.cookie = 'browser-local-cashed-url-back-to=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
                this.$router.push('/clearing-and-forwarding/conversion-room/' + requestId)

            })

        })

        } else {

          this.clearMessageAsRead(requestId).then(() => {

            this.autoLoadNotification().then(() => {

              document.cookie = 'browser-local-cashed-url-back-to=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
              this.$router.push('/clearing-and-forwarding/conversion-room/' + requestId)

            })

        })

        }

      } else {

        this.clearMessageAsRead(requestId).then(() => {

          this.autoLoadNotification().then(() => {

            document.cookie = 'browser-local-cashed-url-back-to=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
            this.$router.push({ name: 'CustomerChat', params: { requestId: requestId} })

          })

        })


      }

      this.isShowMessage = false

    },

    toSpecificRequest (data, status) {

      if(status === 'minor-or-major') {

        this.$router.push({name: 'CustomerApplicationDetails', params: {requestId: data.requestId}})

      } else {

        let requestType = data.requestTypeFlag === "IMP" ? 'import' : 'export';
        this.$router.push('/clearing-and-forwarding/' + requestType + '-' + status + '-application-view/' + data.requestId);

      }

      this.isShowNotification = false

    },

    async autoLoadNotification () {

      let url = '';
      this.totalMessages = 0
      this.totalNotification = 0
      this.messageTypes = []
      this.notificationTypes = []
      
      this.requestsCustomer = []
      this.requestMessagesCustomer = []
      this.requestMessagesStaff = []
      this.newExport = []
      this.newImport = []
      this.AmendedRequests = []

      if (window.$cookies.isKey('sidebar-custom-width-pixel')) {

        if (parseInt(window.$cookies.get('sidebar-custom-width-pixel').split('x')[1]) !== 0) { // customer user
          if (this.finalUserPermissions.includes('CLEARINGFORWARDING_NEW_REJECTED-REQUESTS_GET')) {

            await API.autoLoadNotificationsRejectedCustomer().then(response => {
              if (response.data.status) {
                this.requestsCustomer = response.data.data;
                this.notificationTypes.push({name: 'Returned', quantity: this.requestsCustomer.length, id: 'CFA_APPLICATION_RETURNED_TO_CUSTOMER'})
                this.totalNotification = this.requestsCustomer.length
              } else {

              }
            }
            ).catch(() => {
              this.myloader = false;
            });
          }

          if (this.finalUserPermissions.includes('REQUEST-COMMENTS_NOTIFICATIONS-CUSTOMER_GET')) {

            await API.autoLoadNotificationsRequestMessagesCustomer().then(response => {
              if (response.data.status) {
                this.requestMessagesCustomer = response.data.data;
                this.messageTypes.push({name: 'Message', quantity: this.requestMessagesCustomer.length, id: 'CFA_CUSTOMER_INCOMING_MESSAGE'})
                this.totalMessages = this.requestMessagesCustomer.length
              } else {

              }
            }
            ).catch(() => {
              this.myloader = false;
            });
          }
        } else {
          if (this.finalUserPermissions.includes('REQUEST-COMMENTS_NOTIFICATIONS-STAFF_GET')) {

            await API.autoLoadNotificationsRequestMessagesStaff().then(response => {
              if (response.data.status) {
                this.requestMessagesStaff = response.data.data;

                this.messageTypes.push({name: 'Message', quantity: this.requestMessagesStaff.length, id: 'CFA_INCOMING_MESSAGE'})
                this.totalMessages += this.requestMessagesStaff.length
              } else {

              }
            }
            ).catch(() => {
              this.myloader = false;
            });
          }

          if (this.finalUserPermissions.includes('CLEARINGFORWARDING_NEW_REQUEST-CREATED_GET')) {
            
            await API.autoLoadNotificationsCreatedTasac().then(response => {
              if (response.data.status) {
                this.requests = response.data.data

                for(let i=0 ; i < this.requests.length ; i++) {

                  if(this.requests[i].requestTypeFlag === 'EXP') {

                    this.newExport.push(this.requests[i])
                    
                  }

                  if(this.requests[i].requestTypeFlag === 'IMP') {

                    this.newImport.push(this.requests[i])

                  }
                }
                this.notificationTypes.push({name: 'New import', quantity: this.newImport.length, id: 'CFA_NEW_IMPORT_APPLICATION'}, {name: 'New export', quantity: this.newExport.length, id: 'CFA_NEW_EXPORT_APPLICATION'})
                this.totalNotification += this.newImport.length
                this.totalNotification += this.newExport.length

              } else {

              }
            }
            ).catch(() => {
              this.myloader = false;
            });
          }

          if (this.finalUserPermissions.includes('CLEARINGFORWARDING_NEW_REQUEST-AMENDED_GET')) {
            await API.autoLoadNotificationsAmendedTasac().then(response => {
              if (response.data.status) {
                this.AmendedRequests = response.data.data;

                
                this.notificationTypes.push({name: 'Amended', quantity: this.AmendedRequests.length, id: 'CFA_APPLICATION_RETURNED_FROM_CUSTOMER'})
                this.totalNotification += this.AmendedRequests.length
              } else {

              }
            }
            ).catch(() => {
              this.myloader = false;
            });
          }
        }
      }
    },

    changeCurrentUserPassword () {
      if (this.$v.currentPass.$invalid || this.$v.newPass.$invalid || this.$v.confNewPass.$invalid) {
        if (this.$v.confNewPass.$invalid) {
          this.confNewPassError = true;
        }
        if (this.$v.newPass.$invalid) {
          this.newPassError = true;
        }
        if (this.$v.currentPass.$invalid) {
          this.currentPassError = true;
        }
      } else {
        if (this.newPass !== this.confNewPass) {
          this.passMatch = true;
          this.passMatchCurrent = false;
          this.allCharacters = false;
          this.confNewPassError = false;
          this.newPassError = false;
          this.currentPassError = false;

          this.currentPass = '';
          this.newPass = '';
          this.confNewPass = '';
        } else if (this.currentPass === this.newPass) {
          this.passMatchCurrent = true;
          this.passMatch = false;
          this.allCharacters = false;
          this.confNewPassError = false;
          this.newPassError = false;
          this.currentPassError = false;

          this.currentPass = '';
          this.newPass = '';
          this.confNewPass = '';
        } else {
          let x = 0;
          let y = 0;

          for (const condition of this.rules) {
            if (!condition.regex.test(this.newPass)) {
              x++;
            } else {
              y++;
            }
          }

          if (y > 0 && x === 0) {
            if (this.newPass.indexOf(' ') >= 0) {
              this.allCharacters = true;
              this.passMatch = false;
              this.passMatchCurrent = false;
              this.confNewPassError = false;
              this.newPassError = false;
              this.currentPassError = false;

              this.currentPass = '';
              this.newPass = '';
              this.confNewPass = '';
            } else {
              const newRequest = {

                newPassword: this.newPass,
                oldPassword: this.currentPass

              };

              return new Promise((resolve, reject) => {
                API.changeUserPassword(JSON.stringify(newRequest))

                  .then(res => {
                    if (res.data.status) {
                      this.changePassword = false;

                      this.$swal('Success', 'You have changed your password', 'success');

                      this.confNewPassError = false;
                      this.newPassError = false;
                      this.currentPassError = false;
                      this.passMatch = false;
                      this.passMatchCurrent = false;
                      this.allCharacters = false;

                      this.currentPass = '';
                      this.newPass = '';
                      this.confNewPass = '';
                    }

                    resolve();
                  })

                  .catch(err => {
                    reject(err);
                  });
              });
            }
          } else {
            this.allCharacters = true;
            this.passMatch = false;
            this.passMatchCurrent = false;
            this.confNewPassError = false;
            this.newPassError = false;
            this.currentPassError = false;

            this.currentPass = '';
            this.newPass = '';
            this.confNewPass = '';
          }
        }
      }
    },

    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown () {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown () {
      this.activeNotifications = false;
    },
    toggleSidebar () {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar () {
      this.$sidebar.displaySidebar(false);
    },

    openCloseModal () {
      this.changePassword = !this.changePassword;
    },

    toggle () {
      if (typeof (localStorage.getItem('side-bar-dynamism')) === 'undefined' || localStorage.getItem('side-bar-dynamism') === null) {
        this.isOpen = false;
        localStorage.setItem('side-bar-dynamism', 'false');
      } else {
        this.isOpen = JSON.parse(localStorage.getItem('side-bar-dynamism'));
      }

      this.isOpen = !this.isOpen;

      if (this.isOpen) { localStorage.setItem('side-bar-dynamism', 'true'); } else { localStorage.setItem('side-bar-dynamism', 'false'); }

      this.$store.dispatch('global/toggleSideBar', this.isOpen);
    },

    CheckToggleOnCreate () {
      if (typeof (localStorage.getItem('side-bar-dynamism')) === 'undefined' || localStorage.getItem('side-bar-dynamism') === null) {
        this.isOpen = false;
        localStorage.setItem('side-bar-dynamism', 'false');
      } else {
        this.isOpen = JSON.parse(localStorage.getItem('side-bar-dynamism'));
      }

      this.$store.dispatch('global/toggleSideBar', this.isOpen);
    },

    hideError403 () {

      this.$store.dispatch('global/erroring403', false)

    }
  }
};

</script>
<style scoped>

  .tasac-tariff {
    position:absolute;
    left:45%;
    color:rgb(73, 144, 217) !important;
  }

  .tasac-tariff:hover {
    color: #d59a18 !important;
    cursor: pointer;
  }

  .navbar-brand {
    font-size: 17px !important;
    font-weight: bold !important;
    color: #d59a18 !important;
  }

  #notification-data-amended, #notification-data-customer, #notification-data-import, #notification-data-export,
  #notification-data-message-staff, #notification-data-message-customer {
    font-size: 14px;
  }

  .even-color {
    background-color: #f5f5f5;
  }

  .odd-color {
    background-color: #f0f8ff;
  }

  .odd-color:hover, .even-color:hover {
    background-color: #ffffff !important;
    color: #d59a18 !important;
  }

  .user-manual:hover {
    cursor: pointer;
  }

  .user-logs {
    margin-right: 20px;
  }

  .dropdown-item:hover {
    cursor: pointer;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  .hidden {
    visibility: hidden;
  }

  /** burger **/

  button {
    cursor: pointer;
  }

  /* remove blue outline */
  button:focus {
    outline: 0;
  }

  .burger-button {
    position: relative;
    right: 20px;
    height: 30px;
    width: 32px;
    display: block;
    z-index: 60;
    border: 0;
    border-radius: 0;
    background-color: transparent;
    pointer-events: all;
    transition: transform .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar {
    background-color: #000;
    position: absolute;
    top: 50%;
    right: 6px;
    left: 6px;
    height: 2px;
    width: auto;
    margin-top: -1px;
    transition: transform .6s cubic-bezier(.165,.84,.44,1),opacity .3s cubic-bezier(.165,.84,.44,1),background-color .6s cubic-bezier(.165,.84,.44,1);
  }

  .burger-bar--1 {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    background-color: blue;
  }

  .burger-bar--2 {
    transform-origin: 100% 50%;
    transform: scaleX(.8);
    background-color: #d59a18;
  }

  .burger-button:hover .burger-bar--2 {
    transform: scaleX(1);
  }

  .no-touchevents .burger-bar--2:hover {
    transform: scaleX(1);
  }

  .burger-bar--3 {
    transform: translateY(6px);
    background-color: green;

  }

  #burger.active .burger-button {
    transform: rotate(-180deg);
  }

  #burger.active .burger-bar--1 {
    transform: rotate(45deg)
  }

  #burger.active .burger-bar--2 {
    opacity: 0;
  }

  #burger.active .burger-bar--3 {
    transform: rotate(-45deg)
  }

  @keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    -ms-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  2% {
    -webkit-transform: rotate(15deg);
    -ms-transform: rotate(15deg);
    transform: rotate(15deg);
  }

  4% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  6% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  8% {
    -webkit-transform: rotate(-22deg);
    -ms-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }

  10% {
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
  }

  12% {
    -webkit-transform: rotate(-18deg);
    -ms-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }

  14% {
    -webkit-transform: rotate(18deg);
    -ms-transform: rotate(18deg);
    transform: rotate(18deg);
  }

  16% {
    -webkit-transform: rotate(-12deg);
    -ms-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }

  18% {
    -webkit-transform: rotate(12deg);
    -ms-transform: rotate(12deg);
    transform: rotate(12deg);
  }

  20% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.faa-ring.animated,
.faa-ring.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-ring {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
}

.faa-comments.animated,
.faa-comments.animated-hover:hover,
.faa-parent.animated-hover:hover > .faa-comments {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
  transform-origin-x: 50%;
  transform-origin-y: 0px;
  transform-origin-z: initial;
}

.my-notification {
  
  text-align: center;
  border-radius: 10px;
  min-width: 18px;
  padding: 0 5px;
  height: 18px;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
}

.notification-list {
  position: absolute;
  right: 210px;
  top:50px;
  z-index: 999999;
  -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
  min-width: 10rem;
  padding: 15px 0 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  font-size: 14px;
  line-height: 3px;
}

ul {
    list-style-type: none;
}

ul > div > li a:hover {
  cursor: pointer;
}

</style>

<style>

  .nav-item img {
    border-radius: 180px;
    width: 50px;
  }

  .dg-backdrop, .dg-container {
    z-index: 999999 !important;
  }

  .loader-color {
    color: #d59a18 !important;
  }

  .class-new-app {
  color: #696969
}

.class-approved-app {
  color: #3498db
}

.class-rejected-mojor-app {
  color: #ff0000
}

.class-rejected-minor-app {
  color: #ffd700

}
.class-returned-app {
  color: #2f4f4f
}

.class-assessed-app {
  color: #D2691E
}

.class-rejected-assessment-app {
  color: #ff0000
}

.class-charged-app {
  color: #008080
}

.class-billed-app {
  color: #8b4513
}

.class-bill-approved-app {
  color: #800080
}

.class-paid-app {
  color: #008000
}

.class-closed-app {
  color: #000000

}
.class-duplicate-app {
  color: #ff0000
}

.class-rejected-charge-app {
  color: #ff0000
}

.class-dismissed-app {
  color: #ff0000
}

.class-draft-app {
  color: #ff0000
}

.class-bill-not-approved {
  color: #f4a460;
}

.class-bill-submitting-to-gepg {
  color: #808080;
}

.class-bill-not-paid {
  color: #808080;
}

.class-bill-waiting-control-number {
  color: #003d00;
}

.loading-text-color {
    color: #d59a18;
}

</style>
