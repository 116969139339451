<template>
<card>
  <modal v-if="showModal">
    <div slot="body">
      <div class="row">
        <div class="col-md-6">
          <label>Regime Name</label>
          <input type="text" class="form-control"  placeholder="Regime Name" v-model="regime.regimeName">
          <div v-if="regimeNameError" class="error">
            <div class="error text-danger" v-if="!$v.regime.regimeName.required">
              This field is required
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <label>Regime Code</label>
          <input type="text" class="form-control"  placeholder="Regime Name" v-model="regime.regimeCode">
          <div v-if="regimeCodeError">
            <div class="error text-danger" v-if="!$v.regime.regimeCode.required">
              This field is required
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <label for="operation">Operation Type</label>
          <select class="form-control" id="operation" v-model="regime.operationType">
            <option value=""></option>
            <option value="IMP">IMPORT</option>
            <option value="EXP">EXPORT</option>
          </select>
          <div v-if="operationTypeError" class="error">
            <div class="error text-danger">
              <span v-if="!$v.regime.operationType.required">Field is required</span>
            </div>
          </div>
        </div>


        <div class="col-md-12">
          <label>Regime Description</label>
          <textarea
            class="special-bottom form-control col-md-12"
            placeholder="Regime Description"
            rows="10"
            type="textarea"
            v-model="regime.regimeDescription"
          />
          <div v-if="regimeDescriptionError" class="error">
            <div class="error text-danger" v-if="!$v.regime.regimeDescription.required">
              This field is required
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <button @click="closeModal" class="btn btn-fill btn-outline-info float-right mb-2 mr-2" type="button">
            Close
          </button>
          <button @click="saveRegime" class="btn btn-success float-right mb-2 mr-2" type="button">
            Save
          </button>
        </div>
      </div>

    </div>

  </modal>
  <div class="row">
    <div class="col-md-12 text-right">
     <span class="float-right pointer  mb-3" @click="openModal">
         <font-awesome-icon class="plus-container" icon="plus" :style="{ color: 'green' }" size="lg"/>&nbsp;&nbsp;&nbsp;&nbsp;New Regime
     </span>
    </div>
    <div class="col-md-12">
      <data-table
        :tableHeaders="tableHeaders"
        :tableData="filteredRequests"
        :tableDataKeys="tableDataKeys"
        :pageSizeProp="pageSize"
        :pageNo="pageNo"
        :hasFilter="false"
        :myLoader="loading"
        :myFirstLoader="loading"
        :noDataFound="noDataFound"
        :searchParameterProp="searchParameter"
        :loadingTitle="'Please wait while system is loading CFA applications charges'"
        :hasManageButton="false"
        :totalElements="totalElements"
        :totalPages="totalPages"
        :isFirst="isFirst"
        :isLast="isLast"
        :isHovered="[]"
        :pageNumber="pageNumber"
        :has-custom-button="true"
        custom-button-name="Edit"
        :is-hovered="false"
        :permissions="[ {key: 'searchPermission', value: 'CFAREPORT_ASYNC_REPORT_GET'} ]"
        @fireCustomMethod="openModal"
        @onChange="onChange"
        @goToPage="goToPage"
      >
        <div slot = "pagination">
          <paginate
            v-model="getIsPage"
            :page-count=parseInt(totalPages)
            :page-range="3"
            :margin-pages="2"
            :click-handler="goToPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'">
          </paginate>
        </div>
      </data-table>

    </div>
  </div>
</card>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapGetters } from 'vuex'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'
import {ModelListSelect} from 'vue-search-select';


export default {

  name: 'RegimeList',

  components: {

    PulseLoader,
    Modal,
    ModelListSelect,
    DataTable: () => import('../../../components/DataTable/dataTable'),

  },

  computed: {

    ...mapGetters('regime', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ]),

    getIsPage: {

      get: function () {
        return this.isPage
      },
      set: function (newValue) {
        this.isPage = newValue;
      }

    }

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Regimes List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));
    this.loadRegimes()
  },
  data () {
    return {
      tableHeaders:['Regime Name','Code','Description','Operation'],
      tableDataKeys:['regimeName','regimeCode','regimeDescription','opType'],
      showModal:false,
      edit:false,
      filteredRequests:[],
      pageNo: 0,
      pageSize: 15,
      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: '',
      pageCount: 1,
      noDataFound:false,
      searchParameter: '',
      isPage: 0,

      getRegime: [],
      finalUserPermissions: [],

      loader: false,
      color: 'green',
      size: '15px',
      loading: false,

      data: {},
      regime:{
        operationType: "",
        regimeCode: "",
        regimeDescription: "",
        regimeId: null,
        regimeName: ""
      },

      regimeNameError: false,
      regimeCodeError: false,
      regimeDescriptionError: false,
      operationTypeError:false,

      opLoading:false,
    }
  },

  validations: {

    regime: {
      regimeName: { required },
      regimeCode: { required },
      regimeDescription: { required },
      operationType:{required}
    },

  },

  methods: {

    sortingData(data) {
      this.filteredRequests=[];
      console.log('data',data);
      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
          ...data[x],
          opType:data[x].operationType===null? '':data[x].operationType==='IMP'? 'Import':'Export'
        };

        this.filteredRequests.push(requestObject)

      }

    },

    onChange(event) {
      this.pageSize=event;
      this.pageNo=0;
    },

    goToPage(page) {

      if(page > 0) {page = page - 1;}
      this.isPage = page + 1;
      this.pageNo=page;

      this.loadAllreports();

    },

    loadRegimes () {
      this.loading = true;

      API.getRegimePageable().then(resp => {
          if (resp.data.status) {
            this.totalPages=resp.data.data.totalPages;
            this.totalElements=resp.data.data.totalElements;
            this.pageNumber=resp.data.data.number;
            this.isFirst=resp.data.data.first;
            this.isLast=resp.data.data.isLast;
            this.loading = false;


            if (resp.data.data.content.length > 0) {
              this.noDataFound=false;
              this.sortingData(resp.data.data.content);
            }else {
              this.noDataFound=true;
            }
          } else {
            this.loadNotification('error', 4000, 'Error',
              'Failed to load Regimes',
              'response', 1000 , true  , true);
          }
        }
      ).catch(e => {

        this.loading = false;
        this.loadNotification('error', 4000, 'Error',
          'Failed to load Regimes',
          'response', 1000 , true  , true);
      })
    },


    saveRegime () {
      if (this.$v.regime.regimeName.$invalid || this.$v.regime.regimeCode.$invalid || this.$v.regime.regimeDescription.$invalid || this.$v.regime.operationType.$invalid) {
        if (this.$v.regime.regimeName.$invalid) {
          this.regimeNameError = true
        }
        if (this.$v.regime.regimeCode.$invalid) {
          this.regimeCodeError = true
        }
        if (this.$v.regime.regimeDescription.$invalid) {
          this.regimeDescriptionError = true
        }

        if (this.$v.regime.operationType.$invalid) {
          this.operationTypeError = true
        }
      } else {
        this.opLoading = true;

        if(this.edit){


          let edits =
            {
              regimeName: this.regime.regimeName,
              regimeCode: this.regime.regimeCode,
              regimeDescription: this.regime.regimeDescription,
              regimeId: this.regime.regimeId,
              operationType:this.regime.operationType
            };

          this.closeModal();

          API.updateRegime(JSON.stringify(edits)).then(response => {
              if (response.data.status) {
                this.loadRegimes();
                this.opLoading = false;

                this.regime={
                  operationType: "",
                  regimeCode: "",
                  regimeDescription: "",
                  regimeId: null,
                  regimeName: ""
                };

                this.loadNotification('success', 4000, 'Success',
                  'Regime has been Successfully Updated',
                  'response', 1000 , true  , true);

              } else {
                this.loadNotification('error', 4000, 'Error',
                  response.data.message,
                  'response', 1000 , true  , true);
              }
            }
          ).catch(e => {
            this.opLoading = false;
            this.loadNotification('error', 4000, 'Error',
              'Failed Edit Regime Contact System Administrator',
              'response', 1000 , true  , true);
            this.closeModal()
          })
        }else {
          API.postRegime(JSON.stringify(this.regime)).then(
            response => {
              if (response.data.status) {
                this.closeModal();
                this.loadRegimes();
                this.opLoading = false;

                this.regime={
                  operationType: "",
                  regimeCode: "",
                  regimeDescription: "",
                  regimeId: null,
                  regimeName: ""
                };

                this.loadNotification('success', 4000, 'Success',
                  'Regime has been Saved',
                  'response', 1000 , true  , true);

              } else {
                this.loadNotification('error', 4000, 'Error',
                  response.data.message,
                  'response', 1000 , true  , true);
              }
            }
          ).catch(e => {
            this.opLoading = false;
            this.closeModal();
            this.loadNotification('error', 4000, 'Error',
              'Regime Failed to be Saved,Contact System Administrator',
              'response', 1000 , true  , true);
          })

        }


      }
    },
    openModal (data=null) {
        this.showModal = true;
        if(data!==null){
          this.regime=data;
          this.edit=true;
        }
    },

    closeModal () {
      this.regime={
          operationType: "",
          regimeCode: "",
          regimeDescription: "",
          regimeId: null,
          regimeName: ""
      };

      this.edit=false;
      this.showModal=false;
      this.regimeNameError = false;
      this.regimeCodeError = false;
      this.regimeDescriptionError = false;
      this.operationTypeError=false;
    }

  }
}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>

<style>
  .alert-success {
    padding-top:10px;
    padding-bottom: 10px;
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  .alert-danger {
    padding-top:10px;
    padding-bottom: 10px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  .close-custom {
    margin-top: 10px;
    color: #155724;
  }

  .pointer{
    cursor: pointer;
  }
</style>
