<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 text-right">
          SBMS v1.1.0-alpha
          <span id="sbms-separator">|</span>
          <span class="tasac-copyright">
            Powered by<a
              href="https://www.tasac.go.tz/"
              target="_blank"
            > Tasac</a> &copy; {{ this.thisYear }}
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {

  data () {
    return {

      thisYear: new Date().getFullYear()

    }
  }

}

</script>
<style>

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px !important;
    font-size: 13px;
    padding-top: 5px;
  }

  .tasac-copyright {
    color: #777777 !important;
  }

  #sbms-separator {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
  }

</style>
