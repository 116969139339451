<template>
  <div class="content">
    <card>
      <div
        class="col-md-12 alert-dismissible fade show"
        role="alert"
        :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
        style="border-radius:5px;"
        @enter="enter"
      >
        <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
        <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="SuccessAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-label="Close"
          v-if="ErrorAlert"
          style="line-height:2px;"
          @click="fadeMe"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <br>

      <div class="row">
        <div class="col-md-4">
          <div class="text-left">
            Company List
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
              v-if="myloader"
            />
          </div>
        </div>
        <div class="col-md-4 text-right">
          <span
            class="all-fa-icons btn-space"
            v-if="finalUserPermissions.includes('COMPANY_CONFIG_POST')"
            @click.prevent="openModal(null , null , null, null ,null , null , null, null ,null , null , null, null , null ,
                                      null,actionButton = 'AddModal')"
          >
            <font-awesome-icon icon="plus" />
          </span>
        </div>
      </div>

      <!--   START TOP PAGENATION AND SEARCH ELEMENTS   -->
      <hr>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-3 text-left">
              Entries per page
            </div>

            <div class="col-md-2 float-left">
              <select
                name="PageSize"
                @change="onChange($event)"
                class=""
                v-model="pageSize"
                style="width:73px;"
              >
                <option value="15">
                  15
                </option>
                <option value="25">
                  25
                </option>
                <option value="50">
                  50
                </option>
                <option value="75">
                  75
                </option>
                <option value="100">
                  100
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div
              class="col-md-7 text-right"
              v-if="finalUserPermissions.includes('COMPANY_SEARCH_POST')"
            >
              <form
                class="search"
                @submit.prevent="loadSearchRequestCompanyList(pageNo , pageSize)"
              >
                <div class="form-group float-right">
                  <div class="input-group">
                    <div
                      class="refresh-all"
                      title="load all requests"
                      v-if="reloadAllRequest"
                      @click.prevent="reloadByIcon"
                    >
                      <font-awesome-icon icon="sync-alt" />
                    </div>
                    <input
                      type="text"
                      placeholder="Search ...."
                      id="inputGroup"
                      v-model="searchParamenter"
                      @input="loadAllPageble"
                    >
                    <span
                      class="input-group-addon"
                      @click.prevent="loadSearchRequestCompanyList(pageNo , pageSize)"
                    >
                      <font-awesome-icon icon="search" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-5 text-right">
              <div
                class="col-md-12 disable-highlight"
                unselectable="on"
                style="font-size: 20px"
              >
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="firstPage"
                  title="go to first page"
                >First
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
                  @click="prevPage"
                  title="go to previous page"
                >
                  <font-awesome-icon icon="angle-double-left" />
                </span>

                <span class="btn-space" />
                <span class="btn-space" />

                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="nextPage"
                  title="go to next page"
                >
                  <font-awesome-icon icon="angle-double-right" />
                </span>
                <span
                  class="btn-space"
                  :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
                  @click="lastPage"
                  title="go to last page"
                >Last
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <!--   END TOP PAGENATION AND SEARCH ELEMENTS   -->

      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">
              #
            </th>
            <th scope="col">
              Company Name
            </th>
            <th scope="col">
              Company Code
            </th>
            <th scope="col">
              Company Type
            </th>
            <th
              scope="col"
              class="special-limit"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(list, index) in getCompany"
            :key="list.companyTypeId"
            v-if="!noDataFound"
          >
            <th scope="row">
              {{ index + 1 }}
            </th>
            <td>
              {{ list.companyName }}
            </td><td>
              {{ list.companyCode }}
            </td>
            <td>
              {{ list.website }}
            </td>

            <td class="special-limit">
              <div
                class="all-fa-icons edit-fa-icon"
                v-if="finalUserPermissions.includes('COMPANY_CONFIG_PUT')"
                @click.prevent="openModal(list.companyId , list.companyName , list.companyCode , list.website , list.tinNo
                                          , list.email , list.companyTelephone , list.companyAddress3 , list.companyAddress2 ,
                                          list.companyAddress1 , list.city !== null ? list.city.cityId : 'null' ,
                                          list.companyType !== null ? list.companyType.companyTypeId : 'null',list.companyStatus , list.vrn,actionButton = 'EditModal')"
                title="edit"
              >
                <font-awesome-icon icon="edit" />
              </div>

              <div
                class="all-fa-icons fa-icons-space view-fa-icon"
                v-if="finalUserPermissions.includes('COMPANY_CONFIG_{COMPANYID}_GET')"
                @click.prevent="openModal(list.companyId , null , null , null , null , null , null , null , null
                                          , null , null , null , null, null,actionButton = 'ShowModal')"
                title="view"
              >
                <font-awesome-icon icon="eye" />
              </div>

              <div
                class="all-fa-icons fa-icons-space delete-fa-icon"
                v-if="finalUserPermissions.includes('COMPANY_CONFIG_{COMPANYID}_DELETE')"
                @click.prevent="deleteCompany(list.companyId , list.companyName)"
                title="delete"
              >
                <font-awesome-icon icon="trash-alt" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        class="col-md-12 text-center bg-grey"
        v-if="noDataFound"
      >
        No data found
      </div>

      <!--   START BOTTOM PAGENATION ELEMENTS   -->
      <hr>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            Showing page <b>{{ pageNo + 1 }} </b> of {{ pageCount }}
          </div>

          <div
            class="col-md-9 text-right disable-highlight"
            unselectable="on"
            style="line-height: 5px;font-size: 20px"
          >
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="firstPage"
              title="go to first page"
            >First
            </span>
            <span
              class="btn-space"
              :class="[ this.pageNo === 0 ? 'disabled-click' : 'enabled-click']"
              @click="prevPage"
              title="go to previous page"
            >
              <font-awesome-icon icon="angle-double-left" />
            </span>

            <span class="btn-space" />
            <span class="btn-space" />

            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="nextPage"
              title="go to next page"
            >
              <font-awesome-icon icon="angle-double-right" />
            </span>
            <span
              class="btn-space"
              :class="[ this.pageCount - 1 === this.pageNo ? 'disabled-click' : 'enabled-click']"
              @click="lastPage"
              title="go to last page"
            >Last
            </span>
          </div>
        </div>
      </div>
      <!--   END BOTTOM PAGENATION ELEMENTS   -->
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Company <b>{{ companyName }} ({{ companyCode }})</b>
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv"
          >
            Register Company
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            Details for Company: <b>{{ data.companyName }}</b>
          </div>
          <div
            class="col-md-12"
            v-if="UploadModalDiv"
          >
            Upload Excel for Company
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <card>
          <!--        EDIT FORM       -->
          <form
            method="POST"
            @submit.prevent="editCompany"
            v-if="EditModalDiv"
          >
            <div class="row">
              <div class="col-md-8">
                <base-input
                  type="text"
                  label="Name"
                  placeholder="Name"
                  v-model="companyName"
                  value="companyName"
                  class="special-bottom"
                />
                <div v-if="companyNameError">
                  <div
                    class="error"
                    v-if="!$v.companyName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Code"
                  placeholder="Code"
                  v-model="companyCode"
                  value="companyCode"
                  class="special-bottom"
                />
                <div v-if="companyCodeError">
                  <div
                    class="error"
                    v-if="!$v.companyCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>Search City name</label>
                <model-list-select
                  :list="getCity"
                  v-model="company.city.cityId"
                  class="form-control"
                  option-value="cityId"
                  option-text="cityName"
                  placeholder="Search City name"
                />

                <!-- <label for="myselect45">Select City</label>
                 <select id = "myselect45" class = "form-control special-bottom" v-model="cityId">
                   <option disabled value="">Select city</option>
                   <option v-for="city in getCity"
                           :value = "city.cityId"
                           v-bind:key="city.cityId"
                           :selected="cityId === city.cityId">{{ city.cityName }}</option>
                 </select>-->
                <div v-if="cityIdError">
                  <div
                    class="error"
                    v-if="!$v.cityId.required"
                  >
                    Select city
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label for="myselect3">Select Company Type</label>
                <select
                  id="myselect3"
                  class="form-control"
                  v-model="companyTypeId"
                >
                  <option
                    v-for="companytype in getCompanyType"
                    :value="companytype.companyTypeId"
                    :key="companytype.companyTypeId"
                    :selected="companyTypeId === companytype.companyTypeId"
                  >
                    {{ companytype.companyTypeName }}
                  </option>
                </select>
                <div v-if="companyTypeIdError">
                  <div
                    class="error"
                    v-if="!$v.companyTypeId.required"
                  >
                    Select city
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Webiste"
                  placeholder="Website"
                  v-model="website"
                  value="website"
                  class="special-bottom"
                />
                <div v-if="websiteError">
                  <div
                    class="error"
                    v-if="!$v.website.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="TIN number"
                  placeholder="TIN number"
                  v-model="tinNo"
                  value="tinNo"
                  class="special-bottom"
                />
                <div v-if="tinNoError">
                  <div
                    class="error"
                    v-if="!$v.tinNo.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="vrn"
                  placeholder="vrn"
                  v-model="vrn"
                  value="tinNo"
                  class="special-bottom"
                />
                <div v-if="vrnError">
                  <div
                    class="error"
                    v-if="!$v.vrn.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Telephone"
                  placeholder="Telephone"
                  v-model="companyTelephone"
                  value="companyTelephone"
                  class="special-bottom"
                />
                <div v-if="companyTelephoneError">
                  <div
                    class="error"
                    v-if="!$v.companyTelephone.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Email"
                  placeholder="Email"
                  v-model="email"
                  value="email"
                  class="special-bottom"
                />
                <div v-if="emailError">
                  <div
                    class="error"
                    v-if="!$v.email.required"
                  >
                    This field is required
                  </div>
                  <div
                    class="error"
                    v-if="!$v.email.email"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 1"
                  placeholder="Address 1"
                  v-model="companyAddress1"
                  value="companyAddress1"
                  class="special-bottom"
                />
                <div v-if="companyAddress1Error">
                  <div
                    class="error"
                    v-if="!$v.companyAddress1.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 2"
                  placeholder="Address 2"
                  v-model="companyAddress2"
                  value="companyAddress2"
                  class="special-bottom"
                />
                <div v-if="companyAddress3Error">
                  <div
                    class="error"
                    v-if="!$v.companyAddress2.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 3"
                  placeholder="Address 3"
                  v-model="companyAddress3"
                  value="companyAddress3"
                  class="special-bottom"
                />
                <div v-if="companyAddress3Error">
                  <div
                    class="error"
                    v-if="!$v.companyAddress3.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="EditModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Edit
                </button>
              </div>
            </div>
          </form>

          <!--      ADD FORM      -->
          <form
            method="POST"
            @submit.prevent="saveCompany"
            v-if="AddModalDiv"
          >
            <div class="row">
              <div class="col-md-8">
                <base-input
                  type="text"
                  label="Name"
                  placeholder="Name"
                  v-model="company.companyName"
                  value="company.companyName"
                  class="special-bottom"
                />
                <div v-if="companyNameError">
                  <div
                    class="error"
                    v-if="!$v.company.companyName.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Code"
                  placeholder="Code"
                  v-model="company.companyCode"
                  value="company.companyCode"
                  class="special-bottom"
                />
                <div v-if="companyCodeError">
                  <div
                    class="error"
                    v-if="!$v.company.companyCode.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>Search City name</label>
                <model-list-select
                  :list="getCity"
                  v-model="company.city.cityId"
                  class="form-control"
                  option-value="cityId"
                  option-text="cityName"
                  placeholder="Search City name"
                />

                <!--           <label for="myselect4">Select City</label>
                           <select id = "myselect4" class = "form-control special-bottom" v-model="company.city.cityId">
                             <option disabled value="">Select city</option>
                             <option v-for="city in getCity"
                                     :value = "city.cityId"
                                     v-bind:key="city.cityId"
                                     :selected="cityId === city.cityId">{{ city.cityName }}</option>
                           </select>-->

                <div v-if="cityIdError">
                  <div
                    class="error"
                    v-if="!$v.company.city.cityId.required"
                  >
                    Select city
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <label for="myselect5">Select Company Type</label>
                <select
                  id="myselect5"
                  class="form-control special-bottom"
                  v-model="company.companyType.companyTypeId"
                >
                  <option
                    disabled
                    value=""
                  >
                    Select company type
                  </option>
                  <option
                    v-for="companytype in getCompanyType"
                    :value="companytype.companyTypeId"
                    :key="companytype.companyTypeId"
                  >
                    {{ companytype.companyTypeName }}
                  </option>
                </select>
                <div v-if="companyTypeIdError">
                  <div
                    class="error"
                    v-if="!$v.company.companyType.companyTypeId.required"
                  >
                    Select city
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Webiste"
                  placeholder="Website"
                  v-model="company.website"
                  value="company.website"
                  class="special-bottom"
                />
                <div v-if="websiteError">
                  <div
                    class="error"
                    v-if="!$v.company.website.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="TIN number"
                  placeholder="TIN number"
                  v-model="company.tinNo"
                  value="company.tinNo"
                  class="special-bottom"
                />
                <div v-if="tinNoError">
                  <div
                    class="error"
                    v-if="!$v.company.tinNo.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="vrn"
                  placeholder="vrn"
                  v-model="company.vrn"
                  value="tinNo"
                  class="special-bottom"
                />
                <div v-if="vrnError">
                  <div
                    class="error"
                    v-if="!$v.vrn.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Telephone"
                  placeholder="Telephone"
                  v-model="company.companyTelephone"
                  value="company.companyTelephone"
                  class="special-bottom"
                />
                <div v-if="companyTelephoneError">
                  <div
                    class="error"
                    v-if="!$v.company.companyTelephone.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="email"
                  label="Email"
                  placeholder="Email"
                  v-model="company.email"
                  value="company.email"
                  class="special-bottom"
                />
                <div v-if="emailError">
                  <div
                    class="error"
                    v-if="!$v.company.email.required"
                  >
                    This field is required
                  </div>
                  <div
                    class="error"
                    v-if="!$v.company.email.email"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 1"
                  placeholder="Address 1"
                  v-model="company.companyAddress1"
                  value="company.companyAddress1"
                  class="special-bottom"
                />
                <div v-if="companyAddress1Error">
                  <div
                    class="error"
                    v-if="!$v.company.companyAddress1.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 2"
                  placeholder="Address 2"
                  v-model="company.companyAddress2"
                  value="company.companyAddress2"
                  class="special-bottom"
                />
                <div v-if="companyAddress2Error">
                  <div
                    class="error"
                    v-if="!$v.company.companyAddress2.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 3"
                  placeholder="Address 3"
                  v-model="company.companyAddress3"
                  value="company.companyAddress3"
                  class="special-bottom"
                />
                <div v-if="companyAddress3Error">
                  <div
                    class="error"
                    v-if="!$v.company.companyAddress3.required"
                  >
                    This field is required
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center row">
              <div class="col-md-7 text-right loader-div">
                <span v-if="editMyLoader">Please wait ....</span>
              </div>
              <div class="col-md-2 left loader-div-gif">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="editMyLoader"
                />
              </div>
              <div
                class="col-md-3"
                v-if="AddModalDiv"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info float-right"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="submit"
                  class="btn btn-primary btn-space  float-right"
                  data-dismiss="modal"
                >
                  Save
                </button>
              </div>
            </div>
          </form>

          <!--                SHOW FORM-->
          <div v-if="ShowModalDiv">
            <div class="row">
              <div class="col-md-8">
                <base-input
                  type="text"
                  label="Name"
                  placeholder="Name"
                  :value="data.companyName"
                  readonly
                />
                <div class="red" />
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Code"
                  placeholder="Code"
                  :value="data.companyCode"
                  readonly
                />
                <div class="red" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label for="myselect6">Select City</label>
                <select
                  id="myselect6"
                  class="form-control"
                  disabled
                >
                  <option
                    v-for="city in getCity"
                    :value="city.cityId"
                    :key="city.cityId"
                    :selected="cityId === city.cityId"
                  >
                    {{ city.cityName }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label for="myselect7">Select Company Type</label>
                <select
                  id="myselect7"
                  class="form-control"
                  disabled
                >
                  <option
                    v-for="companytype in getCompanyType"
                    :value="companytype.companyTypeId"
                    :key="companytype.companyTypeId"
                    :selected="companyTypeId === companytype.companyTypeId"
                  >
                    {{ companytype.companyTypeName }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Webiste"
                  placeholder="Website"
                  :value="data.website"
                  readonly
                />
                <div class="red" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="TIN number"
                  placeholder="TIN number"
                  :value="data.tinNo"
                  readonly
                />
                <div class="red" />
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Telephone"
                  placeholder="Telephone"
                  :value="data.companyTelephone"
                  readonly
                />
                <div class="red" />
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Email"
                  placeholder="Email"
                  :value="data.email"
                  readonly
                />
                <div class="red" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 1"
                  placeholder="Address 1"
                  :value="data.companyAddress1"
                  readonly
                />
                <div class="red" />
              </div>
              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 2"
                  placeholder="Address 2"
                  :value="data.companyAddress2"
                  readonly
                />
                <div class="red" />
              </div>

              <div class="col-md-4">
                <base-input
                  type="text"
                  label="Address 3"
                  placeholder="Address 3"
                  :value="data.companyAddress3"
                  readonly
                />
                <div class="red" />
              </div>
            </div>

            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right"
                  @click="closeModal()"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </modal>
  </div>
</template>
<script>
import API from '../../../api/index'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'

export default {

  name: 'CompanyList',

  components: {

    PulseLoader,
    Modal,
    ModelListSelect

  },

  created () {
    this.$store.dispatch('pagenames/currentPageName', 'Companies List')

    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    this.loadPageableCompanyList(this.pageNo, this.pageSize)
    this.loadCity()
    this.loadCompanyType()
  },

  computed: {

    ...mapGetters('companies', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete'

    ])

  },

  data () {
    return {

      array: [],
      getCompany: [],
      finalUserPermissions: [],
      getCity: [],
      getCompanyType: [],
      getCompanyArray: [],
      pageNo: 0,
      pageSize: 15,
      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      data: {},

      color: 'green',
      size: '15px',
      loading: true,

      SuccessMessage: '',
      ErrorMessage: '',
      DataNotFoundMessage: '',

      companyName: '',
      companyCode: '',
      companyId: '',
      cityId: '',
      companyTypeId: '',
      website: '',
      tinNo: '',
      vrn: '',
      email: '',
      companyStatus: '',
      companyTelephone: '',
      companyAddress3: '',
      companyAddress2: '',
      companyAddress1: '',

      error: '',

      fileData: '',
      file: '',

      company: {
        companyName: '',
        companyCode: '',
        city: {
          cityId: ''
        },
        companyType: {
          companyTypeId: ''
        },
        website: '',
        tinNo: '',
        vrn: '',
        email: '',
        companyTelephone: '',
        companyAddress3: '',
        companyAddress2: '',
        companyAddress1: ''
      },

      SuccessAlert: false,
      ErrorAlert: false,
      showModal: false,
      myLoader: false,
      editMyLoader: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      UploadModalDiv: false,
      DataNotFound: false,

      companyNameError: false,
      companyCodeError: false,
      cityIdError: false,
      companyTypeIdError: false,
      websiteError: false,
      tinNoError: false,
      vrnError: false,
      emailError: false,
      companyTelephoneError: false,
      companyAddress1Error: false,
      companyAddress2Error: false,
      companyAddress3Error: false

    }
  },

  validations: {

    company: {
      companyName: { required },
      companyCode: { required },
      city: {
        cityId: { required }
      },
      companyType: {
        companyTypeId: { required }
      },
      website: { required },
      tinNo: { required },
      vrn: { required },
      email: { required, email },
      companyTelephone: { required },
      companyAddress3: { required },
      companyAddress2: { required },
      companyAddress1: { required }
    },

    companyName: { required },
    companyCode: { required },
    cityId: { required },
    companyTypeId: { required },
    website: { required },
    tinNo: { required },
    vrn: { required },
    email: { required, email },
    companyTelephone: { required },
    companyAddress3: { required },
    companyAddress2: { required },
    companyAddress1: { required }

  },

  methods: {

    loadCompany () {
      this.myloader = true

      API.getCompany().then(
        response => {
          if (response.data.status) {
            if (response.data.data.length > 0) {
              this.getCompany = response.data.data
              this.myloader = false
              this.DataNotFound = false
            } else {
              this.DataNotFound = true
              this.myloader = false
              this.DataNotFoundMessage = 'Not data found'
            }
          } else {
            this.DataNotFound = true
            this.myloader = false
            this.DataNotFoundMessage = 'Wooops! looks like there is some data but none retrieved'
          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSearchRequestCompanyList (pageNo, pageSize) {
      this.myloader = true
      this.reloadAllRequest = true
      this.hasSearchedVariable = true

      API.getSearchPageableCompanyList(pageNo, pageSize, this.searchParameter).then(
        response => {
          if (response.data.status) {
            this.myloader = false

            if (response.data.data.length > 0) {
              this.getCompany = response.data.data
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            } else {
              this.noDataFound = true
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.myloader = false
            }
          } else {
            this.noDataFound = true
            this.pageCount = response.data.message
            this.docPageCount = this.pageCount
            this.docPageNo = this.pageNo
            this.docPageSize = this.pageSize
            this.getPageableDoc = response.data.data
            this.myloader = false
          }
        }
      ).catch(e => {
        this.myloader = false
      })
    },

    loadPageableCompanyList (pageNo, pageSize) {
      return new Promise((resolve, reject) => {
        this.myloader = true

        API.getPageableCompanyList(pageNo, pageSize).then(
          response => {
            if (response.data.status) {
              this.pageCount = response.data.message
              this.docPageCount = this.pageCount
              this.docPageNo = this.pageNo
              this.docPageSize = this.pageSize
              this.getCompany = response.data.data
              this.myloader = false
            } else {

            }

            resolve()
          }
        ).catch(e => {
          this.ErrorMessage = ' Requests Not loaded ' + e
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.myloader = false

          reject(e)
        })
      })
    },

    onChange (event) {
      this.pageSize = event.target.value
      this.pageNo = 0
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCompanyList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCompanyList(this.pageNo, this.pageSize)
      }
      this.docPageNo = this.pageNo
      this.docPageSize = this.pageSize
      this.docPageCount = this.pageCount
      this.docSortBy = this.sortBy
    },

    nextPage () {
      this.pageNo++
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCompanyList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCompanyList(this.pageNo, this.pageSize)
      }
    },

    prevPage () {
      this.pageNo--
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCompanyList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCompanyList(this.pageNo, this.pageSize)
      }
    },

    firstPage () {
      this.pageNo = 0
      this.docPageNo = this.pageNo

      if (this.hasSearchedVariable) {
        this.loadSearchRequestCompanyList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCompanyList(this.pageNo, this.pageSize)
      }
    },

    lastPage () {
      this.pageNo = this.pageCount - 1
      this.docPageNo = this.pageNo
      if (this.hasSearchedVariable) {
        this.loadSearchRequestCompanyList(this.pageNo, this.pageSize)
      } else {
        this.loadPageableCompanyList(this.pageNo, this.pageSize)
      }
    },

    reloadByIcon () {
      this.loadPageableCompanyList(this.pageNo, this.pageSize)
      this.noDataFound = false
      this.hasSearchedVariable = false
      this.reloadAllRequest = false
      this.searchParamenter = ''
    },

    loadAllPageble () {
      if (this.searchParameter === '' && this.hasSearchedVariable === true) {
        this.loadPageableCompanyList(this.pageNo, this.pageSize)
        this.noDataFound = false
        this.hasSearchedVariable = false
        this.reloadAllRequest = false
        this.searchParamenter = ''
      }
    },

    loadCity () { // for city select box
      API.getCity().then(
        response => {
          if (response.data.status) {
            this.getCity = response.data.data
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadCompanyType () { // for company type select box
      API.getCompanyType().then(
        response => {
          if (response.data.status) {
            this.getCompanyType = response.data.data
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not loaded'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    },

    loadSingleCompany (data) {
      API.getIndividualCompany(data).then(

        response => {
          if (response.data.status) {
            this.data = response.data.data
          } else {
            this.data = 'Data not found'
          }
        }
      ).catch(e => {
        // this.state.errors.push(e)
      })
    },

    saveCompany () {
      if (this.$v.company.companyName.$invalid || this.$v.company.companyCode.$invalid ||
                this.$v.company.city.cityId.$invalid || this.$v.company.companyType.companyTypeId.$invalid ||
                this.$v.company.website.$invalid || this.$v.company.tinNo.$invalid || this.$v.company.vrn.$invalid ||
                this.$v.company.email.$invalid || this.$v.company.companyTelephone.$invalid ||
                this.$v.company.companyAddress1.$invalid || this.$v.company.companyAddress2.$invalid ||
                this.$v.company.companyAddress3.$invalid) {
        if (this.$v.company.companyName.$invalid) {
          this.companyNameError = true
        }
        if (this.$v.company.companyCode.$invalid) {
          this.companyCodeError = true
        }
        if (this.$v.company.city.cityId.$invalid) {
          this.cityIdError = true
        }
        if (this.$v.company.companyType.companyTypeId.$invalid) {
          this.companyTypeIdError = true
        }
        if (this.$v.company.website.$invalid) {
          this.websiteError = true
        }
        if (this.$v.company.tinNo.$invalid) {
          this.tinNoError = true
        }
        if (this.$v.company.vrn.$invalid) {
          this.vrnError = true
        }
        if (this.$v.company.email.$invalid) {
          this.emailError = true
        }
        if (this.$v.company.companyTelephone.$invalid) {
          this.companyTelephoneError = true
        }
        if (this.$v.company.companyAddress1.$invalid) {
          this.companyAddress1Error = true
        }
        if (this.$v.company.companyAddress2.$invalid) {
          this.companyAddress2Error = true
        }
        if (this.$v.company.companyAddress3.$invalid) {
          this.companyAddress3Error = true
        }
      } else {
        this.editMyLoader = true

        // setTimeout(() => {

        API.postCompany(JSON.stringify(this.company)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = '"' + response.data.data.companyName + '" company type saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.closeModal()
              this.loadPageableCompanyList(this.pageNo, this.pageSize)
              this.editMyLoader = false
              this.company.CompanyName = ''
              this.company.companyCode = ''
              this.company.city.cityId = ''
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Record not added'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    deleteCompany (data, name) {
      this.$store.dispatch('companies/nameBeforeDelete', name)

      this.$dialog.confirm('If you delete Company [ ' + name + " ], it'll be gone forever.", {

        loader: true
      })
        .then((dialog) => {
          // setTimeout(() => {

          API.deleteCompany(data).then(
            response => {
              if (response.data.status) {
                this.SuccessMessage = ', Company  [ ' + this.getNameBeforeDelete + ' ] has been deleted'
                this.$store.dispatch('companies/nameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                window.scrollTo(10, 0)
                this.loadPageableCompanyList(this.pageNo, this.pageSize)
                this.$el.scrollTop = 0
              } else {

              }
            }
          ).catch(e => {
            this.ErrorMessage = ' Record not deleted'
            this.SuccessMessage = ''
            this.SuccessAlert = false
            this.ErrorAlert = true
          })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    editCompany () {
      if (this.$v.companyName.$invalid || this.$v.companyCode.$invalid ||
                this.$v.cityId.$invalid || this.$v.companyTypeId.$invalid ||
                this.$v.website.$invalid || this.$v.tinNo.$invalid || this.$v.vrn.$invalid ||
                this.$v.email.$invalid || this.$v.companyTelephone.$invalid ||
                this.$v.companyAddress1.$invalid || this.$v.companyAddress2.$invalid ||
                this.$v.companyAddress3.$invalid) {
        if (this.$v.companyName.$invalid) {
          this.companyNameError = true
        }
        if (this.$v.companyCode.$invalid) {
          this.companyCodeError = true
        }
        if (this.$v.cityId.$invalid) {
          this.cityIdError = true
        }
        if (this.$v.companyTypeId.$invalid) {
          this.companyTypeIdError = true
        }
        if (this.$v.website.$invalid) {
          this.websiteError = true
        }
        if (this.$v.tinNo.$invalid) {
          this.tinNoError = true
        }

        if (this.$v.vrn.$invalid) {
          this.vrnError = true
        }
        if (this.$v.email.$invalid) {
          this.emailError = true
        }
        if (this.$v.companyTelephone.$invalid) {
          this.companyTelephoneError = true
        }
        if (this.$v.companyAddress1.$invalid) {
          this.companyAddress1Error = true
        }
        if (this.$v.companyAddress2.$invalid) {
          this.companyAddress2Error = true
        }
        if (this.$v.companyAddress3.$invalid) {
          this.companyAddress3Error = true
        }
      } else {
        this.editMyLoader = true

        this.array =
                  {
                    companyName: this.companyName,
                    companyCode: this.companyCode,
                    cityId: this.cityId,
                    website: this.website,
                    tinNo: this.tinNo,
                    email: this.email,
                    companyId: this.companyId,
                    companyTelephone: this.companyTelephone,
                    companyAddress3: this.companyAddress3,
                    companyAddress2: this.companyAddress2,
                    companyAddress1: this.companyAddress1,
                    companyTypeId: this.companyTypeId,
                    companyStatus: this.companyStatus,
                    vrn: this.vrn
                  }

        // setTimeout(() => {

        API.updateCompany(JSON.stringify(this.array)).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = `${this.updatedValueStatus(this.getValuesBeforeEdit,
                              [response.data.data.companyAddress1, response.data.data.companyAddress2,
                                response.data.data.companyAddress3, response.data.data.companyTelephone,
                                response.data.data.companyStatus, response.data.data.email, response.data.data.tinNo,
                                response.data.data.website, response.data.data.companyCode, response.data.data.companyName],
                              'Company', response.data.data.companyName)}`
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              window.scrollTo(10, 0)
              this.closeModal()
              this.loadPageableCompanyList(this.pageNo, this.pageSize)
              this.editMyLoader = false
            } else {

            }
          }
        ).catch(e => {
          this.errors = e
          this.editMyLoader = false
          this.ErrorMessage = ' Record not edited'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.editMyLoader = false
          this.closeModal()
        })

        // }, 2000);
      }
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (companyId = null, companyName = null, companyCode = null, website = null, tinNo = null,
      email = null, companyTelephone = null, companyAddress3 = null, companyAddress2 = null,
      companyAddress1 = null, cityId = null, companyTypeId = null, companyStatus = null, vrn = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.EditModalDiv = true
        this.showModal = true
        this.ShowModalDiv = false
        this.UploadModalDiv = false
        this.companyName = companyName
        this.companyCode = companyCode
        this.companyId = companyId
        this.cityId = cityId
        this.website = website
        this.tinNo = tinNo
        this.vrn = vrn
        this.email = email
        this.companyStatus = companyStatus
        this.companyTelephone = companyTelephone
        this.companyAddress3 = companyAddress3
        this.companyAddress2 = companyAddress2
        this.companyAddress1 = companyAddress1
        this.companyTypeId = companyTypeId

        this.$store.dispatch('companies/valuesBeforeEdit', [this.companyAddress1, this.companyAddress2,
          this.companyAddress3, this.companyTelephone, this.companyStatus, this.email, this.tinNo, this.website,
          this.companyCode, this.companyName])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.UploadModalDiv = false
      } else {
        this.loadSingleCompany(companyId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
        this.UploadModalDiv = false
      }
    },

    closeModal () {
      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.UploadModalDiv = false

      this.company.CompanyTypeName = ''
      this.company.companyTypeCode = ''
      this.company.city.cityId = ''

      this.companyNameError = false
      this.companyCodeError = false
      this.cityIdError = false
      this.companyTypeIdError = false
      this.websiteError = false
      this.tinNoError = false
      this.emailError = false
      this.companyTelephoneError = false
      this.companyAddress1Error = false
      this.companyAddress2Error = false
      this.companyAddress3Error = false
    },

    uploadFile (event) {
      this.fileData = event.target.files[0]
      this.file = this.fileData.name

      this.myloader = true

      const formData = new FormData()

      formData.append('file', this.fileData)

      API.uploadCompanyExcel(formData).then(

        response => {
          if (response.data.status) {
            response.data.data.map((value) => {
              this.getCompanyArray.push(value.companyName)
            })

            this.SuccessAlert = true
            this.ErrorAlert = false
            this.SuccessMessage = ' Countries Added [ ' + this.getCompanyArray + ' ]'
            this.myloader = false
            this.loadPageableCompanyList(this.pageNo, this.pageSize)
            this.$el.scrollTop = 0
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ' Record not sent'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
        this.myloader = false
      })
    }

  }

}
</script>
<style scoped>
  .btn-space {
    margin-right: 10px !important;
  }

  .special-limit {
    max-width: 100px !important;
    min-width: 90px !important;
  }

  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.50;
    text-decoration: none;
  }
  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .fileUpload span {
    cursor:pointer;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }
</style>
