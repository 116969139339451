<template>
    <div>
        <card>
        <data-table
            :tableHeaders="tableHeaders"
            :tableData="filteredRequests"
            :tableDataKeys="tableDataKeys"
            :pageSizeProp="pageSize"
            :pageNo="pageNo"
            :myLoader="myLoader"
            :myFirstLoader="myFirstLoader"
            :noDataFound="noDataFound"
            :searchParameterProp="searchParameter"
            :activeTariffItems="activeTariffItems"
            :loadingTitle="'Please wait while system is loading your bills'"
            :hasManageButton="false"
            :coloredStatus="true"
            :totalElements="totalElements"
            :totalPages="totalPages"
            :isFirst="isFirst"
            :isLast="isLast"
            :pageNumber="pageNumber"
            :buttonList="[ {'code' : 'BillGenerate' , 'name' : 'More Details' , 'class' : 'btn-primary'} ]"
            :whichModuleAndType="whichModuleAndType"
            :is-hovered="isHovered"
            :permissions="[ {key: 'searchPermission', value: 'CLEARINGFORWARDING_SEARCH_REQUESTS_GET'} ]"
            @search="searchGeneralChargeParam"
            @resetParams="resetParams"
            @showToolTip="showToolTip"
            @hideToolTip="hideToolTip"
            @onChange="onChange"
            @goToPage="goToPage"
            @goToBillGenerate="goToApplication"
        >
        <div slot = "pagination">
            <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
            </paginate>
        </div>
        </data-table>
        </card>
        <global-modal v-if="myLoader">
        <div slot="globalLoader">
            <div class="row text-center">
            <div class="col-md-12">
                Loading your bills, Please wait
            </div>
            <div class="col-md-12">
                <br>
            </div>
            <div class="col-md-12">
                <pulse-loader
                :loading="loading"
                :color="color"
                :size="size"
                />
            </div>
            </div>
        </div>
        </global-modal>
    </div>
</template>

<script>

    import {mapGetters, mapActions} from "vuex";
    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import GlobalModal from '../../../components/globalLoader'
    import DataTable from '../../../components/DataTable/dataTable'

    export default {

    name: 'MyBillList',

    data () {

        return {

        finalUserPermissions: [],

        color: 'green',
        size: '13px',
        loading: true,

        requests: [],
        filteredRequests: [],
        activeTariffItems: [],
        noDataFound: false,
        reloadAllRequest: false,
        hasSearchedVariable: false,
        searchParameter: '',
        pageNo: 0,
        pageSize: 15,
        myLoader: false,
        isHovered: [],
        myFirstLoader: false,
        totalElements: "",
        totalPages: "",
        isFirst: false,
        isLast: false,
        pageNumber: '',
        whichModuleAndType: 'cfaCustomerBill',
        newParams: '',
        isPage: 0,
        pageNoPart: 0,
        initialParams: '?billType=cfa&size=' + 15 + '&page=' + 0,
    
        tableHeaders: ['Bill Number','Tansad Number', 'Customer', 'Total Amount (TZS)', 'Billed Date','Control Number', 'Bill Type', 'Status'],
        tableDataKeys: ['billNo','tansadno', 'company', 'billVatedAmountTzs', 'dateBilled','controlNumber', 'bulk', 'status']

        }

    },

    components: {

        PulseLoader,
        GlobalModal,
        DataTable

    },

    created () {

        this.loadRequest()
        this.loadAllActiveTariffItems()
        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.$store.dispatch('pagenames/currentPageName', 'My Bills')

    },

    computed: {

        ...mapGetters('shippingtallybilling', [

            'getRequestList',
            'getPageCount',
            'getMyFirstLoader',
            'getNoDataFound',
            'getReloadAllRequest',
            'getMyLoader',
            'getSearchParameter',
            'getHasSearchedVariable',
            'getPageNo',
            'getPageSize',
            'getTotalElement',
            'getTotalPage',
            'getPageNumber',
            'getIsFirst',
            'getIsLast',

        ]),

        ...mapGetters('global' , [

            'getActiveTariffItems'

        ]),

        getIsPage: {

        get: function () {
            return this.isPage
        },
        set: function (newValue) {
            this.isPage = newValue;
        }

        }

    },

    methods: {

        ...mapActions("shippingtallybilling", [

            "searchWithGeneralParamsForAllBillsOnCreated",
            "searchWithGeneralParamsForAllBillsAfterCreated",
            "searchWithGeneralParams"

        ]),

        ...mapActions("global", [

            'loadActiveTariffItems'

        ]),

        resetParams () {

            this.searchGeneralChargeParam(this.initialParams , 0)
            this.$store.dispatch("shippingtallybilling/hasSearchngParams", false);
            this.newParams = ""
            this.pageNoPart = 0
            this.isPage = this.pageNoPart + 1
            this.filteredRequests = []

        },

        loadAllActiveTariffItems() {

            this.loadActiveTariffItems({}).then(() => {

                this.activeTariffItems = this.getActiveTariffItems;

            }).catch(() => {})

        },

        loadRequest() {

            if (localStorage.getItem("search-params") !== null) {

            this.newParams = ""
            this.newParams = localStorage.getItem("search-params")

            let secondPart = this.newParams.split("size=")[1]
            this.pageNoPart = parseInt(secondPart.split("page=")[1])
            this.isPage = this.pageNoPart + 1

            //this.searchGeneralChargeParam(this.newParams) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page with selected page bug
            this.searchGeneralChargeParam(this.newParams, this.pageNoPart) // localStorage.getItem("search-params") add this and delete above to go back to cuurent page without selected page bug

            } else {

                this.loadPageableRequestsOnCreated(this.initialParams , 0)
                this.$store.dispatch("shippingtallybilling/hasSearchngParams", false);
                this.newParams = ""
                this.pageNoPart = 0

            }

            this.filteredRequests = []

        },

        sortingData(data) {

            for(let x = 0 ; x < data.length ; x++) {

                let requestObject = {

                        ...data[x],
                        dateBilled: this.dateTimeToHuman(data[x].dateBilled),
                        billVatedAmountTzs: this.putComma(data[x].billVatedAmountTzs),
                        bulk: data[x].bulk ? 'BULK' : 'SINGLE'

                    }

                    this.filteredRequests.push(requestObject)

            }

        },

        loadSearchParameters(params, fromApplicationPageNo) {

            let fromApplicationPageNoOrNot
            typeof fromApplicationPageNo !== 'undefined' ? fromApplicationPageNoOrNot = fromApplicationPageNo : fromApplicationPageNoOrNot = 0
            this.isPage = fromApplicationPageNo

            if (this.getHasSearchedVariable) {

                let firstPart = params.split("&page=")[0]
                let secondPart = firstPart.split("size=")[0]
                this.newParams = ""

                this.newParams = secondPart + 'size=' + this.pageSize + '&page=' + fromApplicationPageNoOrNot

                return new Promise((resolve) => {

                    this.$store.dispatch("shippingtallybilling/paramsSearching", this.newParams);

                    resolve();

                })

            } else {

                return new Promise((resolve) => {

                    this.$store.dispatch("shippingtallybilling/paramsSearching", params);

                    resolve();

                })

            }

        },

        setAllGetters(flag) {

            this.requests = this.getRequestList;
            flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
            flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
            this.noDataFound = this.getNoDataFound;
            this.SuccessMessage = this.getSuccessMessage;
            this.ErrorMessage = this.getErrorMessage;
            this.ErrorAlert = this.getErrorAlert;
            this.SuccessAlert = this.getSuccessAlert;
            this.pageNo = this.getPageNo;
            this.pageSize = this.getPageSize;

            this.totalElements = this.getTotalElement;
            this.totalPages = this.getTotalPage;
            this.pageNumber = this.getPageNumber;
            this.isFirst = this.getIsFirst;
            this.isLast = this.getIsLast;

            this.sortingData(this.requests)

        },

        searchGeneralChargeParam(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.searchWithGeneralParams({}).then(() => {

                this.setAllGetters('onCreated')

                }).catch(() => {


                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        loadPageableRequestsOnCreated(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.searchWithGeneralParamsForAllBillsOnCreated({}).then(() => {

                this.setAllGetters('onCreated');

                });

                this.myFirstLoader = this.getMyFirstLoader;

            }).catch(() => {


            });

        },

        loadPageableRequestsAfterCreated(params, fromApplicationPageNo) {

            this.newParams = params

            this.filteredRequests = []

            this.loadSearchParameters(params, fromApplicationPageNo).then(() => {

                this.searchWithGeneralParamsForAllBillsAfterCreated({}).then(() => {

                this.setAllGetters('afterCreated');

                });

                this.myLoader = this.getMyLoader;

            }).catch(() => {


            });

        },

        goToPage(page) {

            if(page > 0) {page = page - 1;}
            this.isPage = page + 1

            this.pageNo = page;
            this.pageNoPart = this.isPage

            this.$store.dispatch("shippingtallybilling/pageSizing", this.pageSize);
            this.$store.dispatch("shippingtallybilling/pageNumbering", this.pageNo);

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("page=")[0]
                this.newParams = ""
                this.newParams = firstPart + '&page=' + this.pageNo
                this.pageNoPart = this.isPage

                this.searchGeneralChargeParam(this.newParams, this.pageNoPart);
                this.filteredRequests = []

            } else {

                let newparams = '?billType=cfa&size=' + this.pageSize + '&page=' + this.pageNo
                
                this.loadPageableRequestsAfterCreated(newparams, this.pageNoPart);
                this.filteredRequests = []
            }

        },

        onChange (event) {

            this.$store.dispatch("shippingtallybilling/pageSizing", Number(event));
            this.$store.dispatch("shippingtallybilling/pageNumbering", 0);
            localStorage.removeItem("page-no")
            localStorage.removeItem("page-size")
            this.isPage = 0

            this.pageSize = Number(this.getPageSize);
            this.pageNo = this.getPageNo;

            if (this.getHasSearchedVariable) {

                let firstPart = this.newParams.split("&page=")[0]
                let secondPart = firstPart.split("size=")[0]
                let thirdPart = firstPart.split("size=")[1]
                this.newParams = ""
                this.newParams = secondPart + 'size=' + this.pageSize + 'page=' + 0
                this.pageNoPart = 0

                this.searchGeneralChargeParam(this.newParams , this.pageNoPart);
                this.filteredRequests = []

            } else {

                let newparams = '?billType=cfa&size=' + this.pageSize + '&page=' + 0

                this.loadPageableRequestsAfterCreated(newparams , this.pageSize);
                this.filteredRequests = []
            }

        },

        showToolTip (id) {

        this.isHovered = [id]

        },

        hideToolTip (id) {

        this.isHovered.splice(this.isHovered.indexOf(id), 1)

        },

        goToApplication (object) {

            document.cookie = 'browser-local-cashed-url-back-2=' + this.$route.fullPath + ';Path = /;SameSite = Strict'

            if (this.getHasSearchedVariable) {

                localStorage.setItem("search-params", this.newParams);
                this.newParams = ""

            } else {

                localStorage.setItem("page-no", this.getPageNo);
                localStorage.setItem("page-size", this.getPageSize);
                
            }

            this.$router.push({name: 'MyBillDetails', params: {billId: object.billId}})

        }

    }

}
</script>

<style scoped>

    .btn-space {
        margin-right: 10px !important;
    }

    .btn-success-custom {
        padding: 0 4px 0 4px;
        color: #3cb371;
    }

    .btn-success-custom:hover {
        cursor: pointer;
        color: green;
    }

    .table-striped tr {
        line-height: 20px;
    }

    .disabled-click {
        pointer-events:none;
        color:#dcdcdc;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }

    .enabled-click {
        pointer-events:auto;
    }

    .search .form-group .input-group .input-group-addon {
        width: 40px;
        text-align: center;
        padding-top: 3.5px;
        background-color: rgba(220, 220, 220, .3);
    }

    .disable-highlight:hover span {
        cursor: pointer;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .refresh-all {
        margin-top: 6px;
        margin-right: 20px
    }

    .refresh-all:hover {
        cursor: pointer;
    }

    .search .form-group .input-group #inputGroup {
        width: 250px;
        padding-top: 3.5px;
    }

    .search .form-group .input-group .input-group-addon:hover {
        cursor: pointer;
        background-color: rgba(220, 220, 220, .5);
    }

    .custom-btn {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        padding: 5px 9px 5px 9px !important;
        font-size: 14px !important;
        background-color: #FFFFFF !important;
    }

    .table td , .table th {
        text-align: center;
    }

    .text-center {
        text-align: center !important;
    }

    .table-hover tbody tr:hover {
        background-color: rgba(153, 186, 221, .5) !important;
    }

    .bg-grey {
        background-color: #dcdcdc;
    }

    .more-customer-details:hover {
        cursor: pointer;
        color: #d59a18;
    }

    .close-info {
        color: blue !important;
    }

    .close-info:hover {
        cursor: pointer;
        color: green !important;
    }

</style>
