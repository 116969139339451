<template>
  <div id="operation">
    <div class="row">
      <crud-notification
        :error-message="ErrorMessage"
        :success-message="SuccessMessage"
        :error-alert="ErrorAlert"
        :success-alert="SuccessAlert"
        @fadeMe="fadeMe"
        @enter="enter"
      />

    </div>
    <div class="row">
      <div class="col-12">
        <ul class="progressbar">
          <li :class="step===1? 'active':''">
            Step 1
          </li>
          <li :class="step===2? 'active':''">
            Step 2
          </li>
          <li :class="step===3? 'active':''">
            Step 3
          </li>
        </ul>
      </div>
    </div>

    <card>
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
      <div v-if="step===1">
        <div class="row">
          <div class="text-center sect-title col-md-12">
            <label
              for="type"
              class="notification-title"
            >Select Tally Operation</label>
            <select
              class="custom-select"
              id="type"
              v-model="operationType"
            >
              <option
                selected
                value=""
              >
                Select Operation
              </option>
              <option value="DISCHARGING">
                DISCHARGING
              </option>
              <option value="LOADING">
                LOADING
              </option>
              <option value="STUFFING">
                STUFFING
              </option>
              <option value="DESTUFFING">
                DESTUFFING
              </option>
            </select>
            <div
              v-if="operationTypeError"
              class="error"
            >
              <span
                v-if="!$v.operationType.required"
                class="text-danger"
              >This Field is Required</span>
            </div>
          </div>
        </div>
        <!--                <div class="row" v-if="selectedNotification!==''">-->
        <!--                    <div class="col-md-12 mt-5">-->
        <!--                        <table class="table">-->
        <!--                            <tbody>-->
        <!--                            <tr>-->
        <!--                                <td>CALLID&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.callId}}</strong></span></td>-->
        <!--                                <td>Location&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.locationName}}</strong></span></td>-->
        <!--                                <td>Location Type&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.locationType}}</strong></span></td>-->
        <!--                            </tr>-->
        <!--                            <tr>-->
        <!--                                <td>Location Type&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.locationType}}</strong></span></td>-->
        <!--                                <td v-if="operationType!=='Discharging&Loading'">Master BL&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.masterBl}}</strong></span></td>-->
        <!--                                <td>Shiiping Order No<strong>:</strong><span class="data"><strong>{{selectedNotificationData.shippingOrderNo}}</strong></span></td>-->
        <!--                            </tr>-->
        <!--                            <tr>-->
        <!--                                <td>Staring Time&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.startTime!==undefined?dateTimeToHuman(selectedNotificationData.startTime):""}}</strong></span></td>-->
        <!--                                <td>End Time&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.endTime!==undefined?dateTimeToHuman(selectedNotificationData.endTime):""}}</strong></span></td>-->
        <!--                                <td>Location Type&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.locationType}}</strong></span></td>-->
        <!--                            </tr>-->
        <!--                            <tr>-->
        <!--                                <td>Voyage&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.voyage}}</strong></span></td>-->
        <!--                                <td>Vessel Call Id&nbsp;&nbsp;&nbsp;<strong>:</strong><span class="data"><strong>{{selectedNotificationData.vesselCallId}}</strong></span></td>-->
        <!--                            </tr>-->
        <!--                            </tbody>-->
        <!--                        </table>-->
        <!--                        <hr/>-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
      <div v-if="step===2">
        <div class="form-group">
          <label
            for="atleast"
            class="notification-title"
          >Required Minimum Number of Clerk Per Shift</label>
          <input
            type="number"
            class="form-control"
            id="atleast"
            v-model="atleast"
          >
        </div>
        <label class="notification-title">Select Clerk Names</label>
        <multi-select
          :options="allUsers"
          :selected-options="items"
          placeholder="select item"
          id="multiSelect"
          @select="onSelect"
        />
        <label class="notification-title mt-3">Select Resources</label>
        <multi-select
          :options="resources"
          :selected-options="itemsResources"
          placeholder="select item"
          @select="onSelectResource"
        />
      </div>
      <div v-if="step===3">
        <card>
          <table class="table customeFont">
            <tbody>
              <tr>
                <td>TALLY OPERATION : <strong>{{ operationType }}</strong></td>
                <td>CALL ID:<strong>{{ selectedNotificationData.callId }}</strong></td>
                <td>REQUIRED NUMBER OF PEOPLE FOR OPERATION : <strong>{{ atleast }}</strong></td>
              </tr>
              <tr>
                <td>EXCLUDE ASSIGNED ON SPECIFIED DAY <strong>{{ excludeAssignedOnSpecifiedDay? 'EXCLUDE':'DO NOT EXCLUDE' }}</strong></td>
                <td>CONSIDER SHIFT TIME <strong>{{ considerShiftTime? 'CONSIDER ':'DO NOT CONSIDER' }}</strong></td>
                <td>CONSIDER ALL CLERKS IN SHIFTS <strong>{{ allClerk?'CONSIDER':'DO NOT CONSIDER' }}</strong></td>
              </tr>
            </tbody>
          </table>
          <div class="clerks">
            <h3>Clerks Selected:</h3>
            <span
              class="ckerkNames"
              v-for="(clerk,index) in items"
            >
              {{ clerk.text }},
            </span>
          </div>
        </card>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-default"
            @click.prevent="continueStep"
            v-if="step<3"
          >
            Continue
          </button>
          <button
            class="btn btn-default"
            @click.prevent="createOperation"
            v-if="step===3"
          >
            Submit
          </button>
          <button
            class="btn btn-default ml-3"
            @click.prevent="backStep"
            v-if="step>1"
          >
            Revert
          </button>

          <button
            type="button"
            class="btn btn-fill btn-outline-info float-right mb-2 mr-2"
            @click.prevent="goToBack"
          >
            Close
          </button>

        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import TALLYAPI from '../../../api/tally';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import API from '../../../api';
import { ModelListSelect, MultiSelect } from 'vue-search-select';
import CrudNotification from '../../../components/DataTable/crudNotification';


export default {
  name: 'TallyingOperation',
  components: {
    PulseLoader, ModelListSelect, MultiSelect,CrudNotification
  },

  validations: {
    selectedNotification: { required },
    operationType: { required },
    allClerk: { required },
    excludeAssignedOnSpecifiedDay: { required },
    considerShiftTime: { required }
  },

  created () {
    this.getNotifications();
    this.getUsers();
    this.getAllResources();
    this.selectedNotificationFunc();
    this.$store.dispatch('pagenames/currentPageName', 'Tallying Operation');

  },
  data () {
    return {
      resources: [],
      itemsResources: [],

      loading: false,
      color: 'green',
      size: '15px',
      allUsers: [],
      selectedClerk: '',
      notifications: [],
      selectedNotification: '',
      selectedNotificationError: false,
      selectedNotificationData: {},
      operationTypeError: false,
      excludeAssignedOnSpecifiedDay: false,
      excludeAssignedOnSpecifiedDayError: false,
      considerShiftTime: false,
      considerShiftTimeError: false,

      operationType: '',
      step: 1,
      atleast: '',
      allClerk: false,
      allClerkError: false,

      items: [],
      lastSelectItem: {},
      lastSelectItemResource: {},

      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      SuccessMessage: '',
    };
  },

  methods: {

    getAllResources () {
      TALLYAPI.getAllResources().then((response) => {
        response.data.data.map((data) => {
          this.resources.push({ value: data.id, text: data.name });
        });
      });
    },

    onSelect (items, lastSelectItem) {
      this.items = items;
      this.lastSelectItem = lastSelectItem;
    },

    onSelectResource (items, lastSelectItem) {
      this.itemsResources = items;
      this.lastSelectItemResource = lastSelectItem;
    },

    createOperation () {
      const clerkIds = [];
      const resourceIds = [];

      this.items.map((data) => {
        clerkIds.push(data.value);
      });

      this.itemsResources.map((data) => {
        resourceIds.push(data.value);
      });

      const requestObject = {
        allClerk: this.allClerk,
        atleast: this.atleast,
        clerkIds: clerkIds,
        considerShiftTime: this.considerShiftTime,
        excludeAssignedOnSpecifiedDay: this.excludeAssignedOnSpecifiedDay,
        notificationId: this.selectedNotification,
        operationType: this.operationType,
        resourceIds: resourceIds,
        specifiedByShiftTime: this.considerShiftTime
      };

      this.loading = true;
      TALLYAPI.CreateTallyOperation(requestObject).then((response) => {
        this.loading = false;
        this.allClerk = '';
        this.atleast = '';
        this.items = [];
        this.considerShiftTime = '';
        this.excludeAssignedOnSpecifiedDay = '';
        this.selectedNotification = '';
        this.operationType = '';
        this.itemsResources = [];
        this.lastSelectItem = {};
        this.lastSelectItemResource = {};
        this.step = 1;

        this.ErrorMessage = ''
        this.SuccessAlert = true
        this.ErrorAlert = false
        this.SuccessMessage = 'Operation was Created Successfully';
        this.loading = false
      }).catch(() => {
        this.loading = false;
        this.ErrorMessage = 'Operation Failed To Be created';
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.loading = false
      });
    },

    fullName (name) {
      return `${name.firstName}  ${name.lastName}`;
    },

    continueStep () {
      this.step = this.step + 1;
    },

    goToBack(){
      history.back()
    },

    backStep () {
      if (this.step !== 1) {
        this.step = this.step - 1;
      }
    },

    getNotifications () {
      TALLYAPI.getNotification().then((response) => {
        this.notifications = response.data.data.content;
      });
    },

    getNotiFicationById () {
      const notification = this.notifications.findIndex((notification) => {
        return notification.id.toString() === this.selectedNotification.toString();
      });
      this.selectedNotificationData = this.notifications[notification];
    },

    selectedNotificationFunc () {
      this.selectedNotification = this.$route.params.notificationId;
    },

    dateTimeToHuman (stringDate) {
      if (stringDate !== null) {
        const splittedDate = stringDate.split('T');

        const vuejsDate = splittedDate[0];

        const vuejsTime = splittedDate[1].split('.');

        return vuejsDate + ' ' + vuejsTime[0];
      }
    },
    getUsers () {
      this.loading = true;
      TALLYAPI.getShiftClerks().then((response) => {
        this.loading = false;
        response.data.data.map((data) => {
          this.allUsers.push({ value: data.id, text: this.fullName(data) });
        });
      });
    },
    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert;
      } else {
        this.ErrorAlert = !this.ErrorAlert;
      }
    },
    enter: function (el, done) {
      var that = this;

      setTimeout(function () {
        that.SuccessAlert = false;
        that.ErrorAlert = false;
      }, 3000); // hide the message after 3 seconds
    },

  }
};
</script>
<style lang="scss">
    .ui{
        &.label{
            background: green;
            color: #FFFFFF;
        }
    }
</style>

<style scoped lang="scss">

    .clerks{
        .ckerkNames{
            font-size: 18px;
            font-weight: 400;
        }
    }

    .customeFont{
        font-size: 16px !important;
    }

    .notification-title{
        font-size: 15px;
        color: black;
    }
    .card{
        color: black !important;
    }

    .sect-title{
        font-size: 20px;
    }

    .progressbar{

        .active{
            color: green;
            border-color: green;
            &:after{
                background-color: green;
            }
        }
        counter-reset: step;
        li{
            list-style: none;
            float:left;
            width: 33.33%;
            position: relative;
            text-align: center;

            &.active{
                &:before{
                    background: green;
                    color: white;
                }
            }

            &:before{
                content: counter(step);
                counter-increment: step;
                width: 30px;
                height: 30px;
                line-height: 30px;
                display: block;
                border: 2px solid green;
                text-align: center;
                margin: 0 auto 10px auto;
                border-radius: 50%;
                background: white;
                color:black;

            }

            &:after{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background: #ddd;
                border:2px solid green;
                top: 15px;
                left: -50%;
                z-index: -1;

            }

            &:first-child{
                &:after{
                    content: none;
                }
            }

        }
    }

    ul{
        &.details{
            list-style: none;
            display: flex;

            li{
                display: block;
                width: 33.33%;
                span{

                }
            }
        }

    }
</style>
