<template>
  <card>
    <div class="row">
      <div class="col-md-12">
        <crud-notification
          :error-message="ErrorMessage"
          :success-message="SuccessMessage"
          :error-alert="ErrorAlert"
          :success-alert="SuccessAlert"
          @fadeMe="fadeMe"
          @enter="enter"
        />
      </div>
      <div class="col-12 text-center">
        <pulse-loader
          :loading="loading"
          :color="color"
          :size="size"
          v-if="loading"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <form>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="name">Resource Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="name">
                <div
                  class="error text-danger"
                  v-if="nameError">
                  <span v-if="!$v.name.required">Field is required</span>
                </div>
              </div>
              <div class="form-group">
                <label for="usedln">Select USE DLN</label>
                <select
                  class="form-control"
                  id="usedln"
                  v-model="usedIn">
                  <option/>
                  <option value="ALL">
                    ALL
                  </option>
                  <option value="AA">
                    AA
                  </option>
                  <option value="AB">
                    AB
                  </option>
                  <option value="AD">
                    AD
                  </option>
                  <option value="AE">
                    AE
                  </option>
                  <option value="BL">
                    BL
                  </option>
                  <option value="BPN">
                    BPN
                  </option>
                  <option value="BPY">
                    BPY
                  </option>
                  <option value="BR">
                    BR
                  </option>
                  <option value="BX">
                    BX
                  </option>
                  <option value="CH">
                    CH
                  </option>
                  <option value="CN">
                    CN
                  </option>
                  <option value="DPA">
                    DPA
                  </option>
                  <option value="EFP">
                    EFP
                  </option>
                  <option value="EYP">
                    EYP
                  </option>
                  <option value="FPN">
                    FPN
                  </option>
                  <option value="FPR">
                    FPR
                  </option>
                  <option value="FSU">
                    FSU
                  </option>
                  <option value="LAR">
                    LAR
                  </option>
                  <option value="LU">
                    LU
                  </option>
                  <option value="MPA">
                    MPA
                  </option>
                  <option value="PA">
                    PA
                  </option>
                  <option value="PBP">
                    PBP
                  </option>
                  <option value="PFP">
                    PFP
                  </option>
                  <option value="PL">
                    PL
                  </option>
                  <option value="PPA">
                    PPA
                  </option>
                  <option value="PST">
                    PST
                  </option>
                  <option value="RF">
                    RF
                  </option>
                  <option value="RG">
                    RG
                  </option>
                  <option value="RGF">
                    RGF
                  </option>
                  <option value="RO">
                    RO
                  </option>
                  <option value="RR">
                    RR
                  </option>
                  <option value="SCA">
                    SCA
                  </option>
                  <option value="SCB">
                    SCB
                  </option>
                  <option value="SCC">
                    SCC
                  </option>
                  <option value="SFA">
                    SFA
                  </option>
                  <option value="SPP">
                    SPP
                  </option>
                  <option value="STR">
                    STR
                  </option>
                  <option value="SW">
                    SW
                  </option>
                  <option value="TE">
                    TE
                  </option>
                  <option value="TP">
                    TP
                  </option>
                  <option value="TS">
                    TS
                  </option>
                  <option value="TSU">
                    TSU
                  </option>
                  <option value="UL">
                    UL
                  </option>
                </select>
                <div
                  class="error text-danger"
                  v-if="usedInError">
                  <span v-if="!$v.usedIn.required">Field is required</span>
                </div>
              </div>
              <div class="form-group">
                <label for="shared">Resource Shared</label>
                <select
                  class="form-control"
                  id="shared"
                  v-model="sharedByClerks">
                  <option/>
                  <option value="true">
                    Shared
                  </option>
                  <option value="false">
                    Not Shared
                  </option>
                </select>
                <div
                  class="error text-danger"
                  v-if="sharedByClerksError">
                  <span v-if="!$v.sharedByClerks.required">Field is required</span>
                </div>
              </div>
              <div class="form-group">
                <label for="may">May Shift Before Completion</label>
                <select
                  class="form-control"
                  id="may"
                  v-model="mayShiftShipBeforeCompletion">
                  <option/>
                  <option value="true">
                    May shift
                  </option>
                  <option value="false">
                    May Not Shift
                  </option>
                </select>
                <div
                  class="error text-danger"
                  v-if="mayShiftShipBeforeCompletionError">
                  <span v-if="!$v.mayShiftShipBeforeCompletion.required">Field is required</span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="serial">Serial Number</label>
            <input type="text" class="form-control" id="serial" v-model="serial">
          </div>
          <div class="form-group">
            <label for="may">May Shift Before Completion</label>
            <select
              class="form-control"
              id="may"
              v-model="mayShiftShipBeforeCompletion"
            >
              <option />
              <option value="true">
                May shift
              </option>
              <option value="false">
                May Not Shift
              </option>
            </select>
            <div
              class="error text-danger"
              v-if="mayShiftShipBeforeCompletionError"
            >
              <span v-if="!$v.mayShiftShipBeforeCompletion.required">Field is required</span>
            </div>
          </div>
          <div class="col-md-12 text-right">
            <button
              type="submit"
              class="btn btn-primary mr-3"
              @click.prevent="editResource">
              Edit
            </button>
            <button type="button" class="btn btn-fill btn-outline-info float-right" @click="goBack">
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
    <br />
  </card>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import resourceView from './resourceView'
  import { required } from 'vuelidate/lib/validators'
  import CrudNotification from '../../../components/DataTable/crudNotification';
  import TALLYAPI from "../../../api/tally";

  export default {
    name: 'Resource',

    components: {
      PulseLoader,
      resource_view: resourceView,
      CrudNotification
    },
    validations: {
      usedIn: { required },
      sharedByClerks: { required },
      mayShiftShipBeforeCompletion: { required },
      active: { required },
      name: { required }
    },

    created() {

      this.$store.dispatch('pagenames/currentPageName', 'Resources');
      this.getResourceById();

    },

    data() {

      return {

        usedIn: '',
        sharedByClerks: '',
        mayShiftShipBeforeCompletion: '',
        active: true,
        name: '',
        serial:'',

        usedInError: false,
        sharedByClerksError: false,
        mayShiftShipBeforeCompletionError: false,
        activeError: false,
        nameError: false,

        loading: false,
        color: 'green',
        size: '15px',

        ErrorMessage: '',
        SuccessAlert: false,
        ErrorAlert: false,
        SuccessMessage: '',

      }

    },

    methods: {

      getResourceById(){
        this.loading=true;
        TALLYAPI.getResourceById(this.$route.params.resourceId).then((response)=>{
          this.loading=false;
          this.active=response.data.data.active;
          this.mayShiftShipBeforeCompletion=response.data.data.mayShiftShipBeforeCompletion;
          this.name=response.data.data.name;
          this.sharedByClerks=response.data.data.sharedByClerks;
          this.usedIn=response.data.data.usedIn;
          console.log('response.data.data', response.data.data)
          this.serial = response.data.data.serial

        }).catch((error)=>{
          this.loading=false;
          this.ErrorMessage = 'Failed to Load Resource Contact System Administrator';
          this.SuccessAlert = false;
          this.ErrorAlert = true;
          this.SuccessMessage = ''
        })
      },

      editResource() {
        if (this.$v.name.required &&
          this.$v.usedIn.required &&
          this.$v.sharedByClerks &&
          this.$v.mayShiftShipBeforeCompletion &&
          this.$v.active
        ) {
          this.loading = true;

          TALLYAPI.editResource({
            active: this.active,
            mayShiftShipBeforeCompletion: this.mayShiftShipBeforeCompletion,
            name: this.name,
            sharedByClerks: this.sharedByClerks,
            usedIn: this.usedIn
          }).then((response)=>{

            this.loading=false;
            this.loading = false;
            this.ErrorMessage = '';
            this.SuccessAlert = true;
            this.ErrorAlert = false;
            this.SuccessMessage = 'Resource Edited Successfully'
          }).catch(()=>{
            this.loading=false;
            this.ErrorMessage = 'Failed to Edit Resource Contact System Administrator';
            this.SuccessAlert = false;
            this.ErrorAlert = true;
            this.SuccessMessage = ''
          });
        } else {
          this.usedInError = true
          this.sharedByClerksError = true
          this.mayShiftShipBeforeCompletionError = true
          this.activeError = true
          this.nameError = true
        }
      },

      goBack() {

        if (window.$cookies.isKey('browser-local-cashed-url-back')) {

          this.$router.push(window.$cookies.get('browser-local-cashed-url-back'));

          window.$cookies.remove('browser-local-cashed-url-back');

        }

      },

      fadeMe: function () {
        if (this.SuccessAlert) {
          this.SuccessAlert = !this.SuccessAlert;
        } else {
          this.ErrorAlert = !this.ErrorAlert;
        }
      },
      enter: function (el, done) {
        var that = this;

        setTimeout(function () {
          that.SuccessAlert = false;
          that.ErrorAlert = false;
        }, 3000); // hide the message after 3 seconds
      },
    },
  }
</script>

<style scoped>

</style>
