<template>
  <div>
    <card>
      <div class="row">
<!--        <modal-edit-plan v-if="refundModal">-->
<!--          <div slot="body">-->
<!--          </div>-->
<!--        </modal-edit-plan>-->
        <div class="col-md-12">
          <ul class="nav-justified" style="padding-left: 10px;padding-right: 10px">
<!--            <li v-if="finalUserPermissions.includes('DISBURSEMENT_REFUND_ADD_POST')">-->
<!--              <button @click.prevent="refundModal=!refundModal"-->
<!--                      class="btn btn-dark-blue-like btn-dark-blue-like-fill mr-2 custom-btn">-->
<!--                CREDIT/DEBIT PRINCIPAL ACCOUNT-->
<!--              </button>-->
<!--            </li>-->
            <li>
              <button
                type="submit"
                class="btn btn-primary btn-space mr-2 custom-btn float-right"
                @click.prevent="gotToAccount"
              > Principal Account
              </button>
            </li>
          </ul>

        </div>
        <div class="col-md-12" v-if="refundModal">
          <Transition name="fade">
            <div class="legend">
              <h6 class="legend-title">Principal Details</h6>
              <sub-disbersment-adhock :sub-disbursement-id="this.$route.params.principalId" v-on:closemodal="closeModal"></sub-disbersment-adhock>
            </div>
          </Transition>
        </div>

        <div class="col-md-12">
            <div class="legend">
              <h6 class="legend-title">Principal Details</h6>
              <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                  Account Number
                </div>
                <div class="col-md-2 div-table div-table-value">
                  {{ principalDetails.accountNo }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                  Principal Name
                </div>
                <div class="col-md-2 div-table div-table-value">
                  {{ principalDetails.name }}
                </div>
                <div class="col-md-2 div-table div-table-title">
                  Carrier Code
                </div>
                <div class="col-md-2 div-table div-table-value">
                  {{ principalDetails.carrierCode }}
                </div>
              </div>
              <br />
              <div class="row div-table-row">
                <div class="col-md-2 div-table div-table-title">
                  Address
                </div>
                <div class="col-md-10 div-table div-table-value">
                  {{ principalDetails.address }}
                </div>
              </div>
            </div>
        </div>
      </div>
    </card>
    <div>
      <transition
        name="col-md-12"
        v-if="showModal"
      >
        <div>
          <div
            class="modal  modal-mask"
            style="display: block"
          >
            <div
              class="modal-dialog medium-modal-dialog"
              role="document"
            >
              <div class="modal-content col-md-12">
                <div class="modal-header col-md-12">
                  <div class="modal-title col-md-12">
                    <div class="row">
                      <div class="col-md-12">
                        <b> {{ this.modal_title }}</b>
                      </div>

                      <div class="col-md-12">
                        <hr>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!--  Form  -->
                  <div class="modal-body col-md-12" >
                    <card>
                      <!--      ADD FORM      -->
                      <form
                        v-if="AddModalDiv || EditModalDiv"
                      >
                        <div id="add-view">

                        <ul class="nav-justified">
                        <li>
                          <a @click.prevent="showStart = !showStart "  :class="[showStart ? 'is-active-main-details': '']">Vessel Details</a>
                        </li>
                          <li>
                            <a @click.prevent="showStart = !showStart " :class="[!showStart? 'is-active-additional-details': '']">Additional Vessel Particulars</a>
                          </li>
                        </ul>
                        <div v-if="showStart">
                          <!--  row 1 -->
                          <div class="row">
                            <div class="col-md-4">
                              <base-input
                                type="text"
                                label="Vessel Name"
                                placeholder="Vessel Name"
                                v-model="vesselDetails.vesselName"
                                :value="vesselDetails.vesselName"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.vesselName.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-4">
                              <base-input
                                type="text"
                                label="Vessel Voyage No."
                                placeholder="Vessel Voyage No."
                                v-model="vesselDetails.voyageNo"
                                :value="vesselDetails.voyageNo"
                                class="special-bottom"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.voyageNo.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-2">
                              <label>Vessel Type</label>
                              <model-list-select
                                :list="vesselTypeEnum"
                                v-model="vesselDetails.vesselType"
                                class="form-control"
                                option-value="vesselType"
                                option-text="vesselType"
                                placeholder="Vessel Type"
                                :is-disabled="!hasSaveAction"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.vesselType.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Vessel Carrier Name"
                                placeholder="Vessel Carrier Name"
                                v-model="vesselDetails.carrierName"
                                :value="vesselDetails.carrierName"
                                class="special-bottom"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.carrierName.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>
                          </div>
                          <!--   row 1.5-->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Vessel Carrier Code"
                                placeholder="Vessel Carrier Code (TASAC)"
                                v-model="vesselDetails.carrierCode"
                                :value="vesselDetails.carrierCode"
                                class="special-bottom"
                                :readonly="true"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.carrierCode.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>

                            <div class="col-md-2">
                              <label>Contract Type</label>
                              <model-list-select
                                :list="contractTypeEnum"
                                v-model="vesselDetails.contractType"
                                class="form-control"
                                option-value="contractType"
                                option-text="contractType"
                                placeholder="Contract Type"
                                required="true"
                                :is-disabled="!hasSaveAction"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.contractType.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Vessel Master Name"
                                placeholder="Vessel Master Name"
                                v-model="vesselDetails.vesselMaster"
                                :value="vesselDetails.vesselMaster"
                                class="special-bottom"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.vesselMaster.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>
                            <div class="col-md-6">
                              <base-input
                                type="text"
                                label="Vessel Master Address"
                                placeholder="Vessel Master Address"
                                v-model="vesselDetails.vesselMasterAddress"
                                :value="vesselDetails.vesselMasterAddress"
                                class="special-bottom"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.vesselMasterAddress.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>

                          </div>

                          <!--  row 2 -->
                          <div class="row">

                            <div class="col-md-3">
                              <base-input
                                type="text"
                                label="Transport Means Name"
                                placeholder="Transport Means Name"
                                v-model="vesselDetails.transportMeansName"
                                :value="vesselDetails.transportMeansName"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Port of Registry"
                                placeholder="Port of Registry"
                                v-model="vesselDetails.transportMeansId"
                                :value="vesselDetails.transportMeansId"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Flag"
                                placeholder="Flag"
                                v-model="vesselDetails.transportMeansNationality"
                                :value="vesselDetails.transportMeansNationality"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Mode of Transport Coded"
                                placeholder="Mode of Transport Coded"
                                v-model="vesselDetails.modeOfTransportCoded"
                                :value="vesselDetails.modeOfTransportCoded"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>

<!--                            <div class="col-md-2">-->
<!--                              <base-input-->
<!--                                type="text"-->
<!--                                label="Transport Stage Type"-->
<!--                                placeholder="Transport Stage Type"-->
<!--                                v-model="vesselDetails.transportStageType"-->
<!--                                :value="vesselDetails.transportStageType"-->
<!--                                class="special-bottom"-->
<!--                                :readonly="!hasSaveAction"-->
<!--                              />-->
<!--                            </div>-->

                          </div>

                          <!--   row 2.5 -->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Port of Loading"
                                placeholder="Port of Loading"
                                v-model="vesselDetails.arrivalPort"
                                :value="vesselDetails.arrivalPort"
                                class="special-bottom"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.arrivalPort.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Port of Discharge"
                                placeholder="Port of Discharge"
                                v-model="vesselDetails.destinationPort"
                                :value="vesselDetails.destinationPort"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Next Port"
                                placeholder="Next Port"
                                v-model="vesselDetails.nextPort"
                                :value="vesselDetails.nextPort"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Port of Call"
                                placeholder="Port of Call"
                                v-model="vesselDetails.portOfCall"
                                :value="vesselDetails.portOfCall"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Terminal"
                                placeholder="Terminal"
                                v-model="vesselDetails.terminal"
                                :value="vesselDetails.terminal"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Terminal Operator Code"
                                placeholder="Terminal Operator Code"
                                v-model="vesselDetails.terminalOperatorCode"
                                :value="vesselDetails.terminalOperatorCode"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                          </div>

                          <!--  row 3 -->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Control Reference Number"
                                placeholder="Control Reference Number"
                                v-model="vesselDetails.controlReferenceNumber"
                                :value="vesselDetails.controlReferenceNumber"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Custom Office Code"
                                placeholder="Custom Office Code"
                                v-model="vesselDetails.customOfficeCode"
                                :value="vesselDetails.customOfficeCode"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>

                            <div class="col-md-2">
                              <base-input
                                type="datetime-local"
                                label="Vessel ETA"
                                placeholder="Vessel ETA"
                                v-model="vesselDetails.eta"
                                :value="vesselDetails.eta"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.eta.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="datetime-local"
                                label="Vessel ETB"
                                placeholder="Vessel ETB"
                                v-model="vesselDetails.etb"
                                :value="vesselDetails.etb"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.etb.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="datetime-local"
                                label="Vessel ETD"
                                placeholder="Vessel ETD"
                                v-model="vesselDetails.etd"
                                :value="vesselDetails.etd"
                                class="special-bottom"
                                :onchange="getExpectedStayTime"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.etd.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Mode of Transport"
                                placeholder="Mode of Transport"
                                v-model="vesselDetails.modeOfTransport"
                                :value="vesselDetails.modeOfTransport"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.etd.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>
                          </div>

                          <!-- row 3.5  -->
                          <div class="row">

                            <div class="col-md-8">
                              <base-input
                                type="text"
                                label="Vessel Operations"
                                placeholder="Vessel Operations"
                                v-model="vesselDetails.operation"
                                :value="vesselDetails.operation"
                                class="special-bottom"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.operation.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                label="Expected Stay Time (Hrs)"
                                placeholder="Expected Stay Time (Hrs)"
                                v-model="vesselDetails.stayTime"
                                :value="vesselDetails.stayTime"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.stayTime.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>

                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Vessel Draft (Metres)"
                                placeholder="Vessel Draft (Metres)"
                                v-model="vesselDetails.draft"
                                :value="vesselDetails.draft"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.draft.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>

                          </div>

                          <!--  row 4 -->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Vessel Dead Weight Tonnage (DWT)"
                                placeholder="Vessel Dead Weight Tonnage (DWT)"
                                v-model="vesselDetails.dwt"
                                :value="vesselDetails.dwt"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.dwt.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Vessel Gross Weight Tonnage"
                                placeholder="Vessel Gross Weight Tonnage"
                                v-model="vesselDetails.grt"
                                :value="vesselDetails.grt"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                                :onchange="this.vesselDetails.factor = Math.ceil(this.vesselDetails.grt/100)"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.grt.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Net Weight Tonnage"
                                placeholder="Net Weight Tonnage"
                                v-model="vesselDetails.nrt"
                                :value="vesselDetails.nrt"
                                class="special-bottom"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.nrt.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Length Overall (LOA) (Metres)"
                                placeholder="Length Overall (LOA) (Metres)"
                                v-model="vesselDetails.loa"
                                :value="vesselDetails.loa"
                                :readonly="!hasSaveAction"
                                class="special-bottom"
                              />
                              <div
                                class="error"
                                v-if="!$v.vesselDetails.loa.required && isTested"
                              >
                                This field is required
                              </div>
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Vessel Factor"
                                placeholder="Vessel Factor"
                                v-model="vesselDetails.factor"
                                :value="vesselDetails.factor"
                                :readonly="true"
                                class="special-bottom"
                              />
<!--                              <div-->
<!--                                class="error"-->
<!--                                v-if="!$v.vesselDetails.factor.required && isTested"-->
<!--                              >-->
<!--                                This field is required-->
<!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Vessel Beam (Metres)"
                                placeholder="Vessel Beam (Metres)"
                                v-model="vesselDetails.beam"
                                :value="vesselDetails.beam"
                                class="special-bottom"
                                :readonly="!hasSaveAction"

                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.beam.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>

                          </div>


                        </div>



                        <div v-if="!showStart">


                          <!--  row 6 -->
                          <div class="row">

                            <div class="col-md-3">
                              <base-input
                                type="datetime-local"
                                label="Vessel ATA"
                                placeholder="Vessel ATA"
                                v-model="vesselDetails.ata"
                                :value="vesselDetails.ata"
                                class="special-bottom"
                                :readonly="!hasSaveAction"

                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.ata.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-3">
                              <base-input
                                type="datetime-local"
                                label="Vessel ATAOA"
                                placeholder="Vessel ATAOA"
                                v-model="vesselDetails.ataoa"
                                :value="vesselDetails.ataoa"
                                class="special-bottom"
                                :readonly="!hasSaveAction"

                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.ata.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-3">
                              <base-input
                                type="datetime-local"
                                label="Vessel ATB"
                                placeholder="Vessel ATB"
                                v-model="vesselDetails.atb"
                                :value="vesselDetails.atb"
                                class="special-bottom"
                                :readonly="!hasSaveAction"

                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.ata.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-3">
                              <base-input
                                type="datetime-local"
                                label="Vessel ATC"
                                placeholder="Vessel ATC"
                                v-model="vesselDetails.atc"
                                :value="vesselDetails.atc"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                          </div>

                          <!--  row 7 -->
                          <div class="row">
                            <div class="col-md-3">
                              <base-input
                                type="datetime-local"
                                label="Vessel ATD"
                                placeholder="Vessel ATD"
                                v-model="vesselDetails.atd"
                                :value="vesselDetails.atd"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-3">
                              <base-input
                                type="datetime-local"
                                label="Vessel ATDOA"
                                placeholder="Vessel ATDOA"
                                v-model="vesselDetails.atdoa"
                                :value="vesselDetails.atdoa"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-3">
                              <base-input
                                type="datetime-local"
                                label="Pilot In"
                                placeholder="Pilot In"
                                v-model="vesselDetails.pilotIn"
                                :value="vesselDetails.pilotIn"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.atd.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-3">
                              <base-input
                                type="text"
                                label="Vessel Berth"
                                placeholder="Vessel Berth"
                                v-model="vesselDetails.berth"
                                :value="vesselDetails.berth"
                                class="special-bottom"
                                oninput="let p=this.selectionStart;this.value=this.value.toUpperCase();this.setSelectionRange(p, p);"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                          </div>

                          <!-- ballast & draft additional 1-->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Berth No."
                                placeholder="Berth No."
                                v-model="vesselDetails.berthNo"
                                :value="vesselDetails.berthNo"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                  <div-->
                              <!--                    class="error"-->
                              <!--                    v-if="!$v.vesselDetails.berth.required && isTested"-->
                              <!--                  >-->
                              <!--                    This field is required-->
                              <!--                  </div>-->
                            </div>
                            <div class="col-md-2" style="align-items: center;justify-items: center;display: grid;">
                              <div class="custom-control custom-checkbox custom-control-inline">
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  id="sun"
                                  v-model="vesselDetails.ballast"
                                  :readonly="!hasSaveAction"
                                >
                                <label
                                  class="custom-control-label"
                                  for="sun"
                                >Ballast</label>
                              </div>
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Vessel Draft After "
                                placeholder="Vessel Draft After "
                                v-model="vesselDetails.draftAfter"
                                :value="vesselDetails.draftAfter"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Vessel Draft After Units"
                                placeholder="Vessel Draft After Units"
                                v-model="vesselDetails.draftAfterUnit"
                                :value="vesselDetails.draftAfterUnit"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="0.0001"
                                label="Vessel Draft Fore "
                                placeholder="Vessel Draft Fore "
                                v-model="vesselDetails.draftFore"
                                :value="vesselDetails.draftFore"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="text"
                                label="Vessel Draft Fore Units "
                                placeholder="Vessel Draft Fore Units "
                                v-model="vesselDetails.draftForeUnit"
                                :value="vesselDetails.draftForeUnit"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                              <!--                              <div-->
                              <!--                                class="error"-->
                              <!--                                v-if="!$v.vesselDetails.draft.required && isTested"-->
                              <!--                              >-->
                              <!--                                This field is required-->
                              <!--                              </div>-->
                            </div>
                          </div>

                          <!-- CN Quantity-->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Container Quantity Loaded"
                                placeholder="Container Quantity Loaded"
                                v-model="vesselDetails.cnQuantityLoaded"
                                :value="vesselDetails.cnQuantityLoaded"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Container Weight Loaded"
                                placeholder="Container Weight Loaded"
                                v-model="vesselDetails.cnWeightLoaded"
                                :value="vesselDetails.cnWeightLoaded"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Container Quantity At Destination"
                                placeholder="Container Quantity At Destination"
                                v-model="vesselDetails.cnQuantityAtDestination"
                                :value="vesselDetails.cnQuantityAtDestination"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Container Weight At Destination"
                                placeholder="Container Weight At Destination"
                                v-model="vesselDetails.cnWeightAtDestination"
                                :value="vesselDetails.cnWeightAtDestination"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Container Quantity At Discharge"
                                placeholder="Container Quantity At Discharge"
                                v-model="vesselDetails.cnQuantityAtDischarge"
                                :value="vesselDetails.cnQuantityAtDischarge"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Carton Weight At Discharge"
                                placeholder="Carton Weight At Discharge"
                                v-model="vesselDetails.cnWeightAtDischarge"
                                :value="vesselDetails.cnWeightAtDischarge"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                          </div>

                          <!-- CAR Quantity  -->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Car Quantity Loaded"
                                placeholder="Car Quantity Loaded"
                                v-model="vesselDetails.carQuantityLoaded"
                                :value="vesselDetails.carQuantityLoaded"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Car Weight Loaded"
                                placeholder="Car Weight Loaded"
                                v-model="vesselDetails.carWeightLoaded"
                                :value="vesselDetails.carWeightLoaded"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Car Quantity At Destination"
                                placeholder="Car Quantity At Destination"
                                v-model="vesselDetails.carQuantityAtDestination"
                                :value="vesselDetails.carQuantityAtDestination"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Car Weight At Destination"
                                placeholder="Car Weight At Destination"
                                v-model="vesselDetails.carWeightAtDestination"
                                :value="vesselDetails.carWeightAtDestination"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Car Quantity At Discharge"
                                placeholder="Car Quantity At Discharge"
                                v-model="vesselDetails.carQuantityAtDischarge"
                                :value="vesselDetails.carQuantityAtDischarge"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Car Weight At Discharge"
                                placeholder="Car Weight At Discharge"
                                v-model="vesselDetails.carWeightAtDischarge"
                                :value="vesselDetails.carWeightAtDischarge"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                          </div>

                          <!-- BK Quantity  -->
                          <div class="row">
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Bulk Quantity Loaded"
                                placeholder="Bulk Quantity Loaded"
                                v-model="vesselDetails.bkQuantityLoaded"
                                :value="vesselDetails.bkQuantityLoaded"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Bulk Weight Loaded"
                                placeholder="Bulk Weight Loaded"
                                v-model="vesselDetails.bkWeightLoaded"
                                :value="vesselDetails.bkWeightLoaded"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Bulk Quantity At Destination"
                                placeholder="Bulk Quantity At Destination"
                                v-model="vesselDetails.bkQuantityAtDestination"
                                :value="vesselDetails.bkQuantityAtDestination"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Bulk Weight At Destination"
                                placeholder="Bulk Weight At Destination"
                                v-model="vesselDetails.bkWeightAtDestination"
                                :value="vesselDetails.bkWeightAtDestination"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Bulk Quantity At Discharge"
                                placeholder="Bulk Quantity At Discharge"
                                v-model="vesselDetails.bkQuantityAtDischarge"
                                :value="vesselDetails.bkQuantityAtDischarge"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                            <div class="col-md-2">
                              <base-input
                                type="number"
                                step="1"
                                label="Bulk Weight At Discharge"
                                placeholder="Bulk Weight At Discharge"
                                v-model="vesselDetails.bkWeightAtDischarge"
                                :value="vesselDetails.bkWeightAtDischarge"
                                class="special-bottom"
                                :readonly="!hasSaveAction"
                              />
                            </div>
                          </div>
                        </div>
                        </div>

                        <div class="text-center row">
                          <div class="col-md-7 text-right loader-div">
                            <span v-if="editMyLoader">Please wait ....</span>
                          </div>
                          <div class="col-md-2 left loader-div-gif">
                            <pulse-loader
                              :loading="loading"
                              :color="color"
                              :size="size"
                              v-if="editMyLoader"
                            />
                          </div>
                          <div
                            class="col-md-3"
                            v-if="AddModalDiv || EditModalDiv"
                          >
                            <button
                              type="button"
                              class="btn btn-fill btn-outline-info float-right "
                              style="margin-inline-start:20px"
                              @click="closeModal()"
                            >
                              Close
                            </button>
                            <button
                              type="submit"
                              class="btn btn-primary btn-space  float-right"
                              data-dismiss="modal"
                              @click.prevent="saveFormDetails"
                              v-if="AddModalDiv && hasSaveAction"
                            >
                              Save
                            </button>
                            <button
                              type="submit"
                              class="btn btn-primary btn-space  float-right"
                              data-dismiss="modal"
                              v-if="EditModalDiv && hasSaveAction"
                              @click.prevent="editSaveFormDetails"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </card>
                  </div>

                  <!--   View Table  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
  import PulseLoader from 'vue-spinner/src/PulseLoader'
  import GlobalModal from '@/components/globalLoader'
  import DataTable from '@/components/DataTable/dataTable'
  import Modal from '@/components/Inputs/Modal'
  import {mapActions, mapGetters} from 'vuex'
  import {required} from 'vuelidate/lib/validators'
  import {ModelListSelect} from 'vue-search-select'
  import CrudNotification from '../../../components/DataTable/crudNotification'
  import ModalEditPlan from "../../../components/Inputs/ModalEditPlan";
  import subDisbersmentAdhock from "../disbursement/sub-disbursment-account/subDisbersmentAdhock";


  export default {
    name: 'PrincipalItems',
    components: {
      PulseLoader,
      GlobalModal,
      DataTable,
      Modal,
      ModelListSelect,
      CrudNotification,
      ModalEditPlan,
      subDisbersmentAdhock,

    },
    created () {
      this.$store.dispatch('pagenames/currentPageName', 'Principal Particulars')
      this.principalId = this.$route.params.principalId
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
      this.loadPageablePrincipalItemsOnCreated()
    },
    computed: {

      getExpectedStayTime () {
        if (this.vesselDetails.etb && this.vesselDetails.etd){
          let etaCalc = new Date (this.vesselDetails.etb);
          let etdCalc = new Date (this.vesselDetails.etd);

          let expectedStayTimeMS = Math.abs(etdCalc.getTime() -  etaCalc.getTime());
          // convert milliseconds to hrs then round to integer
          let expectedStayTimeHrs = Math.ceil(expectedStayTimeMS/(1000*60*60));

          this.vesselDetails.stayTime = expectedStayTimeHrs
          return expectedStayTimeHrs
        }else{
          this.vesselDetails.stayTime = 0
          return 0
        }

      },

      ...mapGetters('notifications', [

        'getNewAppPage'

      ]),
      ...mapGetters('principal', [

        'getRequestList',
        'getPageCount',
        'getMyFirstLoader',
        'getNoDataFound',
        'getReloadAllRequest',
        'getMyLoader',
        'getSearchParameter',
        'getHasSearchedVariable',
        'getPageNo',
        'getRequestId'
      ]),
      ...mapGetters('vesselParticulars', [
        'getSaveDetails',
        'getSuccessAlert',
        'getErrorMessage',
        'getErrorAlert',
        'getSuccessMessage',
        'getReloadAllRequest',
        'getMyLoader',
        'getSearchParameter',
        'getHasSearchedVariable',
        'getPageNo'
      ])
    },
    validations: {
      vesselDetails: {

        // ata: { required },
        // atd: { required },
        // beam: { required }, // removed from UAT 2.
        // berth: { required },
        // carrierCode: { required },
        // contractType: { required },
        // draft: { required },
        // dwt: { required },
        // factor: { required },
        // grt: { required },
        // nrt: { required },
        // principalParticularId: { required },
        // stayTime: { required },
        // vesselType: { required },

        loa: { required },
        operation: { required },
        etd: { required },
        etb: { required }, // new field from UAT 3.
        eta: { required },
        arrivalPort: { required },
        voyageNo: { required },
        vesselName: { required },


      }

    },
    data () {
      return {
        refundModal:false,
        vesselTypeEnum: [
          { vesselType: 'Dry Bulk' },
          { vesselType: 'Pure car Carrier' },
          // Dry Bulk, Pure car Carrier, Tanker, Military, Cruise, Exhibition, Other
          { vesselType: 'Tanker' },
          { vesselType: 'Military' },
          { vesselType: 'Cruise' },
          { vesselType: 'Exhibition' },
          { vesselType: 'Other' }

        ],
        // Charter, Liner, Other
        contractTypeEnum: [
          { contractType: 'Charter' },
          { contractType: 'Liner' },
          { contractType: 'Other' }
        ],

        vesselDetailsOld: {

          arrivalPort: '',
          ata: '',
          atb: '',
          atc: '',
          atd: '',
          beam: '',
          berth: '',
          carrierCode: '',
          contractType: '',
          // Enum:
          //   [ Charter, Liner, Other ]
          draft: '',
          dwt: 0,
          eta: '',
          etd: '',
          etb: '',
          factor: '',
          grt: 0,
          loa: '',
          nrt: 0,
          principalParticularId: '',
          stayTime: '',
          operation: '',
          pilotIn: '',
          vesselName: '',
          vesselParticularId: '',
          vesselType: '',
          // Enum:
          //   [ Dry Bulk, Pure car Carrier, Tanker, Military, Cruise, Exhibition, Other ]
          voyageNo: ''

        },
      vesselDetails: {
        arrivalPort: '', //
        ata: '', //
        ataoa: '', //
        atb: '', //
        atc: '', //
        atd: '', //
        atdoa: '', //
        ballast: true, //
        beam: 0, //
        berth: '', //
        berthNo: '', //

        bkQuantityAtDestination: 0, //
        bkQuantityAtDischarge: 0, //
        bkQuantityLoaded: 0, //
        bkWeightAtDestination: 0, //
        bkWeightAtDischarge: 0, //
        bkWeightLoaded: 0, //

        carQuantityAtDestination: 0, //
        carQuantityAtDischarge: 0, //
        carQuantityLoaded: 0, //
        carWeightAtDestination: 0, //
        carWeightAtDischarge: 0, //
        carWeightLoaded: 0, //

        carrierCode: '', //
        carrierName: '', //
        // new
        cnQuantityAtDestination: 0, //
        cnQuantityAtDischarge: 0, //
        cnQuantityLoaded: 0, //
        cnWeightAtDestination: 0, //
        cnWeightAtDischarge: 0, //
        cnWeightLoaded: 0, //
//
        contractType: 'Charter', //
        controlReferenceNumber: '', //
        customOfficeCode: '', //
        destinationPort: '', //
        //
        draft: 0, //
        draftAfter: 0, //
        draftAfterUnit: '', //
        draftFore: 0, //
        draftForeUnit: '', //
        //
        dwt: 0, //
        eta: '', //
        etb: '', //
        etd: '', //
        factor: 0, //
        grt: 0, //
        loa: '', //
        modeOfTransport:'', //
        modeOfTransportCoded: '', //
        nextPort: '', //
        nrt: 0, //
        operation: '', //
        pilotIn: '', //
        portOfCall: '', //
        principalParticularId: 0,
        status: 'Created',
        stayTime: 0,

        terminal: '', //
        terminalOperatorCode: '', //

        transportMeansId: '', //
        transportMeansName: '', //
        transportMeansNationality: '', //
        transportStageType: '', //

        vesselMaster: '',  //
        vesselMasterAddress: '', //
        vesselName: '', //
        vesselParticularId: 0, //
        vesselType: 'Dry Bulk', //
        voyageNo: '', //
      },



        // rate: '',
        // description: '',
        // name: '',
        // serviceItemId: '',
        // serviceCategoryId: '',
        showStart: true,
        AddModalDiv: false,
        EditModalDiv: false,
        showModal: false,
        editMyLoader: false,
        modal_title: '',
        principalId: '',
        pageNo: 0,
        pageSize: 15,
        pageCount: 1,
        isFirst: true,
        isLast: false,
        totalElements: '',
        pageNumber: 0,
        totalPages: 1,
        sortBy: 'createdAt',
        hasAction: false,
        color: 'green',
        size: '12px',
        loading: true,
        editable: true,
        searchParameter: '',
        hasSearchedVariable: false,
        reloadAllRequest: false,
        noDataFound: false,
        pdfFormatError: false,
        loadAllHeaderLoader: false,
        allAttachmentVerified: false,
        myLoader: false,
        myFirstLoader: false,
        status: 'PRINCIPAL_VESSEL',
        isSearchable: false,
        requests: [],
        requestFlag: 'CONFIG',
        principalDetails: {},
        hasSaveAction: true,
        SuccessAlert: false,
        ErrorAlert: false,

        isTested:false,

        SuccessMessage: '',
        ErrorMessage: '',

        isHovered: [],
        saveDetails: {},
        ifThereIsParam: '',
        editDetails: false,
        finalUserPermissions: [],
        tableHeaders: ['Vessel Name', 'Vessel Type', 'Voyage No', 'DWT', 'ETA', 'Stay-Time (Hrs)'],
        tableDataKeys: ['vesselName', 'vesselType', 'voyageNo', 'dwt', 'eta', 'stayTime'],

      }
    },
    watch :{
      expectedStayTime () {
        let etaCalc = new Date (this.vesselDetails.eta);
        let etdCalc = new Date (this.vesselDetails.etd);

        let expectedStayTimeMS = Math.abs(etdCalc.getTime() -  etaCalc.getTime());
        let expectedStayTimeHrs = Math.ceil(expectedStayTimeMS/(1000*60*60));
        this.vesselDetails.stayTime = expectedStayTimeHrs
        return expectedStayTimeHrs
      }
    },
    methods: {

      ...mapActions('principal', [
        'loadPrincipalsParticularsById'
      ]),

      ...mapActions('vesselParticulars', [
        'loadVesselParticularsPageableOnCreated',
        'addVesselParticulars',
        'updateVesselParticulars'

      ]),

      loadParameters () {

        return new Promise((resolve) => {

          this.$store.dispatch('principal/pageSizing', this.pageSize)
          this.$store.dispatch('principal/pageNumbering', this.pageNo)
          this.$store.dispatch('principal/requestid', this.principalId)
          resolve()

        })
      },

      loadSaveDetailsParameters () {

        return new Promise((resolve) => {

          this.$store.dispatch('vesselParticulars/savingDetails', this.saveDetails)

          resolve()
        })
      },


      gotToAccount(){
        this.$router.push({name:"PrincipalAccount",params:{principalId:this.$route.params.principalId}})
      },

      loadPageablePrincipalItemsOnCreated () {

        this.loadParameters().then(() => {

          this.loadPrincipalsParticularsById({}).then(() => {

            this.pageCount = this.getPageCount
            this.myFirstLoader = this.getMyFirstLoader
            this.noDataFound = this.getNoDataFound
            // this.pageNo = this.getPageNo;

            this.principalDetails = this.getRequestList
            if (this.principalDetails.vesselParticulars === null) {
                this.noDataFound = true
            } else {
                this.requests = this.principalDetails.vesselParticulars
            }

          }).catch(() => {})

          this.myFirstLoader = this.getMyFirstLoader

        })

      },

      savePrincipalItems () {
        let tempVessel = this.saveDetails
        this.loadSaveDetailsParameters().then(() => {
          this.addVesselParticulars({}).then(() => {
            this.myFirstLoader = this.getMyFirstLoader
            this.noDataFound = this.getNoDataFound
            this.ErrorAlert = this.getErrorAlert
            this.SuccessAlert = this.getSuccessAlert
            if  (this.SuccessAlert){
              this.notifyUser('success', 'Vessel Added Successfully!', `Marine vessel ${tempVessel.vesselName} has been added successfully.` )
              this.SuccessAlert = false;
            }
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
            this.loadPrincipalsParticularsById({}).then(() => {
              this.pageCount = this.getPageCount
              this.myFirstLoader = this.getMyFirstLoader
              this.noDataFound = this.getNoDataFound
              // this.pageNo = this.getPageNo;

              this.principalDetails = this.getRequestList
              if (this.principalDetails.vesselParticulars === null) { this.noDataFound = true } else { this.requests = this.principalDetails.vesselParticulars }

            }).catch(() => {
              this.ErrorAlert = this.getErrorAlert
              if (this.ErrorAlert){
                this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
                this.ErrorAlert = false;
              }
            })
          }).catch(() => {
            this.myFirstLoader = this.getMyFirstLoader
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
          })
          this.myFirstLoader = this.getMyFirstLoader
        })
      },
      editPrincipalItems () {
        let tempVessel = this.saveDetails

        this.loadSaveDetailsParameters().then(() => {
          this.updateVesselParticulars({}).then(() => {
            // this.requests = this.getRequestList;
            // this.pageCount = this.getPageCount;
            this.myFirstLoader = this.getMyFirstLoader
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }

            this.SuccessAlert = this.getSuccessAlert
            if  (this.SuccessAlert){
              this.notifyUser('success', 'Vessel Edited Successfully!', `Marine vessel ${tempVessel.vesselName} has been edited successfully.` )
              this.SuccessAlert = false;
            }
            this.loadPageablePrincipalItemsOnCreated();
          }).catch(() => {
            this.ErrorAlert = this.getErrorAlert
            if (this.ErrorAlert){
              this.notifyUser('error', 'An Error Occurred', 'Sorry, something went wrong')
              this.ErrorAlert = false;
            }
          })
          this.myFirstLoader = this.getMyFirstLoader
        })
      },

      editItemsDetails (details) {
        this.vesselDetails = JSON.parse(JSON.stringify(details))
        this.$v.vesselDetails.$touch()
        this.openModal('EDIT')
      },
      viewFormDetails (details) {
        this.vesselDetails = JSON.parse(JSON.stringify(details))
        this.openModal('VIEW')
      },
      openModal (modalType) {
        this.vesselDetails.carrierCode = 'TASAC'
        this.$v.vesselDetails.$touch()

        if (modalType === 'ADD') {
          this.modal_title = 'ADD VESSEL DETAILS'
          this.EditModalDiv = false
          this.hasSaveAction = true
          this.vesselDetails.carrierCode = 'TASAC'
          this.AddModalDiv = true
          this.showModal = true
          this.isTested = false
        }
        if (modalType === 'EDIT') {
          this.modal_title = 'EDIT VESSEL DETAILS'
          this.EditModalDiv = true
          this.hasSaveAction = true
          this.isTested = false

          this.AddModalDiv = false
          this.showModal = true
        }
        if (modalType === 'VIEW') {
          this.modal_title = 'VESSEL DETAILS'
          this.EditModalDiv = true

          this.hasSaveAction = false
          this.isTested = false
          this.AddModalDiv = false
          this.showModal = true
        }
      },
      closeModal () {
        this.showModal = false
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.UploadModalDiv = false
        this.hasSaveAction = false
        this.vesselDetails = {}
        this.isTested = false
        this.showStart = true;
        this.refundModal=false;

      },
      cleanValues () {
        this.vesselDetails.stayTime = parseInt(this.vesselDetails.stayTime, 10)
        this.vesselDetails.principalParticularId = parseInt(this.vesselDetails.principalParticularId, 10)

        this.vesselDetails.factor = parseFloat(this.vesselDetails.factor)
        this.vesselDetails.grt	= parseFloat(this.vesselDetails.grt)
        this.vesselDetails.loa	= parseFloat(this.vesselDetails.loa)
        this.vesselDetails.nrt	= parseFloat(this.vesselDetails.nrt)
        this.vesselDetails.beam = parseFloat(this.vesselDetails.beam)
        this.vesselDetails.draft = parseFloat(this.vesselDetails.draft)
        this.vesselDetails.dwt = parseFloat(this.vesselDetails.dwt)
      },
      saveFormDetails () {
        this.vesselDetails.principalParticularId = this.principalId
        // delete (this.vesselDetails.vesselParticularId);
        this.vesselDetails.vesselParticularId = null
        // console.log(this.vesselDetails)
        this.$v.vesselDetails.$touch()
        this.isTested = true;

        if (this.$v.vesselDetails.$invalid) {
          // this.submitStatus = 'ERROR'
          // // console.log(this.submitStatus)
        } else {
          this.cleanValues()
          this.saveDetails = this.vesselDetails
          this.savePrincipalItems()

          this.vesselDetails = {}
          this.saveDetails = {}
          this.closeModal()
        }
      },
      editSaveFormDetails () {
        this.$v.vesselDetails.$touch()
        this.isTested = true;

        this.$v.vesselDetails.$touch()
        if (this.$v.vesselDetails.$invalid) {
          this.submitStatus = 'ERROR'
          console.log(this.$v.vesselDetails)
          console.log(this.submitStatus)
        } else {
          this.cleanValues()
          this.submitStatus = 'PENDING'
          this.saveDetails = this.vesselDetails;

          this.editPrincipalItems()
          this.vesselDetails = {}
          this.saveDetails = {}
          this.closeModal()
        }
      },
      // fadeMe: function () {
      //   if (this.SuccessAlert) {
      //     this.SuccessAlert = !this.SuccessAlert
      //   } else {
      //     this.ErrorAlert = !this.ErrorAlert
      //   }
      // },
      // onChange () {},
      enter: function (el, done) {
        var that = this

        setTimeout(function () {
          that.SuccessAlert = false
          that.ErrorAlert = false
        }, 2000) // hide the message after 2 seconds
      },
      goBack () {
        history.back()
      },
      goToVesselDetails (vesselDetails) {
        document.cookie = 'browser-local-cashed-url-back=' + this.$route.fullPath + ';Path = /;SameSite = Strict'
        this.$router.push('/shipping-agency/vessel-operations/' + vesselDetails.vesselParticularId)
      }

    }

  }
</script>

<style scoped>
  ul.nav-justified {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    /*background-color: #ededee;*/
    border-radius: 3px;
  }

  ul.nav-justified li {
    float: left;
    border-bottom: none !important;
    margin-right: 15px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  ul.nav-justified li a {
    display: block;
    text-align: center;
    padding: 8px 15px 8px 15px !important;
    width: auto;
    text-decoration: none;
    color: #000000;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul.nav-justified li :hover {
    color: #d59a18;
    padding: 16px 20px 10px 21px;
    background-color: #ffffff;
    cursor: pointer;
  }

  .tab-content {
    border-top: 1px solid #DCDCDC;
    border-radius: 5px;
  }

  .is-active-main-details, .is-active-main-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: #3498db !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .is-active-additional-details, .is-active-additional-details :hover {
    color: #ffffff !important;
    padding: 0 0 2px 0;
    background-color: green !important;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .modal-mask {
    position: fixed;
    z-index: 4910;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: table;
    transition: opacity .3s ease;
    overflow-y: auto;
  }

  .medium-modal-dialog {
    /*width: calc(80vw);*/
    /*min-width: calc(60vw);*/
    max-width: calc(90vw) !important;
  }

  .legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 3px;
    margin-top: 1rem;
  }

  .legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
  }

  .btn-space {
    margin-right: 10px !important;
  }

  .custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
  }

  .error{
    color:#dc3545;
    margin-left: 2px;
    margin-top: 2px;
  }
  .loader-div {
    line-height: 40px;
  }

  .loader-div-gif {
    line-height: 50px;
  }
  .div-table-row {
    padding: 0 15px 0 15px;
  }

  .div-table {
    padding: 9px 8px 9px 8px;
  }

  .div-table-title {
    width: 250px;
    background-color: rgba(153, 186, 221, .5);
    font-weight: normal;
  }

  .div-table-value {
    border: 1px solid #DCDCDC;
    font-weight: 900;
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  .btn-dark-blue-like:hover {
    border-color:  #154360 ;
    color:  #154360 ;
    background-color: #ffffff;
  }

  .btn-dark-blue-like {
    border-color: rgba( 21, 67, 96 , .5);
    color: rgba( 21, 67, 96 , .5);
  }

  .btn-dark-blue-like-fill {
    background-color: rgb( 21, 67, 96 );
    opacity: 1;
    color: #FFFFFF;
  }

  .btn-linen:hover {
    border-color: #D2691E;
    color: #D2691E;
    background-color: #ffffff;
  }

  .btn-linen {
    border-color: rgba(210, 105, 30, .5);
    color: rgba(210, 105, 30, .5);
  }

  .btn-linen-fill {
    background-color: rgb(210, 105, 30);
    opacity: 1;
    color: #FFFFFF;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 10s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

</style>
