<template>
  <h6 class="card-title">
    <span v-html="icon" /> {{ totalRequests.total }}
  </h6>
</template>
<script>
import API from '../../api/index'
export default {

  props: {

    requestTypeId: 0,
    icon: ''

  },

  data () {
    return {

      totalRequests: {

        title: '',
        total: 0

      }
    }
  },

  components: {

  },

  methods: {

    getTotalRequestsByTypeAllYear () {
      API.getTotalRequestsByTypeAllYear(this.requestTypeId).then(responce => {
        if (responce.data.status) {
          if (Object.keys(responce.data.data).length > 0) {
            this.totalRequests = responce.data.data
          }
        }
      })
    }

  },

  created () {
    this.getTotalRequestsByTypeAllYear()
  }
}
</script>
