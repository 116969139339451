<template>
    <div>
        <card style="padding-left:20px; padding-right:20px;" v-if="!ifRequestSubmitted">
            <form @submit.prevent="saveRequest">
                <div class="row justify-content-center">
                    <div class="col-md-3"></div>
                    <div class="col-md-6">
                        <label for="myselect">Select application type</label>
                        <select class="form-control" id="myselect" name="myselect" v-model="requestType" @change="changeMyTittle(requestType)">
                            <option disabled value="">Select application type</option>
                            <option :value="RequestType.id" :key="index" v-for="(RequestType, index) in getRequestTypes">
                                {{ RequestType.name }}
                            </option>
                        </select>
                        <div v-if="requestTypeError">
                            <div class="text-danger" v-if="!$v.requestType.required">
                            This field is required
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                </div>

                <div class="row dynamic-bg" v-for="(row , index) in rows" :key="index">
                    <div class="col-md-12">
                        <div class="legend">
                            <h6 class="legend-title">
                                {{ myTittle + ' ( ' + (index + 1) + ' )' }}
                            </h6>

                            <div class="row" style="margin-left:5px;margin-right:5px;">
                                <div :class="[rows.length > 1 ? 'col-md-11' : 'col-md-12']">
                                    <div class="row pr-4">
                                        <div class="col-md-3">
                                            <base-input
                                                class="special-bottom"
                                                :label="myTittle"
                                                :placeholder="myTittle"
                                                type="text"
                                                v-model="row.requestName"
                                                @input="clearRequestNameError(row.entry)"
                                            />
                                            <div v-if="requestNameError.includes(row.entry)" style="margin-top:-16px;">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3" style="margin-top:-1px;">
                                            <label>Search Regime {{ filteredRegimes.length === 0 ? ' ( Select Application type first )' : '' }}</label>
                                            <model-list-select
                                                :list="filteredRegimes"
                                                :isDisabled="filteredRegimes.length === 0"
                                                v-model="row.requestUniqueIdentifier"
                                                class="form-control"
                                                option-value="regimeId"
                                                option-text="regimeName"
                                                placeholder="regime name"
                                                @input="clearRequestUniqueIdentifierError(row.entry)"
                                            />
                                            <div v-if="requestUniqueIdentifierError.includes(row.entry)">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <base-input
                                                class="special-bottom"
                                                label="PO Number"
                                                placeholder="PO Number"
                                                type="text"
                                                v-model="row.poNumber"
                                            />
                                        </div>
                                        <div class="col-md-3">
                                            <base-input
                                                class="special-bottom"
                                                :label="myDate"
                                                :placeholder="myDate"
                                                type="date"
                                                v-model="row.cargoExpectedDate"
                                            />
                                            <!-- <div v-if="cargoExpectedDateError.includes(row.entry)">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="col-md-3">
                                            <label for="myselect">Select product type</label>
                                            <select class="form-control" id="myselect" name="myselect" v-model="row.productType" @change="clearProductTypeError(row.entry)">
                                                <option disabled value="">Select product type</option>
                                                <option :value="productType.id" :key="index" v-for="(productType, index) in productTypes">
                                                    {{ productType.name }}
                                                </option>
                                            </select>
                                            <div v-if="productTypeError.includes(row.entry)">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <base-input
                                                class="special-bottom"
                                                label="Invoice Number (separate with comma if more than one)"
                                                placeholder="Invoice Number (separate with comma if more than one)"
                                                type="text"
                                                v-model="row.invoiceNumber"
                                                @input="clearInvoiceNumberError(row.entry)"
                                            />
                                            <div v-if="invoiceNumberError.includes(row.entry)" style="margin-top:-16px;">
                                                <div class="text-danger">
                                                This field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <label>Select tariff items</label>
                                            <multiselect v-model="row.tariffItem" 
                                            :options="activeTariffItems" 
                                            :multiple="true" 
                                            :close-on-select="false" 
                                            :clear-on-select="false" 
                                            :preserve-search="true" 
                                            :hide-selected="true"
                                            placeholder="Select tariff items" 
                                            label="tariffItemName" 
                                            track-by="tariffItemId" 
                                            :preselect-first="false"
                                            :openDirection="'top'"
                                            :disabled="activeTariffItems.length <= 0"
                                            @select="getSelectedObject($event, index)"
                                            @remove="removeSelectedObject($event, index)"
                                            >
                                                <template slot="selection" slot-scope="{ values, search, isOpen }">
                                                    <span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} {{values.length === 1 ? 'tariff item' : 'tariff items'}} selected</span>
                                                </template>
                                            </multiselect>
                                        </div>
                                        <div class="col-md-12">
                                            <label>Application Description</label>
                                            <textarea class="special-bottom form-control col-md-12" placeholder="Request Details" rows="5" type="textarea" v-model="row.description" @input="clearDescriptionError(row.entry)"/>
                                            <div v-if="descriptionError.includes(row.entry)">
                                                <div class="text-danger">
                                                    This field is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1 minus-bl-row-1" v-if="rows.length > 1">
                                    <div class="row">
                                        <div class="col-md-12 text-center remove-bl" title="remove BL/Airway Bill/RCN">
                                            <button
                                            v-if="rows.length > 1"
                                            class="btn btn-danger btn-space float-right custom-btn mr-2"
                                            :disabled="sendRequestLoader"
                                            data-dismiss="modal"
                                            @click="deleteRow(row)">
                                            Remove {{ myTittle }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="!sendRequestLoader">
                    <div class="col-md-12 add-bl text-right" :title="myTittle">
                        <!-- <span class="plus-bl" @click="addRow">Add new {{ myTittle }}</span> -->
                        <button
                        @click="addRow"
                        class="btn btn-success btn-space  float-right custom-btn mr-2"
                        :disabled="sendRequestLoader"
                        data-dismiss="modal">
                        Add new {{ myTittle }}
                        </button>
                    </div>
                </div>

                <hr>

                <div class="text-center row">
                    <div class="col-md-9 text-right pt-3">
                        <div class="row">
                            <div class="col-md-11">
                                <span v-if="sendRequestLoader" class="float-right">Please wait</span>
                            </div>
                            <div class="col-md-1">
                                <pulse-loader
                                class="float-right"
                                :color="color"
                                :size="size"
                                v-if="sendRequestLoader"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <button
                        class="btn btn-primary btn-space  float-right custom-btn mr-2"
                        :disabled="sendRequestLoader"
                        data-dismiss="modal"
                        type="submit">
                        Continue to attachment
                        </button>
                    </div>
                </div>
            </form>
        </card>
        <card v-if="ifRequestSubmitted">
            <div class="row">
                <div class="col-md-12 text-center" v-if="mandatoryAttachments.length > 0">
                    Mandatory attachments for {{ attchmentType === 'IMP' ? 'Import' : 'Export' }} Application <b class="text-danger" style="font-size:20px;"><sup>*</sup></b>
                </div>
                <div class="col-md-12 text-center" v-if="mandatoryAttachments.length > 0">
                    <ul class="items">
                        <li v-for="(item, index_item) in mandatoryAttachments" :key="index_item">
                            <b> {{ index_item + 1 }}) </b> {{ item }}
                        </li>
                        <li style="font-size:15px;">
                            <b> {{ mandatoryAttachments.length + 1 }}) <i>Plus any other related document(s)</i></b>
                        </li>
                    </ul>
                </div>
                <div class="col-md-12">
                    <hr>
                </div>
            </div>
            <div class="row" v-for="(request , index) in requestIdArray" :key="index">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-10">
                        Attach document(s) for supporting application with BL/Airway Bill/RCN
                        [ <b>{{ requestBlArray[index] }}</b> ]
                        </div>
                        <div class="col-md-2 text-right">
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr />
                </div>
                <div class="col-md-12">
                    <table class="table table-striped" v-if="request.responseAttachment.length > 0">
                        <thead>
                            <tr>
                                <th scope="col"> # </th>
                                <th scope="col">Attachment Type</th>
                                <th scope="col">Download url</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        <tr :key="index" v-for="(attachment , index) in request.responseAttachment" v-if="attachment.requestId === request.requestMyId">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>{{ attachment.attachmentName }}</td>
                            <td class="text-primary" @click.prevent="openPdfViewer(attachment.attachmentUrl , attachment.attachmentName, 'view')">
                            <a href=""><span class="text-success">view</span></a>
                            </td>
                            <td class="text-success">Uploaded</td>
                            <td>
                                <div @click.prevent="deleteArrayRequestAttachment(attachment.requestAttachmentId ,request.requestMyId , requestBlArray[index] , attachment.attachmentName)"
                                class="all-fa-icons fa-icons-space delete-fa-icon" title="remove">
                                    <font-awesome-icon icon="trash-alt" />
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-md-12">
                    <form enctype="multipart/form-data">
                        <div class="row">
                            <div class="col-md-5">
                            <label>Search attachment type</label>
                            <model-list-select
                                :list="options"
                                v-model="documentTypeId[request.requestMyId]"
                                class="form-control"
                                option-value="attachmentTypeId"
                                option-text="attachmentTypesName"
                                placeholder="attachment type"
                                :isDisabled="isUploadingAttachment"
                            />

                            <div v-if="documentTypeIdErrorMany.includes(request.requestMyId)">
                                <div class="text-danger">
                                Select document type
                                </div>
                            </div>
                            <div v-if="!pdfFormatError.includes(request.requestMyId) && fileData" class="text-danger">
                                Attach pdf document only
                            </div>
                            </div>
                            <div class="col-md-5" style="margin-top:25px;">
                                <div class="upload-btn-wrapper">
                                    <button class="btn btn-info btn-fill" :disabled="isUploadingAttachment">Choose Document..</button>
                                    <input type="file" name="cfa-attachment" id="cfa-attachment"  :disabled="isUploadingAttachment"
                                    @change.prevent="saveAttachment($event , request.requestMyId , requestBlArray[index])" />
                                </div>
                                <label
                                    for="first_upload2"
                                    class="label-upload"
                                    v-if="uploadOneByOne.includes(request.requestMyId)"
                                >
                                    {{ file ? 'System is uploading file:  ' + file : 'No File Selected' }} </label>

                            </div>
                            <div class="col-md-2">
                            <pulse-loader
                                :color="color"
                                :loading="loading"
                                :size="size"
                                class="float-center"
                                style="margin-top: 38px"
                                v-if="uploadOneByOne.includes(request.requestMyId)"
                            />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-12" v-if="requestIdArray.length > 1">
                    <hr />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <hr />
                </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <button
                        class="btn btn-fill btn-outline-info float-right custom-btn-all"
                        :disabled="isUploadingAttachment"
                        type="button"
                        @click.prevent="backToStepOne">
                        Cancel
                        </button>
                        <button
                        class="btn btn-primary btn-space  float-right custom-btn mr-2"
                        :disabled="isUploadingAttachment"
                        data-dismiss="modal"
                        @click.prevent="openConfirmManyModal">
                        Save
                        </button>
                    </div>
            </div>
        </card>

        <modal v-if="showConfirmManyModal">
            <div class="modal-title col-md-12" slot="header">
            </div>

            <div slot="body">
                <div>
                <card class="text-center">

                    <p>{{ finalSubmitLoader ? 'Please wait while system is sending your file' : attachmentManyAlertMessage }}</p>
                    <p>
                        <pulse-loader
                                :color="color"
                                :size="size"
                                v-if="finalSubmitLoader"
                                class="float-center"
                                style="margin-top: 38px"
                            />
                    </p>

                    <div class="text-center row">
                    <div class="col-md-12 text-center">
                        <button @click="markApplicationAsSubmited" :disabled="finalSubmitLoader"
                        class="btn btn-fill btn-outline-info btn-space float-right custom-btn-all" type="button">
                        No, proceed
                        </button>
                        <button @click="closeBackModal" :disabled="finalSubmitLoader"
                        class="btn btn-success btn-space float-right mr-2 custom-btn" type="button">
                        Yes
                        </button>
                    </div>
                    </div>

                </card>
                </div>
            </div>
        </modal>
        <global-modal v-if="globalLoader">
            <div slot="globalLoader">
                <div class="row text-center">
                    <div class="col-md-12">
                        Preparing requested file, Please wait
                    </div>
                    <div class="col-md-12">
                        <br>
                    </div>
                    <div class="col-md-12">
                        <pulse-loader
                        :color="color"
                        :size="size"
                        />
                    </div>
                </div>
            </div>
        </global-modal>
        <pdf-viewer :mySrc="scr" v-if="showPdfViewer" :maxWidth="1800" @closePdfViewer="closePdfViewer"></pdf-viewer>
    </div>
</template>

<script>

import { CFA_APPLICATION_TYPES, PRODUCT_TYPES } from '../../../mixins/constants'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from "vuex"
import { ListSelect, ModelListSelect } from 'vue-search-select'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import CFA_API from '../../../api/cfa'
import API from '../../../api'
import Modal from '../../../components/Inputs/Modal'
import PdfViewer from '../../../components/pdfViewer.vue'
import GlobalModal from '../../../components/globalLoader'
import Multiselect from 'vue-multiselect'

export default {

    name: 'TASACNewApplication',

    props: {

        requestIdArrayProp: {

            type: Array,
            default: () => []

        },

        requestBlArrayProp: {

            type: Array,
            default: () => []

        },

        ifRequestSubmittedProp: {

            type: Boolean,
            default: () => false

        }

    },

    components: {

        ListSelect,
        ModelListSelect,
        PulseLoader,
        Modal,
        Multiselect,
        PdfViewer,
        GlobalModal

    },

    created () {

        this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
        this.$store.dispatch('pagenames/currentPageName', 'New Application')
        this.loadDocumentType()
        this.loadAllRegimes()
        this.loadAllActiveTariffItems()
        if(this.ifRequestSubmittedProp) {

            this.ifRequestSubmitted = this.ifRequestSubmittedProp
            this.requestIdArray = this.requestIdArrayProp
            this.requestBlArray = this.requestBlArrayProp
            this.blsToSubmit = [this.requestIdArray[0].requestMyId]
            this.loadRequestAttachment(this.requestIdArray[0].requestMyId)

        }

    },

    computed:{

        ...mapGetters('global' , [

            'getAllRegimes',
            'getGlobalLoader',
            'getActiveTariffItems'

        ])

    },

    validations: {

        requestType: { required },
        requestName: { required },
        description: { required },
        requestUniqueIdentifier: { required }

    },

    data () {

        return {

            color: 'green',
            size: '13px',
            size1: '9px',
            loading: true,

            requestType: '',
            attchmentType: '',
            myTittle: 'BL/Airway Bill/RCN',
            myDate: 'Expected Time of Arrival (ETA)',
            getRequestTypes: CFA_APPLICATION_TYPES,
            requestUniqueIdentifier: '',
            regimes: [],
            filteredRegimes: [],
            rows: [ { entry: 1 , requestName: '', description: '', requestUniqueIdentifier: '', poNumber: '', cargoExpectedDate: '', productType: '', invoiceNumber: '', tariffItem: [], descriptionsArray: [] } ],
            selectedRegime: {},
            file: "",
            attachmentManyAlertMessage: '',
            fileData: '',

            requestTypeError: false,
            requestUniqueIdentifierError: [],
            cargoExpectedDateError: [],
            requestNameError: [],
            descriptionError: [],
            sendRequestLoader: false,
            ifRequestSubmitted: false,
            hasUserUploadedAnyThing: false,
            pdfFormatSingleError: false,
            loadAttachmentLoader: false,
            showConfirmManyModal: false,
            isUploadingAttachment: false,
            finalSubmitLoader: false,
            showPdfViewer: false,
            scr: '',
            initialEntry: 1,

            requestIdArray: [],
            requestBlArray: [],
            responseAttachment: [],
            uploadOneByOne: [],
            options: [],
            documentTypeId: [],
            documentTypeIdErrorMany: [],
            pdfFormatError: [],
            checkBlList: [],
            blsToSubmit: [],
            finalUserPermissions: [],
            productTypeError: [],
            invoiceNumberError: [],
            productTypes: PRODUCT_TYPES,
            tariffItemCopy: [],
            activeTariffItems: [],
            mandatoryAttachments: [],
            globalLoader: false

        }

    },

    methods: {

        ...mapActions("global", [

            'loadRegimes',
            'loadFileFromMinio',
            'loadActiveTariffItems'

        ]),

        clearRequestNameError (entry) {

            if(this.requestNameError.includes(entry)) {

                this.requestNameError.splice(this.requestNameError.indexOf(entry), 1)

            }

        },
        clearRequestUniqueIdentifierError (entry) {

            if(this.requestUniqueIdentifierError.includes(entry)) {

                this.requestUniqueIdentifierError.splice(this.requestUniqueIdentifierError.indexOf(entry), 1)

            }

        },
        clearProductTypeError (entry) {

            if(this.productTypeError.includes(entry)) {

                this.productTypeError.splice(this.productTypeError.indexOf(entry), 1)

            }

        },
        clearInvoiceNumberError (entry) {

            if(this.invoiceNumberError.includes(entry)) {

                this.invoiceNumberError.splice(this.invoiceNumberError.indexOf(entry), 1)

            }

        },
        clearDescriptionError (entry) {

            if(this.descriptionError.includes(entry)) {

                this.descriptionError.splice(this.descriptionError.indexOf(entry), 1)

            }

        },

        getSelectedObject(selectedObject, entry) {

            let description = []

            if(this.rows[entry].description !== '' && this.rows[entry].descriptionsArray.length === 0) {

                // this.rows[entry].descriptionsArray.push(this.rows[entry].description)
                description.push(this.rows[entry].description)

            }

            this.rows[entry].tariffItem = []
            this.tariffItemCopy.push(selectedObject)
            this.rows[entry].tariffItem = this.tariffItemCopy

            description = this.rows[entry].description !== '' ? this.rows[entry].description.split('\n') : []
            this.rows[entry].description = ''
            
            for (let i = 0; i < this.rows.length; i++) {

                if (i === entry && !this.rows[entry].descriptionsArray.includes(selectedObject)) {

                    this.rows[entry].descriptionsArray.push(selectedObject)
                    // return this.rows[i].description = this.rows[entry].descriptionsArray.join('\r\n')
                    description.push(selectedObject.tariffItemName)

                }
                
            }

            this.rows[entry].description = description.join('\n')

        },

        removeSelectedObject(selectedObject, entry) {

            this.rows[entry].tariffItem = []
            this.tariffItemCopy.splice(this.tariffItemCopy.indexOf(selectedObject), 1)
            this.rows[entry].tariffItem = this.tariffItemCopy
            let description = []
            description = this.rows[entry].description.split('\n')
            this.rows[entry].description = ''

            for (let i = 0; i < this.rows.length; i++) {

                if (i === entry) {

                    this.rows[entry].descriptionsArray.splice(this.rows[entry].descriptionsArray.indexOf(selectedObject), 1)
                    // return this.rows[i].description = this.rows[entry].descriptionsArray.join('\r\n')
                    description.splice(description.indexOf(selectedObject.tariffItemName), 1)

                }
                
            }

            this.rows[entry].description = description.join('\n')

        },

        loadAllActiveTariffItems() {

            this.loadActiveTariffItems({}).then(() => {

                this.activeTariffItems = this.getActiveTariffItems;

            }).catch(() => {})

        },

        loadParameters (fileUrl, fileName) {
        
            return new Promise((resolve) => {

                this.$store.dispatch('global/fileUrling', fileUrl)
                this.$store.dispatch('global/fileNaming', fileName)

                resolve()
            })

        },

        openPdfViewer (fileUrl, fileName, action) {

            this.getFileFromMinio(fileUrl, fileName, action).then(() => {

                this.showPdfViewer = true

            }).catch(() => {})

        },

        closePdfViewer () {

            this.showPdfViewer = false
            this.scr = ''

        },

        getFileFromMinio (fileUrl, fileName, action) {

            return new Promise((resolve, reject) => {

                this.loadParameters(fileUrl, fileName).then(() => {

                this.loadFileFromMinio({action}).then((res) => {

                    if(res[1].status === 204) {

                        this.loadNotification('warn', 4000, '',
                        'File not found, please contact system admin',
                        'response', 1000 , true  , true)

                    } else {

                        this.loadNotification('success', 4000, '',
                        'File has been successfully loaded, please have a look at it',
                        'response', 1000 , true  , true)
                        this.scr = res[0]
                        resolve()


                    }

                        this.globalLoader = this.getGlobalLoader

                    }).catch((error) => {

                    this.loadNotification('error', 4000, '',
                        'File not loaded, please contact system admin',
                        'response', 1000 , true  , true)

                    this.globalLoader = false

                    })

                    this.globalLoader = this.getGlobalLoader

                }).catch(() => {

                    this.globalLoader = false

                })

            })

        },

        // getFileFromMinio (fileUrl, fileName) {

        //     this.loadParameters(fileUrl, fileName).then(() => {

        //     this.loadFileFromMinio({}).then((res) => {

        //         if(res.status === 204) {

        //             this.loadNotification('warn', 4000, 'File Download (click to close)',
        //             'File not found, please contact system admin',
        //             'response', 1000 , true  , true)

        //         } else {

        //             this.loadNotification('success', 4000, 'File Download (click to close)',
        //             'File has been successfully downloaded, please have a look at it',
        //             'response', 1000 , true  , true)


        //         }

        //             this.globalLoader = this.getGlobalLoader
                
        //         }).catch(() => {

        //         this.loadNotification('error', 4000, 'File Download (click to close)',
        //             'File not downloaded, please check your internet connection',
        //             'response', 1000 , true  , true)

        //         this.globalLoader = this.getGlobalLoader

        //         })

        //         this.globalLoader = this.getGlobalLoader

        //     }).catch(() => {

        //     })

        // },

        loadDocumentType() {

            API.getAttachments().then(response => {

                if (response.data.status) {

                for (let i = 0; i < response.data.data.length; i++) {

                    if (response.data.data[i].attachmentGroupCode !== 'CRA') {

                        this.options.push(response.data.data[i])

                    }

                }

                } else {

                }
            }
            ).catch(() => {


            });

        },

        getMandatoryAttachments (tagType) {

            let newTagType

            tagType === 'IMP' ? newTagType = 'IMPORT' : newTagType = 'EXPORT'

            this.mandatoryAttachments = []

            for (let i = 0; i < this.options.length; i++) {

                if ((this.options[i].category === newTagType || this.options[i].category === 'BOTH') && this.options[i].mandatory) {

                    this.mandatoryAttachments.push(this.options[i].attachmentTypesName)

                }

            }

            this.requestType = ''

        },

        loadAllRegimes() {

            this.loadRegimes({}).then(() => {

                this.regimes = this.getAllRegimes;

            }).catch(() => {


            });

        },

        addRow: function () {

            this.initialEntry = this.initialEntry + 1
            // this.initialEntry = myEntry

            this.rows.push({ entry: this.rows.length + 1, requestName: '', description: '', requestUniqueIdentifier: '', poNumber: '', cargoExpectedDate: '', productType: '', invoiceNumber: '', tariffItem: [], descriptionsArray: [] })

        },

        deleteRow: function (row) {

            this.rows.splice(this.rows.indexOf(row), 1)

        },

        populateSelectedRegime (object) {

            this.selectedRegime = object

        },

        markApplicationAsSubmited () {

            this.finalSubmitLoader = true

            CFA_API.applicationStatusToSubmit(this.blsToSubmit).then(
                response => {

                    if (response.data.status) {

                    this.showConfirmManyModal = false
                    this.finalSubmitLoader = false
                    if(this.ifRequestSubmittedProp) {

                        this.ifRequestSubmittedProp = false
                        this.requestIdArray = []
                        this.requestBlArray = []
                        this.blsToSubmit = []

                    } else {

                        this.ifRequestSubmitted = false

                    }
                    this.loadNotification('success', 4000, 'New Application (click to close)', 'Application(s) has been successfully submitted', 'response', 1000 , true  , true)
                    this.$router.push({ name: 'MyApplicationList', params: { status: 'my'}});

                    } else {

                    this.loadNotification('error', 5000, 'New Application (click to close)', response.data.message, 'response', 1000 , true  , true)
                    this.finalSubmitLoader = false

                    }

            }).catch(() => {this.finalSubmitLoader = false});

        },

        openConfirmManyModal() {

            this.showConfirmManyModal = true;
            let rightFlag = 0;

            for (let c = 0; c < this.requestIdArray.length; c++) {

                if (typeof this.documentTypeId[this.requestIdArray[c].requestMyId] !== 'undefined') {

                    if (this.documentTypeId[this.requestIdArray[c].requestMyId] !== []) {

                    API.getIndividualAttachment(this.documentTypeId[this.requestIdArray[c].requestMyId]).then(
                        response => {

                            if (response.data.status) {

                                this.getAttachmentTypeName = response.data.data.attachmentTypesName;

                                this.attachmentManyAlertMessage = "You have selected attachment " +
                                "[ " + this.getAttachmentTypeName + " ] for BL/Airway Bill/RCN " +
                                "[ " + this.requestBlArray[c] + " ] but you have not uploaded any file, do you " +
                                "want to attach respective file?"

                            } else {

                                this.attachmentManyAlertMessage = "It's seems you are trying to attach document for " +
                                "unknown attachment type, please contact system administrator";

                            }
                        }
                    ).catch(() => {});

                    rightFlag++;

                    } else {


                    }

                } else {

                }

            }

            if (rightFlag === 0) {

            this.attachmentManyAlertMessage = "Do you have any other document(s) to attach?";

            }

        },

        checkIfAnyBlExists(data) {

            return new Promise((resolve) => {

                this.sendRequestLoader = true

                CFA_API.checkIfBlExists(data).then(
                response => {

                    if (response.data.status) {

                        let responseBlList = []
                        let dialogMessage
                        for (let w = 0; w < response.data.data.length; w++) {

                            responseBlList.push(response.data.data[w].blNumber)

                        }

                        if(response.data.data.length === 1) {

                            dialogMessage = 'The following bl exists <br><br> B/L No. - [ ' + responseBlList + ' ] <br>Do you want to proceed?.'

                        } else {

                            dialogMessage = 'The following bls exist <br><br> B/L No. - [ ' + responseBlList + ' ] <br>Do you want to proceed?.'

                        }

                        this.$dialog.confirm(dialogMessage, {

                        loader: true,
                        html: true,
                        animation: 'zoom', // Available: "zoom", "bounce", "fade"
                        okText: 'Yes, proceed',
                        cancelText: 'No, close'

                        }).then((dialog) => {

                            dialog.close()
                            resolve(true);

                        }).catch(() => {

                            this.sendRequestLoader = false
                            resolve(false);

                        })

                    } else {

                        this.sendRequestLoader = false
                        resolve(true);

                    }

                }).catch(() => {})

            })

        },

        saveRequest () {

            if (this.$v.requestType.$invalid) {

                if (this.$v.requestType.$invalid) {

                    this.requestTypeError = true

                }

            } else {

                let errorFree = 0

                for (let w = 0; w < this.rows.length; w++) {

                    if (this.rows[w].requestName === '' || this.rows[w].description === '' || this.rows[w].requestUniqueIdentifier === '' || this.rows[w].productType === '' || this.rows[w].invoiceNumber === '') {

                        if (this.rows[w].requestName === '') {

                            this.requestNameError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.requestNameError.splice(this.requestNameError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].description === '') {

                            this.descriptionError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.descriptionError.splice(this.descriptionError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].requestUniqueIdentifier === '') {

                            this.requestUniqueIdentifierError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.requestUniqueIdentifierError.splice(this.requestUniqueIdentifierError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].productType === '') {

                            this.productTypeError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.productTypeError.splice(this.productTypeError.indexOf(this.rows[w].entry), 1)

                        }

                        if (this.rows[w].invoiceNumber === '') {

                            this.invoiceNumberError.push(this.rows[w].entry)
                            errorFree++

                        } else {

                            this.invoiceNumberError.splice(this.invoiceNumberError.indexOf(this.rows[w].entry), 1)

                        }

                        // if (this.rows[w].cargoExpectedDate === '') {

                        //     this.cargoExpectedDateError.push(this.rows[w].entry)
                        //     errorFree++

                        // } else {

                        //     this.cargoExpectedDateError.splice(this.cargoExpectedDateError.indexOf(this.rows[w].entry), 1)

                        // }


                    }

                }

                if (errorFree === 0) {

                    this.sendRequestLoader = true
                    this.checkBlList = []

                    const requestBls = []

                    for (let y = 0; y < this.rows.length; y++) {

                        const singleBl = {

                        blNumber: this.rows[y].requestName,
                        regimeId: this.rows[y].requestUniqueIdentifier,
                        description: this.rows[y].description, //.replace(/\r\n/g, ":::"),
                        poNumber: this.rows[y].poNumber,
                        cargoExpectedDate: this.rows[y].cargoExpectedDate !== '' ? this.rows[y].cargoExpectedDate + 'T00:00:00.000Z' : '',
                        productType: this.rows[y].productType,
                        invoiceNo: this.rows[y].invoiceNumber

                        }

                        requestBls.push(singleBl)
                        this.checkBlList.push(this.rows[y].requestName)
                    }

                    let blToCheckObject = {

                        blNumbers: this.checkBlList,
                        customerId: 0

                        }

                    this.checkIfAnyBlExists(blToCheckObject).then((result) => {

                        if(result) {

                            const request = {

                                requestBls,
                                requestName: null,
                                requestType: this.requestType,
                                requestTypeId: 0,
                                customerId: 0

                            }

                            CFA_API.customerNewApplication(JSON.stringify(request)).then(
                                response => {

                                    if (response.data.status) {

                                        this.sendRequestLoader = false

                                        this.rows = [ { entry: 1, requestName: '', description: '', requestUniqueIdentifier: '', poNumber: '', cargoExpectedDate: '', productType: '', invoiceNumber: '', tariffItem: [], descriptionsArray: [] } ]

                                        for (let a = 0; a < response.data.data.length; a++) {

                                            this.requestBlArray.push(response.data.data[a].blNumber)
                                            const newObject = {

                                                requestMyId: response.data.data[a].requestId,
                                                responseAttachment: []

                                            }

                                            this.requestIdArray.push(newObject)
                                            this.blsToSubmit.push(response.data.data[a].requestId)
                                        }

                                        this.ifRequestSubmitted = true
                                        this.sendRequestLoader = false
                                        this.hasUserUploadedAnyThing = true
                                        this.attchmentType = response.data.data[0].requestType
                                        this.getMandatoryAttachments(response.data.data[0].requestType)

                                    } else {

                                        this.loadNotification('error', 5000, 'New Application (click to close)', 'Application not sent, please retry', 'response', 1000 , true  , true)
                                        this.sendRequestLoader = false

                                }
                                }
                            ).catch(() => {

                                this.loadNotification('error', 5000, 'New Application (click to close)', 'Application not sent, please retry', 'response', 1000 , true  , true)
                                this.sendRequestLoader = false

                            })

                        } else {

                            this.sendRequestLoader = false

                        }

                    })

                } else {}

            }

        },

        saveAttachment (event, requestId, responseRequestBl) {

            for (let z = 0; z < this.requestIdArray.length; z++) {

                if (this.requestIdArray[z].requestMyId === requestId) {

                    this.fileData = event.target.files[0]

                    if (this.documentTypeId[this.requestIdArray[z].requestMyId] === undefined ||
                                this.fileData.type !== 'application/pdf') {
                                    
                            if (this.documentTypeId[this.requestIdArray[z].requestMyId] === undefined) {

                                this.documentTypeIdErrorMany.push(this.requestIdArray[z].requestMyId)

                            } else {

                                this.documentTypeIdErrorMany.splice(this.documentTypeIdErrorMany.indexOf(this.requestIdArray[z].requestMyId), 1)

                            }

                            if (this.fileData.type !== 'application/pdf') {

                                this.pdfFormatError.push(this.requestIdArray[z].requestMyId)

                            } else {

                                // this.pdfFormatError.splice(this.pdfFormatError.indexOf(this.requestIdArray[z].requestMyId), 1)

                            }

                    } else {

                        this.isUploadingAttachment = true

                        this.uploadOneByOne = [this.requestIdArray[z].requestMyId]
                        this.displayFeedback = [this.requestIdArray[z].requestMyId]

                        this.file = this.fileData.name

                        const formData = new FormData()

                        formData.append('file', this.fileData)
                        formData.append('requestId', requestId)
                        formData.append('attachmentTypeId', this.documentTypeId[this.requestIdArray[z].requestMyId])

                        API.uploadRequestAttachment(formData).then(
                        response => {
                            if (response.data.status) {

                                this.loadNotification('success', 4000, 'Attachment Upload (click to close)', 'Attachment [ ' + this.file + ' ] for application for ' +
                                                '[ ' + responseRequestBl + ' ] has been uploaded', 'response', 1000 , true  , true)
                                this.fileData = ''
                                this.loadRequestAttachment(response.data.data.requestId)
                                this.pdfFormatError = []
                                this.uploadOneByOne = []
                                this.documentTypeId = []
                                this.documentTypeIdErrorMany = []
                                this.file = ''
                                this.isUploadingAttachment = false

                                if (this.deciderReturn) {
                                    this.editRequest()
                                }

                                setTimeout(() => {
                                    this.uploadedMessage = ''
                                }, 7000)
                            } else {

                                this.isUploadingAttachment = false

                            }
                        }
                        ).catch(() => {
                        
                            this.isUploadingAttachment = false

                        })

                    }
                }
            }
        },

        deleteArrayRequestAttachment (data, myRequestId, blNumber, AttachmentName) {

            for (let k = 0; k < this.requestIdArray.length; k++) {

                if (this.requestIdArray[k].requestMyId === myRequestId) {

                this.$store.dispatch('clearingrequests/customerNameBeforeDelete', blNumber)
                this.$store.dispatch('clearingrequests/attachmentNameBeforeDelete', AttachmentName)

                this.$dialog
                    .confirm(
                    'If you delete attachment [ ' +
                                    AttachmentName +
                                    " ], it'll be gone forever.",
                    {
                        loader: true
                    }
                    )
                    .then(dialog => {

                    API.deleteRequestAttachment(data)
                        .then(response => {
                        if (response.data.status) {
                            this.SuccessMessage = ', Attachment [ ' + this.getAttachmentNameBeforeDelete + ' ] ' +
                                                'has been removed from BL/Airway Bill/RCN  [ ' + this.getCustomerNameBeforeDelete + ' ]'
                            this.$store.dispatch('clearingrequests/customerNameBeforeDelete', '')
                            this.$store.dispatch('clearingrequests/attachmentNameBeforeDelete', '')
                            this.ErrorMessage = ''
                            this.SuccessAlert = true
                            this.ErrorAlert = false
                            this.loadRequestAttachment(myRequestId)

                            if (this.deciderReturn) {
                            this.editRequest()
                            }

                            window.scrollTo(10, 0)
                        } else {

                        }
                        })
                        .catch(e => {
                            
                        })

                    dialog.close()
                    })
                    .catch(() => {
                    this.myloader = false
                    })
                }
            }

        },

        loadRequestAttachment(data) {

            this.loadAttachmentLoader = true;

            for (let q = 0; q < this.requestIdArray.length; q++) {

            if (this.requestIdArray[q].requestMyId === data) {

                API.getFullRequestDetails(data).then(
                response => {

                    if (response.data.status) {

                    this.requestIdArray[q].responseAttachment = response.data.data.requestAttachDtos;
                    this.attchmentType = response.data.data.requestType
                    this.getMandatoryAttachments(response.data.data.requestType)

                    this.loadAttachmentLoader = false;

                    } else {

                    }

                }
                ).catch(() => {});

            }

            }

        },

        changeMyTittle (flag) {

            this.filteredRegimes = []

            if (flag === 'EXP') {

            this.myTittle = 'SO/CRN/Airway Bill & Ticket/REFNO.'
            this.myDate = 'Expected Time of Departure (ETD)'

            } else {

            this.myTittle = 'BL/Airway Bill/RCN'
            this.myDate = 'Expected Time of Arrival (ETA)'
            }

            for (let x = 0; x <= this.regimes.length; x++) {

                if(typeof this.regimes[x] !== 'undefined' && this.regimes[x].operationType === this.requestType) {

                    this.filteredRegimes.push(this.regimes[x])

                }

            }

        },

        closeBackModal() {

            this.showConfirmManyModal = false

        },

        backToStepOne() {

            if(this.ifRequestSubmittedProp) {

                this.$router.push({ name: 'CustomerApplicationDetails', params: {requestId: this.requestIdArray[0].requestMyId}});
                this.ifRequestSubmittedProp = false
                this.requestIdArray = []
                this.requestBlArray = []
                this.blsToSubmit = []

            } else {

                this.ifRequestSubmitted = false

            }

        }

    }
    
}

</script>

<style scoped>

.items li {

    list-style: none;
    padding-right: 10px;
    display: inline;
}


.legend {
    position: relative;
    border: 1px solid #DCDCDC;
    padding: 1.5rem 1rem 1rem 1rem;
    margin-bottom: 2rem;
    border-radius: 3px;
    margin-top: 2rem;
}

.legend-title {
    background-color: inherit;
    position: absolute;
    top: -0.6rem;
    padding: 0.2rem 1rem;
    background-color: white;
    color: #d59a18;
}

.remove-bl {

    margin-top: 142px;
    color: red;

}

.minus-bl:hover, .plus-bl:hover {

    cursor: pointer;

}

.add-bl {

color: green;
background-color: rgba(128, 128, 128, .1);
padding-right: 5px;
border-radius: 3px;

}

.minus-bl-row {

background-color: rgba(128, 128, 128, .1);
border-radius: 3px;

}

.custom-btn {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
    background-color: #FFFFFF !important;
}

.custom-btn-all {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 5px 9px 5px 9px !important;
    font-size: 14px !important;
    width: auto;
}

/* .fileUpload {
position: relative;
overflow: hidden;
margin: 0.5em 5px;
font-size: 1em;
letter-spacing: 0.02em;
line-height: 1.26;
text-decoration: none;
top: 23px;
font-weight: normal;
}

.fileUpload input.upload {
position: absolute;
top: 0;
right: 0;
margin: 0;
padding: 0;
cursor: pointer;
opacity: 0;
filter: alpha(opacity=0);
}

label {
position: relative;
top: -7px;
right: 0;
margin: 0;
padding: 0;
}

.label-upload {
position: relative;
top: 23px;
}

.fileUpload span {
cursor: pointer;
} */

.upload-btn-wrapper {
position: relative;
overflow: hidden;
display: inline-block;
}

.upload-btn-wrapper input[type=file]:hover {
cursor: pointer;
}

.upload-btn-wrapper input[type=file] {
font-size: 100px;
position: absolute;
left: 0;
top: 0;
opacity: 0;
}

.label-upload {
position: absolute;
top: 9px;
left: 200px
}

.delete-fa-icon {
    color: red;
}

.delete-fa-icon:hover {
    cursor: pointer;
}

</style>