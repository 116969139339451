<template>
  <div class="content">
    <card>
      <div class="row" v-if="!myLoader && !myFirstLoader">
        <div class="col-md-12 text-right">
          <!-- <span
            class="all-fa-icons btn-space"
            title="add new request"
            @click.prevent="openModal(null , actionButton = 'AddModal')"
            v-if="finalUserPermissions.includes('CUSTOMERS_ADD_POST')"
          >
            <font-awesome-icon icon="user-plus" />
          </span> -->
          <button
            type="submit"
            class="btn btn-primary custom-btn"
            title="add new request"
            @click.prevent="openModal(null , actionButton = 'AddModal')"
          >
            Add New Customer
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" v-if="!myLoader && !myFirstLoader">
          <base-input class="special-bottom" placeholder="Customer Name" label="Customer Name" type="text" v-model="customerNameFilter"></base-input>
        </div>
        <div class="col-md-12" v-if="!myLoader && !myFirstLoader">
            <button
            @click.prevent="resetParams"
            :disabled="customerNameFilter === ''"
            class="btn btn-fill btn-outline-info float-right custom-btn-all"
            data-dismiss="modal"
            >
            Reset
            </button>
            <button
            @click.prevent="searchGeneralParams(customerNameFilter, 'onCreated')"
            :disabled="customerNameFilter === ''"
            class="btn btn-primary btn-space float-right custom-btn"
            data-dismiss="modal"
            >
            Search
            </button>
        </div>
        <div class="col-md-12">
          <data-table
            ref="table"
            :key="pageSize"
            :hasFilter="false"
            :tableHeaders="tableHeaders"
            :tableData="filteredRequests"
            :tableDataKeys="tableDataKeys"
            :pageSizeProp="parseInt(pageSize)"
            :pageNo="pageNo"
            :myLoader="myLoader"
            :reloadAllRequest="reloadAllRequest"
            :myFirstLoader="myFirstLoader"
            :noDataFound="noDataFound"
            :searchParameterProp="searchParameter"
            :loadingTitle="'Please wait while system is loading CFA customers'"
            :hasManageButton="false"
            :totalElements="totalElements"
            :totalPages="totalPages"
            :isFirst="isFirst"
            :isLast="isLast"
            :pageNumber="pageNumber"
            :buttonList="[{'code' : 'FinishTallying' ,'name' : 'Manage' , 'class' : 'btn-success'}]"
            :whichModuleAndType="whichModuleAndType"
            :permissions="[ {key: 'searchPermission', value: 'BILLS_SEARCH_BILLS_GET'} ]"
            @onChange="onChange"
            @goToPage="goToPage"
            @goToFinishTallying="openModal($event, actionButton = 'ShowModal')">
            <div slot="pagination">
              <paginate
                v-model="getIsPage"
                :page-count=parseInt(totalPages)
                :page-range="3"
                :margin-pages="2"
                :click-handler="goToPage"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'">
              </paginate>
            </div>
          </data-table>
        </div>
      </div>
    </card>

    <modal v-if="showModal">
      <div
        slot="header"
        class="modal-title col-md-12"
      >
        <div class="row">
          <div
            class="col-md-12"
            v-if="EditModalDiv"
          >
            Edit Customer [ <b>{{ name }}</b> ]
          </div>
          <div
            class="col-md-12"
            v-if="AddModalDiv && !addFlag"
          >
            Register New Customer
          </div>
          <div
            class="col-md-12"
            v-if="ShowModalDiv"
          >
            <div class="row">
              <div class="col-md-10">
                Details for Customer: [ <b>{{ singleCustomer.name }}</b> ] |
                TIN: [ <b>{{ typeof singleCustomer.tin !== 'undefined' ? hyphenifyTin(singleCustomer.tin) : '' }}</b> ]
              </div>
              <div class="col-md-2">
                <pulse-loader
                  :loading="loading"
                  :color="color"
                  :size="size"
                  v-if="loadingShowCustomer"
                />
              </div>
              <div class="col-md-12">
                <br />
              </div>
              <div class="col-md-12">
                <ul
                  class="nav-justified"
                  style="padding-left: 10px;padding-right: 10px">

                  <li class="float-right">
                    <button title="Delete this customer"
                      @click.prevent="deleteSingleCustomer(customerDetailsPopulated.customerId , customerDetailsPopulated.name)"
                      class="btn btn-danger-custom btn-danger-custom-fill mr-2 custom-btn-all">
                      Delete
                    </button>
                  </li>

                  <li class="float-right">
                    <button title="Edit details for this customer"
                    @click.prevent="openModal(customerDetailsPopulated , actionButton = 'EditModal')"
                      class="btn btn-success-custom btn-success-custom-fill mr-2 custom-btn-all">
                      Edit
                    </button>
                  </li>

                  <li class="float-right">
                    <button title="Add user for this customer"
                    v-if="customerDetailsPopulated.customerType === 'Company' &&
                      finalUserPermissions.includes('USERS_ADD_POST')"
                    @click.prevent="addCustomerAccount(customerDetailsPopulated.customerId , customerDetailsPopulated.name)"
                    class="btn btn-purple btn-purple-fill mr-2 custom-btn-all">
                      Add User Account
                    </button>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div
            class="col-md-12"
            v-if="RegisterCustomerUserModalDiv"
          >
            <span v-if="addFlag">
              Register Account for customer [ <b>{{ customerName }}</b> ]
            </span>
            <span v-if="!addFlag">
              Register Account for customer [ <b>{{ responseUserDetails.name }}</b> ]
            </span>
            <br v-if="!addFlag">
            <span v-if="customer.customerType === 'ind001' && !addFlag">One account is required for this
              customer to be able to manage C&F Applications</span>
            <span v-if="customer.customerType === 'com001' && !addFlag">Create one account for this customer
              to be able to manage C&F Applications, more accounts for this customer can be registered later</span>
            <br v-if="!addFlag">
            <span v-if="!addFlag">You can skip registering account now by clicking close button</span>
          </div>
          <div class="col-md-12">
            <hr>
          </div>
        </div>
      </div>

      <div slot="body">
        <!--        <card>-->

        <!--     EDIT FORM       -->
        <div v-if="EditModalDiv">
          <card v-if="!ifCustomerSubmitted">
            <form
              method="POST"
              @submit.prevent="editCustomer"
              v-if="EditModalDiv"
            >
              <div class="row">
                <div class="col-md-12 text-center">
                  Please select customer type below
                </div>
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <label>Customer Type</label>
                  <select class="form-control" v-model="customerType">
                      <option value="com001">COMPANY</option>
                      <option value="ind001">INDIVIDUAL</option>
                  </select>
                </div>
                <div class="col-md-4"></div>
              </div>
              <!-- <div class="row">
                <div class="col-md-4 text-right">
                  <input
                    type="radio"
                    v-model="customerType"
                    name="gender"
                    value="com001"
                    :checked="customerType == 'Company'"
                  > &nbsp;&nbsp;<span
                    class="text-muted"
                  >Company</span>
                  <div v-if="userTypeError">
                    <div
                      class="error"
                      v-if="!$v.customerType.required"
                    >
                      Select atleast one option
                    </div>
                  </div>
                </div>
                <div class="col-md-4 text-right" />
                <div class="col-md-4 text-left">
                  <input
                    type="radio"
                    v-model="customerType"
                    name="gender"
                    value="ind001"
                    :checked="customerType === 'Individual'"
                  > &nbsp;&nbsp;<span
                    class="text-muted"
                  >Individual</span>
                </div>
              </div> -->

              <div class="row">
                <div class="col-md-8">
                  <base-input
                    type="text"
                    label="Full Name"
                    placeholder="Full Name"
                    v-model="name"
                    class="special-bottom"
                    value="name"
                  />
                  <div v-if="firstNameError">
                    <div
                      class="error"
                      v-if="!$v.name.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="">Phone Number</label>
                      <masked-input class="col-md-12 custom-form-group" placeholder="Phone Number" label="Phone Number" type="tel" mask="(\+\255) 111 - 111 - 111" v-model="mobile"/>
                  <!-- <base-input
                    type="text"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                    v-model="mobile"
                    class="special-bottom"
                    value="mobile"
                  /> -->
                  <div v-if="middleNameError">
                    <div
                      class="error"
                      v-if="!$v.mobile.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <base-input
                    type="email"
                    label="Email Address"
                    placeholder="Email Address"
                    v-model="email"
                    class="special-bottom"
                    value="email"
                  />
                  <div v-if="emailError">
                    <div
                      class="error"
                      v-if="!$v.email.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <label for="">TIN Number</label>
                  <masked-input class="col-md-12 custom-form-group" placeholder="TIN Number" label="TIN Number" type="tel" mask="111 - 111 - 111" v-model.trim="tin"/>
                  <!-- <base-input
                    type="text"
                    label="TIN Number"
                    placeholder="TIN Number"
                    v-model.trim="tin"
                    class="special-bottom"
                    value="tin"
                  /> -->
                  <div v-if="tinNoError">
                    <div
                      class="error"
                      v-if="!$v.tin.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <base-input
                    type="text"
                    label="VRN Number"
                    placeholder="VRN Number"
                    v-model.trim="vrn"
                    class="special-bottom"
                    value="vrn"
                  />
                  <div v-if="usernameError">
                    <div
                      class="error"
                      v-if="!$v.vrn.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label>Customer Address Details</label>
                  <textarea
                    class="special-bottom form-control col-md-12"
                    type="textarea"
                    rows="8"
                    placeholder="Address Details"
                    v-model="address"
                  />
                  <div v-if="addressError">
                    <div
                      class="error"
                      v-if="!$v.address.required"
                    >
                      This field is required
                    </div>
                  </div>
                </div>
              </div>

              <br>

              <div class="text-center row">
                <div class="col-md-4 text-right loader-div">
                  <span v-if="sendCustomerLoader">Please wait ....</span>
                </div>
                <div class="col-md-2 left loader-div-gif">
                  <pulse-loader
                    :loading="loading"
                    :color="color"
                    :size="size"
                    v-if="sendCustomerLoader"
                  />
                </div>
                <div
                  class="col-md-6"
                  v-if="EditModalDiv"
                >
                  <button
                    type="button"
                    class="btn btn-fill btn-outline-info float-right custom-btn-all"
                    @click="closeModal()"
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary btn-space  float-right custom-btn"
                    data-dismiss="modal"
                  >
                    Edit
                  </button>
                  <button
                    @click.prevent="showAddOrRemoveAttachment"
                    class="btn btn-primary btn-space  float-right custom-btn"
                    data-dismiss="modal"
                    type="submit"
                  >
                    Add / Remove Attachment
                  </button>
                </div>
              </div>
            </form>
          </card>

          <br v-if="ifCustomerSubmitted">

          <div
            class="col-md-12 alert-dismissible fade show"
            v-if="ifCustomerSubmitted"
            role="alert"
            style="border-radius:5px;"
            :class="[ SuccessAlert ? 'alert-success' : '' , ErrorAlert ? 'alert-danger' : '' ]"
            @enter="enter"
          >
            <strong v-if="SuccessAlert">Successifully!</strong> <span v-if="SuccessAlert">{{ SuccessMessage }}</span>
            <strong v-if="ErrorAlert">Error!</strong> <span v-if="ErrorAlert">{{ ErrorMessage }}</span>

            <button
              aria-label="Close"
              class="close close-custom"
              data-dismiss="alert"
              style="line-height:2px;"
              type="button"
              v-if="SuccessAlert"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              aria-label="Close"
              class="close close-custom"
              data-dismiss="alert"
              style="line-height:2px;"
              type="button"
              v-if="ErrorAlert"
              @click="fadeMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <card v-if="ifCustomerSubmitted">
            <div
              class="col-md-12"
              v-if="ifCustomerSubmitted"
            >
              <div class="row">
                <div class="col-md-10">
                  Attach document(s) for supporting customer [ <b>{{ name }}</b> ]
                </div>
                <div class="col-md-2 text-right">
                  <pulse-loader
                    :color="color"
                    :loading="loading"
                    :size="size"
                    v-if="customerAttachmentLoader"
                  />
                </div>
              </div>
            </div>

            <hr v-if="ifCustomerSubmitted">

            <div v-if="ifCustomerSubmitted">
              <table
                class="table table-striped"
                v-if="responseAttachment.length > 0"
              >
                <thead>
                  <tr>
                    <th scope="col">
                      #
                    </th>
                    <th>Attachment Type</th>
                    <th scope="col">
                      Download url
                    </th>
                    <th scope="col">
                      Status
                    </th>
                    <th scope="col">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :key="index"
                    v-for="(attachment , index) in responseAttachment"
                  >
                    <th scope="row">
                      {{ index + 1 }}
                    </th>
                    <td>{{ attachment.attachmentTypesName }}</td>
                    <td
                      class="text-primary"
                      @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)"
                    >
                      <a href=""><span class="text-success">view</span></a>
                    </td>
                    <td class="text-success">
                      Uploaded
                    </td>
                    <td>
                      <div
                        @click.prevent="deleteSingleCustomerAttachment(attachment.customerAttachmentId, name , attachment.attachmentTypesName)"
                        class="all-fa-icons fa-icons-space delete-fa-icon"
                        title="remove"
                      >
                        <font-awesome-icon icon="trash-alt" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <form
              enctype="multipart/form-data"
              v-if="ifCustomerSubmitted"
            >
              <div class="row">
                <div class="col-md-4">
                  <label>Search attachment type</label>
                  <model-list-select
                    :list="afterOptions"
                    v-model="customerAttachmentTypeId"
                    class="form-control"
                    option-value="attachmentTypeId"
                    option-text="attachmentTypesName"
                    placeholder="attachment type"
                  />

                  <div v-if="customerAttachmentTypeIdError">
                    <div
                      class="error"
                      v-if="!$v.customerAttachmentTypeId.required"
                    >
                      Select document type
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="fileUpload btn btn-info btn-fill">
                    <span>Choose Document..</span>
                    <input
                      @change.prevent="EditAttachment($event , customerId , name)"
                      class="upload"
                      id="first_upload2"
                      label="Upload File"
                      name="attachment"
                      type="file"
                    >
                  </div>
                  <label
                    for="first_upload"
                    class="label-upload"
                  >
                    {{ file ? '" ' + file + ' "' : 'No File Selected' }} </label>
                  <div
                    v-if="pdfFormatSingleError"
                    class="red-color"
                  >
                    Attach pdf document only
                  </div>
                </div>
                <div class="col-md-2">
                  <pulse-loader
                    :color="color"
                    :loading="loading"
                    :size="size"
                    class="float-center"
                    style="margin-top: 38px"
                    v-if="uploadOneByOne"
                  />
                </div>
              </div>
            </form>
          </card>

          <div class="text-center row">
            <div
              class="col-md-12"
              v-if="ifCustomerSubmitted"
            >
              <button
                @click="closeAttachModal"
                class="btn btn-fill btn-outline-info btn-space float-right custom-btn-all"
                type="button"
              >
                Close
              </button>
            </div>
          </div>
        </div>

        <!--      ADD FORM      -->
        <div v-if="AddModalDiv">
          <div class="content">
            <div class="col-md-12">
              <card v-if="!ifCustomerSubmitted && !RegisterCustomerUserModalDiv">
                <form
                  method="post"
                  @submit.prevent="saveCustomer"
                  v-if="!ifCustomerSubmitted"
                >
                  <div class="row">
                    <div class="col-md-12 text-center">
                      Please select customer type below (company selected by default)
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4 text-right">
                      <input
                        type="radio"
                        v-model="customer.customerType"
                        name="gender"
                        value="com001"
                      >
                      &nbsp;&nbsp;<span class="text-muted">Company</span>
                      <div v-if="userTypeError">
                        <div
                          class="error"
                          v-if="!$v.customer.customerType.required"
                        >
                          Select atleast one option
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 text-right" />
                    <div class="col-md-4 text-left">
                      <input
                        type="radio"
                        v-model="customer.customerType"
                        name="gender"
                        value="ind001"
                      >
                      &nbsp;&nbsp;<span class="text-muted">Individual</span>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-8">
                      <base-input
                        type="text"
                        label="Full Name"
                        placeholder="Full Name"
                        v-model="customer.name"
                        class="special-bottom"
                      />
                      <div v-if="firstNameError">
                        <div
                          class="error"
                          v-if="!$v.customer.name.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label for="">Phone Number</label>
                      <masked-input class="col-md-12 custom-form-group" placeholder="Phone Number" label="Phone Number" type="tel" mask="(\+\255) 111 - 111 - 111" @input="customer.mobile = arguments[1]"/>
                      <!-- <base-input
                        type="text"
                        label="Mobile Number"
                        placeholder="Mobile Number"
                        v-model="customer.mobile"
                        class="special-bottom"
                      /> -->
                      <div v-if="middleNameError">
                        <div
                          class="error"
                          v-if="!$v.customer.mobile.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <base-input
                        type="email"
                        label="Email Address"
                        placeholder="Email Address"
                        v-model="customer.email"
                        class="special-bottom"
                      />
                      <div v-if="emailError">
                        <div
                          class="error"
                          v-if="!$v.customer.email.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">

                      <label for="">TIN Number</label>
                      <masked-input class="col-md-12 custom-form-group" placeholder="TIN Number" label="TIN Number" type="tel" mask="111 - 111 - 111" @input="customer.tin = arguments[1]"/>
                      <!-- <base-input
                        type="text"
                        label="TIN Number"
                        placeholder="TIN Number"
                        v-model.trim="customer.tin"
                        class="special-bottom"
                      /> -->
                      <div v-if="tinNoError">
                        <div
                          class="error"
                          v-if="!$v.customer.tin.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="VRN Number"
                        placeholder="VRN Number"
                        v-model.trim="customer.vrn"
                        class="special-bottom"
                      />
                      <div v-if="usernameError">
                        <div
                          class="error"
                          v-if="!$v.customer.vrn.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <label>Customer Address Details</label>
                      <textarea
                        class="special-bottom form-control col-md-12"
                        type="textarea"
                        rows="8"
                        placeholder="Address Details"
                        v-model="customer.address"
                      />
                      <div v-if="addressError">
                        <div
                          class="error"
                          v-if="!$v.customer.address.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <br>

                  <div class="text-center row">
                    <div class="col-md-7 text-right loader-div">
                      <span v-if="sendCustomerLoader">Please wait ....</span>
                    </div>
                    <div class="col-md-2 left loader-div-gif">
                      <pulse-loader
                        :loading="loading"
                        :color="color"
                        :size="size"
                        v-if="sendCustomerLoader"
                      />
                    </div>
                    <div
                      class="col-md-3"
                      v-if="AddModalDiv"
                    >
                      <button
                        type="button"
                        class="btn btn-fill btn-outline-info float-right custom-btn-all"
                        @click="closeModal"
                        v-if="!ifCustomerSubmitted"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary btn-space  float-right custom-btn"
                        data-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </card>

              <br v-if="ifCustomerSubmitted">

              <card v-if="ifCustomerSubmitted">
                <div class="row">
                  <div
                    class="col-md-10"
                    v-if="ifCustomerSubmitted"
                  >
                    Attach Supporting Document for customer [ <b>{{ responseUserDetails.name }}</b> ]
                  </div>
                  <div class="col-md-2">
                    <pulse-loader
                      :loading="loading"
                      :color="color"
                      :size="size"
                      v-if="customerAttachmentLoader"
                      style="margin-top: 38px"
                      class="float-center"
                    />
                  </div>
                </div>

                <hr v-if="ifCustomerSubmitted">

                <div v-if="ifCustomerSubmitted">
                  <table
                    class="table table-striped"
                    v-if="responseAttachment.length > 0"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          #
                        </th>
                        <th>Customer Attachment Name</th>
                        <th scope="col">
                          Download url
                        </th>
                        <th scope="col">
                          Status
                        </th>
                        <th scope="col">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(attachment , index) in responseAttachment"
                        :key="index"
                      >
                        <th scope="row">
                          {{ index + 1 }}
                        </th>
                        <td>{{ attachment.attachmentTypesName }}</td>
                        <td
                          class="text-primary"
                          @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)"
                        >
                          <a href=""><span class="text-success">view</span></a>
                        </td>
                        <td class="text-success">
                          Uploaded
                        </td>
                        <td>
                          <div
                            @click.prevent="deleteSingleCustomerAttachment(attachment.customerAttachmentId,responseUserDetails.name , attachment.attachmentTypesName)"
                            class="all-fa-icons fa-icons-space delete-fa-icon"
                            title="remove"
                          >
                            <font-awesome-icon icon="trash-alt" />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <form
                  v-if="ifCustomerSubmitted"
                  enctype="multipart/form-data"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <label>Search attachment type</label>
                      <model-list-select
                        :list="afterOptions"
                        v-model="customerAttachmentTypeId"
                        class="form-control"
                        option-value="attachmentTypeId"
                        option-text="attachmentTypesName"
                        placeholder="attachment type"
                      />
                      <div v-if="customerAttachmentTypeIdError">
                        <div
                          class="error"
                          v-if="!$v.customerAttachmentTypeId.required"
                        >
                          Select document type
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="fileUpload btn btn-info btn-fill">
                        <span>Choose Document..</span>
                        <input
                          type="file"
                          name="attachment"
                          label="Upload File"
                          class="upload"
                          id="first_upload"
                          @change.prevent="saveAttachment"
                        >
                      </div>
                      <label
                        for="first_upload"
                        class="label-upload"
                      >
                        {{ file ? '" ' + file + ' "' : 'No File Selected' }} </label>
                      <div
                        v-if="pdfFormatSingleError"
                        class="red-color"
                      >
                        Attach pdf document only
                      </div>
                    </div>
                    <div class="col-md-2">
                      <pulse-loader
                        :loading="loading"
                        :color="color"
                        :size="size"
                        v-if="uploadOneByOne"
                        style="margin-top: 38px"
                        class="float-center"
                      />
                    </div>
                  </div>
                </form>
              </card>
            </div>

            <div class="text-center row">
              <div
                class="col-md-12"
                v-if="ifCustomerSubmitted"
              >
                <button
                  type="button"
                  class="btn btn-fill btn-outline-info btn-space float-right custom-btn-all"
                  @click="closeModal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <!--      REGISTER ACCOUNT FOR CUSTOMER  -->
        <div v-if="RegisterCustomerUserModalDiv">
          <div class="content">
            <div class="col-md-12">
              <card>
                <form
                  method="post"
                  @submit.prevent="saveCustomerUserAccount"
                >
                  <div class="row">
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="First Name"
                        placeholder="First Name"
                        v-model="firstName"
                        class="special-bottom"
                      />
                      <div v-if="firstNameError">
                        <div
                          class="error"
                          v-if="!$v.firstName.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="Middle Name"
                        placeholder="Middle Name"
                        v-model.trim="middleName"
                        class="special-bottom"
                      />
                      <div v-if="middleNameError">
                        <div
                          class="error"
                          v-if="!$v.middleName.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="Last Name"
                        placeholder="Last Name"
                        v-model.trim="lastName"
                        class="special-bottom"
                      />
                      <div v-if="lastNameError">
                        <div
                          class="error"
                          v-if="!$v.lastName.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4">
                      <base-input
                        type="email"
                        label="Email Address"
                        placeholder="Email Address"
                        v-model="email"
                        class="special-bottom"
                      />
                      <div v-if="emailError">
                        <div
                          class="error"
                          v-if="!$v.email.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="Mobile"
                        placeholder="Mobile"
                        v-model.trim="mobile"
                        class="special-bottom"
                      />
                      <div v-if="mobileError">
                        <div
                          class="error"
                          v-if="!$v.mobile.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <base-input
                        type="text"
                        label="Username"
                        placeholder="Username"
                        v-model.trim="username"
                        class="special-bottom"
                      />
                      <div v-if="usernameError">
                        <div
                          class="error"
                          v-if="!$v.username.required"
                        >
                          This field is required
                        </div>
                      </div>
                    </div>
                  </div>

                  <br>

                  <div class="text-center row">
                    <div class="col-md-7 text-right loader-div">
                      <span v-if="sendCustomerLoader">Please wait ....</span>
                    </div>
                    <div class="col-md-2 left loader-div-gif">
                      <pulse-loader
                        :loading="loading"
                        :color="color"
                        :size="size"
                        v-if="sendCustomerLoader"
                      />
                    </div>
                    <div
                      class="col-md-3"
                      v-if="AddModalDiv"
                    >
                      <button
                        type="button"
                        class="btn btn-fill btn-outline-info float-right custom-btn-all"
                        @click="closeModal"
                        v-if="!ifCustomerSubmitted"
                      >
                        Close
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary btn-space  float-right custom-btn"
                        data-dismiss="modal"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </card>
            </div>
          </div>
        </div>

        <!--      SHOW FORM      -->
        <div v-if="ShowModalDiv">
          <card>
            <div class="row">

              <div class="col-md-12">
                <div class="legend">
                  <h6 class="legend-title">
                    Customer Details
                  </h6>

                  <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                      Name
                    </div>
                    <div class="col-md-9 div-table div-table-value">
                      {{ singleCustomer.name }}
                    </div>
                  </div>
                  <br>
                  <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                      TIN
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                      {{ typeof singleCustomer.tin !== undefined ? hyphenifyTin(singleCustomer.tin) : '' }}
                    </div>
                    <div class="col-md-3 div-table div-table-title">
                      VRN
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                      {{ singleCustomer.vrn }}
                    </div>
                  </div>
                  <br>
                  <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                      Email address
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                      {{ singleCustomer.email }}
                    </div>
                    <div class="col-md-3 div-table div-table-title">
                      Type
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                      {{ singleCustomer.customerType === 'com001' ? 'Company' : 'Individual' }}
                    </div>
                  </div>
                  <br>
                  <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                      Phone Number
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                      {{ singleCustomer.mobile }}
                    </div>
                    <div class="col-md-3 div-table div-table-title">
                      Passport
                    </div>
                    <div class="col-md-3 div-table div-table-value">
                      {{ singleCustomer.passportNumber === '' && singleCustomer.passportNumber === null ? '-' : singleCustomer.passportNumber }}
                    </div>
                  </div>
                  <br>
                  <div class="row div-table-row">
                    <div class="col-md-3 div-table div-table-title">
                      Address
                    </div>
                    <div class="col-md-9 div-table div-table-value">
                      {{ singleCustomer.address === null ? 'NIL' : singleCustomer.address }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b>Associated Attachment(s)</b>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <table
                    class="table table-striped"
                    v-if="responseAttachment.length > 0"
                  >
                    <thead>
                      <tr>
                        <th scope="col">
                          #
                        </th>
                        <th>Attachment Type</th>
                        <th scope="col">
                          Download url
                        </th>
                      <!--<th scope="col">Status</th>-->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(attachment , index) in responseAttachment"
                        :key="index"
                      >
                        <th scope="row">
                          {{ index + 1 }}
                        </th>
                        <td>{{ attachment.attachmentTypesName }}</td>
                        <td
                          class="text-primary"
                          @click.prevent="getFileFromMinio(attachment.attachmentUrl , attachment.attachmentName)"
                        >
                          <a href=""><span class="text-success">view</span></a>
                        </td>
                        <td class="text-success">
                          Uploaded
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div
                    class="table table-striped"
                    v-if="responseAttachment.length === 0"
                  >
                    <div style="margin-left: 15px">
                      No attachment
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <hr>
            <div class="text-center row">
              <div class="col-md-12">
                <button
                  class="btn btn-default btn-fill float-right custom-btn-all"
                  @click="closeModal"
                >
                  Back
                </button>
              </div>
            </div>
          </card>
        </div>

        <!--        </card>-->
      </div>
    </modal>

    <modal v-if="showConfirmModal">
      <div
        class="modal-title col-md-12"
        slot="header"
      />

      <div slot="body">
        <!--      CONFIRM LEAVING WITHOUT ATTACHING ANY DOC   -->
        <div v-if="ShowConfirmModalDiv">
          <card class="text-center">
            <p> {{ attachmentAlertMessage }} </p>

            <br><br><br>

            <div class="text-center row">
              <div class="col-md-12 text-center">
                <button
                  @click="closeModal"
                  class="btn btn-fill btn-outline-info btn-space float-right"
                  type="button"
                >
                  Yes!, <span v-if="attachmentFromEditAction">Close</span>
                  <span v-else>Continue</span>
                </button>
                <button
                  @click="closeBackModal"
                  class="btn btn-success btn-space float-right"
                  type="button"
                >
                  No!
                </button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </modal>
    <global-modal v-if="myLoader">
      <div slot="globalLoader">
        <div class="row text-center">
          <div class="col-md-12">
            LOading customers, Please wait
          </div>
          <div class="col-md-12">
            <br>
          </div>
          <div class="col-md-12">
            <pulse-loader
              :loading="loading"
              :color="color"
              :size="size"
            />
          </div>
        </div>
      </div>
    </global-modal>
  </div>
</template>

<script>

import API from '../../../api'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Modal from '../../../components/Inputs/Modal'
import { required } from 'vuelidate/lib/validators'
import { ModelListSelect } from 'vue-search-select'
import { mapActions, mapGetters } from 'vuex';
import GlobalModal from '../../../components/globalLoader'
import MaskedInput from 'vue-masked-input'


export default {

  name: 'Customer',

  created () {

    this.$store.dispatch('pagenames/currentPageName', 'CFA Customers')
    this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'))
    // this.loadPageableCustomer(this.pageNo, this.pageSize)
    this.searchGeneralParams('', 'onCreated')
    this.loadAllAttachment()

  },

  data () {
    return {

      loader: false,
      color: 'green',
      size: '15px',
      loading: true,
      sendCustomerLoader: false,
      attachLoader: false,
      uploadOneByOne: false,
      showSection: true,
      showConfirmModal: false,
      ShowConfirmModalDiv: false,
      attachmentAlertMessage: '',
      uploadedMessage: '',

      pageCount: 1,
      docPageNo: 0,
      docPageCount: 0,
      docSortBy: 'id',
      docPageSize: 0,
      getPageableDoc: 0,
      searchParameter: '',
      hasSearchedVariable: false,
      reloadAllRequest: false,
      noDataFound: false,

      customer: {

        name: '',
        email: '',
        vrn: '',
        mobile: '',
        address: '',
        tin: '',
        customerType: 'com001'

      },

      user: {},
      customerType: '',

      customerAttachmentTypeId: '',
      customerName: '',
      file: '',
      fileData: '',
      options: [],
      getCustomerAttachmentType: [],
      responseAttachment: [],
      responseAllAttachment: [],
      customers: [],
      finalUserPermissions: [],
      permissionGroups: [],
      afterOptions: [],
      customerAttachment: {},
      singleCustomer: {},

      customerId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      username: '',
      mobile: '',
      address: '',
      tin: '',

      date: '',

      responseUserDetails: {},

      SuccessMessage: '',
      ErrorMessage: '',
      SuccessAlert: false,
      ErrorAlert: false,
      addFlag: false,

      showModal: false,
      myLoader: false,
      editMyLoader: false,
      RegisterCustomerUserModalDiv: false,
      EditModalDiv: false,
      AddModalDiv: false,
      ShowModalDiv: false,
      ifCustomerSubmitted: false,
      loadingShowCustomer: false,
      hasUserUploadedAnyThing: false,
      attachmentFromEditAction: false,
      customerAttachmentTypeIdError: false,
      pdfFormatSingleError: false,
      customerAttachmentLoader: false,
      firstNameError: false,
      middleNameError: false,
      lastNameError: false,
      usernameError: false,
      mobileError: false,
      addressError: false,
      tinNoError: false,
      emailError: false,
      userTypeError: false,
      globalLoader: false,

      tableHeaders: ['Name', 'tin', 'vrn','mobile', 'email address', 'Customer Type', 'Passport'],
      tableDataKeys: ['name', 'tin', 'vrn','mobile', 'email', 'customerType', 'passportNumber'],
      pageNo: 0,
      pageSize: 15,
      totalElements: "",
      totalPages: "",
      isFirst: false,
      isLast: false,
      pageNumber: '',
      isPage: 0,
      pageNoPart: 0,
      filteredRequests: [],
      myFirstLoader: false,
      myloader: false,
      whichModuleAndType: 'customerList',
      customerNameFilter: '',
      customerDetailsPopulated: {}

    }
  },

  computed: {

    ...mapGetters('customers', [

      'getValuesBeforeEdit',
      'getNameBeforeDelete',
      'getCustomerId',
      'getAttachmentNameBeforeDelete',
      'getCustomerNameBeforeDelete',
      'getRequestList',
      'getPageCount',
      'getMyFirstLoader',
      'getNoDataFound',
      'getMyLoader',
      'getPageNo',
      'getPageSize',
      'getRequestId',
      'getTotalElement',
      'getTotalPage',
      'getPageNumber',
      'getIsFirst',
      'getIsLast',
      'getSearchParams'

    ]),

    ...mapGetters('global', [

      'getGlobalLoader'

    ]),

    getIsPage: {

      get: function () {
        return this.isPage
      },
      set: function (newValue) {
        this.isPage = newValue;
      }

    }

  },

  validations: {

    customer: {

      name: { required },
      email: { required },
      vrn: { required },
      mobile: { required },
      address: { required },
      tin: { required },
      customerType: { required }

    },

    customerType: { required },
    customerId: { required },
    firstName: { required },
    middleName: { required },
    lastName: { required },
    email: { required },
    username: { required },
    mobile: { required },
    address: { required },
    name: { required },
    vrn: { required },
    tin: { required },
    customerAttachmentTypeId: { required }

  },

  components: {

    PulseLoader,
    Modal,
    MaskedInput,
    ModelListSelect,
    GlobalModal,
    DataTable: () => import('../../../components/DataTable/dataTable')

  },

  methods: {

    ...mapActions('global', [

      'loadFileFromMinio'

    ]),

    ...mapActions('customers', [

      'loadCfaCustomers'

    ]),

    sortingData(data) {

      for(let x = 0 ; x < data.length ; x++) {

        const requestObject = {
          ...data[x],
          tin: data[x].tin !== null && data[x].tin !== '' && data[x].tin !== 'NIL' ? this.hyphenifyTin(data[x].tin) : '',
          vrn: data[x].vrn !== null && data[x].vrn !== '' && data[x].vrn !== 'NIL' ? data[x].vrn : '',
          customerType: data[x].customerType === 'com001' ? 'Company' : 'Individual',
          passportNumber: data[x].passportNumber !== null && data[x].passportNumber !== '' ? data[x].passportNumber : ''
          }

          this.filteredRequests.push(requestObject)

      }

    },

    loadParameters (fileUrl, fileName) {

      return new Promise((resolve) => {

        this.$store.dispatch('global/fileUrling', fileUrl)
        this.$store.dispatch('global/fileNaming', fileName)

        resolve()
      })

    },

    getFileFromMinio (fileUrl, fileName) {

      this.loadParameters(fileUrl, fileName).then(() => {

      this.loadFileFromMinio({}).then((res) => {

          if(res.status === 204) {

              this.loadNotification('warn', 4000, 'File Download',
              'File not found, please contact system admin',
              'response', 1000 , true  , true)

          } else {

            this.loadNotification('success', 4000, 'File Download',
              'File has been successfully downloaded, please have a look at it',
              'response', 1000 , true  , true)


          }

            this.globalLoader = this.getGlobalLoader

        }).catch(() => {

          this.loadNotification('error', 4000, 'File Download',
              'File not downloaded, please check your internet connection',
              'response', 1000 , true  , true)

          this.globalLoader = this.getGlobalLoader

        })

        this.globalLoader = this.getGlobalLoader

      }).catch(() => {

      })

    },

    goToApplication () {},

    goToPage(page) {

        if(page > 0) {page = page - 1;}
        this.isPage = page + 1

        this.pageNo = page;

        if (this.customerNameFilter !== '') {

            this.searchGeneralParams(this.customerNameFilter, 'afterCreated')
            this.filteredRequests = []

        } else {

            this.searchGeneralParams('', 'afterCreated')
            this.filteredRequests = []
        }

    },

    onChange (event) {

        this.$store.dispatch("customers/pageSizing", event);
        this.$store.dispatch("customers/pageNumbering", 0);
        this.isPage = 0

        this.pageSize = event;
        this.pageNo = 0;

        if (this.customerNameFilter !== '') {

            this.filteredRequests = []
            this.searchGeneralParams(this.customerNameFilter, 'afterCreated')

        } else {

            this.filteredRequests = []
            this.searchGeneralParams('', 'afterCreated')
        }

    },

    resetParams () {

      this.searchGeneralParams('', 'onCreated')
      this.customerNameFilter = ''

    },

    loadSearchParameters(paramsValue) {

      let params

      if(paramsValue === '') {

        params = ''

      } else {

        params = paramsValue

      }

      return new Promise((resolve) => {

        this.$store.dispatch("customers/pageSizing", this.pageSize);
        this.$store.dispatch("customers/pageNumbering", this.pageNo);
        this.$store.dispatch("customers/paramsSearching", params);

        resolve();

      })

    },

    searchGeneralParams(params, callerType) {

      this.newParams = params

      this.filteredRequests = []

      this.loadSearchParameters(params).then(() => {

        this.loadCfaCustomers({callerType}).then(() => {

          this.setAllGetters(callerType)

        }).catch(() => {


        });

        callerType === 'afterCreated' ? this.myLoader = this.getMyLoader : this.myFirstLoader = this.getMyFirstLoader

      }).catch(() => {


      });

    },

    setAllGetters(flag) {

      this.requests = this.getRequestList;
      flag === 'afterCreated' ? this.myLoader = this.getMyLoader : '';
      flag === 'onCreated' ? this.myFirstLoader = this.getMyFirstLoader : '';
      this.noDataFound = this.getNoDataFound;
      this.pageNo = this.getPageNo;
      this.pageSize = this.getPageSize;

      this.totalElements = this.getTotalElement;
      this.totalPages = this.getTotalPage;
      this.pageNumber = this.getPageNumber;
      this.isFirst = this.getIsFirst;
      this.isLast = this.getIsLast;

      this.sortingData(this.requests)

    },

    toggle () {
      this.showSection = !this.showSection
    },

    loadCustomerAll () {
      API.getCustomerAll().then(
        response => {
          if (response.data.status) {
            this.customers = response.data.data
          } else {

          }
        }
      ).catch(e => {

      })
    },

    loadSingleCustomer (data) {
      this.loadingShowCustomer = true

      API.getSingleCustomer(data).then(
        response => {
          if (response.data.status) {
            this.loadingShowCustomer = false

            this.singleCustomer = response.data.data
            this.loadCustomerAttachment(response.data.data.customerId)
          } else {

          }
        }
      ).catch(e => {
        this.loadingShowCustomer = false
      })
    },

    loadAllAttachment () {
      API.getAttachments().then(
        response => {
          if (response.data.status) {
            this.options = response.data.data

            for (let k = 0; k < this.options.length; k++) {
              if (this.options[k].attachmentGroupCode === 'CRA') {
                const object = {

                  attachmentTypeId: this.options[k].attachmentTypeId,
                  attachmentTypesName: this.options[k].attachmentTypesName

                }

                this.afterOptions.push(object)
              }
            }
          } else {

          }
        }
      ).catch(e => {

      })
    },

    showAddOrRemoveAttachment () {
      this.ifCustomerSubmitted = true
      this.hasUserUploadedAnyThing = true
      this.loadCustomerAttachment(this.customerId)
      this.attachmentFromEditAction = true
    },

    loadCustomerAttachmentType (data) {
      API.getCustomerAttachments(data).then(
        response => {
          if (response.data.status) {
            this.options = response.data.data
          } else {

          }
        }
      ).catch(e => {

      })
    },

    saveCustomer () {

      if (this.$v.customer.name.$invalid || this.$v.customer.customerType.$invalid ||
          this.$v.customer.mobile.$invalid || this.$v.customer.address.$invalid || this.$v.customer.tin.$invalid ||
          this.$v.customer.email.$invalid) {
        if (this.$v.customer.name.$invalid) {
          this.firstNameError = true
        }
        // if (this.$v.customer.vrn.$invalid) {
        //   this.usernameError = true
        // }
        if (this.$v.customer.customerType.$invalid) {
          this.userTypeError = true
        }
        if (this.$v.customer.mobile.$invalid) {
          this.middleNameError = true
        }
        if (this.$v.customer.address.$invalid) {
          this.addressError = true
        }
        if (this.$v.customer.tin.$invalid) {
          this.tinNoError = true
        }
        if (this.$v.customer.email.$invalid) {
          this.emailError = true
        }
      } else {

        if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.customer.email))) {

            this.loadNotification('error', 5000, '', 'Invalid email address', 'response', 1000 , true  , true)

        } else if (isNaN(this.customer.mobile)) {

            this.loadNotification('error', 5000, '', 'Invalid phone number', 'response', 1000 , true  , true)

        } else if (isNaN(this.customer.tin)) {

            this.loadNotification('error', 5000, '', 'Invalid TIN number', 'response', 1000 , true  , true)

        } else {

          this.sendCustomerLoader = true;
          let customer = {

              name:this.customer.name.trim(),
              email: this.customer.email.trim(),
              vrn: this.customer.vrn !== '' ? this.customer.vrn.trim() : '',
              mobile: '255' + this.customer.mobile.trim(),
              address: this.customer.address.trim(),
              tin: this.customer.tin.trim(),
              customerType: this.customer.customerType//'com001'

          };

          API.postCustomer(JSON.stringify(customer)).then(
            response => {

              if (response.data.status) {

                this.sendCustomerLoader = true
                this.responseUserDetails = response.data.data
                this.customer.name = ''
                this.customer.email = ''
                this.customer.vrn = ''
                this.customer.mobile = ''
                this.customer.address = ''
                this.customer.tin = ''
                this.loadNotification('success', 4000, '','Customer has been successfully saved', 'response', 1000 , true  , true)
                this.loadCustomerAll()

                this.$store.dispatch('customers/customerid', this.responseUserDetails.customerId)

                setTimeout(() => {

                  this.ifCustomerSubmitted = true
                  this.sendCustomerLoader = false
                  this.hasUserUploadedAnyThing = true
                }, 2000)

              } else {

                this.loadNotification('error', 4000, '',response.data.message, 'response', 1000 , true  , true)

              }
            }
          ).catch(() => {

            this.sendCustomerLoader = false
            this.loadNotification('error', 4000, '','Customer not saved', 'response', 1000 , true  , true)
            this.closeModal()

          })

        }

      }

    },

    continueToCustomerAccountModal () {
      this.RegisterCustomerUserModalDiv = true
      this.ifCustomerSubmitted = false
    },

    addCustomerAccount (data, name) {

      this.AddModalDiv = true
      this.showModal = true
      this.RegisterCustomerUserModalDiv = true
      this.ifCustomerSubmitted = false
      this.customerId = data
      this.customerName = name
      this.addFlag = true

    },

    saveCustomerUserAccount () {

      if (this.$v.username.$invalid || this.$v.firstName.$invalid || this.$v.middleName.$invalid ||
          this.$v.lastName.$invalid || this.$v.email.$invalid || this.$v.mobile.$invalid) {
        if (this.$v.username.$invalid) {
          this.usernameError = true
        }
        if (this.$v.firstName.$invalid) {
          this.firstNameError = true
        }
        if (this.$v.middleName.$invalid) {
          this.middleNameError = true
        }
        if (this.$v.lastName.$invalid) {
          this.lastNameError = true
        }
        if (this.$v.email.$invalid) {
          this.emailError = true
        }
        if (this.$v.mobile.$invalid) {
          this.mobileError = true
        }
      } else {
        this.sendCustomerLoader = true

        if (this.addFlag) {
          this.user = {

            customerId: this.customerId,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            middleName: this.middleName,
            name: null,
            phone: this.mobile,
            tin: null,
            type: 'customer',
            username: this.username

          }
        } else {
          this.user = {

            customerId: this.responseUserDetails.customerId,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            middleName: this.middleName,
            name: null,
            phone: this.mobile,
            tin: null,
            type: 'customer',
            username: this.username

          }
        }

        // setTimeout(() => {

        API.postUser(JSON.stringify(this.user)).then(
          response => {
            if (response.data.status) {
              
              this.user.firstName = ''
              this.user.email = ''
              this.user.lastName = ''
              this.user.middleName = ''
              this.user.phone = ''
              this.user.username = ''
              this.closeModal()
              this.sendCustomerLoader = false
              this.loadNotification('success', 4000, '','User for this customer has been successfully saved', 'response', 1000 , true  , true)

            } else {

              this.loadNotification('error', 4000, '',response.data.message, 'response', 1000 , true  , true)

            }
          }
        ).catch(e => {

          this.editMyLoader = false
          this.editMyLoader = false
          this.closeModal()
          this.loadNotification('error', 4000, '','User for this customer not saved', 'response', 1000 , true  , true)

        })

        // }, 2000);
      }
    },

    editCustomer () {

      if (this.$v.name.$invalid || this.$v.customerType.$invalid ||
          this.$v.mobile.$invalid || this.$v.address.$invalid || this.$v.tin.$invalid || this.$v.email.$invalid) {
        if (this.$v.name.$invalid) {
          this.firstNameError = true
        }
        if (this.$v.customerType.$invalid) {
          this.userTypeError = true
        }
        if (this.$v.mobile.$invalid) {
          this.middleNameError = true
        }
        if (this.$v.address.$invalid) {
          this.addressError = true
        }
        if (this.$v.tin.$invalid) {
          this.tinNoError = true
        }
        if (this.$v.email.$invalid) {
          this.emailError = true
        }
      } else {

        this.mobile = this.mobile.toString().replace(/\+/g, '')
        this.mobile = this.mobile.toString().replace(/\(/g, '')
        this.mobile = this.mobile.toString().replace(/\)/g, '')
        this.mobile = this.mobile.toString().replace(/\ /g, '')
        this.mobile = this.mobile.toString().replace(/\-/g, '')

        this.tin = this.tin.toString().replace(/\ /g, '')
        this.tin = this.tin.toString().replace(/\-/g, '')

        if(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))) {

            this.loadNotification('error', 5000, '', 'Invalid email address', 'response', 1000 , true  , true)

        } else if (isNaN(this.mobile)) {

            this.loadNotification('error', 5000, '', 'Invalid phone number', 'response', 1000 , true  , true)

        } else if (isNaN(this.tin)) {

            this.loadNotification('error', 5000, '', 'Invalid TIN number', 'response', 1000 , true  , true)

        } else {

          this.sendCustomerLoader = true

          const customerData = {

            customerId: this.customerId,
            name: this.name,
            email: this.email,
            vrn: this.vrn,
            mobile: this.mobile,
            address: this.address,
            tin: this.tin,
            customerType: this.customerType

          }

          API.updateSingleCustomer(JSON.stringify(customerData)).then(
            response => {
              if (response.data.status) {
                
                this.sendCustomerLoader = false
                this.vrn = ''
                this.tin = ''
                this.name = ''
                this.email = ''
                this.username = ''
                this.mobile = ''
                this.address = ''
                this.loadCustomerAll()
                window.scrollTo(10, 0)
                this.closeModal()
                this.loadNotification('success', 4000, '','Customer has been successfully updated', 'response', 1000 , true  , true)
                this.$store.dispatch('customers/valuesBeforeEdit', [])
                this.$store.dispatch('customers/customerid', response.data.data.customerId)

              } else {

                this.loadNotification('error', 4000, '',response.data.message, 'response', 1000 , true  , true)

              }
            }
          ).catch(e => {
            
            this.sendCustomerLoader = false
            this.customer.firstName = ''
            this.customer.middleName = ''
            this.customer.lastName = ''
            this.customer.email = ''
            this.customer.username = ''
            this.customer.mobile = ''
            this.customer.address = ''
            this.closeModal()
            this.loadNotification('error', 4000, '','Customer not updated', 'response', 1000 , true  , true)

          })

        }

      }
    },

    loadCustomerAttachment (data) {
      this.customerAttachmentLoader = true

      API.getCustomerAttachments(data).then(
        response => {
          if (response.data.status) {
            this.customerAttachmentLoader = false
            this.responseAttachment = response.data.data
          } else {

          }
        }
      ).catch(e => {
        this.ErrorMessage = ', Customer attachment not loaded, please contact system administrator'
        this.SuccessAlert = false
        this.ErrorAlert = true
        this.SuccessMessage = ''
        this.SuccessAlert = false
      })
    },

    saveAttachment (event) {
      this.fileData = event.target.files[0]

      if (this.$v.customerAttachmentTypeId.$invalid || this.fileData.type !== 'application/pdf') {
        if (this.$v.customerAttachmentTypeId.$invalid) {
          this.customerAttachmentTypeIdError = true
        }
        if (this.fileData.type !== 'application/pdf') {
          this.pdfFormatSingleError = true
        }
      } else {
        this.uploadOneByOne = true

        this.file = this.fileData.name

        const formData = new FormData() // URLSearchParams

        formData.append('file', this.fileData)
        formData.append('customerId', this.getCustomerId)
        formData.append('customerAttachmentTypeId', this.customerAttachmentTypeId)

        // for (let key of formData.entries()) {
        //   console.log(key[0] + ', ' + key[1])
        // }

        // setTimeout(() => {

        API.uploadCustomerAttachment(formData).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ' Customer [ ' + this.responseUserDetails.name + ' ] has been saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.customerAttachmentTypeId = ''
              this.file = ''
              this.loadCustomerAttachment(this.getCustomerId)
              this.uploadOneByOne = false

              setTimeout(() => {
                this.uploadedMessage = ''
              }, 7000)

              // this.closeModal();
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Attachment not uploaded, please contact system administrator'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.attachLoader = false
          // this.closeModal();
        })

        // }, 2500);
      }
    },

    EditAttachment (event, customerId, customerName) {
      this.fileData = event.target.files[0]

      if (this.$v.customerAttachmentTypeId.$invalid === '' || this.fileData.type !== 'application/pdf') {
        if (this.$v.customerAttachmentTypeId.$invalid) {
          this.customerAttachmentTypeIdError = true
        }
        if (this.fileData.type !== 'application/pdf') {
          this.pdfFormatSingleError = true
        }
      } else {
        this.uploadOneByOne = true

        this.file = this.fileData.name

        const formData = new FormData()

        formData.append('file', this.fileData)
        formData.append('customerId', customerId)
        formData.append('customerAttachmentTypeId', this.customerAttachmentTypeId)

        // for (let pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }

        // setTimeout(() => {

        API.uploadCustomerAttachment(formData).then(
          response => {
            if (response.data.status) {
              this.SuccessMessage = ', Attachment [ ' + this.file + ' ] for customer [ ' + customerName + ' ] ' +
                  'has been saved'
              this.SuccessAlert = true
              this.ErrorMessage = ''
              this.ErrorAlert = false
              this.fileData = ''
              this.customerAttachmentTypeId = ''
              this.loadCustomerAttachment(response.data.data.customerId)
              this.pdfFormatSingleError = false
              this.customerAttachmentTypeIdError = false
              this.uploadOneByOne = false
              this.file = ''

              setTimeout(() => {
                this.uploadedMessage = ''
              }, 7000)
            } else {

            }
          }
        ).catch(e => {
          this.editMyLoader = false
          this.ErrorMessage = ' Attachment not uploaded, please contact system administrator'
          this.SuccessMessage = ''
          this.SuccessAlert = false
          this.ErrorAlert = true
          this.uploadOneByOne = false
        })

        // }, 2500);
      }
    },

    deleteSingleCustomer (data, name) {
      this.$store.dispatch('customers/nameBeforeDelete', name)

      this.$dialog
        .confirm(
          'If you delete Customer [ ' + name + " ], it'll be gone forever.",
          {
            loader: true
          }
        )
        .then(dialog => {
          // setTimeout(() => {

          API.deleteCustomer(data)
            .then(response => {
              if (response.data.status) {
                this.SuccessMessage = ' Customer [ ' + this.getNameBeforeDelete + ' ] has been deleted'
                this.$store.dispatch('customers/nameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.loadCustomerAll()
                window.scrollTo(10, 0)
              } else {

              }
            })
            .catch(e => {
              this.ErrorMessage = ', Customer not deleted, please contact system administrator'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
            })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    fadeMe: function () {
      if (this.SuccessAlert) {
        this.SuccessAlert = !this.SuccessAlert
      } else {
        this.ErrorAlert = !this.ErrorAlert
      }
    },

    enter: function (el, done) {
      const that = this

      setTimeout(function () {
        that.SuccessAlert = false
        that.ErrorAlert = false
      }, 3000) // hide the message after 3 seconds
    },

    openModal (customerDetails = null, actionButton) {
      if (actionButton === 'EditModal') {
        this.customerId = customerDetails.customerId
        this.vrn = customerDetails.vrn
        this.tin = this.removeHyphen(customerDetails.tin)
        this.name = customerDetails.name
        this.email = customerDetails.email
        this.customerType = customerDetails.customerType === 'Company' ? 'com001' : 'ind001'
        this.mobile = (customerDetails.mobile).split("255").pop()
        console.log('this.mobile', this.mobile)
        this.address = customerDetails.address
        this.EditModalDiv = true
        this.showModal = true
        this.AddModalDiv = false
        this.ShowModalDiv = false
        this.RegisterCustomerUserModalDiv = false
        this.$store.dispatch('customers/valuesBeforeEdit', [this.name, this.tin, this.vrn, this.email,
          this.address, this.mobile, this.customerType])
      } else if (actionButton === 'AddModal') {
        this.EditModalDiv = false
        this.AddModalDiv = true
        this.ShowModalDiv = false
        this.showModal = true
        this.RegisterCustomerUserModalDiv = false
      } else {
        this.customerDetailsPopulated = customerDetails
        this.loadSingleCustomer(customerDetails.customerId)
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.ShowModalDiv = true
        this.showModal = true
        this.RegisterCustomerUserModalDiv = false
      }
    },

    deleteSingleCustomerAttachment (data, customerName, AttachmentName) {
      this.$store.dispatch('customers/customerNameBeforeDelete', customerName)
      this.$store.dispatch('customers/attachmentNameBeforeDelete', AttachmentName)

      this.$dialog
        .confirm(
          'If you delete attachment [ ' +
            AttachmentName +
            " ], it'll be gone forever.",
          {
            loader: true
          }
        )
        .then(dialog => {
          // setTimeout(() => {

          API.deleteCustomerAttachment(data)
            .then(response => {
              if (response.data.status) {
                this.SuccessMessage = ', Attachment [ ' + this.getAttachmentNameBeforeDelete + '] has been removed ' +
                    'from customer  [ ' + this.getCustomerNameBeforeDelete + ' ]'
                this.$store.dispatch('customers/customerNameBeforeDelete', '')
                this.$store.dispatch('customers/attachmentNameBeforeDelete', '')
                this.ErrorMessage = ''
                this.SuccessAlert = true
                this.ErrorAlert = false
                this.loadCustomerAttachment(this.customerId)
                window.scrollTo(10, 0)
              } else {

              }
            })
            .catch(e => {
              this.ErrorMessage = ' Record not deleted, please contact system administrator'
              this.SuccessMessage = ''
              this.SuccessAlert = false
              this.ErrorAlert = true
            })

          dialog.close()
          // }, 2500);
        })
        .catch(() => {
          this.myloader = false
          this.SuccessAlert = false
        })
    },

    openConfirmModal () {
      this.showConfirmModal = true
      this.ShowConfirmModalDiv = true

      if (this.customerAttachmentTypeId !== '') {
        API.getIndividualAttachment(this.customerAttachmentTypeId).then(
          response => {
            if (response.data.status) {
              this.getAttachmentTypeName = response.data.data.attachmentTypesName

              this.attachmentAlertMessage = 'You have selected attachment [ ' + this.getAttachmentTypeName + ' ] ' +
                  'but you have not uploaded any document, do you really want to close?'
            } else {
              this.attachmentAlertMessage = "It's seems you are trying to attach document for unknown attachment " +
                  'type, please contact system administrator'
            }
          }
        ).catch(e => {

          // this.state.errors.push(e)

        })
      } else {
        this.attachmentAlertMessage = "Don't you have any other document(s) to attach?"
      }
    },

    closeBackModal () {
      this.ShowConfirmModalDiv = false
      this.showConfirmModal = false

      this.documentTypeIdSingle = ''
      this.documentTypeId = []
      this.attachmentAlertMessage = ''
    },

    closeAttachModal () {
      if (this.hasUserUploadedAnyThing) {
        this.openConfirmModal()
      } else {
        this.showModal = false
        this.EditModalDiv = false
        this.AddModalDiv = false
        this.RegisterCustomerUserModalDiv = false
        this.ifCustomerSubmitted = false

        this.addressError = false
        this.ShowModalDiv = false

        this.ShowConfirmModalDiv = false
        this.showConfirmModal = false

        this.customer.firstName = ''
        this.customer.middleName = ''
        this.customer.lastName = ''
        this.customer.email = ''
        this.customer.username = ''
        this.customer.mobile = ''
        this.customer.address = ''

        this.firstNameError = false
        this.middleNameError = false
        this.lastNameError = false
        this.usernameError = false
        this.mobileError = false
        this.tinNoError = false
        this.emailError = false
        this.name = ''

        this.singleCustomer = {}

        // after upload empty state
        this.$store.dispatch('customers/customerid', '')
      }
    },

    closeModal () {
      if (!this.attachmentFromEditAction) {
        this.continueToCustomerAccountModal()
      }

      this.showModal = false
      this.EditModalDiv = false
      this.AddModalDiv = false
      this.RegisterCustomerUserModalDiv = false
      this.ifCustomerSubmitted = false

      this.addressError = false
      this.ShowModalDiv = false

      this.ShowConfirmModalDiv = false
      this.showConfirmModal = false

      this.customer.firstName = ''
      this.customer.middleName = ''
      this.customer.lastName = ''
      this.customer.email = ''
      this.customer.username = ''
      this.customer.mobile = ''
      this.customer.address = ''

      this.firstNameError = false
      this.middleNameError = false
      this.lastNameError = false
      this.usernameError = false
      this.mobileError = false
      this.tinNoError = false
      this.emailError = false
      this.name = ''
      this.email = ''
      this.mobile = ''
      this.customerAttachmentTypeId = ''

      this.singleCustomer = {}

      // after upload empty state
      this.$store.dispatch('customers/customerid', '')
    }

  }

}

</script>

<style scoped>

  .custom-btn {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
      padding: 5px 9px 5px 9px !important;
      font-size: 14px !important;
      width: auto;
      background-color: #FFFFFF !important;
  }

  .custom-form-group {
    background-color: #FFFFFF !important;
    border: 1px solid #E3E3E3 !important;
    border-radius: 4px !important;
    color: #565656 !important;
    padding: 8px 12px !important;
    height: 40px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

  .btn-space {
    margin-right: 10px !important;
  }

  .fileUpload {
    position: relative;
    overflow: hidden;
    margin: 0.5em 5px;
    font-size: 1em;
    letter-spacing: 0.02em;
    line-height: 1.26;
    text-decoration: none;
    top: 23px;
    font-weight: normal;
  }

  .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  label {
    position: relative;
    top: -7px;
    right: 0;
    margin: 0;
    padding: 0;
  }

  .label-upload {
    position: relative;
    top: 23px;
  }

  .fileUpload span {
    cursor: pointer;
  }

  .special-bottom {
    margin-bottom: -1px;
  }

  ul.nav-justified {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: #ededee;
        border-radius: 3px;
    }

    ul.nav-justified li {
        float: left;
    }

    ul.nav-justified li a:hover {
        color: #d59a18;
    }

    .text-link {
        color: blue;
    }

    .text-link:hover {
        color: #d59a18;
        cursor: pointer;
    }

    ul.nav-justified li a {
        display: block;
        text-align: center;
        padding: 16px 20px 10px 21px;
        text-decoration: none;
        color: blue;
        font-weight: bold;
        text-transform: uppercase;
    }

    .btn-success-custom:hover {
        border-color: #008000;
        color: #008000;
        background-color: #ffffff;
    }

    .btn-success-custom {
        border-color: rgba(0, 128, 0, .5);
        color: rgba(0, 128, 0, .5);
    }

    .btn-success-custom-fill {
        background-color: rgba(0, 128, 0, .8);
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-purple:hover {
        border-color: #800080;
        color: #800080;
        background-color: #ffffff;
    }

    .btn-purple {
        border-color: #9932cc;
        color: #9932cc;
    }

    .btn-purple-fill {
        background-color: #9932cc;
        opacity: 1;
        color: #FFFFFF;
    }

    .btn-danger-custom:hover {
        border-color: #ff0000;
        color: #ff0000;
        background-color: #ffffff;
    }

    .btn-danger-custom {
        border-color: rgba(255, 0, 0, .5);
        color: rgba(255, 0, 0, .5);
    }

    .btn-danger-custom-fill {
        background-color: rgba(255, 0, 0, .5);
        opacity: 1;
        color: #FFFFFF;
    }

    .legend {
        position: relative;
        border: 1px solid #DCDCDC;
        padding: 1.5rem 1rem 1rem 1rem;
        margin-bottom: 1rem;
        border-radius: 3px;
        margin-top: 1rem;
    }

    .legend-title {
        background-color: inherit;
        position: absolute;
        top: -0.6rem;
        padding: 0.2rem 1rem;
        background-color: white;
        color: #d59a18;
    }
  .div-table-row {
          padding: 0 15px 0 15px;
    }

    .div-table {
        padding: 9px 8px 9px 8px;
    }

    .div-table-title {
        width: 250px;
        background-color: rgba(153, 186, 221, .5);
        font-weight: normal;
    }

    .div-table-value {
        border: 1px solid #DCDCDC;
        font-weight: 900;
    }

    .custom-table, .custom-table th, .custom-table td {
        border: 1px solid #DCDCDC;
        border-collapse: collapse;
    }

    .custom-table th, .custom-table td {
        padding: 8px;
        text-align: left;
    }

</style>
