<template>
  <div @click="clearLocalStorage">
    <div
      class="menu-title row"
      @click="toggleMenu('sa-operations')"
      title="Shipping Agency Operations"
      v-if="finalUserPermissions.includes('SA_OPERATIONS')"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="tally_export"
          :has-fill="true"
        />
        <span class="menu-title-name">AGENCY - OPERATIONS</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openSaOperationsMenu"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openSaOperationsMenu"
        />
      </div>

    </div>
    <transition name="list">
      <div
        v-if="openSaOperationsMenu"
        class="menu-value"
      >
        <sidebar-link :to="{ name: 'VesselList' }" v-if="finalUserPermissions.includes('VESSEL_ADD_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Marine Vessels</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'PrincipalDetails' }" v-if="finalUserPermissions.includes('PRINCIPAL_ADD_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Vessel Principals</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'VesselCalls' }" v-if="finalUserPermissions.includes('VESSEL_ADD_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> Marine Vessel Call Notifications</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'subdisbursemenList' }" v-if="finalUserPermissions.includes('DISBURSEMENT_SUB_LIST_GET')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> Sub Disbursements</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'disbursementDeposit' }" v-if="finalUserPermissions.includes('DISBURSEMENT_PAYMENT_ALL_GET')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> Disbursement Account Deposits </p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'deliveryOrdersRequests' }" v-if="finalUserPermissions.includes('DISBURSEMENT_PAYMENT_ALL_PAGEABLE_GET')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> Delivery Order Requests</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'checkDeliveryOrders' ,params:{status:'CREATED'}}" v-if="finalUserPermissions.includes('DELIVERY-ODER_REQUEST_STATUS_UPDATE_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Check Delivery Order</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'checkDeliveryOrders',params:{status:'CHECKED'} }" v-if="finalUserPermissions.includes('DELIVERY-ODER_REQUEST_STATUS_UPDATE_POST')">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Approve Delivery Order</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'shipping-agency-customer' }">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Delivery Order Applications</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'deliveryOrders' }">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p> Delivery Orders </p>
        </sidebar-link>

      </div>
    </transition>
    <div
      class="menu-title row"
      @click="toggleMenu('app')"
      title="Shipping Agency - Outbound"
      v-if="finalUserPermissions.includes('SA_OUTBOUND')"
    >
      <div class="col-md-10">
        <svg-icon
          class="nc-icon"
          icon="shipping_agency_export"
          :has-fill="true"
        />
        <span class="menu-title-name">SHIPPING ORDER</span>
      </div>
      <div class="col-md-2">
        <svg-icon
          class="nc-icon-page"
          icon="down_arrow"
          :has-fill="true"
          v-show="openApplicationMenu"
        />
        <svg-icon
          class="nc-icon-page"
          icon="right_arrow"
          :has-fill="true"
          v-show="!openApplicationMenu"
        />
      </div>
    </div>
    <transition name="list">
      <div
        v-if="openApplicationMenu"
        class="menu-value"
      >
        <sidebar-link :to="{ name: 'bookingAplication' }">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Booking Application</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'shippingorderList' }">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Received Applications</p>
        </sidebar-link>
        <sidebar-link :to="{ name: 'billoflading' }">
          <svg-icon
            class="nc-icon-inner"
            icon="circle"
            :has-fill="true"
          />
          <p>Bill of Ladings</p>
        </sidebar-link>
<!--        <sidebar-link :to="{ name: 'myAssignments' }">-->
<!--          <svg-icon-->
<!--            class="nc-icon-inner"-->
<!--            icon="circle"-->
<!--            :has-fill="true"-->
<!--          />-->
<!--          <p>My Assignments</p>-->
<!--        </sidebar-link>-->

      </div>
    </transition>
  </div>
</template>

<script>

  import SvgIcon from '../../components/SvgLoader/svgLoader';

  export default {

    name: 'ShippingAgency',

    data() {
      return {

        finalUserPermissions: [],
        openMenuExport: false,
        openMenuImport: false,
        openApplicationMenu: false,
        openSaOperationsMenu: false
      };
    },

    components: {
      SvgIcon
    },

    created() {
      this.finalUserPermissions = JSON.parse(localStorage.getItem('browser-local-permits'));

      this.openMenuImport = typeof (localStorage.getItem('sa-import-side-menu')) ? JSON.parse(localStorage.getItem('sa-import-side-menu')) : false;
      this.openMenuExport = typeof (localStorage.getItem('sa-export-side-menu')) ? JSON.parse(localStorage.getItem('sa-export-side-menu')) : false;
      this.openSaOperationsMenu = typeof (localStorage.getItem('sa-operations-side-menu')) ? JSON.parse((localStorage.getItem('sa-operations-side-menu'))) : false;
    },

    methods: {

      toggleMenu(type) {
        if (type === 'im') {
          this.openMenuImport = !this.openMenuImport;
          localStorage.setItem('sa-import-side-menu', this.openMenuImport);
        }

        if (type === 'ex') {
          this.openMenuExport = !this.openMenuExport;
          localStorage.setItem('sa-export-side-menu', this.openMenuExport);
        }
        if (type === 'sa-operations') {
          this.openSaOperationsMenu = !this.openSaOperationsMenu;
          localStorage.setItem('sa-operations-side-menu', this.openSaOperationsMenu);
        }

        if (type === 'app') {
          this.openApplicationMenu = !this.openApplicationMenu;
        }
      }

    }
  };
</script>

<style scoped>


.menu-title {
  /*background-color: rgba(203, 203, 210, 0.20);*/
  /*background-color: rgba(0, 0, 0, .2);*/
  padding: 10px 2px 10px 0;
  text-align: left;
  font-weight: normal;
  font-size: 13px;
  color: #FFFFFF;
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 12px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

.menu-title:hover {
  background-color: rgba(203, 203, 210, 0.10);
  cursor: pointer;
}

.menu-title-name {
  margin-left: 32px;
}

.menu-value {
  background-color: rgba(0, 0, 0, .2);
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .8s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.list-enter-active, .list-leave-active {
  transition: all .7s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.nc-icon {
  padding-right: 8px;
  padding-top: 4px;
  font-size: 20px !important;
}

div.drop-down-sub-menu {
  display: block;
  padding-left: 7px;
}

.sub-menu-display {
  display: block !important;
}

.sub-menu-hidden, .nc-important-hidden {
  display: none !important;
}

.sidebar .nav li .nav-link p, body > .navbar-collapse .nav li .nav-link p {
  text-transform: capitalize !important;
  padding-left: 10px;
  font-weight: normal !important;
}

.sidebar .nav li .nav-link:hover .nc-icon-inner, body > .navbar-collapse .nav li .nav-link:hover .nc-icon-inner {
  fill: #d59a18;
}

.menu-title:hover .nc-icon , .menu-title:hover .nc-icon-page {
  fill: #d59a18;
}

.nc-icon {
  position: absolute;
  top: -10px;
  left: 15px;
  font-size: 25px !important;
  fill: #FFFFFF;
}

.nc-icon-inner {
  font-size: 10px !important;
  padding-left: 30px;
  fill: #FFFFFF;
}

.nc-icon-page {
  font-size: 10px !important;
  fill: #FFFFFF;
}

.custom-hr {
  background-color: #DCDCDC;
  width: auto;
  margin-left: 10%;
  margin-right: 10%;
}

</style>
